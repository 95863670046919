<div [formGroup]="formGroup">
  <div>
    <mat-form-field appearance="outline" class="ftp-setup-host-field">
      <mat-label>Host</mat-label>
      <input matInput placeholder="Host" formControlName="Host">
    </mat-form-field>&nbsp;
    <mat-form-field appearance="outline" class="ftp-setup-port-field">
      <mat-label>Port</mat-label>
      <input matInput placeholder="Port" formControlName="Port">
    </mat-form-field>
  </div>
  <div>
    <mat-form-field appearance="outline" class="ftp-setup-un-field">
      <mat-label>Username</mat-label>
      <input matInput placeholder="Username" formControlName="Username">
    </mat-form-field>&nbsp;
    <mat-form-field appearance="outline" class="ftp-setup-un-field">
      <mat-label>Password</mat-label>
      <input matInput type="password" placeholder="Password" formControlName="Password">
    </mat-form-field>
  </div>
  <div>
    <mat-form-field appearance="outline" class="ftp-setup-rmtFolder-field">
      <mat-label>Remote Folder</mat-label>
      <input matInput placeholder="Remote Folder" formControlName="Folder">
    </mat-form-field>
  </div>
  <div>
    <mat-form-field appearance="outline" class="ftp-setup-rmtFolder-field">
      <mat-label>Filename Pattern</mat-label>
      <input matInput placeholder="Filename Pattern" formControlName="File">
    </mat-form-field>
  </div>
  <div>
    <mat-checkbox class="example-margin" formControlName="Passive">Passive</mat-checkbox>
  </div>
  <div>
    <button mat-raised-button color="primary" (click)="testService()">Test Access to FTP Service</button>&nbsp;
    <div *ngIf="testing" class="loading-container">
      <span class="loading-content">
        <div class="spinner-div"><mat-spinner [diameter]="25"></mat-spinner></div>
        <div class="spinner-text"> Testing...</div>
      </span>
    </div>
    <div *ngIf="successes.length > 0" class="mt-10">
      <div class="alert alert-success" role="alert">
        <div *ngFor="let success of successes">
          {{success}}
        </div>
      </div>
    </div>
    <div *ngIf="errors.length > 0" class="mt-10">
      <div class="alert alert-danger" role="alert">
        <div *ngFor="let error of errors">
          {{error}}
        </div>
      </div>
    </div>
  </div>
</div>
