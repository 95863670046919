<div class="fullWidth dialog-title">
  <div class="leftFloat">
    <h3>Review Interview</h3>
  </div>
  <div class="rightFloat">
    <button mat-icon-button matDialogClose="no" matTooltip="Close" matTooltipPosition="below">
      <mat-icon class="dialog-close-icon">close</mat-icon>
    </button>
  </div>
</div>
<div class="outer-table-container clearBoth">
  <div class="review-data-table-container custom-scroll-bar">
    <table mat-table #table [dataSource]="dataSource"
           multiTemplateDataRows
           matSort
           matSortActive="asked"
           matSortDisableClear matSortDirection="desc">

      <!-- Name Column -->
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
        <td mat-cell *matCellDef="let survey"> {{survey.VariableName}} </td>
      </ng-container>

      <!-- Created Column -->
      <ng-container matColumnDef="response">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Response </th>
        <td mat-cell *matCellDef="let survey"> {{survey.Response}} </td>
      </ng-container>

      <!-- Modified Column -->
      <ng-container matColumnDef="asked">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Asked </th>
        <td mat-cell *matCellDef="let survey"> {{survey.AskedDate < '1900-01-01' ? '' : survey.AskedDate | date:'medium': '' : translate.currentLang}} </td>
      </ng-container>

      <!-- Go Live Column -->
      <ng-container matColumnDef="answered">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Answered </th>
        <td mat-cell *matCellDef="let survey"> {{survey.AnsweredDate | date:'medium': '' : translate.currentLang}} </td>
      </ng-container>

      <ng-container matColumnDef="action">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> </th>
        <td mat-cell *matCellDef="let survey">  </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="columns; sticky: true"></tr>
      <tr mat-row *matRowDef="let survey; columns: columns;"
          class="users-element-row">
      </tr>
    </table>
  </div>
</div>
