<div class="netwrk-dtl-container">
  <div class="netwrk-dtl-content">
    <form [formGroup]="formGroup">
      <div>
        <mat-form-field appearance="outline" class="fullWidth">
          <mat-label>Name</mat-label>
          <input formControlName="name" matInput placeholder="Name">
        </mat-form-field>
      </div>
      <div>
        <mat-form-field appearance="outline" class="fullWidth">
          <mat-label>Description</mat-label>
          <textarea formControlName="description" matInput placeholder="Description" cdkTextareaAutosize
                    #autosize="cdkTextareaAutosize"
                    cdkAutosizeMinRows="3"
                    cdkAutosizeMaxRows="10"></textarea>
        </mat-form-field>
      </div>
      <div>
        <h3>Numbers</h3>
      </div>
      <div formArrayName="element">
        <div *ngFor="let opt of optionArr.controls; let i = index" [formGroupName]="i">
          <div class="fieldRowWithPadding list margin-top-bot-5">
            <div>
              <div class="fieldRowWithPadding list">
                <mat-form-field appearance="outline">
                  <mat-label> Number </mat-label>
                  <input formControlName="number" matInput placeholder="Number" />
                </mat-form-field>&nbsp;
              </div>
              <div class="fieldRowWithPadding list">
                <div>
                  <mat-button-toggle-group #group="matButtonToggleGroup" formControlName="direction">
                    <mat-button-toggle [value]="1">1-Way</mat-button-toggle>
                    <mat-button-toggle [value]="2">2-Way</mat-button-toggle>
                  </mat-button-toggle-group>&nbsp;
                </div>
                <div>
                  <mat-button-toggle-group #group="matButtonToggleGroup" multiple formControlName="capability">
                    <mat-button-toggle [value]="1">Concatenation</mat-button-toggle>
                    <mat-button-toggle [value]="2">GSM Standard</mat-button-toggle>
                    <mat-button-toggle [value]="3">GSM Extended</mat-button-toggle>
                    <mat-button-toggle [value]="4">Unicode</mat-button-toggle>
                    <mat-button-toggle [value]="5">Brandable</mat-button-toggle>
                  </mat-button-toggle-group>&nbsp;
                </div>
              </div>
              <div class="fieldRowWithPadding list mt-5">
                <div>
                  <mat-button-toggle-group #group="matButtonToggleGroup" multiple formControlName="whitelisting">
                    <mat-button-toggle [value]="1">Brand</mat-button-toggle>
                    <mat-button-toggle [value]="2">Invite</mat-button-toggle>
                    <mat-button-toggle [value]="3">Reminder</mat-button-toggle>
                    <mat-button-toggle [value]="4">Survey Link</mat-button-toggle>
                    <mat-button-toggle [value]="5">Opt Out</mat-button-toggle>
                    <mat-button-toggle [value]="6">Tiny Url</mat-button-toggle>
                  </mat-button-toggle-group>
                </div>
              </div>
            </div>
            <div *ngIf="i + 1 == optionArr.controls.length">
              <button mat-line-end mat-icon-button matTooltip="Add" matTooltipPosition="above" (click)="addNewRow(i)">
                <mat-icon class="dialog-close-icon">add_circle_outline</mat-icon>
              </button>
            </div>
            <div *ngIf="i < optionArr.controls.length && optionArr.controls.length > 1">
              <button mat-line-end mat-icon-button matTooltip="Remove" matTooltipPosition="above" (click)="deleteRow(i)">
                <mat-icon class="dialog-close-icon">remove_circle_outline</mat-icon>
              </button>
            </div>
          </div>
          <mat-divider></mat-divider>
        </div>
      </div>
      <div class="margin-top-bot-40">
        <div>
          <button mat-raised-button color="primary" (click)="onSubmit()" type="submit" [disabled]="formGroup.invalid">Save</button>&nbsp;&nbsp;
          <button mat-raised-button color="accent" (click)="cancel()">Cancel</button>
        </div>
      </div>
    </form>
  </div>
</div>
