import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { SmsEditorComponent } from '../../Development/sms-editor/sms-editor.component';

@Component({
  selector: 'app-message-editor',
  templateUrl: './message-editor.component.html',
  styleUrls: ['./message-editor.component.css']
})
export class MessageEditorComponent {

  formGroup: FormGroup = new FormGroup({});
  inEditMode: boolean = false;

  @ViewChild('sms') public sms: SmsEditorComponent | undefined;

  constructor() {
    this.formGroup = new FormGroup({
      message: new FormControl('')
    });
  }

  save() {
    this.inEditMode = false;
    this._text = this.multiLine ? this.sms.text : this.formGroup.controls.message.value
    this.onChange.emit(this.text);
  }

  @Input()
  get name(): string {
    return this._name;
  }
  set name(value: string) {
    this._name = value;
  }
  private _name: string = "";

  @Input()
  get multiLine(): boolean {
    return this._multiLine;
  }
  set multiLine(value: boolean) {
    this._multiLine = value;
  }
  private _multiLine: boolean = false;

  @Input()
  get text(): string {
    return this._text;
  }
  set text(value: string) {
    this._text = value;
    this.formGroup.controls.message.setValue(value);
  }
  private _text: string = "";

  @Output()
  onChange: EventEmitter<string> = new EventEmitter();
}
