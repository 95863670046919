import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { fadeInDownAnimation, fadeInLeftAnimation, fadeInRightAnimation, rubberBandAnimation } from 'angular-animations';
import { fromEvent, takeUntil, Subject, Observable } from 'rxjs';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { version } from "../../environments/environment";

@Component({
  selector: 'app-newhome',
  templateUrl: './newhome.component.html',
  styleUrls: ['./newhome.component.css'],
  animations: [
    fadeInLeftAnimation({anchor: 'leftIn'}),
    fadeInRightAnimation({ anchor: 'rightIn' }),
    rubberBandAnimation({ anchor: 'rubberBand' }),
    fadeInDownAnimation({ anchor: 'downIn'})
    ]
})
export class NewhomeComponent implements OnInit, AfterViewInit, OnDestroy {
  start: boolean = false;
  fadeInLeftState = false;
  formGroup: FormGroup;
  p1: boolean = false;
  p2: boolean = false;
  p3: boolean = false;

  destroy = new Subject();

  destroy$ = this.destroy.asObservable();
  top: number = 0;

  currentVersion: string;
  currentYear = new Date().getFullYear();

  menu = [
    { name: "Surveys", location: "surveys" },
    { name: "Respondents", location: "audience" },
    { name: "Channels", location: "step3" },
    { name: "Results", location: "step4" },
    { name: "Pricing", location: "pricing" }
  ];


  constructor(private breakpointObserver: BreakpointObserver, private router: Router) {
    this.currentVersion = version;

    this.formGroup = new FormGroup({
      email: new FormControl("", [Validators.required, Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$')])
    })
  }

  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
    .pipe(map(result => result.matches));

  ngOnDestroy(): void {
    this.destroy.next(this.destroy$);
    }

  ngAfterViewInit(): void {
    fromEvent(window, 'scroll').pipe(takeUntil(this.destroy$)).subscribe((e: Event) => {
      this.top = this.getYPosition(e);
    });

    Promise.resolve(null).then(() => {
      this.start = true;
    });
  }

  checkStyle(): string {
    if (this.top > 5) {
      return 'mat-elevation-z2';
    }

    return '';
  }

  ngOnInit(): void {
  }

  openTerms() {
    const url = this.router.createUrlTree(['/termsofservice'])
    window.open(url.toString(), '_blank')
  }

  openPrivacy() {
    const url = this.router.createUrlTree(['/privacystatement'])
    window.open(url.toString(), '_blank')
  }

  goToLink(url: string) {
    window.open(url, "_blank");
  }

  getYPosition(e): number {
    return e.target.scrollingElement.scrollTop;
  }

  startForFree(isMobile: boolean) {
    if (!this.formGroup.valid && !isMobile) {
      return;
    }

    this.router.navigate(["/signup/" + this.formGroup.controls.email.value]);

    this.start = !this.start;
  }

  onAppear() {
    let a = 0;
  }

  scrollTo(location: string) {
    const element = document.getElementById(location);
    element?.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'start' });
  }
}
