import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { AfterViewInit, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AuthenticationService } from '../../authentication.Service';
import { ChatMessage, ChatService } from '../../chat.Service';
import { UserProfile } from '../../user.Service';

@Component({
  selector: 'app-compose-message',
  templateUrl: './compose-message.component.html',
  styleUrls: ['./compose-message.component.css']
})
export class ComposeMessageComponent implements OnInit, AfterViewInit {
  message: ChatMessage = null;
  displayError = false;
  errorMsg = "";
  formGroup: FormGroup;
  addOnBlur = true;
  reply = false;
  readonly separatorKeysCodes = [ENTER, COMMA] as const;
  users: UserProfile[] = [];

  @ViewChild('content') public content: any;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<ComposeMessageComponent>,
    public router: Router,
    private chatService: ChatService)
  {
    if (data && data.message) {
      this.message = data.message;
      this.reply = data.reply;
      this.chatService.markAsRead(this.message);
    }
    this.formGroup = new FormGroup({
      subject: new FormControl(),
      to: new FormControl(),
      contents: new FormControl()
    });
  }

  ngAfterViewInit(): void {
    if (this.content == undefined) {
      return;
    }

    let selector = this.content.nativeElement.querySelector('a');
    let clickValue = selector.attributes.getNamedItem("(click)").nodeValue as string;
    let elements = clickValue.split('[')[1].split(']')[0].split(',');
    selector.addEventListener('click', this.navigate.bind(this));
  }

  ngOnInit(): void {
    if (this.reply) {
      this.setupReply();
    }
  }

  onSubmit() {
    let message = new ChatMessage();
    message.Subject = this.formGroup.controls.subject.value;
    let temp = [];
    for (let i = 0; i < this.users.length; i++) {
      temp.push(this.users[i].UserID);
    }

    message.ToList = temp.join(";");
    message.Content = (this.message ? this.message.Content : "") + this.formGroup.controls.contents.value;
    this.chatService.sendMessage(message).subscribe(result => {
      this.dialogRef.close();
    });
  }

  navigate(element: any) {
    let clickValue = element.currentTarget.attributes.getNamedItem("(click)").nodeValue as string;
    let elements = clickValue.split('[')[1].split(']')[0].split(',');
    for (let i = 0; i < elements.length; i++) {
      elements[i] = elements[i].replaceAll("'", "");
    }

    this.router.navigate(elements);
    this.dialogRef.close();
  }

  setupReply() {
    this.reply = true;
    this.formGroup.controls.subject.setValue("Re: " + this.message.Subject);
  }
}
