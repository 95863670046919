<div mat-dialog-title class="randomise-dialog-title">
  <div class="leftFloat">
    <h1>Randomise Questions</h1>
  </div>
  <div class="rightFloat">
    <button mat-icon-button mat-dialog-close>
      <mat-icon>close</mat-icon>
    </button>
  </div>
</div>

<div class="randomise-qnTable-container mat-elevation-z8">
  <table mat-table [dataSource]="dataSource">
    <!-- Checkbox Column -->
    <ng-container matColumnDef="select">
      <th mat-header-cell *matHeaderCellDef [style]="(isHandset$ | async) ? 'padding:0px 10px 0px 8px; text-align:center;' : 'padding:0px 10px 0px 8px; text-align:center;'">
        <mat-checkbox (change)="$event ? masterToggle() : null"
                      [checked]="selection.hasValue() && isAllSelected()"
                      [indeterminate]="selection.hasValue() && !isAllSelected()"
                      [aria-label]="checkboxLabel()">
        </mat-checkbox>
      </th>
      <td mat-cell *matCellDef="let row" [style]="(isHandset$ | async) ? 'padding: 6px 10px 0px 8px;' : 'padding: 6px 10px 0px 8px;'">
        <mat-checkbox (click)="$event.stopPropagation()"
                      (change)="$event ? selection.toggle(row) : null"
                      [checked]="selection.isSelected(row)"
                      [aria-label]="checkboxLabel(row)">
        </mat-checkbox>
      </td>
    </ng-container>


    <!-- Question ID Column -->
    <ng-container matColumnDef="questionId">
      <th mat-header-cell *matHeaderCellDef class="txt-align-center"> Id </th>
      <td mat-cell *matCellDef="let element"> {{element.questionId}} </td>
    </ng-container>

    <!-- Question Text Column -->
    <ng-container matColumnDef="questionText">
      <th mat-header-cell *matHeaderCellDef class="txt-align-center"> Question </th>
      <td mat-cell *matCellDef="let element"> {{element.questionText}} </td>
    </ng-container>


    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true" class="txt-align-center"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"
        (click)="selection.toggle(row)">
    </tr>
  </table>
</div>
