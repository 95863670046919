<div class="fullWidth dialog-title">
  <div class="leftFloat">
    <h3>Import Surveys</h3>
  </div>
  <div class="rightFloat">
    <button mat-icon-button matDialogClose="no" matTooltip="Close" matTooltipPosition="below">
      <mat-icon class="dialog-close-icon">close</mat-icon>
    </button>
  </div>
</div>
@if (uploadFile) {
<div style="margin-top:5px">
  <app-file-upload #fileUpload accept=".*" text="Import Project" (uploaded)="onFileUploaded($event)"></app-file-upload>
</div>
}
@else {
<div class="clearBoth margin-5 padding-lr-10">
  Select which surveys and what elements you want to import
  <div class="alert alert-warning" role="alert">
    This process will not check of existing surveys, instead it will add the surveys as new entries with the same name.
  </div>
</div>
<div [formGroup]="formGroup">
  <mat-checkbox [checked]="allSelected()" [indeterminate]="patialSelection()" (change)="update($event.checked)">All Surveys</mat-checkbox>
  <ul formArrayName="Surveys">
    @for (survey of surveys; track survey; let i = $index) {
    <li formGroupName="{{i}}">
      <div style="width:100%;display:flex;flex-flow:row">
        <div style="width:50%">
          <mat-checkbox formControlName="Selected"><strong>{{survey.Survey.Name}}</strong> ({{survey.Survey.SurveyID}}, {{status(survey)}} <span *ngIf="survey.Survey.Reference != ''">,&nbsp;{{survey.Survey.SurveyReference}}</span>)&nbsp;
          <span style="width:200px;overflow-x: clip;text-overflow:ellipsis">{{survey.Survey.Description}}</span></mat-checkbox>&nbsp;&nbsp;
        </div>
        <div style="width:50%">
          <mat-button-toggle-group formControlName="Options" multiple>
            <mat-button-toggle [disabled]="!hasJobMaster(survey)" value="job">Schedule</mat-button-toggle>
            <mat-button-toggle [disabled]="!hasPanel(survey)" value="panel">Panel</mat-button-toggle>
            <mat-button-toggle [disabled]="processes(survey).length == 0" value="process">{{processes(survey).length}} Data I/O Process(s)</mat-button-toggle>
          </mat-button-toggle-group>
        </div>
      </div>
    </li>
    }
  </ul>
  @if (showImportButton) {
  <button *ngIf="showImportButton" mat-raised-button color="primary" (click)="import()">Import</button>
  }
  @else {
  <div *ngIf="!error && !success">
    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
  </div>
  }
  <div *ngIf="error" class="alert alert-danger" role="alert">
    There was a problem importing the surveys. Please contact your support team.
  </div>
  <div *ngIf="success" class="alert alert-success" role="alert">
    The surveys selected were successfully imports. Please check the account to be sure you got what you wanted.
  </div>
</div>
}
