<div>
  <form class="tfnform" [formGroup]="formGroup">
    <p>
      Organization
      <br />
      <mat-form-field appearance="outline" class="msg-hist-filtByCountry-field">
        <mat-label>Organization</mat-label>
        <mat-select formControlName="Organization">
          <mat-option value="">All Organization</mat-option>
          <mat-option *ngFor="let org of orgList" [value]="org">{{org.name}}</mat-option>
        </mat-select>
      </mat-form-field>
      <br />
      Business Name
      <br />
      <mat-form-field appearance="outline" class="msg-hist-filtByNum-field" style="width:288px">
        <mat-label>Business Name</mat-label>
        <input matInput placeholder="Business Name" formControlName="businessname">
      </mat-form-field>
      <br />
      Street Address
      <br />
      <mat-form-field appearance="outline" class="msg-hist-filtByNum-field" style="width:288px">
        <mat-label>Street Address</mat-label>
        <input matInput placeholder="Street Address" formControlName="streetaddress">
      </mat-form-field>
      <br />
      City
      <br />
      <mat-form-field appearance="outline" class="msg-hist-filtByNum-field" style="width:288px">
        <mat-label>City</mat-label>
        <input matInput placeholder="City" formControlName="city">
      </mat-form-field>
      <br />
      Country
      <br />
      <mat-form-field appearance="outline" class="msg-hist-filtByCountry-field">
        <mat-label>Country</mat-label>
        <mat-select formControlName="Country">
          <mat-option value="">All Countries</mat-option>
          <mat-option *ngFor="let country of countries" [value]="country">{{country.Country}}</mat-option>
        </mat-select>
      </mat-form-field>
      <br />
      Registered Address (State)
      <br />
      <mat-form-field appearance="outline" class="msg-hist-filtByNum-field" style="width:288px">
        <mat-label>Registered Address (State)</mat-label>
        <input matInput placeholder="State" formControlName="state">
      </mat-form-field>
      <br />
      ZIP / Postal Code
      <br />
      <mat-form-field appearance="outline" class="msg-hist-filtByNum-field" style="width:288px">
        <mat-label>ZIP / Postal Code</mat-label>
        <input matInput placeholder="ZIP / Postal" formControlName="postalcode">
      </mat-form-field>
      <br />
      Company Website
      <br />
      <mat-form-field appearance="outline" class="msg-hist-filtByNum-field" style="width:288px">
        <mat-label>Company Website</mat-label>
        <input matInput placeholder="Company Website" formControlName="companywebsite">
      </mat-form-field>
      <br />
      Business Name
      <br />
      <mat-form-field appearance="outline" class="msg-hist-filtByNum-field" style="width:288px">
        <mat-label>Business Name</mat-label>
        <input matInput placeholder="Business Name" formControlName="contactname">
      </mat-form-field>
      <br />
      Business Email
      <br />
      <mat-form-field appearance="outline" class="msg-hist-filtByNum-field" style="width:288px">
        <mat-label>Business Email</mat-label>
        <input matInput placeholder="Business Email" formControlName="contactemail">
      </mat-form-field>
      <br />
      Business Phone Number
      <br />
      <mat-form-field appearance="outline" class="msg-hist-filtByNum-field" style="width:288px">
        <mat-label>Business Phone Number</mat-label>
        <input matInput placeholder="Business PhoneNumber" formControlName="contactnumber">
      </mat-form-field>
      <br />
      Estimated Monthly Volume of Messages
      <br />
      <mat-form-field appearance="outline" class="msg-hist-filtByNum-field" style="width:288px">
        <mat-label>Estimated Monthly Volume of Messages</mat-label>
        <input matInput placeholder="e.g 1000" formControlName="volofmessage">
      </mat-form-field>
      <br />
      Toll-Free Phone Number(s)
      <br />
      <mat-form-field appearance="outline" class="msg-hist-filtByNum-field" style="width:288px">
        <mat-label>Toll-Free Phone Number(s)</mat-label>
        <input matInput placeholder="1800-2222-4444" formControlName="tollfreenumber">
      </mat-form-field>
      <br />
      Use Case Category
      <br />
      <mat-form-field appearance="outline" class="msg-hist-filtByCountry-field">
        <mat-label>Use Case Catergory</mat-label>
        <mat-select [formControl]="category" [(value)]="selectedcategory" multiple>
          @for (category of categoryList; track category) {
          <mat-option [value]="category">{{category}}</mat-option>
          }
        </mat-select>
      </mat-form-field>
      <br />
      Use Case Description
      <br />
      <mat-form-field appearance="outline" class="msg-hist-filtByNum-field" style="width:288px">
        <mat-label>Use Case Description</mat-label>
        <textarea formControlName="description" matInput placeholder="Description"></textarea>
      </mat-form-field>
      <br />
      Opt-In Consent Description<button mat-mini-fab color="primary" aria-label="Refresh"
                                        matTooltip="Describe with words how the the message recipient explicitly consents to receive text messages. For example, online, mobile website, mobile application, text to join, point of sale or paper form. Recipients must opt-in to receive messages before they receive the first message. Please see image below for a good example of online opt-in consent disclosure." style="text-align:left">
        <mat-icon>info</mat-icon>
      </button>
      <br />
      <mat-form-field appearance="outline" class="msg-hist-filtByNum-field" style="width:288px">
        <mat-label>Opt-In Consent Description</mat-label>
        <textarea formControlName="optindescription" matInput placeholder="Opt-In Consent Description"></textarea>
      </mat-form-field>
    </p>
    <p>
      <label style="width:288px;">Describe with words how the the message recipient explicitly consents to receive text messages.  For example, online, mobile website, mobile application, text to join, point of sale or paper form.  Recipients must opt-in to receive messages before they receive the first message.  Please see image below for a good example of online opt-in consent disclosure.</label>
    </p>
    <p>
      Opt-In Consent Image<button mat-mini-fab color="primary" aria-label="Refresh"
                                  matTooltip="The opt-in website should include - Message frequency disclosure, link to Terms & Conditions and Privacy policy, Opt-out instructions, Help instructions,Message and data rates may apply disclosure and Clear, understandable program description that informs the end user of the nature of the message program, identifies the sender or brand associated with the program, and matches the supplied sample messages." style="text-align:left">
        <mat-icon>info</mat-icon>
      </button>
      <br />
      <mat-form-field appearance="outline" class="msg-hist-filtByNum-field" style="width:288px">
        <mat-label>Opt-In Consent Image</mat-label>
        <input matInput placeholder="Consent Image" formControlName="image">
      </mat-form-field>
    </p>
    <p>
      <label style="width:288px;">The opt-in website should include - Message frequency disclosure, link to Terms & Conditions and Privacy policy, Opt-out instructions, Help instructions,Message and data rates may apply disclosure and Clear, understandable program description that informs the end user of the nature of the message program, identifies the sender or brand associated with the program, and matches the supplied sample messages.</label>
    </p>
    <p>
    Opt-in URL
    <br />
    <mat-form-field appearance="outline" class="msg-hist-filtByNum-field" style="width:288px">
      <mat-label>Opt-in URL</mat-label>
      <input matInput placeholder="Opt-in URL" formControlName="url">
    </mat-form-field>
    <br />
    </p>
    Message content
    <br />
    <div>
      <app-sms-editor formControlName="messagecontent" place #sms [text]="message" [channel]="isSms() ? 'sms' : 'whatsapp'" [smart]="false"></app-sms-editor>
    </div>

    <!--<mat-form-field appearance="outline" class="msg-hist-filtByNum-field" style="width:288px">
    <mat-label>Message content</mat-label>
    <input matInput placeholder="Message content" formControlName="messagecontent">
  </mat-form-field>&nbsp;-->

    <button mat-flat-button color="primary" (click)="newSearch()">Send</button>

  </form>
</div>
<div *ngIf="displayError">
  <div class="alert alert-danger" role="alert">
    {{errorMsg}}
  </div>
</div>

<div *ngIf="displaySuccess">
  <div class="alert alert-success" role="alert">
    Email has been sent for your TFN Registration.
  </div>
</div>
