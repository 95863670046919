import { Component } from '@angular/core';
import { Observable } from "rxjs";
import { tap } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { MatTableDataSource } from '@angular/material/table';
import { ProcessService, ProcessServiceModel } from '../../process.Service';
import { SurveyService, SurveySummaryModel } from '../../survey.Service';
import { UserService, UserProfile } from '../../user.Service';
import { plainToClass } from 'class-transformer';

@Component({
  selector: 'app-active-dataio',
  templateUrl: './active-dataio.component.html',
  styleUrls: ['./active-dataio.component.css']
})
export class ActiveDataioComponent {
  tempSummary: Observable<any>;
  processes: ProcessServiceModel[] = null;
  displayedColumns = ['user', 'name', 'status'];
  dataSource = new MatTableDataSource(this.processes);
  users: UserProfile[] = [];
  surveys: SurveySummaryModel[] = [];

  constructor(public translate: TranslateService,
    private surveyService: SurveyService,
    private userService: UserService,
    private processService: ProcessService) {
    this.processes = [];
    this.tempSummary = this.processService.getActiveProcessForSystem().pipe(tap<ProcessServiceModel[]>(
      result => {
        let temp: ProcessServiceModel[] = [];
        for (let i = 0; i < result.length; i++) {
          temp.push(plainToClass(ProcessServiceModel, result[i]));
        }

        this.processes = temp;
        this.dataSource = new MatTableDataSource(this.processes);
        for (let i = 0; i < this.processes.length; i++) {
          this.getEvents(this.processes[i]);
          let found = false;
          for (let j = 0; j < this.surveys.length; j++) {
            if (this.surveys[j].SurveyID = this.processes[i].ReferenceId) {
              this.processes[i].surveySummary = this.surveys[j];
              found = true;
              break;
            }
          }

          if (!found) {
            this.surveyService.getSurveySummary(this.processes[i].ReferenceId).subscribe(
              result => {
                this.processes[i].surveySummary = result;
                this.surveys.push(result);
              });
          }

          found = false;
          for (let j = 0; j < this.users.length; j++) {
            if (this.users[j].UserID = this.processes[i].OwnerId) {
              this.processes[i].userProfile = this.users[j];
              found = true;
              break;
            }
          }

          if (!found) {
            this.userService.getUser(this.processes[i].OwnerId.toString()).subscribe(
              result => {
                this.processes[i].userProfile = result;
                this.users.push(result);
              }
            )
          }
        }
      }));
  }

  getEvents(process: ProcessServiceModel) {
    this.processService.getProcessMessages(process.ProcessId, 0).subscribe(
      result => {
        process.messages = result;

      });
    this.processService.getProcessErrors(process.ProcessId, 0).subscribe(
      result => {
        process.errors = result;
      });
  }

  isExport(process: ProcessServiceModel) {
    return process.Task.Type == "EXPORT";
  }

  automate(process: ProcessServiceModel) {
    return (process.StatusId > 0 && (process.StatusId < 3 || process.StatusId == 4)) || process.Task.Automated;
  }

  edit(row: any) {

  }

  delete(process: ProcessServiceModel) {
    this.processService.deleteForUserProcess(process).subscribe(
      result => {
        for (let i = 0; i < this.processes.length; i++) {
          if (process.ProcessId == this.processes[i].ProcessId) {
            this.processes.splice(i, 1)
            this.dataSource = new MatTableDataSource(this.processes);
            return;
          }
        }
      },
      error => {
      }
    )
  }

  login(process: ProcessServiceModel) {
  }
}
