import { Component, OnInit } from '@angular/core';
import { SelectionModel } from '@angular/cdk/collections';
import { MatTableDataSource } from '@angular/material/table';
import { Observable } from "rxjs";
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-randomise-questions',
  templateUrl: './randomise-questions.component.html',
  styleUrls: ['./randomise-questions.component.css']
})
export class RandomiseQuestionsComponent implements OnInit {
  displayedColumns: string[] = ['select', 'questionId', 'questionText'];
  dataSource = new MatTableDataSource<QuestionElement>(ELEMENT_DATA);
  selection = new SelectionModel<QuestionElement>(true, []);
  

  constructor(private breakpointObserver: BreakpointObserver) { }

  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
    .pipe(map(result => result.matches));

  ngOnInit(): void {
  }

/** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.dataSource.data.forEach(row => this.selection.select(row));
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: QuestionElement): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.questionId + 1}`;
  }
}

export interface QuestionElement {
  questionId: string;
  questionText: string;
}

const ELEMENT_DATA: QuestionElement[] = [
  { questionId: 'Q1', questionText: 'Test Question Text 1' },
  { questionId: 'Q2', questionText: 'Test Question Text 2' },
  { questionId: 'Q3', questionText: 'Test Question Text 3' },
  { questionId: 'Q4', questionText: 'Test Question Text 4' },
  { questionId: 'Q5', questionText: 'Test Question Text 5' },
  { questionId: 'Q6', questionText: 'Test Question Text 6' },
  { questionId: 'Q7', questionText: 'Test Question Text 7' },
  { questionId: 'Q8', questionText: 'Test Question Text 8' },
  { questionId: 'Q9', questionText: 'Test Question Text 9' },
  { questionId: 'Q10', questionText: 'Test Question Text 10' },
  { questionId: 'Q11', questionText: 'Test Question Text 11' },
  { questionId: 'Q12', questionText: 'Test Question Text 12' },
  { questionId: 'Q13', questionText: 'Test Question Text 13' },
  { questionId: 'Q14', questionText: 'Test Question Text 14' },
  { questionId: 'Q15', questionText: 'Test Question Text 15' },
  { questionId: 'Q16', questionText: 'Test Question Text 16' },
  { questionId: 'Q17', questionText: 'Test Question Text 17' },
  { questionId: 'Q18', questionText: 'Test Question Text 18' },
  { questionId: 'Q19', questionText: 'Test Question Text 19' },
  { questionId: 'Q20', questionText: 'Test Question Text 20' },
];
