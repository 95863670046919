<div class="rightFloat">
  <button mat-icon-button mat-dialog-close>
    <mat-icon>close</mat-icon>
  </button>
</div>
<div class="clearBoth"></div>
<div mat-dialog-content>
  <form class="flex-stretch-column" [formGroup]="formGroup">
    <h2>Delete Data</h2>
    <mat-icon>warning</mat-icon>
    <div>
      <button mat-raised-button class="mt-5" (click)="delete()" color="primary">Delete</button>
    </div>
  </form>
</div>
