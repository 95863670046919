<div class="fullWidth dialog-title">
  <div class="leftFloat">
    <h3>Language Translation Summary</h3>
  </div>
  <div class="rightFloat">
    <button mat-icon-button [matDialogClose]="analysis" matTooltip="Close" matTooltipPosition="below">
      <mat-icon class="dialog-close-icon">close</mat-icon>
    </button>
  </div>
</div>
<div class="mt-5 mb-5">
  <button mat-raised-button color="primary" (click)="selectLanguages()">Add Language</button>&nbsp;
  <button *ngIf="analysis?.Summary.length > 1" mat-stroked-button (click)="edit()">
    <mat-icon>edit</mat-icon>
    <span>Edit</span>
  </button>&nbsp;
  <button mat-stroked-button (click)="export()">
    <mat-icon>download</mat-icon>
    <span>Export</span>
  </button>&nbsp;
  <button mat-stroked-button (click)="import()">
    <mat-icon>publish</mat-icon>
    <span>Import</span>
  </button>
</div>
<div class="outer-table-container mat-elevation-z8 custom-scroll-bar clearBoth lang-summ-table-div">
  <table mat-table #table [dataSource]="dataSource"
         multiTemplateDataRows
         matSort
         matSortActive="amended"
         matSortDisableClear matSortDirection="desc">

    <!-- Name Column -->
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
      <td mat-cell *matCellDef="let language"> {{language.Language.Description}} </td>
    </ng-container>

    <!-- Progress Column -->
    <ng-container matColumnDef="region">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Region </th>
      <td mat-cell *matCellDef="let language">{{language.Language.Region}}</td>
    </ng-container>

    <!-- Visible Column -->
    <ng-container matColumnDef="visible">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Visible </th>
      <td mat-cell *matCellDef="let language">
        <mat-checkbox [checked]="isVisible(language.Language.Region)"></mat-checkbox>
      </td>
    </ng-container>

    <!-- Status -->
    <ng-container matColumnDef="status">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Status </th>
      <td mat-cell *matCellDef="let language">{{language.CompletedQuestions}} Elements out of {{total(language)}} completed</td>
    </ng-container>

    <!-- Action -->
    <ng-container matColumnDef="action">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> </th>
      <td mat-cell *matCellDef="let language">
        <button *ngIf="analysis?.Summary.length > 1" mat-icon-button [matMenuTriggerFor]="menu" aria-label="icon-button with a menu" (click)="$event.stopPropagation()">
          <mat-icon>more_vert</mat-icon>
        </button>
        <mat-menu #menu="matMenu">
          <button *ngIf="languages.length > 1" mat-menu-item (click)="delete(language)">
            <mat-icon>delete</mat-icon>
            <span>Delete</span>
          </button>
        </mat-menu>
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="columnsToDisplay; sticky: true"></tr>
    <tr mat-row *matRowDef="let language; columns: columnsToDisplay;"
        class="users-element-row">
    </tr>
  </table>
</div>
<ng-template #newLanguageDialog>
  <div class="user-details-dialog-container">
    <div class="leftFloat">
      <h3>New Language</h3>
    </div>
    <div class="rightFloat">
      <button mat-icon-button matDialogClose="no">
        <mat-icon>close</mat-icon>
      </button>
    </div>
  </div>
  <div class="clearBoth mt-10">
    <p class="ml-15">
      Select one or more languages from the list.
    </p>
    <div class="outer-table-container custom-scroll-bar lang-summ-table-div mb-10">
      <mat-selection-list #regions>
        <mat-list-option *ngFor="let language of languages" [selected]="isUsed(language)" [value]="language">
          {{language.Description}}({{language.Region}})
        </mat-list-option>
      </mat-selection-list>
    </div>
    <button mat-flat-button color="accent" (click)="createCustomLanguage()">Create Custom Language</button>&nbsp;
    <button mat-flat-button color="primary" (click)="addLanguages()">Add Languages</button>&nbsp;
  </div>
</ng-template>
<ng-template #customLanguageDialog>
  <div class="user-details-dialog-container">
    <div class="leftFloat">
      <h3>Add custom Language</h3>
    </div>
    <div class="rightFloat">
      <button mat-icon-button matDialogClose="no">
        <mat-icon>close</mat-icon>
      </button>
    </div>
  </div>
  <div class="clearBoth mt-10">
    <div [formGroup]="customLanguage">
      <div>
        <mat-form-field appearance="outline" class="longField">
          <mat-label>Display Name</mat-label>
          <input matInput placeholder="Display Name" formControlName="DisplayName">
        </mat-form-field>
      </div>
      <div>
        <mat-form-field appearance="outline" class="longField">
          <mat-label>Local Definition</mat-label>
          <input matInput placeholder="Local Definition" formControlName="Locale">
        </mat-form-field>
      </div>
      <div>
        <mat-form-field appearance="outline">
          <mat-label>Region</mat-label>
          <input matInput placeholder="Region" formControlName="Region">
        </mat-form-field>
      </div>
    </div>
    <button mat-flat-button color="primary" [disabled]="customLanguage.invalid" (click)="addCustomLanguage()">Add Custom Language</button>&nbsp;
  </div>
</ng-template>

