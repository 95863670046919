import { Component, Input } from '@angular/core';
import { JobMasterModel, SurveyModel, SurveyService } from '../../survey.Service';
import { InterviewModel } from '../../question.service';
import { EstimateModel, PanelModel } from '../../panel.Service';
import { CommsModel } from '../../comms.Service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CintSurvey } from '../../cint.Service';
import { ProcessService, ProcessServiceModel } from '../../process.Service';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MessageBuilderComponent } from '../../survey-distribution/message-builder/message-builder.component';
import { plainToClass } from 'class-transformer';
import { Clipboard } from '@angular/cdk/clipboard';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { PanellistDialogComponent } from '../../panels/panellist-dialog/panellist-dialog.component';

@Component({
  selector: 'app-respondent-distribution',
  templateUrl: './respondent-distribution.component.html',
  styleUrl: './respondent-distribution.component.css',
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', maxHeight: '0' })),
      state('expanded', style({ height: '*', })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ])
  ]
})
export class RespondentDistributionComponent {

  comms: CommsModel[] = [];
  comm: CommsModel = new CommsModel();
  formGroup: FormGroup = new FormGroup({});
  fb = new FormBuilder();
  cintSurvey: CintSurvey = new CintSurvey();
  cintJson: string = "";

  notEnough: boolean = false;
  edit: boolean = false;
  displayQRTiny: boolean = false;
  displayKeyword: boolean = false;
  displayWhatsApp: boolean = false;
  show: boolean = true;
  surveyUrl = 'https://diysurveys.com/';
  tinyUrl = 'https://diysurveys.com/';
  process: ProcessServiceModel;
  estimate: EstimateModel;

  firstFormGroup: FormGroup = new FormGroup({});

  constructor(private dialog: MatDialog,
    private surveyService: SurveyService,
    private processService: ProcessService,
    private snackbar: MatSnackBar,
    private clipboard: Clipboard
  ) { }

  ngOnInit(): void {

    this.formGroup = this.fb.group({
      subject: [this.comm.Subject, Validators.required],
      content: [this.comm.EmailContent, Validators.required]
    });
  }

  onSendClick(channel: number) {
    const dialogRef = this.dialog.open(MessageBuilderComponent, { data: { survey: this.survey, channel: channel } });
    dialogRef.afterClosed().subscribe(result => {
      if (result && result.survey) {
        this.survey = result.survey;
      }
      else {
        this.cancelChoice();
      }

      return false;
    });
    return false;
  }

  update(action: number) {
    switch (action) {
      case 3:
        this.edit = false;
        break;
    }
  }

  copied(text: string) {
    this.clipboard.copy(text);
    this.openSnackbar("Copied", "");
    return text;
  }

  cancelChoice() {
    if (this.survey?.JobMaster != null && this.survey.JobMaster.JobMasterId !== 0) {
      this.survey.JobMaster.Subject = "";
      this.survey.JobMaster.Content = "";
      this.survey.JobMaster.ChannelID = 0;
      this.surveyService.saveJobMaster(this.survey.JobMaster).subscribe();
    }
    return false;
  }

  processClosed(event) {

  }

  get name(): string {
    if (this.panel) {
      let temp = this.panel.Name;
      if (this.panel.Name === "Cint") {
        temp = "";
      }
      if (this.panel.Name === this.panel.Description || this.panel.Description === "Cint") {
        return temp;
      }

      return temp + " " + this.panel.Description;
    }

    return "";
  }

  get distributionSetup(): boolean {
    if (this.survey?.JobMaster != null && this.survey.JobMaster.JobMasterId !== 0) {
      return this.survey.JobMaster.ChannelID != 0;
    }

    return this.survey?.Channel == 1;
  }

  editCurrentOptions() {
    this.edit = !this.edit;
  }

  editPanel() {

  }

  viewPanel() {
    const dialogRef = this.dialog.open(PanellistDialogComponent, { data: { panelId: this.panel.PanelID } });
  }

  respondentStatement() {
    if (this.panel?.PanellistCount == 0) {
      return "There are no respondents currently loaded";
    }

    let response = this.panel?.PanellistCount + " respondent";
    if (this.panel?.PanellistCount == 1) {
      return response;
    }

    return response + "s";
  }

  refresh() {
    this.process = null;
    this.processService.getImportProcessesForReference(this._survey.SurveyID).subscribe(
      result => {
        let processes: ProcessServiceModel[] = result;
        if (processes.length > 0) {
          this.process = plainToClass(ProcessServiceModel, processes[0]);
        }
      });
  }

  @Input()
  get survey(): SurveyModel | null {
    return this._survey;
  }
  set survey(value: SurveyModel | null) {
    this._survey = value;
    if (this._survey != null) {
      this.displayQRTiny = false;
      this.displayKeyword = false;
      if (this._survey.PanelID === -1) {
        if (this._survey.JobMaster === undefined || this._survey.JobMaster === null) {
          this._survey.JobMaster = new JobMasterModel();
        }
        this._survey.JobMaster.Subject = "";
        this._survey.JobMaster.Content = "";
        this._survey.JobMaster.ChannelID = 3;
        this._survey.JobMaster.Launched = new Date();
        this._survey.JobMaster.SurveyID = this._survey.SurveyID;
        this.surveyService.saveJobMasterForDistribution(this._survey.JobMaster).subscribe(
          result => {
            if (this._survey != null && this._survey.JobMaster != null) {
              this._survey.JobMaster.JobMasterId = result.JobMasterId;
            }
            this.surveyUrl = result.Sid;
            this.tinyUrl = result.TinyUrl;
          }
        );
        if (this._survey.Channel == 1) {
          this.displayKeyword = true;
        }
        else {
          this.displayQRTiny = true;
        }
      }

      this.refresh();
    }
  }
  private _survey: SurveyModel | null = null;

  @Input()
  get interview(): InterviewModel {
    return this._interview;
  }
  set interview(value: InterviewModel) {
    this._interview = value;
  }
  private _interview: InterviewModel = null;

  @Input()
  get panel(): PanelModel | null {
    return this._panel;
  }
  set panel(value: PanelModel | null) {
    this._panel = value;
  }
  private _panel: PanelModel | null = null;

  @Input()
  get allowDelete(): boolean {
    return this._allowDelete;
  }
  set allowDelete(value: boolean) {
    this._allowDelete = value;
  }
  private _allowDelete: boolean = true;

  @Input()
  get allowEdit(): boolean {
    return this._allowEdit;
  }
  set allowEdit(value: boolean) {
    this._allowEdit = value;
  }
  private _allowEdit: boolean = true;

  private openSnackbar(message: string, action: string) {
    if (action == "") {
      this.snackbar.open(message, action, { duration: 2000 });
    }
    else {
      this.snackbar.open(message, action, { duration: 7000 });
    }
  }
}
