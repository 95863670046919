import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { UserService, UserProfile } from "../user.Service";
import { UserInvite } from "../authentication.Service";
import { Observable } from 'rxjs';
import {switchMap, debounceTime} from 'rxjs/operators';

@Component({
  selector: 'invite-people',
  templateUrl: './invite-people.component.html',
  styleUrls: ['./invite-people.component.css']
})
export class InvitePeopleComponent implements OnInit {
  formGroup: FormGroup;
  users?: Observable<UserProfile[]>;
  public list: UserInvite[] = [];
  userId: number = -1;
  constructor(private userService: UserService) {
    this.formGroup = new FormGroup({
      Name: new FormControl(""),
    });
  }

  ngOnInit(): void {
    this.users = this.formGroup.controls.Name.valueChanges.pipe(
    debounceTime(300), switchMap(value => this.userService.findUser(value)));
  }

  displayFn(user: UserProfile): string {
    if (user) {
      this.userId = user.UserID;
      return user.UserName;
    }

    this.userId = 0;
    return "";
  }

  removeName(i: number): boolean {
    this.list.splice(i, 1);
    return false;
  }

  addName(): boolean {
    if (this.formGroup.value.Name === null || this.formGroup.value.Name === "") {
      return false;
    }

    if (this.isUserProfile(this.formGroup.value.Name)) {
      const user = this.formGroup.value.Name as UserProfile;
      if (this.list.find(e => e.UserID === user.UserID)) {
        return false;
      }
      this.list.push(new UserInvite(user.UserID, user.UserName, 0));
      this.userId = 0;
      this.formGroup.controls["Name"].patchValue("");
      return false;
    }

    if (this.emailIsValid(this.formGroup.value.Name)) {
      this.list.push(new UserInvite(0, this.formGroup.value.Name, 0));
      this.formGroup.controls["Name"].patchValue("");
    }

    return false;
  }

  isUserProfile(profile: any): profile is UserProfile {
    return (profile as UserProfile).UserID !== undefined;
  }

  emailIsValid(email: string): boolean {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)
  }

  @Input()
  get invites(): UserInvite[] {
    return this.list;
  }
  set invites(value: UserInvite[]) {
    this.list = value;
  }

}
