import { Component, OnInit } from '@angular/core';
import { BaseChart } from '../../question.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-table-terminate',
  templateUrl: './table-terminate.component.html',
  styleUrls: ['./table-terminate.component.css']
})
export class TableTerminateComponent extends BaseChart implements OnInit {

  displayedColumns: string[] = ['LastAnswered'];

  constructor(public translate: TranslateService) {
    super();
  }

  ngOnInit(): void {
  }

}
