<div class="fullWidth dialog-title">
  <div class="leftFloat">
    <h3>{{title}}</h3>
  </div>
  <div class="rightFloat">
    <button mat-icon-button matDialogClose="no" matTooltip="Close" matTooltipPosition="below">
      <mat-icon class="dialog-close-icon">close</mat-icon>
    </button>
  </div>
</div>
<div class="clearBoth"></div>
<div class="fullWidth display-flex align-center-center-flex fill-flex">
  <form class="column-flex align-center-center-flex margin-20" [formGroup]="formGroup">
    <div *ngIf="displayError">
      <div class="alert alert-danger" role="alert">
        {{errorMsg}}
      </div>
    </div>
    <div>
      <div class="wa-main-content">
        <mat-form-field appearance="outline">
          <mat-label>Panel Title</mat-label>
          <input formControlName="PanelTitle" matInput placeholder="Panel Title">
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>Panel Subtitle</mat-label>
          <input formControlName="PanelSubtitle" matInput placeholder="Panel Subtitle">
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>Description</mat-label>
          <textarea formControlName="PanelDescription" matInput placeholder="Description"></textarea>
        </mat-form-field>
        <mat-form-field *ngIf="isNewAdmin" appearance="outline">
          <mat-label>Panel Type</mat-label>
          <mat-select placeholder="Panel Type" formControlName="PanelType">
            <mat-option *ngFor="let panelType of panelTypes" [value]="panelType.id">
              {{ panelType.value }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field *ngIf="isAdmin" class="example-chip-list" appearance="outline">
          <mat-label>Panel Category</mat-label>
          <mat-chip-grid #chipList aria-label="Panel Category Selection">
            <mat-chip-row *ngFor="let category of categories"
                          [removable]="removable"
                          (removed)="remove(category)">
              {{category}}
              <button matChipRemove *ngIf="removable">
                <mat-icon matChipRemove>cancel</mat-icon>
              </button>
            </mat-chip-row>
            <input placeholder="Categories..."
                   #categoryInput
                   [formControl]="categoryCtrl"
                   [matAutocomplete]="auto"
                   [matChipInputFor]="chipList"
                   [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                   (matChipInputTokenEnd)="add($event)">
          </mat-chip-grid>
          <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
            <mat-option *ngFor="let category of filteredCategories | async" [value]="category">
              {{category}}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
        <app-image-crop *ngIf="isAdmin" #imageCropper [mediaId]="panel.MediaID"></app-image-crop>
      </div>
    </div>
    <button mat-raised-button color="primary" (click)="onSubmit()" type="submit" [disabled]="formGroup.invalid">{{buttonName}}</button>
  </form>
</div>
