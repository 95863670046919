import { Component, ComponentFactoryResolver, Inject, OnInit, ViewChild, ElementRef , ViewContainerRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CaseDataService } from '../../casedata.Service';
import { InterviewModel, ReportModel } from '../../question.service';
import { SurveyService } from '../../survey.Service';
import { QuestionReportData, ReportsComponent } from '../reports.component';
import html2canvas from 'html2canvas';
import html2pdf from 'html2pdf.js';

@Component({
  selector: 'app-selected-questions',
  templateUrl: './selected-questions.component.html',
  styleUrls: ['./selected-questions.component.css']
})
export class SelectedQuestionsComponent implements OnInit {

  component: ReportsComponent;
  model: ReportModel;
  questionData: QuestionReportData[] = [];
  jsonModel: string;
  isDone = false;
  @ViewChild('chartContainer', { static: false, read: ViewContainerRef }) chart: ViewContainerRef | undefined;


  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private resolver: ComponentFactoryResolver,
    private caseDataService: CaseDataService,
    private dialogRef: MatDialogRef<SelectedQuestionsComponent>,
    private fb: FormBuilder,
    private surveyService: SurveyService
  ) {
    this.component = data.component;
    this.model = this.component.report;
    this.jsonModel = JSON.stringify(this.model);
  }

  ngOnInit(): void {
    for (let i = 0; i < this.model.Questions.length; i++) {
      if (this.model.Questions[i].Selected) {
        const data = new QuestionReportData();
        data.setQuestion(this.component.id, this.caseDataService, this.model.Questions[i], this.component.interview, this.component.defaultScheme, this.model.TestData);
        this.questionData.push(data);
      }
    }
  }

  @ViewChild('container')
  container: ElementRef;

  public exportToPDF() {

    /* old code if the new one does not work correctly this can be used */

    let currentPosition = document.getElementById("reportToConvert")?.scrollTop;

    const options = {
      margin: 1,
      filename: 'ExportCharts.pdf',
      image: { type: 'png'},
      html2canvas: { scale: 1.75 },
      jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
    };    

    const content: Element | null = document.getElementById("reportToConvert");
    html2pdf()
      .set(options)
      .from(content)      
      .save();
  }
}
