<div *ngIf="scriptQuestions?.length == 0" class="txt-align-center margin-10 height-auto">
  There are no questions in your library. Please use the <button mat-stroked-button disabled>
    <mat-icon>add_circle_outline</mat-icon>To Library
  </button>
  in the question properties to build your library.
</div>
<div cdkDropList class="qLib-question-sidebar-list custom-scroll-bar">
  <div class="qLib-question-sidebar-box" *ngFor="let question of scriptQuestions; let i = index">
    <div class="qLib-icon">
      <mat-icon>{{question.Icon}}</mat-icon>
    </div>
    <div class="qLib-question-sidebar-text">
      <span>[{{question.QuestionName}}] {{question.FormattedBanner}}</span>
    </div>
    <div class="justify-flex-end">
      <button mat-icon-button [matMenuTriggerFor]="menu">
        <mat-icon>more_vert</mat-icon>
      </button>
      <mat-menu #menu="matMenu">
        <div class="qLib-add-del-btn-div">
          <button mat-icon-button matTooltip="Add to Questionnaire" matTooltipPosition="below" (click)="addQuestion(i)">
            <mat-icon>add</mat-icon>
          </button>
          <button mat-icon-button matTooltip="Delete from Library" matTooltipPosition="below" (click)="deleteQuestion(i)">
            <mat-icon>delete</mat-icon>
          </button>
        </div>
      </mat-menu>
    </div>
  </div>
</div>
