<div *ngIf="question !== undefined && index !== undefined && question.Categories.Items[index]" [formGroup]="formGroup">
  <div *ngIf="radioButtons && !other">
    <mat-radio-group class="vertical-radio-group" [name]="groupName" formControlName="radiobutton">
      <mat-radio-button #radiobutton class="vertical-radio-button" [value]="question.Categories.Items[index].Name" (change)="onChange()">
        <span [hidden]="!displayLabel" class="category-radio-button-label" [innerHTML]="question.Categories.Items[index].Label.Text"></span>
      </mat-radio-button>
    </mat-radio-group>
  </div>
  <div *ngIf="checkBoxes && !other" class="vertical-radio-group">
    <mat-checkbox #checkbox class="vertial-radio-button" [value]="question.Categories.Items[index].Name" formControlName="checkbox" (change)="onChange()">
      <span [hidden]="!displayLabel" class="category-radio-button-label" [innerHTML]="question.Categories.Items[index].Label.Text"></span>
    </mat-checkbox>
  </div>
  <div *ngIf="buttons && !other" class="margin-5">
    <button mat-flat-button [color]="buttonColor" (click)="onChange(isSelected)">
      <span [hidden]="!displayLabel" [innerHTML]="question.Categories.Items[index].Label.Text"></span>
    </button>
  </div>
  <div *ngIf="other" class="surv-dashBrd-progress-container">
    <div *ngIf="radioButtons">
      <mat-radio-group class="vertical-radio-group" [name]="groupName" formControlName="radiobutton">
        <mat-radio-button #radiobutton class="vertical-radio-button" [value]="question.Categories.Items[index].Name" (change)="onChange()">
        </mat-radio-button>
      </mat-radio-group>
      <div class="padding-2"></div>
    </div>
    <div *ngIf="checkBoxes" class="vertical-radio-group">
      <mat-checkbox #checkbox class="vertial-radio-button" [value]="question.Categories.Items[index].Name" formControlName="checkbox" (change)="onChange()">
      </mat-checkbox>
      <div class="padding-4"></div>
    </div>
    <diy-data #data [show]="true" [preview]="preview" [readonly]="!isSelected" [question]="question.Categories.Items[index].OtherQuestion" (change)="onOtherChange()" [style]="checkBoxes ? 'padding-left: 4px;':''"></diy-data>
  </div>
</div>
