import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import { CustomFormat, DataTask, Field } from '../../../process.Service';
import { QuestionModel } from '../../../question.service';

@Component({
  selector: 'app-field-definition',
  templateUrl: './field-definition.component.html',
  styleUrls: ['./field-definition.component.css']
})
export class FieldDefinitionComponent implements OnInit, OnDestroy {

  formGroup: FormGroup;
  showQuestion: boolean = true;
  isOpen: boolean = false;
  subscription: Subscription;
  hideUpdate: boolean = false;

  constructor() {
    this.formGroup = new FormGroup({
      Name: new FormControl(this._field.Name),
      Title: new FormControl(this._field.Title),
      Text: new FormControl(this._field.Text),
      Start: new FormControl(this._field.Position),
      Length: new FormControl(this._field.Length),
      Format: new FormControl(this._field.Format)
    });
    this.subscription = this.formGroup.valueChanges.subscribe(result => {
      if (!this.hideUpdate) {
        this._field.Name = this.formGroup.controls.Name.value;
        this._field.Title = this.formGroup.controls.Title.value;
        this._field.Text = this.formGroup.controls.Text.value;
        this._field.Start = this.formGroup.controls.Start.value;
        this._field.Length = this.formGroup.controls.Length.value;
        this._field.Format = this.formGroup.controls.Format.value;
        this.fieldUpdated?.emit(this._field);
      }
    });
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }

  ngOnInit(): void {
  }

  @Input()
  get field(): Field {
    return this._field;
  }
  set field(value: Field) {
    this._field = value;
    this.hideUpdate = true;
    this.formGroup.controls.Name.setValue(this._field.Name);
    this.formGroup.controls.Title.setValue(this._field.Title);
    this.formGroup.controls.Text.setValue(this._field.Text);
    this.formGroup.controls.Start.setValue(this._field.Start);
    this.formGroup.controls.Length.setValue(this._field.Length);
    this.formGroup.controls.Format.setValue(this._field.Format);
    this.hideUpdate = false;
  }
  private _field: Field = new Field();

  @Input()
  get questions(): QuestionModel[] {
    return this._questions;
  }
  set questions(value: QuestionModel[]) {
    this._questions = value;
  }
  private _questions: QuestionModel[] = [];

  @Input()
  get format(): CustomFormat {
    return this._format;
  }
  set format(value: CustomFormat) {
    this._format = value;
  }
  private _format: CustomFormat = new CustomFormat();

  @Input()
  get templateProfile(): any {
    return this._templateProfile;
  }
  set templateProfile(value: any) {
    this._templateProfile = value;
  }
  private _templateProfile: any;

  @Input()
  get isLast(): boolean {
    return this._isLast;
  }
  set isLast(value: boolean) {
    this._isLast = value;
  }
  private _isLast: boolean = false;

  header(): boolean {
    return this._format && this._format.Header;
  }

  @Input()
  get task(): DataTask {
    return this._task;
  }
  set task(value: DataTask) {
    this._task = value;
  }
  private _task: DataTask = new DataTask();

  @Output()
  add: EventEmitter<Field> = new EventEmitter<Field>();

  @Output()
  fieldUpdated: EventEmitter<Field> = new EventEmitter<Field>();

  delimited(): boolean {
    if (this._task == undefined) {
      return true;
    }

    return this._task && "CSV|PIPE|TSV|XML|JSON|EXCEL".includes(this._task.Format);
  }

  isValue(): boolean {
    if (this._task == undefined) {
      return false;
    }

    return this._task.Type == "EXPORT" && this._field.Name == '(placeholder)';
  }

  selected(value: Field) {
    this.hideUpdate = true;
    this._field.Name = value.Name;
    this._field.Title = value.Title;
    this._field.Text = value.Text;
    this.formGroup.controls.Name.setValue(value.Name);
    this.formGroup.controls.Title.setValue(value.Title);
    this.formGroup.controls.Text.setValue(value.Text);
    this.fieldUpdated?.emit(this._field);
    this.hideUpdate = false;
  }

  onAdd() {
    this.add.emit(this._field);
  }
}
