import { HttpClient, HttpEventType } from "@angular/common/http";
import { baseUrl, options } from "../environments/environment";
import { Observable } from "rxjs";
import { Injectable } from "@angular/core";

export class MediaModel {
  MediaID: number = 0;
  MediaTypeID: number = 0;
  MediaUsageTypeID: number = 0;
  Name: string = "";
  Description: string = "";
  IsDeleted: boolean = false;
  Comments: string = "";
  Remark: string = "";
  Blob: any;
  FlashBlob: any;
  Snapshotblob: any;
  CreatedDate: Date = new Date();
  UserID: number = 0;
  MovBlob: any;
  T3gBlob: any;
  Mp4Blob: any;
  OggBlob: any;
  WebmBlob: any;
  LasteUpdatedDate: Date = new Date();
}

@Injectable()
export class MediaService {

  constructor(private http: HttpClient) {
  }

  getMedia(): Observable<MediaModel[]> {
    return this.http.get<MediaModel[]>(baseUrl + 'Media/GetMedia', options);
  }

  getJson(filename: string): Observable<any> {
    return this.http.get(baseUrl + 'Media/GetJson?filename=' + filename, options)
  }

  getMediaAsBase64(id: number) {
    this.http.get(baseUrl + 'Media/GetMediaBase64ById?id=' + id, options).subscribe(result => {
      return result;
    });
  }

  getMediaById(id: number): Observable<string> {
    return this.http.get(baseUrl + 'Media/GetMediaBase64ById?id=' + id, {responseType: 'text'});
  }

  setMedia(model: MediaModel) {
    this.http.post(baseUrl + 'Media/Post', model, options).subscribe(
      result => {
        model.MediaID = (result as any).MediaID;
      });
  }

  uploadMedia(form: any) {
    this.http.post(baseUrl + 'Media/Post', form, options).subscribe(
      result => {
        let model = result;
      });
  }

  uploadFile(file: File): Observable<any> {
    const formData = new FormData();
    formData.append('file', file, file.name);
    return this.http.post(baseUrl + 'Media/Post', formData, {reportProgress: true, observe: 'events'});
  }

  uploadAnyFile(file: File): Observable<any> {
    const formData = new FormData();
    formData.append('file', file, file.name);
    return this.http.post(baseUrl + 'Media/Upload', formData);
  }

  uploadBase64File(file: string): Observable<any> {
    return this.uploadBase64FileWithMediaId(file, 0);
  }

  uploadBase64FileWithMediaId(file: string, mediaId: number): Observable<any> {
    let data = { 'File': file };
    return this.http.post(baseUrl + 'Media/PostBase64?id=' + mediaId,  data, {reportProgress: true, observe: 'events'});
  }


}
