import { Component, Inject } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, FormArray } from '@angular/forms';
import { MediaService } from '../../media.Service';
import { ImportProjectSelection, ImportProjectSelections, ProjectSelectionCriteria, SurveyModel, SurveyService } from '../../survey.Service';
import { ProcessServiceModel } from '../../process.Service';
import { PanelModel } from '../../panel.Service';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-import-project',
  templateUrl: './import-project.component.html',
  styleUrl: './import-project.component.css'
})
export class ImportProjectComponent {
  formGroup: FormGroup;
  fb: FormBuilder = new FormBuilder();
  uploadFile: boolean = true;
  json: any;
  surveys: any[] = [];
  filename: string;
  error: boolean = false;
  success: boolean = false;
  showImportButton: boolean = true;
  userid: number;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    private mediaService: MediaService,
    private surveyService: SurveyService
  ) {
    this.userid = data;
  }

  onFileUploaded($event: any) {
    this.filename = $event.name;
    this.mediaService.getJson($event.name).subscribe(
      result => {
        this.json = result;
        this.surveys = this.json.Surveys;
        this.formGroup = new FormGroup({
          AllSurveys: new FormControl(false),
          Surveys: new FormArray([])
        });
        let userlist = this.formGroup.controls.Surveys as FormArray;
        for (let i = 0; i < this.surveys.length; i++) {
          let selections = [];
          if (this.hasJobMaster(this.surveys[i])) {
            selections.push("job");
          }
          if (this.hasPanel(this.surveys[i])) {
            selections.push("panel");
          }
          if (this.processes(this.surveys[i]).length > 0) {
            selections.push("process");
          }
          let form = new FormGroup({
            Selected: new FormControl(),
            Options: new FormControl(selections)
          });
          userlist.push(form);
        }

        this.uploadFile = false;
      }
    )
  }

  status(survey: any): string {
    switch (survey.Survey.Status) {
      case 1:
        return "Draft";
      case 2:
        return "Live";
      case 3:
        return "Idle";
      case 4:
        return "Completed";
      case 5:
        return "Abandon";
      case 6:
        return "Abandonned";
      default:
        return "Unknown";
    }
  }

  allSelected(): boolean {
    let surveylist = this.formGroup.controls.Surveys as FormArray;
    let someselected = false;
    let somenotselected = false;
    for (let i = 0; i < this.surveys.length; i++) {
      let form = surveylist.controls[i] as FormGroup;
      if (!form.controls.Selected.value) {
        somenotselected = true;
        continue;
      }

      someselected = true;
    }

    return someselected && !somenotselected;
  }

  patialSelection(): boolean {
    let surveylist = this.formGroup.controls.Surveys as FormArray;
    let someselected = false;
    let somenotselected = false;
    for (let i = 0; i < this.surveys.length; i++) {
      let form = surveylist.controls[i] as FormGroup;
      if (!form.controls.Selected.value) {
        somenotselected = true;
        continue;
      }

      someselected = true;
    }

    return someselected && somenotselected;
  }

  update(selected: boolean) {
    let userlist = this.formGroup.controls.Users as FormArray;
    for (let i = 0; i < this.surveys.length; i++) {
      let form = userlist.controls[i] as FormGroup;
      form.controls.Selected.setValue(selected);
    }
  }

  hasJobMaster(survey: any): boolean {
    if (survey.JobMaster == null || survey.JobMaster.JobMasterID == 0) {
      return false;
    }

    return true;
  }

  processes(survey: any): ProcessServiceModel[] {
    if (survey.ProcessServices == null) {
      return [];
    }

    return survey.ProcessServices;
  }

  hasPanel(survey: any): boolean {
    if (survey.Panel == null || survey.Panel.PanelID == 0) {
      return false;
    }

    return true;
  }

  panel(survey: any): PanelModel {
    return survey.Panel;
  }

  import() {
    this.showImportButton = false;
    let criteria = new ImportProjectSelections();
    criteria.AllSurveys = this.formGroup.controls.AllSurveys.value == true;
    criteria.Criteria = new ProjectSelectionCriteria();
    let surveylist = this.formGroup.controls.Surveys as FormArray;
    for (let i = 0; i < this.surveys.length; i++) {
      let form = surveylist.controls[i] as FormGroup;
      if (!form.controls.Selected.value) {
        continue;
      }

      let projectCriteria = new ImportProjectSelection();
      projectCriteria.Criteria = new ProjectSelectionCriteria();
      projectCriteria.SurveyId = this.surveys[i].Survey.SurveyID;
      let selections = form.controls.Options.value as [];
      for (let j = 0; j < selections.length; j++) {
        switch (selections[j]) {
          case "job":
            projectCriteria.Criteria.JobMaster = true;
            break;
          case "panel":
            projectCriteria.Criteria.Respondents = true;
            break;
          case "process":
            projectCriteria.Criteria.DataIO = true;
            break;
        }
      }

      criteria.Surveys.push(projectCriteria);
    }

    this.surveyService.importSurveys(this.filename, criteria, this.userid).subscribe({
      next: result => {
        this.success = true;
      },
      error: err => {
        this.error = true;
      }
    });

  }
}
