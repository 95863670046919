import { Component, OnInit } from '@angular/core';
import { Validators, FormBuilder, FormGroup, FormArray } from '@angular/forms';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { BaseQuestion, DataModel, InterviewService, ParameterModel, QuestionService } from '../../question.service';

@Component({
  selector: 'app-maxdiff',
  templateUrl: './maxdiff.component.html',
  styleUrls: ['./maxdiff.component.css']
})
export class MaxdiffComponent extends BaseQuestion implements OnInit {
  constructor(private breakpointObserver: BreakpointObserver, private qs: QuestionService, private is: InterviewService) {
    super(qs, is, undefined, undefined, new DataModel("maxDiff"));
  }

  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
    .pipe(map(result => result.matches));

  public maxdiffFormGroup: FormGroup = new FormGroup({});
  public optionFormGroup: FormGroup = new FormGroup({});

  ngOnInit() {
    this.maxdiffFormGroup = this.fb.group({
      maxdiffFormArray: this.fb.array([this.initQuestion()])
    });
    // Remember the BaseQuestion does the subscription
    this.maxdiffFormGroup.valueChanges.subscribe(x => {
      let array = this.maxdiffFormGroup.controls.maxdiffFormArray as FormArray;
      let form = array.controls[0] as FormGroup;
      this.model.Banner = form.controls.banner.value;
      this.model.Parameters = [];
      this.model.Parameters.push(this.createParameterModel("least", form.controls.leastInput.value));
      this.model.Parameters.push(this.createParameterModel("most", form.controls.mostInput.value));
      this.model.Parameters.push(this.createParameterModel("optionCount", form.controls.optionCountInput.value.toString()));
      this.model.Parameters.push(this.createParameterModel("setCount", form.controls.setCountInput.value.toString()));
    });
  }

  createParameterModel(name: string, value: string): ParameterModel {
    let model = new ParameterModel();
    model.Name = name;
    model.Value = value;
    return model;
  }

  get optionArr(): FormArray {
    return this.optionFormGroup.get('questionOption') as FormArray;
  }

  initOptionRow(): FormGroup {
    return this.fb.group({
      qOption: ['', Validators.required]
    });
  }

  onSubmit(): void {
  }

  addNewOption() {
    this.optionArr.push(this.initOptionRow());
  }

  removeOption(index: number) {
    console.log(this.optionArr.length);
    if (this.optionArr.length > 1) {
      this.optionArr.removeAt(index);
    }
  }

  get questionArr(): FormArray | null {
    if (this.maxdiffFormGroup) {
      return this.maxdiffFormGroup.get('maxdiffFormArray') as FormArray;
    }

    return null;
  }

  getParameter(name: string): string {
    for (let i = 0; i < this.model.Parameters.length; i++) {
      if (this.model.Parameters[i].Name === name) {
        return this.model.Parameters[i].Value;
      }
    }

    return "";
  }

  initQuestion() {
    return this.fb.group({
      qNumber: [this.model.QuestionName, Validators.required],
      banner: [this.model.Banner],
      leastInput: [this.getParameter("least"), Validators.required],
      mostInput: [this.getParameter("most"), Validators.required],
      optionCountInput: [this.getParameter("optionCount"), Validators.required],
      setCountInput: [this.getParameter("setCount"), Validators.required]
    });
  }
}
