import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { version } from "../../environments/environment";

@Component({
  selector: 'app-invite-layout',
  templateUrl: './invite-layout.component.html',
  styleUrls: ['./invite-layout.component.css']
})
export class InviteLayoutComponent implements OnInit {

  currentVersion: string;
  currentYear = new Date().getFullYear();

  constructor(private breakpointObserver: BreakpointObserver) {
    this.currentVersion = version;
  }

  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
    .pipe(map(result => result.matches));

  ngOnInit(): void {
  }

}
