import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { baseUrl, options } from "../environments/environment";
import { InterviewModel, RuleList } from './question.service';
import { PeriodStatusTotal } from './survey.Service';
import { JsonCyclic } from './utils';

export class ChartData {
  SurveyId: number = 0;
  VariableName: string = "";
  Response: string = "";
  Answer: number = 0;
  LastAnswered: Date = new Date();
}

export class GroupingChartData {
  SurveyId: number = 0;
  PProfileVariable: string = "";
  VariableName: string = "";
  Response: string = "";
  Answer: number = 0;
  LastAnswered: Date = new Date();
}

export class BasicData {
  SurveyId: number = 0;
  PanellistReferenceId: number =0;
  SystemVariable: boolean = false;
  VariableName: string = "";
  CommonName: string = "";
  Response?: object;
  AskedDate: Date = new Date();
  AnsweredDate: Date = new Date();
  ReportHelper: ReportHelper = new ReportHelper();
}

export class PanellistReferenceEntry {
  PanellistProfileReferenceID: number;
  Created: Date;
  Expires: Date;
  SurveyID: number;
  Serial: number;
  Guid: string;
  InviteID: number;
  BatchID: number;
  StatusID: number;
  PAnellistID: number;
  Processed: boolean;
  Started: Date;
  Stopped: Date;
}

export class ReportHelper {
  Parts: string[] = [];
}

@Injectable()
export class CaseDataService {
  constructor(private http: HttpClient) {
  }

  findActiveNumber(number: string): Observable<PanellistReferenceEntry[]> {
    return this.http.get<PanellistReferenceEntry[]>(baseUrl + 'casedata/FindActiveNumber?number=' + number, options);
  }

  getMultiplateChoiceData(surveyId: number, variableName: string, test: boolean): Observable<ChartData[]> {
    return this.http.get<ChartData[]>(baseUrl + 'casedata/MultipleChoice?surveyid=' + surveyId + '&variableName=' + variableName + '&test=' + test, options);
  }

  getMultiplateChoiceDataGrouping(surveyId: number, variableName: string, test: boolean): Observable<GroupingChartData[]> {
    return this.http.get<GroupingChartData[]>(baseUrl + 'casedata/MultipleChoiceGrouping?surveyid=' + surveyId + '&variableName=' + variableName + '&test=' + test, options);
  }

  getTableData(surveyId: number, variableName: string, test: boolean): Observable<ChartData[]> {
    return this.http.get<ChartData[]>(baseUrl + 'casedata/Table?surveyid=' + surveyId + '&variableName=' + variableName + '&test=' + test, options);
  }

  getMatrixTableData(surveyId: number, variableName: string, columns: number, test: boolean): Observable<ChartData[]> {
    return this.http.get<ChartData[]>(baseUrl + 'casedata/MatrixTable?surveyid=' + surveyId + '&variableName=' + variableName + '&columns=' + columns + '&test=' + test, options);
  }

  getExcel(surveyId: number, model: string, test: boolean): Observable<any> {
    return this.http.post(baseUrl + 'casedata/Excel?surveyid=' + surveyId + '&test=' + test, model, {
      responseType: "blob",
      headers: new HttpHeaders().append("Content-Type", "application/json")
    });
  }

  getTestDataCount(surveyId: number): Observable<any> {
    return this.http.get(baseUrl + 'casedata/GetTestDataCount?surveyid=' + surveyId, options);
  }

  deleteTestCaseData(surveyId: number) {
    this.http.delete(baseUrl + 'casedata/DeleteTestCaseData?surveyid=' + surveyId, options).subscribe();
  }

  deleteLiveCaseData(surveyId: number) {
    this.http.delete(baseUrl + 'casedata/DeleteLiveCaseData?surveyid=' + surveyId, options).subscribe();
  }

  getWordCloud(surveyId: number, variableName: string, test: boolean): Observable<ChartData[]> {
    return this.http.get<ChartData[]>(baseUrl + 'casedata/WordCloud?surveyid=' + surveyId + '&variableName=' + variableName + '&test=' + test, options);
  }

  getResults(surveyId: number, status: number, page: number, size: number, model: InterviewModel, rules: RuleList): Observable<BasicData[][]> {
    let dataModel = { InterviewModel: model, RuleList: rules };
    return this.http.post<BasicData[][]>(baseUrl + 'casedata/getResults?surveyId=' + surveyId + "&status=" + status + "&page=" + page + "&size=" + size, JsonCyclic.toJson(dataModel) , options);
  }

  getResultsWithErrors(surveyId: number, status: number, page: number, size: number, model: InterviewModel, rules: RuleList): Observable<BasicData[][]> {
    let dataModel = { InterviewModel: model, RuleList: rules };
    return this.http.post<BasicData[][]>(baseUrl + 'casedata/getResultsWithErrors?surveyId=' + surveyId + "&status=" + status + "&page=" + page + "&size=" + size, JsonCyclic.toJson(dataModel), options);
  }

  getTotalResults(surveyId: number, status: number, model: InterviewModel, rules: RuleList): Observable<number> {
    let dataModel = { InterviewModel: model, RuleList: rules };
    return this.http.post<number>(baseUrl + 'casedata/getTotals?surveyId=' + surveyId + "&status=" + status, JsonCyclic.toJson(dataModel), options);
  }

  getPeriodTotalResults(surveyId: number, period: number): Observable<PeriodStatusTotal[]> {
    return this.http.get<PeriodStatusTotal[]>(baseUrl + 'casedata/getPeriodTotals?surveyId=' + surveyId + "&period=" + period, options);
  }

  getTextResults(surveyId: number, variableName: string, keywords: string[]) {
    return this.http.post<BasicData[][]>(baseUrl + 'casedata/getTextResults?surveyId=' + surveyId + "&variableName=" + variableName, keywords, options);
  }

  getTotals(surveyId: number): Observable<any> {
    return this.http.get(baseUrl + 'casedata/getTotals?surveyId=' + surveyId, options);
  }

  updateReference(entry: PanellistReferenceEntry): Observable<any> {
    return this.http.patch(baseUrl + 'casedata/update', entry, options);
  }

  abandonReference(entry: PanellistReferenceEntry): Observable<any> {
    return this.http.patch(baseUrl + 'casedata/abandon', entry, options);
  }
}
