<div class="wa-main-container">
  <div class="wa-main-content">
    <div>
      <h3>Welcome to the DIY Surveys WhatsApp Business Solution</h3>
      <p>
        In order to start using the DIY Surveys WhatsApp Business Solution you need
        to complete the following form to gain a WhatsApp Access Token.
      </p>
    </div>
    <div *ngIf="display" [formGroup]="formGroup">
      <div>
        <h3>Company Profile</h3>
        <p>
          This refers to the company profile that will be using WhatsApp.
        </p>
      </div>
      <div>
        <mat-form-field appearance="outline" class="fullWidth">
          <mat-label>Company Name</mat-label>
          <input matInput placeholder="Company Name" formControlName="CompanyName">
        </mat-form-field>
      </div>
      <div>
        <mat-form-field appearance="outline" class="fullWidth">
          <mat-label>Company Website</mat-label>
          <input matInput placeholder="Company Website" formControlName="Website">
        </mat-form-field>
      </div>
      <div>
        <mat-form-field appearance="outline" class="fullWidth">
          <mat-label>Business Vertical</mat-label>
          <input matInput placeholder="Business Vertical" formControlName="Vertical">
        </mat-form-field>
      </div>
      <div>
        <mat-form-field appearance="outline" class="fullWidth">
          <mat-label>Your Region</mat-label>
          <mat-select formControlName="Region">
            <mat-option value="Europe">Europe &amp; the Middle East &amp; Africa (EMA)</mat-option>
            <mat-option value="North Asia">North Asia</mat-option>
            <mat-option value="South East Asia">South East Asia (SEA)</mat-option>
            <mat-option value="Japan">Japan</mat-option>
            <mat-option value="North Amaerica">North America</mat-option>
            <mat-option value="LATAM">LATAM</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <mat-divider class="wa-content-divider"></mat-divider>
      <div>
        <h3>
          Company Facebook Details
        </h3>
        <p>
          This must be the Business Manager ID of the Brand that will be using WhatsApp,
          NOT a partner or agency. To locate your ID,
          please visit this link: <a target="_blank" href="https://www.facebook.com/business/help/1181250022022158">https://www.facebook.com/business/help/1181250022022158</a> for more information
        </p>
      </div>
      <div>
        <mat-form-field appearance="outline" class="fullWidth">
          <mat-label>Company Facebook Business Manager ID</mat-label>
          <input matInput placeholder="Company Facebook Business Manager ID" formControlName="CFBMID">
        </mat-form-field>
      </div>
      <div>
        <mat-form-field appearance="outline" class="fullWidth">
          <mat-label>Facebook URL</mat-label>
          <input matInput placeholder="Company Facebook URL" formControlName="CFBURL">
        </mat-form-field>
      </div>
      <mat-divider class="wa-content-divider"></mat-divider>
      <div>
        <h3>Messaging Profile</h3>
        <mat-form-field appearance="outline" class="fullWidth">
          <mat-label>Message Type</mat-label>
          <mat-select formControlName="MessageType">
            <mat-option value="Notification">Notification (e.g. 2FA or appointment reminders) only</mat-option>
            <mat-option value="Conversational">Conversational messaging (e.g. Customer Care, ChatBot or Support Messages) only</mat-option>
            <mat-option value="Notification and Conversational">Both of the above</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div>
        <mat-form-field appearance="outline" class="fullWidth">
          <mat-label>Example Messages</mat-label>
          <textarea matInput placeholder="Example Text Messages" formControlName="Examples"></textarea>
        </mat-form-field>
      </div>
      <div>
        <mat-form-field appearance="outline" class="fullWidth">
          <mat-label>Expected Monthly Spend</mat-label>
          <mat-select formControlName="Spend">
            <mat-option [value]="100">100 (EUR) / month</mat-option>
            <mat-option [value]="1000">1,000 (EUR) / month</mat-option>
            <mat-option [value]="10000">10,000 (EUR) / month</mat-option>
            <mat-option [value]="100000">100,000 (EUR) / month</mat-option>
            <mat-option [value]="1000000">1,000.000 (EUR) / month</mat-option>
            <mat-option [value]="1000001">Over 1,000,000 (EUR)s / month</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div>
        <mat-form-field appearance="outline" class="fullWidth">
          <mat-label>Monthly Message Volume</mat-label>
          <mat-select formControlName="Volume">
            <mat-option value="1000">Up to 1,000 Messages / month</mat-option>
            <mat-option value="10000">Up to 10,000 Messages / month</mat-option>
            <mat-option value="100000">Up to 100,000 Messages / month</mat-option>
            <mat-option value="1000000">Up to 1,000.000 Messages / month</mat-option>
            <mat-option value="1000001">Over 1,000,000 Messages / month</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div>
        <mat-form-field appearance="outline" class="fullWidth">
          <mat-label>Brand Name</mat-label>
          <input matInput placeholder="Brand Name" formControlName="BrandName">
        </mat-form-field>
      </div>
      <div *ngIf="isAdminUser">
        <div>
          <h3>Admin Actions</h3>
          <mat-form-field appearance="outline" class="fullWidth">
            <mat-label>Status</mat-label>
            <mat-select formControlName="Status">
              <mat-option value="0">Pending</mat-option>
              <mat-option value="1">Approved</mat-option>
              <mat-option value="2">Rejected</mat-option>
              <mat-option value="3">Active</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div *ngIf="formGroup.controls.Status.value == 0">
          You need to check that this application has been approved.
        </div>
        <div *ngIf="formGroup.controls.Status.value == 1">
          You need to make sure the WhatsApp Access Token has been setup for this account.
          <button mat-raised-button color="primary" (click)="checkWhatsAppAccessToken()" [disabled]="formGroup.invalid">Check WhatsApp Access Token</button>
        </div>
        <div *ngIf="formGroup.controls.Status.value == 2">
          You need to make some notes in the Status Message to let the client know why it has been rejected.
        </div>
        <div *ngIf="formGroup.controls.Status.value == 3">
          Before you use this status, be sure that the WhatsApp Access Token has been successfully set up.
          <button mat-raised-button color="primary" (click)="checkWhatsAppAccessToken()" [disabled]="formGroup.invalid">Check WhatsApp Access Token</button>
        </div>
        <div class="mt-10">
          <mat-form-field appearance="outline" class="fullWidth">
            <mat-label>Status Message</mat-label>
            <textarea matInput placeholder="Status Message" formControlName="StatusMessage"></textarea>
          </mat-form-field>
        </div>
      </div>
      <div>
        <button mat-raised-button color="primary" (click)="onSubmit()" type="submit" [disabled]="formGroup.invalid">{{(isAdminUser ? 'Update' : 'Submit') + ' Application'}}</button>
      </div>
    </div>
  </div>
</div>
