import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ThemeService {

  private theme?: string;

  private logo?: string;

  private background?: string;

  private headerColor?: string;

  private navColor?: string;

  private controlColor?: string;

  constructor() { }

  setTheme(color: string) {
    this.theme = color;
  }

  getTheme(): string | undefined {
    let temp = this.theme;
    if (this.theme == undefined) {
      return 'default';
    }

    return temp;
  }

  setLogo(url: string) {
    this.logo = url;
  }

  getLogo(): string | undefined {
    let temp = this.logo;
    if (this.logo == undefined) {
      return 'assets/img/logo.svg';
    } else {
      return temp;
    }
  }

  setBackground(color: string) {
    this.background = color;
  }

  getBackground(): string | undefined {
    let temp = this.background;
    if (this.background == undefined) {
      return '';
    } else {
      return temp;
    }
  }

  setHeaderColor(color: string) {
    this.headerColor = color;
  }

  getHeaderColor(): string | undefined {
    let temp = this.headerColor;
    if (this.headerColor == undefined) {
      return '';
    } else {
      return temp;
    }
  }

  setNavColor(color: string) {
    this.navColor = color;
  }

  getNavColor(): string | undefined {
    let temp = this.navColor;
    if (this.navColor == undefined) {
      return '';
    } else {
      return temp;
    }
  }

  setControlColor(color: string) {
    this.controlColor = color;
  }

  getControlColor(): string | undefined {
    let temp = this.controlColor;
    if (this.controlColor == undefined) {
      return '';
    } else {
      return temp;
    }
  }
}
