<div class="fullWidth">
  <div class="leftFloat">
    <strong>Download Results</strong>
  </div>
  <div class="rightFloat">
    <button mat-icon-button mat-dialog-close (click)="onNoClick()">
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <div class="clearBoth"></div>
  <div class="padding-10">
    <mat-button-toggle-group #group="matButtonToggleGroup" [value]="exportType">
      <mat-button-toggle aria-label="Drafts" (click)="setType('excel')" value="excel">
        Excel
      </mat-button-toggle>
      <mat-button-toggle aria-label="Scheduled" (click)="setType('pdf')" value="pdf">
        PDF
      </mat-button-toggle>
    </mat-button-toggle-group>
    <div *ngIf="exportType == 'excel'" class="mt-10">
      <div class="dwnld-rprt-icon-div">
        <i class="fa fa-file-excel-o dwnld-rprtExcl-icon"></i>
      </div>
      <div class="dwnld-rprt-txt-div">
        <p>
          <strong>Excel Report</strong>
        </p>
        <p>
          This is an Excel file that can be used for general review of the data.
        </p>

      </div>
    </div>
    <div *ngIf="exportType == 'pdf'" class="mt-10">
      <div class="dwnld-rprt-icon-div">
        <i class="fa fa-file-pdf-o dwnld-rprtPdf-icon"></i>
      </div>
      <div class="dwnld-rprt-txt-div">
        <p>
          <strong>PDF Report</strong>
        </p>
        <p>
          This is a PDF file that can be used to share pre formatted results.
        </p>
      </div>
    </div>
  </div>
  <div class="dwnld-rprt-button-div">
    <button mat-raised-button color="primary" (click)="download()">
      Download
      <mat-icon>download_file</mat-icon>
    </button>
  </div>
</div>
