  <div class="rightFloat">
    <button mat-icon-button mat-dialog-close>
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <div class="align-center-center-flex fill-flex">
    <mat-card class="settings-card">
      <form class="settings-forgotPwd-form column-flex flex-stretch-column" [formGroup]="formGroup" (ngSubmit)="onSubmit()">
        <h1 class="txt-align-center">Change Password</h1>
        <div *ngIf="displayError">
          <div class="alert alert-danger" role="alert">
            {{errorMsg}}
          </div>
        </div>
        <p class="txt-align-center">
          Enter your user account's verified email address and we will send you a password reset link.
        </p>
        <div *ngIf="displaySuccess">
          <div class="alert alert-success" role="alert">
            Please check your email for a password reset link
          </div>
        </div>
        <mat-form-field appearance="outline">
          <mat-label>Enter your email address</mat-label>
          <input matInput placeholder="Enter your email address" formControlName="username">
        </mat-form-field>
        <button mat-raised-button type="submit" class="mt-5" color="primary">Submit</button>
      </form>
    </mat-card>
  </div>
