<div *ngIf="isHandset$ == undefined || !isHandset$">
  <div *ngIf="question" class="display-flex align-space-between-top-flex fill-flex">
    <div *ngFor="let group of groups; let i = index" class="group-container">
      <div *ngIf="group.name !== ''">{{group.name}}</div>
      <div *ngIf="group.name === ''">&nbsp;</div>
      <div class="bucket-list" [id]="groupNames[i]" cdkDropList [cdkDropListData]="group.ranks" [cdkDropListConnectedTo]="groupNames" (cdkDropListDropped)="drop($event)">
        <div class="bucket-box" *ngFor="let rank of groups[i].ranks; let j = index" cdkDrag>{{rank.label}}</div>
      </div>
    </div>
  </div>
</div>
<div *ngIf="isHandset$">
  <div *ngIf="question">
    <div *ngFor="let group of groups; let i = index" class="bucket-group-container">
      <div *ngIf="group.name !== ''">{{group.name}}</div>
      <div *ngIf="group.name === ''">&nbsp;</div>
      <div class="bucket-list" [id]="groupNames[i]" cdkDropList [cdkDropListData]="group.ranks" [cdkDropListConnectedTo]="groupNames" (cdkDropListDropped)="drop($event)">
        <div class="bucket-box" *ngFor="let rank of groups[i].ranks; let j = index" cdkDrag>{{rank.label}}</div>
      </div>
    </div>
  </div>
</div>
