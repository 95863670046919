<mat-grid-list *ngIf="cells" cols="3" rowHeight="4rem">
  <mat-grid-tile #cell *ngFor="let cell of cells; let i = index" colspan="1">
    <span *ngIf="cell.isLabel">{{cell.label}}</span>
    <mat-radio-group *ngIf="cell.isCategory && cell.groupName.endsWith('_low')" [(ngModel)]="low" class="vertical-radio-group" [name]="cell.groupName">
      <mat-radio-button [value]="cell.category.Name" (click)="checkOtherSide(cell.category.Name, cell.groupName)"></mat-radio-button>
    </mat-radio-group>
    <mat-radio-group *ngIf="cell.isCategory && cell.groupName.endsWith('_high')" [(ngModel)]="high" class="vertical-radio-group" [name]="cell.groupName">
      <mat-radio-button [value]="cell.category.Name" (click)="checkOtherSide(cell.category.Name, cell.groupName)"></mat-radio-button>
    </mat-radio-group>
  </mat-grid-tile>
</mat-grid-list>
