<div class="fullWidth dialog-title">
  <div class="leftFloat">
    <h3>{{title}}</h3>
  </div>
  <div class="rightFloat">
    <button mat-icon-button matDialogClose="no" matTooltip="Close" matTooltipPosition="below">
      <mat-icon class="dialog-close-icon">close</mat-icon>
    </button>
  </div>
</div>
<div class="clearBoth"></div>
<div *ngIf="loaded else loading" class="main-div display-flex align-center-center-flex fill-flex">
  <form class="column-flex align-center-center-flex margin-10" [formGroup]="formGroup">
    <div>
      <div class="wa-main-content">
        <mat-form-field appearance="outline">
          <mat-label>Survey Title</mat-label>
          <input formControlName="SurveyTitle" required="true" matInput placeholder="Survey Title *">
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>Survey Subtitle</mat-label>
          <input formControlName="SurveySubtitle" required="true" matInput placeholder="Survey Subtitle *">
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>Description</mat-label>
          <textarea formControlName="SurveyDescription" matInput placeholder="Description"></textarea>
        </mat-form-field>
        <div>
          <div>
            What channel are you targeting for the survey experience:
          </div>
          <mat-button-toggle-group #group="matButtonToggleGroup" formControlName="Channel">
            <mat-button-toggle [value]="0">Web</mat-button-toggle>
            <mat-button-toggle [value]="1">SMS</mat-button-toggle>
            <mat-button-toggle [value]="5">WhatsApp</mat-button-toggle>
            <mat-button-toggle [value]="4">SMS Invite</mat-button-toggle>
            <mat-button-toggle [value]="3">WhatsApp Invite</mat-button-toggle>
          </mat-button-toggle-group>
          <div>
            &nbsp;
          </div>
          <div *ngIf="getChannel(0)">
            <h3>Web</h3>
            <p>
              An online DIY survey that you send via our email, SMS, WhatsApp invitation or embed in a QR code. Alternatively you can create the Survey in DIY and send out the survey url link via your own email system.
            </p>
          </div>
          <div *ngIf="getChannel(1)">
            <h3>SMS</h3>
            <p>
              A 2-way SMS DIY survey that you send via our SMS to mobile devices and where each question and reply is an SMS question or answer.
            </p>
          </div>
          <div *ngIf="getChannel(5)">
            <h3>WhatsApp</h3>
            <p>
              A 2-way WhatsApp DIY survey that you send via our WhatsApp to mobile devices and where each question and reply is an WhatsApp question or answer.
            </p>
          </div>
          <div *ngIf="getChannel(4)">
            <h3>SMS Invite</h3>
            <p>
              Create a survey in your own survey software but send your survey url link out on our SMS.
            </p>
          </div>
          <div *ngIf="getChannel(3)">
            <h3>WhatsApp Invite</h3>
            <p>
              Create a survey in your own survey software but send your survey url link out on our WhatsApp.
            </p>
          </div>

        </div>
        <mat-form-field *ngIf="isAdmin()" class="fullWidth mt-10" appearance="outline">
          <mat-label>Survey Category</mat-label>
          <mat-chip-grid #chipGrid aria-label="Survey Category Selection">
            <mat-chip-row *ngFor="let category of categories"
                          [removable]="true"
                          (removed)="remove(category)">
              {{category}}
              <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
            </mat-chip-row>
            <input placeholder="Categories..."
                   #categoryInput
                   [formControl]="categoryCtrl"
                   [matAutocomplete]="auto"
                   [matChipInputFor]="chipGrid"
                   [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                   (matChipInputTokenEnd)="add($event)">
          </mat-chip-grid>
          <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
            <mat-option *ngFor="let category of filteredCategories | async" [value]="category">
              {{category}}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
        <app-image-crop *ngIf="isAdmin()" #imageCropper [mediaId]="survey.MediaID"></app-image-crop>
      </div>
    </div>
    <div class="margin-5 fullWidth">
      <mat-divider></mat-divider>
    </div>
    <button mat-raised-button color="primary" (click)="onSubmit()" type="submit" [disabled]="formGroup.invalid">{{buttonName}}</button>
  </form>
</div>
<ng-template #loading>
  <div class="loading-container">
    <div class="loading-content">
      <div class="spinner-div"><mat-spinner [diameter]="25"></mat-spinner></div>
      <div class="spinner-text"> Please Wait...</div>
    </div>
  </div>
</ng-template>

