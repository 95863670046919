import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormArray, Validators } from '@angular/forms';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { BaseQuestion, CategoryModel, DataModel, InterviewService, LabelModel, QuestionService } from '../../question.service';

@Component({
  selector: 'app-rating',
  templateUrl: './rating.component.html',
  styleUrls: ['./rating.component.css']
})
export class RatingComponent extends BaseQuestion implements OnInit {
  questions = [
    { id: 'Question1', value: 'Question 1' },
    { id: 'Question2', value: 'Question 2' },
    { id: 'Question3', value: 'Question 3' }
  ];

  ratingTypes = [
    { id: 'star', value: 'Stars' },
    { id: 'slider', value: 'Slider' },
    { id: 'radioButtons', value: 'Radio buttons' },
    { id: 'links', value: 'Links' },
    { id: 'freeText', value: 'Free text' }
  ];

  showRatingOptions: boolean = false;

  constructor(private breakpointObserver: BreakpointObserver, private qs: QuestionService, private is: InterviewService) {
    super(qs, is, undefined, undefined, new DataModel("rating"));
  }

  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
    .pipe(map(result => result.matches));

  public ratingFormGroup: FormGroup = new FormGroup({});
  public optionFormGroup: FormGroup = new FormGroup({});

  ngOnInit() {
    this.ratingFormGroup = this.fb.group({
      ratingFormArray: this.fb.array([this.initQuestion()])
    });

    this.optionFormGroup = this.fb.group({
      rowOptions: this.fb.array([this.initRowOption()])
    });

    this.ratingFormGroup.valueChanges.subscribe(x => {
      let array = this.ratingFormGroup.controls.ratingFormArray as FormArray;
      let form = array.controls[0] as FormGroup;
      if (this.model.QuestionName !== form.controls.qNumber.value) {
        this.model.QuestionName = form.controls.qNumber.value;
        this.model.QuestionNameTouched = true;
      }
      this.model.Banner = form.controls.banner.value;
      this.model.Min = Number(form.controls.minValue.value);
      this.model.Max = Number(form.controls.maxValue.value);
      this.model.LowLabel = form.controls.minLabel.value;
      this.model.LowLanguageLabel = this.setLanguageLabel(this.model.LowLanguageLabel, this.model.LowLabel);
      this.model.HighLabel = form.controls.maxLabel.value;
      this.model.HighLanguageLabel = this.setLanguageLabel(this.model.HighLanguageLabel, this.model.HighLabel);
      this.model.Step = Number(form.controls.step.value);
      if (this.model.NotApplicable) {
        if (this.model.Categories.List == null) {
          this.model.Categories.List = [];
        }

        if (this.model.Categories.List.length == 0) {
          this.model.Categories.List.push(new CategoryModel(0, "NA", form.controls.naLabel.value));
        }
        else {
          this.model.Categories.List[0] = new CategoryModel(0, "NA", form.controls.naLabel.value);
        }
      }
      else {
        this.model.Categories.List = [];
      }
    });
  }

  setLanguageLabel(labels, text) {
    let label = new LabelModel();
    label.Label = text;
    label.Language = this.model.Interview?.DefaultLanguage ?? "en-GB";
    if (label.Language == "") {
      label.Language = "en-GB";
    }

    if (labels == null) {
      labels = [];
    }

    if (labels.length == 0) {
      labels.push(label);
    }
    else {
      labels[0] = label;
    }

    return labels;
  }

  initQuestion() {
    let naLabel = "N/A";
    if (this.model.Categories.List?.length > 0) {
      naLabel = this.model.Categories.List[0].Label;
    }

    this.formGroup = this.fb.group({
      qNumber: [this.model.QuestionName, Validators.required],
      banner: [this.model.Banner],
      minValue: [this.model.Min],
      maxValue: [this.model.Max],
      minLabel: [this.model.LowLabel],
      maxLabel: [this.model.HighLabel],
      step: [this.model.Step],
      naLabel: [naLabel]
    });
    return this.formGroup;
  }

  get rowOptionArr(): FormArray {
    return this.optionFormGroup.get('rowOptions') as FormArray;
  }

  initRowOption() {
    return this.fb.group({
      valueInput: ['', Validators.required],
      labelInput: ['', Validators.required]
    });
  }

  onSubmit(): void {
  }

  addNewRowOption() {
    this.rowOptionArr.push(this.initRowOption());
  }

  removeRowOption(index: number) {
    console.log(this.rowOptionArr.length);
    if (this.rowOptionArr.length > 1) {
      this.rowOptionArr.removeAt(index);
    }
  }

  get questionArr(): FormArray | null {
    if (this.ratingFormGroup) {
      return this.ratingFormGroup.get('ratingFormArray') as FormArray;
    }

    return null;
  }

  showRatingOptionsRow() {
    if (this.showRatingOptions === false) {
      this.showRatingOptions = true;
    }
  }
}
