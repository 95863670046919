import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { InterviewService } from '../question.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ProcessService, ProcessServiceModel } from '../process.Service';
import { SurveyModel } from '../survey.Service';
import { Router } from '@angular/router';
import { CaseDataService } from '../casedata.Service';

@Component({
  selector: 'app-interview-test',
  templateUrl: './interview-test.component.html',
  styleUrls: ['./interview-test.component.css']
})
export class InterviewTestComponent implements OnInit {
  survey: SurveyModel;
  jsonString: string = "";
  show = false;
  error = false;
  errorMsg: string = "";
  formGroup: FormGroup;
  isProcessing = false;
  process: ProcessServiceModel = new ProcessServiceModel();
  viewReport = false;
  testDataCount = 0;

  constructor(private dialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private router: Router,
    private processService: ProcessService,
    private casedataService: CaseDataService,
    private interviewService: InterviewService) {
    this.survey = data.survey;
    this.casedataService.getTestDataCount(this.survey.SurveyID).subscribe(
      result => { this.testDataCount = result.Count; }
    );
    this.formGroup = new FormGroup({
      Responses: new FormControl("5", [Validators.required])
    });
  }

  ngOnInit(): void {
  }

  runTest(): void {
    this.processService.interviewTest(this.survey.SurveyID, 1, this.survey.Name, this.formGroup.controls.Responses.value, [], []).subscribe(
      result => {
        this.isProcessing = true;
        this.process = result;
      });
  }

  onTestCompleted(event: any) {
    this.isProcessing = false;
    if (event.process.StatusId == 4) {
      return;
    }

    this.viewReport = true;
  }

  viewResults() {
    this.dialogRef.close();
    this.router.navigate(["/reports", this.survey.SurveyID, "testreport"]);
  }
}
