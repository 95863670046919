<div [formGroup]="formGroup">
  <div formArrayName="element">
    <div class="fieldRowWithPadding window-list" *ngFor="let opt of optionArr.controls; let i = index" [formGroupName]="i">
      <div>
        <mat-form-field appearance="outline" class="window-from-field">
          <mat-label> From </mat-label>
          <input matInput [ngxTimepicker]="picker" placeholder="From" formControlName="From">
          <ngx-material-timepicker #picker></ngx-material-timepicker>
        </mat-form-field>&nbsp;
      </div>
      <div>
        <mat-form-field appearance="outline" class="window-from-field" >
          <mat-label> To </mat-label>
          <input matInput [ngxTimepicker]="picker2" placeholder="To" formControlName="To">
          <ngx-material-timepicker #picker2></ngx-material-timepicker>
        </mat-form-field>&nbsp;
      </div>
      <div>
        for the following days&nbsp;
      </div>
      <div>
        <mat-button-toggle-group #group="matButtonToggleGroup" multiple formControlName="Days">
          <mat-button-toggle value="sun">Sun</mat-button-toggle>
          <mat-button-toggle value="mon">Mon</mat-button-toggle>
          <mat-button-toggle value="tue">Tue</mat-button-toggle>
          <mat-button-toggle value="wed">Wed</mat-button-toggle>
          <mat-button-toggle value="thu">Thu</mat-button-toggle>
          <mat-button-toggle value="fri">Fri</mat-button-toggle>
          <mat-button-toggle value="sat">Sat</mat-button-toggle>
        </mat-button-toggle-group>
      </div>
      <div>
        <button mat-line-end mat-mini-fab matTooltip="Add" matTooltipPosition="above" (click)="addNewRow(i)">
          <mat-icon class="window-add-del-icon">add</mat-icon>
        </button>
      </div>
      <div>
        <button mat-line-end mat-mini-fab matTooltip="Remove" matTooltipPosition="above" (click)="deleteRow(i)">
          <mat-icon class="window-add-del-icon">remove</mat-icon>
        </button>
      </div>
    </div>
  </div>
</div>
