import { Component, Inject } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { plainToClass } from 'class-transformer';
import { InterviewService, LibraryQuestionModel, QuestionModel, QuestionService } from '../../question.service';

@Component({
  selector: 'app-add-question',
  templateUrl: './add-question.component.html',
  styleUrls: ['./add-question.component.css']
})
export class AddQuestionComponent {
  questions = [
    {
      name: 'information', icon: 'info', title: 'Survey Introduction', description: 'Introduces survey to respondent. Can also be used anywhere in the survey to provide information.',
      channels: ['sms', 'whatsapp', 'web'], category: ['general'], allowInBlock: true, model: null
    },
    {
      name: 'textbox', icon: 'text_fields', title: 'Free Text', description: 'Respondent replies with text comment.',
      channels: ['sms', 'whatsapp', 'web'], category: ['general', 'popular'], allowInBlock: true, model: null
    },
    {
      name: 'email', icon: 'email', title: 'Email Address', description: 'Respondent replies with Email Address.',
      channels: ['sms', 'whatsapp', 'web'], category: ['general'], allowInBlock: true, model: null
    },
    {
      name: 'mcma', icon: 'check_box', title: 'Multiple Answer', description: 'Respondent can choose multiple answer options.',
      channels: ['sms', 'whatsapp', 'web'], category: ['general', 'popular'], allowInBlock: true, model: null
    },
    {
      name: 'mcsa', icon: 'radio_button_checked', title: 'Single Answer', description: 'Respondent can choose one answer option.',
      channels: ['sms', 'whatsapp', 'web'], category: ['general', 'popular'], allowInBlock: true, model: null
    },
    {
      name: 'matrix', icon: 'apps', title: 'Matrix', description: 'A group of multiple-choice question displayed in a grid of rows (questions asked to the respondent) and contains (answers that apply to each question in the row).',
      channels: ['web'], category: ['general', 'popular'], allowInBlock: true, model: null
    },
    {
      name: 'rating', icon: 'star_rate', title: 'Rating', description: 'Respondent replies with a number (or selects an image representing a number e.g. Stars) to rate low or high e.g. satisfaction, agreement etc. with your question.',
      channels: ['sms', 'whatsapp', 'web'], category: ['general', 'popular'], allowInBlock: true, model: null
    },
    {
      name: 'ranking', icon: 'reorder', title: 'Rank Order', description: 'Rank order question asks respondents to order the presented options from high to low in a priority order.',
      channels: ['sms', 'whatsapp', 'web'], category: ['general', 'popular'], allowInBlock: true, model: null
    },
    {
      name: 'currency', icon: 'money', title: 'Currency', description: 'Respondent replies with a monetary value. Specify the currency in your question text and provide an example e.g. 1.55.',
      channels: ['sms', 'whatsapp', 'web'], category: ['general'], allowInBlock: true, model: null
    },
    {
      name: 'integer', icon: 'looks_one', title: 'Number', description: 'Requires the respondent to enter a number only.',
      channels: ['sms', 'whatsapp', 'web'], category: ['general'], allowInBlock: true, model: null
    },
    {
      name: 'boolean', icon: 'thumbs_up_down', title: 'Yes or No', description: 'Simple choice question that requires respondent to select either Yes or No as an answer.',
      channels: ['web'], category: ['general'], allowInBlock: true, model: null
    },
    {
      name: 'date', icon: 'date_range', title: 'Date & Time', description: 'Requires the respondent to select a Date and/or Time at the time of taking the survey.',
      channels: ['web'], category: ['general'], allowInBlock: true, model: null
    },
    {
      name: 'terminate', icon: 'stop_circle', title: 'Close Survey', description: 'Final message to indicate the end of your survey to the respondent.',
      channels: ['sms', 'whatsapp', 'web'], category: ['general'], allowInBlock: true, model: null
    },
    {
      name: 'block', icon: 'view_list', title: 'Block', description: 'Use a block to present a group of specific questions to your respondents in a separate section of your survey.',
      channels: ['web'], category: ['advanced'], allowInBlock: false, model: null
    },
    {
      name: 'maxDiff', icon: 'insert_chart_outlined', title: 'MaxDiff', description: "Designed to identify what is and isn't important to respondents. The options are presented in random sets and you can choose how many times sets are presented.",
      channels: ['web'], category: ['advanced'], allowInBlock: false, model: null
    },
    {
      name: 'bucket', icon: 'group_work', title: 'Grouping (Card Sort)', description: 'Respondents categorise options that you have specified into groups (that you have created). These options can be text or image options.',
      channels: ['web'], category: ['advanced'], allowInBlock: false, model: null
    },
    {
      name: 'imageMap', icon: 'image', title: 'Image Map', description: 'Add an image question to your survey and ask respondents to click on the image to identify areas they Like, Dislike, or feel Neutral about.',
      channels: ['web'], category: ['advanced'], allowInBlock: false, model: null
    },
    {
      name: 'media', icon: 'camera', title: 'Media', description: 'You can choose to request the respondent to upload an image, video, or audio file.',
      channels: ['web'], category: ['advanced'], allowInBlock: true, model: null
    },
    {
      name: 'geocode', icon: 'location_on', title: 'Location', description: 'Requests that the respondent shares their present location. Appears as a map and collects longitude and latitude.',
      channels: ['web'], category: ['advanced'], allowInBlock: true, model: null
    },
    {
      name: 'barcode', icon: 'qr_code_scanner', title: 'Barcode', description: 'Requests the respondent to enter a barcode number (Online) or Auto Scan (in the DIY Surveys Mobile App).',
      channels: ['web'], category: ['advanced'], allowInBlock: true, model: null
    },
    {
      name: 'sendSMS', icon: 'sms', title: 'Send SMS', description: 'The ability to send an SMS to someone indicating an event in the survey. This question type is a notification and is not sent to the survey respondent.',
      channels: ['sms', 'whatsapp', 'web'], category: ['advanced'], allowInBlock: true, model: null
    },
    {
      name: 'sendEmail', icon: 'send', title: 'Send Email', description: 'The ability to send an Email to someone indicating an event in the survey. This question is a notification and is not sent to the survey respondent.',
      channels: ['sms', 'whatsapp', 'web'], category: ['advanced'], allowInBlock: true, model: null
    },
    {
      name: 'userlist', icon: 'list', title: 'User List', description: 'A list that can be reused by other multiple choice type questions.',
      channels: ['sms', 'whatsapp', 'web'], category: ['advanced'], allowInBlock: false, model: null
    }
  ];

  categories: any[] =  [
    { name: 'all', description: 'All' },
    { name: 'general', description: 'General' },
    { name: 'advanced', description: 'Advanced' },
    { name: 'popular', description: 'Most Popular' },
  ];

  category: string = 'all';
  layout: string = 'list';
  allQuestions: any[] = null;
  library: any[] = null;
  formGroup: FormGroup = new FormGroup({});
  style: string = "clear: both";
  capability: string[] = ['web', 'sms', 'whatsapp'];
  inBlock: boolean = false;
  libraryQuestions: LibraryQuestionModel[] = null;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    private interviewService: InterviewService,
    private dialogRef: MatDialogRef<AddQuestionComponent>,
    private questionEventEmitterService: QuestionService) {

    this.interviewService.getLibrary().subscribe(result => {
      this.libraryQuestions = result;
      if (this.libraryQuestions.length > 0) {
        this.categories.push({ name: 'library', description: 'Your Library' });
      }
    });

    let option = localStorage.getItem('option') ?? "general|detail";
    let options: string[] = [];
    options[0] = option.toLowerCase();
    if (option.includes("|")) {
      options = option.split("|");
    }
    else {
      options[1] = "detail";
    }

    this.category = options[0];
    this.layout = options[1];
    this.formGroup = new FormGroup({
      category: new FormControl(this.category),
      layout: new FormControl(this.layout)
    });
    this.inBlock = this.data.data.parentIndex != undefined && this.data.data.parentIndex > -1;
    this.setLayout();
    this.capability = [ this.data.data.channel ];
  }

  addNewQuestion(question: any) {
    this.questionEventEmitterService.onAddComponentButtonClick({
      questionType: question.name,
      action: this.data.data.action,
      index: this.data.data.index,
      displayIndex: this.data.data.displayIndex,
      parentIndex: this.data.data.parentIndex,
      displayParentIndex: this.data.data.displayParentIndex,
      model: this.category == 'library' ? question.model : undefined
    });
  }

  layoutChange(event) {
    this.layout = event.value;
    this.setLayout();
  }

  setLayout() {
    switch (this.layout) {
      case "list":
        this.style = "clear: both";
        this.dialogRef.updateSize("30%");
        break;
      case "detail":
        this.dialogRef.updateSize("80%");
        this.style = "clear: both; width: 80%";
        break;
    }

    localStorage.setItem('option', this.category + "|" + this.layout);
  }

  onCategoryChange(event) {
    this.category = event.value;
    localStorage.setItem('option', this.category + "|" + this.layout);
  }

  fromLibrary(): any[] {
    if (this.library != null && this.libraryQuestions != null) {
      return this.library;
    }

    if (this.libraryQuestions == null) {
      return [];
    }

    this.library = [];
    for (let i = 0; i < this.libraryQuestions.length; i++) {
      const model = plainToClass(QuestionModel, JSON.parse(this.libraryQuestions[i].QuestionXML));
      model.checkAllModel();
      let question = null;
      for (let j = 0; j < this.questions.length; j++) {
        if (this.questions[j].name == model.QuestionType) {
          question = this.questions[j];
          break;
        }
      }
      if (question != null) {
        let found = false;
        if (this.inBlock && question.allowInBlock || !this.inBlock) {
          for (let j = 0; j < this.capability.length; j++) {
            if (question.channels.includes(this.capability[j])) {
              found = true;
              break;
            }
          }
        }

        if (found) {
          let banner = model.FormatBannerForSms;
          if (banner.length > 50) {
            banner = banner.substring(0, 50) + "...";
          }

          let entry =
          {
            name: model.QuestionType, icon: model.Icon, title: question.title, description: banner,
            channels: question.Channels, category: question.Category, allowInBlock: question.allowInBlock, model: model
          };
          this.library.push(entry);
        }
      }
    }

    return this.library;
  }

  getQuestionForAllCategories(): any[] {
    if (this.allQuestions != null) {
      return this.allQuestions;
    }

    let results = [];
    let j = 0;
    let c = [];
    for (let i = 0; i < this.questions.length; i++) {
      let question = this.questions[i];
      let found = false;
      if (this.inBlock && question.allowInBlock || !this.inBlock) {
        for (let j = 0; j < this.capability.length; j++) {
          if (question.channels.includes(this.capability[j])) {
            found = true;
            break;
          }
        }
      }

      if (found) {
        if (j == 0) {
          c = [];
        }

        j++;
        c.push(question);
        if (j == 2) {
          results.push(c);
          j = 0;
        }
      }
    }

    this.allQuestions = results;
    return results;
  }

  getQuestionForCategory(value: string): any[] {
    let results = [];
    for (let i = 0; i < this.questions.length; i++) {
      let question = this.questions[i];
      if (this.category == 'all' || this.category == "library" || question.category.includes(this.category)) {
        let found = false;
        if (this.inBlock && question.allowInBlock || !this.inBlock) {
          for (let j = 0; j < this.capability.length; j++) {
            if (question.channels.includes(this.capability[j])) {
              found = true;
              break;
            }
          }
        }

        if (found) {
          results.push(question);
        }
      }
    }

    return results;
  }

}
