import { Component } from '@angular/core';
import { FormControl, FormGroup, FormBuilder } from '@angular/forms';
import { MessageService, MobileCountryInfo } from '../../message.Service';
import { TranslateService } from '@ngx-translate/core';
import { NetworkService } from '../../network.Service';

@Component({
  selector: 'app-dlc-campaign',
  templateUrl: './dlc-campaign.component.html',
  styleUrl: './dlc-campaign.component.css'
})
export class DlcCampaignComponent {
  formGroup: FormGroup;
  errorMsg: string;
  displayError: boolean;
  displaySuccess: boolean;
  dataReady: boolean = false;
  countrycode: number = 0;
  countries: MobileCountryInfo[] = [];

  constructor(private fb: FormBuilder,
    public translate: TranslateService,
    private messageService: MessageService,
    private networkSerivce: NetworkService
  ) {
    this.errorMsg = "";
    this.displayError = false;
    this.displaySuccess = false;
    this.messageService.getCountries().subscribe(result => {
      this.countries = result;
    });

    this.formGroup = new FormGroup({
      usecase: new FormControl(),
      campaignname: new FormControl(),
      campaigndescription: new FormControl(),
      samplemessage: new FormControl(),
      suboptinflow: new FormControl(),
      messageboxin: new FormControl(),
      suboptoutflow: new FormControl(),
      messageboxout: new FormControl(),
      suboptinflowhelp: new FormControl(),
      messageboxhelp: new FormControl()
    });
  }

  newSearch() {
    this.networkSerivce.DlcCampaignRegEnquiry(this.formGroup.value.usecase, this.formGroup.value.campaignname,
      this.formGroup.value.campaigndescription, this.formGroup.value.samplemessage, this.formGroup.value.suboptinflow,
      this.formGroup.value.messageboxin, this.formGroup.value.suboptoutflow, this.formGroup.value.messageboxout,
      this.formGroup.value.suboptinflowhelp, this.formGroup.value.messageboxhelp)
      .subscribe(result => {
        this.displayError = false;
        this.displaySuccess = true;
      },
        error => {
          console.error(error);
          this.errorMsg = "There was a problem Sending your DLC_Campaign Registration Request. Please Try again later.";
          this.displayError = true;
        });
  }
}
