<div class="padding-10">
  <form class="new-communication-form column-flex" [formGroup]="formGroup" (ngSubmit)="onSubmit()">
    <mat-accordion>
      <mat-expansion-panel hideToggle [expanded]="true">
        <mat-expansion-panel-header>
          <mat-panel-title>
            Basic Settings
          </mat-panel-title>
          <mat-panel-description>

          </mat-panel-description>
        </mat-expansion-panel-header>
        <p>
          Created:{{ hook.Created | date:'medium' }} and Last Updated:{{ hook.Amended | date:'medium' }}
        </p>
        <div>
          <mat-form-field appearance="outline" class="fullWidth">
            <mat-label>Name</mat-label>
            <input matInput placeholder="Name" formControlName="Tag">
          </mat-form-field>
        </div>
        <div>
          <mat-form-field appearance="outline" class="fullWidth">
            <mat-label>WebHook ID</mat-label>
            <input matInput placeholder="App ID" formControlName="Name">
          </mat-form-field>
        </div>
        <div>
          <mat-form-field appearance="outline" class="fullWidth">
            <mat-label>Payload URL</mat-label>
            <input matInput type="url" placeholder="Payload URL" formControlName="PayloadUrl">
          </mat-form-field>
        </div>
        <div>
          <mat-form-field appearance="outline" class="fullWidth">
            <mat-label>Content Type</mat-label>
            <mat-select formControlName="ContentTypeId">
              <mat-option value="0">application/json</mat-option>
              <mat-option value="1">application/x-www-form-urlencoded</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div>
          <mat-form-field appearance="outline" class="fullWidth">
            <mat-label>Method</mat-label>
            <mat-select formControlName="Method">
              <mat-option value="0">GET</mat-option>
              <mat-option value="1">POST</mat-option>
              <mat-option value="2">PATCH</mat-option>
              <mat-option value="3">COPY</mat-option>
              <mat-option value="4">DELETE</mat-option>
              <mat-option value="5">HEAD</mat-option>
              <mat-option value="6">MERGE</mat-option>
              <mat-option value="7">OPTIONS</mat-option>
              <mat-option value="8">PUT</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div>
          <mat-form-field appearance="outline" class="fullWidth">
            <mat-label>Secret</mat-label>
            <input matInput placeholder="Secret" formControlName="Secret">
          </mat-form-field>
        </div>
        <div>
          <p>Which events would you like to trigger with this web hook?</p>
          <mat-button-toggle-group #group="matButtonToggleGroup" (change)="hookTypeChange($event)" formControlName="HookTypeIndicator">
            <mat-button-toggle value="1" [checked]="hook.HookType === hookType.All">All Events</mat-button-toggle>
            <mat-button-toggle value="2" [checked]="hook.HookType !== hookType.All">Individual Events</mat-button-toggle>
          </mat-button-toggle-group>
        </div>
        <div *ngIf="individualEvents" class="fullWidth flex-flow-wrap">
          <mat-card class="wbHook-det-individual-card">
            <mat-checkbox formControlName="hookTypeReceipt">Receipt</mat-checkbox>
            <mat-card-content>
              An inbound message where the status reflects more information.
            </mat-card-content>
          </mat-card>
          <mat-card class="wbHook-det-individual-card">
            <mat-checkbox formControlName="hookTypeLookup">Lookups</mat-checkbox>
            <mat-card-content>
              A callback when the outbound message destination is checked through the look up service and it fails.
            </mat-card-content>
          </mat-card>
          <mat-card class="wbHook-det-individual-card">
            <mat-checkbox formControlName="hookTypeMessage">Message</mat-checkbox>
            <mat-card-content>
              An inbound message that you will need to process.
            </mat-card-content>
          </mat-card>
          <mat-card class="wbHook-det-individual-card">
            <mat-checkbox formControlName="hookTypeInfo">Info</mat-checkbox>
            <mat-card-content>
              An inbound message that has been processed by the platform as an info message.
            </mat-card-content>
          </mat-card>
          <mat-card class="wbHook-det-individual-card">
            <mat-checkbox formControlName="hookTypeHelp">Help</mat-checkbox>
            <mat-card-content>
              An inbound message that has been processed by the platform as a help message.
            </mat-card-content>
          </mat-card>
          <mat-card class="wbHook-det-individual-card">
            <mat-checkbox formControlName="hookTypeStop">Stop</mat-checkbox>
            <mat-card-content>
              An inbound message that has been detected as a STOP message from a recipient
              and has been added to the global stop list.
            </mat-card-content>
          </mat-card>
          <mat-card class="wbHook-det-individual-card">
            <mat-checkbox formControlName="hookTypeUnstop">Unstop</mat-checkbox>
            <mat-card-content>
              An inbound message that has been detected as an UNSTOP message from a
              recipient and has been removed from the global stop list.
            </mat-card-content>
          </mat-card>
          <mat-card class="wbHook-det-individual-card">
            <mat-checkbox formControlName="hookTypeKeyword">Keyword</mat-checkbox>
            <mat-card-content>
              An inbound message that has been detected as one of your keywords.
            </mat-card-content>
          </mat-card>
          <mat-card class="wbHook-det-individual-card">
            <mat-checkbox formControlName="hookTypeClick">Click</mat-checkbox>
            <mat-card-content>
              A tiny URL registered to you was clicked on.
            </mat-card-content>
          </mat-card>
          <mat-card class="wbHook-det-individual-card">
            <mat-checkbox formControlName="hookTypeKilled">Killed</mat-checkbox>
            <mat-card-content>
              The message has been killed before it was sent.
            </mat-card-content>
          </mat-card>
          <mat-card class="wbHook-det-individual-card">
            <mat-checkbox formControlName="hookTypeNotKilled">Not Killed</mat-checkbox>
            <mat-card-content>
              There was no message to be killed.
            </mat-card-content>
          </mat-card>
          <mat-card class="wbHook-det-individual-card">
            <mat-checkbox formControlName="hookTypeOnStop">On Stop</mat-checkbox>
            <mat-card-content>
              The number being used is currently on stop.
            </mat-card-content>
          </mat-card>
          <mat-card class="wbHook-det-individual-card">
            <mat-checkbox formControlName="hookTypeRouting">Routing Failure</mat-checkbox>
            <mat-card-content>
              The message attempting to be sent could not be successfully routed.
            </mat-card-content>
          </mat-card>
          <mat-card class="wbHook-det-individual-card">
            <mat-checkbox formControlName="hookTypeTimedOut">Timed Out</mat-checkbox>
            <mat-card-content>
              The message attempted to be sent had timed out before it was processed.
            </mat-card-content>
          </mat-card>
        </div>
        <div>
          <p>Status</p>
          <mat-button-toggle-group #group="matButtonToggleGroup" formControlName="ActiveStatus">
            <mat-button-toggle value="1" [checked]="hook.Active === true">Active</mat-button-toggle>
            <mat-button-toggle value="2" [checked]="hook.Active === false">Inactive</mat-button-toggle>
          </mat-button-toggle-group>
        </div>
      </mat-expansion-panel>
      <mat-expansion-panel hideToggle>
        <mat-expansion-panel-header>
          <mat-panel-title>
            Advanced Settings
          </mat-panel-title>
          <mat-panel-description>
          </mat-panel-description>
        </mat-expansion-panel-header>
        <div>
          <p>The length of time in milliseconds before the callback attempt is timed out.</p>
          <mat-form-field appearance="outline" class="fullWidth">
            <mat-label>Timeout (milliseconds)</mat-label>
            <input matInput placeholder="Timeout" formControlName="Timeout">
          </mat-form-field>
        </div>
        <div>
          <p>The HTTP status code to expect from a successful callback attempt.</p>
          <mat-form-field appearance="outline" class="fullWidth">
            <mat-label>HTTP Status Code</mat-label>
            <input matInput placeholder="Status" formControlName="Status">
          </mat-form-field>
        </div>
        <div>
          <p>The number of attempts to try the callback before the process is considered a failure.</p>
          <mat-form-field appearance="outline" class="fullWidth">
            <mat-label>Attempts</mat-label>
            <input matInput placeholder="Attempts" formControlName="Retries">
          </mat-form-field>
        </div>
        <div>
          <p>Total time to try the callback for.</p>
          <mat-form-field appearance="outline" class="fullWidth">
            <mat-label>Total Timeout (milliseconds)</mat-label>
            <input matInput placeholder="Total Timeout" formControlName="TotalTimeout">
          </mat-form-field>
        </div>
      </mat-expansion-panel>
      <mat-expansion-panel hideToggle *ngIf="tempEvents">
        <mat-expansion-panel-header>
          <mat-panel-title>
            Recent Deliveries
          </mat-panel-title>
          <mat-panel-description>
          </mat-panel-description>
        </mat-expansion-panel-header>
        <div *ngIf="tempEvents | async; else loading">
          <div *ngFor="let event of events">
            <mat-accordion>
              <mat-expansion-panel hideToggle [expanded]="false">
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    <mat-icon color="{{eventTypeColor(event.ResponseStatus)}}">{{eventType(event.ResponseStatus)}}</mat-icon>{{event.Guid}}
                  </mat-panel-title>
                  <mat-panel-description>
                    <div class="leftFloat wd-80-per">{{ event.Sent | date:'medium' }}&nbsp;Completed in&nbsp;{{event.Duration / 100}} seconds</div>
                    <div class="rightFloat"><button mat-button color="Basic" (click)="resend($event, event.WebHookEventId);$event.preventDefault()">Resend Event</button></div>
                  </mat-panel-description>
                </mat-expansion-panel-header>
                <mat-tab-group animationDuration="0ms" mat-align-tabs="start">
                  <mat-tab label="Request">
                    <div class="list-group">
                      <div class="list-group-item">
                        <h3>Headers</h3>
                        <div [innerHtml]="event.HeadersHtml"></div>
                      </div>
                      <div class="list-group-item">
                        <h3>Payload</h3>
                        <div [innerHtml]="event.PayloadHtml"></div>
                      </div>
                    </div>
                  </mat-tab>
                  <mat-tab>
                    <ng-template mat-tab-label>
                      Response&nbsp;<span [class]="eventTypeBadgeColor(event.ResponseStatus)">{{event.ResponseStatus}}</span>
                    </ng-template>
                    <div class="list-group">
                      <div class="list-group-item">
                        <h3>Headers</h3>
                        <div [innerHtml]="event.ResponseHeadersHtml"></div>
                      </div>
                      <div class="list-group-item">
                        <h3>Payload</h3>
                        <div [innerHtml]="event.ResponsePayloadHtml"></div>
                      </div>
                    </div>
                  </mat-tab>
                </mat-tab-group>
              </mat-expansion-panel>
            </mat-accordion>
          </div>
        </div>
      </mat-expansion-panel>
    </mat-accordion>
  </form>
</div>

<ng-template #loading>
  <div class="loading-container">
    <span class="loading-content">
      <div class="spinner-div"><mat-spinner [diameter]="25"></mat-spinner></div>
      <div class="spinner-text"> Please Wait...</div>
    </span>
  </div>
</ng-template>

