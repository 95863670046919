import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { baseUrl, options } from "../environments/environment";

export class WebHookType {
  Receipt = 0B1;
  Lookup = 0B10;
  Message = 0B100;
  Info = 0B1000;
  Help = 0B10000;
  Stop = 0B100000;
  Unstop = 0B1000000;
  Keyword = 0B10000000;
  Click = 0B100000000;
  Killed = 0B1000000000;
  NotKilled = 0B10000000000;
  OnStop = 0B100000000000;
  RoutingFailure = 0B1000000000000;
  TimedOut = 0B10000000000000;
  All = 0B11111111111111;
  Unknown = 0B0;
  HookTypes: number[] = [];
  constructor() {
    this.HookTypes.push(this.Receipt);
    this.HookTypes.push(this.Lookup);
    this.HookTypes.push(this.Message);
    this.HookTypes.push(this.Info);
    this.HookTypes.push(this.Help);
    this.HookTypes.push(this.Stop);
    this.HookTypes.push(this.Unstop);
    this.HookTypes.push(this.Keyword);
    this.HookTypes.push(this.Click);
    this.HookTypes.push(this.Killed);
    this.HookTypes.push(this.NotKilled);
    this.HookTypes.push(this.OnStop);
    this.HookTypes.push(this.RoutingFailure);
    this.HookTypes.push(this.TimedOut);
  }
}

export class WebHookModel {
  WebHookId: number = 0;
  Name: string = "";
  PayloadUrl: string = "";
  ContentTypeId: number = 0;
  Secret: string = "";
  Created: Date = new Date();
  Amended: Date = new Date();
  OwnerId: number = 0;
  IsDeleted: boolean = false;
  Active: boolean = false;
  HookType: number = 0;
  Timeout: number = 0;
  TotalTimeout: number = 0;
  Retries: number = 0;
  Action: string = "";
  Status: number = 0;
  Method: number = 0;
  WebHookTypeList: string = "";
  Tag: string;

  constructor() {
    let temp = new WebHookType();
    this.Method = 0;
    this.Timeout = 30000;
    this.TotalTimeout = 30000;
    this.Retries = 1;
    this.ContentTypeId = 0;
    this.Status = 200;
    this.HookType = temp.All;
    this.Active = true;
    this.Action = "";
    this.OwnerId = 0;
    this.Tag = "";
  }
}

export class WebHookEventModel {
  WebHookEventId: number = 0;
  WebHookId: number = 0;
  Headers: string = "";
  Payload: string = "";
  ResponseStatus: number = 0;
  ResponseHeaders: string = "";
  ResponsePayload: string = "";
  HeadersHtml: string = "";
  PayloadHtml: string = "";
  ResponseHeadersHtml: string = "";
  ResponsePayloadHtml: string = "";
  Created: Date = new Date();
  Sent: Date = new Date();
  IsDeleted: boolean = false;
  Guid: string = "";
  Duration: number = 0;
  Attempts: number = 0;
}

@Injectable()
export class WebHookService {

  constructor(private http: HttpClient) {
  }

  getWebHooksForAccount(userId: number) {
    return this.http.get<WebHookModel[]>(baseUrl + 'WebHook/GetWebHooksForAccount?userid=' + userId, options);
  }

  getWebHook(hookId: string): Observable<WebHookModel> {
    return this.http.get<WebHookModel>(baseUrl + 'WebHook/GetByHookId?id=' + hookId, options);
  }

  setWebHook(hook: WebHookModel): Observable<WebHookModel> {
    return this.http.patch<WebHookModel>(baseUrl + 'WebHook/Update', hook, options);
  }

  resendWebHook(hookId: string): Observable<any> {
    return this.http.get(baseUrl + 'WebHook/ResendEvent?id=' + hookId, options);
  }

  getWebHooks(): Observable<WebHookModel[]> {
    return this.http.get<WebHookModel[]>(baseUrl + 'WebHook/GetWebHooks', options);
  }

  getWebHookEvents(hookId: string): Observable<WebHookEventModel[]> {
    return this.http.get<WebHookEventModel[]>(baseUrl + 'WebHook/GetWebHookEvents?id=' + hookId, options);
  }

  getWebHookEventGuids(hookId: string): Observable<string[]> {
    return this.http.get<string[]>(baseUrl + 'WebHook/GetWebHookEventGuids?id=' + hookId, options);
  }

  deleteWebHook(hookId: string) {
    this.http.delete(baseUrl + 'WebHook/Delete?id=' + hookId, options).subscribe();
  }

}
