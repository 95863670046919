import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CaseDataService } from '../../casedata.Service';
import { SurveyModel } from '../../survey.Service';

@Component({
  selector: 'app-delete-data',
  templateUrl: './delete-data.component.html',
  styleUrls: ['./delete-data.component.css']
})
export class DeleteDataComponent implements OnInit {

  survey: SurveyModel;
  formGroup: FormGroup;

  constructor(private dialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private caseDataService: CaseDataService
  ) {
    this.survey = data.survey;
    this.formGroup = new FormGroup({
      Responses: new FormControl("5", [Validators.required])
    });
  }

  ngOnInit(): void {
  }

  delete() {
    this.caseDataService.deleteTestCaseData(this.survey.SurveyID);
    this.dialogRef.close();
  }

}
