import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ImageOnlyAlertComponent } from '../name-survey-from-scratch/image-only-alert/image-only-alert.component';
import { ThemePalette } from '@angular/material/core';
import { ThemeService } from './theme.service';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { InterviewModel, InterviewService, LanguageAnalysis, LanguageSummary, ParamModel, StyleModel } from '../question.service';
import { HttpEventType } from '@angular/common/http';
import { MediaService } from '../media.Service';
import { QuestionEditorComponent } from '../question-editor/question-editor.component';
import { MatRadioChange } from '@angular/material/radio';
import { FileUploadComponent } from '../file-upload/file-upload.component';
import { LanguageSummaryComponent } from '../languages/language-summary/language-summary.component';
import { JsonCyclic } from '../utils';
import { OneScriptAngularPlayerComponent } from '../lib/one-script-angular-player.component';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-survey-style',
  templateUrl: './survey-style.component.html',
  styleUrls: ['./survey-style.component.css']
})
export class SurveyStyleComponent implements OnInit, OnDestroy {
  panelOpenState: boolean = false;
  isRadioSelected: boolean = true;

  isDefaultChecked: boolean = false;
  surveyStyle: StyleModel = new StyleModel();

  progress: number = 0;

  logoFormGroup: FormGroup = new FormGroup({});
  interview: InterviewModel = new InterviewModel();

  file?: File | null = null;
  selectedLogo?: File | null = null;
  selectedFooterLogo: number = 0;
  backgroundTick: boolean = false;
  errorHandling: number = 1;
  dialogRef: MatDialogRef<any, any>;
  model: any;
  show: boolean = false;
  styleModel: StyleModel = null;
  customForm: FormGroup = new FormGroup({});
  subscription: Subscription;
  isInvite: boolean = false;

  @ViewChild('logoInput', { static: false }) logoInput: ElementRef | undefined;
  @ViewChild('scriptUpload') scriptUpload: FileUploadComponent | undefined;
  @ViewChild('styleDialog') styleDialog: TemplateRef<any>;
  @ViewChild('player') player: OneScriptAngularPlayerComponent | undefined;

  public disabled = false;
  public color: ThemePalette = 'primary';
  public touchUi = false;

  themes: any = {
    deepPurpleAmber: false,
    indigoPink: false,
    pinkBlueGrey: false,
    greenPurple: false,
    default: false
  };

  backgrounds: any = {
    red: false,
    blue: false,
    green: false,
    cyan: false,
    beige: false,
    white: false
  }

  headerColors: any = {
    red: false,
    blue: false,
    green: false,
    cyan: false,
    beige: false,
    white: false
  }

  navColors: any = {
    red: false,
    blue: false,
    green: false,
    cyan: false,
    beige: false,
    white: false
  }

  controlColors: any = {
    red: false,
    blue: false,
    green: false,
    cyan: false,
    beige: false,
    white: false
  };

  analysis: LanguageAnalysis = null;

  constructor(private dialog: MatDialog,
    private theme: ThemeService,
    private mediaService: MediaService,
    private interviewService: InterviewService
  ) {
    this.customForm = new FormGroup({
      BackgroundColor: new FormControl(),
      HeaderColor: new FormControl(),
      NavigationColor: new FormControl(),
      ControlColor: new FormControl()
    });
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }

  ngOnInit(): void {
    this.logoFormGroup = new FormGroup({});
    this.subscription = this.customForm.valueChanges.subscribe(result => {
      if (this.editor != null) {
        this.show = false;
        this.editor.interview.Style.BackgroundColor = this.customForm.controls.BackgroundColor.value;
        this.editor.interview.Style.HeaderColor = this.customForm.controls.HeaderColor.value;
        this.editor.interview.Style.NavigationColor = this.customForm.controls.NavigationColor.value;
        this.editor.interview.Style.ControlsColor = this.customForm.controls.ControlColor.value;
        this.updatePreview();
      }
    });

    this.isInvite = this.editor?.interview.IsInvite ?? false;
    if (this.editor?.interview.Style.Name == '') {
      this.selectedTheme('default');
    }

    if (this.editor?.interview.Style.Logo == 0) {
      this.isDefaultChecked = true;
    }

    for (let theme in this.themes) {
      if ((this.editor?.interview.Style.Name == theme) && (this.editor.interview.Style.BackgroundColor == '') && (this.editor.interview.Style.HeaderColor == '')) {
        this.themes[theme] = true;
      } else {
        this.themes[theme] = false;
      }
    }

    for (let background in this.backgrounds) {
      if (this.editor?.interview.Style.BackgroundColor == background) {
        this.backgrounds[background] = true;
      } else {
        this.backgrounds[background] = false;
      }
    }

    for (let headerColor in this.headerColors) {
      if (this.editor?.interview.Style.HeaderColor == headerColor) {
        this.headerColors[headerColor] = true;
      } else {
        this.headerColors[headerColor] = false;
      }
    }

    for (let navColor in this.navColors) {
      if (this.editor?.interview.Style.NavigationColor == navColor) {
        this.navColors[navColor] = true;
      } else {
        this.navColors[navColor] = false;
      }
    }

    for (let controlColor in this.controlColors) {
      if (this.editor?.interview.Style.NavigationColor == controlColor) {
        this.controlColors[controlColor] = true;
      } else {
        this.controlColors[controlColor] = false;
      }
    }

    if (this.editor?.interview.Style.FooterLogoIsDark == false || this.editor.interview.Style.FooterLogoIsDark == null) {
      this.selectedFooterLogo = 1;
    } else {
      this.selectedFooterLogo = 0;
    }

    if (this.editor != null) {
      this.errorHandling = this.editor.interview.Style.ErrorHandling;
    }

    if (this.errorHandling != 0 && this.errorHandling != 1) {
      this.editor.interview.Style.ErrorHandling = this.errorHandling = 0;
    }
  }

  onLogoChange(event: any) {
    const reader = new FileReader();
    const inputFiles: { [key: string]: File } = this.logoInput?.nativeElement.files;
    if (event.target.files && event.target.files.length) {
      const file = event.target.files[0];
      reader.readAsDataURL(file);
      const mimeType = file.type;
      if (mimeType.match(/image\/*/) == null) {
        this.dialog.open(ImageOnlyAlertComponent);
        return;
      } else {
        this.selectedLogo = inputFiles[0];
      }
      reader.onload = () => {
        this._media = reader.result as string;
        if (this._media) {
          this.uploadImage().then((id: number) => {
            if (this.editor != null) {
              this.editor.interview.Style.Logo = id;
              this.editor.saveInterview();
            }
          });
        }
        this.isDefaultChecked = false;
        this.theme.setLogo(this.media ?? "");
      };
    }
  }

  onClickLogoInputButton(): void {
    this.isDefaultChecked = false;
    this.logoInput?.nativeElement.click();
  }

  selectedTheme(color: string) {
    for (let theme in this.themes) {
      this.themes[theme] = theme == color;
    }
    this.theme.setTheme(color);
    if (this.editor != null) {
      this.show = false;
      this.editor.interview.Style.Name = color;
      this.editor.interview.Style.BackgroundColor = '';
      this.editor.interview.Style.HeaderColor = '';
      this.editor.interview.Style.NavigationColor = '';
      this.editor.interview.Style.ControlsColor = '';
      this.editor.interview.Style.FooterLogoIsDark = false;
      this.selectedFooterLogo = 1;
      switch (color) {
        case "pinkBlueGrey":
        case "greenPurple":
          this.editor.interview.Style.FooterLogoIsDark = true;
          this.selectedFooterLogo = 0;
          break;
      }
      this.updatePreview();
    }
  }

  updatePreview() {
    this.editor.saveInterview();
    this.styleModel = this.editor.interview.Style;
    this.model.Style = this.styleModel;
    this.player.model = this.model;
    this.player.media = null;
    this.show = true;
  }

  checkDefault(e: MatCheckboxChange) {
    if (e.checked) {
      this.show = false;
      this.isDefaultChecked = true;
      this.selectedLogo = null;
      this.media = '';
      this.logo = 0;
      this.theme.setLogo(this.media);
      this.updatePreview();
    }
  }

  changeFooterLogo(e: MatRadioChange) {
    if (this.editor == null) {
      return;
    }

    this.show = false;
    this.editor.interview.Style.FooterLogoIsDark = (e.value == 0);
    this.updatePreview();
  }

  changeInviteFlag(e: MatCheckboxChange) {
    if (this.editor == null) {
      return;
    }

    this.isInvite = e.checked;
    this.editor.interview.IsInvite = this.isInvite;
    this.editor.saveInterview();
  }

  changeErrorHandling(e: MatRadioChange) {
    if (this.editor == null) {
      return;
    }

    this.editor.interview.Style.ErrorHandling = e.value;
    this.editor.saveInterview();
    this.styleModel = this.editor.interview.Style;
    this.model.Style = this.styleModel;
    this.player.model = this.model;
    this.show = true;
  }

  uploadImage(): Promise<number> {
    return new Promise((resolve, reject) => {
      if (this.media != null) {
        this.mediaService.uploadBase64FileWithMediaId(this.media, this.logo).subscribe(
          event => {
            if (event.type === HttpEventType.UploadProgress)
              this.progress = Math.round(100 * event.loaded / event.total);
            else if (event.type === HttpEventType.Response) {
              this.logo = event.body.MediaID;
            }
          });
      }
    });
  }

  @Input()
  get editor(): QuestionEditorComponent | null {
    return this._editor;
  }
  set editor(value: QuestionEditorComponent | null) {
    this._editor = value;
    if (this._editor === undefined || this._editor === null) {
      return;
    }

    if (this._editor.interview) {
      this._editor.interview.Style.Logo && this._editor.interview.Style.Name
    }

    this.customForm.controls.BackgroundColor.setValue(this.editor.interview.Style.BackgroundColor);
    this.customForm.controls.HeaderColor.setValue(this.editor.interview.Style.HeaderColor);
    this.customForm.controls.NavigationColor.setValue(this.editor.interview.Style.NavigationColor);
    this.customForm.controls.ControlColor.setValue(this.editor.interview.Style.ControlsColor);
    this.interviewService.analyzeInterview(JsonCyclic.toJson(this._editor.interview)).subscribe(result => {
      this.analysis = result;
    });
  }
  private _editor: QuestionEditorComponent | null = null;

  private _gettingMedia: boolean = false;

  get logo(): number {
    if (this._editor && this._editor.interview && this._editor.interview.Style) {
      return this._editor.interview.Style.Logo;
    }

    return 0;
  }
  set logo(value: number) {
    if (this._editor && this._editor.interview && this._editor.interview.Style) {
      this.show = false;
      this._editor.interview.Style.Logo = value;
      this.updatePreview();
    }
  }

  get media(): string | null {
    if (this._editor && this._media === undefined && this.logo > 0 && !this._gettingMedia) {
      this._gettingMedia = true;
      this.mediaService.getMediaById(this._editor.interview.Style.Logo).subscribe(
        result => { this._media = result; });
    }

    return this._media;
  }
  set media(value: string | null) {
    this._media = value;
  }
  private _media: string | null = null;

  get isSms(): boolean {
    return this._editor?.interview.Channel == "sms" || this._editor?.interview.Channel == "whatsapp";
  }

  @Output() uploadScript = new EventEmitter();

  onFileUploaded(event: any) {
    this.uploadScript.emit(event.name);
    if (this.scriptUpload == undefined) {
      return;
    }

    this.scriptUpload.progress = 0;
    this.scriptUpload.message = "Your questionnaire has been successfully imported";
  }

  clickLanguages() {
    let langRef = this.dialog.open(LanguageSummaryComponent, { data: { model: this._editor.interview, editor: this._editor }, minWidth: "90%" });
    langRef.afterClosed().subscribe(result => {
      this.analysis = result;
      this.updateInterview();
    });
  }

  clickChooseLanguage() {
    this.analysis = null;
  }

  setLanguage(summary: LanguageSummary): boolean {
    this._editor.interview.DefaultLanguage = summary.Language.Region;
    for (let i = 0; i < this.analysis.Summary.length; i++) {
      if (summary.Language.Region == this.analysis.Summary[i].Language.Region) {
        this.analysis.Summary.splice(i, 1);
        this.analysis.Summary.splice(0, 0, summary);
        this.updateInterview();
        return false;
      }
    }

    return false;
  }

  updateInterview() {
    this._editor.interview.Languages = [];
    this._editor.interview.ShowLanguage = [];
    for (let j = 0; j < this.analysis.Summary.length; j++) {
      this._editor.interview.Languages.push(this.analysis.Summary[j].Language.Region);
      this._editor.interview.ShowLanguage.push(this.analysis.Summary[j].Visible);
    }
  }

  get DefaultLanguage(): LanguageSummary {
    if (this.analysis == null) {
      return null;
    }

    for (let i = 0; i < this.analysis.Summary.length; i++) {
      if (this.analysis.Summary[i].Language.Region == this._editor.interview.DefaultLanguage) {
        return this.analysis.Summary[i];
      }
    }

    if (this.analysis.Summary.length > 0) {
      return this.analysis.Summary[0];
    }

    return null;
  }

  get allowLanguageChoice(): boolean {
    if (this._editor?.interview) {
      return this._editor.interview.AllowLanguageChoice;
    }

    return false;
  }

  changeShowLanguage(event) {
    this._editor.interview.AllowLanguageChoice = event.checked;
  }

  get summaries(): LanguageSummary[] {
    if (this.analysis == null) {
      this.interviewService.analyzeInterview(JsonCyclic.toJson(this._editor.interview)).subscribe(result => {
        this.analysis = result;
      },
        error => {
          this.analysis = new LanguageAnalysis();
        });

      return [];
    }

    return this.analysis.Summary;
  }

  openStyling() {
    let params: ParamModel[] = [];
    this.interviewService.interviewSetup(false, "", this.editor.interview, params).subscribe(
      result => {
        this.model = result;
        this.show = true;
      });
    this.dialogRef = this.dialog.open(this.styleDialog);

  }

  languageChanged(language: string) {
  }

  navigateQuestions($event) {

  }
}
