<mat-card [@flipInY]="animationState" class="dashboard-card">
  <div *ngIf="showSummary">
    <div class="fieldRow">
      <div>
        <button mat-mini-fab color="primary" aria-label="Refresh">
          <mat-icon>refresh</mat-icon>
        </button>
      </div>
      <div>
        <button mat-mini-fab color="primary" aria-label="Refresh"
                matTooltip="Gateway Activity focusses on messages going in and out of the DIY Surveys messaging gateways.">
          <mat-icon>info</mat-icon>
        </button>
      </div>
      <div>
        <button mat-mini-fab color="primary" (click)="filterResults()" aria-label="Filter">
          <mat-icon>tune</mat-icon>
        </button>
      </div>
      <div>
        <h3>Gateway Activity</h3>
      </div>
    </div>
    <table *ngIf="tempSummary | async; else loading" mat-table [dataSource]="dataSource">
      <ng-container matColumnDef="GatewayName">
        <th mat-header-cell *matHeaderCellDef> UID </th>
        <td mat-cell *matCellDef="let element"> {{element.GatewayName}}</td>
      </ng-container>

      <ng-container matColumnDef="When">
        <th mat-header-cell *matHeaderCellDef> Added </th>
        <td mat-cell *matCellDef="let element"> {{element.When  | date:'shortDate' : translate.currentLang}} </td>
      </ng-container>

      <ng-container matColumnDef="StatusString">
        <th mat-header-cell *matHeaderCellDef> Status </th>
        <td mat-cell *matCellDef="let element"> {{element.StatusString}} </td>
      </ng-container>

      <ng-container matColumnDef="Count">
        <th mat-header-cell *matHeaderCellDef> Messages </th>
        <td mat-cell *matCellDef="let element"> {{element.Count}} </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr mat-row class="users-element-row" *matRowDef="let row; columns: displayedColumns;" (click)="edit(row)"></tr>
    </table>
  </div>
  <div *ngIf="!showSummary">
    <div class="fieldRow">
      <div>
        <button mat-mini-fab color="primary" (click)="noSave()" aria-label="Back">
          <mat-icon>arrow_back</mat-icon>
        </button>
      </div>
      <div>
        <button mat-mini-fab color="primary" aria-label="Save">
          <mat-icon>save</mat-icon>
        </button>
      </div>
    </div>
    <div>
      <div>
        <h3>Gateway Activity</h3>
        <p>
          Gateway Activity focusses on messages going in and out of the DIY Surveys messaging gateways.
        </p>
      </div>
    </div>
    <div>
    </div>
  </div>
  <ng-template #userProfileTemplate let-element>
  </ng-template>
  <ng-template #loading>
    <div class="loading-container">
      <span class="loading-content">
        <div class="spinner-div"><mat-spinner [diameter]="25"></mat-spinner></div>
        <div class="spinner-text"> Loading analysis...</div>
      </span>
    </div>
    <br /><br />
  </ng-template>
</mat-card>
