<mat-toolbar class="mat-toolbar-container">
  <a [routerLink]="'/'"><img src="assets/img/logo.svg" class="toolbar-logo" /></a>
  <span class="toolbar-spacer"></span>
  <div class="toolbar-header-right-container" [class]="(isHandset$ | async) ? 'noDisplay' : ''">
    <a href="mailto:hello@diysurveys.com" class="toolbar-hellodiysurveyscom">hello&#64;diysurveys.com</a>
    <button mat-raised-button color="accent" [routerLink]="'/signup'">SIGN UP</button>
  </div>
</mat-toolbar>
<div class="toolbar-handset-container">
  <div class="toolbar-handset">
    <a [routerLink]="'/'"><img src="assets/img/logo.svg" class="toolbar-logo" /></a>
  </div>
  <div class="login-signup-div-handset" [class]="(isHandset$ | async) ? '' : 'noDisplay'">
    <button mat-raised-button color="accent" [routerLink]="'/signup'" class="loginPage-signup-btn-handset">SIGN UP</button>
  </div>
</div>
<div class="invite-layout-content">
  <div>
    <router-outlet></router-outlet>
  </div>
  <div class="fixed-footer" [class]="(isHandset$ | async) ? 'noDisplay' : ''">
    <div class="footer-container">
      <div class="footer-left-content">
        <div class="footer-version-div">
          <span>&#169;{{ currentYear }} DIY Surveys Ltd </span>&nbsp;<span>{{currentVersion}}</span>
        </div>
        <div class="footer-terms-div">
          <span><a href="#" [innerHTML]="(isHandset$ | async) ? 'Terms' : 'Terms of Service'"></a></span>
        </div>
        <div class="footer-privacy-div">
          <span><a href="#" [innerHTML]="(isHandset$ | async) ? 'Privacy' : 'Privacy Statement'"></a></span>
        </div>
      </div>
    </div>
  </div>
  <div class="handset-footer" [class]="(isHandset$ | async) ? '' : 'noDisplay'">
    <div class="handset-footer-email-container">
      <div>
        <a href="mailto:hello@diysurveys.com" class="handset-footer-email"><span>hello&#64;diysurveys.com</span></a>
      </div>&nbsp;&nbsp;&nbsp;&nbsp;
    </div>
    <div class="handset-footer-bottom-container">
      <div>
        <span>&#169;{{ currentYear }} <br />DIY Surveys Ltd </span>
      </div>
      <div>
        <span><a href="#">Terms of Service</a></span>
      </div>
      <div>
        <span><a href="#">Privacy Statement</a></span>
      </div>
    </div>
  </div>
</div>
