<div class="fieldRowWithPadding">
  <h3>Favorite Organizations</h3>
  <button mat-icon-button><mat-icon>settings</mat-icon></button>
</div>
<div class="fullWidth row-flex-flow">
  <div *ngFor="let org of organizations; let i = index" cdkDrag>
    <div class="fav-org-drag-div" *cdkDragPlaceholder></div>
    <mat-card style="" class="fav-org-card">
      <div>
        <h3>{{org.Name}}</h3>
      </div>
      <div>
        {{org.Description}}
      </div>
    </mat-card>
  </div>
</div>
