<div class="comm-list-main-container">
  <div class="simple-row">
    &nbsp;
    <button mat-stroked-button [matMenuTriggerFor]="ioMenu">
      <mat-icon>expand_more</mat-icon>
      <span>Create</span>
    </button>&nbsp;
    <button mat-icon-button (click)="getComms()">
      <mat-icon>refresh</mat-icon>
    </button>
    <mat-menu #ioMenu="matMenu">
      <button mat-menu-item [matMenuTriggerFor]="emailMenu">
        <span>Email</span>
      </button>
      <button *ngIf="whatsAppStatus == 3 || key != null" mat-menu-item [matMenuTriggerFor]="whatsAppMenu">
        <span>WhatsApp</span>
      </button>
      <button mat-menu-item [matMenuTriggerFor]="smsMenu">
        <span>SMS.</span>
      </button>
    </mat-menu>
    <mat-menu #emailMenu="matMenu">
      <button mat-menu-item *ngFor="let item of emailTypes" (click)="newComm(0, item.id)">
        <span>{{item.name}}</span>
      </button>
    </mat-menu>
    <mat-menu #whatsAppMenu="matMenu">
      <button mat-menu-item *ngFor="let item of whatsAppTypes" (click)="newComm(1, item.id)">
        <span>{{item.name}}</span>
      </button>
    </mat-menu>
    <mat-menu #smsMenu="matMenu">
      <button mat-menu-item *ngFor="let item of smsTypes" (click)="newComm(2, item.id)">
        <span>{{item.name}}</span>
      </button>
    </mat-menu>
  </div>
  <mat-accordion>
    <mat-expansion-panel *ngFor="let detail of details; let i = index" hideToggle (afterExpand)="selectedIndex = i" [expanded]="i == selectedIndex">
      <mat-expansion-panel-header>
        <mat-panel-title>
          <div class="simple-row">
            <mat-icon class="mini-fab">{{detail.icon}}</mat-icon><span class="user-details-expansion-panel-title">{{detail.name}}&nbsp;{{detail.comms?.length > 0 ? '(' + detail.comms?.length + ')' : ''}}</span>
          </div>
        </mat-panel-title>
        <mat-panel-description>

        </mat-panel-description>
      </mat-expansion-panel-header>
      <div class="margin-5" *ngIf="i == 1 && whatsAppStatus != 3 && key == null">
        <h3>Welcome to the DIY Surveys' WhatsApp Business solution</h3>
        <p *ngIf="whatsAppStatus == -1">
          In order to start using WhatsApp you need to complete our <a [routerLink]="'/whatsapp/0/whatsapp/0/comms'">Application Form</a>.
        </p>
        <p *ngIf="whatsAppStatus == 0">
          You have completed the WhatsApp Application Form and it is waiting for approval.
          <a [routerLink]="'/whatsapp/0/whatsapp/0/profile'">Click here</a> to review the Application.
        </p>
        <p *ngIf="whatsAppStatus == 1">
          Your application has been approved and DIY Surveys is connecting your account to your WhatsApp Business Account.<br />
          You will be notified as soon as this complete.
        </p>
        <p *ngIf="whatsAppStatus == 2">
          Your application has been rejected with the basic feedback:<br />
          {{application?.Data.StatusMessage}}<br /><br />
          For more information please contact the Client Services Team.
        </p>
      </div>
      <div *ngIf="detail.comms?.length < 1 && i == 1 && (whatsAppStatus == 3 || key != null)">
        There are no {{detail.name}} definitions
      </div>
      <div *ngIf="detail.comms?.length < 1 && i != 1">
        There are no {{detail.name}} definitions
      </div>
      <div>
        <app-comm-editor *ngFor="let comm of detail.comms; let j = index" [comm]="comm" (closed)="commClosed($event, j)"></app-comm-editor>
      </div>
    </mat-expansion-panel>
  </mat-accordion>
</div>
