import { Component, OnInit, ElementRef, ViewChild, Input, Optional, Self, OnDestroy } from '@angular/core';
import { NgControl, ControlValueAccessor } from '@angular/forms';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { Subject } from 'rxjs';
import { FocusMonitor } from '@angular/cdk/a11y';
import { MatFormFieldControl } from '@angular/material/form-field';

@Component({
  selector: 'category-button',
  templateUrl: './category-button.component.html',
  styleUrls: ['./category-button.component.css'],
  providers: [{ provide: MatFormFieldControl, useExisting: CategoryButtonComponent }]
})
export class CategoryButtonComponent implements OnInit, ControlValueAccessor, MatFormFieldControl<boolean>, OnDestroy {
  static nextId = 0;
  @ViewChild('button') button: ElementRef | undefined;
  name: string = "";
  stateChanges = new Subject<void>();
  onChange = (_: any) => {};
  onTouched = () => {};
  describedBy = '';

  constructor(
    @Optional() @Self() public ngControl: NgControl,
    private _focusMonitor: FocusMonitor
  ) {
    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }
  }

  id = 'category-button-${CategoryButtonComponent.nextId++}';
  focused = false;
  empty = false;
  shouldLabelFloat: boolean = true;
  errorState: boolean = false;
  controlType = 'category-button';

  setDescribedByIds(ids: string[]) {
    this.describedBy = ids.join(' ');
  }

  onContainerClick(event: MouseEvent): void {
    this.value = !this.value;
  }

  @Input()
  get placeholder(): string {
    return this._placeholder;
  }

  set placeholder(value: string) {
    this._placeholder = value;
    this.stateChanges.next();
  }

  private _placeholder: string = "";

  @Input()
  get value(): boolean {
    return this.set;
  }

  set value(selected: boolean) {
    this.set = selected;
  }

  @Input()
  get required(): boolean {
    return this._required;
  }

  set required(value: boolean) {
    this._required = coerceBooleanProperty(value);
    this.stateChanges.next();
  }

  private _required = false;

  @Input()
  get set(): boolean {
    return this._set;
  }

  set set(value: boolean) {
    this._set = coerceBooleanProperty(value);
    this.stateChanges.next();
  }
  private _set: boolean = false;

  @Input()
  get disabled(): boolean {
    return this._disabled;
  }

  set disabled(value: boolean) {
    this._disabled = coerceBooleanProperty(value);
    this.stateChanges.next();
  }

  private _disabled = false;

  ngOnInit(): void {
  }

  ngOnDestroy() {
    this.stateChanges.complete();
  }

  writeValue(set: boolean): void {
    this.value = set;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
}
