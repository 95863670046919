import { Component, OnInit, ViewChild, ViewContainerRef, ComponentRef, ComponentFactoryResolver, AfterContentInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { BaseQuestion, QuestionService, DataModel, InterviewService } from '../../question.service';
import { FormGroup, FormArray, Validators } from '@angular/forms';

@Component({
  selector: 'app-qeblock',
  templateUrl: './qe-block.component.html',
  styleUrls: ['./qe-block.component.css']
})
export class QeBlockComponent extends BaseQuestion implements OnInit, AfterContentInit {

  @ViewChild('blockContent', { static: false, read: ViewContainerRef }) public target: ViewContainerRef | undefined;
  components: ComponentRef<any>[] = [];
  qType: string = "";

  questions = [
    { id: 'Question1', value: 'Question 1' },
    { id: 'Question2', value: 'Question 2' },
    { id: 'Question3', value: 'Question 3' }
  ];

  constructor(private qs: QuestionService, private is: InterviewService, private resolver: ComponentFactoryResolver, public dialog: MatDialog) {
    super(qs, is, undefined, undefined, new DataModel("block"));

  }
  ngAfterContentInit() {

  }

  public blockFormGroup: FormGroup = new FormGroup({});

  ngOnInit() {
    this.blockFormGroup = this.fb.group({
      blockFormArray: this.fb.array([this.initQuestion()])
    });
  }

  get questionArr(): FormArray | null {
    if (this.blockFormGroup) {
      return this.blockFormGroup.get('blockFormArray') as FormArray;
    }

    return null;
  }

  onSubmit(): void {
  }

  addNewOption() {
    this.questionArr?.push(this.initQuestion());
  }

  addQuestion(qType: string) {
    let length = 0;
    if (this.target) {
      length = this.target.length;
    }

    this.qs.sendClickEvent({
      questionType: qType, action: "after",
      index: length - 1,
      parentIndex: this.model.Index,
      displayIndex: length - 1,
      displayParentIndex: this.model.DisplayIndex
    });
  }

  selectQuestion(index: number, parentIndex: number, displayIndex: number, displayParentIndex: number) {
    for (let i = 0; i < this.components.length; i++) {
      const ref = this.components[i] as ComponentRef<any>;
      const shouldPreview = displayIndex !== i;
      if (!shouldPreview) {
        setTimeout(() => {
          ref.location.nativeElement.scrollIntoView({
            //behavior: "smooth",
            block: "nearest",
            inline: "nearest"
          });
        }, 0);
        return;
      }
    }
  }
}
