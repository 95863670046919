import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { NewGroupComponent } from '../new-group/new-group.component';
import { Group, AuthenticationService } from '../../authentication.Service';

@Component({
  selector: 'app-policy-group-manager',
  templateUrl: './policy-group-manager.component.html',
  styleUrl: './policy-group-manager.component.css'
})
export class PolicyGroupManagerComponent {

  groups: Group[] = [];

  constructor(public dialog: MatDialog,
    private router: Router,
    private authService: AuthenticationService) {
    this.authService.getPolicyGroups().subscribe(
      result => { this.groups = result }
    );
  }

  createGroup() {
    const dialogRef = this.dialog.open(NewGroupComponent,
      {
        data: { id: 0, isPolicyGroup: true }
      });

    dialogRef.afterClosed().subscribe(result => {
      if (result?.data?.group?.GroupId != 0) {
        this.router.navigate(['/policygroup', result.data.group.GroupId]);
      }
    });
  }

  action(data) {
    switch(data.action) {
      case "edit":
        this.router.navigate(['/policygroup', data.id]);
        break;
      case "delete":
        for (let i = 0; i < this.groups.length; i++) {
          if (this.groups[i].GroupId == data.id) {
            let group = this.groups[i];
            group.IsDeleted = true;
            this.groups.splice(i, 1);
            this.authService.savePolicyGroup(group).subscribe(
              result => {
              }
            );
          }
        }

        break;
    }
  }
}
