<div class="outer-table-container mat-elevation-z8">

  <div class="file-status-table-container custom-scroll-bar">
    <table mat-table #table [dataSource]="processes"
           multiTemplateDataRows
           matSort
           matSortActive="amended"
           matSortDisableClear matSortDirection="desc">

      <!-- Name Column -->
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
        <td mat-cell *matCellDef="let process"> {{process.Name}} </td>
      </ng-container>

      <!-- Modified Column -->
      <ng-container matColumnDef="amended">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Modified </th>
        <td mat-cell *matCellDef="let process"> {{process.Amended | date:'shortDate': '' : translate.currentLang}} {{process.Amended | date:'mediumTime': '' : translate.currentLang}} </td>
      </ng-container>


      <!-- Progress Column -->
      <ng-container matColumnDef="progress">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Progress </th>
        <td mat-cell *matCellDef="let process">
          <div class="surv-dashBrd-progress-container">
            <div class="surv-dashBrd-progressBar-div">
              <mat-progress-bar mode="determinate" [value]="process.Progress"></mat-progress-bar>
            </div>
          </div>
        </td>
      </ng-container>

      <!-- Status -->
      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Status </th>
        <td mat-cell *matCellDef="let process">
          <mat-icon>{{status(process)}}</mat-icon>
        </td>
      </ng-container>

      <!-- Action -->
      <ng-container matColumnDef="action">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> </th>
        <td mat-cell *matCellDef="let process">
          <button mat-icon-button aria-label="Delete" (click)="delete(process)">
            <mat-icon>delete</mat-icon>
          </button>
        </td>
      </ng-container>

      <ng-container matColumnDef="expandedDetail">
        <td mat-cell *matCellDef="let process" [attr.colspan]="columnsToDisplay.length">
          <div class="file-status-element-detail"
               [@detailExpand]="process == expandedProcess ? 'expanded' : 'collapsed'">
            <div *ngFor="error of errors; let i = index;">
              <div class="file-status-element-description">
                <div>
                  {{errors[i].Line}}:{{errors[i].Col}}&nbsp;&nbsp;
                </div>
                <div>
                  {{errors[i].Message}}
                </div>
              </div>
            </div>
          </div>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="columnsToDisplay; sticky: true"></tr>
      <tr mat-row *matRowDef="let process; columns: columnsToDisplay;"
          class="file-status-element-row"
          [class.file-status-expanded-row]="expandedProcess === process"
          (click)="edit(process)">
      </tr>
      <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="file-status-detail-row"></tr>
    </table>
  </div>
  <mat-paginator [pageSize]="processPageSize" [length]="resultsLength"></mat-paginator>
</div>

