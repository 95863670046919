<div [formGroup]="ranges" class="range-input-container">
  <input class="range-input-element"
         formControlName="minRange" size="2"
         maxLength="2"
         aria-label="Minimum range"
         (input)="_handleInput(ranges.controls.minRange, maxRange)"
         #minRange>
  <span class="range-input-spacer">&ndash;</span>
  <input class="range-input-element"
         formControlName="maxRange"
         maxLength="2"
         size="2"
         aria-label="Maximum range"
         (input)="_handleInput(ranges.controls.maxRange)"
         (keyup.backspace)="autoFocusPrev(ranges.controls.maxRange, minRange)"
         #maxRange>
</div>
