import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { options, baseUrl } from "../environments/environment";
import { Observable } from "rxjs";

export class UserTinyUrl {
  UserTinyUrlId: number;
  Url: string;
  OwnerId: number;
  IsDeleted: boolean;
  Created: Date = new Date();
  Amended: Date = new Date();
  IsHttps: boolean = false;
  ValidationCode: string = "";
  IsValidated: boolean = false;

  constructor() {
    this.UserTinyUrlId = 0;
    this.Url = "";
    this.OwnerId = 0;
    this.IsDeleted = false;
    this.ValidationCode = "";
  }
}

@Injectable()
export class TinyUrlService {
  constructor(
    private http: HttpClient) {
  }

  public getTinyUrls(): Observable<any> {
    return this.http.get(baseUrl + 'TinyUrl/GetTinyUrls?id=0', options);
  }

  public getTinyUrlsForAccount(userId: number): Observable<any> {
    return this.http.get(baseUrl + 'TinyUrl/GetTinyUrlsForAccount?userId=' + userId, options);
  }

  public getTinyUrl(id: string): Observable<any> {
    return this.http.get(baseUrl + 'TinyUrl/GetById?id=' + id, options);
  }

  public setTinyUrl(model: UserTinyUrl): Observable<any> {
    return this.http.patch<UserTinyUrl>(baseUrl + 'TinyUrl/Update?id=0', JSON.stringify(model), options);
  }

  public checkUrl(url: string, id: string, txtCode: string) {
    return this.http.get(baseUrl + "TinyUrl/CheckUrl?url=" + url + "&id=" + id + "&txtCode=" + txtCode, options);
  }

  public getVerificationCode(): Observable<any> {
    return this.http.get(baseUrl + "TinyUrl/VerificationCode", options);
  }

  public deleteTinyUrl(id: number) {
    return this.http.delete(baseUrl + "TinyUrl/Delete?id=" + id, options).subscribe();
  }
}
