<mat-toolbar class="mat-elevation-z2 mat-toolbar-container">
  <a [routerLink]="'/'"><img src="assets/img/logo.svg" class="toolbar-logo" /></a>
  <span class="toolbar-spacer"></span>
  <div class="header-right-container" [style]="(isHandset$ | async) ? 'display: none;' : ''">
    <a href="mailto:hello@diysurveys.com" class="toolbar-hellodiysurveyscom">hello&#64;diysurveys.com</a>&nbsp;&nbsp;
    <button mat-raised-button color="primary" [routerLink]="'/login'">LOG IN</button>
  </div>
</mat-toolbar>
<div class="toolbar-handset-container">
  <div class="toolbar-handset">
    <a [routerLink]="'/'"><img src="assets/img/logo.svg" class="toolbar-logo" /></a>&nbsp;&nbsp;
  </div>
  <div class="login-signup-div-handset" [style]="(isHandset$ | async) ? '' : 'display: none;'">
    <button mat-raised-button color="primary" [routerLink]="'/login'" class="loginPage-signup-btn-handset">
      LOG IN
    </button>
  </div>
</div>
<div class="diy-landing-content-bgColor">
  <mat-grid-list [cols]="(isHandset$ | async) ? '1' : '4'" rowHeight="24vh" [style]="(isHandset$ | async) ? '' : 'top: 20px;'">
    <mat-grid-tile [colspan]="(isHandset$ | async) ? '1' : '1'" [rowspan]="(isHandset$ | async) ? '4' : '4'" class="diy-landing-gridList-bgColor">
      <form fxLayoutAlign="stretch" fxLayout="column" class="register-form" [formGroup]="formGroup" (ngSubmit)="onSubmit()">
        <div style="padding: 30px; display: flex; flex-direction: column;"
             [style]="(isHandset$ | async) ? 'top: 40px; padding: 0px 15px 0px 15px;' : 'top:0;'">
          <div style="text-align: left;" [style]="(isHandset$ | async) ? 'margin-bottom: 5px;' : 'margin-bottom: 15px;'">
            <span style="font-weight: 500;"
                  [style]="(isHandset$ | async) ? 'margin-bottom: 10px; font-size: 20px;': 'font-size: 20px;'">Create a FREE account</span>
          </div>

          <div class="register-first-tile-container">
            <div class="register-first-tile-div">
              <mat-form-field appearance="outline" class="fullWidth">
                <mat-label>First Name</mat-label>
                <input matInput placeholder="First Name" formControlName="firstname">
              </mat-form-field>
            </div>
          </div>
          <div class="register-first-tile-container">
            <div class="register-first-tile-div">
              <mat-form-field appearance="outline" class="fullWidth">
                <mat-label>Last Name</mat-label>
                <input matInput placeholder="Last Name" formControlName="lastname">
              </mat-form-field>
            </div>
          </div>
          <div class="register-first-tile-container">
            <div class="register-first-tile-div">
              <mat-form-field appearance="outline" class="fullWidth">
                <mat-label>Email</mat-label>
                <input matInput placeholder="Email" type="email" formControlName="email">
              </mat-form-field>
            </div>
          </div>
          <div class="register-first-tile-container">
            <div class="register-first-tile-div">
              <mat-form-field appearance="outline" class="fullWidth">
                <mat-label>Password</mat-label>
                <input matInput [type]="hide ? 'password' : 'text'" placeholder="Password" formControlName="password">
                <mat-icon class="reveal" matSuffix (click)="hide = !hide">{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
              </mat-form-field>
            </div>
          </div>
          <!--<mat-form-field appearance="outline">
            <input matInput placeholder="Username" formControlName="username">
          </mat-form-field>-->
          <div class="mt-10">
            <mat-checkbox formControlName="tandc">
              <span class="register-terms-agreement-span">
                I've read and agree to the <a (click)="openTerms()" style="cursor: pointer; color:#62a8ff;">terms</a> <br />and <a (click)="openPrivacy()" style="cursor: pointer; color:#62a8ff;">privacy policy</a>
              </span>
            </mat-checkbox>
          </div>
          <button mat-raised-button type="submit" color="accent">SIGN UP</button>
          <div *ngIf="displaySuccess">
            <div class="register-success-div">
              Please check your email for a verification link
            </div>
          </div>
          <div *ngIf="displayError" style="width: 225px; text-align: center; border-radius: 4px; border: 1px solid; color: #842029; background-color: #f8d7da; padding: 1rem; margin: 5px 0 0; ">
            <!--<div class="alert alert-danger" role="alert">
              {{errorMsg}}
            </div>-->
            {{errorMsg}}
          </div>
          <div class="register-or-separator">
            <span>Or Sign up with</span>
          </div>
          <div style="width: 100%; height: 50px; display: flex; flex-flow: row; background-color: #ffffff; align-items: center; margin: 0 0 30px 0;">
            <div style="width: 50%; margin: 0 -40px 0 18%;">
              <asl-google-signin-button type="icon" size="medium"></asl-google-signin-button>
            </div>
            <div class="social-login-separator">
            </div>
            <a mat-stroked-button href="#" (click)="logInWithFacebook()" style="width: 50%; border: none; height: 100%; padding-top: 7px;">
              <img src="assets/img/icon-facebook.png" />
            </a>
          </div>
        </div>
        <div style="text-align:center; margin: 30px 0;">Already have an account? <a [routerLink]="'/login'" style="color: #19284c; font-weight: 600; letter-spacing: 1px;">Log in</a></div>
      </form>
    </mat-grid-tile>
    <mat-grid-tile [colspan]="(isHandset$ | async) ? '1' : '3'" [rowspan]="(isHandset$ | async) ? '3' : '2'" style="background-color: #1a284d;" [class]="(isHandset$ | async) ? '' : 'diy-landing-grid-tile'">
      <div style="display: flex; flex-direction: column;"
           [style]="(isHandset$ | async) ? 'padding: 30px;' : 'padding: 50px 30px 30px 30px;'">
        <div style="margin: 0px 10px 0px 45px;">
          <span style="height: 38px; font-size: 28px; color: #ffffff; font-weight: 600; line-height: normal; height: 38px; font-family: Roboto; font-stretch: normal; font-style: normal; letter-spacing: 1.5px;"
                [style]="(isHandset$ | async) ? 'font-size: 24px;' : 'font-size: 28px;'">Step 1 - Complete the easy sign-up form and start using DIY Surveys today.</span>
        </div>
        <div style="margin: 10px 10px 10px 45px;">
          <span style="font-size: 16px; color: #ffffff; font-weight: 600; line-height: normal; font-family: Roboto; font-stretch: normal; font-style: normal; letter-spacing: 1px;"
                [style]="(isHandset$ | async) ? 'font-size: 24px;' : 'font-size: 16px;'">Next...</span>
        </div>
        <div style="color: #ffffff; padding: 0px 10px 0px 45px; display: flex; font-size: 16px; letter-spacing: 1.22px; font-weight: 300;">
          <div>
            <ol start="2">
              <li style="display: none;"><span>Sign up for your license free account</span></li>
              <li><span>Create a survey in minutes – 23 pre-programmed question types. Not a survey expert - Use our survey templates</span></li>
              <li><span>Upload your respondents, or use ours - 306+ million people, 130+ countries, 50+ demographics</span></li>
              <li><span>Preview the costs, launch your survey, see your results in real-time</span></li>
            </ol>
          </div>
        </div>
      </div>
    </mat-grid-tile>
    <mat-grid-tile [colspan]="(isHandset$ | async) ? '1' : '3'" [rowspan]="(isHandset$ | async) ? '2' : '2'" style="display: flex; flex-wrap: wrap;" [style]="(isHandset$ | async) ? 'margin-bottom: 80px;' : ' '">
      <div style="width: 100%; text-align: center; height: 100%;" [style]="(isHandset$ | async) ? 'padding: 0 0 80px 0;' : 'padding: 0 0 35px 0;'">
        <img src="assets/img/sign-up-image@3x.png" class="full-width-height" />
      </div>
    </mat-grid-tile>
  </mat-grid-list>
  <div class="fixed-footer" [style]="(isHandset$ | async) ? 'display:none;' : ''">
    <div class="footer-container">
      <div class="footer-left-content">
        <div class="footer-version-div">
          <span>&#169;{{ currentYear }} DIY Surveys Ltd </span>&nbsp;<span>{{currentVersion}}</span>
        </div>
        <div class="footer-terms-div">
          <span><a (click)="openTerms()" class="cursor-pt" [innerHTML]="(isHandset$ | async) ? 'Terms' : 'Terms of Service'"></a></span>
        </div>
        <div class="footer-privacy-div">
          <span><a (click)="openPrivacy()" class="cursor-pt" [innerHTML]="(isHandset$ | async) ? 'Privacy' : 'Privacy Statement'"></a></span>
        </div>
      </div>
    </div>
  </div>
  <div class="handset-footer" [style]="(isHandset$ | async) ? '' : 'display:none;'">
    <div class="handset-footer-email-container">
      <div>
        <a href="mailto:hello@diysurveys.com" class="handset-footer-email"><span>hello&#64;diysurveys.com</span></a>
      </div>&nbsp;&nbsp;&nbsp;&nbsp;
    </div>
    <div class="handset-footer-bottom-container">
      <div>
        <span>&#169;{{ currentYear }} <br />DIY Surveys Ltd </span>
      </div>
      <div>
        <span><a (click)="openTerms()" class="cursor-pt">Terms of <br />Service</a></span>
      </div>
      <div>
        <span><a (click)="openPrivacy()" class="cursor-pt">Privacy <br />Statement</a></span>
      </div>
    </div>
  </div>
</div>
