import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatChipInputEvent } from '@angular/material/chips';
import { Observable } from 'rxjs';
import { AuthenticationService } from '../../authentication.Service';
import { UserProfile, UserService } from '../../user.Service';
import { map, startWith, debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';

@Component({
  selector: 'app-user-search',
  templateUrl: './user-search.component.html',
  styleUrls: ['./user-search.component.css']
})
export class UserSearchComponent implements OnInit {

  separatorKeysCodes: number[] = [ENTER, COMMA];
  userCtrl = new FormControl('');
  filteredUsers: Observable<UserProfile[]>;
  @ViewChild('userInput') userInput: ElementRef<HTMLInputElement> | undefined;
  isAdmin = false;

  constructor(private authService: AuthenticationService,
    private userService: UserService) {
    this.isAdmin = this.authService.isAuthorized(['Admin']);
    this.filteredUsers = this.userCtrl.valueChanges.pipe(
      startWith(''),
      debounceTime(400),
      distinctUntilChanged(),
      switchMap(val => {
        return this.getUsers(val || '')
      })
    );
  }

  ngOnInit(): void {
  }

  @Input()
  get label(): string {
    return this._label;
  }
  set label(value: string) {
    this._label = value;
  }
  private _label: string = "";

  @Input()
  get users(): UserProfile[] {
    return this._users;
  }
  set users(value: UserProfile[]) {
    this._users = value;
  }
  private _users: UserProfile[] = [];

  @Input()
  get withOrgs(): boolean {
    return this._withOrgs;
  }
  set withOrgs(value: boolean) {
    this._withOrgs = value;
  }
  private _withOrgs: boolean = false;

  getUsers(searchTag: string) {
    if (searchTag === "") {
      return new Observable<UserProfile[]>();
    }

    if (this.isAdmin) {
      return this._withOrgs ? this.userService.findUserAndOrgs(searchTag) : this.userService.findUser(searchTag);
    }
    else {
      return this.authService.searchUsersForOrgOwner(searchTag);
    }
  }

  add(event: MatChipInputEvent): void {
    event.chipInput!.clear();
    this.userCtrl.setValue(null);
  }

  icon(user: UserProfile): string {
    return user.EmailAddress.includes("@") ? 'account_circle' : 'supervised_user_circle';
  }

  isOrganisation(user: UserProfile): boolean {
    return !user.EmailAddress.includes("@") || user.EmailAddress === user.UserName;
  }

  remove(user: UserProfile): void {
    for (let i = 0; i < this.users.length; i++) {
      if (user.UserID === this.users[i].UserID) {
        this.users.splice(i, 1);
        return;
      }
    }
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    this.users.push(event.option.value);
    if (this.userInput != undefined) {
      this.userInput.nativeElement.value = '';
    }

    this.userCtrl.setValue(null);
  }
}
