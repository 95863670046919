import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'snackbar-default',
  templateUrl: './snackbar-default.component.html',
  styleUrls: ['./snackbar-default.component.css']
})
export class SnackbarDefaultComponent implements OnInit {
  constructor() {
  }

  ngOnInit(): void {
  }
}
