<div *ngFor="let temp of tempArray; let i = index">
  <div *ngIf="display(i)" class="alert alert-primary row-flex align-start-center-flex" div>
    <div>
      <mat-icon class="dialog-close-icon mt-7">{{icon(i)}}</mat-icon>
    </div>
    <div class="flex-alignItems-center">
      <div>
        <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="icon-button with a menu">
          <mat-icon class="dialog-close-icon">more_vert</mat-icon>
        </button>
        <mat-menu #menu="matMenu">
          <button mat-menu-item (click)="onEdit(i)">Edit</button>
          <button mat-menu-item (click)="onDelete(i)">Delete</button>
        </mat-menu>
      </div>
      <div class="display-flex"><div><span>{{name(i)}}</span></div>&nbsp;<app-advanced-routing [display]="true" [rules]="rules(i)" class="fullWidth"></app-advanced-routing></div>
    </div>
  </div>
</div>
