<div [formGroup]="formGroup">
  {{ label }}
  <mat-form-field appearance="outline" class="advTimings-form-field">
    <mat-label>{{placeholder}}</mat-label>
    <input matInput [diyMatDatetimePicker]="picker" [placeholder]="placeholder" [formControl]="date"
           readonly>
    <mat-datepicker-toggle matSuffix [for]="$any(picker)"></mat-datepicker-toggle>
    <diy-mat-datetime-picker #picker [showSpinners]="true" [showSeconds]="true"
                             [stepHour]="1" [stepMinute]="1" [stepSecond]="1"
                             [touchUi]="false" color="primary" [enableMeridian]="false"
                             [disableMinute]="false" [hideTime]="false">
    </diy-mat-datetime-picker>
  </mat-form-field>&nbsp;
</div>
