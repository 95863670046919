import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { AuthenticationService } from '../../authentication.Service';
import { CommsService, WhatsAppApplication } from '../../comms.Service';
import { MissionService } from '../../MissionService';

@Component({
  selector: 'app-whatsapp-application',
  templateUrl: './whatsapp-application.component.html',
  styleUrls: ['./whatsapp-application.component.css']
})
export class WhatsappApplicationComponent {
  formGroup: FormGroup = new FormGroup({});
  subscription: Subscription;
  application: WhatsAppApplication = null;
  display: boolean = false;
  userId: number = 0;
  action: string = "comms";

  constructor(private router: Router,
    private actRoute: ActivatedRoute,
    private authenticationService: AuthenticationService,
    private commsService: CommsService,
    private missionService: MissionService,
    private snackbar: MatSnackBar
  ) {
    this.userId = this.actRoute.snapshot.params.userid;
    this.action = this.actRoute.snapshot.params.actionType;
    if (this.userId == 0) {
      this.commsService.getApplication().subscribe(
        result => {
          this.application = result ?? new WhatsAppApplication();
          this.populateForm();
          this.display = true;
        });
    }
    else {
      this.commsService.getApplicationForUser(this.userId).subscribe(
        result => {
          this.application = result ?? new WhatsAppApplication();
          this.populateForm();
          this.display = true;
        });
    }
    this.subscription = missionService.missionAnnounced$.subscribe(
      mission => {
        if (mission === "Back") {
          this.backtoCommunications();
        }
      });

  }

  get isAdminUser(): boolean {
    return this.authenticationService.isAuthorized(['Admin']);
  }

  onSubmit() {
    if (!this.formGroup.valid) {
      return;
    }

    this.application.CompanyName = this.application.Data.Company = this.formGroup.controls.CompanyName.value;
    this.application.Data.Region = this.formGroup.controls.Region.value;
    this.application.Data.CFBMID = this.formGroup.controls.CFBMID.value;
    this.application.Data.CFBURL = this.formGroup.controls.CFBURL.value;
    this.application.Data.Website = this.formGroup.controls.Website.value;
    this.application.Data.Vertical = this.formGroup.controls.Vertical.value;
    this.application.Data.MessageType = this.formGroup.controls.MessageType.value;
    let exampleString = this.formGroup.controls.Examples.value ?? "";
    let temp = exampleString.split(["\n\r", "\r\n"]) ?? [];
    this.application.Data.Examples = temp;
    this.application.Data.Spend = Number(this.formGroup.controls.Spend.value);
    this.application.Data.Volume = Number(this.formGroup.controls.Volume.value);
    this.application.Data.Peak = -1; // Number(this.formGroup.controls.Peak.value);
    this.application.Data.BrandName = this.formGroup.controls.BrandName.value;
    this.application.Data.Emails = []; // this.formGroup.controls.Emails.value;
    this.application.Data.StatusMessage = this.formGroup.controls.StatusMessage.value;
    this.application.Contents = JSON.stringify(this.application.Data);
    this.application.StatusId = Number(this.formGroup.controls.Status.value);
    this.commsService.saveApplication(this.application).subscribe(
      result => {
        this.openSnackbar("Successfully submitted your application", "");
        if (this.action != "manage") {
          this.backtoCommunications();
        }
      },
      error => {
        this.openSnackbar("There was a problem submitting your application", "Cancel");
      });
  }

  backtoCommunications() {
    this.subscription?.unsubscribe();
    switch (this.action) {
      case "manage":
        this.router.navigate(['/manageapplications']);
        return;
      case "user":
        this.router.navigate(['/edituser/' + this.application.OwnerId + '/user'])
        break;
      case "editprofile":
        this.router.navigate(['/profile']);
        return;
      default:
        this.router.navigate(['/communications']);
        return;
    }
  }

  populateForm() {
    this.formGroup = new FormGroup({
      CompanyName: new FormControl(this.application.CompanyName, Validators.required),
      Region: new FormControl(this.application.Data.Region, Validators.required),
      CFBMID: new FormControl(this.application.Data.CFBMID, Validators.required),
      CFBURL: new FormControl(this.application.Data.CFBURL, Validators.required),
      Website: new FormControl(this.application.Data.Website, Validators.required),
      Vertical: new FormControl(this.application.Data.Vertical, Validators.required),
      MessageType: new FormControl(this.application.Data.MessageType, Validators.required),
      Examples: new FormControl(this.application.Data.Examples.join("\r\n"), Validators.required),
      Spend: new FormControl(this.application.Data.Spend, Validators.required),
      Volume: new FormControl(this.application.Data.Volume.toString(), Validators.required),
      Peak: new FormControl(this.application.Data.Peak.toString(), Validators.required),
      BrandName: new FormControl(this.application.Data.BrandName, Validators.required),
      Emails: new FormControl(this.application.Data.Emails),
      Status: new FormControl(this.application.StatusId.toString()),
      StatusMessage: new FormControl(this.application.Data.StatusMessage)
    });
  }

  checkWhatsAppAccessToken() {
    this.router.navigate(["/securitykey", 0, 'whatsapp', this.userId, 'whatsapp']);
  }

  private openSnackbar(message: string, action: string) {
    if (action == "") {
      this.snackbar.open(message, action, { duration: 2000 });
    }
    else {
      this.snackbar.open(message, action, { duration: 7000 });
    }
  }
}
