import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormArray, Validators } from '@angular/forms';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { BaseQuestion, DataModel, InterviewService, QuestionService } from '../../question.service';

@Component({
  selector: 'app-mcsa',
  templateUrl: './mcsa.component.html',
  styleUrls: ['./mcsa.component.css']
})
export class McsaComponent extends BaseQuestion implements OnInit {
  constructor(private breakpointObserver: BreakpointObserver, private qs: QuestionService, private is: InterviewService) {
    super(qs, is, undefined, undefined, new DataModel("mcsa"));
  }

  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
    .pipe(map(result => result.matches));

  public mcsaFormGroup: FormGroup = new FormGroup({});

  ngOnInit() {
    this.mcsaFormGroup = this.fb.group({
      mcsaFormArray: this.fb.array([this.initQuestion()])
    });
  }

  onSubmit(): void {
  }

  get questionArr(): FormArray | null {
    if (this.mcsaFormGroup) {
      return this.mcsaFormGroup.get('mcsaFormArray') as FormArray;
    }

    return null;
  }
}
