<mat-card class="dashboard-card">
  <div class="fieldRow">
    <div>
      <button mat-mini-fab color="primary" aria-label="Refresh">
        <mat-icon>refresh</mat-icon>
      </button>
    </div>
    <div>
      <h3>Most Active Users</h3>
    </div>
  </div>
  <table *ngIf="tempSummary | async; else loading" mat-table [dataSource]="dataSource">
    <ng-container matColumnDef="UserID">
      <th mat-header-cell *matHeaderCellDef> UID </th>
      <td mat-cell *matCellDef="let element"> {{element.UserID}} </td>
    </ng-container>

    <ng-container matColumnDef="BusinessType">
      <th mat-header-cell *matHeaderCellDef> </th>
      <td mat-cell *matCellDef="let element">
        <div style="margin-top:10px">
          <mat-icon *ngIf="isTemplate(element)" [matBadge]="templateType(element)" matBadgeSize="small" [matBadgeColor]="templateType(element) == 'T' ? 'accent' : 'warn'">{{businessType(element)}}</mat-icon> <mat-icon *ngIf="!isTemplate(element)">{{businessType(element)}}</mat-icon>
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="CreateDate">
      <th mat-header-cell *matHeaderCellDef> Created </th>
      <td mat-cell *matCellDef="let element"> {{element.CreatedDate  | date:'short' : '' : translate.currentLang}} </td>
    </ng-container>

    <ng-container matColumnDef="LastLoggedIn">
      <th mat-header-cell *matHeaderCellDef> Last Logged In </th>
      <td mat-cell *matCellDef="let element"> {{element.LastLoggedIn  | date:'medium'}} </td>
    </ng-container>

    <ng-container matColumnDef="UserName">
      <th mat-header-cell *matHeaderCellDef> Username </th>
      <td mat-cell *matCellDef="let element">
        {{element.UserName}}<br />
        {{element.FirstName}}&nbsp; {{element.LastName}}<br />
        ({{element.EmailAddress}})
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <tr mat-row class="users-element-row" *matRowDef="let row; columns: displayedColumns;" (click)="edit(row)"></tr>
  </table>
  <ng-template #loading>
    <div class="loading-container">
      <span class="loading-content">
        <div class="spinner-div"><mat-spinner [diameter]="25"></mat-spinner></div>
        <div class="spinner-text"> Loading user analysis...</div>
      </span>
    </div>
    <br /><br />
  </ng-template>
</mat-card>
