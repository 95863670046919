<p>bubble-chart works!</p>
<ngx-charts-bubble-chart [animations]="false"
                         [view]="view"
                         [scheme]="colorScheme"
                         [results]="bubbleData"
                         [xAxis]="showXAxis"
                         [yAxis]="showYAxis"
                         [legend]="showLegend"
                         [showXAxisLabel]="showXAxisLabel"
                         [showYAxisLabel]="showYAxisLabel"
                         [xAxisLabel]="xAxisLabel"
                         [yAxisLabel]="yAxisLabel"
                         [yScaleMin]="yScaleMin"
                         [yScaleMax]="yScaleMax"
                         [minRadius]="minRadius"
                         [maxRadius]="maxRadius"
                         (select)="onSelect($event)"
                         (activate)="onActivate($event)"
                         (deactivate)="onDeactivate($event)">
</ngx-charts-bubble-chart>

