<div class="fullWidth">
  <mat-toolbar class="ans-option-toolbar">
    <span class="ans-option-title">{{title}}</span>
    <span class="spacer"></span>
    <mat-chip-listbox multiple>
      <mat-chip selected="false" (click)="onSort()"><mat-icon class="ans-option-small-icon">sort_by_alpha</mat-icon></mat-chip>
      <mat-chip #bulk selected="false" (click)="onBulkEdit()">{{bulkText}}</mat-chip>
    </mat-chip-listbox>
  </mat-toolbar>
  <div *ngIf="!isBulk">
    <div *ngFor="let opt of options; let i = index" class="advancedOptions fullWidth">
      <div class="ans-option-advOpt-div">
        <app-advanced-answer-option class="fullWidth" [isSms]="isSms" [userFeatures]="userFeatures" [model]="model" [parameter]="useParameters ? options[i].value : null" [category]="useParameters ? null : options[i].value"
                                    [optionType]="multipleChoices" [index]="i" [label]="label" [count]="options.length"
                                    (delete)="removeOption(i)" (change)="valueChanged($event)"
                                    (up)="moveUp(i)" (down)="moveDown(i)"></app-advanced-answer-option>
      </div>
      <div class="ans-option-addBtn-div">
        <button mat-mini-fab matTooltip="Add new answer option" matTooltipPosition="above"
                [style.visibility]="allowDeleteAdd && optionValid(i) ? 'display' : 'hidden'" (click)="addNewOption()">
          <mat-icon>add</mat-icon>
        </button>
      </div>
    </div>
  </div>
  <div *ngIf="isBulk" [formGroup]="form">
    <mat-form-field appearance="outline" class="fullWidth">
      <mat-label>{{label}}s</mat-label>
      <textarea matInput formControlName="options" class="scroll fullWidth"
                cdkTextareaAutosize
                #autosize="cdkTextareaAutosize"
                cdkAutosizeMinRows="1"
                cdkAutosizeMaxRows="10"></textarea>
    </mat-form-field>
  </div>
</div>
