<div>
  Associate Tiny URLs with your account and we will map them to our service for you.
  <div class="fieldRow">
    <div>
      <button mat-raised-button color="primary" (click)="addTinyUrl($event)">Create New Tiny URL</button>&nbsp;&nbsp;
    </div>
  </div>
  <br/>
  <div *ngIf="tempTinyUrls | async; else loading">
  <table *ngIf="tinyUrls.length > 0" mat-table [dataSource]="dataSource">
    <ng-container matColumnDef="Url">
      <th mat-header-cell *matHeaderCellDef> Url </th>
      <td mat-cell *matCellDef="let element"> {{element.Url ?? ".."}} </td>
    </ng-container>
    <ng-container matColumnDef="Status">
      <th mat-header-cell *matHeaderCellDef> Status </th>
      <td mat-cell *matCellDef="let element">
        <span mat-line *ngIf="element.IsValidated === false" class="alert alert-danger tUrl-status-span-width">{{errorRate(element)}}</span>
        <span mat-line *ngIf="element.IsValidated" class="alert alert-success tUrl-status-span-width">This Tiny URL is correctly configured</span>
      </td>
    </ng-container>
    <ng-container matColumnDef="CreateDate">
      <th mat-header-cell *matHeaderCellDef> Created </th>
      <td mat-cell *matCellDef="let element"> {{element.Created  | date:'short' : '' : translate.currentLang}} </td>
    </ng-container>
    <ng-container matColumnDef="ModifiedDate">
      <th mat-header-cell *matHeaderCellDef> Modified </th>
      <td mat-cell *matCellDef="let element"> {{element.Amended  | date:'short' : '' : translate.currentLang}} </td>
    </ng-container>
    <ng-container matColumnDef="Action">
      <th mat-header-cell *matHeaderCellDef mat-sort-header></th>
      <td mat-cell *matCellDef="let element">
        <div>
          <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="icon-button with a menu" (click)="$event.stopPropagation()">
            <mat-icon>more_vert</mat-icon>
          </button>
          <mat-menu #menu="matMenu">
            <button mat-menu-item [routerLink]='["/tinyurl", element.UserTinyUrlId, actionType, userId ]'>
              <mat-icon>edit</mat-icon>
              <span>Edit TinyUrl</span>
            </button>
            <button mat-menu-item (click)="deleteTinyUrl(element.UserTinyUrlId)">
              <mat-icon>delete</mat-icon>
              <span>Delete</span>
            </button>
          </mat-menu>
        </div>
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <tr mat-row class="users-element-row" *matRowDef="let row; columns: displayedColumns;" [routerLink]='["/tinyurl", row.UserTinyUrlId, actionType, userId ]'></tr>
  </table>
    </div>
</div>

<ng-template #loading>
  <div class="loading-container">
    <span class="loading-content">
      <div class="spinner-div"><mat-spinner [diameter]="25"></mat-spinner></div>
      <div class="spinner-text"> Please Wait...</div>
    </span>
  </div>
</ng-template>
