<div class="trans-item-container">
  <div class="chng-un-select-name-div">
    <form [formGroup]="formGroup">
      <div>
        <h2>Transfer {{title}} - {{name}}</h2>
      </div>
      <div class="alert alert-warning" role="alert">
        Warning. Please make sure you want to transfer this {{title.toLowerCase()}} before you continue.
      </div>
      <div>
        <mat-radio-group formControlName="type">
          <div>
            <mat-radio-button value="1">Select one of my organizations {{selected ? '': '(or my account)'}}</mat-radio-button>
          </div>
          <div>
            <mat-form-field appearance="outline">
              <mat-label>Organization</mat-label>
              <mat-select [disabled]="!orgSelected()" formControlName="name" (selectionChange)="alreadyExists()">
                <mat-option *ngFor="let org of organizations; let i = index" [value]="org.Name">{{org.Name}} - {{org.Description}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div *ngIf="exists && orgSelected()" class="alert alert-danger" role="alert">
            The {{title.toLowerCase()}} {{name}} already exists in {{username()}}
          </div>
          <div *ngIf="exists && orgSelected()" class="mb-30">
            <mat-form-field appearance="outline" class="password-field">
              <mat-label>New name</mat-label>
              <input formControlName="rename" matInput placeholder="New name">
              <mat-error *ngIf="formGroup.controls.rename.invalid">{{error}}</mat-error>
            </mat-form-field>
          </div>
          <div>
            <mat-radio-button value="2">Specify {{ isOrganization ? 'a' : 'an organization or' }} username</mat-radio-button>
          </div>
          <div>
            <mat-form-field appearance="outline">
              <mat-label>{{isOrganization ? 'Username' : 'Organization/Username'}}'</mat-label>
              <input formControlName="name2" [readonly]="orgSelected()" matInput [placeholder]="isOrganization ? 'Username' : 'Organization/Username'">
            </mat-form-field>
          </div>
          <div>
            <br/>
            <mat-divider></mat-divider>
            <br/>
          </div>
          <div>
            <p>
              If {{username()}} is using DIY Surveys the {{title.toLowerCase()}} will be transferred to their account and you will potentially lose access to it.
            </p>
          </div>
          <div *ngIf="transferType == 'survey'">
            <p>
              When transferring a survey the following information is included:
              </p>
              <ul>
                <li>The basic survey details</li>
                <li>The panel data associated with it</li>
                <li>The questionnaire</li>
              </ul>
            <p>
              If {{username()}} does not have access to the same channels and capabilities some features of the survey may not be available.
            </p>
          </div>
        </mat-radio-group>
      </div>
      <button mat-raised-button color="primary" (click)="onSubmit()" type="submit" [disabled]="formGroup.invalid">I understand, transfer this {{title}}</button>
    </form>
  </div>
</div>
