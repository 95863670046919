import { PositionStrategy, ScrollStrategy, ScrollStrategyOptions } from '@angular/cdk/overlay';
import { Component, OnInit, Input } from '@angular/core';
import { Subscription } from 'rxjs';
import { QuestionService } from '../question.service';

@Component({
  selector: 'app-question-action-buttons',
  templateUrl: './question-action-buttons.component.html',
  styleUrls: ['./question-action-buttons.component.css']
})
export class QuestionActionButtonsComponent {


  clickEventSubscription?: Subscription;
  isOpen = false;
  scrollStrategy?: ScrollStrategy;
  positionStrategy?: PositionStrategy;

  constructor(private readonly sso: ScrollStrategyOptions,
    private questionService: QuestionService)
  {
    this.scrollStrategy = this.sso.block();
  }

  click(clickFunction: string) {
    let qType = this.questionType;
    switch (clickFunction) {
      case "after":
      case "before":
        qType = "unknown";
        break;
      case "delete":
      case "copy":
        break;
    }

    this.questionService.sendClickEvent(
      { questionType: qType,
        action: clickFunction,
        index: this._index,
        parentIndex: this._parentIndex,
        displayIndex: this._displayIndex,
        displayParentIndex: this._displayParentIndex,
        channel: this.channel
      });
  }

  @Input()
  get questionType(): string {
    return this._questionType;
  }
  set questionType(value: string) {
    this._questionType = value;
  }
  private _questionType: string = "";

  @Input()
  get index(): number {
    return this._index;
  }
  set index(value: number) {
    this._index = value;
  }
  private _index: number = -1;

  @Input()
  get parentIndex(): number {
    return this._parentIndex;
  }
  set parentIndex(value: number) {
    this._parentIndex = value;
  }
  private _parentIndex: number = -1;

  @Input()
  get displayIndex(): number {
    return this._displayIndex;
  }
  set displayIndex(value: number) {
    this._displayIndex = value;
  }
  private _displayIndex: number = -1;

  @Input()
  get displayParentIndex(): number {
    return this._displayParentIndex;
  }
  set displayParentIndex(value: number) {
    this._displayParentIndex = value;
  }
  private _displayParentIndex: number = -1;

  @Input()
  get channel(): string {
    return this._channel;
  }
  set channel(value: string) {
    this._channel = value;
  }
  private _channel: string = "web";
}
