import { HttpClient } from "@angular/common/http";
import { baseUrl, options } from "../environments/environment";
import { Observable } from "rxjs";
import { Injectable } from "@angular/core";

export class CommsModel {
  EmailTemplateID: number = 0;
  Name: string = "";
  Description: string = "";
  EmailContent: string = "";
  UserID: number = 0;
  IsDeleted: boolean = false;
  CreatedDate: Date = new Date();
  LastUpdatedDate: Date = new Date();
  Subject: string = "";
  EmailType: number = 0;
  DefaultThemeID: number = 0;
}

export class WhatsAppFullTemplate {
  message: string = "";
  items: number = 0;
  template: WhatsAppTemplate = new WhatsAppTemplate();
}

export class WhatsAppTemplate {
  id: string = "";
  name: string = "";
  language: string = "";
  status: string = "";
  category: string = "";
  previous_category?: string = null;
  components: WhatsAppComponent[] = [];

  buildBasicTemplate() {
    this.components = [];
    let component = new WhatsAppComponent();
    component.type = "BODY";
    this.components.push(component);
  }
}

export class WhatsAppComponent {
  type: string = "";
  format?: string = null;
  text: string = "";
  example: WhatsAppExample = new WhatsAppExample();
  buttons?: WhatsAppButton[] = null
}

export class WhatsAppExample {
  body_text: any[] = [];
}

export class WhatsAppButton {
  type: string = "";
  text: string = "";
  url: string = "";
  phone_number: string = "";
}

@Injectable()
export class CommsService {

  constructor(private http: HttpClient) {
  }

  getComms(): Observable<CommsModel[]> {
    return this.http.get<CommsModel[]>(baseUrl + 'Communication/GetCommunications', options);
  }

  getComm(id: string): Observable<CommsModel> {
    return this.http.get<CommsModel>(baseUrl + 'Communication/GetCommunication?id=' + id, options);
  }

  setComm(model: CommsModel): Observable<any> {
    return this.http.post(baseUrl + 'Communication/Update', JSON.stringify(model), options);
  }

  testComm(model: CommsModel, destination: string): Observable<any> {
    return this.http.post(baseUrl + 'Communication/Test?destination=' + destination, JSON.stringify(model), options);
  }

  submitComm(model: CommsModel): Observable<any> {
    return this.http.post(baseUrl + 'Communication/submit', JSON.stringify(model), options);
  }

  deleteComm(model: CommsModel): Observable<any> {
    return this.http.post(baseUrl + 'Communication/delete', JSON.stringify(model), options);
  }

  getTemplatesToUse(channel: number): Observable<CommsModel[]> {
    return this.http.get<CommsModel[]>(baseUrl + 'Communication/GetTemplatesToUse?channel=' + channel, options);
  }

  getCommByType(emailTypeId: number): Observable<CommsModel> {
    return this.http.get<CommsModel>(baseUrl + 'Communication/GetCommunicationbyType?emailTypeId=' + emailTypeId, options);
  }

  getWhatsApptemplates(): Observable<CommsModel[]> {
    return this.http.get<CommsModel[]>(baseUrl + 'Communication/GetWhatsAppTemplates', options);
  }

  getCommsByChannel(channel: number): Observable<CommsModel[]> {
    return this.http.get<CommsModel[]>(baseUrl + 'Communication/GetCommsByChannel?channel=' + channel, options);
  }

  copyComm(etid: string): Observable<CommsModel> {
    return this.http.get<CommsModel>(baseUrl + 'Communication/CopyTemplate?eTemplateId=' + etid, options);
  }

  getApplication(): Observable<WhatsAppApplication> {
    return this.http.get<WhatsAppApplication>(baseUrl + 'Communication/GetApplication', options);
  }

  getApplicationForUser(userId: number): Observable<WhatsAppApplication> {
    return this.http.get<WhatsAppApplication>(baseUrl + 'Communication/GetApplicationForUser?userid=' + userId, options);
  }

  getApplications(): Observable<WhatsAppApplication[]> {
    return this.http.get<WhatsAppApplication[]>(baseUrl + 'Communication/GetApplications', options);
  }

  saveApplication(application: WhatsAppApplication): Observable<WhatsAppApplication> {
    return this.http.post<WhatsAppApplication>(baseUrl + 'Communication/SaveApplication', JSON.stringify(application), options);
  }
}

export class WhatsAppApplication {
  ApplicationId: number = 0;
  OwnerId: number = 0;
  CompanyName: string = "";
  StatusId: number = 0;
  Contents: string = "";
  IsDeleted: boolean = false;
  Created: Date = new Date();
  Amended: Date = new Date();
  Data: WhatsAppApplicationData = new WhatsAppApplicationData();
}

export class WhatsAppApplicationData {
  Company: string = "";
  Region: string = "";
  CFBMID: string = "";
  CFBURL: string = "";
  Website: string = "";
  Vertical: string = "";
  MessageType: string = "";
  Examples: string[] = [];
  Spend: number = 0;
  Volume: number = 0;
  Peak: number = 0;
  BrandName: string = "";
  Emails: string[] = [];
  StatusMessage: string = "";
}
