import { Component, OnInit } from '@angular/core';
import { FormGroup, FormArray, Validators } from '@angular/forms';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { BaseQuestion, DataModel, InterviewService, QuestionService } from '../../question.service';

@Component({
  selector: 'qe-app-bucket',
  templateUrl: './qe-bucket.component.html',
  styleUrls: ['./qe-bucket.component.css']
})
export class QeBucketComponent extends BaseQuestion implements OnInit {
  constructor(private breakpointObserver: BreakpointObserver, private qs: QuestionService, private is: InterviewService) {
    super(qs, is, undefined, undefined, new DataModel("bucket"));
  }

  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
    .pipe(map(result => result.matches));

  public bucketFormGroup: FormGroup = new FormGroup({});
  public optionFormGroup: FormGroup = new FormGroup({});

  ngOnInit() {
    this.bucketFormGroup = this.fb.group({
      bucketFormArray: this.fb.array([this.initQuestion()])
    });
  }


  initBucketOption(): FormGroup {
    return this.fb.group({
      bucketOption: ['', Validators.required]
    });
  }

  onSubmit(): void {
  }

  get questionArr() {
    return this.bucketFormGroup.get('bucketFormArray') as FormArray;
  }
}
