<div [formGroup]="formGroup">
  <div formArrayName="element" class="metadata-table-container">
    <div class="fieldRowWithPadding metadata-table-list" *ngFor="let opt of optionArr.controls; let i = index" [formGroupName]="i">
      <div>
        <mat-form-field appearance="outline">
          <mat-label> Name </mat-label>
          <input matInput placeholder="Name" formControlName="Name">
        </mat-form-field>&nbsp;
      </div>
      <div>
        <mat-form-field appearance="outline" class="metadata-table-type-field">
          <mat-label> Type </mat-label>
          <mat-select formControlName="DataType">
            <mat-option value="2">Text</mat-option>
            <mat-option value="1">Number</mat-option>
            <mat-option value="5">Boolean</mat-option>
            <mat-option value="3">Date/Time</mat-option>
            <mat-option value="7">Date</mat-option>
            <mat-option value="8">Time</mat-option>
          </mat-select>
        </mat-form-field>&nbsp;
      </div>
      <div class="wd-25-per">
        <mat-form-field appearance="outline" class="fullWidth">
          <mat-label> Value </mat-label>
          <input matInput placeholder="Value" formControlName="Value">
        </mat-form-field>
      </div>
      <div>
        <button mat-line-end mat-mini-fab matTooltip="Add" matTooltipPosition="above" (click)="addNewRow(i)">
          <mat-icon class="dialog-close-icon">add</mat-icon>
        </button>
      </div>
      <div>
        <button mat-line-end mat-mini-fab matTooltip="Remove" matTooltipPosition="above" (click)="deleteRow(i)">
          <mat-icon class="dialog-close-icon">remove</mat-icon>
        </button>
      </div>
    </div>
  </div>
</div>
