<div>
  <div *ngIf="inEditMode">
    <div>
      <h3>{{name}}</h3>
    </div>
    <div class="row-flex-flow">
      <div *ngIf="multiLine && inEditMode">
        <app-sms-editor #sms [text]="text"></app-sms-editor>
      </div>
      <div *ngIf="!multiLine && inEditMode" [formGroup]="formGroup" class="wd-80-per">
        <mat-form-field appearance="outline" class="fullWidth">
          <mat-label>{{name}}</mat-label>
          <input formControlName="message" matInput placeholder="{{name}}">
        </mat-form-field>
      </div>
      <div class="ml-5">
        <button mat-stroked-button (click)="save()">Save</button>
      </div>
    </div>
  </div>
  <div *ngIf="!inEditMode && text != ''">
    <div>
      <h3>{{name}}</h3>
    </div>
    <div class="row-flex-flow">
      <div class="wd-80-per">
        {{text}}
      </div>
      <div>
        <button mat-stroked-button (click)="inEditMode = true">Edit</button>
      </div>
    </div>
  </div>
  <div *ngIf="text == '' && !inEditMode">
    <button mat-stroked-button (click)="inEditMode = true">Create {{name}}</button>
  </div>
</div>
