<app-newtoolbar></app-newtoolbar>
<div #body class="body">
  <div id="home" class="header">
    <h2>
      About Us
    </h2>
    <p>
      The DIY Surveys team have been pioneering Mobile Market Research technologies since 2006.
    </p>
    <p>
      DIY Surveys is the culmination of those years of experience.
    </p>
    <p>
      Our survey software reflects a recognition that offer Self Service Survey software providers have forgotton their roots
      and made things too complicated in terms of usability and too expensive in terms of increased budget contraints.
    </p>
    <p>
      Most notable however, ths DIY Surveys team are global communications specialists and provide unrivalled connectvity to
      your respondents wherever they are in the world and whatever their preferred communication device is which means
      higher engagement, more high-quality reponses, and faster more effective project completion and business decisions.
    </p>
  </div>
</div>
<app-newfooter></app-newfooter>
