<app-breadcrumb></app-breadcrumb>
<div *ngIf="tempComms | async; else loading" class="comm-top-container">
  <div>
    <button mat-raised-button color="primary" [routerLink]="['/edittemplate', '0', 'template']">Create New Template</button>&nbsp;&nbsp;
    <mat-button-toggle-group #group="matButtonToggleGroup" [value]="toggle" (change)="toggleView($event)">
      <mat-button-toggle value="card" aria-label="Modular View">
        <mat-icon>view_module</mat-icon>
      </mat-button-toggle>
      <mat-button-toggle value="list" aria-label="List View">
        <mat-icon>view_list</mat-icon>
      </mat-button-toggle>
    </mat-button-toggle-group>
  </div>&nbsp;&nbsp;
</div>
<div *ngIf="toggle == 'card'">
  <div class="comm-card-container">
    <div class="comm-card-content">
      <mat-card *ngFor="let comm of comms" class="comm-card">
        <mat-card-header class="row-flex">
          <div class="auto-flex"></div>
          <mat-card-title>
            {{comm.Name}}
          </mat-card-title>
          <div class="auto-flex"></div>
        </mat-card-header>
        <mat-card-content>
          {{comm.Description}}
          <br /><br />
          Created : {{comm.CreatedDate | date:'shortDate'}} | Modified: {{comm.LastUpdatedDate | date:'shortDate'}}
        </mat-card-content>
        <mat-card-actions>
          <!--<button mat-button [routerLink]="'/survey-details'">EDIT</button>-->
          <button mat-icon-button colour="primary" [routerLink]="['/edittemplate', comm.EmailTemplateID, 'template']">
            <mat-icon>edit</mat-icon>
          </button>
          <button mat-icon-button colour="primary">
            <mat-icon>delete</mat-icon>
          </button>
          <button mat-icon-button colour="primary">
            <mat-icon>file_copy</mat-icon>
          </button>
        </mat-card-actions>
      </mat-card>
    </div>
  </div>
</div>
<div *ngIf="toggle == 'list'">
  <div>
    <div class="fullWidth flex-row-wrap">
      <mat-card *ngFor="let comm of comms" class="comm-list-card">
        <div class="rightFloat">
          <mat-card-actions>
            <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="icon-button with a menu">
              <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu #menu="matMenu">
              <button mat-menu-item [routerLink]="['/edittemplate', comm.EmailTemplateID, 'template']">
                <mat-icon>edit</mat-icon>
                <span>Edit</span>
              </button>
              <button mat-menu-item>
                <mat-icon>delete</mat-icon>
                <span>Delete</span>
              </button>
              <button mat-menu-item>
                <mat-icon>file_copy</mat-icon>
                <span>Copy</span>
              </button>
            </mat-menu>
          </mat-card-actions>
        </div>
        <div>
          <div class="fullWidth">
            <mat-card-header class="row-flex">
              <mat-card-title>
                {{comm.Name}}
              </mat-card-title>
            </mat-card-header>
            <mat-card-content class="comm-list-card-content">
              <span>{{comm.Description}} | Created : {{comm.CreatedDate | date:'shortDate'}} | Modified: {{comm.LastUpdatedDate | date:'shortDate'}}</span>
            </mat-card-content>
          </div>
        </div>
      </mat-card>
    </div>
  </div>
</div>

<ng-template #loading>
  <div class="loading-container">
    <span class="loading-content">
      <div class="spinner-div"><mat-spinner [diameter]="25"></mat-spinner></div>
      <div class="spinner-text"> Please Wait...</div>
    </span>
  </div>
</ng-template>


