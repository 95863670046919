import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { StyleModel } from '../../../question.service';

@Component({
  selector: 'diy-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements OnInit {

  type: string = "";
  navColor: string = "";
  isNavColor: boolean = false;

  @ViewChild('navBtn ') navBtn: ElementRef | undefined;

  constructor() { }

  ngOnInit(): void {
  }

  @Input()
  get color(): string {
    return this._color;
  }
  set color(value: string) {
    this._color = value;
    this.isNavColor = this._color !== undefined && this._color != "";
  }
  private _color: string = "";

  @Input()
  get navTxtColor(): string {
    return this._navTxtColor;
  }
  set navTxtColor(value: string) {
    this._navTxtColor = value;
  }
  private _navTxtColor: string = "";

  @Input()
  get navigation() {
    return this._navigation;
  }
  set navigation(value: any) {
    this._navigation = value;
  }
  private _navigation: any;

  @Output()
  navigate: EventEmitter<this> = new EventEmitter<this>();

  registerOnNavigate(fn: any): void {
    this.onNavigate = fn;;
  }

  onNavigate(type: string) {
    this.type = type;
    this.navigate.emit(this);
  }

  getNavigationId(type: string): number {
    switch (type) {
      case "next":
        return 0;
      case "prev":
        return 1;
      case "stop":
        return 5;
      default:
        return -1;
    }
  }

  getNavigation(type: string) {
    if (this.navigation === undefined) {
      return undefined;
    }

    let id = this.getNavigationId(type);

    if (id === -1) {
      return undefined;
    }

    for (let i = 0; i < this.navigation.Items.length; i++) {
      if (this.navigation.Items[i].Type === id) {
        return this.navigation.Items[i];
      }
    }

    return undefined;
  }
}
