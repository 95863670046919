import { Component, OnInit } from '@angular/core';
import { single } from '../../data.service';

@Component({
  selector: 'app-grid-pie-chart',
  templateUrl: './grid-pie-chart.component.html',
  styleUrls: ['./grid-pie-chart.component.css']
})
export class GridPieChartComponent implements OnInit {

  single: any[] = [];
  view: [number, number] = [500, 400];

  // options
  showLegend: boolean = true;
  showLabels: boolean = true;

  colorScheme: any = {
    domain: ['#5AA454', '#E44D25', '#CFC0BB', '#7aa3e5', '#a8385d', '#aae3f5']
  };

  constructor() {
    Object.assign(this, { single });
  }
  ngOnInit(): void {
  }

  onSelect(event: any) {
  }
}
