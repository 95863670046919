import { options, baseUrl } from "../environments/environment";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { CintFeasibility } from "./cint.Service";

@Injectable()
export class PanelService {

  constructor(private client: HttpClient) {}

  getPanels() {
    return this.client.get<PanelModel[]>(baseUrl + 'Panel/GetPanels', options);
  }

  getPagedPanels(page: number, pageSize: number) {
    return this.client.get<PanelModel[]>(baseUrl + 'Panel/GetPagedPanels?page=' + page + '&pageSize=' + pageSize);
  } 

  panelCount() {
    return this.client.get<number>(baseUrl + 'Panel/PanelCount', options);
  }

  panelCountForUser(userid: string) {
    return this.client.get<number>(baseUrl + 'Panel/PanelCountForUser?userid=' + userid, options);
  }

  getTemplateProfile(panelId: number): Observable<any> {
    return this.client.get<any>(baseUrl + 'Panellist/GetTemplateProfile?panelID=' + panelId, options);
  }
  getPanelTemplates() {
    return this.client.get<PanelModel[]>(baseUrl + 'Panel/GetPanelTemplates', options);
  }

  getPanelsForSurvey(surveyId: number): Observable<PanelModel[]> {
    return this.client.get<PanelModel[]>(baseUrl + 'Panel/GetPanelsForSurvey?surveyid=' + surveyId, options);
  }

  getPanel(id: number): Observable<PanelModel> {
    return this.client.get<PanelModel>(baseUrl + 'Panel/GetPanel?id=' + id, options);
  }

  getPanelForSurvey(id: number): Observable<PanelModel> {
    return this.client.get<PanelModel>(baseUrl + 'Panel/GetPanelForSurvey?id=' + id, options);
  }

  getLatestPanel(userid: string) {
    return this.client.get<PanelModel>(baseUrl + 'Panel/GetLatestPanelForUser?userid=' + userid, options);
  }

  getPanelists(id: number, searchKey: string, page: number, pageSize: number): Observable<PanelistProfile[]> {
    return this.client.get<PanelistProfile[]>(baseUrl + 'Panel/GetPanelists?id=' + id + "&page=" + page + "&pageSize=" + pageSize, options);
  }

  getPanelist(id: number): Observable<PanelistProfile> {
    return this.client.get<PanelistProfile>(baseUrl + 'Panel/GetPanelist?id=' + id, options);
  }

  getPanelistByReferenceId(id: string): Observable<PanelistProfile> {
    return this.client.get<PanelistProfile>(baseUrl + 'Panellist/GetByReferenceId?id=' + id, options);
  }

  setPanelist(panelId: number, model: PanelistProfile) {
    return this.client.post(baseUrl + 'Panel/SetPanelist?panelid=' + panelId, model, options);
  }

  addPanelist(model: PanelistProfile) {
    return this.client.post(baseUrl + 'Panellist/AddPanellist', model, options);
  }

  addPanellistToNewPanel(model: PanelistProfile, name: string) {
    return this.client.post(baseUrl + 'Panellist/AddPanellistToNewPanel?name=' + name, model, options);
  }

  addUserPanel(name: string) {
    return this.client.post(baseUrl + 'Panel/AddUserPanel', { name: name }, options);
  }

  addPanel(model: PanelModel) {
    return this.client.post(baseUrl + 'Panel/Add', model, options);
  }

  updatePanel(model: PanelModel) {
    return this.client.patch(baseUrl + 'Panel/Update', model, options);
  }

  copyTemplatePanel(id: number) {
    return this.client.get(baseUrl + 'Panel/CopyTemplate?id=' + id, options);
  }

  copyPanel(id: number) {
    return this.client.get(baseUrl + 'Panel/Copy?id=' + id, options);
  }

  delete(id: number) {
    return this.client.delete(baseUrl + 'Panel/Delete?id=' + id, options);
  }

  deletePanellist(id: number, panellists: string) {
    return this.client.delete(baseUrl + 'Panellist/DeletePanellists?panelID=' + id + '&panellistIDs=' + panellists, options);
  }

  uploadPanel(id: number, filename: string) {
    return this.client.patch(baseUrl + 'Panel/FileUpload?id=' + id + "&filename=" + filename, options);
  }

  updateQuota(id: number, model: CintFeasibility): Observable<any> {
    return this.client.patch(baseUrl + 'Panel/UpdateQuota?id' + id, model, options);
  }
}

export class PanelSupport {
}

export class PanelModel {
  sources = [
    { name: 'Upload Your Respondents', icon: 'upload', img: 'assets/img/upload_2.png', description: 'Upload your own respondents using our respondent upload template and we will analyse them for the best delivery options.' },
    { name: 'Use Your Previous Respondents', icon: 'groups', img: 'assets/img/respondents_2.png', description: 'You have {{panelCount}} previous Respondent list(s) uploaded.' },
    { name: 'Buy Respondents for this Survey', icon: 'shopping_basket', img: 'assets/img/buy_2.png', description: 'Select and buy respondents from our cost effective Global panel (100+ million people, 60 countries, 50 socio-demographic profile elements)' },
    { name: 'Make Surveys Live Your Way', icon: 'share', img: 'assets/img/your_way_2.png', description: 'Having created your DIY Survey, you want to: embed it in your website, an email signature, post a link in social media, send via your own Email, SMS or Chat App, or generate our QR code and TinyUrl for respondents to engage with.' },
    { name: 'Select a Custom Import Data Source', icon: 'import_export', img: 'assets/img/your_way_2.png', description: 'For those who need a fully custom import using FPT, S3, etc, and multiple data formats.' }
  ];

  PanelID: number = 0;
  ThemeTemplateID: number = 0;
  Name: string = "";
  Description: string = "";
  PanelType: number = 0;
  SearchTag: string = "";
  Quota: string = "";
  Remark: string = "";
  IsDeleted: boolean = false;
  CreatedDate: Date = new Date();
  LastUpdatedDate: Date = new Date();
  UserID: number = 0;
  MediaID: number = 0;
  LogoID: number = 0;
  PanellistCount: number =0;
  Summary: PanelSummaryModel = new PanelSummaryModel();
  IsCint: boolean = false;

  getPanelSource(): any {
    if (this.IsCint) {
      return this.sources[2];
    }


  }
}

export class PanelSummaryModel {
  Emails: number = 0;
  Devices: number = 0;
  Countries: CountryProfileModel[] = [];
}

export class CountryProfileModel {
  CountryId: number = 0;
  Count: number = 0;
  Country: CountryModel = new CountryModel();
}

export class CountryModel {
  CountryID: number = 0;
  Name: string = "";
  CountryCode: string = "";
  Std: string = "";
  Gmt: Date = new Date();
  CreditRate: number = 0;
  IsDeleted: boolean = false;
}

export class EstimateModel {
  CompletionRate: number;
  Respondents: number;
  Rate: number;
  Total: number = 0;
  OutboundSms: number = 0;
  InboundSms: number = 0;
  constructor() {
    this.Rate = 1.2;
    this.CompletionRate = 100;
    this.Respondents = 100;
  }
}

export class PanelistProfile {
  PanelID: number = 0;
  PanellistID: number = 0;
  Title: string = "";
  FirstName: string = "";
  LastName: string = "";
  UserName: string = "";
  Email: string = "";
  MobileNumber: string = "";
  Dob: Date = new Date();
  Website: string = "";
  Address1: string = "";
  Address2: string = "";
  PostalCode: string = "";
  GeoLocation: string = "";
  CreatedDate: Date = new Date();
  LastUpdatedDate: Date = new Date();
  ProfileVariables: ProfileVariable[] = [];
  WhenTrigger: Date = new Date();
}

export class ProfileVariable {
  Id: number = 0;
  Name: string = "";
  TypeId: number = 0;
  TextVal: string = "";
  DateVal: Date = new Date();
  RealVal: number = 0;
  ObjectVal: object = null;

  constructor(id?: number, name?: string, typeId?: number) {
    if (id) {
      this.Id = id;
    }

    if (name) {
      this.Name = name;
    }

    if (typeId) {
      this.TypeId = typeId;
    }
  }
}
