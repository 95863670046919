import { AfterViewInit, Component, Inject, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { EditPanelComponent } from '../../edit-panel/edit-panel.component';

@Component({
  selector: 'app-panellist-dialog',
  templateUrl: './panellist-dialog.component.html',
  styleUrl: './panellist-dialog.component.css'
})
export class PanellistDialogComponent implements AfterViewInit {

  panelId: number;

  @ViewChild('panel') public panel: EditPanelComponent | undefined;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    this.panelId = data.panelId;
  }

  ngAfterViewInit(): void {
    this.panel?.initForm();
  }
}
