<div *ngIf="preview" matRipple>
  <app-advanced-properties [model]="model"></app-advanced-properties>
  <div class="question-var-container" (click)="click()">
    <div class="column-flex auto-flex fullWidth">
      <div class="ml-15 auto-flex" *ngIf="model.Banner !== ''">[{{ model.QuestionName }}]</div>
      <div class="auto-flex">
        <diy-player #canvas [show]="show" [model]="previewModel" [showJson]="false" [preview]="true"></diy-player>
      </div>
    </div>
    <div class="question-icon">
      <mat-icon>image</mat-icon>
    </div>
  </div>
  <div class="question-separator">
  </div>
</div>
<div *ngIf="!preview" [formGroup]="imageMapFormGroup">
  <div formArrayName="imageMapFormArray">
    <app-add-question-button action="before" (doAction)="questionAction($event)"></app-add-question-button>
    <app-advanced-properties [model]="model"></app-advanced-properties>
    <div *ngFor="let opt of questionArr?.controls; let i = index" [formGroupName]="i">
      <div class="question-var-container" (click)="click()">
        <mat-form-field appearance="outline">
          <mat-label>Question Identity</mat-label>
          <input matInput formControlName="qNumber" [patterns]="customPatterns" mask="SA*">
        </mat-form-field>
        <div class="question-icon">
          <div class="question-copy-btn-div">
            <button mat-mini-fab color="accent" matTooltip="Copy Question" (click)="questionAction('copy')"><mat-icon>content_copy</mat-icon></button>
          </div>
          <div>
            <button mat-mini-fab color="accent" matTooltip="Delete Question" (click)="questionAction('delete')"><mat-icon>delete</mat-icon></button>&nbsp;
          </div>
          <div class="question-type-icon-div">
            <mat-icon>image</mat-icon>
          </div>
        </div>
      </div>
      <div>
        <diy-editor [channel]="model.Interview.Channel" [questions]="model.Interview.reportableQuestions()" formControlName="banner"></diy-editor>
      </div>
      <div class="margin-top-bot-10 wrapFlex wd-90-per">
        <form [formGroup]="optionFormGroup" [class]="(isHandset$ | async) ? 'fullWidth' : 'wd-45-per'">
          <div [class]="(isHandset$ | async) ? 'imageMap-options-handset' : 'imageMap-options'">
            <div formArrayName="imageOptions" [class]="(isHandset$ | async) ? 'fullWidth' : 'fullWidth'">
              <div *ngFor="let opt of imageOptionArr.controls; let i = index" [formGroupName]="i">
                <mat-label> Image </mat-label>
                <input type="file" class="noDisplay" #fileInput (change)="onChangeFileInput($event)" formControlName="imageOption">
                <button mat-icon-button class="file-select-button" matTooltip="Upload image" matTooltipPosition="above"
                        (click)="onClickFileInputButton(i)">
                  <mat-icon>image</mat-icon>
                </button>
                <img *ngFor="let url of urls" [src]="url" class="width-100" />
              </div>
            </div>
          </div>
        </form>
        <div class="wrapFlex margin-top-bot-10">
          <mat-form-field appearance="outline">
            <mat-label> Row count </mat-label>
            <input matInput formControlName="rowCount">
          </mat-form-field>&nbsp;&nbsp;
          <mat-form-field appearance="outline">
            <mat-label> Column count </mat-label>
            <input matInput formControlName="colCount">
          </mat-form-field>
        </div>
      </div>
      <app-add-question-button action="after" (doAction)="questionAction($event)"></app-add-question-button>
    </div>
  </div>
</div>
