import { Component } from '@angular/core';
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MessageService, MobileCountryInfo } from '../../message.Service';
import { TranslateService } from '@ngx-translate/core';
import { NetworkService } from '../../network.Service';
import { Organization, AuthenticationService, OrganizationModel } from "../../authentication.Service";
import { ActivatedRoute, Router } from "@angular/router";
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { MediaService } from '../../media.Service';

class OrgList {
  selected: boolean;
  icon: string;
  name: string;
  email: string;
  value: number;
  memberId: number;
  mediaId: number;
  mediaService: MediaService;

  constructor(selected: boolean, icon: string, name: string, email: string, value: number, memberId: number, mediaId: number,
    mediaService: MediaService) {
    this.selected = selected;
    this.icon = icon;
    this.name = name;
    this.email = email;
    this.value = value;
    this.memberId = memberId;
    this.mediaId = mediaId;
    this.mediaService = mediaService;
  }

  get media() {
    if (this._media != null) {
      return this._media == "" ? null : this._media;
    }

    if (this.mediaId == 0) {
      this._media = "";
      return null;
    }

    this.mediaService.getMediaById(this.mediaId).subscribe(
      result => {
        this._media = result;
      },
      error => {
        this._media = "";
      });
  }
  private _media = null;
}

@Component({
  selector: 'app-buynumber',
  templateUrl: './buynumber.component.html',
  styleUrl: './buynumber.component.css'
})
export class BuynumberComponent {
  formGroup: FormGroup = new FormGroup({});
  errorMsg: string;
  displayError: boolean;
  displaySuccess: boolean;
  dataReady: boolean = false;
  countrycode: number = 0;
  countries: MobileCountryInfo[] = [];
  isAdminView = false;
  isMemberView = true;
  tempOrgs?: Observable<Organization[]>;
  orgPageSize: number = 20;
  userId: number = 0;
  organizations: Organization[] = [];
  orgList: OrgList[] = [];
  selectedOrg?: OrgList;
  ownOrganizations: boolean = false;

  constructor(private fb: FormBuilder,
    private authService: AuthenticationService,
    private actRoute: ActivatedRoute,
    public translate: TranslateService,
    private messageService: MessageService,
    private networkSerivce: NetworkService,
    private mediaService: MediaService
  ) {
    this.errorMsg = "";
    this.displayError = false;
    this.displaySuccess = false;
    this.messageService.getCountries().subscribe(result => {
      this.countries = result;
      this.orgList = [];
      this.checkOrg();
    });

    this.formGroup = new FormGroup({
      Country: new FormControl(),
      Organization : new FormControl(),
      feature: new FormControl(),
      numbertype: new FormControl(),
      number: new FormControl(),
      numberval: new FormControl()
    });

    this.userId = this.authService.getUserId();
    if (this.actRoute.snapshot.params.type == "user" && this.isAdminUser) {
      this.userId = Number(this.actRoute.snapshot.params.id);
    }

    this.getOrganizations();
  }

  get isAdminUser(): boolean {
    return this.authService.isAuthorized(['Admin']);
  }

  getOrganizations() {
    if (this.isAdminView) {
      this.tempOrgs = this.authService.getOrganizations().pipe(tap<Organization[]>(result => {
        this.organizations = result as Organization[];
      }));
    }
    else {
      this.isMemberView = true;
      if ((this.actRoute.snapshot.params.type == "user" && this.isAdminUser) || this.actRoute.snapshot.data.breadcrumb == "Profile"
        || this.actRoute.snapshot.data.breadcrumb == "Profile Organization") {
        this.tempOrgs = this.authService.getOrganizationsForSpecificMember(this.userId.toString()).pipe(tap<Organization[]>((result: Organization[]) => {
          this.organizations = result;
        }));
      }
      else {
        this.tempOrgs = this.authService.getOrganizationsForMember().pipe(tap<Organization[]>(result => {
          this.organizations = result as Organization[];
        }));
      }
    }
  }

  checkOrg() {
    let userId = this.authService.getUserId();

    this.authService.getOrganizationSummariesForMember().subscribe(result => {
      /*let m = this.orgList[0];
      m.selected = false;*/
      this.orgList = [];
      this.organizations = result;
      //this.orgList.push(m);
      let i = this.authService.currentOrganization();
      let found = false;
      for (let j = 0; j < this.organizations.length; j++) {
        let org = this.organizations[j];
        let o = new OrgList(false, org.OrganizationId == i ? 'supervised_user_circle' : 'supervisor_account', org.Name, "", org.OrganizationId, org.MemberId, org.MediaId, this.mediaService);
        if (org.RoleId == 5) {
          this.ownOrganizations = true;
        }

        if (o.value == i) {
          found = true;
          this.selectedOrg = o;
        }
        else {
          this.orgList.push(o);
        }
      }
    });
  }

  newSearch() {    
    this.networkSerivce.buynumberrequest(this.formGroup.value.Organization?.name,this.formGroup.value.Country?.Country, this.formGroup.value.feature,
      this.formGroup.value.numbertype, this.formGroup.value.number, this.formGroup.value.numberval)
      .subscribe(result => {
        this.displayError = false;
        this.displaySuccess = true;
      },
        error => {
          console.error(error);
          this.errorMsg = "There was a problem Sending your BuyNumber Request. Please Try again later.";
          this.displayError = true;
        });
  }
}
