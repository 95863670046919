export class AnswerModel {
  name: string = "";
  value: string = "";

  constructor(name?: string, value?: string) {
    if (name != undefined) {
      this.name = name;
    }

    if (value != undefined) {
      this.value = value;
    }
  }
}
