import { Component, OnInit } from '@angular/core';
import { MissionService } from "../../MissionService";
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { WebHookModel, WebHookType, WebHookService, WebHookEventModel } from "../../webhook.Service";
import { Subscription, Observable } from "rxjs";
import { tap } from 'rxjs/operators';
import { nanoid } from 'nanoid';

@Component({
  selector: 'app-webhook-details',
  templateUrl: './webhook-details.component.html',
  styleUrls: ['./webhook-details.component.css']
})
export class WebhookDetailsComponent implements OnInit {
  subscription: Subscription;
  formGroup: FormGroup;
  hook: WebHookModel;
  tempHook?: Observable<WebHookModel>;
  hookType: WebHookType;
  events: WebHookEventModel[] = [];
  tempEvents?: Observable<WebHookEventModel[]> | null;
  individualEvents: boolean = false;
  hookId: string;
  back: string = "/developer/webhooks";
  userId: number = 0;

  constructor(
    private missionService: MissionService,
    private router: Router,
    private hookService: WebHookService,
    private actRoute: ActivatedRoute
  ) {
    this.hook = new WebHookModel();
    this.hookType = new WebHookType();
    this.hookId = this.actRoute.snapshot.params.id;
    this.back = this.actRoute.snapshot.params.type;
    this.userId = this.actRoute.snapshot.params.userid;

    this.subscription = missionService.missionAnnounced$.subscribe(
      mission => {
        if (mission === "Back") {
          this.onSubmit();
        }
      });

    this.formGroup = new FormGroup({
      Tag: new FormControl("", [Validators.required]),
      Name: new FormControl("", [Validators.required]),
      PayloadUrl: new FormControl("", [Validators.required]),
      ContentTypeId: new FormControl(),
      Secret: new FormControl("", [Validators.required]),
      Created: new FormControl(),
      Amended: new FormControl(),
      ActiveStatus: new FormControl(),
      HookTypeIndicator: new FormControl(),
      Timeout: new FormControl(),
      TotalTimeout: new FormControl(),
      Retries: new FormControl(),
      Action: new FormControl(),
      Status: new FormControl(),
      Method: new FormControl(),
      hookTypeReceipt: new FormControl(),
      hookTypeLookup: new FormControl(),
      hookTypeMessage: new FormControl(),
      hookTypeInfo: new FormControl(),
      hookTypeHelp: new FormControl(),
      hookTypeStop: new FormControl(),
      hookTypeUnstop: new FormControl(),
      hookTypeKeyword: new FormControl(),
      hookTypeClick: new FormControl(),
      hookTypeKilled: new FormControl(),
      hookTypeNotKilled: new FormControl(),
      hookTypeOnStop: new FormControl(),
      hookTypeRouting: new FormControl(),
      hookTypeTimedOut: new FormControl()
    });
  }

  ngOnInit(): void {
    let i = "1";
    if (this.hookId === "0") {
      this.hook.Name = nanoid(40).replace('_', '').replace('-', '');
      this.formGroup.patchValue(this.hook);
      this.formGroup.controls['ContentTypeId'].setValue(this.hook.ContentTypeId.toString());
      this.formGroup.controls['Method'].setValue(this.hook.Method.toString());
      this.individualEvents = this.hook.HookType !== this.hookType.All;
      this.formGroup.controls['HookTypeIndicator'].setValue(i);
      this.setAllHookTypes();
      this.tempHook = new Observable(temp => { temp.next(this.hook); });
      this.events = [];
    } else {
      this.hookService.getWebHook(this.hookId).subscribe(
        result => {
          this.hook = result;
          this.formGroup.patchValue(this.hook);
          this.formGroup.controls['ContentTypeId'].setValue(this.hook.ContentTypeId.toString());
          this.formGroup.controls['Method'].setValue(this.hook.Method.toString());
          this.individualEvents = this.hook.HookType !== this.hookType.All;
          if (this.individualEvents) {
            i = "2";
          }
          this.formGroup.controls['HookTypeIndicator'].setValue(i);
          this.setAllHookTypes();
          this.tempEvents = this.hookService.getWebHookEvents((this.hookId)).pipe(tap<WebHookEventModel[]>(
            result => {
              this.events = result;
            }));
        });
    }
  }

  setAllHookTypes() {
    this.setHookType("hookTypeReceipt", this.hookType.Receipt);
    this.setHookType("hookTypeLookup", this.hookType.Lookup);
    this.setHookType("hookTypeMessage", this.hookType.Message);
    this.setHookType("hookTypeInfo", this.hookType.Info);
    this.setHookType("hookTypeHelp", this.hookType.Help);
    this.setHookType("hookTypeStop", this.hookType.Stop);
    this.setHookType("hookTypeUnstop", this.hookType.Unstop);
    this.setHookType("hookTypeKeyword", this.hookType.Keyword);
    this.setHookType("hookTypeClick", this.hookType.Click);
    this.setHookType("hookTypeKilled", this.hookType.Killed);
    this.setHookType("hookTypeNotKilled", this.hookType.NotKilled);
    this.setHookType("hookTypeOnStop", this.hookType.OnStop);
    this.setHookType("hookTypeRouting", this.hookType.RoutingFailure);
    this.setHookType("hookTypeTimedOut", this.hookType.TimedOut);
  }

  hookTypeChange($event: any) {
    this.individualEvents = $event.value === "2";
  }

  setHookType(name: string, value: number) {
    this.formGroup.controls[name].setValue((this.hook.HookType & value) === value &&
      this.hook.HookType !== this.hookType.All);
  }

  getHookType(name: string, value: number) {
    if (this.formGroup.controls[name].value) {
      this.hook.HookType = this.hook.HookType | value;
    } else {
      if ((this.hook.HookType & value) === value) {
        this.hook.HookType = this.hook.HookType ^ value;
      }
    }
  }

  eventType(status: number): string {
    if (status === this.hook.Status) {
      return "check_circle";
    }

    return "warning";
  }

  eventTypeColor(status: number): string {
    if (status === this.hook.Status) {
      return "";
    }

    return "warn";
  }

  eventTypeBadgeColor(status: number): string {
    if (status === this.hook.Status) {
      return "badge bg-secondary";
    }

    return "badge bg-danger";
  }

  eventTypeResponseColor(status: number): string {
    if (status === this.hook.Status) {
      return "primary";
    }

    return "warn";
  }

  htmlEncode(value: string) {
    return new DOMParser().parseFromString(value, "text/html").documentElement.textContent;
  }

  resend($event: any, id: number) {
    this.tempEvents = null;
    this.hookService.resendWebHook(id.toString()).subscribe(() => {
      this.tempEvents = this.hookService.getWebHookEvents((this.hookId)).pipe(tap<WebHookEventModel[]>(
        result => {
          this.events = result;
        }));
    });
  }

  goBack() {
    if (this.back == "profile") {
      this.router.navigate(['profile', this.userId]);
      return;
    }

    this.router.navigate(['edituser', this.userId, 'user']);
  }

  onSubmit() {
    if (this.formGroup.invalid) {
      this.goBack();
      return;
    }

    this.hook.Tag = this.formGroup.value.Tag;
    this.hook.Name = this.formGroup.value.Name;
    this.hook.PayloadUrl = this.formGroup.value.PayloadUrl;
    this.hook.OwnerId = this.userId;
    this.hook.ContentTypeId = Number(this.formGroup.value.ContentTypeId);
    this.hook.Secret = this.formGroup.value.Secret;
    if (this.formGroup.value.ActiveStatus) {
      this.hook.Active = this.formGroup.value.ActiveStatus === '1';
    }

    if (this.formGroup.value.HookTypeIndicator === "1") {
      this.hook.HookType = this.hookType.All;
    } else {
      this.hook.HookType = 0;
      this.getHookType("hookTypeReceipt", this.hookType.Receipt);
      this.getHookType("hookTypeLookup", this.hookType.Lookup);
      this.getHookType("hookTypeMessage", this.hookType.Message);
      this.getHookType("hookTypeInfo", this.hookType.Info);
      this.getHookType("hookTypeHelp", this.hookType.Help);
      this.getHookType("hookTypeStop", this.hookType.Stop);
      this.getHookType("hookTypeUnstop", this.hookType.Unstop);
      this.getHookType("hookTypeKeyword", this.hookType.Keyword);
      this.getHookType("hookTypeClick", this.hookType.Click);
      this.getHookType("hookTypeKilled", this.hookType.Killed);
      this.getHookType("hookTypeNotKilled", this.hookType.NotKilled);
      this.getHookType("hookTypeOnStop", this.hookType.OnStop);
      this.getHookType("hookTypeRouting", this.hookType.RoutingFailure);
      this.getHookType("hookTypeTimedOut", this.hookType.TimedOut);
    }

    this.hook.Timeout = Number(this.formGroup.value.Timeout);
    this.hook.TotalTimeout = Number(this.formGroup.value.TotalTimeout);
    this.hook.Retries = Number(this.formGroup.value.Retries);

    // Action Need to be figured out;

    this.hook.Status = Number(this.formGroup.value.Status);
    this.hook.Method = Number(this.formGroup.value.Method);

    this.hookService.setWebHook(this.hook).subscribe(() => {
      this.goBack();
    });
  }
}
