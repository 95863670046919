<div *ngIf="loaded | async;else loading">
  <div *ngFor="let mainSelection of getValues(); let i = index;">
    <div *ngFor="let selection of getSubValues(mainSelection); let j = index">
      <div *ngIf="false" class="fullWidth row-flex">
        &nbsp;&nbsp;&nbsp;&nbsp;{{selection.question.Name}}:&nbsp;
        <div *ngIf="selection.question.UseRanges" fxLayout="row">
          <div *ngFor="let category of selection.variables; let k = index" fxLayout="row">
            <div *ngIf="k > 0">,&nbsp;</div>
            <div>{{category.quota.TargetGroup.MinAge}} - {{category.quota.TargetGroup.MaxAge}}&nbsp;</div>
            <div *ngIf="category.quota.Limit != 100">({{category.quota.Limit}}%)</div>
          </div>
        </div>
        <div *ngIf="selection.question.UseRanges === false" fxLayout="row">
          <div *ngFor="let category of selection.variables; let k = index" fxLayout="row">
            <div *ngIf="k > 0">,&nbsp;</div>
            <div>{{category.variable.Name}}&nbsp;</div>
            <div *ngIf="category.quota.Limit != 100">({{category.quota.Limit}}%)</div>
          </div>
        </div>
      </div>
      <div>
        <div>
          <strong>{{selection.question.Name}}</strong>
        </div>
        <div *ngIf="selection.question.UseRanges == true">
          <div *ngFor="let category of selection.variables" class="audience-useRanges-content">
            <div>{{category.quota.TargetGroup.MinAge}} - {{category.quota.TargetGroup.MaxAge}}</div>
            <div>{{category.quota.Limit}}%</div>
          </div>
        </div>
        <div *ngIf="selection.question.UseRanges == false">
          <div *ngFor="let category of selection.variables" class="audience-useRanges-content">
            <div>{{category?.variable?.Name}}</div>
            <div>{{category?.quota?.Limit}}%</div>
          </div>
        </div>
        <div class="or-separator">&nbsp;</div>
      </div>
    </div>
  </div>
</div>
<ng-template #loading>
  <div class="loading-container">
    <span class="loading-content">
      <div class="spinner-div"><mat-spinner [diameter]="25"></mat-spinner></div>
      <div class="spinner-text"> Loading...</div>
    </span>
  </div>
</ng-template>

