import { Component, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSelectionList, MatSelectionListChange } from '@angular/material/list';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { AuthenticationService } from '../../authentication.Service';
import { MissionService } from '../../MissionService';
import { NetworkModel, NetworkService, NumberModel, RequestModel } from '../../network.Service';

@Component({
  selector: 'app-manage-multi-request',
  templateUrl: './manage-multi-request.component.html',
  styleUrls: ['./manage-multi-request.component.scss']
})
export class ManageMultiRequestComponent {
  subscription: Subscription;
  formGroup: FormGroup = new FormGroup({});
  networks: NetworkModel[] = [];
  singleNumberNetworks: NetworkModel[] = [];
  unavailable: NetworkModel[] = [];
  multiNumberNetworks: NetworkModel[] = [];
  capabilities: NetworkCapability[] = [];

  @ViewChild('countries') countries: MatSelectionList | undefined;

  constructor(public translate: TranslateService,
    private authenticationService: AuthenticationService,
    private missionService: MissionService,
    private snackbar: MatSnackBar,
    private networkService: NetworkService,
    private actRoute: ActivatedRoute,
    private router: Router
  ) {
    this.subscription = missionService.missionAnnounced$.subscribe(
      mission => {
        if (mission === "Back") {
          this.router.navigate(["/network"]);
        }
      });
    this.formGroup = new FormGroup({
      name: new FormControl(null, Validators.required),
      type: new FormControl(1, Validators.required),
      country: new FormControl(null, Validators.required),
      number: new FormControl(null, Validators.required),
      notes: new FormControl(null),
      status: new FormControl(null)
    });
    this.networkService.getNetworks().subscribe(result => {
      this.networks = result;
    });

  }

  twoWayChange() {
    this.networksChanged(null);
  }

  networksChanged(selections: MatSelectionListChange) {
    this.unavailable = [];
    this.singleNumberNetworks = [];
    this.multiNumberNetworks = [];
    for (let i = 0; i < this.countries.selectedOptions.selected.length; i++) {
      this.checkNetwork(this.countries.selectedOptions.selected[i].value);
    }
  }

  checkNetwork(network: NetworkModel) {
    network.SelectedNumbers = [];
    for (let i = 0; i < network.Numbers.length; i++) {
      if (this.formGroup.value.type == 1 && !network.Numbers[i].TwoWay || this.formGroup.value.type == 2 && network.Numbers[i].TwoWay) {
        network.SelectedNumbers.push(network.Numbers[i]);
      }
    }
    let list: NetworkModel[] = [];
    if (network.SelectedNumbers.length == 0) {
      list = this.unavailable;
    }
    else if (network.SelectedNumbers.length == 1) {
      list = this.singleNumberNetworks;
    }
    else {
      list = this.multiNumberNetworks;
    }

    list.push(network);
  }

  findCapability(name: string): NetworkCapability {
    for (let i = 0; i < this.capabilities.length; i++) {
      if (name == this.capabilities[i].Name) {
        return this.capabilities[i];
      }
    }

    let cap = new NetworkCapability();
    cap.Name = name;
    this.capabilities.push(cap);
    return cap;
  }

  getNetworkNames(networkType: number): string {
    switch (networkType) {
      case 0:
        return this.getNetworks(this.unavailable, false);
      case 1:
        return this.getNetworks(this.singleNumberNetworks, true);
      default:
        return this.getNetworks(this.multiNumberNetworks, true);
    }
  }

  getNetworks(list: NetworkModel[], showNumber: boolean): string {
    let result = "";
    for (let i = 0; i < list.length; i++) {
      if (i == 0) {
        result += list[i].Name;
      }
      else if (i + 1 == list.length) {
        result += " and " + list[i].Name;
      }
      else {
        result += ", " + list[i].Name;
      }

      if (showNumber) {
        result += " (" + list[i].SelectedNumbers[0].Number + ")"
      }
    }

    return result;
  }

  numberChange() {

  }
}

class NetworkCapability {
  Name: string = "";
  Total: number = 0;
  Partial: number = 0;
}
