import { HttpEventType } from '@angular/common/http';
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ImageCroppedEvent, ImageCropperComponent } from 'ngx-image-cropper';
import { MediaService } from '../media.Service';
import { ImageOnlyAlertComponent } from '../name-survey-from-scratch/image-only-alert/image-only-alert.component';

@Component({
  selector: 'app-image-crop',
  templateUrl: './image-crop.component.html',
  styleUrls: ['./image-crop.component.css']
})
export class ImageCropComponent implements OnInit {
  progress: number = 0;
  message:string = "";
  formGroup: FormGroup;
  mediaID: number = -1;

  @ViewChild('fileInput', { static: false }) fileInput: ElementRef | undefined;
  @ViewChild('imageCropper') imageCropper: ImageCropperComponent | undefined;
  @Output() public onUploadFinished = new EventEmitter();

  @Input() parentGroup: FormGroup = new FormGroup({});
  @Input() imgCropFormGroup: FormGroup = new FormGroup({});

  constructor(
    private dialog: MatDialog,
    private mediaService: MediaService
  ) {
    this.formGroup = new FormGroup({
      file: new FormControl(''),
    });
  }

  ngOnInit(): void {
  }

  onClickFileInputButton(): void {
    this.fileInput?.nativeElement.click();
  }

  imageChangedEvent: any = '';
  croppedImage: any = '';

  fileChangeEvent(event: any): void {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      const mimeType = file.type;
      if (mimeType.match(/image\/*/) == null) {
        this.dialog.open(ImageOnlyAlertComponent);
        return;
      }

      this.imageChangedEvent = event;
    }
  }
  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
  }
  imageLoaded() {
    /* show cropper */
  }
  cropperReady() {
    /* cropper ready */
  }
  loadImageFailed() {
    /* show message */
  }

  uploadImage(): Promise<number> {
    return new Promise((resolve, reject) => {
      this.mediaID = 0;
    this.mediaService.uploadBase64FileWithMediaId(this.croppedImage, this.mediaID).subscribe(
      event => {
        if (event.type === HttpEventType.UploadProgress)
          this.progress = Math.round(100 * event.loaded / event.total);
        else if (event.type === HttpEventType.Response) {
          this.mediaID = event.body.MediaID;
          this.message = 'Upload success.';
          this.onUploadFinished.emit(event.body);
          resolve(this.mediaID);
        }
      }, error => {
      let errorMessage = <any>error;
        if(errorMessage != null){
           reject(errorMessage);
        }
      });
    });
  }

  @Input()
  get mediaId(): number {
    return this.mediaID;
  }
  set mediaId(value: number) {
    this.mediaID = value;
    if (this.mediaID && this.mediaID > 0) {
      this.mediaService.getMediaById(this.mediaID).subscribe(
        result => {
          this.croppedImage = result;
        });
    }
  }
}
