import { Component } from '@angular/core';

@Component({
  selector: 'app-security-manager',
  templateUrl: './security-manager.component.html',
  styleUrl: './security-manager.component.css'
})
export class SecurityManagerComponent {

}
