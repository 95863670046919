import { Component, Input, OnDestroy, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { MissionService } from '../../MissionService';
import { Policy, Group, AuthenticationService, UserGroup, GroupUser } from '../../authentication.Service';
import { UserProfile } from '../../user.Service';

@Component({
  selector: 'app-user-group',
  templateUrl: './user-group.component.html',
  styleUrl: './user-group.component.css'
})
export class UserGroupComponent implements OnDestroy {
  groupDetailsForm: FormGroup;
  groupId: number;
  subscription: Subscription;
  policyGroups: Group[] = [];
  groups: Group[] = [];
  previews: boolean[] = [];
  users: UserProfile[] = [];
  accounts: UserProfile[] = [];

  constructor(public translate: TranslateService,
    private router: Router,
    private missionService: MissionService,
    private authService: AuthenticationService,
    private actRoute: ActivatedRoute
  ) {
    this.subscription = missionService.missionAnnounced$.subscribe(
      mission => {
        if (mission === "Back") {
          this.onSubmit();
        }
      });
    this.groupId = this.actRoute.snapshot.params?.id ?? -1;
    if (this.groupId > 0) {
      this.authService.getUserGroup(this.groupId).subscribe(
        result => { this.group = result; }
      );
      this.authService.getPoliciesForUserGroup(this.groupId).subscribe(
        result => {
          this.policyGroups = result;
          for (let i = 0; i < this.policyGroups.length; i++) {
            this.previews[i] = true;
          }
        }
      );
      this.authService.getUserProfilesForGroup(this.groupId).subscribe(
        result => {
          this.accounts = result;
        }
      )
    }

    this.initForm();
  }

  initForm() {
    this.groupDetailsForm = new FormGroup({
      Name: new FormControl("", [Validators.required]),
      Description: new FormControl("", [Validators.required])
    });
  }

  action(data: any) {
    switch (data.action) {
      case "edit":
        let i = this.findPolicyGroup(data.id);
        if (i == -1) {
          return;
        }

        this.previews[i] = false;
        break;
    }
  }

  findPolicyGroup(id: number) {
    for (let i = 0; i < this.policyGroups.length; i++) {
      if (this.policyGroups[i].GroupId == id) {
        return i;
      }
    }

    return -1;
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }

  clickOnButton(button: number) {
    switch (button) {
      case 0:
        this.onClick?.emit({ action: 'edit', id: this.group.UserGroupId });
        break;
      case 1:
        this.onClick?.emit({ action: 'delete', id: this.group.UserGroupId });
        break;
    }
  }

  addAccounts() {
    let groupUsers: GroupUser[] = [];
    for (let i = 0; i < this.users.length; i++) {
      let groupUser = new GroupUser();
      groupUser.GroupId = this.groupId;
      groupUser.UserId = this.users[i].UserID;
      groupUsers.push(groupUser);
      this.addToMembers(this.users[i]);
    }

    this.authService.saveGroupUsers(this.groupId, groupUsers).subscribe(
      result => {
        this.users = [];
      }
    );
  }

  addToMembers(member: UserProfile) {
    for (let i = 0; i < this.accounts.length; i++) {
      if (this.accounts[i].UserID == member.UserID) {
        return;
      }
    }

    this.accounts.push(member);
  }

  deleteMember(userId: number) {
    for (let i = 0; i < this.accounts.length; i++) {
      if (this.accounts[i].UserID == userId) {
        this.accounts.splice(i, 1);
        let groupUsers: GroupUser[] = [];
        for (let i = 0; i < this.accounts.length; i++) {
          let groupUser = new GroupUser();
          groupUser.GroupId = this.groupId;
          groupUser.UserId = this.accounts[i].UserID;
          groupUsers.push(groupUser);
        }

        this.authService.saveGroupUsers(this.groupId, groupUsers).subscribe(
          result => {
          }
        );
      }
    }
  }

  icon(user: UserProfile): string {
    return user.EmailAddress.includes("@") ? 'account_circle' : 'supervised_user_circle';
  }

  isOrganisation(user: UserProfile): boolean {
    return !user.EmailAddress.includes("@") || user.EmailAddress === user.UserName;
  }

  onSubmit() {
    this.router.navigate(["/security"]);
  }

  @Input()
  get group(): UserGroup {
    return this._group
  }
  set group(value: UserGroup) {
    this._group = value;
  }
  private _group: UserGroup = null;

  @Input()
  get preview(): boolean {
    return this._preview;
  }
  set preview(value: boolean) {
    this._preview = value;
  }
  private _preview: boolean = false;

  @Output()
  onClick: EventEmitter<any> = new EventEmitter<any>();

}
