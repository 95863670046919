<table mat-table [dataSource]="data" class="fullWidth">
  <ng-container matColumnDef="VariableName">
    <th mat-header-cell *matHeaderCellDef> Answered </th>
    <td mat-cell *matCellDef="let element"> {{element.VariableName}} </td>
  </ng-container>
  <ng-container matColumnDef="Response">
    <th mat-header-cell *matHeaderCellDef> Response </th>
    <td mat-cell *matCellDef="let element"> {{element.Response}} </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>
