<div *ngIf="processes.length > 0" class="outer-table-container mat-elevation-z8 fullWidth">

  <div class="inbox-table-container custom-scroll-bar">
    <table mat-table #table [dataSource]="dataSource"
           multiTemplateDataRows
           matSort
           matSortActive="amended"
           matSortDisableClear matSortDirection="desc">

      <!-- Name Column -->
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
        <td mat-cell *matCellDef="let process"> {{process.Name}} </td>
      </ng-container>

      <!-- Modified Column -->
      <ng-container matColumnDef="amended">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Modified </th>
        <td mat-cell *matCellDef="let process"> {{process.Created | date:'shortDate': '' : translate.currentLang}} {{process.Amended | date:'mediumTime': '' : translate.currentLang}} </td>
      </ng-container>


      <!-- Progress Column -->
      <ng-container matColumnDef="method">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Method </th>
        <td mat-cell *matCellDef="let process">{{type(process)}}</td>
      </ng-container>

      <!-- Status -->
      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Status </th>
        <td mat-cell *matCellDef="let process">{{getStatus(process)}}</td>
      </ng-container>

      <!-- Action -->
      <ng-container matColumnDef="action">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> </th>
        <td mat-cell *matCellDef="let process">
          <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="icon-button with a menu" (click)="$event.stopPropagation()">
            <mat-icon>more_vert</mat-icon>
          </button>
          <mat-menu #menu="matMenu">
            <button *ngIf="process.Status != 3" mat-menu-item (click)="edit(process)">
              <mat-icon>edit</mat-icon>
              <span>Edit</span>
            </button>
            <button mat-menu-item (click)="copy(process)">
              <mat-icon>content_copy</mat-icon>
              <span>Copy</span>
            </button>
            <button mat-menu-item (click)="export(process)">
              <mat-icon>ios_share</mat-icon>
              <span>Export</span>
            </button>
            <mat-divider></mat-divider>
            <button mat-menu-item (click)="delete(process)">
              <mat-icon>delete</mat-icon>
              <span>Delete</span>
            </button>
          </mat-menu>
        </td>
      </ng-container>

      <ng-container matColumnDef="expandedDetail">
        <td mat-cell *matCellDef="let process" [attr.colspan]="columnsToDisplay.length">
          <div class="example-element-detail"
               [@detailExpand]="process.ProcessId == expandedProcess?.ProcessId ? 'expanded' : 'collapsed'">
            <mat-tab-group mat-stretch-tabs="false" mat-align-tabs="start">
              <mat-tab label="Messages">
                <div class="margin-10">
                  <div *ngIf="messages.length == 0">
                    <div>
                      There is no activity recorded for this definition
                    </div>
                  </div>
                  <div *ngFor="let error of messages; let i = index;" class="fieldRowWithPadding">
                    <div>
                      <mat-icon>check_circle</mat-icon>
                    </div>
                    <div>
                      {{error.Created | date:'short': '' : translate.currentLang}}&nbsp;&nbsp;
                    </div>
                    <div>
                      {{error.Message}}
                    </div>
                  </div>
                </div>
              </mat-tab>
              <mat-tab label="Errors">
                <div class="margin-10">
                  <div *ngIf="errors.length == 0">
                    <div>
                      There is no activity recorded for this definition
                    </div>
                  </div>
                  <div *ngFor="let error of errors; let i = index;" class="fieldRowWithPadding">
                    <div>
                      <mat-icon color="warn">warning</mat-icon>
                    </div>
                    <div>
                      {{error.Created | date:'short': '' : translate.currentLang}}&nbsp;&nbsp;
                    </div>
                    <div *ngIf="errors[i].TypeId == 0">
                      {{error.Line}}:{{errors[i].Col}}&nbsp;&nbsp;
                    </div>
                    <div>
                      {{error.Message}}
                    </div>
                  </div>
                </div>
              </mat-tab>
            </mat-tab-group>
          </div>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="columnsToDisplay; sticky: true"></tr>
      <tr mat-row *matRowDef="let process; columns: columnsToDisplay;"
          class="users-element-row"
          (click)="viewErrors($event, process)">
      </tr>
      <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="conf-proc-detail-row"></tr>
    </table>
  </div>
</div>
