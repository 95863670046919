import { Component, ViewChild, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { Organization, OrganizationModel, AuthenticationService } from "../authentication.Service";
import { InvitationsComponent } from "../invitations/invitations.component";
import { InvitePeopleComponent } from "../invite-people/invite-people.component";
import { Router, ActivatedRoute, ActivatedRouteSnapshot } from '@angular/router';
import { MissionService } from "../MissionService";
import { Subscription }   from 'rxjs';

@Component({
  selector: 'app-organization-detail',
  templateUrl: './organization-detail.component.html',
  styleUrls: ['./organization-detail.component.css']
})
export class OrganizationDetailComponent implements OnInit {
  @ViewChild('invites') invites: InvitePeopleComponent | undefined;
  @ViewChild('invitations') invitations: InvitationsComponent | undefined;
  organizationId: number = 0;
  errorMsg: string = "";
  success: boolean = true;
  formGroup: FormGroup = new FormGroup({});;
  timeout = null;
  model: Organization = new Organization();
  subscription: Subscription;
  showAlert = false;
  alertMessage = "";
  showError = false;
  errorMessage = "";
  action: string = "organizations";

  constructor(private authService: AuthenticationService, missionService: MissionService,
    private router: Router,
    private actRoute: ActivatedRoute)
  {
    if (this.actRoute.snapshot.params.type == "smshomeorg") {
      this.action = "smshome";
    }
    else {
      this.action = actRoute.snapshot.data.breadcrumb == "Edit Organization" ? "organizations" : "adminorgs";
    }
    this.subscription = missionService.missionAnnounced$.subscribe(
      mission => {
        if (mission === "Back") {
          this.router.navigate([this.action]);
        }
      });
    this.organizationId = this.actRoute.snapshot.params.id;
    if (this.organizationId != 0) {
      this.authService.getOrganization(this.organizationId).subscribe(result => {
        this.model = result;
      });
    }
  }

  ngOnInit(): void {
  }

  invite() {
    if (this.invites == undefined) {
      return;
    }

    this.authService.invite(this.organizationId, this.invites.list).subscribe(result => {
      this.alertMessage = "Invitations successfully sent";
      this.showAlert = true;
      this.showError = false;
    },
      error => {
        this.errorMessage = "There was a problem sending the invites - please contact Client Services";
        this.showError = true;
        this.showAlert = false;
      });
  }

  ngOnDestroy() {
    // prevent memory leak when component destroyed
    this.subscription.unsubscribe();
  }
}
