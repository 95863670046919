import { Component, EventEmitter, OnInit, Input, Output } from '@angular/core';
// import { baseUrl, options } from "../../../environments/environment";
import { HttpEventType, HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-media-upload',
  templateUrl: './media-upload.component.html',
  styleUrls: ['./media-upload.component.css']
})
export class MediaUploadComponent implements OnInit {

 public progress: number = 0;
  public message: string = "";
  @Output() public onUploadFinished = new EventEmitter();

  constructor(private http: HttpClient) { }

  ngOnInit() {
  }

  uploadFile(files: any) {

  }

  /*public uploadFile = (files) => {
    if (files.length === 0) {
      return;
    }

    let fileToUpload = <File>files[0];
    const formData = new FormData();
    formData.append('file', fileToUpload, fileToUpload.name);

    /*this.http.post(baseUrl + 'media/upload', formData, {reportProgress: true, observe: 'events'})
      .subscribe(event => {
        if (event.type === HttpEventType.UploadProgress)
          this.progress = Math.round(100 * event.loaded / event.total);
        else if (event.type === HttpEventType.Response) {
          this.message = 'Upload success.';
          this.onUploadFinished.emit(event.body);
        }
      });
  }*/

  @Input()
  get question(): any {
    return this._question;
  }
  set question(value: any) {
    this._question = value;
    if (this._question === undefined) {
      return;
    }
  }
  private _question: any;

  get valid(): boolean {
    return true;
  }
}
