<div class="user-details-dialog-container">
  <div class="leftFloat">
    <h3>{{title}}</h3>
  </div>
  <div class="rightFloat">
    <button mat-icon-button mat-dialog-close>
      <mat-icon>close</mat-icon>
    </button>
  </div>
</div>
<div class="clearBoth">
  <div *ngIf="this.data.id == 0" class="alert alert-warning" role="alert">
    <p>
      For security reasons you cannot create users for general use, only templates for use by the system.<br />
      Unless you have an understanding of the platform please do not create any template users.
    </p>
    <p>
      All users need to be created by the user themselves so that security remains in place.
    </p>
  </div>
</div>
<div class="fullWidth align-center-center-flex fill-flex">
  <form class="chng-un-form column-flex align-center-start-flex" [formGroup]="formGroup">
    <div *ngIf="!newUser" class="alert alert-warning" role="alert">
      Unexpected bad things will happen if you don't read this!
    </div>
    <div *ngIf="!newUser && !isOrganization">
      <p>
        If you have customised message routing set up for your account please coordinate any change of username with the Client Services Team.
        (Usernames can be alphanumeric with special characters hyphen, underscore and fullstop, and no spaces.)
      </p>
    </div>
    <div *ngIf="isOrganization">
      <p>
        If you have customised message routing set up for your organization please coordinate any change of username with the Client Services Team.
        (Names can be alphanumeric with special characters hyphen, underscore and fullstop, and no spaces.)
      </p>
    </div>
    <div class="chng-un-controls-container">
      <div class="chng-un-select-name-div">
        <mat-form-field appearance="outline">
          <mat-label>{{ isOrganization ? 'Select Organization Name' : 'Select Username' }}</mat-label>
          <input formControlName="username" matInput [placeholder]="isOrganization ? 'Select Username' : 'Select Organization Name'" (keyup)="checkName($event)">
        </mat-form-field>
      </div>
    </div>
    <button mat-raised-button color="primary" (click)="onSubmit()" [disabled]="formGroup.invalid || !success">{{ buttonTitle }}</button>
  </form>
</div>
