import { Component, Inject, Input, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { ProcessService, ProcessServiceModel } from '../../process.Service';

@Component({
  selector: 'app-configured-processes-dialog',
  templateUrl: './configured-processes-dialog.component.html',
  styleUrls: ['./configured-processes-dialog.component.css']
})
export class ConfiguredProcessesDialogComponent implements OnInit {

  processes: ProcessServiceModel[] = [];
  page: number = 1;
  title: string = "Export Configurations";
  buttonText: string = "New Export";

  constructor(private dialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) data: any) {
    if (data) {
      this.title = data.title;
      this.processes = data.processes;
    }
  }

  ngOnInit(): void {
  }

  close() {
    this.dialogRef.close({ new: false, process: null });
  }

  newProcess() {
    this.dialogRef.close({ new: true, process: null });
  }

  selectProcess(type: boolean, process: ProcessServiceModel) {
    this.dialogRef.close({ new: false, process: process });
  }
}
