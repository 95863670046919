<div>
  <div class="mt-10 mb-10">
    <div class="fieldRowWithPadding">
      <button mat-stroked-button [matMenuTriggerFor]="filterMenu">
        <mat-icon>{{ noFilter() ? 'filter_alt_off' : 'filter_alt' }}</mat-icon>
        <span>Filter</span>
      </button>
      <mat-menu #filterMenu="matMenu">
        <button mat-menu-item (click)="showFilters()">
          <span>{{ noFilter() ? 'Add Filter...' : 'Edit Filter...' }}</span>
        </button>
        <button *ngIf="!noFilter()" mat-menu-item (click)="clearFilters()">
          <span>Clear Filter </span>
        </button>
      </mat-menu>
      &nbsp;
      <button [matMenuTriggerFor]="colmenu" mat-stroked-button>
        <mat-icon>view_column</mat-icon>
        <span>Column Selection</span>
        <mat-icon>arrow_drop_down</mat-icon>
      </button>
      <mat-menu #colmenu="matMenu">
        <button *ngFor="let column of columnsToView; let index = index" mat-menu-item (click)="$event.stopPropagation();toggleColumn(index)">
          <mat-checkbox [checked]="getColumnChecked(index)" class="mat-menu-item">{{column.Summary}}</mat-checkbox>
        </button>
      </mat-menu>
      &nbsp;
      <button *ngIf="mainFilterType > -1" mat-stroked-button [matMenuTriggerFor]="dataTypeMenu">
        <span>{{getMainFilter(mainFilterType).name}} ({{getMainFilter(mainFilterType).total}})</span>
        <mat-icon>arrow_drop_down</mat-icon>
      </button>
      <mat-menu #dataTypeMenu="matMenu">
        <button *ngFor="let filter of getTotals(); let i = index" (click)="selectMainFilter(filter.value)" mat-menu-item>
          <span>{{filter.name}} ({{filter.total}})</span>
        </button>
      </mat-menu>
      &nbsp;
      <button mat-icon-button color="primary" (click)="refresh()">
        <mat-icon>refresh</mat-icon>
      </button>
    </div>
  </div>
  <div class="inbox-outer-table-container mat-elevation-z8">
    <div class="inbox-table-container custom-scroll-bar">
      <table mat-table #table [dataSource]="data" multiTemplateDataRows>
        <ng-continer *ngFor="let column of columns; let colIndex = index" matColumnDef="{{column}}" [sticky]="colIndex == 0" [stickyEnd]="colIndex + 1 == columns.length">
          <th mat-header-cell *matHeaderCellDef class="basic-data-table-header">
            <div class="basic-data-header-container" matTooltip="{{column == '..' ? '' : column}}" matTooltipPosition="above">
              <span class="basic-data-header-span">{{column == '..' ? '' : column}}</span>
            </div>
          </th>
          <td mat-cell *matCellDef="let element" class="basic-data-table-header">
            <div *ngIf="isMultipleChoice(colIndex)">
              <button [matMenuTriggerFor]="menu"
                      mat-stroked-button
                      class="basic-data-response-label-container"
                      matTooltip="{{getMultipleChoiceLabels(element, colIndex)}}"
                      matTooltipPosition="above">
                {{getMultipleChoiceLabels(element, colIndex)}}
              </button>
              <mat-menu #menu="matMenu">
                <button *ngFor="let category of getCategories(element, colIndex); let subIndex = index" mat-menu-item>
                  <div class="fullWidth display-flex">
                    <div class="flex-alignItems-center">
                      <mat-icon>{{getCategoryIcon(element, colIndex, subIndex)}}</mat-icon>
                    </div>
                    <div class="flex-alignItems-center">
                      <span>{{category.Name}} - {{category.Label}}</span>
                    </div>
                  </div>
                </button>
              </mat-menu>
            </div>
            <div *ngIf="isMaxDiff(colIndex)"
                 class="basic-data-response-label-container"
                 matTooltip="{{getMaxDiffValue(element, colIndex)}}"
                 matTooltipPosition="above">
              <span class="basic-data-response-span">{{getMaxDiffValue(element, colIndex)}}</span>
            </div>
            <div *ngIf="isMatrix(colIndex) && !isMatrixTextbox(colIndex)">
              <button [matMenuTriggerFor]="menu"
                      mat-stroked-button
                      class="basic-data-response-label-container"
                      matTooltip="{{getMatrixLabels(element, colIndex)}}"
                      matTooltipPosition="above">
                {{getMatrixLabels(element, colIndex)}}
              </button>
              <mat-menu #menu="matMenu">
                <button *ngFor="let parameter of getParameters(element, colIndex); let subIndex = index" mat-menu-item>
                  <mat-icon>{{getParameterIcon(element, colIndex, subIndex)}}</mat-icon>
                  <span>{{parameter.Name}} - {{parameter.Value}}</span>
                </button>
              </mat-menu>
            </div>
            <div class="basic-data-response-label-container"
                 *ngIf="isMatrix(colIndex) && isMatrixTextbox(colIndex)"
                 matTooltip="{{getMatrixTextColumn(element, colIndex)}}"
                 matTooltipPosition="above">
              <span class="basic-data-response-span">{{getMatrixTextColumn(element, colIndex)}}</span>
            </div>
            <div *ngIf="isBucket(colIndex)">
              <button [matMenuTriggerFor]="menu"
                      mat-stroked-button
                      class="basic-data-response-label-container"
                      matTooltip="{{getBucketLabels(element, colIndex)}}"
                      matTooltipPosition="above">
                {{getBucketLabels(element, colIndex)}}
              </button>
              <mat-menu #menu="matMenu">
                <button *ngFor="let category of getBucketCategories(element, colIndex); let subIndex = index" mat-menu-item>
                  <span>{{category.Name}} - {{category.Label}}</span>
                </button>
              </mat-menu>
            </div>
            <div *ngIf="isRanked(colIndex)">
              <button [matMenuTriggerFor]="rankedMenu"
                      mat-stroked-button
                      class="basic-data-response-label-container"
                      matTooltip="{{getRankedChoiceLabels(element, colIndex)}}"
                      matTooltipPosition="above">
                {{getRankedChoiceLabels(element, colIndex)}}
              </button>
              <mat-menu #rankedMenu="matMenu">
                <button *ngFor="let category of getRankedCategories(element, colIndex); let subIndex = index" mat-menu-item>
                  <span>{{subIndex + 1}} - {{category.Label}}</span>
                </button>
              </mat-menu>
            </div>
            <div *ngIf="isDate(colIndex)"
                 class="basic-data-response-label-container"
                 matTooltip="{{getColumn(element, colIndex).Response?.toString() |  date: 'short': '' : translate.currentLang}}"
                 matTooltipPosition="above">
              <span class="basic-data-response-span">{{getColumn(element, colIndex).Response?.toString() |  date: 'short': '' : translate.currentLang}}</span>
            </div>
            <div *ngIf="isNot(element, colIndex)"
                 class="basic-data-response-label-container"
                 matTooltip="{{getColumn(element, colIndex).Response}}"
                 matTooltipPosition="above">
              <span class="basic-data-response-span">{{getColumn(element, colIndex).Response}}</span>
            </div>
            <div *ngIf="columnNames[colIndex] == '..'">
              <button mat-icon-button [matMenuTriggerFor]="actionMenu" aria-label="icon-button with a menu" (click)="$event.stopPropagation()">
                <mat-icon>more_vert</mat-icon>
              </button>
              <mat-menu #actionMenu="matMenu">
                <button mat-menu-item (click)="viewInterview(element)">
                  <mat-icon>view_headline</mat-icon>
                  <span>Review Interview</span>
                </button>
                <button mat-menu-item (click)="replayInterview(element)">
                  <mat-icon>replay</mat-icon>
                  <span>Replay Interview</span>
                </button>
                <button *ngIf="mainFilterType == 1" mat-menu-item (click)="makeComplete(element)">
                  <mat-icon>task</mat-icon>
                  <span>Move to Completed</span>
                </button>
              </mat-menu>
            </div>
          </td>
        </ng-continer>

        <tr mat-header-row *matHeaderRowDef="columns; sticky: true"></tr>
        <tr mat-row *matRowDef="let column; columns: columns;"
            class="users-element-row" (click)="edit(column)">
        </tr>
      </table>
    </div>
    <mat-paginator [pageSizeOptions]="[5, 10, 20]" [pageSize]="size" [length]="resultsLength" showFirstLastButtons></mat-paginator>
  </div>
</div>
