<div *ngIf="data && data?.length == 50" class="alert alert-danger" role="alert">
  We are only displaying up to 50 results here. To see more generate a report.
</div>
<table mat-table [dataSource]="data" class="fullWidth">
  <ng-container matColumnDef="LastAnswered">
    <th mat-header-cell *matHeaderCellDef> Answered </th>
    <td mat-cell *matCellDef="let element"> {{element.LastAnswered |  date: 'short': '' : translate.currentLang}} </td>
  </ng-container>
  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>
