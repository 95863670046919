import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { baseUrl, options } from "../environments/environment";

export class CintCategory {
  Name: string;
  Questions: CintQuestion[];

  constructor(name: string) {
    this.Name = name;
    this.Questions = [];
  }
}

export interface CintCountry {
  Id: number;
  Name: string;
  Text: string;
  RegionTypes: CintRegionType[];
}

export interface CintRegionType {
  Id: number;
  Name: string;
  Active: boolean;
  Description: string;
  Regions: CintRegion[];
}

export interface CintRegion {
  Id: number;
  Name: string;
  Active: boolean;
}

export class CintFeasibility {
  IncidenceRate: number = 0;
  LengthOfInterview: number = 0;
  CountryId: number = 0;
  Limit: number = 0;
  FieldPeriod: number = 0;
  QuotaGroups: CintQuotaGroup[] = [];
  Feasibility: number = 0;
  FeasibilityCount: number = 0;
  Errors?: string[];
  RespodentIdentifiableInfo: boolean = false;
}

export class CintCpi {
  Amount: number = 0;
  Currency: string = "";
}

export class CintQuote {
  IncidenceRate: number = 0;
  LengthOfInterview: number = 0;
  CountryId: number = 0;
  Cpi: CintCpi = new CintCpi();
  Errors: string[] = [];
}

export class CintFullQuote {
  Feasibility: CintFeasibility = new CintFeasibility();
  Quote: CintQuote = new CintQuote();
}

export class CintDefaultSettings {
  QuestionLength: number = 0;
  MinimumLoi: number = 0;
  Ir: number = 0;
}

export class CintSurvey {
  Name: string = "";
  ReferenceNumber: string = "";
  Contact: CintContact = new CintContact();
  Limit: number = 0;
  LimitType: number = 0;
  IncidentRate: number = 0;
  LengthOfInterview: number = 0;
  LinkTemplate: string = "";
  TestLinkTemplate: string = "";
  DeviceTypes: number[] = [];
  DeviceCapabilities: number[] = [];
  Categories: number[] = [];
  CountryId: number = 0;
  QuotaGroups: CintQuotaGroup[] = [];
  Links: CintLink[] = [];
  Statistics: CintStatistics = new CintStatistics();

}

export class CintStatistics {
  Responded: number = 0;
  Started: number = 0;
  InSurvey: number = 0;
  Completes: number = 0;
  Screenouts: number = 0;
  QuotaFulls: number = 0;
  Timeouts: number = 0;
  QualityTerminates: number = 0;
}

export class CintLink {
  Rel: string = "";
  Href: string = "";
}

export class CintQuotaGroup {
  Name: string = "";
  Limit: number = 0;
  Quotas: CintQuota[] = [];
}

export class CintQuota {
  Name: string = "";
  Limit: number = 0;
  TargetGroup: CintTargetGroup = new CintTargetGroup();
}

export class CintTargetGroup {
  MaxAge?: number;
  MinAge?: number;
  Gender?: number;
  VariableIds?: number[];
  RegionIds?: number[];
}

export class CintContact {
  EmailAddress: string = "";
  Name: string = "";
  Company: string = "";
}

export class CintQuestion {
  Id: number = 0
  Name: string = "";
  Text: string = "";
  CategoryName: string = "";
  UseRanges: boolean = false;
  Variables: CintVariable[] = [];
  Ranges: CintRange[] = [];

  constructor(id: number, name: string, text: string, categoryName: string) {
    this.Id = id;
    this.Name = name;
    this.Text = text;
    this.CategoryName = categoryName;
    this.Variables = [];
    this.Ranges = [];

  }
}

export class CintRange {
  From: number;
  To: number;

  constructor(from: number, to: number) {
    this.From = from;
    this.To = to;
  }
}

export class CintVariable {
  Id: number;
  Name: string;
  Set: boolean;

  constructor(id: number, name: string, set: boolean) {
    this.Id = id;
    this.Name = name;
    this.Set = set;
  }
}

@Injectable()
export class CintService {
  constructor(private http: HttpClient) {
  }

  getCountries(): Observable<CintCountry[]> {
    return this.http.get<CintCountry[]>(baseUrl + 'cint/countries', options);
  }

  getQuestions(countryId: string): Observable<CintCategory[]> {
    return this.http.get<CintCategory[]>(baseUrl + 'cint/questions?countryId=' + countryId);
  }

  getFinanceQuestions(countryId: string): Observable<CintQuestion[]> {
    return this.http.get<CintQuestion[]>(baseUrl + 'cint/finance?countryId=' + countryId);
  }

  getRegions(countryId: string): Observable<CintRegionType[]> {
    return this.http.get<CintRegionType[]>(baseUrl + 'cint/regions?countryId=' + countryId);
  }

  getRegionsAndFinanceQuestions(countryId: string): Observable<CintQuestion[]> {
    return this.http.get<CintQuestion[]>(baseUrl + 'cint/regionsandfinance?countryId=' + countryId);
  }

  updateQuestionVariables(countryId: string, question: CintQuestion, variables: CintVariable[]) {
    return this.http.patch(baseUrl + 'cint/update?countryId=' + countryId + '&questionId=' + question.Id,
      variables,
      options);
  }

  checkFeasibility(model: CintFeasibility) {
    return this.http.post(baseUrl + 'cint/checkFeasibility', model, options);
  }

  getSurvey(id: number): Observable<CintSurvey> {
    return this.http.get<CintSurvey>(baseUrl + 'cint/getSurvey?id=' + id, options);
  }

  processPricing(filename: string) {
    return this.http.get(baseUrl + 'cint/processpricing?filename=' + filename, options);
  }

  defaultSettings(): Observable<CintDefaultSettings> {
    return this.http.get<CintDefaultSettings>(baseUrl + 'cint/defaultsettings', options);
  }
}
