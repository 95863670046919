<app-newtoolbar></app-newtoolbar>
<div #body class="body">
  <div id="home" class="header">
    <h2 class="txt-align-left">DIY Surveys ("DIYS") Privacy Statement</h2>
    <h2 class="txt-align-left">Who we are?</h2>
    <p class="txt-align-left"><span>In this privacy policy references to "we", "us" and "our" are to DIY Surveys (or DIYS). References to "our Website" or "the Website" are to www.diysurveys.com or an landing pages that we use for marketing DIYS and/or products. Where we use the term "you" we are referring to visitors to our website or customers using the DIY Surveys services.</span></p>
    <p class="txt-align-left"><span>Where we use the term "Customer" we are referring to the entity in contract with us to use DIY Surveys services. Where we use the term "end-user" we are referring to a person contacted through your use of the DIY Surveys services, someone that you may contact in the future using the DIY Surveys services, or any individual whose information you provide to us in your use of the DIY Surveys service.</span></p>
    <h2 class="txt-align-left">What personal information we collect</h2>
    <h3 class="txt-align-left"><span class="terms-privacy-h3-color">Information you provide during account registration</span></h3>
    <p class="txt-align-left"><span>When signing up for and using the DIY Surveys services, consulting with our support teams, sending us an email or communicating with us in any way, you are voluntarily giving us information that we collect. That information may include either your email address, IP address, phone number, postal address, in addition to other details that may include gender, location, occupation or other information of a demographic nature. In supplying this information, you consent to this information being collected, used, disclosed and stored by us, as described in our <a (click)="openTerms()" class="cursor-pt terms-privacy-h3-color">Terms</a> and this Privacy Policy.</span></p>
    <h3 class="txt-align-left"><span class="terms-privacy-h3-color">Information we collect via the Website and Email</span></h3>
    <p class="txt-align-left"><span>Any personal details you knowingly provide us with through forms and our email, such as name, address, telephone number, email address etc.</span></p>
    <p class="txt-align-left"><span>We use cookies and other information gathering technologies for several purposes. These technologies may provide us with personal information, information about devices and networks you utilize to access our Websites, and other information regarding your interactions with our Websites.</span></p>
    <p class="txt-align-left"><span>In order to effectively process credit or debit card transactions it may be necessary for the bank or card processing agency to verify your personal details for authorisation outside the European Economic Area (EEA). Such information will not be transferred out of the EEA for any other purpose. We will hold these details no longer than we are required to carry out our services to you and any legal requirements to retain trading information. The information which we hold will be held securely in accordance with our internal security policy and the law.</span></p>
    <p class="txt-align-left"><span>Your preferences and use of email updates, recorded by emails we send you (if you select to receive email updates on products and offers).</span></p>
    <h3 class="txt-align-left"><span class="terms-privacy-h3-color">Information we collect via the DIY Surveys services</span></h3>
    <p class="txt-align-left"><span>We collect personal information from your use of the DIY Surveys platform. When you upload end-user data in the DIY Surveys platform, we may have access to the data and content of your surveys and the associated end-users. This information might include the name and mobile number of your end-users as well as any additional information you have provided as part of the survey that could be used to identify an individual.</span></p>
    <p class="txt-align-left"><span>As is true with most websites and services delivered over the Internet, we gather certain information and store it in log files when you interact with our Websites and Services. This information includes internet protocol (IP) addresses as well as browser type, URLs of referring/exit pages, operating system, date/time stamp, information you search for, locale and language preferences, identification numbers associated with your devices, your mobile carrier, and system configuration information. Occasionally, we connect personal information to information gathered in our log files as necessary to improve our Websites and Services. In such a case, we would treat the combined information in accordance with this Policy.</span></p>
    <h2 class="txt-align-left">What we do with your information</h2>
    <p class="txt-align-left"><span>Any personal information we collect will be used in accordance with the Data Protection Act 1998, The General Data Protection Regulations 2018 and other applicable laws. The details we collect will be used:</span></p>
    <ul>
      <li class="txt-align-left">To process your order, to provide after sales service (we may pass your details to another organisation to supply/deliver products or services you have purchased and/or to provide after-sales service).</li>
      <li class="txt-align-left">To supply, improve and support the services we provide.</li>
      <li class="txt-align-left">To protect, investigate and deter against fraudulent, harmful, unauthorized or illegal activity.</li>
      <li class="txt-align-left">To fulfil requests that you may make.</li>
      <li class="txt-align-left">To bill you (e.g. to send you invoices, process payment, notices).</li>
      <li class="txt-align-left">To send notifications about the platform.</li>
      <li class="txt-align-left">To bring or defend legal proceedings, meet legal requirements (e.g. complying with court orders, enforcement actions, or other legally valid mechanisms) or respond to lawful requests by public authorities or law enforcement requests.</li>
      <li class="txt-align-left">To send you information about our products, technology updates and general market research news. We will only do this if you opt in and provide us with your consent, whereby we may use your email address to send you information on other products and services we have, as well as news, technology and development updates from time to time. In such a case you will be offered the option to opt out on each communication we send. We may need to pass the information we collect to other companies for administrative purposes. We may use third parties to carry out certain activities, such as processing and sorting data, monitoring how customers use the Website and issuing our e-mails for us. Third parties will not be allowed to use your personal information for the<span>ir own purposes.</span></li>
    </ul>
    <h3 class="txt-align-left"><span class="terms-privacy-h3-color">Third-Party Service Providers</span></h3>
    <p class="txt-align-left"><span>We share information, including personal information, with our third-party service providers for purposes including, but not limited to: hosting and maintenance of our Websites, application development, backup, storage, payment processing, analytics and other services. These third-party service providers may have access to or process your personal information for the purpose of providing these services for us. We do not permit our third-party service providers to use the personal information that we share with them for their marketing purposes or for any other purpose than in connection with the services they provide to us.</span></p>
    <h3 class="txt-align-left"><span class="terms-privacy-h3-color">Transfer of your information out of the EEA</span></h3>
    <p class="txt-align-left"><span>Your personal information may be stored and processed in any country where we have facilities or service providers, and by using our service or by providing consent to us (where required by law), you agree to the transfer of information to countries outside of your country of residence, including to the United States, which may provide for different data protection rules than in your country. Please note that all DIY Surveys entities operate under Standard Contractual Clauses and applicable measures of the GDPR providing protections and safeguards for cross-border transfers. We take all steps reasonably necessary to ensure that your data is treated securely and in accordance with this privacy policy.</span></p>
    <p class="txt-align-left"><span>DIY Surveys abides by the principles of the EU-U.S. Privacy Shield as set forth by the U.S. Department of Commerce. For more information on the EU-U.S. Privacy Shield, please visit <a href="https://www.privacyshield.gov/" target="_blank">https://www.privacyshield.gov/</a></span></p>
    <h2 class="txt-align-left">Keeping your personal information secure</h2>
    <p class="txt-align-left"><span>We have appropriate security measures in place to prevent personal information from being accidentally lost, used or accessed in an unauthorised way. We limit access to your personal information to those who have a genuine business need to know it. Those processing your information will do so only in an authorised manner and are subject to a duty of confidentiality. We also have procedures in place to deal with any suspected data security breach. We will notify you and any applicable regulator of a suspected data security breach where we are legally required to do so.</span></p>
    <h2 class="txt-align-left">How long we store your personal information</h2>
    <p class="txt-align-left"><span>We store information required for the purpose for which it was provided while it is active. Once it is no longer active we store that information for the following durations.</span></p>
    <ul class="txt-align-left">
      <li><span>End-user information for 3 months. This includes the name, mobile number, email address or any other personal information provided as part of using the DIY Surveys services.</span></li>
      <li><span>Survey data for 3 months. This includes the result data collected as part of using the DIY Surveys services.</span></li>
      <li><span>Customer details while you're one of our customers, and for six years after; and&nbsp;</span></li>
      <li><span>Details relating to any dispute for six years after it was closed.</span></li>
    </ul>
    <p class="txt-align-left"><span>In other cases we'll store personal information for the periods needed for the purposes for which the information was collected or for which it is to be further processed according to an agreed data retention policy. Sometimes we'll keep it for longer if we need to by law. Otherwise we delete it.</span></p>
    <h2 class="txt-align-left">Your Rights</h2>
    <p class="txt-align-left"><span>You have the right to access, update, erase, change or correct your Personal Information. To request whether or not we hold any personal information relating to you please contact us at privacy&#64;diysurveys.com. Subscribers to our Services may update or change their Account Information by editing their profile or by contacting <a href="mailto:hello@diysurveys.com" class="hellodiysurveyscom">hello&#64;diysurveys.com</a> for more detailed instructions. To make a request to have personal information returned to you or removed, please email <a href="mailto:hello@diysurveys.com" class="hellodiysurveyscom">hello&#64;diysurveys.com</a>. Requests to access, update, or remove your personal information will be handled within 30 days. An individual who seeks access to, or who seeks to correct, amend, or delete inaccuracies in personal information stored or processed by us on behalf of a subscriber should direct his/her query to the subscriber (the data controller) directly. Upon receipt of a request from one of our subscribers for us to remove the data, we will respond to their request within thirty (30) days. We will retain personal information that we store and process on behalf of our subscribers for as long as needed to provide the Services to our subscribers. We will retain and use this personal information as necessary to comply with our legal obligations, resolve disputes, and enforce our agreements.</span></p>
    <p class="txt-align-left"><span>For further information on each of those rights, including the circumstances in which they apply, see the Guidance from the UK Information Commissioner's Office (ICO) on individuals’ rights under the General Data Protection Regulation.</span></p>
  </div>
</div>
<app-newfooter>
</app-newfooter>
