import { Component } from '@angular/core';
import { NewGroupComponent } from '../new-group/new-group.component';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AuthenticationService, UserGroup } from '../../authentication.Service';

@Component({
  selector: 'app-group-manager',
  templateUrl: './group-manager.component.html',
  styleUrl: './group-manager.component.css'
})
export class GroupManagerComponent {

  groups: UserGroup[] = [];

  constructor(public dialog: MatDialog,
    private router: Router,
    private authService: AuthenticationService) {
    this.authService.getUserGroups().subscribe(
      result => { this.groups = result }
    );
  }

  createGroup() {
      const dialogRef = this.dialog.open(NewGroupComponent,
        {
          data: { id: 0, isPolicyGroup: false }
        });

      dialogRef.afterClosed().subscribe(result => {
        if (result?.data?.userGroup?.GroupId != 0) {
          this.router.navigate(['/usergroup', result.data.userGroup.GroupId]);
        }
      });
  }

  action(data) {
    switch (data.action) {
      case "edit":
        this.router.navigate(['/usergroup', data.id]);
        break;
      case "delete":
        for (let i = 0; i < this.groups.length; i++) {
          if (this.groups[i].UserGroupId == data.id) {
            let group = this.groups[i];
            group.IsDeleted = true;
            this.groups.splice(i, 1);
            this.authService.saveUserGroup(group).subscribe(
              result => {
              }
            );
          }
        }

        break;
    }
  }
}
