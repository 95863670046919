import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormArray, Validators } from '@angular/forms';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { BaseQuestion, DataModel, InterviewService, QuestionService } from '../../question.service';

@Component({
  selector: 'app-matrix',
  templateUrl: './matrix.component.html',
  styleUrls: ['./matrix.component.css']
})
export class MatrixComponent extends BaseQuestion implements OnInit {
  matrixTypes = [
    { id: 'checkbox', value: 'Multiple Choice' },
    { id: 'radiobutton', value: 'Single Choice' },
    { id: 'textbox', value: 'Textbox' }
  ];

  showMatrixOptions: boolean = false;

  constructor(private breakpointObserver: BreakpointObserver, private qs: QuestionService, private is: InterviewService) {
    super(qs, is, undefined, undefined, new DataModel("matrix"));
  }

  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
    .pipe(map(result => result.matches));

  public matrixFormGroup: FormGroup = new FormGroup({});

  ngOnInit() {
    this.matrixFormGroup = this.fb.group({
      matrixFormArray: this.fb.array([this.initQuestion()])
    });
    this.formSubScription = this.matrixFormGroup.valueChanges.subscribe(x => {
      const array = this.matrixFormGroup.controls.matrixFormArray as FormArray;
      const form = array.controls[0] as FormGroup;
      if (this.model.QuestionName !== form.controls.qNumber.value) {
        this.model.QuestionName = form.controls.qNumber.value;
        this.model.QuestionNameTouched = true;
      }
      this.model.Banner = form.controls.banner.value;
      this.model.Control = form.controls.matrixTypeSelect.value;
    });

  }

  initQuestion(): FormGroup {
    return this.fb.group({
      qNumber: [this.model.QuestionName, Validators.required],
      banner: [this.model.Banner],
      matrixTypeSelect: [this.model.Control, Validators.required]
    });
  }

  showMatrixOptionsRow() {
    if (this.showMatrixOptions === false) {
      this.showMatrixOptions = true;
    }
  }

  get questionArr(): FormArray {
    return this.matrixFormGroup.get('matrixFormArray') as FormArray;
  }
}
