import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormArray, Validators } from '@angular/forms';
import { BaseQuestion, DataModel, InterviewService, QuestionService } from '../../question.service';

@Component({
  selector: 'app-integer',
  templateUrl: './integer.component.html',
  styleUrls: ['./integer.component.css']
})
export class IntegerComponent extends BaseQuestion implements OnInit {
  constructor(private qs: QuestionService, private is: InterviewService) {
    super(qs, is, undefined, undefined, new DataModel("information"));
  }

  public integerFormGroup: FormGroup = new FormGroup({});

  ngOnInit() {
    this.integerFormGroup = this.fb.group({
      integerFormArray: this.fb.array([this.initQuestion()])
    });
  }

  get questionArr(): FormArray | null {
    if (this.integerFormGroup) {
      return this.integerFormGroup.get('integerFormArray') as FormArray;
    }

    return null;
  }

  onSubmit(): void {
  }
}
