import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { BasicData, ReportHelper } from '../../casedata.Service';
import { PanelService } from '../../panel.Service';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-review-data',
  templateUrl: './review-data.component.html',
  styleUrls: ['./review-data.component.css']
})
export class ReviewDataComponent implements OnInit {
  columns: string[] = ['name', 'response', 'asked', 'answered', 'action'];
  data: BasicData[] = [];
  dataSource = new MatTableDataSource<BasicData>();

  constructor(public translate: TranslateService,
    private panelService: PanelService,
    private dialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) data: any) {
    if (data) {
      this.data = data;
      let match = false;
      for (let i = 0; i < this.data.length; i++) {
        if (this.data[i].VariableName == "DataCollection.PanellistReferenceID") {
          match = true;
          this.panelService.getPanelistByReferenceId(this.data[i].Response.toString()).subscribe(
            result => {
              this.AddData("Title", result.Title);
              this.AddData("FirstName", result.FirstName);
              this.AddData("LastName", result.LastName);
              // UserName: string = "";
              this.AddData("Email", result.Email);
              this.AddData("MobileNumber", result.MobileNumber);
              this.AddData("Dob", result.Dob);
              this.AddData("Website", result.Website);
              this.AddData("Address1", result.Address1);
              this.AddData("Address2", result.Address2);
              this.AddData("PostalCode", result.PostalCode);
              this.AddData("GeoLocation", result.GeoLocation);
              this.AddData("CreatedDate", result.CreatedDate);
              this.AddData("LastUpdatedDate", result.LastUpdatedDate);
              this.AddData("When", result.WhenTrigger);
              for (let i = 0; i < result.ProfileVariables.length; i++) {
                this.AddData(result.ProfileVariables[i].Name, result.ProfileVariables[i].ObjectVal)
              }

              this.dataSource = new MatTableDataSource(this.data);
            },
            error => {
              this.dataSource = new MatTableDataSource(this.data);
            });
        }
      }

      if (!match) {
        this.dataSource = new MatTableDataSource(this.data);
      }
    }
  } 

  private AddData(name: string, value: any) {
    for (let i = 0; i < this.data.length; i++) {
      if (this.data[i].VariableName == name) {
        return;
      }
    }

    let bData = new BasicData();
    bData.SurveyId = this.data[0].SurveyId;
    bData.PanellistReferenceId = this.data[0].PanellistReferenceId;
    bData.SystemVariable = true;
    bData.VariableName = name;
    bData.CommonName = name;
    bData.Response = value;
    bData.AskedDate = this.data[0].AskedDate;
    bData.AnsweredDate = this.data[0].AnsweredDate;
    bData.ReportHelper = new ReportHelper();
    this.data.push(bData)
  }

  ngOnInit(): void {
  }

  close() {
    this.dialogRef.close({ new: false, process: null });
  }
}
