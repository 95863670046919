import { OnInit, Component } from '@angular/core';
import { AuthenticationService } from '../authentication.Service';
import { ZendeskUtil } from '../utils';

@Component({
  selector: 'app-zendesk',
  templateUrl: './zendesk.component.html',
  styleUrls: ['./zendesk.component.css']
})
export class ZendeskComponent implements OnInit {

  token: string = "";

  constructor(private authService: AuthenticationService) {
  }

  ngOnInit() {
    this.authService.zendeskCheck().subscribe(
      result => {
        ZendeskUtil.signin(result.Value, 'https://diysurveys.zendesk.com');
      }
    );
  }
}
