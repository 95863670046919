import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormArray, Validators, FormGroup } from '@angular/forms';
import { BaseQuestion, DataModel, InterviewService, QuestionService } from '../../question.service';

@Component({
  selector: 'app-textbox',
  templateUrl: './textbox.component.html',
  styleUrls: ['./textbox.component.css']
})
export class TextboxComponent extends BaseQuestion implements OnInit {

  constructor(private qs: QuestionService, private is: InterviewService) {
    super(qs, is, undefined, undefined, new DataModel("textbox"));
  }

  public textboxFormGroup: FormGroup = new FormGroup({});

  ngOnInit() {
    this.textboxFormGroup = this.fb.group({
      textboxFormArray: this.fb.array([this.initQuestion()])
    });
  }

  get questionArr(): FormArray | null {
    if (this.textboxFormGroup) {
      return this.textboxFormGroup.controls.textboxFormArray as FormArray;
    }

    return null;
  }

  onSubmit(): void {
  }
}
