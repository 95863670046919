import { Component, Injectable, Input } from '@angular/core';
import { MatSnackBar, MatSnackBarRef } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { SnackbarDefaultComponent } from '../snackbar/snackbar-default.component';
import * as moment from 'moment';

@Component({
  template: ''
})
export abstract class BaseFormComponent {

  sb?: MatSnackBarRef<any>;

  constructor(public snackbar: MatSnackBar) {

  }

  public openSnackbar(message: string, action: string) {
    if (action == "-") {
      this.sb = this.snackbar.openFromComponent(SnackbarDefaultComponent);
      return;
    }

    if (action == "*") {
      this.sb = this.snackbar.open(message);
      return;
    }

    if (action == "") {
      this.snackbar.open(message, action, { duration: 2000 });
    }
    else {
      this.snackbar.open(message, action, { duration: 7000 });
    }
  }

  public dismiss() {
    this.sb?.dismiss();
  }
}

@Component({
  selector: 'app-timeago',
  template: "{{description}}"
})
export class TimeAgo {
  constructor(public translate: TranslateService
  ) { }
  @Input()
  get created(): Date {
    return this._created;
  }
  set created(value: Date) {
    this._created = value;
  }
  private _created: Date;

  @Input()
  get amended(): Date {
    return this._amended;
  }
  set amended(value: Date) {
    this._amended = value;
  }
  private _amended: Date;

  @Input()
  get description(): string {
    this._description = this.generateDateTimeString();
    return this._description;
  }
  private _description: string = "";

  private generateDateTimeString(): string {
    let t = moment(this.created).fromNow(true) + " ago";
    if (this.amended != null && this.amended != undefined) {
      let a = moment(this.amended).fromNow(true) + " ago";
      if (t != a) {
        t += ", updated " + a;
      }
    }

    return t;
 }
}
