import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { FormBuilder, FormGroup, FormArray, Validators } from '@angular/forms';
import { QuestionActionButtonsComponent } from '../../question-action-buttons/question-action-buttons.component';
import { BaseQuestion, DataModel, InterviewService, QuestionService } from '../../question.service';

@Component({
  selector: 'app-barcode',
  templateUrl: './barcode.component.html',
  styleUrls: ['./barcode.component.css']
})
export class BarcodeComponent extends BaseQuestion implements OnInit {

  @ViewChild('buttons') Buttons: QuestionActionButtonsComponent | undefined;
  constructor(private qs: QuestionService, private is: InterviewService) {
    super(qs, is, undefined, undefined, new DataModel("barcode"));
  }

  public barcodeFormGroup: FormGroup = new FormGroup({});

  ngOnInit() {
    this.barcodeFormGroup = this.fb.group({
      barcodeFormArray: this.fb.array([this.initQuestion()])
    });
  }

  get questionArr(): FormArray | null {
    if (this.barcodeFormGroup) {
      return this.barcodeFormGroup.get('barcodeFormArray') as FormArray;
    }

    return null;
  }

  onSubmit(): void {
  }
}
