<div *ngIf="channel" [formGroup]="formGroup">
  <div class="fullWidth standard-border">
    <mat-toolbar *ngIf="smart || questions" class="sms-edtr-toolbar-top">
      <span class="spacer"></span>
      <div>
        <button *ngIf="smart" mat-icon-button color="primary" matTooltip="Insert Options" (click)="clickSmart('smart')">
          <mat-icon>sms</mat-icon>
        </button>
      </div>
      <div class="mt-5">
        <app-field-selector *ngIf="questions" [showButton]="true" [questions]="questions" (selectField)="selectField($event)" purpose="editor"></app-field-selector>
      </div>
    </mat-toolbar>
    <textarea #textarea formControlName="Message" (keyup)="messageChange($event)" class="scroll sms-edtr-field"
              cdkTextareaAutosize
              #autosize="cdkTextareaAutosize"
              cdkAutosizeMinRows="6"
              cdkAutosizeMaxRows="20"></textarea>
    <mat-toolbar class="sms-edtr-toolbar-bottom">
      <span class="spacer"></span>
      <mat-chip-listbox multiple>
        <mat-chip-option *ngIf="channel == 'sms'" #GSM [selected]="isGsm">GSM</mat-chip-option>
        <mat-chip-option *ngIf="channel == 'sms'" #LATIN [selected]="isLatin">Latin</mat-chip-option>
        <mat-chip-option *ngIf="channel == 'sms'" #UNICODE [selected]="isUnicode">UNICODE</mat-chip-option>
        <mat-chip>{{length}}</mat-chip>
        <mat-chip *ngIf="channel == 'sms'">{{segmentText}}</mat-chip>
      </mat-chip-listbox>
    </mat-toolbar>
  </div>
  <div *ngIf="channel == 'sms'" style="display:flex;flex-flow:row">
    <div>
      <mat-icon class="sms-edtr-icon-small">warning</mat-icon>
    </div>
    <div>
      <span class="sms-edtr-warning">Remember, the use of SMS should follow our strict <a href="https://docs.diysurveys.com/sms-messaging-service-confirmation" target="_blank">SMS Usage Guidelines</a></span>
    </div>
  </div>
</div>
