import { StepperSelectionEvent } from '@angular/cdk/stepper';
import { Component, Inject, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatStepper } from '@angular/material/stepper';
import { MatTabGroup } from '@angular/material/tabs';
import { SQUARE_APPLICATION_ID, SQUARE_LOCATION_ID } from '../../../environments/environment';
import { AuthenticationService } from '../../authentication.Service';
import { CountryComboComponent } from '../../country-combo/country-combo.component';
import { CreditCardModel, CustomerModel, FinancialService, FinancialTransaction, Payment, PaymentType, ReferenceType } from '../../finacial.Service';
import { EstimateModel, PanelModel } from '../../panel.Service';
import { SurveyModel } from '../../survey.Service';
import { UserProfile, UserService } from '../../user.Service';
declare var Square: any;

@Component({
  selector: 'app-cc-payment',
  templateUrl: './cc-payment.component.html',
  styleUrls: ['./cc-payment.component.css']
})
export class CcPaymentComponent implements OnInit, OnDestroy {

  billingForm: FormGroup = new FormGroup({});
  paymentForm: FormGroup = new FormGroup({});
  reviewForm: FormGroup = new FormGroup({});

  country: any;
  showBusiness = false;
  survey: SurveyModel;

  user: UserProfile = new UserProfile();

  isCreditCustomer = false;
  payments: any;
  card: any;
  startAsync: any;
  cardAttached = false;
  validateAsync: any;
  cardValidated = false;
  token: any;
  cardError = "";
  customer: CustomerModel = new CustomerModel();

  @ViewChild('card-continer') cardContainer: HTMLDivElement | undefined;
  @ViewChild('stepper') stepper: MatStepper | undefined;
  @ViewChild('countryCombo') countryCombo: CountryComboComponent | undefined;
  @ViewChild('paymentTab') paymentTab: MatTabGroup | undefined;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    private financialService: FinancialService,
    private authenticationService: AuthenticationService,
    private dialogRef: MatDialogRef<CcPaymentComponent>,
    private userService: UserService,
    private formBuilder: FormBuilder,
    private financeService: FinancialService,
    private snackbar: MatSnackBar
  ) {
    this.panel = data.panel;
    this.estimate = data.estimate;
    this.survey = data.survey;
    this.payments = Square.payments(SQUARE_APPLICATION_ID, SQUARE_LOCATION_ID);
    this.startAsync = async () => {
      this.card = await this.payments.card();
      this.card.attach("#cardcontainer");
    };

    this.validateAsync = async () => {
      this.cardValidated = false;
      try {
        const result = await this.card.tokenize();
        if (result.status === 'OK') {
          this.token = result.token;
          this.cardValidated = true;
          this.cardError = "";
        }
        else {
          if (result.errors && result.errors.length > 0) {
            this.cardError = result.errors[0].message;
          }
          else {
            this.cardError = "There was a problem validating this card";
          }
        }
      } catch (e) {
        this.cardError = "There was a problem validating this card";
      }
    }
  }

  ngOnDestroy(): void {
    if (this.card) {
      this.card.destroy();
    }
  }

  ngOnInit(): void {
    this.billingForm = this.formBuilder.group({
      EmailAddress: ["", [Validators.required]],
      FirstName: ["", [Validators.required]],
      LastName: ["", [Validators.required]],
      company: [""],
      ponumber: [""],
      address: [""],
      town: [""],
      county: [""],
      postcode: [""],
      billingemail: [""],
      vatregno: [""]
    });
    this.userService.getCurrentUser().subscribe(
      result => {
        this.user = result;
        this.isCreditCustomer = UserService.hasRole(this.user.Member.Roles, "Credit");
        this.billingForm.patchValue(this.user);
        this.country = this.user.Country;
      });

    this.paymentForm = this.formBuilder.group({
      secondCtrl: [],
      name: [],
      card: new FormControl()
    });

    this.reviewForm = this.formBuilder.group({

    });
  }

  countrySelected() {
    if (this.country) {
      return this.country.id;
    }

    return null;
  }

  @Input()
  get panel(): PanelModel {
    return this._panel;
  }
  set panel(value: PanelModel) {
    this._panel = value;
  }
  private _panel: PanelModel = new PanelModel();

  @Input()
  get estimate(): EstimateModel {
    if (this._estimate) {
      return this._estimate;
    }

    this._estimate = new EstimateModel();
    return this._estimate;
  }
  set estimate(value: EstimateModel) {
    this._estimate = value;
    if (this._estimate) {
    }
  }
  private _estimate: EstimateModel = new EstimateModel();

  selectionChanged($event: StepperSelectionEvent) {
    const temp = this.stepper?.steps.toArray();
    if (temp == undefined) {
      return;
    }

    for (let i = 0; i < temp.length; i++) {
      if (i > $event.previouslySelectedIndex) {
        temp[i].editable = false;
        temp[i].completed = true;
      }
    }
  }

  animationDone() {
    switch (this.stepper?.selectedIndex) {
      case 1:
        if (!this.cardAttached) {
          this.startAsync();
        }

        this.cardAttached = true;
        break;
    }
  }

  validatePayment() {
    this.validateAsync().then(() => {
      if (!this.cardValidated) {
        this.openSnackbar(this.cardError, "Cancel");
      }
      else {
        if (this.stepper?.selected != null) {
          this.stepper.selected.completed = true;
          this.stepper.next();
        }
      }
    });
  }

  validateBillingDetails() {
    if (this.billingForm.invalid) {
      return;
    }

    if (this.countryCombo?.countrySelector.invalid) {
      return;
    }

    let model = new CustomerModel();
    model.GivenName = this.billingForm.controls.FirstName.value;
    model.FamilyName = this.billingForm.controls.LastName.value;
    model.AddressLine1 = this.billingForm.controls.address.value;
    model.AddressLine2 = this.billingForm.controls.town.value;
    model.AdministrativeDistrictLevel1 = this.billingForm.controls.county.value;
    model.Country = this.country.countryCode;
    model.EmailAddress = this.billingForm.controls.EmailAddress.value;
    model.ReferenceId = this.user.UserID.toString();
    this.financeService.createCustomer(model).subscribe(
      result => {
        this.customer = result;
        if (this.stepper?.selected != null) {
          this.stepper.selected.completed = true;
          this.stepper.next();
        }
      },
      error => {
        this.openSnackbar("There was a problem with the billing information", "Cancel");
      }
    );
  }

  pay() {
    this.makePayment();
  }

  makePayment() {
    let creditCard = new CreditCardModel();
    creditCard.Token = this.token;
    creditCard.Amount = this._estimate.Total;
    creditCard.CustomerId = this.customer.CustomerId;
    this.financeService.creditCardPayment(creditCard).subscribe(
      result => {
        this.buildPayment();
      });
  }

  buildPayment() {
    let payment = new Payment();
    if (this.paymentTab?.selectedIndex == 0) {
      payment.EndingIn = 0;
      payment.Expires = new Date();
      payment.AlternateBillingEmail = this.token;
    }
    else {
      payment.EndingIn = 0;
      payment.Expires = new Date();
      payment.TypeID = PaymentType.Credit;
    }

    payment.Description = "Payment for survey ";
    payment.BillingEmail = this.billingForm.controls.EmailAddress.value;
    payment.AlternateBillingEmail = this.billingForm.controls.billingemail.value;
    payment.CompanyName = this.billingForm.controls.company.value;
    payment.PurchaseOrderNumber = this.billingForm.controls.ponumber.value;
    payment.Address = this.billingForm.controls.address.value;
    payment.Town = this.billingForm.controls.town.value;
    payment.County = this.billingForm.controls.county.value;
    payment.PostCode = this.billingForm.controls.postcode.value;
    payment.Country = this.country.countryCode;
    payment.VatRegistrationNumber = this.billingForm.controls.vatregno.value;
    payment.Amount = this._estimate.Total;
    payment.InvoiceLines = FinancialService.buildInvoiceLines(this._estimate);
    payment.Reference = this.survey.SurveyID;
    payment.ReferenceTypeID = ReferenceType.Survey;
    this.financialService.makePayment(payment).subscribe(
      result => {
        this.dialogRef.close({ result });
      });
  }

  private openSnackbar(message: string, action: string) {
    if (action == "") {
      this.snackbar.open(message, action, { duration: 2000 });
    }
    else {
      this.snackbar.open(message, action, { duration: 7000 });
    }
  }
}
