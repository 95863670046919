import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { baseUrl, options } from "../environments/environment";
import { EstimateModel } from "./panel.Service";

export class PaymentType {
  static readonly CreditCard = 1;
  static readonly Credit = 2;
  static readonly Template = 3;
}

export class ReferenceType {
  static readonly None = 0;
  static readonly Survey = 1;
}

export class Payment {
  PaymentID: number = 0;
  TypeID: number = 0;
  UserID: number = 0;
  EndingIn: number = 0;
  Created: Date = new Date();
  Expires: Date = new Date();
  BillingEmail: string = "";
  AlternateBillingEmail: string = "";
  CompanyName: string = "";
  PurchaseOrderNumber: string = "";
  Address: string = "";
  Town: string = "";
  County: string = "";
  Country: string = "";
  PostCode: string = "";
  Description: string = "";
  VatRegistrationNumber: string = "";
  ReferenceTypeID: number = 0;
  Reference: number = 0;
  Amount: number = 0;
  InvoiceLines: Invoice[] = [];
}

export class Invoice {
  InvoiceID: number;
  PaymentID: number;
  Line: number;
  Description: string;
  TypeID: number;
  Value: number;

  static readonly Total = 1;
  static readonly Detail = 2;
  static readonly Vat = 3;
  static readonly NoValue = 4;

  constructor(line: number, description: string, typeid: number, value: number) {
    this.PaymentID = 0;
    this.InvoiceID = 0;
    this.Line = line;
    this.Description = description;
    this.TypeID = typeid;
    this.Value = value;
  }
}

export class CreditCardModel {
  Name: string = "";
  Token: string = "";
  Number: string = "";
  Expires: Date = new Date();
  Cvt: string = "";
  Amount: number = 0;
  CustomerId: string = "";
}

export class FinancialTransaction {
  TranID: number = 0;
  TypeID: number = 0;
  BalanceID: number = 0;
  PaymentID: number = 0;
  GeneralBalanceID: number = 0;
  Reference: number = 0;
  UserID: number = 0;
  Description: string = "";
  Amount: number = 0;
  Credit: number = 0;
  Debit: number = 0;
  Created: Date = new Date();
}

export class CustomerModel {
  GivenName: string = "";
  FamilyName: string = "";
  EmailAddress: string = "";
  AddressLine1: string = "";
  AddressLine2: string = "";
  Locailty: string = "";
  AdministrativeDistrictLevel1: string = "";
  PostalCode: string = "";
  Country: string = "";
  PhoneNumber: string = "";
  ReferenceId: string = "";
  Note: string = "";
  CustomerId: string = "";
}

@Injectable()
export class FinancialService {
  constructor(private http: HttpClient) {
  }

  createCustomer(model: CustomerModel): Observable<any> {
    return this.http.post(baseUrl + 'creditcard/createCustomer', model, options);
  }

  creditCardPayment(model: CreditCardModel) {
    return this.http.post(baseUrl + 'creditcard/makePayment', model, options);
  }

  makePayment(payment: Payment): Observable<any> {
    return this.http.post(baseUrl + 'finance/makePayment', payment, options);
  }

  checkBalance(): Observable<number> {
    return this.http.get<number>(baseUrl + 'finance/checkBalance', options);
  }

  checkBalanceForUser(id: number): Observable<number> {
    return this.http.get<number>(baseUrl + 'finance/checkBalanceForUser?id=' + id, options);
  }

  checkBalanceForReference(reference: number, amount: number): Observable<boolean> {
    return this.http.get<boolean>(baseUrl + 'finance/checkBalanceForReference?reference=' + reference + '&amount=' + amount, options);
  }

  changeBalanceForReference(reference: number, amount: number): Observable<any> {
    return this.http.get(baseUrl + 'finance/ChargeBalanceForReference?reference=' + reference + '&amount=' + amount, options);
  }

  getFinancialTransactions(): Observable<FinancialTransaction[]> {
    return this.http.get<FinancialTransaction[]>(baseUrl + 'finance/GetFinancialTransactions', options);
  }

  getFinancialTransactionsForUser(id: string): Observable<FinancialTransaction[]> {
    return this.http.get<FinancialTransaction[]>(baseUrl + 'finance/GetFinancialTransactionsForUser?id=' + id, options);
  }



  static buildInvoiceLines(estimate: EstimateModel): Invoice[] {
    let lines = [];
    lines.push(new Invoice(1, "Completion Rate", Invoice.Detail, estimate.CompletionRate));
    lines.push(new Invoice(2, "Respondents ", Invoice.Detail, estimate.Respondents));
    lines.push(new Invoice(3, "Rate per Respondent", Invoice.Detail, estimate.Rate));
    lines.push(new Invoice(4, "Total", Invoice.Total, estimate.Total));
    return lines;
  }

}
