<div *ngIf="question">
  <div *ngIf="checkBoxes" class="vertical-radio-group">
    <mat-checkbox [(ngModel)]="checked">
      <div class="boolean-radio-button-label" [innerHTML]="yes"></div>
    </mat-checkbox>
  </div>
  <div *ngIf="radioButtons" class="vertical-radio-group">
    <mat-radio-group class="column-flex auto-flex" aria-label="Select an option" [(ngModel)]="checkedString">
      <mat-radio-button value="true"><div class="boolean-radio-button-label" [innerHTML]="yes"></div></mat-radio-button>
      <mat-radio-button value="false"><div class="boolean-radio-button-label" [innerHTML]="no"></div></mat-radio-button>
    </mat-radio-group>
  </div>
</div>
