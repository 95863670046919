<mat-form-field appearance="outline" class="fullWidth margin-top-bot-5">
  <mat-label>{{label}}</mat-label>
  <mat-chip-grid #chipList aria-label="Users">
    <mat-chip-row *ngFor="let user of users"
                  (removed)="remove(user)">
      {{user.UserName}} <span *ngIf="!isOrganisation(user)">({{user.EmailAddress}})</span>
      <button matChipRemove>
        <mat-icon>cancel</mat-icon>
      </button>
    </mat-chip-row>
    <input placeholder="..."
           #userInput
           [formControl]="userCtrl"
           [matAutocomplete]="auto"
           [matChipInputFor]="chipList"
           [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
           (matChipInputTokenEnd)="add($event)">
  </mat-chip-grid>
  <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
    <mat-option *ngFor="let user of filteredUsers | async" [value]="user">
      <mat-icon *ngIf="isAdmin">{{icon(user)}}</mat-icon>{{user.UserName}} <span *ngIf="!isOrganisation(user)">({{user.EmailAddress}})</span>
    </mat-option>
  </mat-autocomplete>
</mat-form-field>
