import { Component, Input, OnInit } from '@angular/core';
import { UserTinyUrl, TinyUrlService } from "../tinyUrl.Service";
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from "rxjs";
import { tap, catchError } from 'rxjs/operators';
import { MatTableDataSource } from '@angular/material/table';
import { TranslateService } from '@ngx-translate/core';


@Component({
  selector: 'app-tinyurls',
  templateUrl: './tinyurls.component.html',
  styleUrls: ['./tinyurls.component.css']
})
export class TinyurlsComponent implements OnInit {
  tinyUrls: UserTinyUrl[] = [];
  errors: Map<number, string> = new Map();
  tempTinyUrls?: Observable<UserTinyUrl[]>;
  success: boolean = false;
  errorMsg: string = "";
  displayedColumns: string[] = ['Url', 'Status', 'CreateDate', 'ModifiedDate', 'Action'];
  dataSource = new MatTableDataSource(this.tinyUrls);
  actionType: string = "";

  constructor(private tinyUrlService: TinyUrlService,
    public translate: TranslateService,
    private router: Router,
    private actRoute: ActivatedRoute) {
    this.actionType = this.actRoute.snapshot.data.breadcrumb == "Edit User" ? "edituser" : "profile";
  }

  ngOnInit(): void {
    this.success = false;
    this.errorMsg = "";
  }

  addTinyUrl(event: any) {
    this.router.navigate(["tinyurl", 0, this.actionType, this.userId]);
  }

  errorRate(tinyUrl: UserTinyUrl) {
    if (this.errors.get(tinyUrl.UserTinyUrlId) === "") {
      this.testUrl(tinyUrl);
    }

    return this.errors.get(tinyUrl.UserTinyUrlId);
  }

  getTinyUrls() {
      this.tempTinyUrls = this.tinyUrlService.getTinyUrlsForAccount(this.userId).pipe(tap<any>(result => {
        this.tinyUrls = result;
        this.dataSource = new MatTableDataSource(this.tinyUrls);
        this.tinyUrls.forEach(tinyUrl => this.errors.set(tinyUrl.UserTinyUrlId, ""));
      }),
      catchError((err, caught) =>
        this.tempTinyUrls = new Observable((observer) => {
          observer.next(this.tinyUrls);
          observer.complete();
        })));
  }

  deleteTinyUrl(id: number) {
    this.tinyUrlService.deleteTinyUrl(id);
    this.getTinyUrls();
  }

  testUrl(tinyUrl: UserTinyUrl) {
    this.tinyUrlService.checkUrl(tinyUrl.Url,tinyUrl.UserTinyUrlId.toString(),tinyUrl.ValidationCode).subscribe(result => {
        this.errors.set(tinyUrl.UserTinyUrlId, "");
      },
      error => {
        switch (error.status) {
          case 404:
            this.errorMsg = "This URL has no certificate associated with it";
            break;
          case 302:
            this.errorMsg = "This URL is in use by another account or you have set it up already";
            break;
          case 208:
            this.success = true;
            break;
          default:
            this.errorMsg = "This URL is not set up in the platform correctly";
            break;
        }
        this.errors.set(tinyUrl.UserTinyUrlId, this.errorMsg);
      });
  }

  @Input()
  get userId(): number {
    return this._userId;
  }
  set userId(value: number) {
    this._userId = value;
    if (this._userId > 0) {
      this.getTinyUrls();
    }
  }
  private _userId: number = -1;

}
