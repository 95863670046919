<form *ngIf="settings | async; else loading" fxLayoutAlign="stretch" fxLayout="column" [formGroup]="formGroup" (ngSubmit)="onSubmit()">
  <mat-accordion>
    <div *ngFor="let setting of finalSettings; let i = index">
      <mat-expansion-panel hideToggle>
        <mat-expansion-panel-header class="display-flex">
          <mat-panel-title class="system-title">
            {{setting.Name}}
          </mat-panel-title>&nbsp;&nbsp;
          <mat-panel-description class="system-desc">
            {{setting.ModifiedId}}
          </mat-panel-description>
        </mat-expansion-panel-header>
        <div>
          Date Amended: {{setting.LastUpdatedDate | date:'short':'BST'}}&nbsp;({{textType(setting.ConfigType)}})
        </div>
        <div *ngIf="setting.ConfigType == 'User'">
          <div>
            {{setting.UserProfile.UserID}}: {{setting.UserProfile.UserName}} {{setting.UserProfile.FirstName }} {{setting.UserProfile.LastName}}
          </div>
          <div>
            <button mat-raised-button class="mt-5" color="primary" (click)="findUser($event, setting.Value, i)">Find User</button>&nbsp;
            <button mat-raised-button class="mt-5" color="primary" (click)="onSignIn($event, i)">SignIn</button>
          </div>
        </div>
        <div *ngIf="setting.ConfigType != 'User'">
          <mat-form-field appearance="outline" class="fullWidth">
            <mat-label>Value</mat-label>
            <input matInput placeholder="Value" [value]="setting.Value" [formControlName]="setting.ModifiedId" />
          </mat-form-field>
        </div>
      </mat-expansion-panel>
      <div></div>
    </div>
    <div class="system-update-btn-container">
      <button mat-raised-button type="submit" class="mt-5" color="primary">Update</button>
    </div>
  </mat-accordion>
</form>

<ng-template #loading>
  <span class="loading-content">
    <div class="spinner-div"><mat-spinner [diameter]="25"></mat-spinner></div>
    <div class="spinner-text"> Please Wait...</div>
  </span>
</ng-template>
