<div class="clearBoth"></div>
<div class="mat-dialog-content custom-scroll-bar randomise-dialog-container">
  <div class="mat-dialog-content randomise-dialog-content" [formGroup]="dataFormGroup">
    <mat-form-field appearance="outline" class="wd-20-per">
      <mat-label> Name </mat-label>
      <input matInput placeholder="Name" formControlName="name">
    </mat-form-field>
    <span>Randomly present</span>
    <mat-form-field appearance="outline" class="wd-20-per">
      <mat-label> Name </mat-label>
      <input matInput placeholder="Name" formControlName="number">
    </mat-form-field>
    <span>of the following</span>
    <div formArrayName="embeddedOption">
      <div *ngFor="let opt of dataArr.controls; let i = index" [formGroupName]="i" class="randomise-embed-option-div">
        <mat-form-field appearance="outline" class="randomise-qnSelector-field">
          <mat-label> Question </mat-label>
          <mat-select formControlName="name">
            <mat-option>None</mat-option>
            <mat-option *ngFor="let question of questionsForDropdown()" [value]="question.QuestionId">
              {{question.FullName}}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <button mat-line-end class="padding-0" mat-icon-button matTooltip="Remove embedded data" matTooltipPosition="above" [hidden]="!isValid(i)" (click)="deleteEWmbeddedData(i)">
          <mat-icon>remove_circle_outline</mat-icon>
        </button>
        <button mat-line-end class="padding-0" mat-icon-button matTooltip="Add new embedded data" matTooltipPosition="above" [hidden]="!isValid(i)" (click)="addEmbeddedData(i)">
          <mat-icon>add_circle_outline</mat-icon>
        </button>
      </div>
    </div>
  </div>
</div>
