<mat-accordion>
  <mat-expansion-panel>
    <mat-expansion-panel-header>
      <mat-panel-title>
        <div class="simple-row">
          <mat-icon class="mini-fab">policy</mat-icon><span class="user-details-expansion-panel-title">Policy Groups</span>
        </div>
      </mat-panel-title>
      <mat-panel-description>

      </mat-panel-description>
    </mat-expansion-panel-header>
    <app-policy-group-manager></app-policy-group-manager>
  </mat-expansion-panel>
  <mat-expansion-panel>
    <mat-expansion-panel-header>
      <mat-panel-title>
        <div class="simple-row">
          <mat-icon class="mini-fab">groups</mat-icon><span class="user-details-expansion-panel-title">User Groups</span>
        </div>
      </mat-panel-title>
      <mat-panel-description>

      </mat-panel-description>
    </mat-expansion-panel-header>
    <app-group-manager></app-group-manager>
  </mat-expansion-panel>
</mat-accordion>
