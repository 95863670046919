import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { plainToClass } from 'class-transformer';
import { Subscription } from 'rxjs';
import { MissionService } from '../MissionService';
import { InterviewModel, InterviewService, QuestionModel } from '../question.service';
import { PeriodStatusTotal, SurveyModel, SurveyService } from '../survey.Service';

@Component({
  selector: 'app-data-analysis',
  templateUrl: './data-analysis.component.html',
  styleUrls: ['./data-analysis.component.css']
})
export class DataAnalysisComponent implements OnInit, OnDestroy {

  id: number;
  survey: SurveyModel = new SurveyModel();
  interview: InterviewModel = new InterviewModel();
  modelChecked: boolean = false;
  periodTotals: PeriodStatusTotal[] = [];
  questionTexts: QuestionModel[] = [];
  subscription: Subscription;

  constructor(private surveyService: SurveyService,
    private missionService: MissionService,
    public translate: TranslateService,
    private interviewService: InterviewService,
    private actRoute: ActivatedRoute, private router: Router) {
    this.id = Number(this.actRoute.snapshot.params.id);
    this.router = router;
    this.subscription = missionService.missionAnnounced$.subscribe(
      mission => {
        if (mission === "Back") {
          this.onSubmit();
        }
      });
    this.surveyService.getSurveyDetail(this.id).subscribe(result => {
      this.survey = result;
      this.interviewService.getInterview(this.survey.ScriptID).subscribe(result => {
        if (result === null) {
          this.survey.QuestionCount = 0;
          this.interview = new InterviewModel();
          this.modelChecked = true;
        }
        else {
          this.interview = plainToClass(InterviewModel, result);
          this.interview.checkModel();
          this.questionTexts = this.interview.textQuestions();
          this.modelChecked = true;
        }
      });
    });
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }

  showSmsInsights(): boolean {
    return (this.survey?.Channel >= 1 && this.survey?.Channel <= 5) || this.survey?.JobMaster?.ChannelID == 2;
  }

  showDataAnalysis(): boolean {
    return this.survey?.Channel == 0 || this.survey?.Channel == 1 || this.survey?.Channel == 2 || this.survey?.Channel == 5;
  }

  onSubmit() {
    let status = 0;
    switch (this.survey.Status) {
      case 1:
        status = 1;
        break;
      case 2:
        status = 2;
        break;
      case 3:
        status = 3;
        break;
      default:
        status = 4;
        break;
    }
    this.router.navigate(["/surveydashboard/" + status]);
  }
}
