<div *ngIf="isHandset$ == undefined || !isHandset$">
  <div class="loop-categorical-main-div"
       [style]="showTextboxMatrix ? 'align-items: center;':' align-items: stretch;'"
       *ngFor="let row of cells; let i = index">
    <div *ngFor="let cell of row; let j = index">
      <div class="loop-categorical-cellLabel" *ngIf="j == 0 && cell.isLabel">{{cell.label}}</div>
      <div [class]="showTextboxMatrix ? 'loop-categorical-txtboxColLabels':'loop-categorical-catColLabels'"
           class="loop-categorical-cellLabel-div"
           *ngIf="j > 0 && cell.isLabel">
        {{cell.label}}
      </div>
      <div class="fill-flex loop-categorical-data-div" *ngIf="cell.isQuestion">
        <diy-data class="fill-flex flex-noshrink loop-categorical-data" #question
                  [show]="true"
                  [question]="cell.question"
                  [displayLabel]="false">
        </diy-data>
      </div>
      <div *ngIf="cell.isBasicCategory">
        <diy-category class="flex-noshrink loop-categorical-category" #category
                      [question]="cell.question"
                      [index]="cell.categoryIndex"
                      [displayLabel]="false"
                      [groupName]="cell.groupName">
        </diy-category>
      </div>
      <div class="flex-fill" #unknown *ngIf="cell.isCategory && !cell.isBasicCategory">cat</div>
    </div>
  </div>
</div>
<div *ngIf="isHandset$">
  <mat-accordion>
    <mat-expansion-panel *ngFor="let row of mobileCells; let i = index" [expanded]="i == 0">
      <mat-expansion-panel-header>
        <mat-panel-title>
          {{row[0].label}}
        </mat-panel-title>
        <div *ngIf="mustAnswer" class="loop-categorical-validAns-icon-div">
          <mat-icon *ngIf="getValidAnswer(i)" class="loop-categorical-validAns-icon">check_circle</mat-icon>
        </div>
      </mat-expansion-panel-header>
      <div *ngFor="let cell of row; let j = index">
        <div *ngIf="j > 0">
          <div *ngIf="cell.isQuestion">
            <diy-data #question
                      [show]="true"
                      [question]="cell.question"
                      [displayLabel]="true">
            </diy-data>
          </div>
          <div *ngIf="cell.isBasicCategory">
            <diy-category #category
                          [question]="cell.question"
                          [index]="cell.categoryIndex"
                          [displayLabel]="true"
                          [groupName]="cell.groupName">
            </diy-category>
          </div>
        </div>
        <div #unknown *ngIf="cell.isCategory && !cell.isBasicCategory">cat</div>
      </div>
    </mat-expansion-panel>
  </mat-accordion>
</div>
