<div *ngIf="country" class="cat-list-container custom-scroll-bar">
  <mat-accordion *ngIf="tempCategories | async; else loading">
    <div *ngFor="let category of categories; let i = index">
      <mat-expansion-panel hideToggle>
        <mat-expansion-panel-header class="cat-list-panel-header">
          <mat-panel-title>
            <div class="fullWidth">
              <div class="leftFloat font-wt-500">
                {{category.Name}}
              </div>
              <div class="rightFloat">
                <mat-icon class="cint-mapper-radio-icon" *ngIf="isSelectedCategory(i)">radio_button_unchecked</mat-icon>
              </div>
            </div>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <mat-selection-list #questionList [multiple]="false" class="cat-list-selList">
          <mat-list-option *ngFor="let question of category.Questions; let j = index" (click)="onSelect($event, i, j)" class="mat-list-options">
            <div class="cat-list-qnList-optionBox">
              <div class="leftFloat">
                {{question.Name}}
              </div>
              <div class="rightFloat">
                <mat-icon class="cint-mapper-radio-icon mt-5" *ngIf="isSelectedQuestion(i, j)">radio_button_unchecked</mat-icon>
              </div>
            </div>
          </mat-list-option>
        </mat-selection-list>
      </mat-expansion-panel>
    </div>
  </mat-accordion>
</div>
<ng-template #loading>
  <div class="loading-container">
    <span class="loading-content">
      <div class="spinner-div"><mat-spinner [diameter]="25"></mat-spinner></div>
      <div class="spinner-text"> Loading Categories...</div>
    </span>
  </div>
</ng-template>
