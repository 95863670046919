import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { version, baseUrl } from "../../environments/environment";
import { AuthenticationService, SocialLogin } from "../authentication.Service";
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { FeatureTourDialogComponent } from '../feature-tour-dialog/feature-tour-dialog.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SurveyService } from '../survey.Service';
import { FacebookLoginProvider, GoogleLoginProvider, SocialAuthService } from '@abacritt/angularx-social-login';
import { DeviceDetectorService } from 'ngx-device-detector';
import { ZendeskUtil } from '../utils';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit, OnDestroy {
  private config: {
    version: string
  };

  private isTrue: boolean = false;
  formGroup: FormGroup;
  currentVersion: string;
  errorMsg: string = "";
  displayError = false;
  displayWait = false;
  hide = true;
  breakpoint: number = 0;
  currentYear = new Date().getFullYear();
  GoogleLoginProvider = GoogleLoginProvider;
  subscription?: Subscription;

  constructor(private http: HttpClient,
    private authenticationService: AuthenticationService,
    private snackBar: MatSnackBar,
    private socialService: SocialAuthService,
    private breakpointObserver: BreakpointObserver,
    private surveyService: SurveyService,
    private router: Router,
    private deviceService: DeviceDetectorService,
    public dialog: MatDialog,
    private snackbar: MatSnackBar) {
    this.currentVersion = version;
    if (this.authenticationService.getAccessToken() != "") {
      try {
        this.socialService.signOut();
      }
      catch { }
    }

    this.authenticationService.logoutWithoutNavigate();
    this.formGroup = new FormGroup({
      email: new FormControl("", [Validators.required]),
      password: new FormControl("", [Validators.required]),
      deviceType: new FormControl("")
    });
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }

  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
    .pipe(map(result => result.matches));

  ngOnInit(): void {
    this.config = require('../../assets/files/config.json')

    const headers = new HttpHeaders()
      .set('Cache-Control', 'no-cache')
      .set('Pragma', 'no-cache');

    this.http.get<{ version: string }>('../../assets/files/config.json', { headers })
      .subscribe(config => {
        if (config.version !== this.config.version) {
          let temp = this.snackBar.open("New version of the platform available", "Reload", {
            horizontalPosition: "center",
            verticalPosition: "top"
          });
          temp.afterDismissed().subscribe(() => {
            this.http
              .get("", { headers, responseType: "text" })
              .subscribe(() => location.reload());
          });
        }
      });

    this.subscription = this.socialService.authState.subscribe((user) => {
      let model = new SocialLogin();
      if (user != null) {
        model.EmailAddress = user.email;
        model.FirstName = user.firstName;
        model.LastName = user.lastName;
        model.PictureUrl = user.photoUrl;
        model.Provider = user.provider;
        model.UserID = user.id;
        this.displayWait = true;
        this.authenticationService.socialLogin(model)
          .subscribe(result => {
            this.displayWait = false;
            this.checkToken((result as any).Token);
          },
            error => {
              this.openSnackbar("There was a problem using the credentials", "Cancel");
              this.displayWait = false;
            });
      }
      else {
        this.openSnackbar("There was a problem using the credentials", "Cancel");
        this.displayWait = false;
      }
    });
  }

  logInWithFacebook() {
    this.displayWait = true;
    this.socialService.signIn(FacebookLoginProvider.PROVIDER_ID);
    this.displayWait = false;
    return false;
  }


  refreshGoogleToken(): void {
    this.displayWait = true;
    this.socialService.refreshAuthToken(GoogleLoginProvider.PROVIDER_ID);
    this.displayWait = false;
  }

  loginProcess() {
    this.errorMsg = "";
    this.formGroup.controls.email.setErrors(null);
    this.formGroup.controls.password.setErrors(null);
    if (this.formGroup.valid) {
      this.displayWait = true;
      let deviceInfo = this.deviceService.getDeviceInfo();
      this.formGroup.controls.deviceType.setValue(deviceInfo.device + ":" + deviceInfo.deviceType + ":" + deviceInfo.browser);
      this.authenticationService.loginNoCheck(this.formGroup.value).subscribe(
        result => {
          this.displayWait = false;
          if (result.Status == 0) {
            this.authenticationService.setAccessToken(result.Token);
            this.router.navigate(['../2fa']);
            return;
          }

          this.checkToken(result.Token);
        },
          error => {
            this.displayWait = false;
            console.error(error);
            this.displayError = true;
            this.formGroup.controls.email.setErrors({ invalid: '' });
            this.formGroup.controls.password.setErrors({ invalid: 'There is a problem with the credentials' });
          });
    }
  }

  private checkToken(token: any) {
    this.authenticationService.setAccessToken(token);
    let page = '';
    if (this.authenticationService.isAuthorized(['Admin'])) {
      page = '/users';
      this.signIntoZenDesk(page);
    }
    else if (this.authenticationService.isAuthorized(['developer'])) {
      page = '/smshome/keys';
      this.signIntoZenDesk(page);
    }
    else {
      this.surveyService.getSurveyCount()
        .subscribe(response => {
          if (response > 0) {
            page = '/surveydashboard';
            if (this.authenticationService.loadSetting('homeTourDone') != "") {
              const dialogRef = this.dialog.open(FeatureTourDialogComponent, { disableClose: true });
              dialogRef.afterClosed().subscribe(result => {
                //localStorage.setItem('HomeTourDone', "Feature Tour - Home");
              });
            }
          } else {
            page = '/startfromtemplate';
            if (this.authenticationService.loadSetting('homeTourDone') != "") {
              const dialogRef = this.dialog.open(FeatureTourDialogComponent, { disableClose: true });
              dialogRef.afterClosed().subscribe(result => {
                //localStorage.setItem('HomeTourDone', "Feature Tour - Home");
              });
            }
          }

          this.signIntoZenDesk(page);
        },
          error => {
            if (error.status === 401) {
              this.router.navigate(['../']);
            }
          });
    }
  }

  private signIntoZenDesk(redirectTo: string) {
    let rt = baseUrl.replace('/api/', redirectTo);
    if (baseUrl.startsWith("https://localhost") || baseUrl.startsWith("https://staging.")) {
      this.router.navigate(['..' + redirectTo]);
      return;
    }

    this.authenticationService.zendeskCheck().subscribe(
      result => {
        ZendeskUtil.signin(result.Value, redirectTo);
      },
      error => {
        console.error("There was a problem with the login for Zendesk:" + error)
      });
  }

  openTerms() {
    const url = this.router.createUrlTree(['/termsofservice'])
    window.open(url.toString(), '_blank')
  }

  openPrivacy() {
    const url = this.router.createUrlTree(['/privacystatement'])
    window.open(url.toString(), '_blank')
  }

  goToLink(url: string) {
    window.open(url, "_blank");
  }

  passwordError() {
    if (this.formGroup.controls.password.invalid) {
      return "There is a problem with the credentials";
    }

    return "";
  }

  changeTheme() {
    this.isTrue = !this.isTrue;
  }

  private openSnackbar(message: string, action: string) {
    if (action == "") {
      this.snackbar.open(message, action, { duration: 2000 });
    }
    else {
      this.snackbar.open(message, action, { duration: 7000 });
    }
  }
}

interface SessionModel {
  SessionId: string;
  Username: string;
  Email: string;
}
