import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-help',
  templateUrl: './help.component.html',
  styleUrls: ['./help.component.css']
})
export class HelpComponent implements OnInit {
  formGroup: FormGroup;

  constructor() {
    this.formGroup = new FormGroup({
      subject: new FormControl("", [Validators.required]),
      description: new FormControl("", [Validators.required]),
      priority: new FormControl("", [Validators.required])
    });
  }

  ngOnInit(): void {
  }
}
