<div *ngIf="preview == 0 && queue" class="display-inline">
  <div class="row-flex-flow margin-5">
    <div class="comm-edtr-name-container">
      <div><strong>{{queue.Name}}</strong></div>
      <div>Modified: {{queue.Amended | date:'shortDate': '' : translate.currentLang}} {{queue.Amended | date:'mediumTime': '' : translate.currentLang}}</div>
      <div>{{queue.Description}}</div>
    </div>
    <div class="column-flex-flow">
      <div class="row-flex-flow">
        <div class="proc-def-actionBtns-container">
          <strong>{{type()}}</strong>
        </div>
        <div class="proc-def-status-div">
          <div><strong>{{status()}}</strong>:&nbsp;{{queue.MessageCharacteristics.DefaultSpeed.description}}</div>
        </div>
      </div>
      <div class="row-flex-flow">
        <div class="proc-def-actionBtns-container">
          &nbsp;
        </div>
        <div class="row-flex-flow proc-def-actionBtns-container">
          <div class="proc-def-actionBtn-div">
            <button mat-mini-fab color="accent" matTooltip="Edit Definition" (click)="editDefinition(1)"><mat-icon>edit</mat-icon></button>
          </div>
          <div class="proc-def-actionBtn-div">
            <button mat-mini-fab color="accent" matTooltip="Delete Definition" (click)="editDefinition(4)"><mat-icon>delete</mat-icon></button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row-flex-flow margin-5">
    <div class="column-flex-flow">
      @if (userSummary.length > 0 || queue.TypeId == 1) {
        @if (userSummary.length > 0) {
          <strong>Applies to</strong>
        }
        <div *ngFor="let user of userSummary;let i = index">{{user.UserName}} ({{user.EmailAddress}})</div>
      } @else {
      <strong>Applies to all users</strong>
      }
    </div>
  </div>
   <mat-divider></mat-divider>
  </div>
<div *ngIf="preview == 1" [@detailExpand]="preview == 1 ? 'expanded' : 'collapsed'">
  <ng-container *ngTemplateOutlet="editorButtons"></ng-container>
  <div [formGroup]="formGroup">
    <div>
      <mat-form-field appearance="outline" class="halfWidth">
        <mat-label>Name</mat-label>
        <input matInput placeholder="Name" formControlName="Name">
      </mat-form-field>&nbsp;
    </div>
    <div>
      <mat-form-field appearance="outline" class="fullWidth">
        <mat-label>Description</mat-label>
        <input matInput placeholder="Description" formControlName="Description">
      </mat-form-field>
    </div>
    <div class="user-details-status-div">
      <span>Status: </span>&nbsp;&nbsp;
      <mat-button-toggle-group formControlName="Status">
        <mat-button-toggle [value]="0">Active</mat-button-toggle>
        <mat-button-toggle [value]="1">Suspended</mat-button-toggle>
      </mat-button-toggle-group>
    </div>
    <div>
      <div class="user-details-status-div">
        <span>Type: </span>&nbsp;&nbsp;
        <mat-button-toggle-group #group="matButtonToggleGroup" formControlName="Type">
          <mat-button-toggle [value]="0">System</mat-button-toggle>
          <mat-button-toggle [value]="1">User</mat-button-toggle>
        </mat-button-toggle-group>
      </div>
    </div>
    <div *ngIf="formType() == 1">
      <h3>
        A user message queue can be used to set the speeds of messages for an API connection or the whole account.
      </h3>
      <p>
        Select the speeds the account will be able to choose from to control their message queue.
      </p>
      <div style="width:25%">
        <mat-selection-list formControlName="AllowedSpeeds">
          <mat-list-option *ngFor="let speed of dummyQueue.MessageCharacteristics.AllowedSpeeds" [value]="speed.description">{{speed.description}}</mat-list-option>
        </mat-selection-list>
      </div>
      <p>
        Select a default speed for the their message queue.
      </p>
      <div>
        <mat-form-field appearance="outline">
          <mat-label>Speed</mat-label>
          <mat-select formControlName="Speed">
            <mat-option *ngFor="let speed of dummyQueue.MessageCharacteristics.AllowedSpeeds" [value]="speed.description">{{speed.description}}</mat-option>
          </mat-select>
        </mat-form-field>&nbsp;
      </div>
      <p>
        Select an Account to offer this message queue to.
      </p>
      <app-user-org-search #oneuser [users]="owner" [userIds]="ownerId" label="Account"></app-user-org-search>
    </div>
    <div *ngIf="formType() == 0">
      <h3>
        A system message queue can be used to prioritise the speed of messages for one or more accounts.
      </h3>
      <div>
        <mat-form-field appearance="outline">
          <mat-label>Speed</mat-label>
          <mat-select formControlName="Speed">
            <mat-option *ngFor="let speed of dummyQueue.MessageCharacteristics.AllowedSpeeds" [value]="speed?.description">{{speed?.description}}</mat-option>
          </mat-select>
        </mat-form-field>&nbsp;
      </div>
      <p>
        Select All Accounts or One or More Accounts the Message Queue is for.
      </p>
      <mat-radio-group formControlName="All">
        <div>
          <mat-radio-button [value]="0">All Accounts</mat-radio-button>
        </div>
        <div>
          <mat-radio-button [value]="1">Select Accounts</mat-radio-button>
          <div *ngIf="formAll()">
            <app-user-org-search #userlist [users]="users" [userIds]="userIds" label="User List"></app-user-org-search>
          </div>
        </div>
      </mat-radio-group>
    </div>
    </div>
  <ng-container *ngTemplateOutlet="editorButtons"></ng-container>
</div>
<ng-template #editorButtons>
  <div class="simple-row">
    <div class="comm-edtr-divider-div">
      <mat-divider>
      </mat-divider>
    </div>
    <div>
      <button mat-mini-fab matTooltip="Save" (click)="saveDefinition()"><mat-icon>save</mat-icon></button>
    </div>
    <div>
      <button mat-mini-fab matTooltip="Close" (click)="cancelDefinition()"><mat-icon>close</mat-icon></button>
    </div>
    <div class="comm-edtr-divider-div">
      <mat-divider>
      </mat-divider>
    </div>
  </div>
</ng-template>

