import { Component, OnInit } from '@angular/core';
import { FormArray, Validators, FormBuilder, FormGroup } from '@angular/forms';
import { BaseQuestion, DataModel, InterviewService, QuestionService } from '../../question.service';

@Component({
  selector: 'qe-app-geocode',
  templateUrl: './qe-geocode.component.html',
  styleUrls: ['./qe-geocode.component.css']
})
export class QeGeocodeComponent extends BaseQuestion implements OnInit {
  constructor(private qs: QuestionService, private is: InterviewService) {
    super(qs, is, undefined, undefined, new DataModel("geocode"));
  }

  public geocodeFormGroup: FormGroup = new FormGroup({});

  ngOnInit() {
    this.geocodeFormGroup = this.fb.group({
      geocodeFormArray: this.fb.array([this.initQuestion()])
    });
  }

  get questionArr(): FormArray | null {
    if (this.geocodeFormGroup) {
      return this.geocodeFormGroup.get('geocodeFormArray') as FormArray;
    }

    return null;
  }

  onSubmit(): void {
  }
}
