<div *ngIf="show" class="padding-10">
  <div *ngIf="displayLanguages" class="canvas-lang-div">
    <button mat-stroked-button matTooltip="Default Langauge" [matMenuTriggerFor]="defLangMenu">
      <mat-icon>language</mat-icon>{{languageSelected?.Locale}}
    </button>
    <mat-menu #defLangMenu="matMenu">
      <ng-template matMenuContent>
        <button *ngFor="let language of languages" mat-menu-item (click)="updateLanguage(language)">
          <span>{{language.Locale}}</span>
        </button>
      </ng-template>
    </mat-menu>
  </div>
  <div class="clearBoth"></div>
  <diy-banner></diy-banner>
  <diy-data [show]="show" #data [preview]="preview" [question]="question" [playerWidth]="playerWidth" [color]="controlsColor"></diy-data>
  <div *ngIf="showNavigation() && !preview">
    <br />&nbsp;
    <diy-navbar #nav [navigation]="navigation" [color]="navigationColor" [navTxtColor]="navigationTextColor" (navigate)="onNavigate($event)"></diy-navbar>
  </div>
</div>
