import { Component, OnInit, Input, AfterViewInit, Inject } from '@angular/core';
import { DatePipe } from '@angular/common';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { AnswerModel } from "../../models/AnswerModel";
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MAT_MOMENT_DATE_FORMATS, MomentDateAdapter } from '@angular/material-moment-adapter';

@Component({
  selector: 'app-date',
  templateUrl: './date.component.html',
  styleUrls: ['./date.component.css'],
  providers: [
    // The locale would typically be provided on the root module of your application. We do it at
    // the component level here, due to limitations of our example generation script.
    { provide: MAT_DATE_LOCALE, useValue: 'en-GB' },

    // `MomentDateAdapter` and `MAT_MOMENT_DATE_FORMATS` can be automatically provided by importing
    // `MatMomentDateModule` in your applications root module. We provide it at the component level
    // here, due to limitations of our example generation script.
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
  ],
})
export class DateComponent implements OnInit, AfterViewInit {

  showDate: boolean = false;
  showTime: boolean = false;

  formGroup: FormGroup;

  constructor(private _adapter: DateAdapter<any>,
    @Inject(MAT_DATE_LOCALE) private _locale: string,
    private datePipe: DatePipe) {
    this.formGroup = new FormGroup({
      date: new FormControl("", [Validators.required]),
      time: new FormControl("", [Validators.required])
    });
  }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    if (this._question.Response.Value === null) {
      return;
    }

    Promise.resolve(null).then(() => {
      this.populateForm();
    });
  }


  @Input()
  get question(): any {
    return this._question;
  }
  set question(value: any) {
    this._question = value;
    if (this._question === undefined) {
      return;
    }

    this.showDate = false;
    this.showTime = false;
    switch (this._question.Style.Control.Type) {
      case 11:
        this.showDate = true;
        break;
      case 12:
        this.showTime = true;
        break;
      case 13:
        this.showDate = true;
        this.showTime = true;
        break;
      }

    this.populateForm();
  }
  private _question: any;

  get answers(): AnswerModel[] {
    const values: AnswerModel[] = [];
    try {
      let datetime: Date | null = null;
      switch (this._question.Style.Control.Type) {
        case 11:
          datetime = this.formGroup.controls.date.value;
          break;
        case 12:
          datetime = new Date(this.datePipe.transform(new Date(), 'shortDate') + " " + this.formGroup.controls.time.value);
          break;
        case 13:
          datetime = new Date(this.datePipe.transform(this.formGroup.controls.date.value, 'shortDate') + " " + this.formGroup.controls.time.value);
          break;
      }

      let answer = "";
      try {
        answer = datetime?.toISOString() ?? "";
      }
      catch {}

      values.push(new AnswerModel(this._question.QuestionFullName, answer));
    }
    catch { }
    return values;
  }

  get valid(): boolean {
    return true;
  }

  displayDate(): string {
    var datetime;
    switch (this._question.Style.Control.Type) {
      case 11:
        datetime = this.formGroup.controls.date.value;
        break;
      case 12:
        datetime = new Date(this.datePipe.transform(new Date(), 'shortDate') + " " + this.formGroup.controls.time.value);
        break;
      case 13:
        datetime = new Date(this.datePipe.transform(this.formGroup.controls.date.value, 'shortDate') + " " + this.formGroup.controls.time.value);
        break;
    }
    let a = this.answers;
    if (a.length > 0) {
      return "Answer: " + a[0].value + ", datetime:" + datetime + ", date: " + this.formGroup.controls.date.value + ", time: "
        + this.formGroup.controls.time.value + ", question: " + this._question.Response.Value;
    }

    return "no answer";
  }

  populateForm() {
    const value = this._question.Response.Value == null ? new Date() : new Date(this._question.Response.Value);
    if (this.formGroup) {
      try {
        let a = this.datePipe.transform(value, 'shortDate');
        this.formGroup.controls.date.setValue(this.datePipe.transform(value, 'yyyy-MM-dd'));
        this.formGroup.controls.time.setValue(this.datePipe.transform(value, "hh:mm a"));
      }
      catch { }

      this.formGroup.controls.date.clearValidators();
      this.formGroup.controls.time.clearValidators();
      if (this._question.MustAnswer) {
        this.formGroup.controls.date.addValidators(Validators.required);
        this.formGroup.controls.time.addValidators(Validators.required);
      }
    }
  }
}
