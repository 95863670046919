<div class="main-div display-flex fill-flex align-center-start-flex">
  <mat-card class="fxFlex-90" fxFlex.xs="100" fxFlex.sm="40">
    <form class="flex-stretch-column" [formGroup]="formGroup" (ngSubmit)="onSubmit()">
      <div class="first-section">
        <div>
          <mat-form-field appearance="outline" class="fullWidth">
            <mat-label>Email Type</mat-label>
            <mat-select formControlName="EmailType">
              <mat-option *ngFor="let type of types" [value]="type.id">
                {{type.text}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div>
          <mat-form-field appearance="outline" class="fullWidth">
            <mat-label>Template Name</mat-label>
            <input matInput placeholder="Template Name" formControlName="Name">
          </mat-form-field>
        </div>
        <div>
          <mat-form-field appearance="outline" class="fullWidth">
            <mat-label>Description</mat-label>
            <input matInput placeholder="Description" formControlName="Description">
          </mat-form-field>
        </div>
        <div>
          <mat-form-field appearance="outline" class="fullWidth">
            <mat-label>Subject</mat-label>
            <input matInput placeholder="Subject" formControlName="Subject">
          </mat-form-field>
        </div>
      </div>
      <div class="comm-edtr-diyEdtr-container padding-20">
        <diy-editor channel="web" formControlName="EmailContent"></diy-editor>
      </div>
    </form>
  </mat-card>
</div>
