<div class="leftFloat">
  <h2>Export Filters</h2>
</div>
<div class="rightFloat">
  <button mat-icon-button mat-dialog-close>
    <mat-icon>close</mat-icon>
  </button>
</div>
<div class="clearBoth">
  <div class="alert alert-primary row-flex align-start-center-flex exprt-filtr-content" role="alert" div>
    <mat-icon>read_more</mat-icon>
    <div>
      <button mat-icon-button [matMenuTriggerFor]="advMenu" aria-label="icon-button with a menu">
        <mat-icon class="dialog-close-icon">more_vert</mat-icon>
      </button>
      <mat-menu #advMenu="matMenu">
        <button mat-menu-item (click)="edit()">Edit</button>
        <button mat-menu-item (click)="delete()">Delete</button>
      </mat-menu>
    </div>
    <app-advanced-routing [display]="true" [rules]="rules" name="Filter " class="fullWidth"></app-advanced-routing>
  </div>
</div>
