<div>
  <form class="senderidform" [formGroup]="formGroup">

    <p>
      Organization
      <br />
      <mat-form-field appearance="outline" class="msg-hist-filtByCountry-field">
        <mat-label>Organization</mat-label>
        <mat-select formControlName="Organization">
          <mat-option value="">All Organization</mat-option>
          <mat-option *ngFor="let org of orgList" [value]="org">{{org.name}}</mat-option>
        </mat-select>
      </mat-form-field>
      <br />
      Country
      <br />
      <mat-form-field appearance="outline" class="msg-hist-filtByCountry-field">
        <mat-label>Country</mat-label>
        <mat-select formControlName="Country">
          <mat-option value="">All Countries</mat-option>
          <mat-option *ngFor="let country of countries" [value]="country">{{country.Country}}</mat-option>
        </mat-select>
      </mat-form-field>&nbsp;
      <br />
      Sender ID
      <br />
      <mat-form-field appearance="outline" class="msg-hist-filtByNum-field" style="width:288px">
        <mat-label>Sender ID</mat-label>
        <input matInput placeholder="Sender ID" formControlName="SenderID">
      </mat-form-field>
      <br />
      Company Name
      <br />
      <mat-form-field appearance="outline" class="msg-hist-filtByNum-field" style="width:288px">
        <mat-label>Company Name</mat-label>
        <input matInput placeholder="Company Name" formControlName="CompanyName">
      </mat-form-field>
      <br />
      Company Website
      <br />
      <mat-form-field appearance="outline" class="msg-hist-filtByNum-field" style="width:288px">
        <mat-label>Company Website</mat-label>
        <input matInput placeholder="Company Website" formControlName="CompanyWebsite">
      </mat-form-field>
      <br />
      Name of Official
      <br />
      <mat-form-field appearance="outline" class="msg-hist-filtByNum-field" style="width:288px">
        <mat-label>Name of Official Company Rep</mat-label>
        <input matInput placeholder="Company Repre Name" formControlName="CompanyRepName">
      </mat-form-field>
      <br />
      Role of Official
      <br />
      <mat-form-field appearance="outline" class="msg-hist-filtByNum-field" style="width:288px">
        <mat-label>Role of Official Company Rep</mat-label>
        <input matInput placeholder="Company Repre Role" formControlName="CompanyRepRole">
      </mat-form-field>
      <br />
      Company Contact Number
      <br />
      <mat-form-field appearance="outline" class="msg-hist-filtByNum-field" style="width:288px">
        <mat-label>Company Contact Number</mat-label>
        <input matInput placeholder="Company Contact Number" formControlName="CompanyContact">
      </mat-form-field>
      <br />
      Estimated monthly volume
  <br />
  <mat-form-field appearance="outline" class="msg-hist-filtByNum-field" style="width:288px">
    <mat-label>Estimated monthly volume</mat-label>
    <input matInput placeholder="Estimated monthly volume" formControlName="CompanyVolume">
  </mat-form-field>
  <br />
      </p>
      Sample messages
      <br />
      <div>
        <app-sms-editor formControlName="SampleMessage" place #sms [text]="message" [channel]="isSms() ? 'sms' : 'whatsapp'" [smart]="false"></app-sms-editor>
      </div>
      <!--<mat-form-field appearance="outline" class="msg-hist-filtByNum-field" style="width:288px">
    <mat-label>Sample messages</mat-label>
    <input matInput placeholder="Sample messages" formControlName="SampleMessage">
  </mat-form-field>-->
      <br />
    <p>
      URL format
      <br />
      <mat-form-field appearance="outline" class="msg-hist-filtByNum-field" style="width:288px">
        <mat-label>URL format</mat-label>
        <input matInput placeholder="URL format" formControlName="URLformat">
      </mat-form-field>
      <br />
      Service Description
      <br />
      <mat-form-field appearance="outline" class="msg-hist-filtByNum-field" style="width:288px">
        <mat-label>Service Description</mat-label>
        <input matInput placeholder="Service Description" formControlName="ServiceDescription">
      </mat-form-field>
      <br />
      Brand Type
      <br />
      <mat-form-field appearance="outline" class="msg-hist-filtByCountry-field">
        <mat-label>Brand Type</mat-label>
        <mat-select formControlName="brandtype">
          <mat-option value="Domestic">Domestic</mat-option>
          <mat-option value="International">International</mat-option>
        </mat-select>
      </mat-form-field>
      <br />
      Traffic Type
      <br />
      <mat-form-field appearance="outline" class="msg-hist-filtByCountry-field">
        <mat-label>Traffic Type</mat-label>
        <mat-select formControlName="traffictype">
          <mat-option value="Transactional (Surveys)">Transactional (Surveys)</mat-option>
          <mat-option value="Promotional (Advertising and Marketing)">Promotional (Advertising and Marketing)</mat-option>
        </mat-select>
      </mat-form-field>
    </p>

      <button mat-flat-button color="primary" (click)="newSearch()">Send</button>
  </form>
</div>

<div *ngIf="displayError">
  <div class="alert alert-danger" role="alert">
    {{errorMsg}}
  </div>
</div>

<div *ngIf="displaySuccess">
  <div class="alert alert-success" role="alert">
    Email has been sent for your Sender ID Registration.
  </div>
</div>
