import { Component, OnInit, Input } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSelectChange } from '@angular/material/select';
import { QuestionModel, RuleList } from '../../question.service';

@Component({
  selector: 'app-question-combo',
  templateUrl: './question-combo.component.html',
  styleUrls: ['./question-combo.component.css']
})
export class QuestionComboComponent implements OnInit {

  questionId: string = '';

  constructor() {
  }

  ngOnInit(): void {
  }

  questionsForRuleType(): QuestionModel[] {
    return this.rules.Question?.Interview.questionList(true, [], "branch") ?? [];
  }

  canDisplay(): boolean {
    return this._rules.Rules.length > 0 && this.questionsForRuleType().length > 0;
  }

  @Input()
  get rules(): RuleList {
    return this._rules;
  }
  set rules(value: RuleList) {
    this._rules = value;
    this.populateForm();
  }
  private _rules = new RuleList();

  @Input()
  get displayGoto(): boolean {
    return this._displayGoto;
  }
  set displayGoto(value: boolean) {
    this._displayGoto = value;
  }
  private _displayGoto: boolean = false;

  populateForm() {
    this.questionId = "";
    if (this._rules.Rules.length > 0) {
      this.questionId = this._rules.Rules[0].TargetId;
    }
  }

  valueChanged(change: MatSelectChange) {
    let a = change;
    this._rules.Rules[0].TargetId = change.value.toString();
  }
}
