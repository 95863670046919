  <div *ngIf="checkBoxes || radioButtons || buttons || buttonsMultiple">
    <diy-category #category *ngFor="let categorical of question.Categories.Items; let i = index"
                  [question]="question" [index]="i" [displayLabel]="true" [groupName]="question.QuestionFullName"
                  (exclusive)="onExclusive(i)"></diy-category>
  </div>
  <div *ngIf="dropDown">
    <mat-form-field appearance="outline">
      <mat-select [(value)]="dropdownSelected">
        <mat-option *ngFor="let categorical of question.Categories.Items; let i = index" [value]="categorical.Name">{{categorical.Label.Text}}</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div *ngIf="dropDownMultiple">
    <mat-form-field appearance="outline">
      <mat-select [(value)]="dropdownMultiSelected" multiple>
        <mat-option *ngFor="let categorical of question.Categories.Items; let i = index" [value]="categorical.Name">{{categorical.Label.Text}}</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
