<div *ngIf="(survey && survey.PanelID === -1) || panel; then distribute else dontDistribute"></div>
<div class="slctRspndnt-main-div" [hidden]="!showMainSection">
  <div class="slctRspndnt-card-div">
    <a (click)="uploadDivFunction()">
      <mat-card class="slctRspndnt-secondstep-cards column-flex">
        <div class="slctRspndnt-card-title-container align-center-start-flex row-flex">
          <div class="slctRspndnt-card-title-content">
            <span>Upload Your Respondents</span>
          </div>
          <div>
            <img mat-card-image src="assets/img/upload_2.png" alt="Upload Respondents" class="slctRspndnt-card-img">
          </div>
        </div>
        <div class="slctRspndnt-card-description-container">
          <div class="slctRspndnt-card-description-content">
            <span>
              Upload your own respondents using our respondent upload template and we will analyse them for the best delivery options.
            </span>
          </div>
        </div>
      </mat-card>
    </a>
  </div>
  <div class="slctRspndnt-card-div" *ngIf="panelCount > 0">
    <a (click)="showPanels()">
      <mat-card class="slctRspndnt-secondstep-cards column-flex">
        <div class="slctRspndnt-card-title-container align-center-start-flex row-flex">
          <div class="slctRspndnt-card-title-content">
            <span>Use Your Previous Respondents</span>
          </div>
          <div>
            <img mat-card-image src="assets/img/respondents_2.png" alt="Use Previous Respondents" class="slctRspndnt-card-img">
          </div>
        </div>
        <div class="slctRspndnt-card-description-container">
          <div class="slctRspndnt-card-description-content">
            <span>You have {{panelCount}} previous Respondent list(s) uploaded.</span>
          </div>
        </div>
      </mat-card>
    </a>
  </div>
  <div *ngIf="interview && interview.Channel != 'sms'" class="slctRspndnt-card-div">
    <a (click)="buyDivFunction()">
      <mat-card class="slctRspndnt-secondstep-cards column-flex">
        <div class="slctRspndnt-card-title-container align-center-start-flex row-flex">
          <div class="slctRspndnt-card-title-content">
            <span>Buy Respondents for this Survey</span>
          </div>
          <div>
            <img mat-card-image src="assets/img/buy_2.png" alt="Select & Buy Respondents" class="slctRspndnt-card-img">
          </div>
        </div>
        <div class="slctRspndnt-card-description-container">
          <div class="slctRspndnt-card-description-content">
            <span>Select and buy respondents from our cost effective Global panel (100+ million people, 60 countries, 50 socio-demographic profile elements)</span>
          </div>
        </div>
      </mat-card>
    </a>
  </div>
  <div *ngIf="survey && survey.Channel != 4 && survey.Channel != 3" class="slctRspndnt-card-div">
    <a (click)="sendYourself()">
      <mat-card class="slctRspndnt-secondstep-cards column-flex">
        <div class="slctRspndnt-card-title-container align-center-start-flex row-flex">
          <div class="slctRspndnt-card-title-content">
            <span>Make Surveys Live Your Way</span>
          </div>
          <div>
            <img mat-card-image src="assets/img/your_way_2.png" alt="Distribute Yourself" class="slctRspndnt-card-img">
          </div>
        </div>
        <div class="slctRspndnt-card-description-container">
          <div class="slctRspndnt-card-description-content">
            <span>Having created your DIY Survey, you want to: embed it in your website, an email signature, post a link in social media, send via your own Email, SMS or Chat App, or generate our QR code and TinyUrl for respondents to engage with.</span>
          </div>
        </div>
      </mat-card>
    </a>
  </div>
  <div class="slctRspndnt-card-div" *ngIf="panelCount > 0">
    <a (click)="customDataImport()">
      <mat-card class="slctRspndnt-secondstep-cards column-flex">
        <div class="slctRspndnt-card-title-container align-center-start-flex row-flex">
          <div class="slctRspndnt-card-title-content">
            <span>Select a Custom Import Data Source</span>
          </div>
          <div>
            <img mat-card-image src="assets/img/respondents_2.png" alt="Use Previous Respondents" class="slctRspndnt-card-img">
          </div>
        </div>
        <div class="slctRspndnt-card-description-container">
          <div class="slctRspndnt-card-description-content">
            <span>For those who need a fully custom import using FPT, S3, etc, and multiple data formats.</span>
          </div>
        </div>
      </mat-card>
    </a>
  </div>
</div>

<div *ngIf="buyAudienceDiv">
  <div class="slctRspndnt-buy-audience-content">
    <div class="margin-5"><button mat-raised-button color="primary" (click)="showAudience()">Choose/Buy Respondents</button></div>
    <div class="slctRspndnt-or-separator">
      <span>Or</span>
    </div>
    <div class="margin-5"><button mat-raised-button color="primary" (click)="showPanelTemplate()">Buy Pre-profiled Respondents</button></div>
    &nbsp;
    <div><button mat-raised-button color="accent" (click)="onCloseBuyRespondentDiv()">Cancel</button></div>
  </div>
</div>

<!-- ================= Upload Respondents start ==================== -->
<div *ngIf="uploadDiv">
  <app-add-upload-respondent (panellistSaveEvent)="onPanellistSave($event)"
                             (cancelAddPanellistEvent)="onUploadDivCancelClick()"
                             (cancelUploadEvent)="onCancelUpload()"
                             [newPanelName]="survey ? survey.Name : 'Newly Uploaded'"
                             (clearEvent)="onClear()" #addPanellist></app-add-upload-respondent>
</div>

<div *ngIf="audienceCard" class="slctRspndnt-audienceCard-div">

</div>

<div *ngIf="audienceTemplateCard">
  <div *ngIf="audiencetemplateCancelbtn">
    <button mat-raised-button color="accent" (click)="onCloseAudienceTemplate()">Cancel</button>
  </div>
  <div class="slctRspndnt-pnlSlctrTemp-div custom-scroll-bar">
    <app-panel-selector-template (selected)="templateSelected($event)"></app-panel-selector-template>
  </div>
</div>
<!-- Select & Buy Respondents section end -->

<ng-template #distribute>
  <div [@detailExpand]="survey?.PanelID == -1 || panel ? 'expanded' : 'collapsed'" class="fullWidth margin-5">
    <app-panel-pricing #panelPricing [respondents]="this" [allowDelete]="allowDelete" [interview]="interview" [survey]="survey" [allowEditDelete]="allowEditDelete" (editing)="onEdit($event)"></app-panel-pricing>
  </div>
  <div [hidden]="showMainSection" class="slctRspndnt-showMainNot-div"></div>
  <div class="slctRspndnt-showMain-div" [hidden]="!showMainSection">
    <span>If you want to change how you would like to collect responses to your survey select from below</span>
  </div>
</ng-template>

<ng-template #dontDistribute>
  <div class="slctRspndnt-showMain-div" [hidden]="!showMainSection">
    <span>Which Respondents would you like to invite to your Survey?</span>
  </div>
</ng-template>
