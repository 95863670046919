import { Component, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar, MatSnackBarRef } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { TranslateService } from '@ngx-translate/core';
import { UserOrgSearchComponent } from '../../find-user/user-org-search/user-org-search.component';
import { ProcessService, ProcessServiceModel } from '../../process.Service';
import { SnackbarDefaultComponent } from '../../snackbar/snackbar-default.component';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { AuthenticationService } from '../../authentication.Service';
import { Router } from '@angular/router';
import { plainToClass } from 'class-transformer';
import { SurveyService } from '../../survey.Service';
import { UserService } from '../../user.Service';

@Component({
  selector: 'app-dataio',
  templateUrl: './dataio.component.html',
  styleUrls: ['./dataio.component.css'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ]
})
export class DataioComponent {
  test: string = "test";
  expandedElement: ProcessServiceModel;
  processes: ProcessServiceModel[] = [];
  columnsToDisplay: string[] =['icon', 'name', 'survey', 'status', 'amended', 'errors', 'user', 'action'];
  sb?: MatSnackBarRef<any>;
  dataSource = new MatTableDataSource<ProcessServiceModel>(this.processes);
  searchKey: string = "";
  resultsLength: number = 0;
  pageSize: number = 50;
  ready: boolean = false;
  @ViewChild('search') search: UserOrgSearchComponent | undefined;

  @ViewChild('table', { static: true }) table: any | undefined;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator | undefined;
  @ViewChild(MatSort, { static: false })
  set sort(value: MatSort) {
    if (this.dataSource) {
      this.dataSource.sort = value;
    }
  }

  constructor(public translate: TranslateService,
    private router: Router,
    private surveyService: SurveyService,
    private authenticationService: AuthenticationService,
    private processService: ProcessService,
    private userService: UserService,
    private snackbar: MatSnackBar) {
    this.loadProcesses();
  }

  loadProcesses() {
    this.sb = this.snackbar.openFromComponent(SnackbarDefaultComponent);
    this.processes = [];
    this.processService.getProcessForSystem().subscribe(
      result => {
        let temp: ProcessServiceModel[] = [];
        for (let i = 0; i < result.length; i++) {
          temp.push(plainToClass(ProcessServiceModel, result[i]));
        }

        this.processes = temp;
        this.dataSource = new MatTableDataSource(this.processes);
        if (this.paginator != null) {
          this.dataSource.paginator = this.paginator;
        }

        this.dataSource.sort = this.sort;
        this.dataSource.filter = this.searchKey;
        this.resultsLength = this.processes.length;
        for (let i = 0; i < this.processes.length; i++) {
          this.getEvents(this.processes[i]);
          this.surveyService.getSurveySummary(this.processes[i].ReferenceId).subscribe(
            result => {
              this.processes[i].surveySummary = result;
            });
          this.userService.getUser(this.processes[i].OwnerId.toString()).subscribe(
            result => {
              this.processes[i].userProfile = result;
            }
          )
        }

        this.sb?.dismiss();
      },
      error => {
        this.sb?.dismiss();
      });
  }

  load() {
    if (this.search?.users.length > 0) {
      this.test = this.search.users[0].UserID.toString();
    }
  }

  isExport(process: ProcessServiceModel) {
    return process.Task.Type == "EXPORT";
  }

  automate(process: ProcessServiceModel) {
    return (process.StatusId > 0 && (process.StatusId < 3 || process.StatusId == 4)) || process.Task.Automated;
  }

  getEvents(process: ProcessServiceModel) {
    this.processService.getProcessMessages(process.ProcessId, 0).subscribe(
      result => {
        process.messages = result;

      });
    this.processService.getProcessErrors(process.ProcessId, 0).subscribe(
      result => {
        process.errors = result;
      });
  }

  login(process: ProcessServiceModel) {
    this.authenticationService.isOrganization(process.OwnerId).subscribe(
      result => {
        if (result) {
          this.authenticationService.impersonateAsOwner(process.OwnerId)
            .subscribe(result => {
              const token = (result as any).Token;
              this.authenticationService.setAccessToken(token);
              this.router.navigate(["/delivery-summary", process.ReferenceId, "summary"])
            },
              error => {
                console.error(error);
              });
          return;
        }

        this.userService.getUser(process.OwnerId.toString()).subscribe(
          result => {
            let user = result;
            this.authenticationService.impersonateAs(user.UserName).subscribe(
              result => {
                const token = (result as any).Token;
                this.authenticationService.setAccessToken(token);
                this.router.navigate(["/delivery-summary", process.ReferenceId, "summary"])
              },
              error => {
                console.error(error);
              });
          });
      });

    return false;
  }

  delete(process: ProcessServiceModel) {
    this.processService.deleteForUserProcess(process).subscribe(
      result => {
        this.openSnackbar("Successfully deleted the process definition", "");
        for (let i = 0; i < this.processes.length; i++) {
          if (process.ProcessId == this.processes[i].ProcessId) {
            this.processes.splice(i, 1)
            this.resultsLength--;
            this.dataSource = new MatTableDataSource(this.processes);
            return;
          }
        }
      },
      error => {
        this.openSnackbar("There was a problem deleting the process definition", "Cancel");
      }
    )
  }

  errorMessageDate(message: string): Date | null {
    if (!message.startsWith("{")) {
      return null;
    }

    let temp = message.split("}");
    let dateString = temp[0].substring(1);
    return new Date(dateString);
  }

  errorMessageValue(message: string): string {
    if (!message.startsWith("{")) {
      return message;
    }

    let temp = message.split("}");
    return temp[1];
  }

  private openSnackbar(message: string, action: string) {
    if (action == "") {
      this.snackbar.open(message, action, { duration: 2000 });
    }
    else {
      this.snackbar.open(message, action, { duration: 7000 });
    }
  }
}
