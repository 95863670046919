<div *ngIf="!displayQRTiny && survey && !(survey.JobMaster && survey.JobMaster.ChannelID > 0 && survey.Channel != 1)">
  <div *ngIf="process" style="margin-left:5px;width:100%">
    <app-process-definition [index]="0" [process]="process" [survey]="survey" [interview]="interview" (closed)="processClosed($event)"></app-process-definition>
  </div>
  <div *ngIf="panel && panel.Summary.Emails > 0 && panel.Summary.Devices > 0 && survey.Channel != 1 && survey.Channel != 5" class="surv-distr-header-span" [hidden]="!show">
    <span>How do you want to distribute your Survey?</span>
  </div>
  <div *ngIf="panel && panel.Summary.Emails > 0 && panel.Summary.Devices > 0 && survey.Channel == 1" class="surv-distr-header-span" [hidden]="!show">
    <span>Your survey will be sent via SMS. Please make sure your first question introduces your survey and indicates how respondents should reply.</span>
  </div>
  <div class="surv-distr-sendType-container">
    <div *ngIf="panel && !panel.IsCint && survey.Channel != 1 && survey.Channel != 4" class="surv-distr-card-div">
      <a (click)="onSendClick(5)">
        <mat-card class="surv-distr-distribution-cards surv-distr-card">
          <img mat-card-image src="assets/img/whatsapp.svg" alt="Send Survey by WhatsApp Invite" class="surv-distr-send-whatsapp-img">
          <mat-card-content>
            <div class="surv-distr-send-type-span">
              <span>Send Survey by WhatsApp Invite</span>
            </div>
          </mat-card-content>
        </mat-card>
      </a>
    </div>
    <div *ngIf="panel && !panel.IsCint && panel.Summary.Emails > 0 && survey && survey.Channel != 3 && survey.Channel != 5 && survey.Channel != 1 && survey.Channel != 4" class="surv-distr-card-div">
      <a (click)="onSendClick(1)">
        <mat-card class="surv-distr-distribution-cards surv-distr-card">
          <img mat-card-image src="assets/img/email_2.png" alt="Send Survey by email" class="surv-distr-send-type-img">
          <mat-card-content>
            <div class="surv-distr-send-type-span">
              <span>Send Survey by email</span>
            </div>
          </mat-card-content>
        </mat-card>
      </a>
    </div>
    <div *ngIf="panel && !panel.IsCint && panel.Summary.Devices > 0 && survey && survey.Channel != 3 && survey.Channel != 5 && survey.Channel != 1" class="surv-distr-card-div">
      <a (click)="onSendClick(2)">
        <mat-card class="surv-distr-distribution-cards surv-distr-card">
          <img mat-card-image src="assets/img/invite_2.png" alt="Send Survey link by SMS Invite" class="surv-distr-send-type-img">
          <mat-card-content>
            <div class="surv-distr-send-type-span">
              <span>Send Survey link by SMS invite</span>
            </div>
          </mat-card-content>
        </mat-card>
      </a>
    </div>
  </div>
</div>
<div *ngIf="survey && survey.JobMaster && survey.JobMaster.ChannelID > 0 && survey.JobMaster.ChannelID !== 3 && survey.Channel != 1">
  <mat-card class="surv-distr-send-email-card column-flex">
    <div class="surv-distr-jobmaster-header auto-flex">
      <strong *ngIf="survey.JobMaster.ChannelID === 1">Your Email Invite</strong>
      <strong *ngIf="survey.JobMaster.ChannelID === 2">Your SMS Invite</strong>
    </div>
    <div *ngIf="survey.JobMaster.ChannelID === 1">
      <h3>{{survey.JobMaster.Subject}}</h3>
    </div>
    <div class="auto-flex">
      <div *ngIf="survey.JobMaster.ChannelID == 1 || survey.JobMaster.ChannelID == 2 || survey.JobMaster.ChannelID == 5">
        <mat-form-field *ngIf="survey.JobMaster.ChannelID != 1"  appearance="outline" class="fullWidth">
          <mat-label>Content</mat-label>
          <textarea matInput [readonly]="true" class="surv-distr-textarea"
                    cdkTextareaAutosize
                    #autosize="cdkTextareaAutosize"
                    cdkAutosizeMinRows="6"
                    cdkAutosizeMaxRows="20">{{survey.JobMaster.Content}}</textarea>
        </mat-form-field>
        <div *ngIf="survey.JobMaster.ChannelID == 1" [innerHTML]="survey.JobMaster.Content" class="mat-form-field-appearance-outline surv-distr-inviteMessage"></div>
      </div>
    </div>
    <div class="auto-flex">
      <button mat-stroked-button (click)="onSendClick(survey.JobMaster.ChannelID)">Edit</button>&nbsp;&nbsp;
      <button mat-stroked-button (click)="cancelChoice()">Cancel</button>
    </div>
  </mat-card>
</div>
<div *ngIf="displayQRTiny" class="column-flex auto-flex margin-10">
  <div class="surv-distr-send-qr-card column-flex">
    <div class="surv-distr-send-qr-div auto-flex"><strong>Send by QR Code</strong></div>
    <div class="auto-flex">
      Copy this QR Code, add it to your chosen communication channel(s) e.g. Website, TV advert, Poster, Menu, Invoice) and invite respondents to scan it with their mobile device’s camera to take your survey.
    </div>
    <div class="txt-align-center auto-flex">
      <asw-qr-code [qrData]="surveyUrl"
                   [width]="180"
                   [height]="180"
                   logo="../../../assets/img/QrLogo.png"
                   [logoStyle]="{hideBackgroundCircle: true, logoSize: 0.3, logoMargin: 0}">    </asw-qr-code>
    </div>
    <div class="surv-distr-send-qr-div flow-flex">
      <strong>Survey URLs</strong>
    </div>
    <div class="flow-flex">
      Use this url and we will track its usage for you!
    </div>
    <div class="flow-flex">
      <mat-form-field appearance="outline" class="fullWidth">
        <input matInput readonly [value]="survey?.Sid">
        <button mat-icon-button matSuffix (click)="copied(survey?.Sid ?? '')">
          <mat-icon class="surv-disr-icon">file_copy</mat-icon>
        </button>
      </mat-form-field>
    </div>
    <div class="flow-flex">
      or use our Tiny URL service for an easier version to share.
    </div>
    <div class="flow-flex">
      <mat-form-field appearance="outline">
        <input matInput readonly [value]="tinyUrl">
        <button mat-icon-button matSuffix (click)="copied(tinyUrl)">
          <mat-icon matRipple class="surv-disr-icon">file_copy</mat-icon>
        </button>
      </mat-form-field>
    </div>
  </div>
</div>
<div *ngIf="displayKeyword" class="column-flex auto-flex margin-10">
  <div class="surv-distr-send-qr-card column-flex">
    <div class="surv-distr-send-qr-div auto-flex"><strong>Trigger your Survey with a Keyword</strong></div>
    <div class="auto-flex">
      Select a keyword from from your list or gnenerate one
    </div>
    <div class="flow-flex">
      <mat-form-field appearance="outline">
        <input matInput readonly [value]="tinyUrl">
        <button mat-icon-button matSuffix (click)="copied(tinyUrl)">
          <mat-icon class="surv-disr-icon">file_copy</mat-icon>
        </button>
      </mat-form-field>
    </div>
  </div>
</div>
