import { Component, OnInit, Input, AfterViewInit, ViewChild, EventEmitter, Output, AfterContentInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatCheckbox } from '@angular/material/checkbox';
import { MatRadioButton } from '@angular/material/radio';
import { AnswerModel } from "../../models/AnswerModel";
import { DataComponent } from '../data/data.component';

@Component({
  selector: 'diy-category',
  templateUrl: './category.component.html',
  styleUrls: ['./category.component.css']
})
export class CategoryComponent implements OnInit, AfterViewInit {

  radioButtons: boolean = false;
  checkBoxes: boolean = false;
  other: boolean = false;
  buttons: boolean = false;
  isSelected: boolean = false;
  checkingChange: boolean = false;

  @ViewChild('radiobutton') radiobutton: MatRadioButton | undefined;
  @ViewChild('checkbox') checkbox: MatCheckbox | undefined;
  @ViewChild('data') data: DataComponent | undefined;

  formGroup: FormGroup;

  constructor() {
    this.formGroup = new FormGroup({
      radiobutton: new FormControl("", [Validators.required]),
      checkbox: new FormControl("", [Validators.required]),
      textBox: new FormControl("", [Validators.required])
    });
  }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    Promise.resolve(null).then(() => {
      this.populateForm();
    });
  }

  @Input()
  get question(): any {
    return this._question;
  }
  set question(value: any) {
    this._question = value;
    if (this._question === undefined) {
      return;
    }

    this.setControls();
  }
  private _question: any;

  @Input()
  get readonly(): boolean {
    return this._readonly;
  }
  set readonly(value: boolean) {
    this._readonly = value;
  }
  private _readonly: boolean = false;

  get buttonColor(): string {
    return this.isSelected ? "accent" : "";
  }

  private setControls() {
    if (this._question === undefined || this._index === undefined) {
      return;
    }

    this.other = this._question.Categories.Items[this._index]?.OtherQuestion ?? null;
    this.radioButtons = this._question.Style.Control.Type === 6;
    this.checkBoxes = this._question.Style.Control.Type === 7;
    this.buttons = this._question.Style.Control.Type === 0x000A;
    if (this.buttons) {
      this.populateForm();
    }
  }

  private populateForm() {
    let value = false;
    if (this._question.Response.Value !== null) {
      if (this._question.Response.Value.includes(this._question.Categories.Items[this._index]?.Name ?? "----")) {
        value = true;
      }
    }

    this.isSelected = value;
    if (!this.buttons) {
      this.formGroup.patchValue(
        {
          radiobutton: value ? this._question.Categories.Items[this._index].Name : "",
          checkbox: value ? this._question.Categories.Items[this._index].Name : ""
        }
      )
    }

    if (this.other) {
      if (!value) {
        this._question.Categories.Items[this._index].OtherQuestion.Response.Value = "";
      }

      if (this.data != undefined) {
        this.data.answers = "dummy value";
      }
    }
  }

  @Input()
  get index(): number {
    return this._index;
  }
  set index(value: number) {
    this._index = value;
    this.setControls();
  }
  private _index: number = -1;

  @Input()
  get displayLabel(): boolean {
    return this._displayLabel;
  }
  set displayLabel(value: boolean) {
    this._displayLabel = value;
  }
  private _displayLabel: boolean = false;

  @Input()
  get preview(): boolean {
    return this._preview;
  }
  set preview(value: boolean) {
    this._preview = value;
  }
  private _preview: boolean = false;

  @Input()
  get groupName(): string {
    return this._groupName;
  }
  set groupName(value: string) {
    this._groupName = value;
  }
  private _groupName: string = "";

  get answers(): AnswerModel[] {
    let value = false;
    if (this.radioButtons) {
      value = this.radiobutton?.checked ?? false;
    }

    if (this.checkBoxes) {
      value = this.checkbox?.checked ?? false;
    }

    if (this.buttons && this.isSelected) {
      value = true;
    }

    const values: AnswerModel[] = [];

    if (this.other && this.data != undefined && this.data.answers[0].value.length > 0) {
      value = true;
      values.push(new AnswerModel(this._question.Categories.Items[this._index].Name, value.toString()));
      this.data.answers.forEach(answer => {
        values.push(answer);
      })
    }
    else {
      values.push(new AnswerModel(this._question.Categories.Items[this._index].Name, value.toString()));
    }

    return values;
  }
  set answers(value: any) {
    this.populateForm();
  }

  @Output() exclusive = new EventEmitter();

  onOtherChange() {
    if (this.checkingChange) {
      return;
    }

    this.checkingChange = true;
    let value = (this.data?.answers[0].value.length ?? 0) > 0;
    if (this.radioButtons && this.radiobutton != undefined) {
      this.radiobutton.checked = value;
    }
    if (this.checkBoxes && this.checkbox != undefined) {
      this.checkbox.checked = value;
    }
    this.checkingChange = false;
  }

  onChange(temp?: boolean) {
    if (this.checkingChange) {
      return;
    }

    this.checkingChange = true;
    let value = false;
    if (this.radioButtons) {
      value = this.radiobutton?.checked ?? false;
    }

    if (this.checkBoxes) {
      value = this.checkbox?.checked ?? false;
    }

    if (this.buttons) {
      this.isSelected = value = !temp;
    }

    if (this.other && this.data != undefined) {
      if (!value) {
        this.data.answers = [];
      }
      else {
        if (this.data.answers[0].value.length > 0) {
          value = true;
        }
      }

      this.data.onChange();
    }

    if (value) {
      this.exclusive.emit();
    }

    this.checkingChange = false;
  }
}
