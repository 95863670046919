import { Component, OnInit, Input, ViewChildren, QueryList, AfterViewChecked, ViewChild, ElementRef } from '@angular/core';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Observable } from 'rxjs';
import { QuestionEditorComponent } from '../question-editor/question-editor.component';
import { QuestionModel } from '../question.service';

@Component({
  selector: 'app-questionnaire-sidebar',
  templateUrl: './questionnaire-sidebar.component.html',
  styleUrls: ['./questionnaire-sidebar.component.scss']
})
export class QuestionnaireSidebarComponent implements OnInit {

  @ViewChild('scroller') scroller: ElementRef | undefined;

  previouslySelected: number = -1;
  selected: number = -1;
  waiting?: Observable<any>;
  showRouting = false;
  constructor() { }

  ngOnInit(): void {
  }

  scrollToSelection() {
    try {
      if (this.previouslySelected == this.selected) {
        return;
      }

      this.previouslySelected = this.selected;
      let childNodes = this.scroller?.nativeElement.childNodes;
      let k = 0;
      for (let i = 0; i < childNodes.length; i++) {
        if (childNodes[i].nodeName == '#comment') {
          k++;
        }
        else {
          if (k == this.selected) {
            this.scroller?.nativeElement.childNodes[i].scrollIntoView({
              behavior: "smooth",
              block: "nearest",
              inline: "nearest"
            });
            return;
          }
        }
      }
    }
    catch(error) {
      let a = error;
    }
  }

  drop(event: CdkDragDrop<string[]>) {
    if (this.questions == null || this.editor == null) {
      return;
    }

    const question = this.questions[event.previousIndex];
    const target = this.questions[event.currentIndex];
    const questionIndex = question.Index;
    const questionParentIndex = question.ParentIndex;
    const questionDisplayIndex = question.DisplayIndex;
    const questionDisplayParentIndex = question.DisplayParentIndex;
    let targetIndex = target.Index;
    let targetParentIndex = target.ParentIndex;
    let targetDisplayIndex = target.DisplayIndex;
    let targetDisplayParentIndex = target.DisplayParentIndex;

    // It is not possible to move a block into another question (especially itself!)
    if (question.ParentIndex === -1) {
      if (target.ParentIndex === question.Index) {
        return;
      }

      if (question.QuestionType === "block" && target.ParentIndex > -1) {
        return;
      }
    }

    if (question.QuestionType !== "block" && target.QuestionType === "block" && questionParentIndex != targetIndex) {
      targetParentIndex = targetIndex;
      targetDisplayParentIndex = targetDisplayIndex;
      targetIndex = 0;
      targetDisplayIndex = 0
    }

    if (questionParentIndex === targetParentIndex) {
      let parent = this.editor.interview.Questions;
      if (questionParentIndex > -1) {
        parent = this.editor.interview.Questions[questionParentIndex].Questions.List;
      }

      moveItemInArray(parent, questionIndex, targetIndex);
      this.editor.interview.reIndex(questionParentIndex, questionDisplayParentIndex, parent);
      this.editor.questions?.moveItemInArray(targetDisplayParentIndex, questionDisplayIndex, targetDisplayIndex);
    }
    else {
      if (questionParentIndex > -1 && targetParentIndex === -1) {
        if (targetIndex > questionParentIndex) {
          targetIndex++;
          targetDisplayIndex++;
        }
      }

      this.editor?.interview.moveQuestionOutOfBlock(questionParentIndex, questionIndex, targetParentIndex, targetIndex);
      this.editor?.questions?.moveItemOutOfBlock(questionDisplayParentIndex, questionDisplayIndex, targetDisplayParentIndex, targetDisplayIndex);
    }

    // Could have shifted everything before we can insert so just check it out
    if (questionParentIndex === -1) {
      if (questionIndex < targetParentIndex) {
        targetParentIndex--;
      }
    }

    this.selectQuestion(targetIndex, targetParentIndex);
    this._questions = (this.showRouting ? this.editor?.interview.Questions : this.editor?.interview.getDisplayQuestions()) ?? null;
  }

  selectQuestion(index: number, parentIndex: number) {
    const question = this.editor?.interview.findQuestionByIndex(parentIndex, index) ?? null;
    if (this.editor != null && question != null) {
      this.editor.questions?.selectQuestion(index, parentIndex, question.DisplayIndex, question.DisplayParentIndex);
      this.editor.routing?.selectQuestion(index, parentIndex);
      this.editor.index = index;
      this.editor.parentIndex = parentIndex;
    }
  }

  isSelected(position: number, index: number, parentIndex: number): boolean {
    return this.checkSelection(position, index, parentIndex);
  }

  checkSelection(position: number, index: number, parentIndex: number): boolean {
    try {
      if (this.editor?.parentIndex != null && this.editor.parentIndex != undefined && this.editor.parentIndex === parentIndex) {
        if (index === this.editor.index) {
          this.selected = position;
          return true;
        }
      }
    }
    catch {
      let a = 0;
    }

    return false;
  }

  refresh() {
    this._questions = null;
  }

  @Input()
  get editor(): QuestionEditorComponent | null {
    return this._editor;
  }
  set editor(value: QuestionEditorComponent | null) {
    this._editor = value;
    if (this._editor === null) {
      return;
    }

    this.waiting = new Observable<any>();
  }
  private _editor: QuestionEditorComponent | null = null;

  get checked(): boolean {
    return this.editor != null && this.editor.modelChecked;
  }

  get questions(): QuestionModel[] | null {
    if (this._questions && this._questions.length > 0) {
      if (this.checked && this.editor?.showQuestionList != this.showRouting) {
        return this._questions;
      }
    }

    if (this.checked) {
      this.showRouting = !this.editor?.showQuestionList;
      this._questions = (this.showRouting ? this.editor?.interview.getRoutingQuestion() : this.editor?.interview.getDisplayQuestions()) ?? null;
      if (this._questions == null) {
        return [];
      }

      return this._questions;
    }

    return [];
  }
  private _questions: QuestionModel[] | null = null;
}
