<div class="panels-addResp-search-div" style="width:100%">
  <div *ngIf="data?.length > 0 && filter.length > 0">
    <button mat-stroked-button [matMenuTriggerFor]="filterMenu">
      <mat-icon>arrow_drop_down</mat-icon>
      <span>Filters</span>
    </button>&nbsp;&nbsp;
    <mat-menu #filterMenu="matMenu">
      <button *ngFor="let fvalue of filter" (click)="onFilterMenu(fvalue)" mat-menu-item>
        <span>{{fvalue}}</span>
      </button>
    </mat-menu>
  </div>
  <div *ngIf="data?.length > 0" style="width:50%">
    <mat-form-field appearance="outline" style="width:98%">
      <mat-label>Search</mat-label>
      <input type="text" matInput placeholder="Search" [(ngModel)]="searchKey" autocomplete="off" style="width:100%" (keyup)="applyFilter($event)">
      <button class="search-close-btn" matSuffix mat-icon-button aria-label="Clear" *ngIf="searchKey" (click)="onSearchClear($event)">
        <mat-icon class="dialog-close-icon">close</mat-icon>
      </button>
    </mat-form-field>&nbsp;&nbsp;
  </div>
  <div *ngIf="addButton != ''">
    <button mat-raised-button class="panels-newRespondentTemplate-btn" (click)="add()">{{addButton}}</button>
  </div>&nbsp;&nbsp;
</div>

<div [class]="border ? 'outer-table-container mat-elevation-z1' : ''">
  <table mat-table #table [dataSource]="data" multiTemplateDataRows>
    <!-- Select Column -->
    <ng-container matColumnDef="select">
      <th mat-header-cell *matHeaderCellDef [attr.colspan]="2" style="width:100%">
        <div style="display:flex;flex-flow:row">
          <div style="width:50%;display:flex;flex-flow:row;align-items:center;align-items:center">
            <div>
              <mat-checkbox [indeterminate]="getTotal() > 0 && getTotal() < selections.length" (change)="checkTotal($event)"></mat-checkbox> {{getTotal() == 0 ? '' : getTotal() + " selected"}}
            </div>
          <div *ngIf="getTotal() > 0">
            <button mat-icon-button [matMenuTriggerFor]="actionMenu" aria-label="icon-button with a menu">
              <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu #actionMenu="matMenu">
              <button *ngFor="let action of actions" mat-menu-item (click)="doActionForMultiple(action)">
                <span>{{action}}</span>
              </button>
            </mat-menu>
          </div>
          </div>
          <div style="width:48%">

          </div>
          <div style="display:flex;align-self:center">
            <button mat-flat-button [matMenuTriggerFor]="sortMenu">
              <span>Sort</span>
              <mat-icon>arrow_drop_down</mat-icon>
            </button>&nbsp;&nbsp;
          </div>

        </div>
        <mat-menu #sortMenu="matMenu">
          <button mat-menu-item>
            <span>Latest</span>
          </button>
          <button mat-menu-item>
            <span>Recently updated</span>
          </button>
        </mat-menu>
      </th>
      <td mat-cell style="width:1%" *matCellDef="let element; let i = dataIndex"><mat-checkbox (click)="$event.stopPropagation()" (change)="checkRow(i);" [checked]="selections[i]"></mat-checkbox></td>
    </ng-container>

    <!-- Body Column -->
    <ng-container matColumnDef="body">
      <th mat-header-cell *matHeaderCellDef style="display:none"></th>
      <td mat-cell *matCellDef="let element">
        <ng-container *ngTemplateOutlet="template; context: { data: element }"></ng-container>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="columnsToDisplay; sticky: true"></tr>
    <tr mat-row *matRowDef="let element; columns: columnsToDisplay;"
        class="txt-analysis-element-row"
        (click)="edit(element)">
    </tr>
  </table>
  <mat-paginator #paginator [pageSize]="pageSize" [length]="total" (page)="pageEvents($event)" />
</div>
