import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-avatar',
  templateUrl: './avatar.component.html',
  styleUrls: ['./avatar.component.css']
})
export class AvatarComponent {

  initials: string = ""
  actualSize: number = 30;
  fontSize: string = "10px";
  padTop: string = "";
  padLeft: string = "";

  @Input()
  get name(): string | null {
    return this._name;
  }
  set name(value: string | null) {
    this._name = value;
    if (this._name == null || this._name == "") {
      this.initials = "?";
      return;
    }

    this.initials = this._name.substring(0, 1).toUpperCase();
  }
  private _name: string | null = null;

  @Input()
  get size(): number | null {
    return this._size;
  }
  set size(value: number | null) {
    this._size = value;
    this.actualSize = this._size == null ? 30 : this._size;
    this.fontSize = (this.actualSize / 3).toString() + "px";
  }
  private _size: number | null = null;

  @Input()
  get paddingTop(): string | null {
    return this._padding;
  }
  set paddingTop(value: string | null) {
    this._padding = value;
    this.padTop = this._padding != null ? this._padding : "";
  }
  private _padding: string | null;

  @Input()
  get paddingLeft(): string | null {
    return this._paddingLeft;
  }
  set paddingLeft(value: string | null) {
    this._paddingLeft = value;
    this.padLeft = this._paddingLeft != null ? this._paddingLeft : "";
  }
  private _paddingLeft: string | null;

  @Input()
  get media(): string | null {
    return this._media;
  }
  set media(value: string | null) {
    this._media = value;
  }
  private _media: string | null;
}
