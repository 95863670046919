<mat-card class="dashboard-card">
  <div class="fieldRow">
    <div>
      <button mat-mini-fab color="primary" aria-label="Refresh">
        <mat-icon>refresh</mat-icon>
      </button>
    </div>
    <div>
      <h3>Active Data IO</h3>
    </div>
  </div>
  <table *ngIf="tempSummary | async; else loading" class="custom-scroll-bar" mat-table #table [dataSource]="dataSource"
         multiTemplateDataRows
         matSort
         matSortActive="created"
         matSortDisableClear matSortDirection="desc">

    <!-- Name Column -->
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
      <td mat-cell *matCellDef="let process">
        <div class-fieldRow>
          <div style="width:200px;margin-top:5px">
            <mat-icon style="font-size:12px">{{isExport(process) ? 'download' : 'upload'}}</mat-icon>
            <mat-icon *ngIf="automate" style="font-size:12px">schedule</mat-icon>
          </div>
          <div style="width:200pxc">
            {{process.LastUpdated | date:'shortDate': '' : translate.currentLang}} {{process.LastUpdated | date:'mediumTime': '' : translate.currentLang}}<br />
            {{process.Name}}<br />
            ({{process.surveySummary?.Name}})
          </div>
        </div>
      </td>
    </ng-container>

    <!-- Name Column -->
    <ng-container matColumnDef="survey">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
      <td mat-cell *matCellDef="let process"> {{process.surveySummary?.Name}} </td>
    </ng-container>

    <!-- Created Column -->
    <ng-container matColumnDef="amended">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Last Updated </th>
      <td mat-cell *matCellDef="let process">  </td>
    </ng-container>

    <!-- Respondent count Column -->
    <ng-container matColumnDef="status">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Status </th>
      <td mat-cell *matCellDef="let process">
        <div>
          <strong>{{process.StatusDescription}}</strong>&nbsp;{{process.StatusDescription == 'In progress' ?'(' + process.Progress + '%)' : ''}}<br />
          {{process.errors?.length > 0 ? process.errors.length + (process.StatusDescription == "Idle" ? " Previous" : "") + " Errors" : ""}}
        </div>
      </td>
    </ng-container>

    <!-- Profile Element Column -->
    <ng-container matColumnDef="user">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> UID </th>
      <td mat-cell *matCellDef="let process"> <div matTooltip="{{process.userProfile?.UserName}}"> {{process.OwnerId}} </div></td>
    </ng-container>


    <!-- Action column -->
    <ng-container matColumnDef="action">
      <th mat-header-cell *matHeaderCellDef mat-sort-header></th>
      <td mat-cell *matCellDef="let process">
        <div>
          <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="icon-button with a menu" (click)="$event.stopPropagation()">
            <mat-icon>more_vert</mat-icon>
          </button>
          <mat-menu #menu="matMenu">
            <button mat-menu-item (click)="login(process)">
              <mat-icon>login</mat-icon>
              <span>Log into Account</span>
            </button>
            <mat-divider></mat-divider>
            <button mat-menu-item (click)="delete(process)">
              <mat-icon>delete</mat-icon>
              <span>Delete</span>
            </button>
          </mat-menu>
        </div>
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <tr mat-row class="users-element-row" *matRowDef="let row; columns: displayedColumns;" (click)="edit(row)"></tr>
  </table>
  <ng-template #loading>
    <div class="loading-container">
      <span class="loading-content">
        <div class="spinner-div"><mat-spinner [diameter]="25"></mat-spinner></div>
        <div class="spinner-text"> Loading active Data IO...</div>
      </span>
    </div>
    <br /><br />
  </ng-template>
</mat-card>
