<div class="user-details-dialog-container">
  <div class="leftFloat">
    <h3>Add Language Translation</h3>
  </div>
  <div class="rightFloat">
    <button mat-icon-button>
      <mat-icon>close</mat-icon>
    </button>
  </div>
</div>
<div class="outer-table-container mat-elevation-z8 clearBoth">
</div>
