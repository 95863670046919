import { Component, OnInit, Input, QueryList, ViewChildren, AfterViewInit } from '@angular/core';
import { AnswerModel } from "../../models/AnswerModel";
import { CategoryComponent } from '../category/category.component';
import { DataComponent } from '../data/data.component';

export class Cell {
  isLabel: boolean = false;
  isCategory: boolean = false;
  isQuestion: boolean = false;
  isBasicCategory: boolean = false;
  questionIndex: number = -1;
  categoryIndex: number = -1;
  label: string = "";
  question: any;
  category: any;
  colspan: number;
  groupName: string = "";

  constructor(questionIndex: number, categoryIndex: number, label?: string, question?: any, category?: any, colspan?: number, groupName?: string) {
    this.questionIndex = questionIndex;
    this.categoryIndex = categoryIndex;
    this.colspan = 1;
    if (colspan !== undefined) {
      this.colspan = colspan;
    }

    if (label !== undefined) {
      this.isLabel = true;
      this.label = label;
    }

    if (groupName !== undefined) {
      this.groupName = groupName;
    }

    if (question !== undefined) {
      this.question = question;
      this.isQuestion = true;
      this.isBasicCategory = this.question.Style.Control.Type === 6 || this.question.Style.Control.Type === 7;
    }

    if (category !== undefined) {
      this.category = category;
      this.isQuestion = false;
      this.isCategory = true;
    }
  }
}

@Component({
  selector: 'diy-loop-categorical',
  templateUrl: './loop-categorical.component.html',
  styleUrls: ['./loop-categorical.component.css']
})
export class LoopCategoricalComponent implements OnInit, AfterViewInit {

  byColumn: boolean = false;
  columns = 0;
  columnLabels: string[] = [];
  rows = 0;
  rowLabels: string[] = [];
  rowHeight = "4rem";
  cells: Cell[][] = [];
  validAnswers: boolean[] = [];
  mustAnswer: boolean = false;
  answersChecked: boolean = false;
  @ViewChildren('question') questions: QueryList<DataComponent> | undefined;
  @ViewChildren('category') categories: QueryList<CategoryComponent> | undefined;

  showTextboxMatrix: boolean = true;

  isHandset$: boolean = false;

  constructor() {
  }

  ngAfterViewInit(): void {
  }

  ngOnInit(): void {
  }

  @Input()
  get playerWidth(): number {
    return this._playerWidth;
  }
  set playerWidth(value: number) {
    this._playerWidth = value;
    this.isHandset$ = this.playerWidth != undefined && this.playerWidth < 400 && this.playerWidth != -1;
  }
  private _playerWidth: number = -1;

  @Input()
  get question(): any {
    return this._question;
  }
  set question(value: any) {
    this._question = value;
    if (this._question === undefined) {
      return;
    }

    this.mustAnswer = this._question.MustAnswer;
    this.buildMatrix();
  }
  private _question: any

  get mobileCells() {
    let temp = [];
    for (let i = 2; i < this.cells.length; i++) {
      temp.push(this.cells[i]);
    }

    return temp;
  }

  getValidAnswer(index: number): boolean {
    //if (this.categories == undefined) {
    //  if (!this.answersChecked) {
        this.checkValidAnswers();
    //    this.answersChecked = true;
    //  }
    //}

    return this.validAnswers[index];
  }

  checkValidAnswers() {
    let k = -1;
    let l = false;
    if (this.categories == undefined) {
      return;
    }

    if (this.categories.length > 0) {
      for (let i = 0; i < this.validAnswers.length; i++) {
        this.validAnswers[i] = false;
      }
    }

    this._valid = true;
    for (let i = 0; i < this.categories.length; i++) {
      let category = this.categories.toArray()[i];
      if (this.mustAnswer) {
        let v = false;
        for (let j = 0; j < category.answers.length; j++) {
          if (category.answers[j].value == 'true') {
            v = true;
            break;
          }
        }

        for (let j = 0; j < this.question.Item.Items.length; j++) {
          if (category.question.QuestionFullName.startsWith(this.question.Item.Items[j].QuestionFullName)) {
            if (j != k) {
              if (k > -1) {
                this.validAnswers[k] = l;
                l = v;
              }

              k = j;
            }
            else {
              if (v) {
                l = true;
              }
            }

            break;
          }
        }
      }
    }

    this.validAnswers[k] = l;
  }

  get answers(): AnswerModel[] {
    const model: AnswerModel[] = [];
    this._valid = true;
    this.checkValidAnswers();
    for (let i = 0; i < this.categories.length; i++) {
      let category = this.categories.toArray()[i];
      for (let j = 0; j < category.answers.length; j++) {
        let answer = category.answers[j];
        if (answer.value === "true") {
          let found = false;
          for (let i = 0; i < model.length; i++) {
            if (model[i].name === category.question.QuestionFullName) {
              const temp = model[i].value.split(",");
              temp.push(answer.name);
              model[i].value = temp.toString();
              found = true;
              break;
            }
          }
          if (!found) {
            model.push(new AnswerModel(category.question.QuestionFullName, answer.name));
          }
        }
      }
    }

    let temp = this.questions.toArray();
    for (let i = 0; i < temp.length; i++) {
      let question = temp[i];
      for (let j = 0; j < question.answers.length; j++) {
        let answer = question.answers[j];
        model.push(answer);
      }
    }

    return model;
  }

  get valid(): boolean {
    let temp = this.answers;
    return this._valid;
  }
  private _valid: boolean = false;

  buildMatrix() {
    try {
      this.cells = [];
      this.columnLabels = [];
      this.cells = new Array<Array<Cell>>();
      if (this._question.Style.Orientation === 2) {
        this.byColumn = true;
        this.buildByColumns();
      }
      else {
        this.buildByRows();
      }
    }
    catch (exception) {
      let a = 9;
    }
  }

  buildByColumns() {
    // Need to include the row and column headers
    this.columns = this._question.Item.Items.length + 1
    this.rows = this._question.Item.Items[0].Categories.length + 1;
    if (this.validAnswers.length == 0) {
      for (let i = 0; i < this.rows - 1; i++) {
        this.validAnswers.push(false);
      }
    }

    for (let i = 0; i < this._question.Categories.Items.length; i++) {
      this.columnLabels.push(this._question.Categories.Items[i].Label.Text);
    }

    // Add the body cells
    for (let j = 0; j < this._question.Item.Items[0].Item.Items.length; j++) {
      let q = this._question.Item.Items[0].Item.Items[j];
      let row = new Array<Cell>();
      if (q.Categories.Items.length === 0) {
        row.push(new Cell(j, -1, q.Label.Text));
        for (let k = 0; k < this._question.Item.Items.length; k++) {
          row.push(new Cell(j, k, undefined, this._question.Item.Items[k]));
        }
      }
      else {
        if (q.Style.Control.Type !== 0) {
          row.push(new Cell(j, 0, undefined, q));
        }
        else {
          for (let c = 0; c < q.Categories.Items.length; c++) {
            row.push(new Cell(c, -1, q.Categories.Items[0].Label.Text));
            for (let k = 0; k < this._question.Item.Items.length; k++) {
              this.showTextboxMatrix = false;
              row.push(new Cell(j, k, undefined, this._question.Item.Items[k], this._question.Item.Items[k].Categories.Items[c]));
            }
          }
        }
      }

      this.cells.push(row);
    }
  }

  findCategory(categories: any, name: string): any {
    for (let i = 0; i < categories.Items.length; i++) {
      if (categories.Items[i].Name === name) {
        return categories.Items[i];
      }
    }
    return null;
  }

  controlRequired(type: number): boolean {
    switch (type) {
      case 2:
      case 4:
      case 5:
      case 8:
      case 9:
      case 0x1001:
      case 0x1005:
      case 0x1006:
      case 0x100A:
      case 0x100B:
      case 0x100C:
        return true;
      default:
        return false;
    }
  }

  buildByRows() {
    // Need to include the row and column headers
    this.rows = this._question.Item.Items.length + 1;
    if (this.validAnswers.length == 0) {
      for (let i = 0; i < this.rows - 1; i++) {
        this.validAnswers.push(false);
      }
    }

    this.columns = 0;
    let row = new Array<Cell>();
    if (this._question.QuestionType === 1) {
      this.columns = 1;
      row.push(new Cell(-1, -1, ""));
    }
    for (let i = 0; i < this._question.Item.Items[0].Item.Items.length; i++) {
      let q = this._question.Item.Items[0].Item.Items[i];
      if (q.Categories.Items.length === 0) {
        row.push(new Cell(-1, -1, q.Label.Text));
        this.columns++;
      }
      else {
        if (this.controlRequired(q.Style.Control.Type)) {
          row.push(new Cell(-1, -1, ""));
          this.columns++;
        }
        else {
          row.push(new Cell(-1, -1, q.Label.Text, undefined, undefined, q.Categories.Items.length));
          this.columns += q.Categories.Items.length;
        }
      }

    }

    this.cells.push(row);
    row = new Array<Cell>();
    if (this._question.QuestionType === 1) {
      row.push(new Cell(-1, -1, ""));
    }
    for (let i = 0; i < this._question.Item.Items[0].Item.Items.length; i++) {
      let q = this._question.Item.Items[0].Item.Items[i];
      if (q.Categories.Items.length > 0 && !this.controlRequired(q.Style.Control.Type)) {
        for (let c = 0; c < q.Categories.Items.length; c++) {
          row.push(new Cell(i, c, q.Categories.Items[c].Label.Text));
        }
      }
      else {
        row.push(new Cell(-1, -1, ""));
      }
    }

    this.cells.push(row);

    for (let i = 0; i < this._question.Item.Items.length; i++) {
      row = new Array<Cell>();
      let q = this._question.Item.Items[i];
      if (q.Categories.Items.length > 0) {
        row.push(new Cell(-1, -1, this._question.Item.Items[0].Categories.Items[i].Label.Text));
      }

      for (let j = 0; j < q.Item.Items.length; j++) {
        if (q.Item.Items[j].Categories.Items.length === 0) {
          row.push(new Cell(i, j, undefined, q.Item.Items[j]));
        }
        else {
          if (this.controlRequired(q.Item.Items[j].Style.Control.Type)) {
            row.push(new Cell(i, j, undefined, q.Item.Items[j]));
          }
          else {
            for (let c = 0; c < q.Item.Items[j].Categories.Items.length; c++) {
              this.showTextboxMatrix = false;
              row.push(new Cell(i, c, undefined, q.Item.Items[j], q.Item.Items[j].Categories.Items[c], undefined, q.Item.Items[j].QuestionFullName + "._c" + j));
            }
          }
        }
      }

      this.cells.push(row);
    }
  }
}
