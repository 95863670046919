<table *ngIf="services != null && services.length > 0" mat-table [dataSource]="services">
  <ng-container matColumnDef="Name">
    <th mat-header-cell *matHeaderCellDef> Name </th>
    <td mat-cell *matCellDef="let zone"> {{zone.Name}} </td>
  </ng-container>

  <ng-container matColumnDef="IpAddress">
    <th mat-header-cell *matHeaderCellDef> IP Address </th>
    <td mat-cell *matCellDef="let zone"> {{zone.IpAddress}} </td>
  </ng-container>

  <ng-container matColumnDef="Port">
    <th mat-header-cell *matHeaderCellDef> Port </th>
    <td mat-cell *matCellDef="let zone"> {{zone.Port}} </td>
  </ng-container>

  <ng-container matColumnDef="Type">
    <th mat-header-cell *matHeaderCellDef> Type </th>
    <td mat-cell *matCellDef="let zone"> {{zoneType(zone.Type)}} </td>
  </ng-container>

  <ng-container matColumnDef="Status">
    <th mat-header-cell *matHeaderCellDef> Status </th>
    <td mat-cell *matCellDef="let zone"> {{zoneStatus(zone.Status)}} </td>
  </ng-container>
  <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
  <tr mat-row class="users-element-row" *matRowDef="let row; columns: displayedColumns;" (click)="edit(row)"></tr>
</table>
