import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormArray, Validators } from '@angular/forms';
import { BaseQuestion, DataModel, InterviewService, QuestionService } from '../../question.service';

@Component({
  selector: 'app-media',
  templateUrl: './media.component.html',
  styleUrls: ['./media.component.css']
})
export class MediaComponent extends BaseQuestion implements OnInit {
  mediaTypes = [
    { id: 'picture', value: 'Picture' },
    { id: 'audio', value: 'Audio' },
    { id: 'video', value: 'Video' }
  ];

  constructor(private qs: QuestionService, private is: InterviewService) {
    super(qs, is, undefined, undefined, new DataModel("media"));
  }

  public mediaFormGroup: FormGroup = new FormGroup({});

  ngOnInit() {
    this.mediaFormGroup = this.fb.group({
      mediaFormArray: this.fb.array([this.initQuestion()])
    });
  }

  onSubmit(): void {
  }

  get questionArr(): FormArray | null {
    if (this.mediaFormGroup) {
      return this.mediaFormGroup.get('mediaFormArray') as FormArray;
    }

    return null;
  }

  initQuestion() {
    return this.fb.group({
      qNumber: ['', Validators.required],
      mediaTypeSelect: ['', Validators.required]
    });
  }
}
