import { Component, OnInit } from '@angular/core';
import { BaseChart } from '../../question.service';

@Component({
  selector: 'app-vertical-chart',
  templateUrl: './vertical-chart.component.html',
  styleUrls: ['./vertical-chart.component.css']
})
export class VerticalChartComponent extends BaseChart implements OnInit {

  constructor() {
    super();
  }
  ngOnInit(): void {
  }

  onSelect(event: any) {
    console.log(event);
  }
}
