import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { JoyrideService } from 'ngx-joyride';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-feature-tour-dialog',
  templateUrl: './feature-tour-dialog.component.html',
  styleUrls: ['./feature-tour-dialog.component.css']
})
export class FeatureTourDialogComponent implements OnInit {

  @Output() tourEvent: EventEmitter<any> = new EventEmitter();

  constructor(private dialogRef: MatDialogRef<FeatureTourDialogComponent>,
    private readonly joyrideService: JoyrideService,
    private router: Router,
    private breakpointObserver: BreakpointObserver) { }

  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
    .pipe(map(result => result.matches));

  ngOnInit(): void {
  }

  startHomeTour() {
    this.dialogRef.close();
    //this.tourEvent.emit();
    if (!localStorage.getItem('homeTourDone') && this.router.url.includes('surveydashboard')) {
      localStorage.setItem('homeTourDone', "Feature Tour - Home");
      this.joyrideService.startTour(
        {
          steps: [
            'resourcesStep@surveydashboard',
            'profileStep@surveydashboard',
            'menuStep@surveydashboard',
            'surveyDashboardStep@surveydashboard',
            'surveyTemplateStep@surveydashboard',
            'surveyScratchStep@surveydashboard',
            'respondentsStep@surveydashboard'
          ],
          showCounter: false,
        } // Your steps order
      );
    }
    if (!localStorage.getItem('homeTourDone') && this.router.url.includes('startfromtemplate')) {
      localStorage.setItem('homeTourDone', "Feature Tour - Home");
      this.joyrideService.startTour(
        {
          steps: [
            'resourcesStep@startfromtemplate',
            'profileStep@startfromtemplate',
            'menuStep@startfromtemplate',
            'surveyDashboardStep@startfromtemplate',
            'surveyTemplateStep@startfromtemplate',
            'surveyScratchStep@startfromtemplate',
            'respondentsStep@startfromtemplate'
          ],
          showCounter: false,
        } // Your steps order
      );
    }
  }

  onDialogClose() {
    localStorage.setItem('homeTourDone', "Feature Tour - Home");
    this.dialogRef.close();
  }

  goToLink(url: string) {
    window.open(url, "_blank");
  }
}
