<div class="margin-15">
  <h2>Development Tools</h2>
  <form class="new-communication-form flex-stretch-column" [formGroup]="formGroup" (ngSubmit)="onSubmit()">
    <mat-accordion>
      <mat-expansion-panel hideToggle [expanded]="transferType == 'keys'">
        <mat-expansion-panel-header>
          <mat-panel-title>
            <div class="titlePanel-div">
              Keys
            </div>
          </mat-panel-title>
          <mat-panel-description>
          </mat-panel-description>
        </mat-expansion-panel-header>
        <div>
          <app-keys></app-keys>
        </div>
      </mat-expansion-panel>
      <mat-expansion-panel hideToggle [expanded]="transferType == 'webhooks'">
        <mat-expansion-panel-header>
          <mat-panel-title>
            <div class="titlePanel-div">
              Web Hooks
            </div>
          </mat-panel-title>
          <mat-panel-description>
          </mat-panel-description>
        </mat-expansion-panel-header>
        <div class="wrapFlex">
          <app-webhooks></app-webhooks>
        </div>
      </mat-expansion-panel>
      <mat-expansion-panel hideToggle [expanded]="transferType == 'sms'">
        <mat-expansion-panel-header>
          <mat-panel-title>
            <div class="titlePanel-div">
              Test SMS
            </div>
          </mat-panel-title>
          <mat-panel-description>
          </mat-panel-description>
        </mat-expansion-panel-header>
        <div>
          <app-send></app-send>
        </div>
      </mat-expansion-panel>
    </mat-accordion>
  </form>
</div>
<ng-template #loading>
  <div class="loading-container">
    <span class="loading-content">
      <div class="spinner-div"><mat-spinner [diameter]="25"></mat-spinner></div>
      <div class="spinner-text"> Please Wait...</div>
    </span>
  </div>
</ng-template>
<ng-template #loadingSurveys>
  <div class="loading-container">
    <span class="loading-content">
      <div class="spinner-div"><mat-spinner [diameter]="25"></mat-spinner></div>
      <div class="spinner-text"> Gathering stats...</div>
    </span>
  </div>
  <br /><br />
</ng-template>
