import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from "@angular/router";
import { AuthenticationService } from '../authentication.Service';
import { SurveyService } from '../survey.Service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent implements OnInit {

  formGroup: FormGroup;
  errorMsg: string = "";
  displayError: boolean;
  hide = true;
  public displayForm: boolean = true;

  constructor(private authService: AuthenticationService,
    private route: ActivatedRoute,
    private surveyService: SurveyService,
    private router: Router) {
    this.displayError = false;
    this.authService.checkToken(this.route.snapshot.queryParamMap.get("token") ?? "").subscribe(
      result => {

      },
      error => {
        this.errorMsg = "Unable to reset the password at this point in time. Please consider requesting a password reset email again.";
        this.displayError = true;
        this.displayForm = false;
      });
    this.formGroup = new FormGroup({
      newPassword: new FormControl("", [Validators.required]),
      confirmPassword: new FormControl("", [Validators.required])
    });
  }

  resetPassword() {
    if (this.formGroup.invalid) {
      return;
    }

    this.authService.resetPassword(this.route.snapshot.queryParamMap.get("token") ?? "", this.formGroup.value.newPassword)
      .subscribe(result => {
        const token = (result as any).Token;
        localStorage.setItem("jwt", token);
        this.displayError = false;
        this.surveyService.getSurveyCount()
          .subscribe(response => {
            if (response > 0) {
              this.router.navigate(['../surveydashboard']);
            } else {
              this.router.navigate(['../startfromtemplate']);
            }
          },
            error => {
              if (error.status === 401) {
                this.router.navigate(['../']);
              }
            });
      },
        error => {
          this.displayError = true;
          if (error.status === 409) {
            // cannot register
            this.errorMsg = "Unable to reset the password at this point in time";
          } else {
            this.errorMsg = "Unable to reset the password at this point in time";
            console.error(error.message);
          }
        });
  }

  ngOnInit(): void {
  }
}
