import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { InterviewModel, InterviewService } from '../../question.service';
import { JsonCyclic } from '../../utils';

@Component({
  selector: 'app-upload-languages',
  templateUrl: './upload-languages.component.html',
  styleUrls: ['./upload-languages.component.css']
})
export class UploadLanguagesComponent {

  interview: InterviewModel;
  model: string;

  constructor(private dialogRef: MatDialogRef<any>,
    private interviewService: InterviewService,
    @Inject(MAT_DIALOG_DATA) data: any) {
    this.interview = data.model;
    this.model = JsonCyclic.toJson(data.model);
  }


  onFileUploaded(event: any) {
    let filename = event.name;
    this.interviewService.setLanguages(filename, this.model).subscribe(result => {
      var a = result;
      this.dialogRef.close(result);
    },
      error => {
        var a = error;
      });
  }

  close() {
    this.dialogRef.close();
  }

}
