<div class="embed-data-container custom-scroll-bar" [formGroup]="dataFormGroup">
  <div formArrayName="embeddedOption">
    <div *ngFor="let opt of dataArr.controls; let i = index" [formGroupName]="i" class="embed-data-option-div">
      <mat-form-field appearance="outline">
        <mat-label> Name </mat-label>
        <input matInput formControlName="name" mask="PQ*" [patterns]="pattern" [dropSpecialCharacters]="false">
      </mat-form-field>
      &nbsp;
      <mat-form-field appearance="outline" class="embed-data-src-field">
        <mat-label> Source </mat-label>
        <mat-select formControlName="source">
          <mat-option value="url">Url or Panel</mat-option>
          <mat-option value="value">Set Value Now</mat-option>
          <mat-option value="meta">Survey Meta Data</mat-option>
          <mat-option value="question">Question</mat-option>
        </mat-select>
      </mat-form-field>
      &nbsp;
      <mat-form-field *ngIf="showDataType(i)" appearance="outline" class="embed-data-dataType-field">
        <mat-label> Data Type </mat-label>
        <mat-select formControlName="dataType">
          <mat-option value="textbox">Text</mat-option>
          <mat-option value="currency">Number</mat-option>
          <mat-option value="define">Multiple Choice</mat-option>
          <mat-option value="date">Date/Time</mat-option>
        </mat-select>
      </mat-form-field>
      <span *ngIf="showValue(i)">&nbsp;</span>
      <mat-form-field *ngIf="showValue(i)" appearance="outline" class="embed-data-val-field">
        <mat-label> Value </mat-label>
        <input matInput placeholder="Name" formControlName="dataValue">
      </mat-form-field>
      <mat-form-field *ngIf="showMetaData(i)" appearance="outline" class="embed-data-surMetaData-field">
        <mat-label> Survey Meta Data </mat-label>
        <mat-select formControlName="metaData">
          <mat-option value="startTime">Start Time</mat-option>
          <mat-option value="endTime">End Time</mat-option>
          <mat-option value="language">Language</mat-option>
          <mat-option value="url">URL</mat-option>
        </mat-select>
      </mat-form-field>
      <span *ngIf="showQuestions(i)">&nbsp;</span>
      <div *ngIf="showQuestions(i)">
        <mat-form-field appearance="outline" class="adv-routing-question-field">
          <mat-label> Question </mat-label>
          <div class="row-flex">
            <input matInput placeholder="Question" formControlName="sourceName">
            <input matInput formControlName="dataValue">
          </div>
          <button matSuffix mat-icon-button aria-label="Clear" class="adv-routing-dropDownArrow-btn" [matMenuTriggerFor]="questionMenu">
            <mat-icon>arrow_drop_down</mat-icon>
          </button>
        </mat-form-field>
        <mat-menu #questionMenu="matMenu">
          <button mat-menu-item *ngFor="let question of getDisplayQuestions();let j = index" [matMenuTriggerFor]="questionFormatMenu" [matMenuTriggerData]="{question: question, index: i}">
            <span>{{question.FullName}}</span>
          </button>
        </mat-menu>
        <mat-menu #questionFormatMenu="matMenu">
          <ng-template matMenuContent let-question="question" let-index="index">
            <button mat-menu-item *ngFor="let format of getFieldFormats(question)" (click)="questionSelected($event, question, index, format)">{{format}}</button>
          </ng-template>
        </mat-menu>
      </div>
      <mat-checkbox formControlName="analysis">
        Include in Analysis
      </mat-checkbox>
      <button class="padding-0" mat-icon-button matTooltip="Remove embedded data" matTooltipPosition="above" [hidden]="!isValid(i)" (click)="deleteEWmbeddedData(i)">
        <mat-icon>remove_circle_outline</mat-icon>
      </button>
      <button class="padding-0" mat-icon-button matTooltip="Add new embedded data" matTooltipPosition="above" [hidden]="!isValid(i)" (click)="addEmbeddedData(i)">
        <mat-icon>add_circle_outline</mat-icon>
      </button>

    </div>
  </div>
</div>
