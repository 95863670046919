import { Component, EventEmitter, Input, Output, TemplateRef, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { FileUploadComponent } from '../../file-upload/file-upload.component';
import { PanelModel, PanelService, ProfileVariable } from '../../panel.Service';
import { CustomFormat, FileTransferModel, Line, ProcessErrorModel, ProcessService, ProcessServiceModel } from '../../process.Service';
import { InterviewModel, InterviewService, QuestionModel, RuleGroup, RuleList, RuleModel } from '../../question.service';
import { Schedule } from '../../scheduler/scheduler.component';
import { DataAnalysisComponent } from '../data-analysis.component';
import { FtpSetupComponent } from '../ftp-setup/ftp-setup.component';
import { v4 as uuidv4 } from 'uuid';
import { delay } from 'rxjs';
import { baseUrl } from '../../../environments/environment';
import { JsonCyclic } from '../../utils';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { MessageService, MobileZoneInfo } from '../../message.Service';
import { MatSelect } from '@angular/material/select';
import { MatOption } from '@angular/material/core';
import { SurveyModel, SurveyService, SurveySummaryModel } from '../../survey.Service';
import { plainToClass } from 'class-transformer';
import { BaseFormComponent } from '../../templates/base.form.component';
import { AuthenticationService } from '../../authentication.Service';
import { MatCheckboxChange } from '@angular/material/checkbox';
import * as moment from 'moment-timezone';
import * as cronstrue from 'cronstrue';

@Component({
  selector: 'app-process-definition',
  templateUrl: './process-definition.component.html',
  styleUrls: ['./process-definition.component.css'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class ProcessDefinitionComponent extends BaseFormComponent {

  messages: ProcessErrorModel[] = [];
  errors: ProcessErrorModel[] = [];
  formGroup: FormGroup;
  summaryFormGroup: FormGroup;
  firstFormGroup: FormGroup;
  rulesFormGroup: FormGroup;
  secondFormGroup: FormGroup;
  thirdFormGroup: FormGroup;
  forthFormGroup: FormGroup;
  questions: QuestionModel[] = [];
  templateProfile = [];
  rules: RuleList[];
  schedule: Schedule = new Schedule("", new Date(), new Date(), "");
  automate: boolean = false;
  title: string;
  export: boolean = false;
  tempProcess: ProcessServiceModel = new ProcessServiceModel();
  isExport: boolean = true;
  startDate = new Date();
  endDate = new Date();
  isSaving: boolean = false;
  maxDate: Date;
  countries: MobileZoneInfo[] = [];
  variables: ProfileVariable[] = [];
  tz: number = 0;

  @ViewChild('ftpSetup') public ftpSetup: FtpSetupComponent | undefined;
  @ViewChild('ftpSetupeh') public ftpSetupeh: FtpSetupComponent | undefined;
  @ViewChild('s3Setup') public s3Setup: FtpSetupComponent | undefined;
  @ViewChild('s3Setupeh') public s3Setupeh: FtpSetupComponent | undefined;
  @ViewChild('fileUpload') fileUpload: FileUploadComponent | undefined;
  @ViewChild('picker') public picker: any | undefined;
  @ViewChild('tester') testerDialog: TemplateRef<any>;
  @ViewChild('statuses') statuses: MatSelect | undefined;

  dialogRef: MatDialogRef<any, any>;

  constructor(public translate: TranslateService,
    public dialog: MatDialog,
    private panelService: PanelService,
    private processService: ProcessService,
    private messageService: MessageService,
    private authenticationService: AuthenticationService,
    public snackbar: MatSnackBar
  ) {
    super(snackbar);
    this.messageService.getCountries().subscribe(result => {
      this.countries = result;
    });
  }

  errorMessageDate(message: string): Date | null {
    if (!message.startsWith("{")) {
      return null;
    }

    let temp = message.split("}");
    let dateString = temp[0].substring(1);
    return new Date(dateString);
  }

  errorMessageValue(message: string): string {
    if (!message.startsWith("{")) {
      return message;
    }

    let temp = message.split("}");
    return temp[1];
  }

  selectStatus(status: any) {
    this.statuses?.options.forEach((option: MatOption) => {
      if (option.value === status) {
        if (option.selected) {
          if (status === '0') {
            this.statuses?.options.forEach((data: MatOption) => {
              if (data.value !== "0") {
                data.deselect();
              }
            });
          }
          else {
            this.statuses?.options.first.deselect();
          }
        }
      }
    });
  }

  get startOffset(): Date {
    return moment(this.schedule.start).add(this.tz, 'm').toDate();
  }

  get endOffset(): Date {
    return moment(this.schedule.end).add(this.tz, 'm').toDate();
  }

  get cronBasicDescription(): string {
    if (this.schedule.cron === "0 0 1 1 *") {
      return "Once";
    }

    try {
      return cronstrue.toString(this.schedule.cron);
    }
    catch {
      return "";
    }
  }

  get customFormat(): CustomFormat {
    return this._customFormat;
  }
  set customFormat(value: CustomFormat) {
    this._customFormat = value;
    this.getVariables();
  }
  private _customFormat: CustomFormat = new CustomFormat();

  get isAdminUser(): boolean {
    return this.authenticationService.isAuthorized(['Admin']);
  }

  editDefinition(what: number) {
    switch (what) {
      case 1:
        this.setupForms();
        break;
      case 3:
        this.closed?.emit(new ProcessDefinitionEvent("copy", this.index, this.process));
        break;
      case 4:
        this.closed?.emit(new ProcessDefinitionEvent("delete", this.index, this.process));
        return;
      case 5:
        this.process.Task.Automated = this.process.StatusId < 3 || this.process.StatusId == 4 || this.process.Task.Automated;
        let blob = new Blob([JsonCyclic.toJson(this.process)], { type: "text/plain" });
        let url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        const fileName = this.process.Name;
        a.href = url;
        a.download = fileName + ".txt";
        a.click();
        window.URL.revokeObjectURL(url);
        return;
      case 6:
        this.closed?.emit(new ProcessDefinitionEvent("abandon", this.index, this.process));
        return;
      case 8:
        // Refresh
        super.openSnackbar("Refreshing...", "*");
        this.processService.getProcessById(this.process.ProcessId).subscribe(
          result => {
            this.process = result;
            super.dismiss();
          });
        return;

    }

    this.preview = what;
  }

  setupForms() {
    if (this.process.Task.CustomFormat == undefined) {
      this.process.Task.CustomFormat = new CustomFormat();
    }

    if (this.process.Task.FileTransfer == undefined) {
      this.process.Task.FileTransfer = new FileTransferModel();
    }

    this.customFormat = plainToClass(CustomFormat, this.process.Task.CustomFormat);
    this.customFormat.Header = this.process.Task.Headers == undefined ? false : this.process.Task.Headers;
    if (this.process.Task.Entity == null) {
      this.process.Task.Entity = this.isExport ? this.hasInvites ? "Invites" : "Completed" : "SurveyPanel";
    }

    let standard = "Standard";
    if (this.process.Task.UseCustomFormat == undefined) {
      if (this.customFormat.Lines.length > 0 && this.customFormat.Lines[0].Fields.length > 0 && this.customFormat.Lines[0].Fields[0].Name != "") {
        standard = "Custom";
      }
    }
    else {
      if (this.process.Task.UseCustomFormat) {
        standard = "Custom";
      }
    }

    this.summaryFormGroup = new FormGroup({
      Name: new FormControl(this.process.Name),
    });
    this.firstFormGroup = new FormGroup({
      TransferFormat: new FormControl(this.process.Task?.FileTransfer?.Type ?? ""),
      Archive: new FormControl(this.process.Task?.FileTransfer?.Archive ?? "")
    });
    this.firstFormGroup.valueChanges.subscribe(result => {
      this.process.Task.FileTransfer.Type = this.firstFormGroup.controls.TransferFormat.value;
    });
    this.formGroup = new FormGroup({
      Country: new FormControl(""),
      Number: new FormControl(this.process.Task.Number),
      Direction: new FormControl(this.process.Task.Direction?.toString()),
      Status: new FormControl(this.summarizeStatuses()),
      Content: new FormControl(this.process.Task.Content)
    });

    if (this.process.Task.Countries != undefined && this.process.Task.Countries?.length > 0) {
      for (let i = 0; i < this.countries.length; i++) {
        if (this.process.Task.Countries.includes(this.countries[i].Code)) {
          this.formGroup.controls.Country.setValue(this.countries[i]);
          break;
        }
      }
    }

    this.secondFormGroup = new FormGroup({
      DataType: new FormControl(this.process.Task.Entity),
      Format: new FormControl(this.process.Task.Format),
      Standard: new FormControl(standard),
      Options: new FormControl(this.process.Task.UseNumericValues ? "1" : "2"),
      AllData: new FormControl(this.process.Task.AllData ? "all" : this.process.Task.From ? "from" : "between"),
      Compress: new FormControl(this.process.Task.Compress),
      LineBreaks: new FormControl(this.process.Task.RemoveLineBreaks),
      Split: new FormControl(this.process.Task.SplitMultiValueFields),
      System: new FormControl(this.process.Task.SystemData),
      Timings: new FormControl(this.process.Task.Timings),
      Header: new FormControl(this.process.Task.Headers),
      Start: new FormControl(this.process.Task.Start),
      Finish: new FormControl(this.process.Task.Finish),
      Respondents: new FormControl(this.process.Task.IncludePanelData),
      ErrorHandling: new FormControl(this.process.Task.ErrorHandling),
      ReportErrors: new FormControl(this.process.Task.ReportErrors),
      TransferFormateh: new FormControl(this.process.Task.ErrorHandlingFileTransfer?.Type ?? ""),
      CountryPrefix: new FormControl(this.process.Task.CountryPrefix ?? "")
    });

    this.rulesFormGroup = new FormGroup({
      NumberLookup: new FormControl(this.process.Task.NumberLookup),
      Dedupe: new FormControl(false),
      Quotas: new FormControl(this.process.Task.Quotas),
      Custom: new FormControl(this.process.Task.Custom),
      CustomUrl: new FormControl(this.process.Task.CustomUrl),
      CustomCustom: new FormControl(this.process.Task.CustomCustom),
      MaskingLevel: new FormControl(this.process.Task.MaskingLevel),

    });
    this.thirdFormGroup = new FormGroup({});
  }

  type() {
    switch (this.process.Task.FileTransfer?.Type) {
      case "SFTP":
        return "SFTP Transfer";
      case "FTPS":
        return "FTPS Transfer";
      case "SPECIFIC":
        if (this.process.ProcessTypeId == 0) {
          return "File Upload";
        }
        else {
          return "File Download";
        }
      case "EMAIL":
        return "Email";
      case "S3":
        return "Amazon S3 Transfer";
      default:
        return "Undefined";
    }
  }

  buildStatuses(): number[] {
    let s: number[] = [];
    let data = this.formGroup.controls.Status.value as [];
    for (let j = 0; j < data.length; j++) {
      let ss = this.buildStatusArray(data[j]);
      for (let i = 0; i < ss.length; i++) {
        s.push(ss[i]);
      }
    }

    return s;
  }

  summarizeStatuses(): string[] {
    if (this.process.Task.Statuses == null) {
      return [];
    }

    if (this.process.Task.Statuses.length == 0) {
      return ['0'];
    }

    let s: number[][] = [];
    let result: string[] = [];
    for (let i = 0; i < 9; i++) {
      s.push(this.buildStatusArray(i.toString()));
    }

    for (let i = 1; i < 9; i++) {
      let match = true;
      for (let j = 0; j < s[i].length; j++) {
        if (!this.process.Task.Statuses.includes(s[i][j])) {
          match = false;
          break;
        }
      }

      if (match) {
        result.push(i.toString());
      }
    }

    return result;
  }

  buildStatusArray(data: string): number[] {
    let s: number[] = [];
    switch (data) {
      case '0':
        break;
      case '1':
        s.push(0); s.push(11);
        break;
      case '2':
        s.push(1);
        break;
      case '3':
        s.push(3); s.push(4); s.push(5); s.push(6); s.push(8); s.push(9); s.push(10); s.push(12); s.push(13); s.push(14); s.push(16);
        break;
      case '4':
        s.push(4); s.push(7);
        break;
      case '5':
        s.push(12); s.push(16);
        break;
      case '6':
        s.push(6);
        break;
      case '7':
        s.push(10);
        break;
      case '8':
        s.push(15);
    }

    return s;
  }

  @Output()
  closed: EventEmitter<ProcessDefinitionEvent> = new EventEmitter<ProcessDefinitionEvent>();

  @Input()
  get process(): ProcessServiceModel {
    return this._process;
  }
  set process(value: ProcessServiceModel) {
    if (value) {
      this._process = plainToClass(ProcessServiceModel, value);
    }

    if (this._process) {
      this.schedule.start = this.process.Start;
      this.schedule.end = this.process.Finish;
      this.schedule.cron = this.process.Cron;
      // this.schedule.offset = this.process.Adjustment;
      this.schedule.timezone = this.process.Timezone;
      this.isExport = this.process.Task.Type == "EXPORT";
      if (this.process.Task.Rules != null) {
        this.rules = [];
        this.rules.push(this.process.Task.Rules);
      }

      if (!this.isExport && this.process.ReferenceId > 0) {
        this.panelService.getPanelForSurvey(this.process.ReferenceId).subscribe(
          result => {
            var temp = result as PanelModel;
            if (temp != null) {
              this.quotaTotal = temp.PanellistCount;
            }
          });
      }

      if (this.process.Task.QuotaRules == null) {
        this.quotas = [];
        this.quotas.push(new RuleList());
        this.quotas[0].Rules = [];
        this.quotas[0].Rules.push(new RuleGroup());
        this.quotas[0].Rules[0].Rules = [];
        this.quotas[0].Rules[0].Rules.push(new RuleModel());
        this.quotas[0].Rules[0].Rules[0].RuleType = "profile";
      }
      else {
        if (this.process.Task.QuotaRules?.length > 0) {
          for (let i = 0; i < this.process.Task.QuotaRules.length; i++) {
            if (!(this.process.Task.QuotaRules[i] instanceof RuleList)) {
              this.process.Task.QuotaRules[i] = plainToClass(RuleList, this.process.Task.QuotaRules[i]);
            }

            this.processService.getQuota(this.process.ReferenceId, this.customFormat, this.process.Task.QuotaRules[i], i).subscribe(
              result => {
                this.process.Task.QuotaRules[result.Quota.QuotaId].Quota.Progress = result.Quota.Progress;
              });
          }
        }

        this.quotas = this.process.Task.QuotaRules;
      }

      this.startDate = this.process.Task.Start;
      this.endDate = this.process.Task.Finish;
      this.automate = (this.process.StatusId > 0 && (this.process.StatusId < 3 || this.process.StatusId == 4)) || this.process.Task.Automated;
      if (this.process.ProcessId == 0) {
        if (this.process.Task.CustomFormat == undefined) {
          this.process.Task.CustomFormat = new CustomFormat();
          this.process.Task.CustomFormat.Lines = [];
          this.process.Task.CustomFormat.Lines.push(new Line())
        }

        this.customFormat = this.process.Task.CustomFormat;
        this.customFormat.Header = this.process.Task.Headers == undefined ? false : this.process.Task.Headers;
        this.setupForms();
        this.preview = 1;
      }

      this.getEvents();
      this.getVariables();
    }
  }
  private _process: ProcessServiceModel = null;

  getVariables() {
    if (this.customFormat != null) {
      this.variables = this.customFormat.createVarialbeList();
      if (this.variables.length > 0) {
        return;
      }
    }

    let variables: ProfileVariable[] = [];
    variables.push(new ProfileVariable(variables.length + 1, "Title", 0));
    variables.push(new ProfileVariable(variables.length + 1, "FirstName", 0));
    variables.push(new ProfileVariable(variables.length + 1, "LastName", 0));
    variables.push(new ProfileVariable(variables.length + 1, "Email", 0));
    variables.push(new ProfileVariable(variables.length + 1, "MobileNumber", 0));
    variables.push(new ProfileVariable(variables.length + 1, "User1", 0));
    variables.push(new ProfileVariable(variables.length + 1, "User2", 0));
    variables.push(new ProfileVariable(variables.length + 1, "User3", 0));
    variables.push(new ProfileVariable(variables.length + 1, "User4", 0));
    variables.push(new ProfileVariable(variables.length + 1, "User5", 0));
    variables.push(new ProfileVariable(variables.length + 1, "User6", 0));
    variables.push(new ProfileVariable(variables.length + 1, "User7", 0));
    variables.push(new ProfileVariable(variables.length + 1, "User8", 0));
    variables.push(new ProfileVariable(variables.length + 1, "User9", 0));
    variables.push(new ProfileVariable(variables.length + 1, "User10", 0));
    this.variables = variables;
  }

  @Input()
  get index(): number {
    return this._index;
  }
  set index(value: number) {
    this._index = value;
  }
  private _index: number = 0;

  @Input()
  get survey(): SurveyModel {
    return this._survey;
  }
  set survey(value: SurveyModel) {
    this._survey = value;
    if (this._survey == null || this._survey?.SurveyID == 0) {
      return;
    }

    this.panelService.getTemplateProfile(this._survey.PanelID).subscribe(
      result => { this.templateProfile = result });
  }
  private _survey: SurveyModel = null;

  @Input()
  get interview(): InterviewModel {
    return this._interview;
  }
  set interview(value: InterviewModel) {
    this._interview = value;
    this.questions = this.interview?.dataQuestions();
  }
  private _interview: InterviewModel = null;
  
  @Input()
  get preview(): number {
    return this._preview;
  }
  set preview(value: number) {
    if (value == 1 && this.preview) {
      this.setupForms();
    }

    this._preview = value;
  }
  private _preview: number = 0;

  get rule(): RuleList {
    if (this.rules == null) {
      this.rules = [];
      this.rules.push(new RuleList());
    }

    if (this.interview?.Questions.length > 0) {
      this.rules[0].Question = this.interview.Questions[0];
    }

    return this.rules[0];
  }

  getEvents() {
    this.processService.getProcessMessages(this.process.ProcessId, 0).subscribe(
      result => {
        this.messages = result;

      });
    this.processService.getProcessErrors(this.process.ProcessId, 0).subscribe(
      result => {
        this.errors = result;
      });
  }

  transfer(transferFormat: string): boolean {
    return this.firstFormGroup != undefined && this.firstFormGroup.controls.TransferFormat.value == transferFormat;
  }

  transfereh(transferFormat: string): boolean {
    return this.secondFormGroup != undefined && this.secondFormGroup.controls.TransferFormateh.value == transferFormat;
  }

  dataSource(dataType: string): boolean {
    return this.secondFormGroup != undefined && this.secondFormGroup.controls.DataType.value == dataType;
  }

  format(name: string) {
    return this.secondFormGroup != undefined && this.secondFormGroup.controls.Format.value == name;
  }

  custom(): boolean {
    return this.secondFormGroup != undefined && this.secondFormGroup.controls.Standard.value == "Custom";
  }

  reportErrors(): boolean {
    return this.secondFormGroup != undefined && this.secondFormGroup.controls.ReportErrors.value;
  }

  hasAnomalies(): boolean {
    return this.survey.Channel == 1 && this.isExport;
  }

  hasStops(): boolean {
    if (!this.isExport) {
      return false;
    }

    switch (this.survey.Channel) {
      case 1:
      case 4:
        return true;
      default:
        return false;
    }
  }

  hasCompleted(): boolean {
    if (!this.isExport) {
      return false;
    }

    switch (this.survey.Channel) {
      case 0:
      case 1:
      case 2:
      case 5:
        return true;
      default:
        return false;
    }
  }

  isOneWay(): boolean {
    switch (this.survey.Channel) {
      case 3:
      case 4:
        return true;
      default:
        return false;
    }
  }

  isDedupe(): boolean {
    return this.rulesFormGroup?.controls.Dedupe.value;
  }

  isQuotas(): boolean {
    return this.process.Task.Quotas;
  }

  isCustom(): boolean {
    return this.rulesFormGroup?.controls.Custom.value;
  }


  hasInvites(): boolean {
    if (!this.isExport) {
      return false;
    }

    switch (this.survey.Channel) {
      case 0:
      case 1:
      case 3:
      case 4:
      case 5:
        return true;
      default:
        return false;
    }
  }

  updateQuota(event: MatCheckboxChange) {
    this.process.Task.Quotas = event.checked;
  }

  updateMaskingLevel(event: any) {
    this.process.Task.MaskingLevel = event.value;
    this.secondFormGroup.controls.MaskingLevel.setValue(event.value);
  }

  maskingLevel(level: number): boolean {
    return this.rulesFormGroup.controls.MaskingLevel.value == level;
  }

  updateTransferFormat(event: any) {
    this.process.Task.FileTransfer.Type = event.value;
    switch (event.value) {
      case "SFTP":
        this.process.Task.FileTransfer.Port = 22;
        if (this.ftpSetup != undefined) {
          this.ftpSetup.refresh();
        }

        break;
      case "FTPS":
        this.process.Task.FileTransfer.Port = 21;
        if (this.ftpSetup != undefined) {
          this.ftpSetup.refresh();
        }

        break;
      case "S3":
        this.process.Task.FileTransfer.Port = 1;
        if (this.s3Setup != undefined) {
          this.s3Setup.refresh();
        }

        break;
    }
  }

  updateTransferFormateh(event: any) {
    this.process.Task.ErrorHandlingFileTransfer.Type = event.value;
    switch (event.value) {
      case "SFTP":
        this.process.Task.ErrorHandlingFileTransfer.Port = 22;
        if (this.ftpSetupeh != undefined) {
          this.ftpSetupeh.refresh();
        }

        break;
      case "FTPS":
        this.process.Task.ErrorHandlingFileTransfer.Port = 21;
        if (this.ftpSetupeh != undefined) {
          this.ftpSetupeh.refresh();
        }

        break;
      case "S3":
        this.process.Task.ErrorHandlingFileTransfer.Port = 1;
        if (this.s3Setupeh != undefined) {
          this.s3Setupeh.refresh();
        }

        break;
    }
  }

  updateFormat(event: any) {
    this.process.Task.Format = event.value;
    if (this.process.Task.Format == "FIXED") {
      this.secondFormGroup.controls.Standard.setValue("Custom");
    }

    for (let i = 0; i < this.process.Task.CustomFormat?.Lines?.length; i++) {
      let line = this.process.Task.CustomFormat.Lines[i];
      for (let j = 0; line?.Fields?.length; j++) {
        switch (this.process.Task.Format) {
          case "CSV":
            line.Fields[j].EndsWith = ",";
            break;
          case "TSV":
            line.Fields[j].EndsWith = "\t";
            break;
          case "PIPE":
            line.Fields[j].EndsWith = "|";
            break;
          default:
            line.Fields[j].EndsWith = "";
            break;
        }
      }
    }
  }

  get quotas(): RuleList[] {
    return this._quotas;
  }
  set quotas(value: RuleList[]) {
    this._quotas = value;
  }
  private _quotas: RuleList[] = []

  get quotaTotal(): number {
    return this._quotaTotal;
  }
  set quotaTotal(value: number) {
    this._quotaTotal = value;
  }
  private _quotaTotal: number = -1;

  updateErrorHandling(event: any) {
    this.process.Task.ErrorHandling = event.value;
  }

  errorType(value: string) {
    return this.process.Task.ErrorHandling == value;
  }

  updateStandard(event: any) {
    if (this.process.Task.Format == undefined || this.process.Task.Format == "") {
      this.process.Task.Format = "CSV";
      this.secondFormGroup.controls.Format.setValue("CSV");
    }

    this.getVariables();
  }

  updateHeader(event: any) {
    this.customFormat.Header = event.checked;
  }

  updateDateRange(event: any) {
    this.process.Task.AllData = event.value == "all";
    this.process.Task.From = event.value == "from";
  }

  scheduleChanged(value: Schedule) {
    this.schedule = value;
  }

  updateProcess() {
    // Basic details
    this.process.Cron = this.schedule.cron;
    this.process.Start = this.schedule.start;
    this.process.Finish = this.schedule.end;
    this.process.Adjustment = this.schedule.offset;
    this.process.Timezone = this.schedule.timezone;
    this.process.IsDeleted = false;
    this.process.Name = this.summaryFormGroup.controls.Name.value;
    this.process.StatusId = 5;
    this.process.ProcessTypeId = this.isExport ? 2 : 0;

    // file Transfer Information
    this.process.Task.Type = this.isExport ? "EXPORT" : "IMPORT";
    this.process.Task.FileTransfer.Type = this.firstFormGroup.controls.TransferFormat.value;
    this.process.Task.FileTransfer.Archive = this.firstFormGroup.controls.Archive.value;
    this.process.Task.Entity = this.secondFormGroup.controls.DataType.value;
    this.process.Task.Rules = this.rules == null ? null : this.rules.length > 0 ? this.rules[0] : null;
    this.process.Task.SplitMultiValueFields = this.secondFormGroup.controls.Split.value;
    this.process.Task.UseNumericValues = this.secondFormGroup.controls.Options.value == "1";
    this.process.Task.RemoveLineBreaks = this.secondFormGroup.controls.LineBreaks.value;
    this.process.Task.Timings = this.secondFormGroup.controls.Timings.value;
    this.process.Task.SystemData = this.secondFormGroup.controls.System.value;
    this.process.Task.Format = this.secondFormGroup.controls.Format.value;
    this.process.Task.Compress = this.secondFormGroup.controls.Compress.value;
    this.process.Task.Headers = this.secondFormGroup.controls.Header.value;
    this.process.Task.IncludePanelData = this.secondFormGroup.controls.Respondents.value;
    this.process.Task.UseCustomFormat = this.secondFormGroup.controls.Standard.value == "Custom";
    this.process.Task.CountryPrefix = this.secondFormGroup.controls.CountryPrefix.value;
    this.process.Task.CustomFormat = this.customFormat;
    this.process.Task.LastSuccess = true;
    this.process.Task.LastRun = new Date(2001, 1, 1);
    if (!this.process.Task.AllData) {
      this.process.Task.Start = this.startDate;
      this.process.Task.Finish = this.endDate;
    }

    this.process.Task.Countries = this.formGroup.controls.Country.value == '' ? [] : this.formGroup.controls.Country.value?.Codes;
    this.process.Task.Number = this.formGroup.controls.Number.value;
    this.process.Task.Direction = this.formGroup.controls.Direction.value;
    this.process.Task.Statuses = this.buildStatuses();
    this.process.Task.Content = this.formGroup.controls.Content.value;

    this.process.Task.ErrorHandling = this.secondFormGroup.controls.ErrorHandling.value;
    if (this.process.Task.ErrorHandlingFileTransfer == null) {
      this.process.Task.ErrorHandlingFileTransfer = new FileTransferModel();
    }

    this.process.Task.ErrorHandlingFileTransfer.Type = this.secondFormGroup.controls.TransferFormateh.value;
    this.process.Task.ReportErrors = this.secondFormGroup.controls.ReportErrors.value;

    this.process.Task.NumberLookup = this.rulesFormGroup.controls.NumberLookup.value;
    this.process.Task.Custom = this.rulesFormGroup.controls.Custom.value;
    this.process.Task.CustomUrl = this.rulesFormGroup.controls.CustomUrl.value;
    this.process.Task.CustomCustom = this.rulesFormGroup.controls.CustomCustom.value;
    this.process.Task.MaskingLevel = this.rulesFormGroup.controls.MaskingLevel.value;

    this.process.Task.Quotas = this.rulesFormGroup.controls.Quotas.value;
    if (this.process.Task.Quotas) {
      this.process.Task.QuotaRules = this.quotas;
    }
    else {
      this.process.Task.QuotaRules = null;
    }
  }

  saveProcess() {
    if (this.isSaving) {
      return;
    }

    this.isSaving = true;
    this.updateProcess();
    this.processService.saveProcess(this.process).subscribe(
      result => {
        this.process = result;
        super.openSnackbar("Successfully Saved", "");
        this.isSaving = false;
        this.preview = 0;
        this.closed?.emit(new ProcessDefinitionEvent("saved", this.index, this.process));
      },
      error => {
        super.openSnackbar("There was a problem saving the " + (this.isExport ? "export" : "import") + " specification", "Cancel");
        this.isSaving = false;
        this.preview = 0;
        this.closed?.emit(new ProcessDefinitionEvent("saved", this.index, this.process));
      });
  }

  launchProcess() {
    while (this.isSaving) {
      delay(1000);
    }

    this.updateProcess();
    this.process.StatusId = 0;
    this.processService.saveProcess(this.process).subscribe(
      result => {
        this.process = result;
        super.openSnackbar("Successfully Launched", "");
        this.preview = 0;
        this.closed?.emit(new ProcessDefinitionEvent("launched", this.index, this.process));
      },
      error => {
        super.openSnackbar("There was a problem launching the " + this.isExport ? "export" : "import", "Cancel");
        this.preview = 0;
        this.closed?.emit(new ProcessDefinitionEvent("launched", this.index, this.process));
      });
  }

  quotasChanged(quotas: RuleList[]) {
    this._quotas = quotas;
  }

  importData() {
    this.updateProcess();
    this.processService.saveProcess(this.process).subscribe(
      result => {
        this.process = result;
        let saveId = this.process.ProcessId;
        if (baseUrl.startsWith("https://localhost")) {
          this.process.ProcessId = 0;
          if (this.fileUpload) {
            this.process.Task.FileTransfer.File = this.fileUpload.name;
          }
          else {
            this.process.Task.FileTransfer.File = uuidv4() + ".tmp";
          }
          this.process.Task.FileTransfer.LocalPath = this.process.Task.FileTransfer.LocalFolder + "\\" + this.process.Task.FileTransfer.File;
          this.process.LastUpdated = new Date();
          this.processService.saveProcess(this.process).subscribe(result => {
            this.process.ProcessId = saveId;
            this.tempProcess = result;
            this.export = false;
            this.dialogRef = this.dialog.open(this.testerDialog);
            this.processService.runProcess(this.tempProcess).subscribe(result => {
              this.tempProcess = result;
            });
          });
        }
        else {
          // Create a copy to use as a test
          if (this.fileUpload) {
            this.process.Task.FileTransfer.File = this.fileUpload.name;
          }
          else {
            this.process.Task.FileTransfer.File = uuidv4() + ".tmp";
          }
          this.process.Task.FileTransfer.LocalPath = this.process.Task.FileTransfer.LocalFolder + "\\" + this.process.Task.FileTransfer.File;
          this.process.ProcessId = 0;
          this.process.StatusId = 0;
          this.process.Cron = "";
          this.process.LastUpdated = new Date();
          this.process.Finish = new Date();
          this.process.Finish.setDate(this.process.Finish.getDate() + 3);
          this.processService.importData(this.process).subscribe(result => {
            this.process.ProcessId = saveId;
            this.export = false;
            this.dialogRef = this.dialog.open(this.testerDialog);
            this.tempProcess = result;
          });
        }
      },
      error => {
        super.openSnackbar("There was a problem saving the " + (this.export ? "export" : "import") + " specification", "Cancel");
      });
  }

  exportData() {
    this.updateProcess();
    this.processService.saveProcess(this.process).subscribe(
      result => {
        this.process = result;
        let saveId = this.process.ProcessId;
        if (baseUrl.startsWith("https://localhost")) {
          this.process.ProcessId = 0;
          this.process.StatusId = 0;
          this.process.Cron = "";
          this.process.Finish = new Date();
          this.process.Task.LastRun = new Date("2020-01-01");
          this.process.LastUpdated = this.process.Task.LastRun;
          this.process.Task.AllData = true;
          this.process.Finish.setDate(this.process.Finish.getDate() + 3);
          this.processService.saveProcess(this.process).subscribe(result => {
            this.process.ProcessId = saveId;
            this.tempProcess = result;
            this.export = true;
            this.dialogRef = this.dialog.open(this.testerDialog);
            this.processService.runProcess(this.tempProcess).subscribe(result => {
              this.tempProcess = result;
            });
          });
        }
        else {
          // Create a copy to use as a test
          this.process.ProcessId = 0;
          this.process.StatusId = 0;
          this.process.Cron = "";
          this.process.Finish = new Date();
          this.process.Task.LastRun = new Date("2020-01-01");
          this.process.LastUpdated = this.process.Task.LastRun;
          this.process.Task.AllData = true;
          this.process.Finish.setDate(this.process.Finish.getDate() + 3);
          this.processService.exportData(this.process.Name, this.survey.SurveyID, this.process.Task).subscribe(result => {
            this.process.ProcessId = saveId;
            this.export = true;
            this.dialogRef = this.dialog.open(this.testerDialog);
            this.tempProcess = result;
          });
        }
      },
      error => {
        super.openSnackbar("There was a problem saving the " + (this.export ? "export" : "import") + " specification", "Cancel");
      });
  }

  onExportDataCompleted($event: any) {
    let process = $event.process as ProcessServiceModel;
    if (process.StatusId == 4) {
      return;
    }

    if (this.isExport && this.process.Task.FileTransfer.Type != "SPECIFIC") {
      return;
    }

    this.export = false;
    this.dialogRef?.close();
    if (this.isExport) {
      this.processService.getFile(process).subscribe(
        response => {
          this.downLoadFile(response, process.Name, ProcessService.FindDownloadFromTask(process.Task));
        });
    }
    else {
      this.processService.getFile(process).subscribe(
        response => {
          super.openSnackbar("Successfully Imported", "");
        });
    }
  }

  downLoadFile(data: any, filename: string, types: string[]) {
    let blob = new Blob([data], { type: types[0] });
    let url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = filename + types[1];
    a.click();
    window.URL.revokeObjectURL(url);
  }

  onFileUploaded($event: any) {
    this.process.Task.FileTransfer.File = $event.name;
    if (this.fileUpload != undefined) {
      this.fileUpload.progress = 0;
      this.fileUpload.message = "Your data file uploaded to be imported";
    }
  }

  cancel() {
    this.closed?.emit(new ProcessDefinitionEvent("closed", this.index, this.process));
    this.preview = 0;
  }

  testCustomUrl() {

  }
}

export class ProcessDefinitionEvent {
  public action: string;
  public index: number;
  public process: ProcessServiceModel;

  constructor(action: string, index: number, process: ProcessServiceModel) {
    this.action = action;
    this.index = index;
    this.process = process;
  }
}
