import { Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { WhatsAppComponent } from '../../comms.Service';

@Component({
  selector: 'app-whatapp-part',
  templateUrl: './whatapp-part.component.html',
  styleUrls: ['./whatapp-part.component.css']
})
export class WhatappPartComponent implements OnDestroy {

  formGroup: FormGroup = new FormGroup({});
  subscription: Subscription;
  updating: boolean = false;

  constructor() {

  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }

  @Output()
  updated: EventEmitter<WhatsAppComponent> = new EventEmitter<WhatsAppComponent>();


  @Input()
  get messagePart(): WhatsAppComponent {
    return this._messagePart;
  }
  set messagePart(value: WhatsAppComponent) {
    if (value != null) {
      this._messagePart = value;
    }

    if (this._messagePart.type == "") {
      this._messagePart.type = "BODY";
    }

    this.buildForm();
  }
  private _messagePart: WhatsAppComponent = new WhatsAppComponent();

  @Input()
  get readonly(): boolean {
    return this._readonly;
  }
  set readonly(value: boolean) {
    this._readonly = value;
  }
  private _readonly = false;

  private buildForm() {
    if (this.updating) {
      return;
    }

    let examples = this._messagePart.example.body_text.join("\r\n");
    this.formGroup = new FormGroup({
      Text: new FormControl(this._messagePart.text),
      Examples: new FormControl(examples)
    });
    this.subscription = this.formGroup.valueChanges.subscribe(result => {
      this.updating = true;
      this._messagePart.text = this.formGroup.controls.Text.value;
      let examples = this.formGroup.controls.Examples.value.split("\r\n");
      this._messagePart.example.body_text = [];
      for (let i = 0; i < examples.length; i++) {
        this._messagePart.example.body_text.push(examples[i].split(","));
      }

      this.updated?.emit(this._messagePart);
      this.updating = false;
    });
  }

}
