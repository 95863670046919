import { Component } from '@angular/core';

@Component({
  selector: 'app-org-summary',
  templateUrl: './org-summary.component.html',
  styleUrls: ['./org-summary.component.css']
})
export class OrgSummaryComponent {

}
