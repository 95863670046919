<form class="new-communication-form fullWidth column-flex" [formGroup]="formGroup">
  <div>
    <strong>Invite New Members</strong>
  </div>
  <div>
    <p>Create a list of people you want to invite by entering their usernames or email addresses:</p>
    <div class="fieldRowWithPadding">
      <div>
        <mat-form-field appearance="outline" class="longField">
          <mat-label>Find User</mat-label>
          <input matInput placeholder="Enter a username or email address" [matAutocomplete]="auto" formControlName='Name'>
        </mat-form-field>
        <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
          <mat-option *ngFor="let user of (users | async)" [value]="user">
            <span>{{ user.UserName }}&nbsp;({{user.EmailAddress}})</span>
          </mat-option>
        </mat-autocomplete>
      </div>
      <div>
        <button mat-icon-button (click)="addName()">
          <mat-icon>add</mat-icon>
        </button>
      </div>
    </div>
  </div>
  <div class="fieldRowWithPadding" *ngFor="let item of list; let i = index">
    <div class="longField invite-ppl-un-div">
      {{item.UserName}}&nbsp;
    </div>
    <div>
      <button mat-icon-button (click)="removeName(i)">
        <mat-icon>remove_circle</mat-icon>
      </button>
    </div>
  </div>
</form>
