<div class="rightFloat">
  <button mat-icon-button mat-dialog-close>
    <mat-icon>close</mat-icon>
  </button>
</div>
<form class="fullWidth flex-stretch-column padding-0" [formGroup]="formGroup">
  <div class="panel-selector-main-div">
    <div class="fullWidth leftFloat">
      <div class="panel-selector-countrySelector-div">
        <mat-form-field *ngIf="tempCountries | async; else loading" appearance="outline" class="leftFloat">
          <mat-label>Select a country</mat-label>
          <mat-select formControlName="country" (selectionChange)="onCountryChange($event)" matNativeControl>
            <mat-option value=""></mat-option>
            <mat-option *ngFor="let country of countries" value="{{country.Id}}">{{country.Name}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div>
        <app-category-list #categoryList [country]="selectedCountry" [model]="quota" (select)="selectQuestion($event)" (loaded)="loaded($event)"></app-category-list>
        <div class="panel-selector-catSelector-container custom-scroll-bar">
          <div *ngIf="useRanges === false" class="fullWidth">
            <category-selector (change)="onCategoryChange($event)" formControlName="catList"></category-selector>
          </div>
          <div *ngIf="useRanges" class="fullWidth clearBoth block-display">
            <div class="display-inline-flex fullWidth" formArrayName="rangeList">
              <div *ngFor="let item of rangeList().controls; let i = index" class="leftFloat margin-5" [formGroupName]="i">
                <rangebox formControlName="range" (change)="onChangeRange($event, i)" (close)="onCloseRange($event,i)"></rangebox>
              </div>
            </div>
            <div class="clearBoth fullWidth display-flex">
              <div class="leftFloat margin-5">
                <button mat-flat-button color="primary" (click)="addRange($event)">Add Range</button>
              </div>
              <div class="leftFloat margin-5">
                <button mat-flat-button color="primary" (click)="deleteAllRanges($event)">Delete All</button>
              </div>
            </div>
          </div>
          <div *ngIf="selectedCategories.length > 0" class="fullWidth clearBoth block-display">
            <div class="panel-selector-separator">
              <b>QUOTAS</b>
            </div>
            <div class="alert alert-success" role="alert">
              Quotas set the number of respondents with the chosen demographics that will complete your survey. Without quotas, it'll be completed by the first available respondents.
            </div>
            <div *ngIf="total != 100" class="alert alert-danger" role="alert">
              Your audience quotas must add up to 100%
            </div>
            <div class="txt-align-center">
              <mat-slide-toggle formControlName="quotaSelected" (change)="onQuotaChange($event)">{{selectedQuestion?.Name ?? ""}}</mat-slide-toggle>
            </div>
            <div formArrayName="quotaList">
              <div *ngFor="let item of quotaList().controls; let i = index" [formGroupName]="i" class="panel-selector-quota-content">
                <div *ngIf="useRanges === false" class="halfWidth txt-align-right">
                  {{selectedCategories[i].name}}
                </div>&nbsp;&nbsp;&nbsp;&nbsp;
                <div *ngIf="useRanges" class="halfWidth txt-align-right">
                  {{selectedCategories[i].range.minRange}} - {{selectedCategories[i].range.maxRange}}
                </div>&nbsp;&nbsp;&nbsp;&nbsp;
                <div class="halfWidth txt-align-left">
                  <mat-form-field appearance="outline" class="width-100">
                    <mat-label>Percentage</mat-label>
                    <input matInput formControlName="percentage" mask="0*" (change)="distributionChange()">
                    <span matSuffix>%</span>
                  </mat-form-field>
                </div>
              </div>
            </div>
            <div class="panel-selector-quota-content">
              <div class="halfWidth txt-align-right">
                <button mat-flat-button color="primary" [disabled]="!quotaOn" (click)="distributeEvenlyAction()">Distribute Evenly</button>
              </div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <div class="halfWidth txt-align-left pl-20">
                <span> Total {{total}}% </span>
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="selections.size > 0" class="panel-selector-audience-container">
          <div class="panel-selector-audience-heading-div">
            <span>Audience</span>
          </div>
          <div class="panel-selector-audience-div custom-scroll-bar">
            <app-audience #audience [model]="quota" [categories]="cintCategories"></app-audience>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="panel-selector-bottom-fields-div">
    <div>
      <mat-form-field appearance="outline" class="width-120">
        <mat-label>Sample Needed</mat-label>
        <input matInput placeholder="Sample Needed" formControlName="needed">
      </mat-form-field>
    </div>
    <div>
      <mat-form-field appearance="outline" class="width-120">
        <mat-label>Available</mat-label>
        <input matInput readonly formControlName="available">
      </mat-form-field>
    </div>
    <div *ngIf="isAdmin">
      <mat-form-field appearance="outline" class="width-120">
        <mat-label>Length of Interview</mat-label>
        <input matInput placeholder="Sample Needed" formControlName="loi">
      </mat-form-field>
    </div>
    <div *ngIf="isAdmin">
      <mat-form-field appearance="outline" class="width-120">
        <mat-label>Incident Rate</mat-label>
        <input matInput placeholder="Incident Rate" formControlName="ir">
      </mat-form-field>
    </div>
    <div>
      <mat-form-field appearance="outline" class="width-120">
        <mat-label>Cost</mat-label>
        <input matInput placeholder="Cost" readonly formControlName="cost">
      </mat-form-field>
    </div>
    <div>
      <button mat-raised-button color="primary" (click)="onConfirmedAction()">Save</button>
    </div>
  </div>
</form>
<ng-template #loading>
  <div class="loading-container">
    <span class="loading-content">
      <div class="spinner-div"><mat-spinner [diameter]="25"></mat-spinner></div>
      <div class="spinner-text"> Loading Countries...</div>
    </span>
  </div>
</ng-template>

