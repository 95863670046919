<div *ngIf="display">
  <div [formGroup]="formGroup" class="fullWidth mt-5">
    <mat-form-field *ngIf="multiline" appearance="outline" class="fullWidth">
      <mat-label>{{placeholderText}}</mat-label>
      <textarea matInput [placeholder]="placeholderText" formControlName="multilinetext"
                  cdkTextareaAutosize
                  #autosize="cdkTextareaAutosize"
                  cdkAutosizeMinRows="1"
                  cdkAutosizeMaxRows="20"></textarea>
    </mat-form-field>
    <mat-form-field *ngIf="singleline" appearance="outline">
      <mat-label>{{placeholderText}}</mat-label>
      <input matInput placeholder="{{placeholderText}}" [mask]="mask" formControlName="text">
    </mat-form-field>
    <mat-form-field *ngIf="password" appearance="outline" class="fullWidth">
      <mat-label>Password</mat-label>
      <input matInput [type]="hide ? 'password' : 'text'" placeholder="Password" formControlName="password">
      <mat-icon class="reveal" matSuffix (click)="hide = !hide">{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
    </mat-form-field>
    <mat-form-field *ngIf="email" appearance="outline">
      <mat-label>{{placeholderText}}</mat-label>
      <input matInput type="email" placeholder="{{placeholderText}}" formControlName="email">
      <mat-error *ngIf="formGroup.controls.email.invalid">{{emailError()}}</mat-error>
    </mat-form-field>
  </div>
</div>
