import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { RuleList, RuleModel, RuleGroup, QuestionModel, CategoriesModel } from '../../question.service';
import { RoutingDialogComponent } from '../routing-dialog/routing-dialog.component';

@Component({
  selector: 'app-feature',
  templateUrl: './feature.component.html',
  styleUrls: ['./feature.component.css']
})
export class FeatureComponent implements OnInit {

  constructor(public dialog: MatDialog) { }

  stateView: boolean[] = [];
  tempArray: boolean[] = [];

  ngOnInit(): void {
    this.tempArray.push(false);
    this.tempArray.push(false);
    this.tempArray.push(false);
    this.tempArray.push(false);
    this.stateView.push(false);
    this.stateView.push(false);
    this.stateView.push(false);
    this.stateView.push(false);
  }

  onEdit(i: number) {
    const rules = this.rules(i);
    const name = this.name(i);
    const dialogRef = this.dialog.open(RoutingDialogComponent,
    {
      data: { name: name, rules: rules },
      width: '90%'
    });
  }

  onDelete(i: number) {
    if (this._question == null) {
      return;
    }

    switch(i) {
      case 0:
        this._question.DisplayLogic = null;
        break;
      case 1:
        this._question.CarryForward = null;
        break;
      case 2:
        this._question.AnswerChoiceLogic = null;
        break;
      case 3:
        this._question.SkipLogic = null;
        break;
    }

    this.stateView[i] = false;
    this.tempArray[i] = false;
  }

  icon(index: number) {
    switch (index) {
      case 0:
        return "account_tree";
      case 1:
        return "low_priority";
      case 2:
        return "read_more";
      case 3:
        return "filter_list";
    }

    return "";
  }

  rules(index: number): RuleList | null {
    if (this._question == null) {
      return null;
    }

    switch (index) {
      case 0:
        return this._question?.DisplayLogic;
      case 1:
        return this._question?.CarryForward;
      case 2:
        return this._question?.AnswerChoiceLogic;
      case 3:
        return this._question?.SkipLogic;
    }

    return null;
  }

  display(index: number) {
    if (this._question == null) {
      return false;
    }

    let flag = false;
    switch (index) {
      case 0:
        flag = this.hasRules(this._question.DisplayLogic);
        break;
      case 1:
        flag = this.hasRules(this._question.CarryForward);
        break;
      case 2:
        flag = this.hasRules(this._question.AnswerChoiceLogic);
        break;
      case 3:
        flag = this.hasRules(this._question.SkipLogic);
        break;
    }

    this.tempArray[index] = flag;
    return flag;
  }

  hasRules(rules: RuleList | null): boolean {
    if (rules == null) {
      return false;
    }

    if (rules.Question === null) {
      return false;
    }

    if (rules.Question?.QuestionId === null) {
      return false;
    }

    if (rules.Rules.length === 0) {
      return false;
    }

    if (rules.Rules[0].Rules.length === 0) {
      return false;
    }

    return true;
  }

  name(index: number): string {
    switch (index) {
      case 0:
        return "Display ";
      case 1:
        return "Carry Forward ";
      case 2:
        return "Answer Choice ";
      case 3:
        return "Skip ";
    }

    return "";
  }

  @Input()
  get question(): QuestionModel | null {
    return this._question;
  }
  set question(value: QuestionModel | null) {
    this._question = value;

    if (this._question === undefined) {
      return;
    }

  }
  private _question: QuestionModel | null = null;

}
