import { Component, OnInit } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { version } from "../../environments/environment";

@Component({
  selector: 'app-diy-landing',
  templateUrl: './diy-landing.component.html',
  styleUrls: ['./diy-landing.component.css']
})
export class DiyLandingComponent implements OnInit {

  currentVersion: string;
  currentYear = new Date().getFullYear();

  breakpoint: number = 0;
  constructor(private breakpointObserver: BreakpointObserver,
    public dialog: MatDialog,
    private router: Router
  ) {
    this.currentVersion = version;
  }

  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
    .pipe(map(result => result.matches));

  ngOnInit(): void {
  }

  openTerms() {
    const url = this.router.createUrlTree(['/termsofservice'])
    window.open(url.toString(), '_blank')
  }

  openPrivacy() {
    const url = this.router.createUrlTree(['/privacystatement'])
    window.open(url.toString(), '_blank')
  }

  goToLink(url: string) {
    window.open(url, "_blank");
  }
}

