import { Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Message, MessageService, MessageStatsSearchModel, MessageSummaryModel, MobileCountryInfo, ReceiptModel, TranLogResults, TranLogStatsModel, TranLogStatsResults } from '../../../message.Service';
import { catchError, map, Observable, startWith, switchMap, of as observableOf } from "rxjs";
import { AuthenticationService, Metadata } from '../../../authentication.Service';
import { MatSelect } from '@angular/material/select';
import { MatOption } from '@angular/material/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MetadataTableComponent } from '../../../Development/metadata-entrytable/metadata-table.component';
import { SnackbarDefaultComponent } from '../../../snackbar/snackbar-default.component';
import { MatSnackBar, MatSnackBarRef } from '@angular/material/snack-bar';
import { UserFeatures, UserProfile, UserService } from '../../../user.Service';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { JsonCyclic } from '../../../utils';
import * as WebDataRocks from '@webdatarocks/webdatarocks';
import { PivotTableComponent } from '../../pivot-table/pivot-table.component';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { MatPaginator } from '@angular/material/paginator';
import * as moment from 'moment-timezone';

@Component({
  selector: 'app-traffic-analysis',
  templateUrl: './traffic-analysis.component.html',
  styleUrls: ['./traffic-analysis.component.css'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ])
  ]
})
export class TrafficAnalysisComponent {
  displayedColumns: string[] = ['TranDate', 'Survey', 'Country', 'Source', 'Destination', 'Inbound', 'Message', 'Type', 'Segments', 'Status'];
  @ViewChild(MatPaginator) paginator: MatPaginator | undefined;
  @ViewChild('messageList') messageList: TemplateRef<any>;
  system: boolean = true;
  dialogRef: MatDialogRef<any, any>;

  timezone: string = moment.tz.guess();
  sb: MatSnackBarRef<any> | undefined;
  mess?: Observable<any>;
  messages: Message[] = [];
  total: number = 0;
  subTotal: number = 0;
  formGroup: FormGroup;
  range: FormGroup;
  metadata: Metadata[] = [];
  startDate: Date = null;
  endDate: Date = null;
  isSearching: boolean = false;
  isExporting: boolean = false;
  isLoadingResults: boolean = false;
  countries: MobileCountryInfo[] = [];
  index: number;
  data: any;
  page: number = 0;
  pageSize: number = 20;
  isRateLimitReached: boolean = false;
  ismapped: boolean = false;
  userFeatures: UserFeatures = new UserFeatures();
  users: UserProfile[] = [];
  stats: TranLogStatsModel[] = null;
  jsonData = null;
  /*[
    {
      "DateAdded": { type: "datetime" },
      "GatewayName": { type: "string" },
      "OwnerId": { type: "number" },
      "Account": { type: "string" },
      "ReceiptStauts": { type: "number" },
      "ReceiptStatusDescription": { type: "string" },
      "Segments": { type: "number" },
      "Messages": { type: "number" }
    }];*/
  dataReady: boolean = false;
  columnNames = [];
  pivot = {
    "dataSource": {
      "dataSourceType": "json",
      "data": this.jsonData
    },
    "slice": {
      "rows": [
        { "uniqueName": "GatewayName", "sort": "asc" },
        { "uniqueName": "Account", "sort": "asc" },
        { "uniqueName": "Country", "sort": "asc" },
        { "uniqueName": "Date", "sort": "asc" }
      ],
      "columns": [
        { "uniqueName": "ReceiptStatusDescription", "sort": "asc" }
      ],
      "measures": [
        { "uniqueName": "Segments", "aggregatation": "sum" }
      ],
      "expands": {
        "expandAll": false,
      },
      "drills": {
        "drillAll": false
      }
    },
    "options": {
      "grid": {
        "type": "compact",
        "title": "",
        "showFilter": true,
        "showHeaders": true,
        "showTotals": true,
        "showGrandTotals": "on",
        "showHierarchies": true,
        "showHierarchyCaptions": true,
        "showReportFiltersArea": true
      },
      "configuratorActive": false,
      "configuratorButton": true,
      "showAggregations": true,
      "showCalculatedValuesButton": true,
      "drillThrough": false,
      "showDrillThroughConfigurator": false,
      "sorting": "on",
      "datePattern": "dd/MM/yyyy",
      "dateTimePattern": "dd/MM/yyyy HH:mm:ss",
      "saveAllFormats": false,
      "showDefaultSlice": true,
      "defaultHierarchySortName": "asc"
    },
    "formats": [
      {
        "name": "",
        "thousandsSeparator": ",",
        "decimalSeparator": ".",
        "decimalPlaces": 0,
        "maxSymbols": 20,
        "currencySymbol": "",
        "currencySymbolAlign": "left",
        "nullValue": " ",
        "infinityValue": "Infinity",
        "divideByZeroValue": "Infinity"
      }
    ]
  }

  aggregators = ['Gateway', 'Country', 'Account', 'Date'];

  results: any = null;

  @ViewChild('messageDetail') messageDetailDialog: TemplateRef<any>;
  @ViewChild('metadataTable') metadataTable: MetadataTableComponent | undefined;
  @ViewChild('statuses') statuses: MatSelect | undefined;
  @ViewChild('pivotTable') pivotTable: PivotTableComponent | undefined;

  constructor(private dialog: MatDialog,
    private messageService: MessageService,
    private authenticationService: AuthenticationService,
    private userService: UserService,
    private snackbar: MatSnackBar
  ) {
    if (!this.isAdminUser) {
      this.aggregators = ['Account', 'Country', 'Date'];
    }

    this.formGroup = new FormGroup({
      Number: new FormControl(),
      Direction: new FormControl(),
      MessageType: new FormControl(),
      Status: new FormControl(),
      Content: new FormControl(),
      Gateway: new FormControl(this.isAdminUser),
      Country: new FormControl(true),
      Account: new FormControl(true),
      Date: new FormControl(true)
    });

    this.startDate = new Date();
    this.startDate.setHours(0, 0, 0, 0);
    this.endDate = new Date();
    this.endDate.setTime(new Date().getTime() + (24 * 60 * 60 * 1000));
    this.endDate.setHours(0, 0, 0, 0);
    this.formGroup.controls.Status.patchValue(["0"]);
    this.formGroup.controls.MessageType.setValue("Segments");
    this.messageService.getCountries().subscribe(result => {
      this.countries = result;
    });
    this.userService.getUserFeatures().subscribe(result => {
      this.userFeatures = result;
    });
  }

  ngOnInit(): void {
  }

  isAdmin(): boolean {
    return false;
  }

  getReceipt(receipt: ReceiptModel): string {
    if (this.isAdminUser || this.isImpersonating()) {
      return receipt?.GatewayReceiptID
    }

    let value = receipt?.GatewayReceiptID.split(":") ?? [];
    if (value.length > 1) {
      return value[1];
    }

    return "";
  }

  cellClickEmpty() {

  }

  cellClick(event) {
    // event.stopPropagation();
    let a = event;
    let rows: any[] = event.rows;
    let columns: any[] = event.columns;
    let searchResults = this.getCellData(rows);
    searchResults.push(this.getStatus(columns[0].caption));
    this.getMessagesDetails(searchResults);
    this.dialogRef = this.dialog.open(this.messageList, { height: '80vh', width: '98%' });
  }

  checkToolbar(toolbar) {
    const tabs = toolbar.getTabs();
    const traffic = this;
    toolbar.getTabs = function () {
      delete tabs[0];
      delete tabs[1];
      delete tabs[2];
      tabs.unshift({
        id: "wdr-tab-refresh",
        title: "Refresh",
        handler: () => {
          return new Promise(() => {
            setTimeout(() => {
              traffic.getMessages();
            })
          })
        },
        icon: this.icons.connect
      });
      return tabs;
    }
  }

  getType(message: Message) {
    switch (message.MessageType) {
      case 0:
        return "SMS";
      case 1:
        return "WHATSAPP";
      case 2:
        return "FACEBOOK";
      case 3:
        return "WHATSAPP TEMPLATE";
    }

    return "UNKONWN";
  }

  searchForMessages() {
    if (this.formGroup.invalid) {
      return;
    }

    this.isSearching = true;
    this.messages = [];
    this.page = 0;
    this.getMessages();
  }

  getMessages() {
    this.sb = this.snackbar.openFromComponent(SnackbarDefaultComponent);
    let model = new MessageStatsSearchModel();
    model.From = this.startDate ?? new Date("2024-01-01");
    model.To = this.endDate ?? new Date("2040-01-01");
    model.GatewayName = '';
    model.UserIds = [];
    for (let i = 0; i < this.users.length; i++) {
      model.UserIds.push(this.users[i].UserID);
    }

    model.Statuses = [];
    this.messageService.getTranlogStats(model).subscribe(
      results => {
        this.stats = results;
        let temp = new TranLogStatsResults(this.stats);
        for (let i = 0; i < temp.columns.length; i++) {
          this.columnNames.push({ "uniqueName": temp.columns[i], "sort": "asc" });
        }

        //this.jsonData = JsonCyclic.toJson(this.stats);
        this.pivot.dataSource.data = this.isAdminUser ? this.stats : temp.results;
        this.pivot.slice.measures[0].uniqueName = this.formGroup.controls.MessageType.value;
        let aggregators = this.calculateAggregators();
        this.pivot.slice.rows = aggregators.rows;
        this.dataReady = true;
        this.results = this.pivot;
        if (this.pivotTable != null && this.pivotTable != undefined) {
          this.pivotTable.report = this.results;
        }

        this.sb?.dismiss();
      }
    );
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.aggregators, event.previousIndex, event.currentIndex);
    this.setAggregators();
  }

  setPivotMeasure() {
    this.pivot.slice.measures[0].uniqueName = this.formGroup.controls.MessageType.value;
    this.results = this.pivot;
    if (this.pivotTable != null && this.pivotTable != undefined) {
      this.pivotTable.report = this.results;
    }
  }

  calculateAggregators() {
    let a = {
      "rows": [
      ]
    };

    for (let i = 0; i < this.aggregators.length; i++) {
      switch (this.aggregators[i]) {
        case "Gateway":
          if (this.formGroup.controls.Gateway.value) {
            a.rows.push({ "uniqueName": "GatewayName", "sort": "asc" });
          }

          break;
        case "Account":
          if (this.formGroup.controls.Account.value) {
            a.rows.push({ "uniqueName": "Account", "sort": "asc" });
          }

          break;
        case "Country":
          if (this.formGroup.controls.Country.value) {
            a.rows.push({ "uniqueName": "Country", "sort": "asc" });
          }

          break;
        case "Date":
          if (this.formGroup.controls.Date.value) {
            a.rows.push({ "uniqueName": "Date", "sort": "asc" });
          }

          break;
      }
    }

    return a;
  }

  setAggregators() {
    let aggregators = this.calculateAggregators();
    this.pivot.slice.rows = aggregators.rows;
    if (this.pivotTable != null && this.pivotTable != undefined) {
      this.pivotTable.report = this.results;
    }
  }

  get isAdminUser(): boolean {
    return this.authenticationService.isAuthorized(['Admin']);
  }

  isImpersonating() {
    return this.authenticationService.impersonate();
  }

  getCellData(rows: any[]): any[] {
    let results: any[] = [];
    for (let i = 0; i < 4; i++) {
      results.push(null);
    }

    for (let i = 0; i < rows.length; i++) {
      switch (rows[i].hierarchyUniqueName) {
        case "GatewayName":
          results[0] = rows[i].caption;
          break;
        case "Account":
          results[1] = this.getUserId(rows[i].caption);
          break;
        case "Country":
          results[2] = rows[i].caption;
          break;
        case "Date":
          results[i] = this.getDateRange(rows[i].caption);
          break;
      }
    }

    return results;
  }

  getDateRange(name: string): Date[] {
    let from = new Date(new Date(name.substring(1, 11) + " " + name.substring(12, 23)).getTime() - new Date().getTimezoneOffset() * 60000);
    let to = new Date(new Date(name.substring(1, 11) + " " + name.substring(12, 23)).getTime() - new Date().getTimezoneOffset() * 60000);
    from.setMinutes(from.getMinutes() - 30);
    let range = [];
    range.push(from);
    range.push(to);
    return range;
  }

  getStatus(name: string): number {
    switch (name) {
        /// <summary>
        /// DELIVRD 000 Delivered to handset
        /// </summary>
      case "Delivered": return 0;

      /// <summary>
      /// ACKED 003 Supplier have submitted your message to a deliverer
      /// </summary>
      case "Submitted to Carrier": return 1;

      /// <summary>
      /// BUFFRED 004 Deliverer could not deliver, but has spooled for retry
      /// </summary>
      case "Buffered For Retry": return 2;

      /// <summary>
      /// FAILED 005 Deliverer could not deliver, and will not retry
      /// </summary>
      case "Failed": return 3;

      /// <summary>
      /// UNKNOWN 999
      /// </summary>
      case "Unknown": return 4;

      /// <summary>
      /// 008 System Error
      /// </summary>
      case "System Error": return 5;

      /// <summary>
      /// 401 Number blacklisted in system
      /// </summary>
      case "Blacklisted": return 6;

      /// <summary>
      /// Destination busy - The message was not sent due to the fact
      /// </summary>
      case "Destination Busy": return 7;

      /// <summary>
      /// 411 Number un-routable. Do not retry.
      /// </summary>
      case "Number UnRoutable": return 8;

      /// <summary>
      /// 422 Destination unavailable, please try again.
      /// </summary>
      case "Destination Unavailable": return 9;

      /// <summary>
      /// 423 Number is blocked. Do not retry.
      /// </summary>
      case "Blocked": return 10;

      /// <summary>
      /// Success - not necessarily delivered - the send worked.
      /// </summary>
      case "Success": return 11;

      /// <summary>
      /// Failed to send because the destination number was invalid.
      /// </summary>
      case "Invalid Destination Number": return 12;

      /// <summary>
      /// Inbound message so no receipt.
      /// </summary>
      case "Inbound": return 13;

      /// <summary>
      /// The message is on on stop.
      /// </summary>
      case "OnStop": return 14;

      /// <summary>
      /// The message has been read
      /// </summary>
      case "Read": return 15;

      /// <summary>
      /// Failed to send becuase the source number was invalid
      /// </summary>
      case "Invalid Source": return 16;

      /// <summary>
      /// Undelivered.
      /// The message has encountered a delivery error and is deemed permanently undeliverable. 
      /// No further delivery attempts will be made. 
      /// Certain network or MC internal errors result in the permanent non-delivery of a message. 
      /// Examples of such errors would be an unknown subscriber or network error that indicated that the given destination mobile was denied 
      /// SMS service or could not support SMS.
      /// </summary>
      case "Undelivered": return 17;

      /// <summary>
      /// Content Related Error
      /// The content is a problem.
      /// </summary>
      case "Content Related Error": return 18;

      /// <summary>
      /// No Longer Active
      /// The number is no longer active and should be removed from your database
      /// </summary>
      case "No Longer Active": return 19;

      /// <summary>
      /// Network Error
      /// The message failed due to a network error. Retry.
      /// </summary>
      case "Network Error": return 20;

      /// <summary>
      /// Age Restriction
      /// The target cannot receive your message due to their age.
      /// </summary>
      case "Age Restriction": return 21;

      /// <summary>
      /// Insufficient Funds
      /// The recipient is on a prepaid plan and does not have enough credit to receive your message.
      /// </summary>
      case "Insufficient Funds": return 22;

      /// <summary>
      /// Regulation
      /// Unexpected regulation error. Submit a support request.
      /// </summary>
      case "Regulation": return 23;

      /// <summary>
      /// Network connection throttled
      /// </summary>
      case "Throttled": return 24;

      /// <summary>
      /// The destination equipement had a permenant problem
      /// </summary>
      case "Equipement Permenant Error": return 25;

      /// <summary>
      /// The destination equipement had a temporary error
      /// </summary>
      case "Equipement Temporary Error": return 26;

      /// <summary>
      /// The destination rejected the message.
      /// </summary>
      case "Rejected": return 27;

      /// <summary>
      /// Invalid Receipt Request
      /// </summary>
      case "Invalid Receipt Request": return 28;

      /// <summary>
      /// Unreachable
      /// </summary>
      case "Unreachable": return 29;
    }

    return -1;
  }

  getTotal(results: any[]) {
    this.messageService.GetTotal(results[1] == null ? (this.isAdminUser ? -1 : 0) : results[1], this.page, this.pageSize,
      this.system ? -1 : 0, results[3] != null ? results[3][0] : this.startDate ?? new Date("2001-01-01"),
      results[3] != null ? results[3][1] : this.endDate ?? new Date("2040-01-01"), [], "", [], 0, [ results[4] ], "", 0, results[0] ?? "").subscribe(
        result => {
          this.total = result;
        }
      );
  }

  getUserId(column: string): number {
    let temp = column.split("(");
    if (temp.length < 2) {
      return -1;
    }

    temp = temp[1].split(")");
    return Number(temp[0]);
  }

  getMessagesDetails(results: any[]) {
    this.messages = [];
    this.getTotal(results);
    this.page = 1;

    if (this.paginator != undefined) {
      this.paginator.pageIndex = this.page - 1;
    }

    this.sb = this.snackbar.openFromComponent(SnackbarDefaultComponent);
    this.messageService.get(results[1] == null ? (this.isAdminUser ? -1 : 0) : results[1], this.page, this.pageSize,
      this.system ? -1 : 0, results[3] != null ? results[3][0] : this.startDate ?? new Date("2001-01-01"),
      results[3] != null ? results[3][1] : this.endDate ?? new Date("2040-01-01"), [], "", [], 0, [results[4]], "", 0, results[0] ?? "").subscribe(
        result => {
          if (result.length == 0) {
            this.paginator?.pageIndex == 0 ? 0 : this.page;
          }
          else {
            this.messages = result;
          }

          this.sb?.dismiss();
        });
  }

  processSearch(results: any[]): void {
    this.paginator?.page
      .pipe(
        startWith({}),
        switchMap(() => {
          this.isLoadingResults = true;
          this.sb = this.snackbar.openFromComponent(SnackbarDefaultComponent);
          this.page = (this.paginator?.pageIndex ?? 0) + 1;
          return this.messageService.get(results[1] == null ? (this.isAdminUser ? -1 : 0) : results[1], this.page, this.pageSize,
            this.system ? -1 : 0, results[3] != null ? results[3][0] : this.startDate ?? new Date("2001-01-01"),
            results[3] != null ? results[3][1] : this.endDate ?? new Date("2040-01-01"), [], "", [], 0, [results[4]], "", 0, results[0] ?? "")
          .pipe(catchError(() => observableOf(null)));
        }),
        map(data => {
          // Flip flag to show that loading has finished.
          this.isLoadingResults = false;
          this.isRateLimitReached = data === null;
          this.sb?.dismiss();

          if (data === null) {
            return [];
          }

          // Only refresh the result length if there is new data. In case of rate
          // limit errors, we do not want to reset the paginator to zero, as that
          // would prevent users from re-triggering requests.
          return data;
        }),
      )
      .subscribe(data =>
        (this.messages = data)
      );
  }


  exportMessages() {
    // let s = this.buildStatuses();
    let date = new Date();
    this.isExporting = true;
    /*this.messageService.export(this.users.length > 0 ? this.users[0].UserID : 0, this._survey?.SurveyID ?? (this.system ? -1 : 0), this.startDate ?? new Date("2001-01-01"), this.endDate ?? new Date("2040-01-01"),
      this.formGroup.value.Country?.Codes, this.formGroup.value.Number, this.metadataTable?.metadata ?? [],
      Number(this.formGroup.controls.Direction.value), s, this.formGroup.value.Content, Number(this.formGroup.value.MessageType))
      .subscribe(
        response => {
          this.downLoadFile(response, "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", ".xlsx")
        },
        error => {
          this.isExporting = false;
        });*/
    return false;
  }

  downLoadFile(data: any, type: string, ext: string) {
    let blob = new Blob([data], { type: type });
    let url = window.URL.createObjectURL(blob);
    //Download by dynamically creating a tag
    const a = document.createElement('a');
    const fileName = "messages";
    a.href = url;
    // a.download = fileName;
    a.download = fileName + ext;
    a.click();
    window.URL.revokeObjectURL(url);
    this.isExporting = false;
  }

  selectStatus(status: any) {
    this.statuses?.options.forEach((option: MatOption) => {
      if (option.value === status) {
        if (option.selected) {
          if (status === '0') {
            this.statuses?.options.forEach((data: MatOption) => {
              if (data.value !== "0") {
                data.deselect();
              }
            });
          }
          else {
            this.statuses?.options.first.deselect();
          }
        }
      }
    });
  }

  showMessageDetails(index: number, row: any) {
    this.index = index;
    this.data = row;
    let dialogRef = this.dialog.open(this.messageDetailDialog,
      {
        width: '80%'
      });
  }

  previousMessage() {
    this.index--;
    this.data = this.messages[this.index];
  }

  nextMessage() {
    this.index++;
    this.data = this.messages[this.index];
  }
}
