<div>
  
  <form class="dlcbrandform" [formGroup]="formGroup">
    <p>
      <!--<strong>Brand Registration</strong><br />-->
      <mat-label style="font-size: 15px"><b>Step 1 : Brand Registration</b> </mat-label>
    </p>

    <p>
      Organization Name
      <br />
      <mat-form-field appearance="outline" class="msg-hist-filtByCountry-field">
        <mat-label>Organization Name</mat-label>
        <mat-select formControlName="Organization">
          <mat-option value="">All Organization</mat-option>
          <mat-option *ngFor="let org of orgList" [value]="org">{{org.name}}</mat-option>
        </mat-select>
      </mat-form-field>
      <br />
      Organization Type
      <br />
      <mat-form-field appearance="outline" class="msg-hist-filtByCountry-field">
        <mat-label>Organization Type</mat-label>
        <mat-select formControlName="category">
          <mat-option value="Government">Government</mat-option>
          <mat-option value="Non-Profit">Non-Profit</mat-option>
          <mat-option value="Private Profit">Private Profit</mat-option>
          <mat-option value="Public profit">Public profit</mat-option>
          <mat-option value="Sole Proprietor">Sole Proprietor</mat-option>
        </mat-select>
      </mat-form-field>
      <br />
      Legal Company name<button mat-mini-fab color="primary" aria-label="Refresh"
                                matTooltip="'The legally registered company name'" style="text-align:left">
        <mat-icon>info</mat-icon>
      </button>
      <br />
      <mat-form-field appearance="outline" class="msg-hist-filtByNum-field" style="width:288px">
        <mat-label>Legal Company name</mat-label>
        <input matInput placeholder="Legal Company name" formControlName="companyname">
      </mat-form-field>
      <br />
      DBA or Brand Name
      <button mat-mini-fab color="primary" aria-label="Refresh"
              matTooltip="'Doing Business as' or trade name of the company'">
        <mat-icon>info</mat-icon>
      </button>
      <br />
      <mat-form-field appearance="outline" class="msg-hist-filtByNum-field" style="width:288px">
        <mat-label>DBA or Brand Name</mat-label>
        <input matInput placeholder="DBA or Brand Name" formControlName="brandname">
      </mat-form-field>
      <br />
      Tax Number
      <br />
      <mat-form-field appearance="outline" class="msg-hist-filtByNum-field" style="width:288px">
        <mat-label>Tax Number</mat-label>
        <input matInput placeholder="Tax Number" formControlName="taxNumber">
      </mat-form-field>
      <br />
      Website
      <br />
      <mat-form-field appearance="outline" class="msg-hist-filtByNum-field" style="width:288px">
        <mat-label>Website</mat-label>
        <input matInput placeholder="Website" formControlName="website">
      </mat-form-field>
      <br />
      Vertical Type
      <br />
      <!--<mat-form-field appearance="outline" class="msg-hist-filtByNum-field" style="width:288px">
    <mat-label>Vertical Type</mat-label>
    <input matInput placeholder="Vertical Type" formControlName="verticaltype">
  </mat-form-field>-->
      <mat-form-field appearance="outline" class="msg-hist-filtByCountry-field">
  <mat-label>Vertical Type</mat-label>
  <mat-select formControlName="verticaltype">
    <mat-option value="Agriculture business and services">Agriculture business and services</mat-option>
    <mat-option value="Communication and mass media">Communication and mass media</mat-option>
    <mat-option value="Construction and materials">Construction and materials</mat-option>
    <mat-option value="Educational institutions providing education to students in educational settings">Educational institutions providing education to students in educational settings</mat-option>
    <mat-option value="Energy and utilities">Energy and utilities</mat-option>
    <mat-option value="Entertainment including gaming and dating services">Entertainment, including gaming and dating services</mat-option>
    <mat-option value="Banking and financial institutions">Banking and financial institutions</mat-option>
    <mat-option value="Gambling and lotteries">Gambling and lotteries</mat-option>
    <mat-option value="Local, state or federal agencies, utilities">Local, state or federal agencies, utilities</mat-option>
    <mat-option value="Healthcare services, including hospital, health insurances">Healthcare services, including hospital, health insurances</mat-option>
    <mat-option value="Travel, hospitality and transportation">Travel, hospitality and transportation</mat-option>
    <mat-option value="Human resources, staffing or recruitment">Human resources, staffing or recruitment</mat-option>
    <mat-option value="Insurance products and services">Insurance products and services</mat-option>
    <mat-option value="Legal services and law firms">Legal services and law firms</mat-option>
    <mat-option value="Goods producing industries">Goods producing industries</mat-option>
    <mat-option value="Non-profit organizations with valid tax-exempted status">Non-profit organizations with valid tax-exempted status</mat-option>
    <mat-option value="Political campaigns and services">Political campaigns and services</mat-option>
    <mat-option value="Postal and delivery services">Postal and delivery services</mat-option>
    <mat-option value="Professional services">Professional services</mat-option>
    <mat-option value="Buying and selling of residential and commercial properties">Buying and selling of residential and commercial properties</mat-option>
    <mat-option value="Consumer products and services, including Retails and eTails">Consumer products and services, including Retails and eTails</mat-option>
    <mat-option value="Information technology and information services">Information technology and information services</mat-option>
    <mat-option value="Transportation or logistics services">Transportation or logistics services</mat-option>
      </mat-select>
  </mat-form-field>
      <br />
      Country of Registration<button mat-mini-fab color="primary" aria-label="Refresh"
                                     matTooltip="'Country in which the company is located'" style="text-align:left">
        <mat-icon>info</mat-icon>
      </button>
      <br />
      <mat-form-field appearance="outline" class="msg-hist-filtByCountry-field">
        <mat-label>Country of Registration</mat-label>
        <mat-select formControlName="Country">
          <mat-option value="">All Countries</mat-option>
          <mat-option *ngFor="let country of countries" [value]="country">{{country.Country}}</mat-option>
        </mat-select>
      </mat-form-field>
      <br />
      Postal/Zip code
      <br />
      <mat-form-field appearance="outline" class="msg-hist-filtByNum-field" style="width:288px">
        <mat-label>Postal/Zip code</mat-label>
        <input matInput placeholder="Postal/Zip code" formControlName="postalcode">
      </mat-form-field>
      <br />
      Address/Street
      <br />
      <mat-form-field appearance="outline" class="msg-hist-filtByNum-field" style="width:288px">
        <mat-label>Address/Street</mat-label>
        <input matInput placeholder="Address/Street" formControlName="address">
      </mat-form-field>
      <br />
      City
      <br />
      <mat-form-field appearance="outline" class="msg-hist-filtByNum-field" style="width:288px">
        <mat-label>City</mat-label>
        <input matInput placeholder="City" formControlName="city">
      </mat-form-field>
      <br />
      State/Region
      <br />
      <mat-form-field appearance="outline" class="msg-hist-filtByNum-field" style="width:288px">
        <mat-label>State/Region</mat-label>
        <input matInput placeholder="State/Region" formControlName="state">
      </mat-form-field>
      <br />
      Email
      <br />
      <mat-form-field appearance="outline" class="msg-hist-filtByNum-field" style="width:288px">
        <mat-label>Email</mat-label>
        <input matInput placeholder="abc@abc.com" formControlName="email">
      </mat-form-field>
      <br />
      Phone Number
      <br />
      <mat-form-field appearance="outline" class="msg-hist-filtByNum-field" style="width:288px">
        <mat-label>Phone Number</mat-label>
        <input matInput placeholder="9786453213" formControlName="phonenumber">
      </mat-form-field>
    </p>
      
    <p>
      <!--<strong>Brand Registration</strong><br />-->
      <mat-label style="font-size: 15px"><b>Step 2 : Campaign Registration</b></mat-label>
</p>
    <p>
      Use Case
      <br />
      <mat-form-field appearance="outline" class="msg-hist-filtByCountry-field">
        <mat-label>Use case</mat-label>
        <mat-select formControlName="usecase">
          <mat-option value="Low Volume Mixed">Low Volume Mixed</mat-option>
          <mat-option value="Marketing">Marketing</mat-option>
        </mat-select>
      </mat-form-field>
      <br />
      Campaign Name
      <br />
      <mat-form-field appearance="outline" class="msg-hist-filtByNum-field" style="width:288px">
        <mat-label>Campaign name</mat-label>
        <input matInput placeholder="Campaign name" formControlName="campaignname">
      </mat-form-field>
      <br />
      Campaign Description
      <br />
      <mat-form-field appearance="outline" class="msg-hist-filtByNum-field" style="width:288px">
        <mat-label>Campaign Description</mat-label>
        <input matInput placeholder="Campaign Description" formControlName="campaigndescription">
      </mat-form-field>
      <br />
      </p>
      Sample Message
      <br />
      <div>
        <app-sms-editor formControlName="samplemessage" place #sms [text]="message" [channel]="isSms() ? 'sms' : 'whatsapp'" [smart]="false"></app-sms-editor>
      </div>
      <br />
      <p>
        <b>Subscriber Opt-in flow</b><br />
        Set up how subscribers will opt-in for your campaign
        <br />
        Opt-in keyword
        <br />
        <mat-form-field appearance="outline" class="msg-hist-filtByNum-field" style="width:288px">
          <mat-label>Example: START...</mat-label>
          <input matInput placeholder="Example: START..." formControlName="messageboxin">
        </mat-form-field>
        <br />
        <label style="width:288px; font-size:11px">In order to select opt-in keyword go back to the Message flow and select Text-to-json consent.</label>
        <br />
        Opt-in Sample message
        <br />
        <mat-form-field appearance="outline" class="msg-hist-filtByNum-field" style="width:288px">
          <mat-label>Opt-in Sample message</mat-label>
          <textarea formControlName="descriptionoptin" matInput placeholder="Description"></textarea>
        </mat-form-field>
        <br />
        <label style="font-size:11px;">
          The opt-in message automatically sent to the subscriber must contain:
          <br />
          1. your brand name
          <br />
          2. message frequency
          <br />
          3. messages & data rates may apply
        </label>
        <br />
        <br />
        <b>Subscriber Opt-out flow</b><br />
        Set up how subscribers will be able to opt-out from your campaign
        <br />
        Opt-out Keyword
        <br />
        <mat-form-field appearance="outline" class="msg-hist-filtByNum-field" style="width:288px">
          <mat-label>Example: UNSUBSCRIBE...</mat-label>
          <input matInput placeholder="Example: UNSUBSCRIBE..." formControlName="messageboxout">
        </mat-form-field>
        <br />
        <label style="width:288px; font-size:11px">
          All campagins requires "STOP" by default. Please add any additional keyword you want to allow.
        </label>
        <br />
        Opt-out Sample message
        <br />
        <mat-form-field appearance="outline" class="msg-hist-filtByNum-field" style="width:288px">
          <mat-label>Example: []: You've successfully unsubscribed and will receive no further messages.</mat-label>
          <textarea formControlName="descriptionoptout" matInput placeholder="Description"></textarea>
        </mat-form-field>
        <br />
        <label style="font-size:11px;">
          The opt-out message automatically sent to the subscriber must contain:
          <br />
          1. your brand name
          <br />
          2. confirmation of the removal of the subscriber from the campaign, and
          <br />
          3. confirmation the subscriber "will no longer receive any further messages"
        </label>
        <br />
        <br />
        <b>Subscriber Help</b>
        <br />
        Help Keyword
        <br />
        <mat-form-field appearance="outline" class="msg-hist-filtByNum-field" style="width:288px">
          <mat-label>Example: HELP...</mat-label>
          <input matInput placeholder="Example: HELP..." formControlName="messageboxhelp">
        </mat-form-field>
        <br />
        Help Sample message
        <br />
        <mat-form-field appearance="outline" class="msg-hist-filtByNum-field" style="width:288px">
          <mat-label>Example: []: For HELP, please email at support.</mat-label>
          <textarea formControlName="descriptionhelp" matInput placeholder="Example: For HELP, please email at support."></textarea>
        </mat-form-field>
        <br />
        <label style="font-size:11px;">
          The automated help message sent to subscribers must contain:
          <br />
          1. your brand name
          <br />
          2. a phone number, email address or link to a customer care or support page
        </label>
      </p>
      <button mat-flat-button color="primary" (click)="newSearch()">Send</button>
  </form>
</div>

<div *ngIf="displayError">
  <div class="alert alert-danger" role="alert">
    {{errorMsg}}
  </div>
</div>

<div *ngIf="displaySuccess">
  <div class="alert alert-success" role="alert">
    Email has been sent for your 10 DLC_Brand Registration.
  </div>
</div>
