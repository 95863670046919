import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { MessageQueue, MessageQueueUser, NetworkService, SpeedDefinition } from '../../network.Service';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { FormControl, FormGroup } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { UserProfile, UserService } from '../../user.Service';
import { JsonCyclic } from '../../utils';
import { plainToClass } from 'class-transformer';
import { UserOrgSearchComponent } from '../../find-user/user-org-search/user-org-search.component';

@Component({
  selector: 'app-message-queue',
  templateUrl: './message-queue.component.html',
  styleUrl: './message-queue.component.css',
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class MessageQueueComponent {

  formGroup: FormGroup;
  owner: UserProfile[] = [];
  ownerId: number[] = [];
  users: UserProfile[] = [];
  userIds: number[] = [];
  dummyQueue: MessageQueue = new MessageQueue();
  userSummary: UserProfile[] = [];

  @ViewChild('userlist') userList: UserOrgSearchComponent | undefined;
  @ViewChild('oneuser') oneUser: UserOrgSearchComponent | undefined;


  constructor(public translate: TranslateService,
    private networkService: NetworkService,
    private userService: UserService,
    private snackbar: MatSnackBar
  ) {

  }

  type(): string {
    switch (this.queue.TypeId) {
      case 0:
        return "System";
      case 1:
        return "User";
      default:
        return "Undefined";
    }
  }

  status(): string {
    switch (this.queue.StatusId) {
      case 0:
        return "Active";
      case 1:
        return "Suspended";
      default:
        return "Undefined";
    }
  }

  editDefinition(i: number) {
    switch (i) {
      case 1:
        this.preview = 1;
        break;
      case 4:
        this.closed?.emit(new MessageQueueEvent("delete", this.queue));
        return;

    }
  }

  saveDefinition() {
    this.queue.Name = this.formGroup.controls.Name.value;
    this.queue.Description = this.formGroup.controls.Description.value;
    this.queue.TypeId = this.formGroup.controls.Type.value;
    this.queue.Users = [];
    if (this.queue.TypeId == 0) {
      this.queue.OwnerId = 0;
      for (let i = 0; i < this.userList?.userIds.length; i++) {
        this.queue.Users.push(new MessageQueueUser(this.userIds[i]));
      }
    }
    else {
      this.queue.OwnerId = this.oneUser.userIds.length > 1 ? this.oneUser.userIds[1] : 0;
    }

    this.queue.MessageCharacteristics.AllowedSpeeds = [];
    let allowedSpeed = this.formGroup.controls.AllowedSpeeds.value as string[];
    for (let i = 0; i < this.dummyQueue.MessageCharacteristics.AllowedSpeeds.length; i++) {
      if (allowedSpeed.includes(this.dummyQueue.MessageCharacteristics.AllowedSpeeds[i].description)) {
        this.queue.MessageCharacteristics.AllowedSpeeds.push(this.dummyQueue.MessageCharacteristics.AllowedSpeeds[i]);
      }

      if (this.formGroup.controls.Speed.value == this.dummyQueue.MessageCharacteristics.AllowedSpeeds[i].description) {
        this.queue.MessageCharacteristics.DefaultSpeed = this.dummyQueue.MessageCharacteristics.AllowedSpeeds[i];
      }
    }

    this.queue.Characteristics = JsonCyclic.toJson(this.queue.MessageCharacteristics);
    this.networkService.saveMessageQueueDefinition(this.queue).subscribe(
      result => {
        this.queue = result;
        this.openSnackbar("Successfully Saved Message Queue Definitions", "");
      },
      error => {
        this.openSnackbar("There was a problem saving the Message Queue Definitions", "Cancel");
      }
    );

    this.preview = 0;
    this.closed?.emit(new MessageQueueEvent("saved", this.queue));
  }

  cancelDefinition() {
    this.preview = 0;
    this.closed?.emit(new MessageQueueEvent("closed", this.queue));
  }

  setupForm() {
    let speeds = [];
    this.queue.MessageCharacteristics.DefaultSpeed = plainToClass(SpeedDefinition, this.queue.MessageCharacteristics.DefaultSpeed);
    for (let i = 0; i < this.queue.MessageCharacteristics.AllowedSpeeds.length; i++) {
      this.queue.MessageCharacteristics.AllowedSpeeds[i] = plainToClass(SpeedDefinition, this.queue.MessageCharacteristics.AllowedSpeeds[i]);
      if (this.queue.MessageCharacteristics.AllowedSpeeds[i] != null) {
        speeds.push(this.queue.MessageCharacteristics.AllowedSpeeds[i].description);
      }
    }

    if (this.queue.OwnerId == 0 && this.queue.Users.length == 0) {
      this.userSummary = [];
    }
    else {
      if (this.queue.OwnerId > 0) {
        this.userService.getUsersForIds([this.queue.OwnerId]).subscribe(
          result => {
            this.userSummary = result;
          });
      }
      else {
        let ids = [];
        for (let i = 0; i < this.queue.Users.length; i++) {
          ids.push(this.queue.Users[i].UserId);
        }

        this.userService.getUsersForIds(ids).subscribe(
          result => {
            this.userSummary = result;
          });
      }
    }

    this.setupUserIds();
    if (this.formGroup == null) {
      this.formGroup = new FormGroup({
        Name: new FormControl(this.queue.Name),
        Description: new FormControl(this.queue.Description),
        Status: new FormControl(this.queue.StatusId),
        Type: new FormControl(this.queue.TypeId),
        All: new FormControl(this.queue.OwnerId == 0 && this.queue.Users.length == 0 ? 0 : 1),
        Speed: new FormControl(this.queue.MessageCharacteristics.DefaultSpeed.description),
        AllowedSpeeds: new FormControl(speeds)
      });
      return;
    }

    this.formGroup.controls.Name.setValue(this.queue.Name);
    this.formGroup.controls.Description.setValue(this.queue.Description);
    this.formGroup.controls.Status.setValue(this.queue.StatusId);
    this.formGroup.controls.Type.setValue(this.queue.TypeId);
    this.formGroup.controls.All.setValue(this.queue.OwnerId == 0 && this.queue.Users.length == 0 ? 0 : 1);
    this.formGroup.controls.Speed.setValue(this.queue.MessageCharacteristics.DefaultSpeed.description);
    this.formGroup.controls.AllowedSpeeds.setValue(speeds);
  }

  formType(): number {
    return this.formGroup?.controls.Type?.value ?? 0;
  }

  formAll(): number {
    return this.formGroup?.controls.All?.value ?? 0;
  }

  @Output()
  closed: EventEmitter<MessageQueueEvent> = new EventEmitter<MessageQueueEvent>();

  @Input()
  get preview(): number {
    return this._preview;
  }
  set preview(value: number) {
    this.dummyQueue = new MessageQueue();
    if (value == 1 && this.preview != value) {
      if (this.queue == null) {
        return;
      }

      this.setupForm();
    }

    this._preview = value;
  }
  private _preview: number = 0;

  @Input()
  get queue(): MessageQueue {
    return this._queue;
  }
  set queue(value: MessageQueue) {
    this._queue = value;
    if (value != null) {
      this.setupForm();
    }
  }
  private _queue: MessageQueue;

  setupUserIds() {
    this.ownerId = [];
    this.ownerId.push(this._queue.OwnerId);
    let ids = [];
    for (let i = 0; i < this._queue.Users.length; i++) {
      ids.push(this._queue.Users[i].UserId);
    }

    this.userIds = ids;
  }

  private openSnackbar(message: string, action: string) {
    if (action == "") {
      this.snackbar.open(message, action, { duration: 2000 });
    }
    else {
      this.snackbar.open(message, action, { duration: 7000 });
    }
  }
}

export class MessageQueueEvent {
  public action: string;
  public queue: MessageQueue;

  constructor(action: string, queue: MessageQueue) {
    this.action = action;
    this.queue = queue;
  }
}
