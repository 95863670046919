<div role="group" class="cc-input-container"
     [formGroup]="parts"
     [attr.aria-labelledby]="_formField.getLabelId()"
     (focusin)="onFocusIn($event)"
     (focusout)="onFocusOut($event)">
  <input class="cc-input-element"
         formControlName="part1" size="4"
         maxLength="4"
         aria-label="Part 1"
         (input)="_handleInput(parts.controls.part1, part2)"
         #part1>
  <span class="cc-input-part-spacer">&ndash;</span>
  <input class="cc-input-element"
         formControlName="part2" size="4"
         maxLength="4"
         aria-label="Part 2"
         (input)="_handleInput(parts.controls.part2, part3)"
         (keyup.backspace)="autoFocusPrev(parts.controls.part2, part1)"
         #part2>
  <span class="cc-input-part-spacer">&ndash;</span>
  <input class="cc-input-element"
         formControlName="part3" size="4"
         maxLength="4"
         aria-label="Part 3"
         (input)="_handleInput(parts.controls.part3, part4)"
         (keyup.backspace)="autoFocusPrev(parts.controls.part3, part2)"
         #part3>
  <span class="cc-input-part-spacer">&ndash;</span>
  <input class="cc-input-element"
         formControlName="part4" size="4"
         maxLength="4"
         aria-label="Part 4"
         (input)="_handleInput(parts.controls.part4, month)"
         (keyup.backspace)="autoFocusPrev(parts.controls.part4, part3)"
         #part4>
  <span class="cc-input-spacer">&nbsp;</span>
  <input class="cc-input-expiry"
         formControlName="month"
         maxLength="2"
         size="2"
         aria-label="Expiry Month"
         (input)="_handleInput(parts.controls.month, year)"
         (keyup.backspace)="autoFocusPrev(parts.controls.month, part4)"
         #month>
  <span class="cc-input-part-spacer">&#47;</span>
  <input class="cc-input-expiry"
         formControlName="year"
         maxLength="2"
         size="2"
         aria-label="Expiry Year"
         (input)="_handleInput(parts.controls.year, cvc)"
         (keyup.backspace)="autoFocusPrev(parts.controls.year, month)"
         #year>
  <span class="cc-input-spacer">&nbsp;</span>
  <input class="cc-input-cvc"
         formControlName="cvc"
         maxLength="3"
         size="3"
         aria-label="CVC"
         (input)="_handleInput(parts.controls.cvc)"
         (keyup.backspace)="autoFocusPrev(parts.controls.cvc, year)"
         #cvc>
</div>
