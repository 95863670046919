import { Component, Inject, TemplateRef, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { plainToClass } from 'class-transformer';
import { QuestionEditorComponent } from '../../question-editor/question-editor.component';
import { InterviewModel, InterviewService, LanguageAnalysis, LanguageDefinition, LanguageSummary } from '../../question.service';
import { JsonCyclic } from '../../utils';
import { UploadLanguagesComponent } from '../upload-languages/upload-languages.component';
import { LanguageEditorComponent } from '../language-editor/language-editor.component';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSelectionList } from '@angular/material/list'

@Component({
  selector: 'app-language-summary',
  templateUrl: './language-summary.component.html',
  styleUrls: ['./language-summary.component.css']
})
export class LanguageSummaryComponent {

  dataSource = new MatTableDataSource<LanguageSummary>();
  processPageSize = 10;
  isRateLimitReached: boolean = false;
  page: number = 1;
  analysis: LanguageAnalysis;
  model: string;
  interview: InterviewModel;
  editor: QuestionEditorComponent;
  languages: LanguageDefinition[] = [];
  customLanguage: FormGroup;
  selectionedRegions: [] = [];
  subDialogRef: MatDialogRef<any>;

  get columnsToDisplay(): string[] {
    return ['name', 'region', 'visible', 'status', 'action'];
  }

  @ViewChild('table', { static: true }) table: any | undefined;
  @ViewChild(MatSort) sort: MatSort | undefined;
  @ViewChild('newLanguageDialog') newLanguageDialog: TemplateRef<any>;
  @ViewChild('customLanguageDialog') customLanguageDialog: TemplateRef<any>;
  @ViewChild('regions') regions: MatSelectionList;

  constructor(private dialog: MatDialog,
    private dialogRef: MatDialogRef<any>,
    private interviewService: InterviewService,
    @Inject(MAT_DIALOG_DATA) data: any) {
    this.interviewService.getAllLanguages().subscribe(result => { this.languages = result });
    this.interview = data.model;
    this.model = JsonCyclic.toJson(data.model);
    this.editor = data.editor;
    this.doAnalysis();
    this.customLanguage = new FormGroup({
      DisplayName: new FormControl('', Validators.required),
      Locale: new FormControl('', Validators.required),
      Region: new FormControl('', Validators.required),
      Code: new FormControl()
    })
  }

  addLanguage(language: LanguageDefinition): boolean {
    if (!this.addLanguageFirst(language)) {
      return false;
    }

    this.interview.Languages.push(language.Region);
    this.interview.ShowLanguage.push(true);
    this.doAnalysis();
    return false;
  }

  addLanguageFirst(language: LanguageDefinition): boolean {
    this.checkLanguages();
    let chosenLanguages = this.interview.Languages;
    for (let i = 0; i < chosenLanguages.length; i++) {
      if (chosenLanguages[i] == language.Region) {
        return false;;
      }
    }

    return true;
  }

  addLanguages(): boolean {
    let l = [];
    for (let i = 0; i < this.regions.selectedOptions.selected.length; i++) {
      let option = this.regions.selectedOptions.selected[i];
      let found = false;
      for (let j = 0; j < this.analysis.Summary.length; j++) {
        if (this.analysis.Summary[j].Language.Region == option.value.Region) {
          found = true;
          break;
        }
      }

      if (!found) {
        if (this.addLanguageFirst(option.value)) {
          l.push(option.value);
        }
      }
    }

    if (l.length > 0) {
      for (let i = 0; i < l.length; i++) {
        this.interview.Languages.push(l[i].Region);
        this.interview.ShowLanguage.push(true);
      }

      this.model = JsonCyclic.toJson(this.interview);
      this.doAnalysis();
    }

    this.subDialogRef.close();
    return false;
  }

  selectLanguages(): boolean {
    this.subDialogRef = this.dialog.open(this.newLanguageDialog);
    return false;
  }

  isUsed(language: LanguageDefinition) {
    for (let i = 0; i < this.analysis.Summary.length; i++) {
      if (language.Region == this.analysis.Summary[i].Language.Region) {
        return true;
      }
    }

    return false;
  }

  createCustomLanguage(): boolean {
    this.subDialogRef.close();
    this.subDialogRef = this.dialog.open(this.customLanguageDialog);
    return false;
  }

  addCustomLanguage(): boolean {
    this.subDialogRef.close();
    for (let i = 0; i < this.interview.CustomLanguages?.length; i++) {
      if (this.interview.CustomLanguages[i].Region.toLowerCase() == this.customLanguage.controls.Region.value.toLowerCase()) {
        this.interview.CustomLanguages[i].Description = this.customLanguage.controls.DisplayName.value;
        this.interview.CustomLanguages[i].Locale = this.customLanguage.controls.Locale.value;
        for (let j = 0; j < this.interview.Languages.length; j++) {
          if (this.interview.Languages[j] == this.interview.CustomLanguages[i].Region) {
            this.doAnalysis();
            return true;
          }
        }

        this.interview.Languages.push(this.customLanguage.controls.Region.value);
        this.interview.ShowLanguage.push(true);
        this.doAnalysis();
        return true;
      }
    }

    let language = new LanguageDefinition();
    language.Region = this.customLanguage.controls.Region.value;
    language.Code = "";
    language.Description = this.customLanguage.controls.DisplayName.value;
    language.Locale = this.customLanguage.controls.Locale.value;
    this.interview.CustomLanguages.push(language);
    this.interview.Languages.push(language.Region);
    this.interview.ShowLanguage.push(true);
    this.doAnalysis();
    return true;
  }

  checkLanguages() {
    if (this.interview.Languages == null || this.interview.Languages.length == 0) {
      this.interview.Languages = [];
      this.interview.Languages.push(this.analysis.Summary[0].Language.Region);
      this.interview.ShowLanguage = [];
      this.interview.ShowLanguage.push(true);
    }

  }

  doAnalysis() {
    this.model = JsonCyclic.toJson(this.interview);
    this.interviewService.analyzeInterview(this.model).subscribe(
      result => {
        this.analysis = result;
        let temp = plainToClass(InterviewModel, this.analysis.InterviewModel);
        this.analysis.InterviewModel = temp;
        this.analysis.InterviewModel.checkModel();
        if (this.interview.findNavigation() == null) {
          this.interview.Questions.push(this.analysis.InterviewModel.findNavigation());
          this.interview.reIndex(-1, -1, this.interview.Questions);
        }

        this.checkLanguages();
        this.dataSource = new MatTableDataSource<LanguageSummary>(this.analysis.Summary);
      });
  }

  total(summary: LanguageSummary): number {
    let complete = 0;
    for (var i in summary.Elements) {
        complete++;
    }

    return complete;
  }

  isVisible(language: LanguageDefinition): boolean {
    let i = this.findLanguage(language.Region);
    if (i == -1) {
      return true;
    }

    return this.interview.ShowLanguage[i];
  }

  findLanguage(region: string): number {
    if (this.interview.Languages == null) {
      return -1;
    }

    for (let i = 0; i < this.interview.Languages.length; i++) {
      if (this.interview.Languages[i] == region) {
        return i;
      }
    }

    return -1;
  }

  export() {
    this.interviewService.getLanguages(this.model)
      .subscribe(response => {
        this.downLoadFile(response, "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", ".xlsx")
      }
      );
  }

  downLoadFile(data: any, type: string, ext: string) {
    let blob = new Blob([data], { type: type });
    let url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    const fileName = "Languages";
    a.href = url;
    a.download = fileName + ext;
    a.click();
    window.URL.revokeObjectURL(url);
  }

  import() {
    let importRef = this.dialog.open(UploadLanguagesComponent, { data: { model: this.interview }, minWidth: "95vw" });
    importRef.afterClosed().subscribe(result => {
      if (result) {
        this.editor.inTimer = true;
        this.editor.modelChecked = false;
        this.interview = plainToClass(InterviewModel, result);
        this.editor.interview = this.interview;
        this.interview.checkModel();
        this.model = JsonCyclic.toJson(this.interview);
        this.doAnalysis();
        this.editor.modelChecked = true;
        this.editor.inTimer = false;
      }
    });
    return false;
  }

  edit() {
    let editorRef = this.dialog.open(LanguageEditorComponent, { data: { analysis: this.analysis, interview: this.interview }, minWidth: "95vw", height: "80%" });
    editorRef.afterClosed().subscribe(result => {
      if (result != 'no') {
        this.analysis = result.analysis;
        this.interview = result.interview;
        this.model = JsonCyclic.toJson(this.interview);
        this.doAnalysis();
      }
    })
  }

  delete(language: LanguageSummary) {
    this.checkLanguages();
    for (let i = 0; i < this.interview.Languages.length; i++) {
      if (this.interview.Languages[i] == language.Language.Region) {
        this.interview.Languages.splice(i, 1);
        this.interview.ShowLanguage.splice(i, 1)
        this.model = JsonCyclic.toJson(this.interview);
        this.doAnalysis();
      }
    }
  }

  close() {
    this.dialogRef.close(this.analysis);
  }

}
