<div class="fullWidth">
  <div class="user-details-dialog-container">
    <div class="leftFloat">
      <h3>Setup Invitation Message</h3>
    </div>
    <div class="rightFloat">
      <button mat-icon-button matDialogClose="no" matTooltip="Close" matTooltipPosition="below">
        <mat-icon class="user-details-dialog-close-icon">close</mat-icon>
      </button>
    </div>
  </div>
  <div class="clearBoth"></div>
  <form [formGroup]="formGroup">
    <div class="padding-10">
      <div>
        <mat-form-field *ngIf="comms?.length > 1" appearance="outline" class="fullWidth">
          <mat-select (selectionChange)="selectionChanges($event)">
            <mat-option *ngFor="let comm of comms" [value]="comm.EmailTemplateID">{{comm.Name}} {{comm.Description == '' ? '' : ' - ' + comm.Description}}</mat-option>
          </mat-select>
          <mat-label>Templates</mat-label>
        </mat-form-field>
      </div>
      <div *ngIf="messageType == 'web'" class="msg-builder-editor-div">
        <mat-form-field appearance="outline" class="fullWidth">
          <mat-label>Subject</mat-label>
          <input matInput placeholder="Subject" formControlName="subject">
        </mat-form-field>
      </div>
      <div class="msg-builder-editor-div">
        <diy-editor [channel]="messageType" formControlName="content"></diy-editor>
      </div>
      <div class="msg-builder-saveBtn-div">
        <button mat-raised-button color="primary" (click)="onSendEmailSubmit()">Save</button>
      </div>
    </div>
  </form>
</div>
