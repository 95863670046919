import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, FormArray, Validators } from '@angular/forms';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { BaseQuestion, DataModel, InterviewService, QuestionService } from '../../question.service';

@Component({
  selector: 'app-image-map',
  templateUrl: './image-map.component.html',
  styleUrls: ['./image-map.component.css']
})
export class ImageMapComponent extends BaseQuestion implements OnInit {

  questions = [
    { id: 'Question1', value: 'Question 1' },
    { id: 'Question2', value: 'Question 2' },
    { id: 'Question3', value: 'Question 3' }
  ];

  selectedFile: File | null= null;
  @ViewChild('fileInput') fileInput: any | undefined;
  urls: string[] = [];

  constructor(private breakpointObserver: BreakpointObserver, private qs: QuestionService, private is: InterviewService) {
    super(qs, is, undefined, undefined, new DataModel("imageMap"));
  }

  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
    .pipe(map(result => result.matches));

  public imageMapFormGroup: FormGroup = new FormGroup({});
  public optionFormGroup: FormGroup = new FormGroup({});

  ngOnInit() {
    this.imageMapFormGroup = this.fb.group({
      imageMapFormArray: this.fb.array([this.initQuestion()])
    });

    this.optionFormGroup = this.fb.group({
      imageOptions: this.fb.array([this.initImageOption()]),
    });
  }

  get imageOptionArr(): FormArray {
    return this.optionFormGroup.get('imageOptions') as FormArray;
  }

  initImageOption(): FormGroup {
    return this.fb.group({
      imageOption: ['', Validators.required]
    });
  }

  onSubmit(): void {
  }

  addNewImage() {
    this.imageOptionArr.push(this.initImageOption());
  }

  removeImage(index: number) {
    console.log(this.imageOptionArr.length);
    if (this.imageOptionArr.length > 1) {
      this.imageOptionArr.removeAt(index);
    }
  }

  get questionArr(): FormArray | null {
    if (this.imageMapFormGroup) {
      return this.imageMapFormGroup.get('imageMapFormArray') as FormArray;
    }

    return null;
  }

  initQuestion() {
    return this.fb.group({
      qNumber: ['', Validators.required],
      rowCount: ['', Validators.required],
      colCount: ['', Validators.required]
    });
  }

  onClickFileInputButton(index: number) {

  }

  onChangeFileInput(event: any): void {
    this.urls = [];
    const files = event.target.files;
    if (files) {
      for (let file of files) {
        let reader = new FileReader();
        reader.onload = (e: any) => {
          this.urls.push(e.target.result);
        }
        reader.readAsDataURL(file);
      }
    }
  }
}
