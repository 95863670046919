import { HttpClient, HttpHeaders } from '@angular/common/http';
import { version, baseUrl } from "../../../environments/environment";
import { AfterViewInit, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AuthenticationService } from '../../authentication.Service';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { DeviceDetectorService } from 'ngx-device-detector';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Observable, Subject, fromEvent, map, takeUntil } from 'rxjs';

@Component({
  selector: 'app-newtoolbar',
  templateUrl: './newtoolbar.component.html',
  styleUrl: './newtoolbar.component.css'
})
export class NewtoolbarComponent implements OnInit, AfterViewInit, OnDestroy {

  private config: {
    version: string
  };

  breakpoint: number = 0;
  currentVersion: string;
  destroy = new Subject();
  destroy$ = this.destroy.asObservable();
  top: number = 0;

  @ViewChild("home") public home: ElementRef;
  @ViewChild("create") public create: ElementRef;
  @ViewChild("step1") public step1: ElementRef;
  @ViewChild("step2") public step2: ElementRef;
  @ViewChild("step3") public step3: ElementRef;
  @ViewChild("step4") public step4: ElementRef;
  @ViewChild("body") public body: ElementRef;
  @ViewChild("marketing") public marketing: ElementRef;
  @ViewChild("enterprise") public enterprise: ElementRef;

  constructor(private http: HttpClient,
    private snackBar: MatSnackBar,
    private breakpointObserver: BreakpointObserver,
    private deviceService: DeviceDetectorService
  ) {
    this.currentVersion = version;
  }

  isHandset: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
    .pipe(map(result => result.matches));

  ngOnInit(): void {
    this.config = require('../../../assets/files/config.json')

    const headers = new HttpHeaders()
      .set('Cache-Control', 'no-cache')
      .set('Pragma', 'no-cache');

    this.http.get<{ version: string }>('../../assets/files/config.json', { headers })
      .subscribe(config => {
        if (config.version !== this.config.version) {
          let temp = this.snackBar.open("New version of the platform available", "Reload", {
            horizontalPosition: "center",
            verticalPosition: "top"
          });
          temp.afterDismissed().subscribe(() => {
            this.http
              .get("", { headers, responseType: "text" })
              .subscribe(() => location.reload());
          });
        }
      });
  }

  ngOnDestroy(): void {
    this.destroy.next(this.destroy$);
  }

  ngAfterViewInit(): void {
    fromEvent(window, 'scroll').pipe(takeUntil(this.destroy$)).subscribe((e: Event) => {
      this.top = this.getYPosition(e);
    });
  }

  getYPosition(e): number {
    return e.target.scrollingElement.scrollTop;
  }

  checkStyle(): string {
    if (this.top > 5) {
      return 'mat-elevation-z2';
    }

    return '';
  }

  scrollTo(location: string) {
    const element = document.getElementById(location);
    element?.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'start' });
  }

  @Input()
  get toolbarType(): string {
    return this._type;
  }
  set toolbarType(value: string) {
    this._type = value;
  }
  private _type: string = "both";

  @Input()
  get menu(): NewToolbarMenu[] {
    return this._menu;
  }
  set menu(value: NewToolbarMenu[]) {
    this._menu = value;
  }
  private _menu: NewToolbarMenu[] = [];
}

class NewToolbarMenu {
  public name: string;
  public location: string;
}
