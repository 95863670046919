<div class="margin-10">
  <form class="flex-stretch-column" *ngIf="tempKey | async; else loading" [formGroup]="formGroup">
    <mat-accordion>
      <mat-expansion-panel hideToggle [expanded]="true">
        <mat-expansion-panel-header>
          <mat-panel-title>
            {{keyTitle}} Access Token
          </mat-panel-title>
          <mat-panel-description>

          </mat-panel-description>
        </mat-expansion-panel-header>
        <p>
          Created:{{ key.Created | date:'medium' }} and Last Updated:{{ key.Amended | date:'medium' }}
        </p>
        <div *ngIf="!isWhatsApp">
          <mat-form-field appearance="outline" class="fullWidth">
            <mat-label>Name</mat-label>
            <input matInput placeholder="Name" formControlName="Name">
          </mat-form-field>
        </div>
        <div *ngIf="isWhatsApp">
          <mat-form-field appearance="outline" class="fullWidth">
            <mat-label>WABA</mat-label>
            <input matInput placeholder="WABA" formControlName="Name">
          </mat-form-field>
        </div>
        <div>
          <mat-form-field appearance="outline" class="fullWidth">
            <mat-label>AppId</mat-label>
            <input matInput placeholder="AppId" [readonly]="!isAdminUser" formControlName="AppId">
          </mat-form-field>
        </div>
        <div *ngIf="isHmac">
          <mat-form-field appearance="outline" class="fullWidth">
            <mat-label>{{isWhatsApp ? 'Private' : ''}} Key</mat-label>
            <input matInput placeholder="Key" [readonly]="!isAdminUser" formControlName="KeyString">
          </mat-form-field>
        </div>
        <div *ngIf="isWhatsApp">
          <mat-form-field appearance="outline" class="halfWidth">
            <mat-label>{{isWhatsApp ? 'Private' : ''}} Key</mat-label>
            <textarea matInput class="scroll" formControlName="KeyString"
                      cdkTextareaAutosize
                      #autosize="cdkTextareaAutosize"
                      cdkAutosizeMinRows="10"
                      cdkAutosizeMaxRows="20" [readonly]="!isAdminUser"></textarea>
          </mat-form-field>
        </div>
        <div class="fieldRowWithPadding">
          <div>
            <mat-button-toggle-group #group="matButtonToggleGroup" formControlName="SuspendedFlag">
              <mat-button-toggle value="1" [checked]="key.Suspended === false">Active</mat-button-toggle>
              <mat-button-toggle value="2" [checked]="key.Suspended === true">Suspended</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div>
          <br/>
        </div>
        <div>
          <button mat-raised-button class="mt-5" color="primary" (click)="onSubmit()">Save</button>&nbsp;
          <button mat-stroked-button class="mt-5" (click)="deleteKey()">Delete</button>&nbsp;
        </div>
      </mat-expansion-panel>
      <mat-expansion-panel hideToggle>
        <mat-expansion-panel-header>
          <mat-panel-title>
            Advanced Settings
          </mat-panel-title>
          <mat-panel-description>
          </mat-panel-description>
        </mat-expansion-panel-header>
        <div *ngIf="tinyUrls.length > 0" [formGroup]="tinyUrlForm">
          <h3>Default Tiny URL</h3>
          <p>If you have one or more custom Tiny URL's you can specify which is the default one to use when accessing the DIY Surveys API.</p>
          <mat-form-field appearance="outline">
            <mat-label>Default Tiny URL</mat-label>
            <mat-select formControlName="defaultTinyUrl">
              <mat-option [value]="0">No DefaultSelected</mat-option>
              <mat-option *ngFor="let tinyUrl of tinyUrls; let i = index" [value]="tinyUrl.UserTinyUrlId">{{tinyUrl.Url}}</mat-option>
            </mat-select>
          </mat-form-field>&nbsp;
        </div>
        <h3>Default Metadata</h3>
        <p>The metadata that will be attached to every message if no metadata is supplied</p>
        <app-metadata-table #metadataTable [metadata]="metadata"></app-metadata-table>
        <h3>Windows</h3>
        <p>
          Invite Windows allow you to control (within the schedule) when invites can go out. This is particularly useful when you
          have messages going out that take time to send and you only want them to go out within specific windows of time so that
          it does not contravene country legislation (typically 8am to 8pm is ok) and/or annoy the recipients.
          Use multiple windows to refine the times for different days and/or multiple times on the same day.<br /><br />
          Note: For invites using mobile numbers, all times are adjusted for the time zone of the destination country.
        </p>
        <app-window #messageWindows [window]="windows"></app-window>
      </mat-expansion-panel>
      <mat-expansion-panel *ngIf="isBetaTester" hideToggle>
        <mat-expansion-panel-header>
          <mat-panel-title>
            Message Queue Management
          </mat-panel-title>
          <mat-panel-description>
          </mat-panel-description>
        </mat-expansion-panel-header>
        <p>
          Queue Management allows you to control the speed of messages leaving the DIY Surveys platform
        </p>
        @if (queue) {
        <div [formGroup]="queueForm">
          <p>
            Select a default speed to set the account speed for.
          </p>
          <div>
            <mat-form-field appearance="outline">
              <mat-label>Speed</mat-label>
              <mat-select formControlName="Speed">
                <mat-option value="">No Speed</mat-option>
                <mat-option *ngFor="let speed of queue.MessageCharacteristics.AllowedSpeeds" [value]="speed.description">{{speed.description}}</mat-option>
              </mat-select>
            </mat-form-field>&nbsp;
          </div>
        </div>
        } @else {
        @if (requestMade) {
          <div class="alert alert-success" role="alert">
            You request has been successfully sent.
          </div>
          } @else {
          @if (errorWithRequest) {
            <div class="alert alert-danger" role="alert">
              There was a problem making your request. Please try again later.
            </div>
          } @else {
            <button mat-raised-button color="primary" (click)="requestQueueManagement()">Request Queue Management Capbility</button>&nbsp;
          }
        }

        }
      </mat-expansion-panel>
    </mat-accordion>
  </form>
</div>

<ng-template #loading>
  <div class="loading-container">
    <span class="loading-content">
      <div class="spinner-div"><mat-spinner [diameter]="25"></mat-spinner></div>
      <div class="spinner-text"> Please Wait...</div>
    </span>
  </div>
</ng-template>

