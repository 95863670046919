<div>
  <div *ngIf="noMessages" class="inbox-compose-nomsg-container">
    <p>
      <span *ngIf="isAdmin">Compose a message to send to individual users or organisations.</span>
      <span *ngIf="!isAdmin">Compose a message to send to any users you are connected to through organisations.</span>
    </p>
    <p>
      <button mat-raised-button color="primary" (click)="newMessageDialog()">Compose</button>
    </p>
  </div>

  <div *ngIf="!noMessages" class="inbox-compose-msg-container">
    <div>
      <button mat-raised-button color="primary" (click)="newMessageDialog()">Compose</button>
    </div>&nbsp;&nbsp;
    <div>
      <mat-form-field appearance="outline">
        <mat-label>Search</mat-label>
        <input type="text" matInput placeholder="Search" [(ngModel)]="searchKey" autocomplete="off" (keyup)="applyFilter($event)">
        <button matSuffix mat-icon-button aria-label="Clear" color="primary" *ngIf="searchKey" (click)="onSearchClear($event)">
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field>&nbsp;&nbsp;
    </div>
  </div>
</div>
<div *ngIf="!noMessages" class="inbox-outer-table-container mat-elevation-z1">

  <div class="inbox-table-container custom-scroll-bar">
    <table mat-table #table [dataSource]="dataSource"
           multiTemplateDataRows
           matSort
           matSortActive="created"
           matSortDisableClear matSortDirection="desc">

      <!-- Name Column -->
      <ng-container matColumnDef="from">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> From </th>
        <td mat-cell *matCellDef="let message"><div [innerHTML]="from(message) | safe: 'html'"></div></td>
      </ng-container>

      <!-- Respondent count Column -->
      <ng-container matColumnDef="subject">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> </th>
        <td mat-cell *matCellDef="let message"> <div [innerHTML]="messageDescription(message) | safe: 'html'"></div> </td>
      </ng-container>

      <!-- Created Column -->
      <ng-container matColumnDef="created">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Created </th>
        <td mat-cell *matCellDef="let message"><span class="whtSpc-noWrap">{{date(message)}}</span></td>
      </ng-container>

      <!-- Action column -->
      <ng-container matColumnDef="action">
        <th mat-header-cell *matHeaderCellDef mat-sort-header></th>
        <td mat-cell *matCellDef="let message">
          <div>
            <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="icon-button with a menu" (click)="$event.stopPropagation()">
              <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu #menu="matMenu">
              <button mat-menu-item (click)="edit(message)">
                <mat-icon>edit</mat-icon>
                <span>View</span>
              </button>
              <button mat-menu-item (click)="replyMessage(message)">
                <mat-icon>reply</mat-icon>
                <span>Reply</span>
              </button>
              <button mat-menu-item (click)="deleteMessage(message)">
                <mat-icon>delete</mat-icon>
                <span>Delete</span>
              </button>
            </mat-menu>
          </div>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="columnsToDisplay; sticky: true"></tr>
      <tr mat-row *matRowDef="let message; columns: columnsToDisplay;"
          [class]="'inbox-element-row' + (message.IsRead ? '' : ' inbox-notRead')" (click)="edit(message)">
      </tr>
    </table>
  </div>
  <mat-paginator [pageSize]="messagePageSize" [length]="resultsLength"></mat-paginator>
</div>
