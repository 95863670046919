<div>
  <div>
    <app-advanced-routing [display]="display" [rules]="rules" [variables]="variables" name="Quota "></app-advanced-routing>
  </div>
  <div style="padding: 0 22px">
    <mat-divider></mat-divider>
  </div>
  <div *ngIf="!display && quotaForm" [formGroup]="quotaForm">
    <div class="embed-data-option-div">
      <div style="padding: 0 22px">
        Apply
      </div>
      <div>
        <mat-form-field mat-line appearance="outline" class="pricing-summ-respondents-field">
          <mat-label>Target</mat-label>
          <input matInput formControlName="number" mask="0*" placeholder="Target">
        </mat-form-field>&nbsp;
      </div>
      <div>
        When target is reached&nbsp;
      </div>
      <div *ngIf="isProfileQuota">
        <mat-form-field appearance="outline">
          <mat-label>Action</mat-label>
          <mat-select formControlName="action">
            <mat-option [value]="0">Do not add recipient</mat-option>
            <mat-option [value]="1">Continue to process</mat-option>
            <mat-option [value]="2">Spread evenly across the reset period</mat-option>
          </mat-select>
        </mat-form-field>&nbsp;
      </div>
      <div *ngIf="!isProfileQuota">
        goto&nbsp;
      </div>
      <app-question-combo *ngIf="!isProfileQuota" [displayGoto]="false" [rules]="rules"></app-question-combo>
      <div *ngIf="rules.Quota?.Progress > 0">
        Progress&nbsp;
      </div>
      <div *ngIf="rules.Quota?.Progress > 0" style="width: 40px;">
        <mat-progress-bar mode="determinate" [value]="calcQuotaPercent()"></mat-progress-bar>
      </div>
      <div>
        &nbsp;{{rules.Quota?.Progress < 1 ? 'Empty' : rules.Quota.Progress}}
      </div>
    </div>
    <div class="embed-data-option-div">
      <div style="padding: 0 22px">
        Reset
      </div>
      <div style="margin-left: 0px">
        <mat-checkbox class="example-margin" formControlName="reset">every</mat-checkbox>&nbsp;
      </div>
      <div *ngIf="isReset">
        <mat-form-field mat-line appearance="outline" class="pricing-summ-respondents-field">
          <mat-label>Period</mat-label>
          <input matInput formControlName="period" mask="0*" placeholder="Period">
        </mat-form-field>&nbsp;
      </div>
      <div *ngIf="isReset">
        <mat-form-field appearance="outline">
          <mat-label>Unit</mat-label>
          <mat-select formControlName="unit">
            <mat-option [value]="0">Day(s)</mat-option>
            <mat-option [value]="1">Month(s)</mat-option>
          </mat-select>
        </mat-form-field>&nbsp;
      </div>
      <div *ngIf="isReset">
        <mat-form-field mat-line appearance="outline">
          <mat-label>Starting</mat-label>
          <input matInput [matDatepicker]="picker" formControlName="starting" placeholder="Starting">
          <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>&nbsp;
      </div>
    </div>
  </div>
</div>
