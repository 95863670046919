<div class="column-flex fullWidth margin-10">
  <div *ngIf="showAlert" class="alert alert-success" role="alert">
    {{alertMessage}}
  </div>
  <div *ngIf="showError" class="alert alert-danger" role="alert">
    {{errorMessage}}
  </div>
  <div>
    <invitations #invitations [organization]="model"></invitations>
  </div>
  <div>
    &nbsp;
  </div>
  <div>
    <invite-people #invites></invite-people>
  </div>
</div>
<button mat-raised-button color="primary" [disabled]="invites.list.length === 0" (click)="invite()">
  Invite
</button>

<ng-template #loading>
  <div class="loading-container">
    <span class="loading-content">
      <div class="spinner-div"><mat-spinner [diameter]="25"></mat-spinner></div>
      <div class="spinner-text"> Please Wait...</div>
    </span>
  </div>
</ng-template>
