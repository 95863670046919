import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormArray, Validators } from '@angular/forms';
import { BaseQuestion, DataModel, InterviewService, QuestionService } from '../../question.service';

@Component({
  selector: 'app-currency',
  templateUrl: './currency.component.html',
  styleUrls: ['./currency.component.css']
})
export class CurrencyComponent extends BaseQuestion implements OnInit {
  constructor(private qs: QuestionService, private is: InterviewService) {
    super(qs, is, undefined, undefined, new DataModel("currency"));
  }

  public currencyFormGroup: FormGroup = new FormGroup({});

  ngOnInit() {
    this.currencyFormGroup = this.fb.group({
      currencyFormArray: this.fb.array([this.initQuestion()])
    });
  }

  get questionArr(): FormArray | null {
    if (this.currencyFormGroup) {
      return this.currencyFormGroup.get('currencyFormArray') as FormArray;
    }

    return null;
  }

  onSubmit(): void {
  }
}
