<div class="nav-footer" [class]="(isHandset$ | async) ? 'noDisplay' : ''">
  <div class="footer-container">
    <div class="footer-terms-div">
      <div style="width: 35px;">
        <img src="../../assets/img/favicon.png" style="width:30px" />
      </div>
      <div>
        <span>&#169;{{ currentYear }} DIY Surveys Ltd.</span>
      </div>
    </div>
    <div class="footer-terms-div" style="margin-top:-5px">
      <span><span><img alt="Static Badge" src="https://img.shields.io/badge/{{currentVersion}}-656565"></span></span>
    </div>
    <div class="footer-terms-div">
      <span><a (click)="goToLink('https://diysurveys.zendesk.com/')" class="cursor-pt">Documentation</a></span>
    </div>
    <div class="footer-terms-div">
      <span><a class="cursor-pt" routerLink="/termsofservice">Terms of Service</a></span>
    </div>
    <div class="footer-terms-div">
      <span><a class="cursor-pt" routerLink="/privacystatement">Privacy Statement</a></span>
    </div>
    <div class="footer-terms-div">
      <span><a class="cursor-pt" routerLink="/about">About Us</a></span>
    </div>
    <div class="footer-terms-div" style="margin-top:-5px">
      <a (click)="goToLink('https://www.linkedin.com/company/diy-surveys/')" style="cursor: pointer;"><img src="assets/img/linkedin-png-linkedin-icon-1600.webp" style="height: 20px;" /></a>
    </div>
    <div class="footer-terms-div">
      <span>{{currentInstance}}</span>
    </div>
  </div>
</div>
<div class="nav-footer" [class]="(isHandset$ | async) ? '' : 'noDisplay'">
  <div stlye="margin: 10px">
    <div style="display:flex;flex-flow:row">
      <div style="width: 35px">
        <img src="../../assets/img/favicon.png" style="width:30px" />
      </div>
      <div>
        <span>&#169;{{ currentYear }} DIY Surveys Ltd.</span>&nbsp;&nbsp;
      </div>
      <div>
        <span><span><img alt="Static Badge" src="https://img.shields.io/badge/{{currentVersion}}-656565"></span></span>
      </div>
    </div>
    <div style="display:flex;flex-flow:row">
      <div>
        <span><a (click)="goToLink('https://diysurveys.zendesk.com/')" class="cursor-pt">Docs</a></span>&nbsp;&nbsp;
      </div>
      <div>
        <span><a class="cursor-pt" routerLink="/termsofservice">Terms</a></span>&nbsp;&nbsp;
      </div>
      <div>
        <span><a class="cursor-pt" routerLink="/privacystatement">Privacy</a></span>&nbsp;&nbsp;
      </div>
      <div>
        <span><a class="cursor-pt" routerLink="/about">About</a></span>
      </div>
    </div>
    <div style="display:flex;flex-flow:row">
      <div>
        <a (click)="goToLink('https://www.linkedin.com/company/diy-surveys/')" style="cursor: pointer;"><img src="assets/img/linkedin-png-linkedin-icon-1600.webp" style="height: 20px;" /></a>&nbsp;&nbsp;
      </div>
      <div>
        <span>{{currentInstance}}</span>
      </div>
    </div>
  </div>
</div>
