<div class="padding-10">
  <form *ngIf="tempKeyword | async; else loading" class="new-communication-form column-flex flex-stretch-column" [formGroup]="formGroup" (ngSubmit)="onSubmit()">
    <mat-accordion>
      <mat-expansion-panel hideToggle [expanded]="true">
        <mat-expansion-panel-header>
          <mat-panel-title>
            Basic Settings
          </mat-panel-title>
          <mat-panel-description>

          </mat-panel-description>
        </mat-expansion-panel-header>
        <p *ngIf="keywordId !== '0'">
          Created:{{ keyword.Created | date:'medium' }} and Last Updated:{{ keyword.Amended | date:'medium' }}
        </p>
        <div *ngIf="showNumbers">
          <p>Check Gateway</p>
          <mat-selection-list formControlName="ShortCodesSelected" (selectionChange)="checkKeyword($event)">
            <mat-list-option *ngFor="let item of numbers" [value]="item.Code">
              {{item.Code}} - {{item.Description}}
            </mat-list-option>
          </mat-selection-list>
        </div>

        <div>
          <p>Enter a keyword</p>
          <mat-form-field mat-line appearance="outline">
            <mat-label>Keyword</mat-label>
            <input matInput placeholder="Keyword" formControlName="Code" (keyup)="checkKeyword($event)">
          </mat-form-field>
          <span mat-line>&nbsp;</span>
          <span mat-line *ngIf="errorMsg !== ''" class="alert alert-danger">{{errorMsg}}</span>
          <span mat-line *ngIf="success === true" class="alert alert-success">This keyword is available</span>
        </div>
      </mat-expansion-panel>
      <mat-expansion-panel hideToggle>
        <mat-expansion-panel-header>
          <mat-panel-title>
            Advanced Settings
          </mat-panel-title>
          <mat-panel-description>
          </mat-panel-description>
        </mat-expansion-panel-header>
        <div>
          <p>Enter a message that you would like us to reply with Automatically.</p>
          <mat-form-field appearance="outline" class="fullWidth">
            <mat-label>Message</mat-label>
            <input matInput placeholder="Message" formControlName="Message">
          </mat-form-field>
        </div>
        <mat-divider></mat-divider>
        <div>
          <p>Forward an Email.</p>
          <mat-form-field appearance="outline" class="fullWidth">
            <mat-label>To</mat-label>
            <input matInput placeholder="Status" formControlName="EmailAddress">
          </mat-form-field>
          <mat-form-field appearance="outline" class="fullWidth">
            <mat-label>Subject</mat-label>
            <input matInput placeholder="Status" formControlName="EmailSubject">
          </mat-form-field>
          <mat-form-field appearance="outline" class="fullWidth">
            <mat-label>Message</mat-label>
            <input matInput placeholder="Status" formControlName="EmailBody">
          </mat-form-field>
        </div>
        <mat-divider></mat-divider>
        <div>
          <p>Role</p>
          <mat-button-toggle-group #group="matButtonToggleGroup" formControlName="KeywordType">
            <mat-button-toggle value="3" [checked]="keyword.KeywordType === 3">Normal</mat-button-toggle>
            <mat-button-toggle value="8" [checked]="keyword.KeywordType === 8">Stop</mat-button-toggle>
            <mat-button-toggle value="9" [checked]="keyword.KeywordType === 9">Help</mat-button-toggle>
            <mat-button-toggle value="10" [checked]="keyword.KeywordType === 10">Info</mat-button-toggle>
          </mat-button-toggle-group>
        </div>
      </mat-expansion-panel>
    </mat-accordion>
  </form>
</div>

<ng-template #loading>
  <div class="loading-container">
    <span class="loading-content">
      <div class="spinner-div"><mat-spinner [diameter]="25"></mat-spinner></div>
      <div class="spinner-text"> Please Wait...</div>
    </span>
  </div>
</ng-template>

