<div class="fullWidth">
  <div class="leftFloat">
    <strong>Setup Invitation Message</strong>
  </div>
  <div class="rightFloat">
    <button mat-icon-button mat-dialog-close>
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <div class="clearBoth"></div>
  <form [formGroup]="formGroup">
    <div class="padding-10">
      <div *ngIf="channel === 1" class="msg-builder-editor-div">
        <mat-form-field appearance="outline" class="fullWidth">
          <mat-label>Subject</mat-label>
          <input matInput placeholder="Subject" formControlName="subject">
        </mat-form-field>
      </div>
      <div class="msg-builder-editor-div">
        <diy-editor [channel]="messageType" formControlName="content"></diy-editor>
      </div>
      <div class="msg-builder-saveBtn-div">
        <button mat-raised-button color="primary" (click)="saveWhatsApp()">Save</button>
      </div>
    </div>
  </form>
</div>
