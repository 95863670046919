import { Component, Inject, Input, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { QuestionModel } from '../../question.service';
import { UserFeatures } from '../../user.Service';

@Component({
  selector: 'app-embed-data',
  templateUrl: './embed-data.component.html',
  styleUrls: ['./embed-data.component.css']
})
export class EmbedDataComponent implements OnInit {

  public dataFormGroup: FormGroup = new FormGroup({});
  private _questions: QuestionModel[] = [];

  customPatterns: string = "a-zA-Z[a-zA-Z0-9]*";

  pattern = {
    P: { pattern: new RegExp('[a-zA-Z0-9_]') },
    Q: { pattern: new RegExp('[a-zA-Z0-9_\\. ]') }
  }
  customMask = ['P|P*', this.pattern];

  constructor(private fb: FormBuilder) {
  }

  ngOnInit(): void {
    this.dataFormGroup = this.fb.group({
      embeddedOption: this.fb.array([this.initEmbeddedDataRow(new QuestionModel(), 0)])
    });

    this.loadData();
  }

  isValid(index: number): boolean {
    if (this.lineItem(index).controls.name.value === "") {
      return false;
    }

    for (let i = 0; i < index; i++) {
      if (i !== index) {
        if (this.lineItem(index).controls.name.value === this.lineItem(i).controls.name.value) {
          return false;
        }
      }
    }

    return true;
  }

  showValue(index: number): boolean {
    return this.lineItem(index).controls.source.value === "value";
  }

  showDataType(index: number): boolean {
    return this.lineItem(index).controls.source.value === "value" || this.lineItem(index).controls.source.value === "url";
  }

  showMetaData(index: number): boolean {
    return this.lineItem(index).controls.source.value === "meta";
  }

  showQuestions(index: number): boolean {
    return this.lineItem(index).controls.source.value === "question";
  }

  getDisplayQuestions(): QuestionModel[] {
    if (this._questions.length == 0) {
      this._questions = this._question.Interview.getDisplayQuestions();
    }

    return this._questions;
  }

  displayFactor() {
    return this._userFeatures?.QuestionEditorFeatures.EditAnswerOptionFactors;
  }

  @Input()
  get question(): QuestionModel {
    return this._question;
  }
  set question(value: QuestionModel) {
    this._question = value;
  }
  private _question: QuestionModel | null;

  @Input()
  get userFeatures(): UserFeatures {
    return this._userFeatures;
  }
  set userFeatures(value: UserFeatures) {
    this._userFeatures = value;
  }
  private _userFeatures: UserFeatures | null;


  addEmbeddedData(index: number) {
    const question = new QuestionModel(this._question.Interview);
    if (index + 1 === this._question.Questions.List.length) {
      this._question.Questions.List.push(question);
      this.dataArr.controls.push(this.initEmbeddedDataRow(question, index + 1));
    }
    else {
      this._question.Questions.List.splice(index + 1, 0, question);
      this.dataArr.controls.splice(index + 1, 0, this.initEmbeddedDataRow(question, index + 1));
    }
  }

  deleteEWmbeddedData(index: number) {
    if (index < this._question.Questions.List.length) {
      this._question.Questions.List.splice(index, 1);
      this.dataArr.controls.splice(index, 1);
    }
  }

  initEmbeddedDataRow(question: QuestionModel, index: number) {
    if (question.QuestionType === "unknown") {
      question.QuestionType = "textbox";
      question.Action = "url";
    }

    let sourceName = question.QuestionName;
    if (question.Action == "question") {
      sourceName = question.Interview.findQuestion(question.DefaultValue).FullName;
    }

    const formGroup = this.fb.group({
      name: [question.QuestionName],
      dataType: [question.QuestionType],
      source: [question.Action],
      sourceName: [sourceName],
      dataValue: [question.InitialValue],
      metaData: [question.DefaultValue],
      questionId: [question.DefaultValue],
      questionData: [question],
      index: [index],
      analysis: [question.Fix]
    });

    formGroup.valueChanges.subscribe(x => {
      const index = x.index as number;
      if (this.isValid(index)) {
        if (index >= this._question.Questions.List.length) {
          this._question.Questions.List.push(new QuestionModel(this._question.Interview));
        }
        const question = this._question.Questions.List[index];
        if (question != null) {
          question.QuestionName = x.name ?? "";
          question.QuestionType = x.dataType ?? "";
          question.Action = x.source ?? "";
          question.InitialValue = x.dataValue ?? "";
          if (question.Action == "question") {
            question.DefaultValue = x.questionId ?? "";
          }
          else {
            question.DefaultValue = x.metaData ?? "";
          }

          question.Fix = x.analysis ?? false;
        }

        this._question.Questions.List[index] = question;
      }
    });

    return formGroup;
  }

  get dataArr(): FormArray {
    return this.dataFormGroup.get('embeddedOption') as FormArray;
  }

  lineItem(index: number): FormGroup {
    return this.dataArr.controls[index] as FormGroup;
  }

  loadData() {
    if (this.dataFormGroup === undefined) {
      return;
    }

    this.dataArr.clear();
    this.dataArr.controls.push(this.initEmbeddedDataRow(new QuestionModel(), 0));
    if (this._question === undefined) {
      return;
    }

    if (this._question.Questions.List.length === 0) {
      return;
    }

    this.dataArr.clear();
    let i = 0;
    this._question.Questions.List.forEach(question => {
      this.dataArr.push(this.initEmbeddedDataRow(question, i++));
    });
  }

  getFieldFormats(question: QuestionModel) {
    switch (question.QuestionType) {
      case "text":
        return ["Response", "Asked", "Answered"];
      case "mcma":
      case "mcsa":
        if (this.userFeatures?.QuestionEditorFeatures?.EditAnswerOptionFactors) {
          return ["Response", "Asked", "Answered", "Factor"];
        }

        return ["Response", "Asked", "Answered"];
      case "information":
      case "terminate":
        return ["Asked"];
    }

    return ["Response", "Asked", "Answered"];
  }

  questionSelected($event, question: QuestionModel, index: number, format: string) {
    // $event.stopPropagation();
    let form = this.lineItem(index);
    form.controls.questionId.setValue(question.QuestionId);
    form.controls.sourceName.setValue(question.FullName);
    form.controls.dataValue.setValue(format);
  }

}
