import { Component, OnInit, ViewChild, ComponentRef, ViewRef, ComponentFactoryResolver, ViewContainerRef, OnDestroy, ChangeDetectorRef, Renderer2 } from '@angular/core';
import { QuestionService, QuestionModel } from '../question.service';
import { InformationComponent } from './information/information.component';
import { McmaComponent } from './mcma/mcma.component';
import { McsaComponent } from './mcsa/mcsa.component';
import { QeBlockComponent } from './qe-block/qe-block.component';
import { MatDialog } from '@angular/material/dialog';
import { TextboxComponent } from './textbox/textbox.component';
import { EmailComponent } from './email/email.component';
import { QeGeocodeComponent } from './qe-geocode/qe-geocode.component';
import { BarcodeComponent } from './barcode/barcode.component';
import { IntegerComponent } from './integer/integer.component';
import { QeDateComponent } from './qe-date/qe-date.component';
import { CurrencyComponent } from './currency/currency.component';
import { QeTerminateComponent } from './qe-terminate/qe-terminate.component';
import { QeBooleanComponent } from './qe-boolean/qe-boolean.component';
import { QeRankingComponent } from './qe-ranking/qe-ranking.component';
import { MatrixComponent } from './matrix/matrix.component';
import { MaxdiffComponent } from './maxdiff/maxdiff.component';
import { MediaComponent } from './media/media.component';
import { RatingComponent } from './rating/rating.component';
import { SendEmailComponent } from './send-email/send-email.component';
import { SendSmsComponent } from './send-sms/send-sms.component';
import { UserlistComponent } from './userlist/userlist.component';
import { QeBucketComponent } from './qe-bucket/qe-bucket.component';
import { ImageMapComponent } from './image-map/image-map.component';
import { AdvancedRoutingComponent } from './advanced-routing/advanced-routing.component';
import { QuestionEditorComponent } from '../question-editor/question-editor.component';

@Component({
  selector: 'app-questions',
  templateUrl: './questions.component.html',
  styleUrls: ['./questions.component.css']
})
export class QuestionsComponent implements OnInit, OnDestroy {
  components: ComponentRef<any>[] = [];
  blankText = true;
  editor: QuestionEditorComponent | null = null;

  @ViewChild('appendHere', { static: false, read: ViewContainerRef }) target: ViewContainerRef | undefined;

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private resolver: ComponentFactoryResolver,
    private questionEventEmitterService: QuestionService,
    private renderer: Renderer2,
    public dialog: MatDialog
  ) {}

  ngOnDestroy(): void {
    this.questionEventEmitterService.subsVar?.unsubscribe();
  }

  ngOnInit() {
      this.questionEventEmitterService.subsVar = this.questionEventEmitterService.
        invokeAddNewComponentFunction.subscribe((result: any) => {
          if (result.questionType !== undefined && result.questionType !== "unknown") {
              switch (result.action) {
                case "after":
                case "before":
                  result = this.addNewComponent(result, result.model);
                  if (result.model && result.model.QuestionType == 'block') {
                    for (let j = 0; j < result.model.Questions.List.length; j++) {
                      let subQuestion = result.model.Questions.List[j];
                      subQuestion.Interview = this.editor.interview;
                      this.addNewComponent({
                        questionType: subQuestion.QuestionType,
                        populate: true,
                        action: "after",
                        index: subQuestion.Index,
                        displayIndex: subQuestion.DisplayIndex,
                        parentIndex: subQuestion.ParentIndex,
                        displayParentIndex: subQuestion.DisplayParentIndex
                      }, subQuestion);
                    }

                    this.editor.refresh();
                  }

                  this.selectQuestion(result.index, result.parentIndex, result.displayIndex, result.displayParentIndex);
                  break;
                case "delete":
                  this.deleteComponent(result);
                  this.selectQuestion(result.index, result.parentIndex, result.displayIndex, result.displayParentIndex);
                  break;
                case "copy":
                  result = this.addNewComponent(result);
                  this.selectQuestion(result.index, result.parentIndex, result.displayIndex, result.displayParentIndex);
                  break;
                case "select":
                  break;
              }
            }
        });
  }

  reload() {
    this.target?.clear();
    this.components = [];
    this.populate();
  }

  populate() {
    this.blankText = this.editor != undefined && this.editor.interview.Questions.length > 0;
    if (this.editor == undefined) {
      return;
    }

    for (let i = 0; i < this.editor.interview.Questions.length; i++) {
      let question = this.editor.interview.Questions[i];
      question.ParentIndex = -1;
      this.addNewComponent({
        questionType: question.QuestionType,
        populate: true,
        action: "after",
        index: question.Index,
        displayIndex: question.DisplayIndex,
        parentIndex: question.ParentIndex,
        displayParentIndex: question.DisplayParentIndex
      }, question);
      if (question.QuestionType === "block") {
        for (let j = 0; j < question.Questions.List.length; j++) {
          let subQuestion = question.Questions.List[j];
          this.addNewComponent({
            questionType: subQuestion.QuestionType,
            populate: true,
            action: "after",
            index: subQuestion.Index,
            displayIndex: subQuestion.DisplayIndex,
            parentIndex: subQuestion.ParentIndex,
            displayParentIndex: subQuestion.DisplayParentIndex
          }, subQuestion);
        }
      }
    }

    this.editor.index = this.editor.interview.Questions.length === 0 ? -1 : 0;
    this.editor.parentIndex = -1;
    const question = this.editor.interview.findQuestionByDisplayIndex(-1, 0);
    if (question) {
      this.selectQuestion(question.Index, question.ParentIndex, question.DisplayIndex, question.DisplayParentIndex);
      this.editor.index = question.Index;
      this.editor.parentIndex = question.ParentIndex;
    }

    this.editor.refresh();
  }

  refreshComponent(data: any) {
    this.addNewComponent(data);
  }

  deleteComponent(data: any) {
    let mainTarget = this.target;
    let childComponents = this.components;
    let noParent = true;
    if (data.displayParentIndex !== undefined && data.displayParentIndex > -1) {
      let block = this.components[data.displayParentIndex].instance as QeBlockComponent;
      noParent = false;
      mainTarget = block.target;
      childComponents = block.components;
    }

    mainTarget?.remove(data.displayIndex);
    childComponents.splice(data.displayIndex, 1);
    this.editor?.interview.deleteQuestion(data);
    if (this.editor == null) {
      return;
    }

    for (let i = data.displayIndex; i < childComponents.length; i++) {
      childComponents[i].instance.updateQuestionNumber();
    }

    if (noParent == false) {
      if (childComponents.length === 0) {
        this.editor.index = this.editor.parentIndex;
        this.editor.parentIndex = -1;
      }
      else {
        if (this.editor.index < childComponents.length) {
          // this.editor.index = -2;
        }
        else {
          if (childComponents.length > 0) {
            this.editor.index = childComponents[childComponents.length - 1].instance.model.Index;
          }
          else {
            this.editor.index = -1;
          }
        }
      }
    }
    else {
      if (this.editor.index < childComponents.length) {
        // this.editor.index = -2;
         // model = this.editor.interview.findQuestionByDisplayIndex(data.displayParentIndex, data.displayIndex);
      }
      else {
          if (childComponents.length > 0) {
            this.editor.index = childComponents[childComponents.length - 1].instance.model.Index;
          }
          else {
            this.editor.index = -1;
          }
      }
    }

    this.editor.refresh();
  }

  getQuestionComponent(model: QuestionModel) {
    let ref: ComponentRef<any>;
    if (model.DisplayParentIndex > -1) {
      const block = this.components[model.DisplayParentIndex].instance as QeBlockComponent;
      ref = block.components[model.DisplayIndex];
    }
    else {
      ref = this.components[model.DisplayIndex];
    }

    return ref.instance;
  }

  selectQuestion(index: number, parentIndex: number, displayIndex: number, displayParentIndex: number) {
    let temp: ComponentRef<any> | null = null;
    let subTemp: ComponentRef<any> | null = null
    for (let i = 0; i < this.components.length; i++) {
      const ref = this.components[i] as ComponentRef<any>;
      const block = ref.instance as QeBlockComponent;
      if (block.components) {
        for (let j = 0; j < block.components.length; j++) {
          const subRef = block.components[j] as ComponentRef<any>;
          let shouldPreview = true;
          if (displayParentIndex === -1) {
            shouldPreview = !(displayIndex === i && j === 0);
          }
          else {
            shouldPreview = !(displayParentIndex === i && displayIndex === j);
          }

          subRef.instance.preview = shouldPreview;
          if (this.editor != undefined && shouldPreview) {
            subRef.instance.interview = this.editor.interview;
          }
          else {
            temp = ref;
            subTemp = subRef;
          }
        }
      }
      else {
        const shouldPreview = displayIndex !== i || displayParentIndex !== -1;
        ref.instance.preview = shouldPreview;
        if (this.editor != undefined && shouldPreview) {
          ref.instance.interview = this.editor.interview;
        }
        else {
          temp = ref;
        }
      }
    }

    // this.changeDetectorRef?.detectChanges();
    if (subTemp != null) {
      let block = temp?.instance as QeBlockComponent;
      block.selectQuestion(index, parentIndex, displayIndex, displayParentIndex);
      return;
    }
    
    if (temp) {
      setTimeout(() => {
        try {
          temp?.location.nativeElement.scrollIntoView({
            behavior: "smooth",
            block: "nearest",
            inline: "nearest"
          });
        }
        catch (err) {
          let a = err;
        }
      }, 0);
    }
  }

  addNewComponent(data: any, question?: QuestionModel): any {
    if (data.model != undefined) {
      question = this.editor.interview.newQuestion(data);
      question.Banner = data.model.Banner;
      question.Questions = data.model.Questions;
      question.Categories = data.model.Categories;
      question.Parameters = data.model.Parameters;
    }

    let populate = data.populate;
    this.blankText = true;
    let childComponent: any | null = null;
    this.dialog.closeAll();
    switch (data.questionType) {
      case 'information':
        childComponent = this.resolver.resolveComponentFactory(InformationComponent);
          break;
      case 'mcsa':
        childComponent = this.resolver.resolveComponentFactory(McsaComponent);
          break;
      case 'mcma':
        childComponent = this.resolver.resolveComponentFactory(McmaComponent);
          break;
      case 'block':
        childComponent = this.resolver.resolveComponentFactory(QeBlockComponent);
          break;
      case 'textbox':
        childComponent = this.resolver.resolveComponentFactory(TextboxComponent);
          break;
      case 'email':
        childComponent = this.resolver.resolveComponentFactory(EmailComponent);
        break;
      case 'geocode':
        childComponent = this.resolver.resolveComponentFactory(QeGeocodeComponent);
          break;
      case 'barcode':
        childComponent = this.resolver.resolveComponentFactory(BarcodeComponent);
          break;
      case 'boolean':
        childComponent = this.resolver.resolveComponentFactory(QeBooleanComponent);
          break;
      case 'integer':
        childComponent = this.resolver.resolveComponentFactory(IntegerComponent);
          break;
      case 'media':
        childComponent = this.resolver.resolveComponentFactory(MediaComponent);
          break;
      case 'date':
        childComponent = this.resolver.resolveComponentFactory(QeDateComponent);
          break;
      case 'currency':
        childComponent = this.resolver.resolveComponentFactory(CurrencyComponent);
          break;
      case 'rating':
        childComponent = this.resolver.resolveComponentFactory(RatingComponent);
          break;
      case 'matrix':
        childComponent = this.resolver.resolveComponentFactory(MatrixComponent);
          break;
      case 'maxDiff':
        childComponent = this.resolver.resolveComponentFactory(MaxdiffComponent);
          break;
      case 'imageMap':
        childComponent = this.resolver.resolveComponentFactory(ImageMapComponent);
          break;
      case 'bucket':
        childComponent = this.resolver.resolveComponentFactory(QeBucketComponent);
          break;
      case 'ranking':
        childComponent = this.resolver.resolveComponentFactory(QeRankingComponent);
          break;
      case 'sendEmail':
        childComponent = this.resolver.resolveComponentFactory(SendEmailComponent);
          break;
      case 'userlist':
        childComponent = this.resolver.resolveComponentFactory(UserlistComponent);
          break;
      case 'advRouting':
        childComponent = this.resolver.resolveComponentFactory(AdvancedRoutingComponent);
          break;
      case 'sendSMS':
        childComponent = this.resolver.resolveComponentFactory(SendSmsComponent);
          break;
      case 'terminate':
        childComponent = this.resolver.resolveComponentFactory(QeTerminateComponent);
          break;
      case 'advanced':
        return;
    }

    if (childComponent == null || this.editor == undefined) {
      return data;
    }

    if (data.action == "replace") {
      question = this.editor.interview.findQuestionByIndex(data.parentIndex, data.index);
    }
    else {
      if (question == undefined) {
        question = this.editor.interview.newQuestion(data);
      }
    }

    let mainTarget = this.target;
    let noParent = true;
    let childComponents = this.components;
    if (data.questionType != "block") {
      if (data.displayParentIndex !== undefined && data.displayParentIndex >= -1 && this.components[data.displayParentIndex]) {
        let block = this.components[data.displayParentIndex].instance as QeBlockComponent;
        noParent = false;
        mainTarget = block.target;
        childComponents = block.components;
      }
    }

    if (noParent) {
      this.editor.parentIndex = -1;
    }
    else {
      this.editor.parentIndex = data.ParentIndex;
    }

    if (data.displayIndex == undefined || data.displayIndex == -1) {
      const componentRef = mainTarget?.createComponent(childComponent) as ComponentRef<any>;
      componentRef.instance.interview = this.editor.interview;
      componentRef.instance.model = question;
      componentRef.instance.userFeatures = this.editor.userFeatures;
      componentRef.changeDetectorRef.detectChanges();
      childComponents.push(componentRef);
      this.editor.index = componentRef.instance.model.Index;
      this.editor.refresh();
      return this.updateData(componentRef, data);
    }

    if (data.action == "replace") {
      let tempTarget = this.target;
      let oldComponents;
      if (!noParent) {
        let block = this.components[data.displayParentIndex].instance as QeBlockComponent;
        tempTarget = block.target;
        oldComponents = block.components;
      }
      else {
        oldComponents = this.components;
      }

      const index = tempTarget?.indexOf(oldComponents[data.displayIndex].hostView);
      tempTarget?.detach(index);
      const componentRef = tempTarget?.createComponent(childComponent, data.displayIndex) as ComponentRef<any>;
      componentRef.instance.interview = this.editor.interview;
      componentRef.instance.model = question;
      componentRef.instance.userFeatures = this.editor.userFeatures;
      componentRef.instance.preview = false;
      componentRef.changeDetectorRef.detectChanges();
      oldComponents.splice(data.displayIndex, 1, componentRef);
      this.editor.index = componentRef.instance.model.Index;
      this.editor.parentIndex = componentRef.instance.model.ParentIndex;
      this.editor.refresh();
      data.action = "select";
      return data;
    }

    if (data.action === "before") {
      const componentBefore = mainTarget?.createComponent(childComponent, data.displayIndex) as ComponentRef<any>;
      componentBefore.instance.interview = this.editor.interview;
      componentBefore.instance.userFeatures = this.editor.userFeatures;
      componentBefore.instance.model = question;
      childComponents.splice(data.displayIndex, 0, componentBefore);
      this.editor.index = -2;
    }
    else {
      if ((data.displayIndex + 1) >= childComponents.length) {
        const componentEnd = mainTarget?.createComponent(childComponent) as ComponentRef<any>;
        componentEnd.instance.interview = this.editor.interview;
        componentEnd.instance.userFeatures = this.editor.userFeatures;
        componentEnd.instance.model = question;
        if (populate && data.questionType === "block") {
          componentEnd.changeDetectorRef.detectChanges();
        }
        else {
          componentEnd.instance.model.preview = true;
        }

        childComponents.push(componentEnd);
        this.editor.parentIndex = data.parentIndex;
        this.editor.index = componentEnd.instance.model.Index;
        data.index = componentEnd.instance.model.Index;
        data.displayIndex = componentEnd.instance.model.DisplayIndex;
        this.editor.refresh();
        data.action = "select";
        return data;
      }

      if (childComponent == null) {
        return data;
      }

      const componentAfter = mainTarget?.createComponent(childComponent, data.displayIndex + 1) as ComponentRef<any>;
      componentAfter.instance.interview = this.editor.interview;
      componentAfter.instance.userFeatures = this.editor.userFeatures;
      componentAfter.instance.model = question;
      this.editor.index = data.index + 1;
      childComponents.splice(data.displayIndex + 1, 0, componentAfter);
      data.index++;
      data.displayIndex++;
    }

    this.editor.refresh();
    return data;
  }

  moveItemInArray(parentIndex: number, previousIndex: number, currentIndex: number) {
    let tempTarget = this.target;
    let tempComponents = this.components;
    if (parentIndex > -1) {
      let block = this.components[parentIndex].instance as QeBlockComponent;
      tempTarget = block.target;
      tempComponents = block.components;
    }

    const index = tempTarget?.indexOf(tempComponents[previousIndex].hostView);
    tempTarget?.detach(index);
    let temp = currentIndex;
    if (previousIndex < currentIndex) {
      temp--;
    }

    tempTarget?.insert(tempComponents[previousIndex].hostView, currentIndex);
    this.array_move(tempComponents, previousIndex, currentIndex);
    if (previousIndex < currentIndex) {
      for (let i = previousIndex; i <= currentIndex; i++) {
        tempComponents[i].instance.refresh();
      }
    }
    else {
      for (let i = currentIndex; i <= previousIndex; i++) {
        tempComponents[i].instance.refresh();
      }
    }
  }

  moveItemOutOfBlock(previousParentIndex: number, previousIndex: number, targetParentIndex: number, targetIndex: number) {
    // Remove from the current position
    let tempTarget = this.target;
    let tempComponents = this.components;
    if (previousParentIndex > -1) {
      let block = this.components[previousParentIndex].instance as QeBlockComponent;
      tempTarget = block.target;
      tempComponents = block.components;
    }

    tempTarget?.detach(previousIndex);
    let component = tempComponents[previousIndex];
    tempComponents.splice(previousIndex, 1);

    // Could have shifted everything before we can insert so just check it out
    if (previousParentIndex === -1) {
      if (previousIndex < targetParentIndex) {
        targetParentIndex--;
      }
    }

    // Add into the new position
    tempTarget = this.target;
    tempComponents = this.components;
    if (targetParentIndex > -1) {
      let block = this.components[targetParentIndex].instance as QeBlockComponent;
      tempTarget = block.target;
      tempComponents = block.components;
    }

    tempTarget?.insert(component.hostView, targetIndex);
    tempComponents.splice(targetIndex, 0, component);
    for (let i = 0; i < tempComponents.length; i++) {
      tempComponents[i].instance.updateQuestionNumber();
    }
  }

  updateData(componentRef: any, data: any): any {
    data.index = componentRef.instance.model.Index;
    data.parentIndex = componentRef.instance.model.ParentIndex;
    data.displayIndex = componentRef.instance.model.DisplayIndex;
    data.displayParentIndex = componentRef.instance.model.DisplayParentIndex;
    return data;
  }

  array_move(arr: any, old_index: number, new_index: number) {
    while (old_index < 0) {
        old_index += arr.length;
    }
    while (new_index < 0) {
        new_index += arr.length;
    }
    if (new_index >= arr.length) {
        var k = new_index - arr.length + 1;
        while (k--) {
            arr.push(undefined);
        }
    }
    arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
    return arr; // for testing purposes
  }
}
