import { Component, OnInit } from '@angular/core';
import { FormArray, Validators, FormBuilder, FormGroup } from '@angular/forms';
import { BaseQuestion, DataModel, InterviewService, QuestionService } from '../../question.service';

@Component({
  selector: 'app-email',
  templateUrl: './email.component.html',
  styleUrls: ['./email.component.css']
})
export class EmailComponent extends BaseQuestion implements OnInit {
  constructor(private qs: QuestionService, private is: InterviewService) {
    super(qs, is, undefined, undefined, new DataModel("email"));
  }

  public emailFormGroup: FormGroup = new FormGroup({});

  ngOnInit() {
    this.emailFormGroup = this.fb.group({
      emailFormArray: this.fb.array([this.initQuestion()])
    });
  }

  get questionArr(): FormArray | null {
    if (this.emailFormGroup) {
      return this.emailFormGroup.get('emailFormArray') as FormArray;
    }

    return null;
  }

  onSubmit(): void {
  }
}
