import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class GoogletranslateService {

  url:string = 'https://translation.googleapis.com/language/translate/v2?key=';
  key: string = 'AIzaSyB72Ius8fGxgo48vW3VxGqLFmzdIlVGOr8';

  constructor(private http: HttpClient) {
  }

  translate(obj: GoogleObj) {
    return this.http.post(this.url + this.key, obj);
  }
}

export interface GoogleObj {
  q: string[];
  target: string;
}
