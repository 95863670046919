import { Component, OnInit, Input, ViewChildren, QueryList, AfterViewInit, AfterContentInit } from '@angular/core';
import { AnswerModel } from "../../models/AnswerModel";
import { CategoryComponent } from '../category/category.component';

@Component({
  selector: 'app-categorical',
  templateUrl: './categorical.component.html',
  styleUrls: ['./categorical.component.css']
})
export class CategoricalComponent implements OnInit, AfterViewInit, AfterContentInit {
  display: boolean = false;
  radioButtons: boolean = false;
  checkBoxes: boolean = false;
  dropDown: boolean = false;
  dropDownMultiple: boolean = false;
  buttonsMultiple: boolean = false;
  buttons: boolean = false;
  @ViewChildren('category') categories: QueryList<CategoryComponent> | undefined;

  dropdownSelected: string = "";
  dropdownMultiSelected: string[] = [];

  constructor() { }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    Promise.resolve(null).then(() => {
      this.populateForm();
    });
  }

  ngAfterContentInit(): void {
  }

  @Input()
  get question(): any {
    return this._question;
  }
  set question(value: any) {
    this._question = value;
    if (this._question) {
      Promise.resolve(null).then(() => {
        this.populateForm();
      });
    }
  }
  private _question: any;

  private populateForm() {
    if (this._question.Style.Control.Type === 4 || this._question.Style.Control.Type === 0x000A) {
      if (this._question.Validation.MinValue === 1 && this._question.Validation.MaxValue === 1) {
        if (this._question.Style.Control.Type === 4) {
          this.dropDown = true;
        }
        else {
          this.buttons = true;
        }

        if (this._question.Response.Value) {
          this.dropdownSelected = this._question.Response.Value.replace("{", "").replace("}", "");
        }
      }
      else {
        if (this._question.Style.Control.Type === 4) {
          this.dropDownMultiple = true;
        }
        else {
          this.buttonsMultiple = true;
        }

        if (this._question.Response.Value) {
          this.dropdownMultiSelected = this._question.Response.Value.replace("{", "").replace("}", "").split(',');
        }
      }
    }

    this.radioButtons = this._question.Style.Control.Type === 6;
    this.checkBoxes = this._question.Style.Control.Type === 7;
    this.display = true;
  }

  get answers(): AnswerModel[] {
    let values: string[] = [];
    const model: AnswerModel[] = [];
    if (this.dropDown) {
      values.push(this.dropdownSelected);
    }
    else if (this.dropDownMultiple) {
      values = this.dropdownMultiSelected;
    }
    else {
      if (this.categories != null) {
        for (let i = 0; i < this.categories.length; i++) {
          let category = this.categories.toArray()[i];
          for (let j = 0; j < category.answers.length; j++) {
            let answer = category.answers[j];
            switch (answer.value) {
              case "true":
                values.push(answer.name);
                break;
              case "false":
                break;
              default:
                model.push(answer);
                break;
            }
          }
        }
      }
    }

    if (values) {
      model.push(new AnswerModel(this._question.QuestionFullName, values.toString()));
    }

    return model;
  }

  get valid(): boolean {
    return true;
  }

  onExclusive(index: number) {
    let mcsa = this._question.Validation.MinValue === 1 && this._question.Validation.MaxValue === 1;
    let answers = this.answers;
    let answer = answers[this.answers.length - 1];
    let resultString = answer.value;
    let results = resultString.split(",");
    let containsOther = this.answers.length > 1;
    let temp = this._question.Categories.Items[index].Attributes & 1;
    if (temp === 1 || mcsa) {
      this._question.Response.Value = this._question.Categories.Items[index].Name;
    }
    else {
      for (let i = 0; i < this._question.Categories.Items.length; i++) {
        if (i !== index) {
          let temp = this._question.Categories.Items[i].Attributes & 1;
          if (temp === 1) {
            for (let j = 0; j < results.length; j++) {
              if (this._question.Categories.Items[i].Name === results[j]) {
                results.splice(j, 1);
                break;
              }
            }
          }
        }
      }


      this._question.Response.Value = results;
    }

    answers.splice(this.answers.length - 1, 1);
    if (containsOther) {
      answers.forEach(result => {
        let name = result.name.split(".");
        let option = name[name.length - 2];
        for (let i = 0; i < this._question.Categories.Items.length; i++) {
          if (this._question.Categories.Items[i].Name === option) {
            this._question.Categories.Items[i].OtherQuestion.Response.Value = result.value;
          }
        }
      })
    }

    this.categories?.forEach(category => {
      category.answers = "dummy value";
    });
  }
}
