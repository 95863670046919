import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { tap } from 'rxjs/operators';
import { KeywordModel, SmsService } from "../sms.Service";
import { Observable } from 'rxjs';

@Component({
  selector: 'app-sms-features',
  templateUrl: './sms-features.component.html',
  styleUrls: ['./sms-features.component.css']
})
export class SmsFeaturesComponent implements OnInit, OnDestroy {
  tempKeywords: Observable<KeywordModel[]>;
  keywords: KeywordModel[] = [];
  displayedColumns: string[] = ['edit', 'Code', 'StartDate', 'FinishDate'];
  formGroup: FormGroup;
  panelId = 0;

  constructor(
    private smsService: SmsService
  ) {
    this.tempKeywords = this.smsService.getKeywords().pipe(tap<KeywordModel[]>(
      result => {
        this.keywords = result;
      }));
    this.formGroup = new FormGroup({
      userName: new FormControl("", [Validators.required]),
      firstName: new FormControl("", [Validators.required]),
      lastName: new FormControl(),
      emailAddress: new FormControl(),
      mobileNumber: new FormControl(),
      isDeleted: new FormControl(),
      createdDate: new FormControl(),
      lastUpdatedDate: new FormControl(),
      roles: new FormControl(),
      status: new FormControl(),
      sharedKey: new FormControl()
    });
  }

  selectedFile: File | null = null;

  ngOnInit(): void {
  }
  
  ngOnDestroy() {
  }

  message() {
    return;
  }

  openKeywordDialog() {

  }

  onSubmit() {
    if (this.formGroup.invalid) {
      return;
    }
  }
}
