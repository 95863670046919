<h2>Payment</h2>
<mat-stepper #stepper orientation="vertical" [linear]="true" (selectionChange)="selectionChanged($event)" (animationDone)="animationDone()">
  <mat-step [stepControl]="billingForm" [completed]="false">
    <form [formGroup]="billingForm">
      <ng-template matStepLabel>Billing Details</ng-template>
      <div>
        &nbsp;
      </div>
      <div>
        <mat-form-field appearance="outline">
          <mat-label>First Name</mat-label>
          <input matInput placeholder="First Name" formControlName="FirstName" required>
        </mat-form-field>&nbsp;
        <mat-form-field appearance="outline">
          <mat-label>Last Name</mat-label>
          <input matInput placeholder="Last Name" formControlName="LastName" required>
        </mat-form-field>
      </div>
      <div>
        <app-country-combo #countryCombo [required]="true" label="Country" [selected]="countrySelected()" (selectedChange)="country=$event"></app-country-combo>
      </div>
      <div>
        <mat-form-field appearance="outline" class="cc-pmnt-form-field">
          <mat-label>Email Address</mat-label>
          <input matInput placeholder="Email Address" formControlName="EmailAddress" required>
        </mat-form-field>
      </div>
      <div>
        <mat-form-field appearance="outline">
          <mat-label>Address</mat-label>
          <input matInput placeholder="Address" formControlName="address">
        </mat-form-field>&nbsp;
      </div>
      <div>
        <mat-form-field appearance="outline">
          <mat-label>Town</mat-label>
          <input matInput placeholder="Town" formControlName="town">
        </mat-form-field>&nbsp;
        <mat-form-field appearance="outline">
          <mat-label>County</mat-label>
          <input matInput placeholder="County" formControlName="county">
        </mat-form-field>
      </div>
      <div>
        <mat-form-field appearance="outline">
          <mat-label>Post Code</mat-label>
          <input matInput placeholder="Post Code" formControlName="postcode">
        </mat-form-field>&nbsp;
      </div>
      <div>
        <button mat-button (click)="showBusiness = !showBusiness">
          Optional Business Information
          <mat-icon>{{ showBusiness ? "expand_less" : "expand_more" }}</mat-icon>
        </button>
      </div>
      <div [hidden]="!showBusiness">
        <div>
          <mat-form-field appearance="outline">
            <mat-label>Company Name</mat-label>
            <input matInput placeholder="Company Name" formControlName="company">
          </mat-form-field>&nbsp;
          <mat-form-field appearance="outline">
            <mat-label>Purchase Order Number</mat-label>
            <input matInput placeholder="Purchase Order Number" formControlName="ponumber">
          </mat-form-field>
        </div>
        <div>
          <mat-form-field appearance="outline">
            <mat-label>Alternate billing email</mat-label>
            <input matInput placeholder="Alternate billing email" formControlName="billingemail">
          </mat-form-field>&nbsp;
        </div>
        <div>
          <mat-form-field appearance="outline">
            <mat-label>VAT Registration number</mat-label>
            <input matInput placeholder="VAT Reg No." formControlName="vatregno">
          </mat-form-field>&nbsp;
        </div>
      </div>
      <div>
        <button mat-button (click)="validateBillingDetails()">Next</button>
      </div>
    </form>
  </mat-step>
  <mat-step [stepControl]="paymentForm" [completed]="true" [editable]="false">
    <form [formGroup]="paymentForm">
      <ng-template matStepLabel>
        Payment Method
        <mat-icon>lock</mat-icon>
      </ng-template>
      <mat-tab-group #paymentTab>
        <mat-tab label="Credit or Debit Card">
          <div class="fullWidth column-flex">
            <div>
              &nbsp;
            </div>
            <div>
              <mat-form-field appearance="outline" class="cc-pmnt-form-field">
                <mat-label>Name on card</mat-label>
                <input matInput placeholder="Name on card" formControlName="name">
              </mat-form-field>&nbsp;
            </div>
            <div class="halfWidth">
              <div id="cardcontainer"></div>
            </div>
          </div>
        </mat-tab>
        <mat-tab *ngIf="isCreditCustomer" label="Invoice">
          <mat-form-field appearance="outline">
            <mat-label>Address</mat-label>
            <input matInput formControlName="secondCtrl" placeholder="Ex. 1 Main St, New York, NY">
          </mat-form-field>
        </mat-tab>
      </mat-tab-group>
      <div>
        <button mat-button (click)="validatePayment()">Next</button>
      </div>
    </form>
  </mat-step>
  <mat-step [stepControl]="reviewForm" completed="true" [editable]="false">
    <form [formGroup]="reviewForm">
      <ng-template matStepLabel>Review Order</ng-template>
      <app-pricing-summary [showBalance]="false" [estimate]="estimate" [panel]="panel" ></app-pricing-summary>
      <p>You are now done.</p>
      <div>
        <button mat-flat-button color="primary" (click)="pay()">Pay</button>
      </div>
      <div>
        <p>
          <mat-icon>lock</mat-icon> Secure 128-bit SSL/TLS encrypted payment
        </p>
        <p>
          You will be billed £{{ estimate.Total | number:'1.2' }} when you click <strong>Pay</strong>.
        </p>
        <p>
          By clicking <strong>Pay</strong>, you agree to our Terms of Use and Privacy Policy.
        </p>
      </div>
    </form>
  </mat-step>
</mat-stepper>
