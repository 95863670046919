<div [formGroup]="formGroup">
  <div class="fieldRowWithPadding">
    <div>
      <div style="width:100%">
        <app-user-org-search [users]="users" label="Search Users/Organizations"></app-user-org-search>
      </div>
      <div>
        <div class="fieldRowWithPadding">
          <div>
            <app-advanced-timings placeholder="From" [(value)]="startDate" [timezone]="timezone"></app-advanced-timings>
          </div>
          <div>
            <app-advanced-timings placeholder="To" label="-" [(value)]="endDate" [timezone]="timezone"></app-advanced-timings>
          </div>
        </div>
      </div>
      <div>
        <mat-button-toggle-group formControlName="MessageType" name="messageType" (change)="setPivotMeasure()">
          <mat-button-toggle value="Segments">Segments</mat-button-toggle>
          <mat-button-toggle value="Messages">Messages</mat-button-toggle>
        </mat-button-toggle-group>
      </div>
    </div>
    <div>
      &nbsp;
    </div>
    <div>
      <div cdkDropList class="example-list" (cdkDropListDropped)="drop($event)">
        @for (aggregator of aggregators; track aggregator) {
        <div class="example-box" cdkDrag>
          <mat-checkbox [formControlName]="aggregator" (click)="setAggregators()">{{aggregator}}</mat-checkbox>
        </div>
        }
      </div>&nbsp;
    </div>
    <div>
      &nbsp;
    </div>
    <div>
      <div>
        <button mat-raised-button color="primary" (click)="searchForMessages()">Search</button>&nbsp;
      </div>
    </div>
  </div>
</div>
<div>
  &nbsp;
</div>
<div *ngIf="dataReady" class="outer-table-container">
  <app-pivot-table #pivotTable [toolbar]="true"
                   [width]="'100%'"
                   [height]="500"
                   [report]="results"
                   (beforetoolbarcreated)="checkToolbar($event)"
                   (celldoubleclick)="cellClick($event)"
                   (cellclick)="cellClickEmpty()">
    WebDataRocks will appear here
  </app-pivot-table>
</div>
<ng-template #messageList>
    <div class="user-details-dialog-container">
      <div class="leftFloat">
        <h3>Message List</h3>
      </div>
      <div class="rightFloat">
        <button mat-icon-button matDialogClose="no" matTooltip="Close" matTooltipPosition="below">
          <mat-icon class="user-details-dialog-close-icon">close</mat-icon>
        </button>
      </div>
    </div>
    <div class="clearBoth mt-5"></div>
      <div class="outer-table-container">
        <div class="inbox-table-container custom-scroll-bar">
          <mat-table [dataSource]="messages" mat-table>
            <ng-container matColumnDef="TranDate">
              <mat-header-cell *matHeaderCellDef> Tran Date </mat-header-cell>
              <mat-cell *matCellDef="let element"> {{element.DateAdded | date:'medium'}} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="Account">
              <mat-header-cell *matHeaderCellDef> Account </mat-header-cell>
              <mat-cell *matCellDef="let element"> {{element.Account}} ({{element.OwnerId}}) </mat-cell>
            </ng-container>

            <ng-container matColumnDef="Survey">
              <mat-header-cell *matHeaderCellDef> Survey </mat-header-cell>
              <mat-cell *matCellDef="let element"> {{element.Survey?.Name}} <span *ngIf="isAdminUser || isImpersonating()">({{element.Survey?.SurveyID}})</span>  </mat-cell>
            </ng-container>

            <ng-container matColumnDef="Country">
              <mat-header-cell *matHeaderCellDef> Country </mat-header-cell>
              <mat-cell *matCellDef="let element"> {{element.MobileZone?.Country}} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="Source">
              <mat-header-cell *matHeaderCellDef> Source </mat-header-cell>
              <mat-cell *matCellDef="let element"> {{element.Source}} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="Destination">
              <mat-header-cell *matHeaderCellDef> Destination </mat-header-cell>
              <mat-cell *matCellDef="let element"> {{element.Destination}} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="Inbound">
              <mat-header-cell *matHeaderCellDef> Direction </mat-header-cell>
              <mat-cell *matCellDef="let element"> {{element.Inbound ? 'Inbound' : 'Outbound'}} </mat-cell>
            </ng-container>
            <ng-container matColumnDef="Message">
              <mat-header-cell *matHeaderCellDef> Message </mat-header-cell>
              <mat-cell *matCellDef="let element"> {{element.Message}} </mat-cell>
            </ng-container>
            <ng-container matColumnDef="Type">
              <mat-header-cell *matHeaderCellDef> Type </mat-header-cell>
              <mat-cell *matCellDef="let element"> {{getType(element)}} </mat-cell>
            </ng-container>
            <ng-container matColumnDef="Segments">
              <mat-header-cell *matHeaderCellDef> Seg </mat-header-cell>
              <mat-cell *matCellDef="let element"> {{element.Segments}} </mat-cell>
            </ng-container>
            <ng-container matColumnDef="Status">
              <mat-header-cell *matHeaderCellDef> Status </mat-header-cell>
              <mat-cell *matCellDef="let element"> {{element.ReceiptStatusMessage}} </mat-cell>
            </ng-container>
            <mat-header-row *matHeaderRowDef="displayedColumns;sticky:true"></mat-header-row>
            <mat-row class="msg-hist-element-row" *matRowDef="let row; columns: displayedColumns; let i = index;" (click)="showMessageDetails(i, row)"></mat-row>
          </mat-table>
          <mat-paginator [pageSize]="pageSize" [length]="total"></mat-paginator>
        </div>
      </div>
</ng-template>
<ng-template #messageDetail>
  <div class="msg-hist-dtl-container">
    <div class="user-details-dialog-container">
      <div class="leftFloat">
        <h3>Message Detail</h3>
      </div>
      <div class="rightFloat">
        <button *ngIf="index > 0" mat-icon-button matTooltip="Previous" matTooltipPosition="below" (click)="previousMessage()">
          <mat-icon class="user-details-dialog-close-icon">arrow_back</mat-icon>
        </button>
        <button *ngIf="index + 1 < messages.length" mat-icon-button matTooltip="Next" matTooltipPosition="below" (click)="nextMessage()">
          <mat-icon class="user-details-dialog-close-icon">arrow_forward</mat-icon>
        </button>
        <button mat-icon-button matDialogClose="no" matTooltip="Close" matTooltipPosition="below">
          <mat-icon class="user-details-dialog-close-icon">close</mat-icon>
        </button>
      </div>
    </div>
    <div class="clearBoth mt-5"></div>
    <div class="custom-scroll-bar compose-msg-main-div">
      <div class="msg-hist-dtl-content">
        <div class="user-details-invitation-container">
          <div><strong>Tran Id:</strong>&nbsp;</div><div>{{data.TranID}} (MS{{data.MessageManagerId}})</div>
        </div>
        <div class="user-details-invitation-container">
          <div>
            <strong>
              Transaction Date:
            </strong>&nbsp;
          </div>
          <div>
            {{data.DateAdded | date:'medium'}}
          </div>
          <div *ngIf="isAdminUser || isImpersonating()">&nbsp;(<strong>TranDate: </strong></div>
          <div *ngIf="isAdminUser || isImpersonating()">&nbsp;{{data.TranDate | date:'medium'}})</div>
        </div>
        <div *ngIf="isAdminUser || isImpersonating()" class="user-details-invitation-container">
          <div>
            <strong>
              Account:
            </strong>&nbsp;
          </div><div>
            {{data.Account}} ({{data.OwnerId}})
          </div>
        </div>
        <div class="user-details-invitation-container">
          <div>
            <strong>
              Country:
            </strong>&nbsp;
          </div><div>
            {{data.MobileZone?.Country}} ({{data.MobileZone?.CountryCode}})
          </div>
        </div>
        <div *ngIf="data.SurveyId > 0">
          <div class="user-details-invitation-container">
            <div>
              <strong>
                Survey:
              </strong>&nbsp;
            </div><div>
              {{data.Survey?.Name}}
            </div>
          </div>
        </div>
        <div class="user-details-invitation-container">
          <div>
            <strong>
              From:
            </strong>&nbsp;
          </div><div>
            {{data.Source}}
          </div>
        </div>
        <div class="user-details-invitation-container">
          <div>
            <strong>
              To:
            </strong>&nbsp;
          </div><div>
            {{data.Destination}}
          </div>
        </div>
        <div class="user-details-invitation-container">
          <div>
            <strong>
              Direction:
            </strong>&nbsp;
          </div><div>
            {{data.Inbound ? 'Inbound' : 'Outbound'}}
          </div>
        </div>
        <div>
          <div>
            <strong>
              Message:
            </strong>&nbsp;
          </div><div class="alert alert-primary" role="alert">
            <div class="msg-hist-msg-content-div">
              {{data.Message}}
            </div>
          </div>
        </div>
        <div class="user-details-invitation-container">
          <div>
            <strong>
              Segments:
            </strong>&nbsp;
          </div>
          <div>
            {{data.Segments}}
          </div>
        </div>
        <div class="user-details-invitation-container">
          <div>
            <strong>
              Lookup:
            </strong>&nbsp;
          </div>
          <div>
            {{data.Lookup}}
          </div>
        </div>
        <div class="user-details-invitation-container">
          <div>
            <strong>
              Test:
            </strong>&nbsp;
          </div>
          <div>
            {{data.Test}}
          </div>
        </div>
        <div class="user-details-invitation-container">
          <div>
            <strong>
              Number Type:
            </strong>&nbsp;
          </div>
          <div>
            {{data.NumberTypeString}}
          </div>
        </div>
        <div class="user-details-invitation-container">
          <div>
            <strong>
              Status:
            </strong>&nbsp;
          </div><div>
            {{data.ReceiptStatusMessage}}
          </div>
        </div>
        <div *ngIf="data.Metadata?.length > 0">
          <div>
            <strong>
              Metadata:
            </strong>&nbsp;
          </div>
        </div>
        <div *ngFor="let metadata of data.Metadata" class="user-details-invitation-container">
          <div>
            <strong>
              {{metadata.Name}}:
            </strong>&nbsp;
          </div>
          <div>
            {{metadata.Value}}
          </div>
        </div>
        <div>
          <div *ngIf="isAdminUser || isImpersonating()" class="user-details-invitation-container">
            <div>
              <strong>
                Supplier:
              </strong>&nbsp;
            </div><div>
              {{data.Supplier}}
            </div>
          </div>
          <div class="user-details-invitation-container">
            @if(isAdminUser || isImpersonating()) {
            <strong>
              Supplier ReceiptID &amp; Status:
            </strong>&nbsp;
            } @else {
            <strong>
              ReceiptID &amp; Status:
            </strong>
            }
            <div>
              <div *ngFor="let receipt of data.Receipts">
                @if(receipt?.ReceiptID == data?.Receipt?.ReceiptID) {
                <strong>{{getReceipt(receipt)}}&nbsp;{{receipt?.StatusForReport}}&nbsp;({{receipt?.LastUpdated | date:'medium'}})</strong>
                } @else {
                {{getReceipt(receipt)}}&nbsp;{{receipt?.StatusForReport}}&nbsp;({{receipt?.LastUpdated | date:'medium'}})
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>

