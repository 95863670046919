import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { AfterViewInit, Component, ElementRef, EventEmitter, forwardRef, HostBinding, Input, OnDestroy, Optional, Output, Self, ViewChild } from '@angular/core';
import { ControlValueAccessor, FormBuilder, FormGroup, NgControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Subject } from 'rxjs';
import { FocusMonitor } from '@angular/cdk/a11y';
import { QuestionModel } from '../../question.service';
import { Field } from '../../process.Service';
import { QuillEditorComponent } from 'ngx-quill';
import ImageUploader from './addons/quill-image-uploader';
import PipeSelector from './addons/PipeSelector';
import { MediaService } from '../../media.Service';
import { HttpEventType } from '@angular/common/http';
import { baseUrl } from '../../../environments/environment';
import { Sources } from 'quill';

@Component({
  selector: 'diy-editor',
  templateUrl: './editor.component.html',
  styleUrls: ['./editor.component.css'],
  providers: [
    { provide: NG_VALUE_ACCESSOR, multi: true, useExisting: forwardRef(() => EditorComponent) }]
})
export class EditorComponent implements ControlValueAccessor, AfterViewInit, OnDestroy {
  static nextId = 0;

  formGroup: FormGroup;
  controlType = 'diy-editor';

  quillModules: any;

  customModules = [
    { path: 'modules/imageUploader', implementation: ImageUploader },
    { path: 'modules/formula', implementation: PipeSelector }
  ];

  @ViewChild('editor') public editor: QuillEditorComponent | undefined;

  constructor(fb: FormBuilder, private fm: FocusMonitor, private elRef: ElementRef, public mediaService: MediaService
  ) {
    this.quillModules = {
      toolbar: [
        [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
        ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
        [{ 'list': 'ordered' }, { 'list': 'bullet' }],
        [{ 'script': 'sub' }, { 'script': 'super' }],     // superscript/subscript
        [{ 'indent': '-1' }, { 'indent': '+1' }],         // outdent/indent
        [{ 'direction': 'rtl' }],                         // text direction
        [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
        [{ 'align': [] }],
        ['blockquote', 'code-block'],
        ['clean'],                                        // remove formatting button
        ['link', 'image', 'video'],                       // link and image, video
        ['formula']
      ],
        imageUploader: {
        upload: (baseUrl, file) => {
          return new Promise((resolve, reject) => {
            setTimeout(() => {
              this.mediaService.uploadAnyFile(file).subscribe(
                result => {
                  resolve(baseUrl.substring(0, baseUrl.length - 4) + "resources/" + result.Name);
                }, error => {
                  let errorMessage = <any>error;
                  if (errorMessage != null) {
                    reject(errorMessage);
                  }
                });
            });
          });
        }
      },
      formula: {
      }

    };
    this.formGroup = fb.group({ 'value': '' })
    this.formGroup.valueChanges.subscribe(x => {
      this._value = this.formGroup.controls.value.value;
      if (this.onChange) {
        this.onChange(this._value);
      }
    });
  }

  ngAfterViewInit(): void {
  }

  selectField(field: Field) {
    if (this.editor == undefined) {
      return;
    }

    let selection = this.editor.quillEditor.getSelection();
    let fieldString = "{" + field.Name + "}";
    this.editor.quillEditor.insertText(selection.index, fieldString);
    this.formGroup.controls.value.setValue(this.editor.quillEditor.root.innerHTML);
  }

  writeValue(obj: any): void {
    this.value = obj;
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }
  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  ngOnDestroy(): void {
    this.stateChanges.complete();
    this.fm.stopMonitoring(this.elRef.nativeElement);
  }

  @Output()
  onSmart: EventEmitter<string> = new EventEmitter<string>();

  clickSmart(value: string) {
    if (this.onSmart) {
      this.onSmart.emit(value);
    }
  }

  onChange = (value: any) => { };

  stateChanges = new Subject<void>();

  touched = false;

  onTouched() {

  }

  get smsText(): string {
    return this._smsText;
  }
  set smsText(value: string) {
    this._smsText = value;
    this.onChange(this._smsText);
  }
  private _smsText: string = "";

  smsChanged($event: any) {
    this.smsText = $event;
  }

  @HostBinding() id = `diy-editor-${EditorComponent.nextId++}`;

  describedBy = '';

  setDescribedByIds(ids: string[]) {
    this.describedBy = ids.join(' ');
  }

  @Input()
  get questions(): QuestionModel[] {
    return this._questions;
  }
  set questions(value: QuestionModel[]) {
    this._questions = value;
  }
  private _questions: QuestionModel[] = [];

  @Input()
  get disabled() { return this._disabled; }
  set disabled(dis) {
    this._disabled = coerceBooleanProperty(dis);
    this.stateChanges.next();
  }
  private _disabled = false;

  @Input()
  get value(): string | null {
    switch (this._channel) {
      case "sms":
      case "whatsapp":
        this._value = this.smsText;
        break;
      case "web":
        this._value = this.formGroup.controls.value.value;
        break;
    }

    return this._value;
  }
  set value(v: string | null) {
    this._smsText = this._value = v ?? "";
    this.formGroup.controls.value.setValue(v);
    this.stateChanges.next();
  }
  private _value: string = "";

  @Input()
  get channel(): string {
    return this._channel;
  }
  set channel(value: string) {
    this._channel = value;
  }
  private _channel = "web";

  @Input()
  get smart(): boolean {
    return this._smart;
  }
  set smart(value: boolean) {
    this._smart = value;
  }
  private _smart: boolean = false;
}
