<div *ngIf="panelId > 0" class="edit-panel-top-container">
  <div *ngIf="!isViewing">
    <button mat-raised-button color="primary" (click)="addRespondents()">Add Respondents</button>
  </div>&nbsp;&nbsp;
  <div *ngIf="total > pageSize">
    <mat-form-field appearance="outline">
      <mat-label>Search</mat-label>
      <input type="text" matInput placeholder="Search" [(ngModel)]="searchKey" autocomplete="off" (keyup)="applyFilter($event)">
      <button matSuffix mat-icon-button aria-label="Clear" *ngIf="searchKey" (click)="onSearchClear($event)">
        <mat-icon>close</mat-icon>
      </button>
    </mat-form-field>
  </div>
</div>
<div class="outer-table-container mat-elevation-z1">
  <table mat-table #table [dataSource]="panellists"
         multiTemplateDataRows
         matSort
         matSortActive="firstName"
         matSortDisableClear matSortDirection="desc">
    <ng-container matColumnDef="email">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Email </th>
      <td mat-cell *matCellDef="let element"> {{element.Email}} </td>
    </ng-container>
    <ng-container matColumnDef="country">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Country </th>
      <td mat-cell *matCellDef="let element"> {{element.Country?.Name ?? "Unknown"}} </td>
    </ng-container>
    <ng-container matColumnDef="mobile">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Mobile Number </th>
      <td mat-cell *matCellDef="let element"> {{element.MobileNumber}} </td>
    </ng-container>
    <ng-container matColumnDef="firstName">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Firstname </th>
      <td mat-cell *matCellDef="let element"> {{element.FirstName}} </td>
    </ng-container>
    <ng-container matColumnDef="lastName">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Lastname </th>
      <td mat-cell *matCellDef="let element"> {{element.LastName}} </td>
    </ng-container>
    <!-- Actions column -->
    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef mat-sort-header></th>
      <td mat-cell *matCellDef="let element">
        <div>
          <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="icon-button with a menu" (click)="$event.stopPropagation()">
            <mat-icon>more_vert</mat-icon>
          </button>
          <mat-menu #menu="matMenu">
            <button mat-menu-item [routerLink]="['/edit-respondent', panelId, element.PanellistID, 'panelist']">
              <mat-icon>edit</mat-icon>
              <span>Edit</span>
            </button>
            <button mat-menu-item (click)="deleteRespondent(element)">
              <mat-icon>delete</mat-icon>
              <span>Delete</span>
            </button>
          </mat-menu>
        </div>
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"
        class="edit-panel-element-row" (click)="edit(row)"></tr>
  </table>
  <mat-paginator [pageSize]="pageSize" [length]="total"></mat-paginator>
</div>

