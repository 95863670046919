<div *ngIf="preview && group">
  <div class="display-inline row-flex-flow margin-5">
    <div class="comm-edtr-name-container">
      <div class="row-flex-flow">
        <div><strong>{{group.Name}}</strong></div>&nbsp;
        <div>{{group.Description}}</div>
      </div>
      <div class="row-flex-flow">
        <div class="proc-def-status-div">
          <div><span class="sub-title">({{group.UserGroupId}})&nbsp;</span>Modified: {{group.Amended | date:'shortDate': '' : translate.currentLang}} {{group.Amended | date:'mediumTime': '' : translate.currentLang}}</div>
        </div>
        <div class="row-flex-flow" style="align-content:end">
          <div class="proc-def-actionBtn-div">
            <button mat-mini-fab color="accent" matTooltip="Edit User Group" (click)="clickOnButton(0)"><mat-icon>edit</mat-icon></button>
          </div>
          <div class="proc-def-actionBtn-div">
            <button mat-mini-fab color="accent" matTooltip="Delete User Group" (click)="clickOnButton(1)"><mat-icon>delete</mat-icon></button>
          </div>
          &nbsp;
        </div>
      </div>
    </div>
  </div>
</div>
<div *ngIf="!preview && group">
  <mat-accordion>
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          <div class="simple-row">
            <mat-icon class="mini-fab">groups</mat-icon><span class="user-details-expansion-panel-title">Members</span>
          </div>
        </mat-panel-title>
        <mat-panel-description>

        </mat-panel-description>
      </mat-expansion-panel-header>
      <div class="alert alert-primary" role="alert">
        <div style="display:flex;flex-flow:row">
          <div>
            It is possible to add any account to a group. This includes users and organizations.
          </div>
        </div>
      </div>
      <app-user-search label="Search Accounts" [withOrgs]="true" [users]="users"></app-user-search>
      <div>
        <button mat-raised-button color="primary" [disabled]="users.length == 0" (click)="addAccounts()">Add Account</button>&nbsp;&nbsp;
      </div>
      <div *ngFor="let account of accounts;let i = index">
        <div class="row-flex-flow margin-5">
          <div>
            <mat-icon>{{icon(account)}}</mat-icon>
          </div>
          <div>
            {{account.UserName}}<span *ngIf="!isOrganisation(account)"> - {{account.FirstName}}, {{account.LastName}} ({{account.EmailAddress}})</span>&nbsp;
          </div>
          <div style="margin-top:-8px">
            <button mat-mini-fab color="accent" matTooltip="Delete Definition" (click)="deleteMember(account.UserID)"><mat-icon>delete</mat-icon></button>
          </div>
        </div>
      </div>
    </mat-expansion-panel>
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          <div class="simple-row">
            <mat-icon class="mini-fab">policy</mat-icon><span class="user-details-expansion-panel-title">User Group Policies</span>
          </div>
        </mat-panel-title>
        <mat-panel-description>

        </mat-panel-description>
      </mat-expansion-panel-header>
      <div *ngFor="let grp of policyGroups; let i = index">
        <app-policy-group [userGroup]="group" [group]="grp" (onClick)="action($event)" [(preview)]="previews[i]"></app-policy-group>
      </div>
    </mat-expansion-panel>
  </mat-accordion>
</div>
