import { HttpClient, HttpHeaders } from "@angular/common/http";
import { baseUrl } from "../environments/environment";

export const padEnd = (text: string, no: number, pad: string): string => {
  let returnStr = text
  let num = no - text.length
  while (num > 0) {
    text += pad
  }
  return returnStr
}

export const padStart = (text: string, no: number, pad: string): string => {
  let returnStr = text
  let num = no - text.length
  while (num > 0) {
    text = pad + text
  }
  return returnStr
}

export const getCircularReplacer = () => {
  const seen = new WeakSet();
  return (key: any, value: any) => {
    if (typeof value === "object" && value !== null) {
      if (seen.has(value)) {
        return;
      }
      seen.add(value);
    }
    return value;
  };
};

export class JsonCyclic {
  public static toJson(value: object): string {
    return JSON.stringify(value, getCircularReplacer());
  }
}

export class ZendeskUtil {

  public static signin(jwt: string, return_to: string) {
    if (baseUrl.startsWith("https://localhost")) {
      window.location.href = return_to;
      return;
    }

    const myform = document.createElement('form');
    myform.method = 'POST';
    var url = baseUrl.replace("/api/", return_to);
    myform.action = 'https://diysurveys.zendesk.com/access/jwt?return_to=' + url;
    myform.style.display = 'none';
    myform.append('Content-Type', 'application/x-www-form-urlencoded');
    myform.append('Accept', 'text/html,application/xhtml+xml,application/xml;q=0.9,image/webp,image/apng,*/*;q=0.8');
    myform.append('Access-Control-Allow-Origin', '*');
    const mapInput = document.createElement('input');
    mapInput.type = 'hidden';
    mapInput.name = 'jwt';
    mapInput.id = 'jwtInput';
    mapInput.value = jwt;
    myform.appendChild(mapInput);
    document.body.appendChild(myform);
    myform.submit();
  } 
}
