<div [formGroup]="formGroup" class="cint-mapper-main-container">
  <div *ngIf="tempCountries | async; else loading" class="leftFloat fullWidth">
    <mat-form-field appearance="outline" class="leftFloat">
      <mat-label>Choose a country</mat-label>
      <mat-select formControlName="country" (selectionChange)="onCountryChange($event)" matNativeControl>
        <mat-option value="" selected></mat-option>
        <mat-option *ngFor="let country of countries" value="{{country.Id}}">{{country.Name}}</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <mat-sidenav-container class="sidenav-container">
    <mat-sidenav #rightSidenav class="cint-mapper-mat-sidenav-right" position="end" [mode]="(isHandset$ | async) ? 'over' : 'side'" [opened]="(isHandset$ | async) ? 'true' : 'true'">
      <div class="custom-scroll-bar cint-mapper-mat-sidenav-right-div">
        <div class="cint-mapper-selected-div">
          <div class="cint-mapper-selected-div-content">
            <span color="primary"><b>Selected</b></span>
          </div>
        </div>
        <div class="cint-mapper-category-div">
          <div *ngFor="let category of select" class="cint-mapper-category-div-content">{{category.id}} {{category.name}}</div>
        </div>
      </div>
    </mat-sidenav>
    <mat-sidenav-content class="fullHeight">
      <div>
        <div>
          <div class="leftFloat fullWidth">
            <div>
              <div *ngIf="tempQuestions | async" class="leftFloat">
                <mat-selection-list #questionList [multiple]="false" class="custom-scroll-bar">
                  <mat-list-option *ngFor="let question of questions; let i = index" (click)="selectQuestion($event, i)">
                    <div>
                      <div class="leftFloat">
                        {{question.Name}}
                      </div>
                      <div class="rightFloat">
                        <mat-icon class="cint-mapper-radio-icon" *ngIf="isSelected(i)">radio_button_unchecked</mat-icon>
                      </div>
                    </div>
                  </mat-list-option>
                </mat-selection-list>
              </div>
              <div class="cint-mapper-cat-selector-container custom-scroll-bar">
                <category-selector (change)="onCategoryChange($event)" #catList></category-selector>
              </div>
            </div>
          </div>
        </div>
      </div>
    </mat-sidenav-content>
  </mat-sidenav-container>

  <ng-template #loading>
    <div class="loading-container">
      <span class="loading-content">
        <div class="spinner-div"><mat-spinner [diameter]="25"></mat-spinner></div>
        <div class="spinner-text"> Please Wait...</div>
      </span>
    </div>
  </ng-template>
</div>
