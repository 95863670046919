<div class="user-details-dialog-container">
  <div class="leftFloat">
    <h3>Change Email Address</h3>
  </div>
  <div class="rightFloat">
    <button mat-icon-button mat-dialog-close>
      <mat-icon>close</mat-icon>
    </button>
  </div>
</div>
<div class="clearBoth"></div>
<div class="fullWidth align-center-center-flex fill-flex">
  <form class="chng-un-form column-flex align-center-start-flex" [formGroup]="formGroup">
    <div class="alert alert-warning" role="alert">
      Please make sure you have access to the email account you supply as we will be sending a verfification message to it.
    </div>
    <div class="chng-un-controls-container">
      <div class="chng-un-select-name-div">
        <mat-form-field appearance="outline">
          <mat-label>Select Email Address</mat-label>
          <input formControlName="email" matInput placeholder="Select Email Address" (keyup)="checkName($event)">
        </mat-form-field>
      </div>
    </div>
    <button mat-raised-button class="chng-un-save-button" (click)="onSubmit()" color="primary" [disabled]="formGroup?.invalid || !success">Change</button>
  </form>
</div>
