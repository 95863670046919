<div class="help-main-div display-flex align-center-center-flex fill-flex">
  <mat-card class="fxFlex-25">
    <mat-toolbar class="help-toolbar">Help</mat-toolbar>
    <form class="padding-20 flex-stretch-column" [formGroup]="formGroup">
      <mat-form-field appearance="outline">
        <mat-label>Subject</mat-label>
        <input matInput placeholder="Subject" formControlName="subject">
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>Description</mat-label>
        <textarea matInput placeholder="Description" formControlName="description" class="help-desc-txtArea"></textarea>
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>Priority</mat-label>
        <mat-select formControlName="priority" required>
          <mat-option value="low">Low</mat-option>
          <mat-option value="medium">Medium</mat-option>
          <mat-option value="high">High</mat-option>
        </mat-select>
      </mat-form-field>
      <div class="txt-align-center">
        <button mat-raised-button type="submit" class="mt-5 help-submit-btn">Submit</button>
      </div>
    </form>
  </mat-card>
</div>
