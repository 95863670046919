import { Component, Input, Output, EventEmitter } from '@angular/core';
import { ProfileVariable } from '../../panel.Service';
import { FormControl, FormGroup } from '@angular/forms';
import { QuotaModel, RuleList } from '../../question.service';

@Component({
  selector: 'app-quota-definition',
  templateUrl: './quota-definition.component.html',
  styleUrl: './quota-definition.component.css'
})
export class QuotaDefinitionComponent {
  quotaForm: FormGroup;

  constructor() {
  }

  reset() {

  }

  setupForm() {
    if (this.quotaForm) {
      return;
    }

    this.checkQuotas();
    this.quotaForm = new FormGroup({
      number: new FormControl(this._ruleList.Quota.Target == -1 ? 0 : this._ruleList.Quota.Target),
      action: new FormControl(this._ruleList.Quota.Action),
      reset: new FormControl(this._ruleList.Quota.Reset),
      period: new FormControl(this._ruleList.Quota.Value == -1 ? 0 : this._ruleList.Quota.Value),
      unit: new FormControl(this._ruleList.Quota.Unit),
      starting: new FormControl(this._ruleList.Quota.Starting)
    });

    this.quotaForm.valueChanges.subscribe(x => {
      this.checkQuotas();
      this._ruleList.Quota.Target = this.quotaForm.controls.number.value == 0 ? -1 : this.quotaForm.controls.number.value;
      this._ruleList.Quota.Reset = this.quotaForm.controls.reset.value;
      this._ruleList.Quota.Starting = this.quotaForm.controls.starting.value;
      this._ruleList.Quota.Unit = this.quotaForm.controls.unit.value;
      this._ruleList.Quota.Action = this.quotaForm.controls.action.value;
      this._ruleList.Quota.Value = this.quotaForm.controls.period.value;
      //this.changed?.emit(this._ruleList);
    });
  }

  checkQuotas() {
    if (this._ruleList == null) {
      this._ruleList = new RuleList();
    }

    if (this._ruleList.Quota == null) {
      this._ruleList.Quota = new QuotaModel();
    }
  }

  calcQuotaPercent() {
    if (this._ruleList.Quota == null) {
      return 0;
    }

    return this._ruleList.Quota.Progress / this._ruleList.Quota.Target * 100;
  }

  get isProfileQuota(): boolean {
    return this._variables?.length > 0;
  }

  get isReset() {
    return this.quotaForm.controls.reset.value;
  }

  @Input()
  get variables(): ProfileVariable[] {
    return this._variables;
  }
  set variables(value: ProfileVariable[]) {
    this._variables = value;
  }
  private _variables: ProfileVariable[] = [];

  @Input()
  get rules(): RuleList {
    return this._ruleList;
  }
  set rules(value: RuleList) {
    this._ruleList = value;
    this.setupForm();
  }
  private _ruleList: RuleList = null;

  @Input()
  get display(): boolean {
    return this._display;
  }
  set display(value: boolean) {
    this._display = value;
  }
  private _display: boolean = false;

  @Output()
  changed: EventEmitter<RuleList> = new EventEmitter<RuleList>();

}
