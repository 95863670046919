import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AuthenticationService, Group, UserGroup } from '../../authentication.Service';
import { OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-new-group',
  templateUrl: './new-group.component.html',
  styleUrl: './new-group.component.css'
})
export class NewGroupComponent implements OnInit {
  newGroup = false;
  formGroup: FormGroup;
  title = "Change Group Policy Basic Details";
  buttonTitle = "Change";
  pattern = '^[a-zA-Z0-9_.+-]+[a-zA-Z0-9-]+$';
  group: Group = new Group();
  userGroup: UserGroup = new UserGroup();
  success: boolean = true;
  isPolicyGroup: boolean = true;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<NewGroupComponent>,
  private authService: AuthenticationService) {
  }

  ngOnInit(): void {

    this.isPolicyGroup = this.data.isPolicyGroup;
    if (this.data.id == 0) {
      this.newGroup = true;
      this.title = "Create Group " + this.isPolicyGroup ? "Policy" : "User";
      this.buttonTitle = "Create";
    }
    else {
      this.title = "Change Group " + (this.isPolicyGroup ? "Policy" : "User") + " Basic Details";
      this.group = this.data.group;
      this.userGroup = this.data.userGroup;
    }

    if (this.isPolicyGroup) {
      this.formGroup = new FormGroup({
        name: new FormControl(this.group.Name, [Validators.required, Validators.pattern(this.pattern)]),
        description: new FormControl(this.group.Description)
      });
    }
    else {
      this.formGroup = new FormGroup({
        name: new FormControl(this.userGroup.Name, [Validators.required, Validators.pattern(this.pattern)]),
        description: new FormControl(this.userGroup.Description)
      });
    }
  }

  onSubmit() {
    if (this.isPolicyGroup) {
      this.group.Name = this.formGroup.controls.name.value;
      this.group.Description = this.formGroup.controls.description.value;
      this.group.TypeId = 1;
      this.authService.savePolicyGroup(this.group).subscribe(
        result => {
          this.group = result;
          this.dialogRef.close({ data: { group: this.group } });
        }
      );
      return;
    }

    this.userGroup.Name = this.formGroup.controls.name.value;
    this.userGroup.Description = this.formGroup.controls.description.value;
    this.authService.saveUserGroup(this.userGroup).subscribe(
      result => {
        this.userGroup = result;
        this.dialogRef.close({ data: { userGroup: this.userGroup } });
      }
    );
  }
}
