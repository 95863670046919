import { Component, OnInit, Input, AfterContentInit, EventEmitter, Output, AfterViewInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { AnswerModel } from "../../models/AnswerModel";


@Component({
  selector: 'app-text',
  templateUrl: './text.component.html',
  styleUrls: ['./text.component.css']
})
export class TextComponent implements OnInit, AfterViewInit {

  display: boolean = false;
  hide = true;
  singleline: boolean = true;
  multiline: boolean = false;
  password: boolean = false;
  email: boolean = false;
  mask = "";
  placeholderText: string = "Input";
  answerValue = "";
  emailMask = '^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$';
  formGroup: FormGroup;
  checkingChange = false;

  constructor() {
    this.formGroup = new FormGroup({
      text: new FormControl("", [Validators.required]),
      multilinetext: new FormControl("", [Validators.required]),
      password: new FormControl("", [Validators.required]),
      email: new FormControl("", [Validators.required, Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$')])
    });
  }

  ngOnInit(): void {
    this.formGroup.valueChanges.subscribe(result => {
      if (this.checkingChange) {
        return;
      }

      this.checkingChange = true;
      this.onChange();
      this.checkingChange = false;
    });
  }

  ngAfterViewInit(): void {
    Promise.resolve(null).then(() => {
      this.populateForm();
    });
  }

  @Input()
  get question(): any {
    return this._question;
  }
  set question(value: any) {
    this._question = value;
    if (this._question === undefined) {
      return;
    }
  }
  private _question: any;

  @Output() change = new EventEmitter();

  onChange() {
    this.change.emit();
  }

  private populateForm() {
    if (this._question === undefined || this._question === null) {
      return;
    }

    if (this._question.Style && this._question.Style.Control) {
      switch (this._question.Style.Control.Type) {
        case 2:
          this.singleline = true;
          this.multiline = false;
          this.password = false;
          this.email = false;
          break;
        case 3:
          this.multiline = true;
          this.singleline = false;
          this.password = false;
          this.email = false;
          break;
        case 14:
          this.password = true;
          this.singleline = false;
          this.multiline = false;
          this.email = false;
          break;
        case 0x1008:
          this.email = true;
          this.singleline = false;
          this.multiline = false;
          this.password = false;
      }

    }
    else {
      this.singleline = true;
      this.multiline = false;
      this.password = false;
      this.email = false;
    }

    if (this._question.Style.Mask !== "") {
      this.mask = this._question.Style.Mask;
    }

    if (this.formGroup === undefined || this.formGroup === null) {
      return;
    }

    this.formGroup.controls.text.clearValidators();
    this.formGroup.controls.multilinetext.clearValidators();
    this.formGroup.controls.password.clearValidators();
    this.formGroup.controls.email.clearValidators();
    this.formGroup.controls.email.addValidators(Validators.email);
    if (this._question.MustAnswer) {
      this.formGroup.controls.text.addValidators(Validators.required);
      this.formGroup.controls.multilinetext.addValidators(Validators.required);
      this.formGroup.controls.password.addValidators(Validators.required);
      this.formGroup.controls.email.addValidators(Validators.required);
    }

    if (this._question.Style.Placeholder !== "") {
      this.placeholderText = this._question.Style.Placeholder;
    }

    let value = this._question.Response.Value;
    if (value === null) {
      value = this._question.Response.Initial;
    }


    if (value !== null) {
      this.formGroup.patchValue(
        {
          text: value,
          multilinetext: value,
          password: value,
          email: value
        }
      );
    }

    this.formGroup.controls.text.updateValueAndValidity();
    this.formGroup.controls.multilinetext.updateValueAndValidity();
    this.formGroup.controls.password.updateValueAndValidity();
    this.formGroup.controls.email.updateValueAndValidity();
    this.display = true;
  }

  get answers(): AnswerModel[] {
    const values: AnswerModel[] = [];
    if (this.singleline) {
      values.push(new AnswerModel(this._question.QuestionFullName, this.formGroup.controls.text.value));
    }

    if (this.multiline) {
      values.push(new AnswerModel(this._question.QuestionFullName, this.formGroup.controls.multilinetext.value));
    }

    if (this.password) {
      values.push(new AnswerModel(this._question.QuestionFullName, this.formGroup.controls.password.value));
    }

    if (this.email) {
      values.push(new AnswerModel(this._question.QuestionFullName, this.formGroup.controls.email.value));
    }

    return values;
  }
  set answers(value) {
    if (!this.checkingChange) {
      this.populateForm();
    }
  }

  get valid(): boolean {
    if (this.singleline) {
      return !this.formGroup.controls.text.invalid;
    }

    if (this.multiline) {
      return !this.formGroup.controls.multilinetext.invalid;
    }

    if (this.password) {
      return !this.formGroup.controls.password.invalid;
    }

    if (this.email) {
      return !this.formGroup.controls.email.invalid;
    }

    return true;
  }

  emailError() {
    if (this.formGroup.controls.email.invalid) {
      return "Please enter a valid email address";
    }

    return "";
  }
}
