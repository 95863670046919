<div *ngIf="Questions && Questions.length > 0" [formGroup]="formGroup">
  <div class="mt-10 mb-10">
    Question: <button [matMenuTriggerFor]="colmenu" mat-stroked-button>
      <span>{{selectedQuestion.Summary}}</span>
      <mat-icon>arrow_drop_down</mat-icon>
    </button>
    <mat-menu #colmenu="matMenu">
      <button *ngFor="let question of Questions; let i = index" mat-menu-item (click)="changeQuestion(i)">
        <span>{{question.Summary}}</span>
      </button>
    </mat-menu>
  </div>
  <div class="mt-10 mb-10">
    <mat-button-toggle-group #group="matButtonToggleGroup" formControlName="AnalysisType" (change)="change($event)">
      <mat-button-toggle value="Search">Search</mat-button-toggle>
      <mat-button-toggle value="Keyword">Keyword</mat-button-toggle>
      <mat-button-toggle value="Sentiment">Sentiment</mat-button-toggle>
    </mat-button-toggle-group>
  </div>
  <div *ngIf="analysisType('Search')">
    <h3>Search text</h3>
    <div>
      <mat-form-field appearance="outline" class="txt-analysis-search-field">
        <mat-label>Search</mat-label>
        <input matInput placeholder="Search" formControlName="Search">
      </mat-form-field>
      &nbsp;
      <button mat-flat-button color="primary" (click)="search()">
        Search
      </button>
    </div>
    <h3>Results:</h3>
    <div *ngIf="data.length > 0" class="outer-table-container mat-elevation-z8">
      <div class="txt-analysis-table-container custom-scroll-bar">
        <table mat-table #table [dataSource]="data" multiTemplateDataRows>
          <!-- Name Column -->
          <ng-container matColumnDef="Response">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Text </th>
            <td mat-cell *matCellDef="let element"> {{element[0].Response}} </td>
          </ng-container>

          <!-- Action column -->
          <ng-container matColumnDef="action">
            <th mat-header-cell *matHeaderCellDef mat-sort-header></th>
            <td mat-cell *matCellDef="let element">
              <div>
                <button mat-icon-button [matMenuTriggerFor]="actionMenu" aria-label="icon-button with a menu" (click)="$event.stopPropagation()">
                  <mat-icon>more_vert</mat-icon>
                </button>
                <mat-menu #actionMenu="matMenu">
                  <button mat-menu-item (click)="viewInterview(element)">
                    <mat-icon>view_headline</mat-icon>
                    <span>Review Interview</span>
                  </button>
                  <button mat-menu-item (click)="replayInterview(element)">
                    <mat-icon>replay</mat-icon>
                    <span>Replay Interview</span>
                  </button>
                </mat-menu>
              </div>
            </td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="columnsToDisplay; sticky: true"></tr>
          <tr mat-row *matRowDef="let column; columns: columnsToDisplay;"
              class="txt-analysis-element-row">
          </tr>
        </table>
      </div>
      <mat-paginator [pageSize]="size" [length]="data.length"></mat-paginator>
    </div>
  </div>
  <div *ngIf="analysisType('Keyword')">
    <h2>Keyword analysis</h2>
    <app-word-cloud-chart #wordCloud></app-word-cloud-chart>
  </div>
  <div *ngIf="analysisType('Sentiment')">
    <h2>Sentiment analysis</h2>
  </div>
</div>
