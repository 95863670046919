import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-image-only-alert',
  templateUrl: './image-only-alert.component.html',
  styleUrls: ['./image-only-alert.component.css']
})
export class ImageOnlyAlertComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
