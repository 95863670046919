import { Component, OnInit } from '@angular/core';
import { SurveyReferenceModel, SurveyService, SurveySummaryModel } from '../survey.Service';
import { MediaService } from '../media.Service';
import { Router } from '@angular/router';
import { MatButtonToggleChange } from '@angular/material/button-toggle';
import { plainToClass } from 'class-transformer';
import { InterviewModel } from '../question.service';
import { InterviewPreviewComponent } from '../interview-preview/interview-preview.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-start-from-template-new',
  templateUrl: './start-from-template-new.component.html',
  styleUrls: ['./start-from-template-new.component.css']
})
export class StartFromTemplateNewComponent implements OnInit {
  optionValue = 'Most Popular';
  categories: string[] = [];
  surveys: SurveySummaryModel[] = [];
  toggle: string = "card";


  constructor(
    private surveyService: SurveyService,
    private mediaService: MediaService,
    private router: Router,
    public dialog: MatDialog
  ) {
    this.surveyService.getSurveyCategories().subscribe(
      result => {
        this.categories = result;
        //this.optionValue = this.categories[0];
        this.optionValue = 'All';
        this.getSurveyTemplates(this.optionValue);
      });
  }

  ngOnInit(): void {
  }

  getSurveyTemplates(category: string) {
    if (category === "All") {
      category = "";
    }

    this.surveyService.getSurveySummaryByCategory(category).subscribe(
      result => {
        this.surveys = [];
        for (let i = 0; i < result.length; i++) {
          this.surveys.push(SurveySummaryModel.copy(result[i], this.mediaService));
        }
      },
      error => { }
    );
  }

  categoryChanged() {
    this.getSurveyTemplates(this.optionValue);
  }

  toggleView(change: MatButtonToggleChange) {
    this.toggle = change.value;
  }

  hasChannel(summary: SurveySummaryModel, channel: number): SurveyReferenceModel {
    for (let i = 0; i < summary.SurveyReferences.length; i++) {
      if (summary.SurveyReferences[i].Channel == channel) {
        return summary.SurveyReferences[i];
      }
    }

    return null;
  }

  onSelectedAction(survey: SurveySummaryModel, channel: number) {
    let id = this.hasChannel(survey, channel).SurveyID;
    this.surveyService.copyTemplate(id).subscribe(
      result => {
        this.router.navigate(["/surveyquestioneditor", result.SurveyID, "survey"]);
      });
  }

  previewInterview(generalSurvey: SurveySummaryModel, channel: number) {
    let survey = this.hasChannel(generalSurvey, channel);
    const model = plainToClass(InterviewModel, JSON.parse(survey.Script)) as InterviewModel;
    const dialogRef = this.dialog.open(InterviewPreviewComponent, {
      data: { model: model, isReview: false, id: 0 },
      width: '80%'
    });
  }

}
