import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { baseUrl, options } from "../environments/environment";
import { UserProfile } from './user.Service';

export class ChatMessage {
  ChatMessageId: number = 0;
  FromId: number = 0;
  OwnerId: number = 0;
  ToList: string = ";"
  IsRead: boolean = false;
  Subject: string = "";
  Content: string = "";
  Created: Date = new Date();
  Amended: Date = new Date();
  Status: number = 0;
  ISDeleted: boolean = false;
  FromChatMessageId: number = 0;
  From: UserProfile = new UserProfile();
}

@Injectable()
export class ChatService {
  constructor(private http: HttpClient) {
  }

  getMessages(): Observable<ChatMessage[]> {
    return this.http.get<ChatMessage[]>(baseUrl + 'ChatMessage/GetMessages', options);
  }

  sendMessage(message: ChatMessage): Observable<any> {
    return this.http.post(baseUrl + 'ChatMessage/SendMessage', message, options);
  }

  deleteMessage(message: ChatMessage): Observable<any> {
    return this.http.patch(baseUrl + 'ChatMessage/Delete', message, options);
  }

  getUnreadMessageCount(): Observable<number> {
    return this.http.get<number>(baseUrl + 'ChatMessage/GetUnreadMessageCount', options);
  }

  getMessageCount(): Observable<number> {
    return this.http.get<number>(baseUrl + 'ChatMessage/GetMessageCount', options);
  }

  markAsRead(message: ChatMessage) {
    this.http.patch(baseUrl + 'ChatMessage/MarkAsRead', message, options).subscribe();
  }

  getFromUser(messageId: number): Observable<any> {
    return this.http.get(baseUrl + 'ChatMessage/GetFrom?messageId=' + messageId, options);
  }
}
