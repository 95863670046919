import Quill, { RangeStatic } from "quill";
import { MatDialog } from '@angular/material/dialog';
import { FieldSelectDialogComponent } from "../../../data-analysis/custom-format/field-select-dialog/field-select-dialog.component";

type quillOptions = {
  upload: Function;
}

export default class PipeSelector {
  quill: Quill;
  options: quillOptions;
  range: RangeStatic | null;
  fileHolder: HTMLInputElement;

  constructor(quill: Quill, options: quillOptions, private dialog: MatDialog) {
    this.quill = quill;
    this.options = options;
    this.range = null;

    let toolbar = this.quill.getModule("toolbar");
    toolbar.addHandler("formula", this.pipeInField.bind(this));

  }

  pipeInField() {
    let dialog =  this.dialog.open(FieldSelectDialogComponent, {
        data: {  },
        width: '90%'
      });
    this.quill.focus();
    this.range = this.quill.getSelection();
  }
}
