import { Component, Inject, ViewChild } from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Subscription } from 'rxjs';
import { ParameterModel } from '../../lib/models/ParameterModel';
import { CategoryModel, InterviewModel, InterviewService, LabelModel, LanguageAnalysis, LanguageDefinition, LanguageElement, LanguageResult, LanguageSummary, QuestionModel } from '../../question.service';
import { GoogleObj, GoogletranslateService } from '../../services/googletranslate.service';
import { UserFeatures } from '../../user.Service';

@Component({
  selector: 'app-language-editor',
  templateUrl: './language-editor.component.html',
  styleUrls: ['./language-editor.component.css']
})
export class LanguageEditorComponent {

  dataSource = new MatTableDataSource<LanguageElement>();
  processPageSize = 10;
  isRateLimitReached: boolean = false;
  page: number = 1;
  size: number = 20;
  resultsLength: number = 0;
  analysis: LanguageAnalysis;
  interview: InterviewModel;
  elements: LanguageElement[] = [];
  row: number = -1;
  column: number = -1;
  model: QuestionModel;
  formGroup: FormGroup = new FormGroup({});
  isBoolean: boolean;
  optionText: string[] = [];
  useParameters: boolean[] = [];
  userFeatures: UserFeatures = new UserFeatures();
  categories: CategoryModel[] = [];
  parameters: ParameterModel[] = [];
  text: string;
  icon: string = "";
  checkChange: boolean = false;
  mainElements: LanguageElement[] = [];
  subscription: Subscription = null;

  @ViewChild('table', { static: true }) table: any | undefined;
  @ViewChild(MatSort) sort: MatSort | undefined;

  constructor(private dialog: MatDialog,
      private dialogRef: MatDialogRef<any>,
    private interviewService: InterviewService,
      private google: GoogletranslateService,
      @Inject(MAT_DIALOG_DATA) data: any) {
    this.analysis = data.analysis;
    this.interview = data.interview;
    for (let i = 0; i < this.analysis.Summary.length; i++) {
      this._columnsToDisplay.push(this.analysis.Summary[i].Language.Region);
    }

    let fullname = "";
    for (let e in this.analysis.Summary[0].Elements) {
      if (this.analysis.Summary[0].Elements[e].QuestionFullName != fullname) {
        this.mainElements.push(this.analysis.Summary[0].Elements[e]);
        fullname = this.analysis.Summary[0].Elements[e].QuestionFullName;
      }

      this.elements.push(this.analysis.Summary[0].Elements[e]);
    }

    this.resultsLength = this.elements.length;
    this.dataSource = new MatTableDataSource<LanguageElement>(this.mainElements);

    this.formGroup = new FormGroup({
      array: new FormArray([])
    });
  }

  getLanguage(region: string): LanguageDefinition {
    for (let i = 0; i < this.analysis.Summary.length; i++) {
      if (this.analysis.Summary[i].Language.Region == region) {
        return this.analysis.Summary[i].Language;
      }
    }

    return null;
  }

  isEdit(element: LanguageElement, index: number): boolean {
    let i = this.getRow(element);
    return index == this.column && i == this.row;
  }

  getQuestionModel(element: LanguageElement): QuestionModel {
    let model = this.interview.findQuestionByFullname(element.QuestionFullName);
    if (model == null) {
      return null;
    }

    return model;
  }

  edit(el: LanguageElement, index: number) {
    let array = this.formGroup.controls.array as FormArray;
    array.clear();
    this.row = -1;
    let r = this.getRow(el);
    this.column = index;
    let e = this.collectElementsForQuestion(el.QuestionFullName);
    this.model = this.interview.findQuestionByFullname(el.QuestionFullName);
    this.isBoolean = this.model.QuestionType == "boolean";
    this.categories = [];
    this.parameters = [];
    this.optionText = [];
    if (this.model.QuestionType === "mcma" || (this.model.QuestionType === "matrix" && this.model.Control === "checkbox")) {
      this.icon = "check_box_outline_blank";
    }

    if (this.model.QuestionType === "mcsa" || (this.model.QuestionType === "matrix" && this.model.Control === "radiobutton")) {
      this.icon = "radio_button_unchecked";
    }

    if (this.model.QuestionType === "ranking") {
      this.icon = "indeterminate_check_box";
    }

    for (let i = 0; i < e.length; i++) {
      let element = e[i];
      let useP = false;
      switch (element.LanguageElementType) {
        case 0:
          if (element.AdditionalElement == "high") {
            this.optionText.push("High");
          }

          if (element.AdditionalElement == "low") {
            this.optionText.push("Low");
          }

          break;
        case 1:
          let ot = this.model.QuestionType == "matrix" ? "Row" : "Option";
          for (let j = 0; this.model.Categories.List.length; j++) {
            if (this.model.Categories.List[j].Name == e[i].AdditionalElement) {
              this.categories.push(this.model.Categories.List[j]);
              this.optionText.push(ot + " " + (j + 1));
              break;
            }
          }

          break;
        case 2:
          useP = true;
          this.optionText.push(this.model.QuestionType == "matrix" ? "Column" : "");
          break;
      }

      this.useParameters.push(useP);
      this.text = this.getLabel(element, index);
      let form = new FormGroup({
        text: new FormControl(this.text)
      });
      form.valueChanges.subscribe(result => {
        if (this.checkChange) {
          this.checkChange = false;
          let me = this.mainElements[r];
          let e = this.collectElementsForQuestion(me.QuestionFullName);
          let array = this.formGroup.controls.array as FormArray;
          for (let i = 0; i < e.length; i++) {
            let form = array.controls[i] as FormGroup;
            this.setLabel(e[i], this.column,  form.controls.text.value);
          }

          this.checkChange = true;
        }
      });
      array.push(form);
    }

    this.row = r;
    this.checkChange = true;
  }

  getRow(element: LanguageElement): number {
    for (let i = 0; i < this.elements.length; i++) {
      if (element.QuestionFullName == this.mainElements[i].QuestionFullName) {
        return i;
      }
    }

    return -1;
  }

  get multipleChoices(): number {
    if (this.useParameters) {
      return 3;
    }

    if (this.model) {
      if (this.model.QuestionType === "mcma" || (this.model.QuestionType === "matrix" && this.model.Control === "checkbox")) {
        return 2;
      }

      if (this.model.QuestionType === "mcsa" || (this.model.QuestionType === "matrix" && this.model.Control === "radiobutton")) {
        return 1;
      }

      if (this.model.QuestionType === "ranking") {
        return 3;
      }
    }

    return -1;
  }

  invalidElementsForQuestion(element: LanguageElement, index: number): boolean {
    let e = this.collectElementsForQuestion(element.QuestionFullName);
    let valid = true;
    for (let i = 0; i < e.length; i++) {
      if (e[i] == undefined) {
        let a = 0;
      }
      let ref = this.analysis.Summary[index].Elements[e[i].Reference] as LanguageElement;
      if (!ref.Valid) {
        valid = false;
      }
    }

    return !valid;
  }

  collectElementsForQuestion(fullname: string): LanguageElement[] {
    let e = [];
    if (fullname.startsWith("__n_n_n_n_n")) {
      let a = "help";
    }
    for (let i = 0; i < this.elements.length; i++) {
      if (this.elements[i].QuestionFullName == fullname) {
        e.push(this.elements[i]);
      }
    }

    return e;
  }

  get columnsToDisplay(): string[] {
    return this._columnsToDisplay;
  }
  private _columnsToDisplay: string[] = [];

  getLabel(element: LanguageElement, index: number) {
    let question = this.interview.findQuestionByFullname(element.QuestionFullName);
    switch (element.LanguageElementType) {
      case 0:
        switch (element.AdditionalElement) {
          case "":
            return this.getLabelBasedOnLanguage(question.Banner, question.LanguageBanner, index);
          case "high":
            return this.getLabelBasedOnLanguage(question.HighLabel, question.HighLanguageLabel, index);
          case "low":
            return this.getLabelBasedOnLanguage(question.LowLabel, question.LowLanguageLabel, index);
        }

        break;
      case 3:
        switch (element.AdditionalElement) {
          case "":
            return this.getLabelBasedOnLanguage(question.Banner, question.LanguageBanner, index);
          case "high":
            return this.getLabelBasedOnLanguage(question.HighLabel, question.HighLanguageLabel, index);
          case "low":
            return this.getLabelBasedOnLanguage(question.LowLabel, question.LowLanguageLabel, index);
        }

        break;
      case 1:
        for (let i = 0; i < question.Categories.List.length; i++) {
          if (question.Categories.List[i].Name == element.AdditionalElement) {
            let category = question.Categories.List[i];
            return this.getLabelBasedOnLanguage(category.Label, category.LanguageLabel, index);
          }
        }

        break;
      case 2:
        for (let i = 0; i < question.Parameters.length; i++) {
          if (question.Parameters[i].Name == element.AdditionalElement) {
            return this.getLabelBasedOnLanguage(question.Parameters[i].Value, question.Parameters[i].LanguageValue, index);
          }
        }

        break;
    }

    return "";
  }

  getLabelBasedOnLanguage(label: string, languageLabels: LabelModel[], index: number): string {
    if (languageLabels == null || languageLabels.length == 0) {
      return index == 0 ? label : "";
    }

    for (let i = 0; i < languageLabels.length; i++) {
      if (languageLabels[i].Language == this.analysis.Summary[index].Language.Region || index == 0 && i == 0 && languageLabels[i].Language == "") {
        return languageLabels[i].Label;
      }
    }

    return "";
  }

  setLabelBasedOnLanguage(languageLabels: LabelModel[], index: number, reference: string, baseValue: string, value: string): LabelModel[] {
    if (languageLabels != null && languageLabels.length > 0) {
      for (let i = 0; i < languageLabels.length; i++) {
        if (i == 0) {
          languageLabels[i].Label = baseValue;
          this.checkElementValidity(0, reference, baseValue != '');
        }

        if (languageLabels[i].Language == this.analysis.Summary[index].Language.Region) {
          languageLabels[i].Label = value;
          this.checkElementValidity(index, reference, value != '');
          return languageLabels;
        }
      }
    }

    if (languageLabels == null) {
      languageLabels = [];
    }

    this.checkElementValidity(index, reference, value != '');
    for (let i = 0; i < this.analysis.Summary.length; i++) {
      let ll = new LabelModel();
      ll.Language = this.analysis.Summary[i].Language.Region;
      ll.Label = i == 0 ? baseValue : i == index ? value : '';
      languageLabels.push(ll)
    }

    return languageLabels;
  }

  checkElementValidity(index: number, reference: string, valid: boolean) {
    this.analysis.Summary[index].Elements[reference].Valid = valid;
  }

  getQuestion() {

  }

  close() {
    this.dialogRef.close();
  }

  translateLabel(element: LanguageElement, index: number, subIndex: number) {
    let e = this.collectElementsForQuestion(element.QuestionFullName);
    let temp = this.getLabel(e[subIndex], 0);
    const googleObj: GoogleObj = {
      q: [ temp ],
      target: this.analysis.Summary[index].Language.Region
    };
    this.google.translate(googleObj).subscribe((result: any) => {
      let array = this.formGroup.controls.array as FormArray;
      let form = array.controls[subIndex] as FormGroup;
      form.controls.text.setValue(this.setLabel(element, index, result.data.translations[0].translatedText));
    });

  }

  copyLabel(element: LanguageElement, index: number, subIndex: number) {
    let e = this.collectElementsForQuestion(element.QuestionFullName);
    let temp = this.getLabel(e[subIndex], 0);
    let array = this.formGroup.controls.array as FormArray;
    let form = array.controls[subIndex] as FormGroup;
    form.controls.text.setValue(this.setLabel(element, index, temp));
  }

  setLabel(element: LanguageElement, index: number, value: string): string {
    let question = this.getQuestionModel(element);
    let t = "";
    switch (element.LanguageElementType) {
      case 0:
      case 3:
        switch (element.AdditionalElement) {
          case "":
            question.LanguageBanner = this.setLabelBasedOnLanguage(question.LanguageBanner, index, element.Reference, question.Banner, value);
            t = question.Banner;
            break;
          case "high":
            question.HighLanguageLabel = this.setLabelBasedOnLanguage(question.HighLanguageLabel, index, element.Reference, question.HighLabel, value);
            t = question.HighLabel;
            break;
          case "low":
            question.LowLanguageLabel = this.setLabelBasedOnLanguage(question.LowLanguageLabel, index, element.Reference, question.LowLabel, value);
            t = question.LowLabel;
            break;
        }

        break;
      case 1:
        for (let i = 0; i < question.Categories.List.length; i++) {
          if (question.Categories.List[i].Name == element.AdditionalElement) {
            question.Categories.List[i].LanguageLabel = this.setLabelBasedOnLanguage(question.Categories.List[i].LanguageLabel, index, element.Reference,
              question.Categories.List[i].Label, value);
            t = question.Categories.List[i].Label;
            break;
          }
        }

        break;
      case 2:
        for (let i = 0; i < question.Parameters.length; i++) {
          if (question.Parameters[i].Name == element.AdditionalElement) {
            question.Parameters[i].LanguageValue = this.setLabelBasedOnLanguage(question.Parameters[i].LanguageValue, index, element.Reference,
              question.Parameters[i].Value, value);
            t = question.Parameters[i].Value;
            break;
          }
        }

        break;
    }

    return t;
  }

  save() {
    this.dialogRef.close({ 'analysis': this.analysis, 'interview': this.interview });
  }
}
