import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormArray, Validators } from '@angular/forms';
import { BaseQuestion, DataModel, InterviewService, QuestionService } from '../../question.service';

@Component({
  selector: 'qe-app-boolean',
  templateUrl: './qe-boolean.component.html',
  styleUrls: ['./qe-boolean.component.css']
})
export class QeBooleanComponent extends BaseQuestion implements OnInit {
  constructor(private qs: QuestionService, private is: InterviewService) {
    super(qs, is, undefined, undefined, new DataModel("boolean"));
  }

  public booleanFormGroup: FormGroup = new FormGroup({});
  public optionFormGroup: FormGroup = new FormGroup({});

  ngOnInit() {
    this.booleanFormGroup = this.fb.group({
      booleanFormArray: this.fb.array([this.initQuestion()])
    });

    this.optionFormGroup = this.fb.group({
      questionOption: this.fb.array([this.initOptionRow("Yes"), this.initOptionRow("No")])
    });

  }

  get questionArr(): FormArray | null {
    if (this.booleanFormGroup) {
      return this.booleanFormGroup.get('booleanFormArray') as FormArray;
    }

    return null;
  }

  initOptionRow(value: string) {
    return this.fb.group({
      qOption: [value, Validators.required],
    });
  }

  addNewQuestion() {
    this.questionArr?.push(this.initQuestion());
  }

  removeQuestion(index: number) {
    this.questionArr?.removeAt(index);
  }
}
