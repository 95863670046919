import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-single-panellist-dialog',
  templateUrl: './single-panellist-dialog.component.html',
  styleUrl: './single-panellist-dialog.component.css'
})
export class SinglePanellistDialogComponent {

  panellistId: number;
  panelId: number;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    this.panellistId = data.panellistId;
    this.panelId = data.panelId;
  }
}
