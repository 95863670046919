import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ProcessService } from '../../process.Service';
import { SurveyModel, SurveyService } from '../../survey.Service';
import { PanelModel, PanelService } from '../../panel.Service';
import { SelectRespondentService } from '../../select-respondents-landing-page/services/select-respondent.service';
import { InterviewModel } from '../../question.service';
import { MatButtonToggleChange } from '@angular/material/button-toggle';
import { PanelDialogComponent } from '../../panels/panel-dialog/panel-dialog.component';
import { MatSelectionList, MatSelectionListChange } from '@angular/material/list';
import { CintFeasibility } from '../../cint.Service';
import { PanelSelectorComponent } from '../../panel-selector/panel-selector.component';

@Component({
  selector: 'app-respondent-source',
  templateUrl: './respondent-source.component.html',
  styleUrl: './respondent-source.component.css'
})
export class RespondentSourceComponent {

  finalSource: any[];
  panelCount: number = 0;
  layout: string = 'detail';

  @ViewChild("list") list: MatSelectionList;

  constructor(private dialog: MatDialog,
    private processService: ProcessService,
    private surveyService: SurveyService,
    private panelService: PanelService,
    private confirmCintPanelService: SelectRespondentService) {
    this.finalSource = this.getSources();
    this.panelService.panelCount().subscribe(result => {
      this.panelCount = result;
      this.finalSource = this.getSources();
    });
  }

  select(index: number) {
    switch (index) {
      case 0:
        break;
      case 1:
        this.selectPanel();
        break;
      case 2:
        break;
      case 3:
        break;
      case 4:
        break;
    }
  }

  selectList(event: MatSelectionListChange) {
    this.select(event.options[0]?.value ?? 0);
  }

  selectPanel() {
    let dialogRef = this.dialog.open(PanelDialogComponent, {
      data: { survey: this.survey },
      width: '80%',
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result.data) {
        let data = result.data;
        if (data.panel) {
          this.addDistribution?.emit(new RespondentSourceEvent("panel", data.panel.PanelID));
        }
      }
    });
  }

  selectCintPanel() {
    let panel: PanelModel = null;
    let quota: CintFeasibility = null;
    this.panelService.getPanel(this.survey.PanelID).subscribe(
      result => {
        panel = result;
        try {
          quota = JSON.parse(panel.Quota) as CintFeasibility;
        }
        catch {
          quota = new CintFeasibility();
        }

        const dialogRef = this.dialog.open(PanelSelectorComponent, { width: '80%', data: { quota: quota, questionCount: 5 } });
        dialogRef.afterClosed().subscribe(result => {
          if (result && result.quota != null) {
            quota = result.quota.Feasibility;
            this.addDistribution?.emit(new RespondentSourceEvent("panel", panel.PanelID));
          }

          return false;
        });
      });
  }

  getSources(): any[] {
    let p = new PanelModel();
    let sources: any[] = [];
    sources.push(p.sources[0]);
    if (this.panelCount > 0) {
      sources.push(p.sources[1]);
    }

    sources.push(p.sources[2]);
    sources.push(p.sources[3]);
    sources.push(p.sources[4]);
    return sources;
  }

  layoutChange(event: MatButtonToggleChange) {
    this.layout = event.value;
  }

  @Input()
  get survey(): SurveyModel {
    return this._survey;
  }
  set survey(value: SurveyModel) {
    this._survey = value;
  }
  private _survey: SurveyModel;

  @Input()
  get interview(): InterviewModel {
    return this._interview;
  }
  set interview(value: InterviewModel) {
    this._interview = value;
  }
  private _interview: InterviewModel;

  @Output() addDistribution = new EventEmitter<RespondentSourceEvent>();
}

class RespondentSourceEvent {
  type: string;
  object: number;

  constructor(type: string, object: number) {
    this.type = type;
    this.object = object;
  }
}
