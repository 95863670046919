<mat-toolbar [class]="checkStyle()" class="mat-toolbar-container-noshadow-new">
  <div *ngIf="(isHandset | async) == false" style="display:flex;flex-flow:row;width:100%">
    <a [routerLink]="'/'"><img src="assets/img/logo.svg" class="toolbar-logo" (click)="scrollTo('home')" /></a>&nbsp;&nbsp;
    <div *ngIf="toolbarType == 'both'">
      <button *ngFor="let item of menu; let i = index" mat-button (click)="scrollTo(item.location)">{{item.name}}</button>&nbsp;&nbsp;
    </div>
    <span class="toolbar-spacer"></span>
    <div *ngIf="toolbarType == 'both'" class="toolbar-header-right-container">
      <a class="a" href="mailto:hello@diysurveys.com">hello&#64;diysurveys.com</a>&nbsp;&nbsp;
      <button mat-raised-button color="primary" [routerLink]="'/newlogin'">
        LOG IN
      </button>&nbsp;&nbsp;
      <button mat-raised-button color="accent" [routerLink]="'/newsignup'">SIGN UP</button>
    </div>
    <div *ngIf="toolbarType == 'signup'" class="toolbar-header-right-container">
      New to DIY Surveys?&nbsp;&nbsp;
      <button mat-raised-button color="accent" [routerLink]="'/newsignup'">SIGN UP</button>
    </div>
    <div *ngIf="toolbarType == 'login'" class="toolbar-header-right-container">
      Already have an account?&nbsp;&nbsp;
      <button mat-raised-button color="primary" [routerLink]="'/newlogin'">LOG IN</button>
    </div>
  </div>
  <div *ngIf="(isHandset | async)" style="display:flex;flex-flow:row;width:100%">
    <a [routerLink]="'/'"><img src="assets/img/logo.svg" class="toolbar-logo" (click)="scrollTo('home')" /></a>&nbsp;&nbsp;
    <span class="toolbar-spacer"></span>
    <button *ngIf="toolbarType != 'signup'" mat-icon-button color="primary" [routerLink]="'/newlogin'">
      <mat-icon>login</mat-icon>
    </button>
    <div *ngIf="toolbarType == 'signup'">
      <button mat-raised-button color="accent" [routerLink]="'/newsignup'">SIGN UP</button>
    </div>
    <button *ngIf="menu.length > 0" mat-icon-button [matMenuTriggerFor]="mainMenu" color="primary">
      <mat-icon>menu</mat-icon>
    </button>
    <mat-menu #mainMenu="matMenu">
      <ng-template matMenuContent>
        <button mat-menu-item *ngFor="let item of menu; let i = index" (click)="scrollTo(item.location+'-mobile')">{{item.name}}</button>
      </ng-template>
    </mat-menu>

  </div>
</mat-toolbar>
