import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-download-options',
  templateUrl: './download-options.component.html',
  styleUrls: ['./download-options.component.css']
})
export class DownloadOptionsComponent implements OnInit {

  exportType: string = "excel";

  constructor(private dialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) data: any) { }

  ngOnInit(): void {
  }

  setType(value: string) {
    this.exportType = value;
  }

  download() {
    this.dialogRef.close({ exportType: this.exportType });
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}
