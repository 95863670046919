import { HttpClient } from "@angular/common/http";
import { baseUrl, options } from "../environments/environment";
import { Observable } from "rxjs";
import { Injectable } from "@angular/core";

export class KeywordModel {
  KeywordID: number = 0;
  Code: string = "";
  AdministratorID: number = 0;
  StartDate: Date = new Date();
  FinishDate: Date = new Date();
  IsDeleted: boolean = false;
  KeywordType: number = 0;
  ActionListID: number = 0;
}

@Injectable()
export class SmsService {

  constructor(private http: HttpClient) {
  }

  getKeywords(): Observable<KeywordModel[]> {
    return this.http.get<KeywordModel[]>(baseUrl + 'Sms/GetKeywords', options);
  }

  getKeyword(id: string): Observable<KeywordModel> {
    return this.http.get<KeywordModel>(baseUrl + 'Sms/GetKeyword?id=' + id, options);
  }

  setKeyword(model: KeywordModel) {
    return this.http.post(baseUrl + 'Sms/UpdateKeyword', JSON.stringify(model), options);
  }


}
