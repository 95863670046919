<app-newtoolbar></app-newtoolbar>
<div #body class="body">
  <div id="home" class="header">
    <h2 class="txt-align-left">DIY Surveys ("DIYS") Terms of Service</h2>
    <h2 class="txt-align-left"><span>1. INTRODUCTION </span></h2>
    <p class="txt-align-left"><span>DIYS offers you the facility to create online and mobile surveys via dedicated user accounts. By signing up for an account or signing an order you hereby subscribe to our software-as-a-service platform which provides access to a secure online portal where you can create and launch surveys and see results in real time to your internet browser on your computer/phone. Data is collected through various mobile channels including, but not limited to SMS, email, Chat solutions (e.g., WhatsApp), mobile applications, voicemail and device browser. DIYS offers a wide range of related services and capabilities which are constantly evolving.</span></p>
    <h2 class="txt-align-left"><span>2. DEFINITIONS </span></h2>
    <p class="txt-align-left"><span>In these terms and conditions, the following definitions have the following meanings: </span></p>
    <p class="txt-align-left"><span>“Account Area” shall mean a defined area of the Website that can only be accessed by your Login and will provide you with access to the Service and any associated software; </span></p>
    <p class="txt-align-left"><span>“Bureau Service” shall mean the service that is available from DIYS as described at clause 7; </span></p>
    <p class="txt-align-left"><span>“End User” shall mean any individual mobile phone user who responds to any use of the service by you, for example, through any campaign or project run by you or is targeted by you in a survey or in any way participates with any Service operated by DIYS; </span></p>
    <p class="txt-align-left"><span>“IPR” shall mean all patents, trademarks, service marks, copyright, design right, database rights, know-how, processes, trade practices and other intellectual property rights whatsoever, whether registered or unregistered anywhere in the world; </span></p>
    <p class="txt-align-left"><span>“Keyword” shall mean a specific word rented by you from DIYS for use in the Keyword Service; </span></p>
    <p class="txt-align-left"><span>“Keyword Service” shall mean a specific service provided by DIYS whereby you can carry out market research and/or run a marketing campaign based on customers sending a Keyword to the DIYS platform via a Short Code or long (virtual) number; </span></p>
    <p class="txt-align-left"><span>“Login” shall mean a personal username and password which will be created by each User and which you agree to keep confidential and secure allowing each User to access and use the DIYS Service and any associated software; </span></p>
    <p class="txt-align-left"><span>“Message” shall mean a Message sent using the Service which may include SMS, MMS, email, Chat solutions (e.g., WhatsApp), Video, Picture or Voice content; </span></p>
    <p class="txt-align-left"><span>“Mobile Application” shall mean a program installed on a mobile phone to perform research related activities that are not possible through other means such as SMS, email, etc. This might include location-based services; </span></p>
    <p class="txt-align-left"><span>“Payment” User accounts are either defined as Enterprise and are invoiced for their usage of Services, or DIY who use the online payment system to add credit in the form of £GBP and for whom services will not operate unless in credit and able to send out mobile surveys (via Messages) and receive responses using the DIYS Service.</span></p>
    <p class="txt-align-left"><span>“Non-Subscription Fees” shall mean the fees due (other than the Subscription Fee) for Services provided by DIYS. Non-Subscription Fees may be in relation to SMS and WhatsApp messages, the Keyword Service, the Reward Service, the provision of Short Codes or any other service provided by DIYS, all in accordance with DIYS’s then current Non- Subscription Fees structure, as may be amended by DIYS from time to time; </span></p>
    <p class="txt-align-left"><span>“Order Form” shall mean the document setting out the details of your order and what services will be provided to you by DIYS along with the fees payable by you; </span></p>
    <p class="txt-align-left"><span>“Personal Data” shall mean any personal data relating to any living individual within the meaning defined under the General Data Protection Regulation and enforced through the Data Protection Act 2018 or other national, regional or local law or Regulations governing the use, access, and disclosure of personal data defined in such law or Regulations; </span></p>
    <p class="txt-align-left"><span>“Regulations” shall mean all legislative and regulatory instruments that affect your use of the Service, these include but are not limited to the Privacy and Electronic Communications Regulations 2003, the Telco Regulations, any ICE/ESOMAR and Mobile Marketing Association codes of practice or Regulations, any MRS and SMR Codes of Conduct/Regulations/Guidelines, any relevant EU Directives (for those operating within the EU) including, but not limited to, the E-Privacy Directive, the Commerce Directive, the Data Protection Directive and the Unfair Contract Terms Directive and any other guidelines, regulations or codes of conduct/practice imposed by network operators and any applicable equivalent of these regulations that are in force in the territory in which you are using the Service; </span></p>
    <p class="txt-align-left"><span>“Reward Service” shall mean a particular service which can be provided by DIYS to you whereby you can offer End Users rewards for participating in a survey or other marketing operation run by you using the DIYS Service. A reward may be in the form of, for example, a free prize-draw, money-off coupons, cash, bar codes, vouchers, ringtones, or other bonuses or benefits as determined by DIYS (“Reward”); </span></p>
    <p class="txt-align-left"><span>“Service” shall mean any service provided to you by DIYS for which you pay the Subscription Fee and the Non-Subscription Fees; </span></p>
    <p class="txt-align-left"><span>“Service Support” shall mean the assistance as detailed below. </span></p>
    <p class="txt-align-left"><span>“Short Code” shall mean a telephone number provided by us to you to which your clients can send a Message as part of a campaign run by you using the Service; </span></p>
    <p class="txt-align-left"><span>“Subscription” shall mean your annual (non-refundable) subscription, for which you pay the Subscription Fee, which enables you to access the DIYS portal to create surveys; </span></p>
    <p class="txt-align-left"><span>“Subscription Fee” shall mean the annual fee, in pounds sterling, payable for the Subscription which will depend on the number of Users required, the support level required, and or consultancy fees should DIYS provide a bureau service.</span></p>
    <p class="txt-align-left"><span>“User(s)” shall mean the individual or individuals who are employees, agents or contractors of your company (or other body) permitted and authorised on your behalf to use the Service, each of whom shall have a unique Login. Users must be one unique person and multiple persons must not share user credentials; </span></p>
    <p class="txt-align-left"><span>“we”, “us”, “our”, “DIYS” shall mean, DIY Surveys Limited (Company No. 12676615) of 27 Old Gloucester Street, London, WC1N 3AX, UK. </span></p>
    <p class="txt-align-left"><span>“Website” shall mean the DIY Surveys website at <a href="https://diysurveys.com/" target=”_blank” class="cursor-pt terms-privacy-h3-color">www.diysurveys.com</a> and any of the sub-domains thereof; </span></p>
    <p class="txt-align-left"><span>“Website Terms of Use” shall mean the DIYS website terms of use available on our website; and </span></p>
    <p class="txt-align-left"><span>“you”, “your”, “yourself” shall mean the person, company or other body ordering a Subscription for access and use of the Service and any associated software pursuant to these terms and conditions. </span></p>
    <h2 class="txt-align-left"><span>3. SUBSCRIPTION AND ACCEPTANCE </span></h2>
    <p class="txt-align-left"><span>3.1. Your Subscription to the Service and/or receiving a demonstration of the Service either online or in person shall be accomplished by your signing up for an account which shall be deemed an acceptance by you of these terms and conditions. </span></p>
    <p class="txt-align-left"><span>3.2. Upon Subscription you must complete all the compulsory fields in the Account Area before making use of the Service or the Service will be disabled. Continued use of the Service is conditional upon the information in the Account Area being true, complete and accurate at all times; failure to do so shall result in the suspension or termination of your Subscription and use of the Service. </span></p>
    <p class="txt-align-left"><span>3.3. Conditions or other terms contained on a Purchase Order proposing additional or different provisions shall be void and unenforceable in their entirety. If purported or stipulated by you in any way to us that such terms are binding, to the extent that they are inconsistent with these terms, they shall also be deemed void. </span></p>
    <h2 class="txt-align-left"><span>4. SERVICE </span></h2>
    <p class="txt-align-left"><span>4.1. Your access to and use of the Service and any associated software shall be subject to payment of the Subscription Fees (If required) and Non-Subscription Fees and is conditional upon your acceptance of these Terms and Conditions. </span></p>
    <p class="txt-align-left"><span>4.2. You agree to pay any/all Subscription Fees and the Non-Subscription Fees promptly in connection with your use of the Service. </span></p>
    <p class="txt-align-left"><span>4.3. You must ensure that services provided by you making use of the Service are of a quality and kind that is not likely to bring DIYS or any network operator into disrepute. You will conduct all of your business in your own name, shall comply with all applicable laws and shall not engage in any deceptive, misleading, or unethical business practices. You will not use the services in a manner which is false or misleading or which may adversely affect the reputation or goodwill of DIYS. Whether or not the services provided by you comply with this clause shall be a matter to be determined by DIYS in its sole and absolute discretion, such discretion not to be used unreasonably.</span></p>
    <p class="txt-align-left"><span>4.4. You acknowledge that for effective use of the Service you must have an internet browser which supports JavaScript and an ADSL or other high speed internet connection is required for proper performance of the Service. The Service is tested and compatible for usage with all major Internet browsers; if your browser is unable to display the Service in a satisfactory manner, DIYS will suggest an alternative, but the Subscription Fees are not refundable for your failure to use a satisfactory browser. </span></p>
    <p class="txt-align-left"><span>4.5. You may not access the Service if you provide services which are substantially similar to those offered by DIYS, except in the event that DIYS has provided its prior written consent. </span></p>
    <p class="txt-align-left"><span>4.6. As the pricing for the Services are potentially proportional to the anticipated usage when usage becomes high, you must inform us, not less than three (3) months in advance, of any anticipated significant increase (over and above your normal usage) in the volume of Messages to be transmitted or received by you through the Service. DIYS retains the right to regulate such volume should we consider that the Service may be impaired by such increased volume. </span></p>
    <p class="txt-align-left"><span>4.7. You shall ensure that nothing provided by you or any End User shall contain any (a) viruses, worms, Trojan horses, or any other code that might disrupt, disable, harm, erase memory, or otherwise impede the operation or functionality or any DIYS or network operator software, firmware, hardware, wireless device, computer system, or network; (b) traps, time bombs, or other code that would disable any software based on the elapsing of a period of time, advancement to a particular date or other numeral; or (c) code that would permit any third party to interfere with or surreptitiously access any End User Personal Data. </span></p>
    <p class="txt-align-left"><span>4.8. You should only send Messages to End Users at times that are compliant with the Regulations or have already been agreed to be appropriate for transmission by the End User and, for the avoidance of doubt, when making use of the Service you must at all times comply with standards at least comparable to the Mobile Marketing Association Code of Conduct (<a href="https://www.mmaglobal.com/" class="cursor-pt terms-privacy-h3-color" target="_blank">www.mmaglobal.com</a>) and any other local equivalent of these guidelines that is applicable. Unless you are using DIYS Bureau Service, then it will be solely your responsibility to identify the target End Users and ensure that they are willing participants in the survey. </span></p>
    <p class="txt-align-left"><span>4.9. DIYS shall be entitled to review copies of any advertisements for the services which you may undertake and provide its prior written approval, and you shall give to DIYS at least 14 days' written notice before advertising any of the Services on radio, in magazines, generally, through any form of marketing communication or on television, whether by terrestrial, cable, satellite or otherwise. </span></p>
    <p class="txt-align-left"><span>4.10. You warrant and undertake that you shall not, and shall not permit others to: </span></p>
    <ul class="list-style-none">
      <li class="txt-align-left"><span>4.10.1. launch a campaign or survey in any country unless you have taken all steps necessary to ensure that your proposed use of the Service in that country does not contravene any Regulations; </span></li>
      <li class="txt-align-left"><span>4.10.2. modify, translate, create derivative copies of or copy the Service; </span></li>
      <li class="txt-align-left"><span>4.10.3. reverse engineer, decompile, disassemble or otherwise tamper with the Service or any material on the Website (except as otherwise permitted by law); </span></li>
      <li class="txt-align-left"><span>4.10.4. distribute, sub-license, assign, share, timeshare, sell, rent, lease, grant a security interest in or otherwise transfer to any third party the Service or your rights to use the Service; </span></li>
      <li class="txt-align-left"><span>4.11.5. remove or modify any copyright, trademark, or other proprietary notices of DIYS or other associated company or body contained on the Website; </span></li>
      <li class="txt-align-left"><span>4.10.6. create data or executable programs which mimic functionality in the Service; </span></li>
      <li class="txt-align-left"><span>4.10.7. use the Service in any manner not expressly authorised or envisioned or in a way which may bring us into disrepute; </span></li>
      <li class="txt-align-left"><span>4.10.8. upload, post, transmit, reproduce or distribute in any way, information, software or other material that is protected by copyright or other proprietary right without obtaining permission of the copyright owner or right holder; </span></li>
      <li class="txt-align-left"><span>4.10.9. restrict, inhibit or otherwise interfere with the ability of any other person to use or enjoy the Service or create an unusually large burden on the Service, including, without limitation, posting or transmitting any information or software that contains a virus or other harmful or debilitating feature; distributing mass or unsolicited Messages or otherwise generating levels of traffic sufficient to impede others’ ability to send or retrieve information; </span></li>
      <li class="txt-align-left"><span>4.10.10. interfere with computer networking or telecommunications service to or from any user, host or network, including but not limited to denial-of-service attacks, seizure or abuse of operator privileges (“hacking”) or attempting to “crash” a host. </span></li>
    </ul>
    <p class="txt-align-left"><span>4.11. In addition to DIYS’s termination rights set out elsewhere in this Agreement, DIYS may immediately suspend or terminate your Subscription and use of the Service immediately if you engage in one or more of the above prohibited activities. Additionally, you shall be responsible for all damages for which your conduct is responsible. DIYS reserves the right to charge you for any direct or indirect costs incurred by DIYS or its affiliates, in connection with your breach of this provision and any costs incurred to enforce your compliance with same.</span></p>
    <h2 class="txt-align-left"><span>5. YOUR USE OF THE SERVICE AROUND THE WORLD </span></h2>
    <p class="txt-align-left"><span>5.1. While the Service is available in many countries, not every DIYS Service is available in every country and the available Services may vary from country to country. This license allows you to access End-Users in multiple countries as part of the same campaign or survey. Before you launch any campaign or survey we strongly recommend that you review the available resources in our online help service and/or seek our advice on the restrictions and regulations that may be in place in those countries and the costs associated with using the service in those countries. </span></p>
    <p class="txt-align-left"><span>5.2. We will supply you with messages (SMS, WhatsApp, email, etc.,) at the price of which shall be determined by the country you choose to send messages to and in £GBP. If you choose to send. Furthermore, we always suggest that you carry out test Messages before launching a new campaign or survey, especially where it is your first time using the DIYS service in that country. </span></p>
    <p class="txt-align-left"><span>5.3. Notwithstanding that the Service may be available in any country we do not warrant or represent that the Service is compliant with all or any local laws and Regulations. </span></p>
    <p class="txt-align-left"><span>When using the Service (and notwithstanding any guidance that we provide to you under clause 5.1), it is solely your responsibility to ensure that your use is compliant with all Regulations in any such country and all such warranties and representations are expressly excluded. To the extent that we incur any liability as a result of your use of the Service that does not comply with the Regulations and/or and breaches of the terms of the network operators, then you shall indemnify us for any and all losses that we incur (see Clause 19.1 below).</span></p>
    <h2 class="txt-align-left"><span>6. MESSAGE CONTENT </span></h2>
    <p class="txt-align-left"><span>6.1. You are solely responsible for the content of all Messages you generate and send to End Users through the Service. You recognise and agree that DIYS is a passive conduit in transmitting and storing Messages and transmitting them to End Users. The only circumstance in which DIYS has any control over the Messages is when you have engaged it to supply you with its Bureau Service. </span></p>
    <p class="txt-align-left"><span>6.2. You shall not transmit or request, encourage, facilitate or participate in the receipt of any type/category of data, content, Messages or information using the Service including, in particular (but without limitation), ring tones, games or graphics, unless such type/category of Content is approved in advance in writing by DIYS. </span></p>
    <p class="txt-align-left"><span>6.3. You acknowledge and agree that DIYS reserves the right to refuse to transmit Messages that we believe, in our sole discretion, may violate any law, governmental order, carrier guidelines, or any other network or subscriber protective measures. However, you acknowledge that any exercise by DIYS of this right shall not be deemed to constitute DIYS’s acceptance of editorial control over the content of any Message or approval of any Message in any way whatsoever. </span></p>
    <p class="txt-align-left"><span>6.4. In the event that any Message (including but not limited to SMS, email, and Chat) is not delivered you shall still be responsible for the cost of sending the Message unless it is due directly to any failure on the part of DIYS. DIYS may occasionally use third parties to send Messages but DIYS will not be liable for any failure of such third party to deliver a Message. </span></p>
    <p class="txt-align-left"><span>6.5. You will cooperate with DIYS and/or governmental authorities in investigations alleging violations of any law, rule or Regulation. </span></p>
    <p class="txt-align-left"><span>6.6. You will comply with all applicable international, import, and export laws. </span></p>
    <p class="txt-align-left"><span>6.7. You are solely responsible for all Messages sent to the Service by End Users and are liable should any loss be caused to DIYS as a result of any attack on the Service (for example, any denial-of-service attack that emanates from an End User). You agree to indemnify DIYS against any loss, damage, cost or expense suffered by DIYS as a result of any such attack by an End User using the Service through you. </span></p>
    <p class="txt-align-left"><span>6.8. You agree to inform End Users of any fees or charges that will apply to End Users and obtain all necessary permissions from End Users that are required under the local law of the country of that End User, including but not limited to: </span></p>
    <ul class="list-style-none">
      <li class="txt-align-left"><span>6.8.1. every time a Short Code is promoted to the public; </span></li>
      <li class="txt-align-left"><span>6.8.2. each time an End User is solicited to do anything by means other than messaging (e.g., mobile internet);</span></li>
      <li class="txt-align-left"><span>6.8.3. every time you send End Users a Message for which they will be charged to receive; and </span></li>
      <li class="txt-align-left"><span>6.8.4. in relation to any mobile applications, proximity marketing and location-based services;</span></li>
    </ul>
    <p class="txt-align-left"><span>6.9. You agree to inform End Users that you are the supplier of services to them before making any charge to the End User, such information to include the following details: </span></p>
    <ul class="list-style-none">
      <li class="txt-align-left"><span>6.9.1 a contact address in the country in which Messages are to be sent to the End user; </span></li>
      <li class="txt-align-left"><span>6.9.2. a description of the services you are to provide to the End User; </span></li>
      <li class="txt-align-left"><span>6.9.3. all costs involved with the service you provide including how the End User will be charged; </span></li>
      <li class="txt-align-left"><span>6.9.4. that if they have a complaint or problem they should contact you and not DIYS; and </span></li>
      <li class="txt-align-left"><span>6.9.5. that they have the right to cancel the service and how to do this. </span></li>
    </ul>
    <p class="txt-align-left"><span>6.10. You agree to provide adequate support to End Users and inform them in an obvious, clearly worded, and territory specific statement of the “opt-out” process for End Users which is provided by DIYS as part of the Service. </span></p>
    <p class="txt-align-left"><span>6.11. You agree to warn all End Users that they should only send Messages or use their mobile phone in any way in connection with the Service when it is safe and legal to do so (e.g., not when driving). </span></p>
    <p class="txt-align-left"><span>6.12. You acknowledge that we shall have no responsibility in the event that any End User mistakenly sends a Message or Messages to a number or Short Code other than those which have been allocated to you or to a number allocated to you but after any campaign run by you has finished. </span></p>
    <h2 class="txt-align-left"><span>7. BUREAU SERVICE </span></h2>
    <p class="txt-align-left"><span>7.1. Unless you choose to use DIYS’s Bureau Service, then it will be solely your responsibility to identify the target End Users and ensure that they are willing participants in the survey. If you choose to make use of DIYS’s Bureau Service then DIYS will use its expertise in the supply of effective mobile surveys to obtain the details of the End Users to be targeted for a specific campaign from a panel provider or any other appropriate organisation, work with you to devise the survey questions and assist in delivering the results of that survey. </span></p>
    <h2 class="txt-align-left"><span>8. KEYWORD SERVICE </span></h2>
    <p class="txt-align-left"><span>8.1. If you make use of our Keyword Service or any other DIYS Service you are liable for the cost of all Messages sent by End Users during your campaign and at any time after your campaign has ended until such time that another client rents that keyword. </span></p>
    <p class="txt-align-left"><span>8.2 If you are using the Keyword Service for national or international activities where the End User has not already been opted in you must inform DIYS in advance and not run your large activity until we have deemed it acceptable as the local operator’s regulations may require you to have your own Short Code.</span></p>
    <h2 class="txt-align-left"><span>9. SHORT CODES / LONG NUMBERS</span></h2>
    <p class="txt-align-left"><span>The DIYS service contains a large range of country connections (numbers to send SMS over) as standard and included in your account. In some cases, however you or your clients may require connection to a country network which is not available in DIYS or is a bespoke requirement. DIYS will source, cost, add to your account(s) and provide support to maintain its use.</span></p>
    <p class="txt-align-left"><span>9.1. Every effort will be made by us to keep any Short Code provided to you running. However, we reserve the absolute right to change Short Codes where necessary in our sole and absolute discretion. </span></p>
    <p class="txt-align-left"><span>9.2. The estimated time anticipated to set-up a Short Code will be communicated to you when you place an order with DIYS. However, DIYS cannot guarantee the accuracy of any such estimate as setting up Short Codes is subject to network operator guidelines. </span></p>
    <p class="txt-align-left"><span>9.3. The rental period for a Short Code will be negotiated when you place an order. The minimum rental period for a Short Code is 6-12 months, and a long number is 3 months and any fees must be paid in advance unless an agreement is made at the time of order for you to be invoiced monthly. Short Codes will automatically renew after the agreed rental period and you will be invoiced for the renewal of the Short Code. Should you not wish the Short Code to renew you must give DIYS 6 months’ notice in writing, and 2 months for a long number. In some cases, it will not be possible for DIYS to renew a Short Code and in this case we will notify you in writing and offer you a different Short Code where possible. </span></p>
    <p class="txt-align-left"><span>9.4. The fees payable for Short Codes are subject to change should the network operator make any change to the amount it charges DIYS and any change will be notified to you in writing. </span></p>
    <p class="txt-align-left"><span>9.5. When sending a Message to an End User using a Short Code you must identify to the End User the Short Code from which the Message to them is sent. </span></p>
    <h2 class="txt-align-left"><span>10. REWARD SERVICE </span></h2>
    <p class="txt-align-left"><span>10.1. All Rewards required by you must be paid for in advance. </span></p>
    <p class="txt-align-left"><span>10.2. In relation to the Reward Service, once we have supplied a Reward to you we shall have no further involvement and you are responsible for any terms and conditions necessary between you and End Users for the provision of any Rewards to them. For the avoidance of doubt, it is you who provides the reward to the End User and not DIYS You agree to indemnify DIYS against any claims made by any End User against DIYS in relation to any Reward provided by you. </span></p>
    <p class="txt-align-left"><span>10.3. When providing or offering a Reward to an End User you agree to conform to all relevant Regulations and guidelines including the Mobile Marketing Association Guidelines, MRS, ESOMAR and any applicable local equivalent of these guidelines. </span></p>
    <p class="txt-align-left"><span>10.4. Rewards will only be available for a fixed period of time to be specified on a project basis. If that fixed period expires you will forfeit use of that reward. </span></p>
    <h2 class="txt-align-left"><span>11. MOBILE APPLICATIONS </span></h2>
    <p class="txt-align-left"><span>11.1. As part of the Service, Mobile Applications may be made available to be downloaded on to the mobile phones of End Users. Mobile Applications may be necessary for offline surveys and location-based services and providing Rewards to End Users. DIYS will manage all Mobile Applications and provide all updates, the fees for which will be agreed with you in advance. The Intellectual Property Rights in all Mobile Applications will be owned by DIYS or its licensors. DIYS will grant a licence to each End User on the terms set out in DIYS’s standard End User Licence Agreement, the acceptance of which shall be a prerequisite of any End User’s download of a Mobile Application. You shall not have any proprietary rights in any Mobile Application used as part of the Service.</span></p>
    <p class="txt-align-left"><span>11.2. You agree that, before offering a Mobile Application to an End User, you must have the express permission of the End User to install and run that application on their phone. </span></p>
    <p class="txt-align-left"><span>11.3. To the fullest extent allowed by the applicable law, DIYS accepts no liability for the malfunctioning of any End User handset which may occur as a result of them downloading a Mobile Application. The software which runs a Mobile Application may be detrimental to the running of the handset and the End User shall be made aware of this in the End User Licence Agreement. </span></p>
    <p class="txt-align-left"><span>11.4. You must provide in the Message sent the date and time information was produced for all applications involving information services such as news, stock information or sports scores. </span></p>
    <p class="txt-align-left"><span>11.5. You must inform End Users that any information displayed by a Mobile Application comes from you and not DIYS and that DIYS is merely a conduit for your marketing or market research purposes. </span></p>
    <h2 class="txt-align-left"><span>12. NETWORK ISSUES </span></h2>
    <p class="txt-align-left"><span>12.1 You shall consult with DIYS (at DIYS’s request) with a view to ensuring that your use of the Service at any time does not significantly exceed DIYS’s capacity or the relevant network operator’s capacity.</span></p>
    <p class="txt-align-left"><span>12.2 In the event that (a) such capacity is exceeded, or (b) DIYS believes, acting reasonably, that such capacity is likely to be exceeded, then upon receipt of any notice thereof given by DIYS to you, you shall promptly take all reasonable steps to prevent such excess. </span></p>
    <p class="txt-align-left"><span>12.3 You acknowledge that if DIYS is unable to contact you for the purposes stated in Paragraph; </span></p>
    <p class="txt-align-left"><span>12.4 or you fail promptly to prevent such excess, DIYS shall be entitled to take whatever action it deems appropriate to prevent such excess, including temporarily suspending the availability of the Service to you. </span></p>
    <p class="txt-align-left"><span>12.5 Without prejudice to DIYS’s other rights under these terms and conditions, any breach of Paragraph 12.2 by the Customer, and in any event any exceeding of DIYS’s or the relevant network operator’s capacity, will entitle DIYS to suspend all Services under these terms and conditions immediately, and to keep them suspended until such time as it is satisfied, acting reasonably, that such breach or excess will not be repeated in the future. </span></p>
    <h2 class="txt-align-left"><span>13. LOGIN AND CONTROL OF USERS </span></h2>
    <p class="txt-align-left"><span>13.1. It is essential that each User’s Login remains confidential to prevent third parties from accessing the Website and the Service. You will be responsible for keeping all Login details confidential and must take all reasonable steps to ensure compliance with this provision. We recommend changing your password every 30 days and using alphanumeric characters. DIYS will not be liable for any loss to you that results from any unauthorised access to the Website. </span></p>
    <p class="txt-align-left"><span>13.2. If at any point you consider that the Login of any User has been compromised and/or is being misused by someone else you must inform us immediately and thereafter take such action as is required and/or is requested by us to prevent any misuse. </span></p>
    <p class="txt-align-left"><span>13.3. You will be liable to DIYS for any loss arising from any unauthorised access to the Website or the Service which is made using your Login. </span></p>
    <p class="txt-align-left"><span>13.4. You agree not to access or attempt to access the Service or any Account Area that you are not authorised to access. </span></p>
    <p class="txt-align-left"><span>13.5. You will ensure that all Users are made fully aware of all of the terms and conditions of this agreement and shall agree to be bound by these terms before using the Service. In particular, the restrictions on use set out at clause 4.11 above will be highlighted to all Users and you shall ensure that Users comply with these terms. </span></p>
    <h2 class="txt-align-left"><span>14. PAYMENT </span></h2>
    <p class="txt-align-left"><span>14.1. Access to the Service is either via license (Enterprise, invoicing) or as agreed, or free as a DIY User, online payment). Any Subscription Fees for Enterprise users will be invoiced annually in advance with payment due within 30 days of invoice. Non-payment of the Subscription Fee may, at the absolute discretion of DIYS, lead to cancellation of your Subscription and all use of Services. All DIY users whose accounts are free to sign up will pay as they go for messages (SMS, WhatsApp) used to deliver their surveys/questions/communications, and complete surveys/forms (online surveys);</span></p>
    <p class="txt-align-left"><span>14.2. The Enterprise Non-Subscription Fees will be arranged between you and DIYS depending upon what products and services you require. Payment of the Non-Subscription Fees will usually be required in advance unless arranged otherwise with DIYS. For the avoidance of doubt, where Non-Subscription Fees are not required in advance, all invoices must be paid within 30 days. Failure to pay any invoice within 30 days could lead, at the absolute discretion of DIYS, to the cancellation or indefinite suspension of your Subscription and use of the Service at which point your Subscription Fee and any Non-Subscription Fees paid will not be refunded.</span></p>
    <ul class="list-style-none">
      <li class="txt-align-left"><span>14.2.1. DIYS reserves the right to charge interest on any overdue fees at a rate of 2% above the then current Bank of England Base Rate. Any interest will be calculated monthly and applied to all overdue fees.</span></li>
      <li class="txt-align-left"><span>14.2.2. You agree to provide DIYS with complete and accurate billing and contact information. This information includes your legal company name, street address, e-mail address, and name and telephone number of an authorised billing contact. You agree to update this information within 30 days of any change to it. If the contact information you provide is false or fraudulent, DIYS reserves the right to terminate your Subscription and your access to the Service. </span></li>
      <li class="txt-align-left"><span>14.2.3. All invoices will be issued in GBP and VAT must be paid in the UK. </span></li>
      <li class="txt-align-left"><span>14.2.4. You will be solely responsible for paying any and all applicable taxes.</span></li>
      <li class="txt-align-left"><span>14.2.5. Fees will be calculated according to DIYS’s records, as reconciled to network operator reports as applicable. In the event of discrepancies, network operator reports shall prevail.</span></li>
      <li class="txt-align-left"><span>14.2.6. In the event that you incur fees payable to DIYS which exceed £5,000 in any given month, then DIYS reserves the right to require you to prepay applicable fees or charges in subsequent months. </span></li>
    </ul>
    <p class="txt-align-left"><span>14.3. The DIY user (‘Free’ account) pays only for Non subscription fees and is in the form of SMS, WhatsApp messages, and survey completes used on a PAYG ‘Pay as you go’ basis and whereby surveys will only run if there is enough credit in your account to cover your intended use.</span></p>
    <p class="txt-align-left"><span>14.4. The Subscription Fee and the Non-Subscription Fees are subject to change from time to time by us and you will be notified of any change. Any increase in the Subscription Fee or Non-Subscription Fees shall not apply retrospectively. </span></p>
    <h2 class="txt-align-left"><span>15. CANCELLATION </span></h2>
    <p class="txt-align-left"><span>15.1. Subject to these terms and conditions, Enterprise users may cancel your Subscription by notice in writing to us (which may be by e-mail) giving us at least 6 months' prior written notice before renewal date. Subscription Fees and Non-Subscription Fees are non-refundable. </span></p>
    <h2 class="txt-align-left"><span>16. RENEWAL </span></h2>
    <p class="txt-align-left"><span>16.1. Your Subscription will renew automatically after 12 months and an invoice will be automatically issued. Should you not wish your Subscription to be renewed you must provide us with 6 months' prior written notice. </span></p>
    <h2 class="txt-align-left"><span>17. LIMITATION OF LIABILITY </span></h2>
    <p class="txt-align-left"><span>17.1. You acknowledge that the following provision reflects a fair allocation of risk. These terms and conditions set out our entire liability to you and all other liability of us to you is hereby excluded, subject that nothing shall exclude or restrict our liability for fraudulent misrepresentation and/or for death or personal injury (including but not limited to sickness and death) to the extent that such injury results from our negligence or wilful default, our servants, agents or subcontractors. </span></p>
    <p class="txt-align-left"><span>17.2. You agree that our total liability under these terms and conditions shall not exceed (death or personal injury excepted) the Subscription Fee paid by you to us, including where such liability arises out of our negligence. </span></p>
    <p class="txt-align-left"><span>17.3. In no event shall we be liable to you whether in contract, tort, by statute or otherwise in respect of any loss of profits and/or for any special, indirect, incidental or consequential loss or damage arising out of or in connection with the provision of the Service, including without limitation: loss of revenue, loss of anticipated savings, loss of business and/or goods, loss of goodwill, loss of use, loss and/or corruption of data and/or other information or software. For the avoidance of doubt, neither the types of loss and/or damage specified above nor any similar types of loss and/or damage shall constitute direct loss for the purposes of these terms and conditions. </span></p>
    <p class="txt-align-left"><span>17.4. In no event shall DIYS be liable for any costs incurred by any End User in any survey, project or campaign run by you and you will keep us fully indemnified against any such costs. </span></p>
    <p class="txt-align-left"><span>17.4. The Service is dependent on mobile network operators and their actions are out of our control. We shall not be liable for any loss caused by the actions of any mobile network operator, including but not limited to: </span></p>
    <ul class="list-style-none">
      <li class="txt-align-left"><span>17.4.1. the non-delivery or late delivery of Messages; </span></li>
      <li class="txt-align-left"><span>17.4.2. any delay in setting up a Short Code; </span></li>
      <li class="txt-align-left"><span>17.4.3. any termination or suspension of a Short Code; and </span></li>
      <li class="txt-align-left"><span>17.4.4. any loss of service. </span></li>
    </ul>
    <p class="txt-align-left"><span>17.5. The Service may be disrupted due to the misuse of other Users of the Service. In this event we will not be liable for any loss caused to you. </span></p>
    <h2 class="txt-align-left"><span>18. WARRANTIES </span></h2>
    <p class="txt-align-left"><span>18.1. You warrant to us that you will: </span></p>
    <ul class="list-style-none">
      <li class="txt-align-left"><span>18.1.1. use the Service and any associated software in a responsible manner and in accordance with standards equivalent to best practice in the industry, country specific laws and any relevant Regulations and subject to these terms and conditions; </span></li>
      <li class="txt-align-left"><span>18.1.2. not use your access to the Service in a manner not envisaged by these terms and conditions or in a way which may bring us into disrepute; </span></li>
      <li class="txt-align-left"><span>18.1.3. not use the Service for the sending of Messages without reasonable cause or for the purpose of causing annoyance, inconvenience or distress to any person; </span></li>
      <li class="txt-align-left"><span>18.1.4. not use the Service in a way that will have a detrimental effect on the goodwill or good standing of any relevant network operator; </span></li>
      <li class="txt-align-left"><span>18.1.5. conform to all internet protocols and standards from time to time applying to the Website (including the Website Terms of Use); </span></li>
      <li class="txt-align-left"><span>18.1.6. ensure that when posting or uploading data, text images, software, code information, logos, icons and all other material onto the Account Area on the Website, such is not incorrect, defamatory, obscene or otherwise illegal or infringing the intellectual property rights of any third party; </span></li>
      <li class="txt-align-left"><span>18.1.7. not use the Service for illegal activities; </span></li>
      <li class="txt-align-left"><span>18.1.8. not use the Service to send threatening or derogatory Messages; </span></li>
      <li class="txt-align-left"><span>18.1.9. not use the Service to send unsolicited or bulk Messages; </span></li>
      <li class="txt-align-left"><span>18.1.10. keep your Login confidential at all times; </span></li>
      <li class="txt-align-left"><span>18.1.11. not do, or permit any other person to do, anything to cause the Service to be shut down; </span></li>
      <li class="txt-align-left"><span>18.1.12. not use the Service to invade any person’s privacy; </span></li>
      <li class="txt-align-left"><span>18.1.13. not assign or resell any Short Code; </span></li>
      <li class="txt-align-left"><span>18.1.14. use the Service for your legitimate business needs only; </span></li>
      <li class="txt-align-left"><span>18.1.15. not exploit any bugs, weaknesses or vulnerabilities in the Service or take any action that could disable, damage, impair, injure or overburden the Service and you will not cause any disruption to the operation of the Service whether through wilful attacks such as denial of service attacks or through excessive use of the Service without giving DIYS due notice of such an increase in use of the Service; </span></li>
      <li class="txt-align-left"><span>18.1.16. prevent the unauthorised or unlawful processing of the Personal Data and ensure that you obtain consent from the End Users for DIYS’s processing of that Personal Data in connection with your use of the Service, which shall include consent for any transfer of that Personal Data to countries outside of the European Union; and </span></li>
      <li class="txt-align-left"><span>18.1.17. ensure that no copies are made of the Personal Data nor disclosures made to any third party. </span></li>
    </ul>
    <p class="txt-align-left"><span>18.2. Any breach of these warranties will entitle us at our discretion to cancel or suspend your Subscription and/or invalidate or suspend your Login without notice.</span></p>
    <p class="txt-align-left"><span>18.3 DIYS may request evidence of compliance with any of the above warranties.</span></p>
    <p class="txt-align-left"><span>18.4 We warrant to you that: - </span></p>
    <ul class="list-style-none">
      <li class="txt-align-left"><span>18.4.1. the Service, including any modifications, enhancements or updates made by DIYS, will perform, operate and function in all material respects with requirements and specifications set forth in these terms and conditions, the applicable Order Form and DIYS’s documentation. </span></li>
      <li class="txt-align-left"><span>18.4.2. the Service does not and shall not infringe the intellectual property of any third parties. </span></li>
      <li class="txt-align-left"><span>18.4.3. we have and will maintain all rights, licenses, permits and consents necessary to comply with these terms and conditions, provide the Service, and perform our obligations hereunder; </span></li>
      <li class="txt-align-left"><span>18.4.4. in providing the Service and performing our obligations hereunder, we will comply with all relevant laws, Regulations and other legal requirements; </span></li>
      <li class="txt-align-left"><span>18.4.5. we will use commercially reasonable efforts to ensure that the Service, and any modifications, enhancements and updates to the software made by DIYS, shall be free, at the time of your access, of any automatic restraints, computer viruses, software locks, time bombs or other such code that hinders your ability to exercise your rights to the Service under these terms and conditions. </span></li>
    </ul>
    <h2 class="txt-align-left"><span>19. INDEMNITIES </span></h2>
    <p class="txt-align-left"><span>19.1. You will fully indemnify and keep DIYS indemnified against all losses and damages, costs and expenses caused by any breach of the above warranties by you and in the event of any claim or legal proceedings brought/threatened against us by any End User or any other third party as a result of your actions or the actions of any individual or body using the Service in connection with any campaign, survey or project run by you. </span></p>
    <h2 class="txt-align-left"><span>20. MARKETING PERMISSION </span></h2>
    <p class="txt-align-left"><span>20.1. You agree to abide by the latest marketing and market research Regulations and follow the Mobile Marketing Association Consumer Best Practices Guidelines (and any other local equivalent of these Guidelines) at all times. In particular, you shall take all steps necessary to ensure that all End Users are willing recipients of your marketing materials. </span></p>
    <p class="txt-align-left"><span>20.2. DIYS will provide “Opt-Out” clauses for End Users. Failure by you to inform End Users of their right to opt-out or how to do so in accordance with the Mobile Marketing Association and the applicable network operator guidelines, could result in your access to the Service being suspended or withdrawn with no refund offered. You will be liable for all complaints that arise as a consequence. </span></p>
    <p class="txt-align-left"><span>20.3. You agree to acquire Explicit “opt-in” permissions from End Users as defined in the mobile marketing best practice guidelines at the initial sign-up. Failure to request and gain the explicit consent of any End User is considered a breach of these terms and conditions and could result in forfeiture of any rights you may have under these terms and conditions.</span></p>
    <p class="txt-align-left"><span>20.4. You agree not to collect information on children aged 13 or under, or to hold such information on the Service unless by express agreement and with the full knowledge of the parents of the children and according to relevant Market research guidelines that advise in these circumstances. In the event that such data is inadvertently collected and stored in the database, you agree to purge such data as soon as practicable but in no case more than twenty-four hours after the discovery is made. </span></p>
    <h2 class="txt-align-left"><span>21. INTELLECTUAL PROPERTY RIGHTS </span></h2>
    <p class="txt-align-left"><span>21.1. All intellectual property rights in the Service and other material, content, information or software relating to the same, including that contained on the Website, is and shall remain owned solely and absolutely by DIYS or its licensors, and nothing in these terms and conditions shall confer any ownership of any intellectual property rights in the same upon you. </span></p>

    <h2 class="txt-align-left"><span>22. DATA PROTECTION </span></h2>
    <p class="txt-align-left"><span>22.1. You agree to the reasonable processing of Personal Data by us for the purposes of providing you use of the DIYS Service under these terms and conditions. In processing Personal Data, we will comply with all relevant legislation, including but not limited to the Data Protection Act 2018 (which updates data protection laws in the UK and complements the European Union's General Data Protection Regulation) and other relevant laws and regulations. We may, from time to time, use your details including any company details/logos, in promotional/marketing literature. We shall obtain your consent prior to such use. </span></p>
    <p class="txt-align-left"><span>22.2 You warrant and undertake that if any of the End User or other data made available by or on behalf of you to DIYS amounts to Personal Data, with respect thereof, you shall be the data controller and DIYS the data processor as defined in the Data Protection Act 2018 and you shall comply with all applicable laws including the Data Protection Act 2018 and all other relevant data protection and privacy laws. </span></p>
    <p class="txt-align-left"><span>22.3. You acknowledge that DIYS has no obligation to retain any End User data and may delete such data. </span></p>
    <p class="txt-align-left"><span>22.4 Where DIYS is processing Personal Data for you, DIYS will: </span></p>
    <ul class="list-style-none">
      <li class="txt-align-left"><span>22.4.1	only do so on documented Customer instructions and in accordance with applicable law, including with regard to transfers of Personal Data to other jurisdictions or an international organization, and the parties agree that this Agreement constitutes such documented instructions of you to DIYS to process your Data;</span></li>
      <li class="txt-align-left"><span>22.4.2	with respect to any transfers of Personal Data out of the European Economic Area (EEA), the United Kingdom or other country requiring Standard Contractual Clauses, that may be required in relation to or in connection with the Agreement and the provision of the Services hereunder, the parties shall comply with and be subject to all obligations imposed on a ‘data importer’ or 'data exporter' (as appropriate) as set out under the Standard Contractual Clauses;</span></li>
      <li class="txt-align-left"><span>22.4.3	ensure that all DIYS personnel involved in the processing of Personal Data are subject to confidentiality obligations in respect of the Personal Data;</span></li>
      <li class="txt-align-left"><span>22.4.4	make available information necessary for you to demonstrate compliance with GDPR’s its Article 28 obligations (if applicable to you) where such information is held by DIYS and is not otherwise available to you through your account and user areas or on DIYS websites, provided that you provide DIYS with at least 14 days' written notice of such an information request;</span></li>
      <li class="txt-align-left"><span>22.4.5	cooperate as reasonably requested by you to enable you to comply with any exercise of rights by a Data Subject afforded to Data Subjects by Data Protection Legislation in respect of Personal Data processed by DIYS in providing the Services;</span></li>
      <li class="txt-align-left"><span>22.4.6 provide assistance, where necessary with all requests received directly from a Data Subject in respect of a Data Subject's Personal Data submitted through the Services;</span></li>
      <li class="txt-align-left"><span>22.4.7	upon deletion, by you, not retain your Personal Data from within your account other than in order to comply with applicable laws and regulations and as may otherwise be kept in routine backup copies made for disaster recovery and business continuity purposes subject to our retention policies;</span></li>
      <li class="txt-align-left"><span>22.4.8	cooperate with any supervisory authority or any replacement or successor body from time to time (or, to the extent required by you, any other data protection or privacy regulator under Data Protection Legislation) in the performance of such supervisory authority's tasks where required;</span></li>
      <li class="txt-align-left"><span>22.4.9	not store Personal Data (in a format that permits identification of relevant Data Subjects) for longer than is necessary for the purposes for which the data is processed save to the extent such retention is required for legitimate business purposes (with respect to, for example, security and billing), in order to comply with applicable laws and regulations and as may otherwise be kept in routine backup copies made for disaster recovery and business continuity purposes; and</span></li>
      <li class="txt-align-left"><span>22.4.10 where required by Data Protection Legislation, inform you if it comes to DIYS’s attention that any instructions received from you infringe the provisions of Data Protection Legislation, provided that notwithstanding the foregoing, DIYS shall have no obligation to review the lawfulness of any instruction received from you.  If this provision is invoked, DIYS will not be liable to you under the Agreement for any failure to perform the applicable Services until such time as you issue new lawful Instructions with regard to the Processing; and</span></li>
      <li class="txt-align-left"><span>22.4.11 assist you as reasonably required where you (i) conduct a data protection impact assessment involving the Services (which may include by provision of documentation to allow customer to conduct their own assessment); or (ii) is required to notify a Security Incident (as defined below) to a supervisory authority or a relevant data subject.</span></li>
    </ul>
    <p class="txt-align-left"><span>22.5 Use of Sub-processors. You provide a general authorisation to DIYS to engage onward sub-processors, subject to compliance with the requirements in this Section.  DIYS will, subject to any confidentiality provisions under this Agreement or otherwise imposed by DIYS: </span></p>
    <ul class="list-style-none">
      <li class="txt-align-left"><span>22.5.1 ensure that all Sub-processors (“Sub-processors”) on the Sub-processor List are bound by contractual terms that are in all material respects no less onerous than those contained in this Agreement; and </span></li>
      <li class="txt-align-left"><span>23.5.1 be liable for the acts and omissions of its Sub-processors to the same extent DIYS would be liable if performing the services of each of those Sub-processors directly under the terms of this Agreement.</span></li>
    </ul>

    <h2 class="txt-align-left"><span>23. SECURITY AND PRIVACY </span></h2>
    <p class="txt-align-left"><span>23.1 DIYS has, considering the state of the art, cost of implementation, the nature, scope, context and purposes of the Services, and the level of risk, implemented appropriate technical and organizational measures to enable a level of security appropriate to the risk of unauthorized or unlawful processing, accidental loss of and/or damage to your Data. At reasonable intervals, DIYS tests and evaluates the effectiveness of these technical and organizational measures for enabling the security of the processing.</span></p>
    <h2 class="txt-align-left"><span>24. RESTRICTIONS OF THE INTERNET </span></h2>
    <p class="txt-align-left"><span>24.1. You acknowledge that:</span></p>
    <ul class="list-style-none">
      <li class="txt-align-left"><span>24.1.1. we have no control over the Internet which is a global decentralised network of computer systems. Accordingly, access to the Service may not always be error free or uninterrupted and may be variable; and </span></li>
      <li class="txt-align-left"><span>24.1.2. information, software and other material accessible over the Internet, including mobile networks, via the Website may contain computer viruses, worms, Trojan horses, cancelbots, or other harmful and destructive components. We are not liable and will not be liable for any direct, indirect, incidental or other loss or damages which result or may result from your access to or use of the internet. We strongly recommend that you maintain sufficient and updated anti-virus software on any hardware used to access the Service and/or access material on the Website. </span></li>
    </ul>
    <p class="txt-align-left"><span>24.2. You acknowledge that it may be possible for third parties to monitor communications and information, including data traffic, while you use the Service. You accept full responsibility for the establishment of appropriate security measures to protect your own systems and your transmissions via the Service. </span></p>
    <h2 class="txt-align-left"><span>25. DOWNTIME </span></h2>
    <p class="txt-align-left"><span>25.1. Every effort is made to ensure the continuity of the Website and the Service, but some occasional technical downtime beyond our control may occur. Such downtime may prevent you from accessing the Service on the Website for the duration of the down time. We and our associated companies shall not be liable if your access to the Service via the Website is delayed or prevented by any cause beyond our control including, for example, hosting server downtime. </span></p>
    <h2 class="txt-align-left"><span>26. COMPLAINTS AND DISPUTES </span></h2>
    <p class="txt-align-left"><span>26.1. Should you have any complaint about the Service you should contact our website administrators in the first instance in accordance with Schedule One. Our website administrators will attempt to resolve the issue within the constraints of these terms and conditions. </span></p>
    <p class="txt-align-left"><span>26.2. In the event that our website administrators are unable to resolve your complaint, your complaint will be referred to the Chief Technology Officer or such other director of the company from time to time who will consult with you to resolve the issue. </span></p>
    <h2 class="txt-align-left"><span>27. TERMINATION AND SUSPENSION </span></h2>
    <p class="txt-align-left"><span>27.1. We may terminate this agreement and invalidate your Login at any time upon notice in writing (which may be by e-mail) to you: </span></p>
    <ul class="list-style-none">
      <li class="txt-align-left"><span>27.1.1. in the event of any breach by you of the provisions of these terms and conditions; </span></li>
      <li class="txt-align-left"><span>27.1.2. in the event you deliberately misuse the Service, including but not limited to obtaining Credit by deceit; </span></li>
      <li class="txt-align-left"><span>27.1.3 if you are found to be in breach of any relevant law (whether in the UK or outside the UK), Regulations or industry codes of practice. This may mean that Short Codes, Keywords and Keyword Services and any other Services are suspended without notice; </span></li>
      <li class="txt-align-left"><span>27.1.4. in the event that any relevant regulator or network operator advises DIYS that you are in breach of any laws, rules or requirements. In this case we reserve the right to suspend or terminate your Subscription until such relevant regulator or network operator is satisfied that you are in compliance with all relevant laws, rules and Regulations and that proper recompense has been made for your breach. We also reserve the right to withhold any Subscription Fees and Non-Subscription Fees paid by you to meet any sums payable to any regulator or network operator. If any regulator or network operator makes any charge or fine against DIYS as a result of your actions we shall be entitled to recover such sum from you in full plus any costs or expenses incurred by DIYS in connection with such charge or fine; and </span></li>
      <li class="txt-align-left"><span>27.1.5. in the event that DIYS is obliged or advised to comply with an order, instruction or request of the government, any regulator, network operator, court or other competent authority. </span></li>
    </ul>
    <p class="txt-align-left"><span>27.2. On termination, all data accessible via your Login on the Website will be dealt with in accordance with our data security policy in effect at that time. </span></p>
    <p class="txt-align-left"><span>27.3. If this Agreement is terminated by reason of your breach, any Subscription Fee or Non-Subscription Fees paid to us by you shall be non-refundable. </span></p>
    <p class="txt-align-left"><span>27.4. We reserve the right to charge a reasonable reconnection fee in the event that your Subscription is terminated and you wish it to be restored. </span></p>
    <p class="txt-align-left"><span>27.5. You agree that, to maintain or improve the Service, or for other business reasons, DIYS can, in its sole discretion suspend, restrict, modify or terminate the Service or the DIYS facilities, including the Website, without notice. You acknowledge and agree that DIYS reserves the right, in its sole discretion and without notice to you, to make changes from time to time in any aspect of the DIYS Service and facilities. </span></p>
    <h2 class="txt-align-left"><span>28. CONFIDENTIALITY </span></h2>
    <p class="txt-align-left"><span>28.1. You may disclose that you share a business relationship with DIYS and that you subscribe to the Service. Further details of your business relationship with DIYS shall not be disclosed without the express consent of DIYS. </span></p>
    <p class="txt-align-left"><span>28.2. DIYS and you undertake and agree with the other that it shall not, unless compelled so to do by a Court or other authority of competent jurisdiction, either during the continuance of the Subscription or at any time thereafter, (and shall procure that its employees, servants, or agents do not) disclose to any third party any trade secret or information of a confidential nature disclosed to it by the other party including in particular, but without limitation, information relating to the other party, its affairs, business or method of carrying on business (including the content of DIYS's commercial proposition), information and data related to the Service, the terms (but not the existence) of these terms and conditions and related matters, whatsoever without the prior written consent of the other party. </span></p>
    <p class="txt-align-left"><span>28.3. The provisions of this clause shall not apply in respect of information if: </span></p>
    <ul class="list-style-none">
      <li class="txt-align-left"><span>28.3.1. the other party has provided written authorisation for the disclosure of such information; </span></li>
      <li class="txt-align-left"><span>28.3.2. such information is or becomes publicly known through no fault of the party wishing to disclose it; or </span></li>
      <li class="txt-align-left"><span>28.3.3. such information is already in the possession of the receiving party at the time of disclosure by the disclosing party as evidenced by the files and records of the receiving party immediately prior to the time of disclosure; or </span></li>
      <li class="txt-align-left"><span>28.3.4. either party divulges such information to any Court, Tribunal, Government Authority or other regulatory body with competent jurisdiction provided that the disclosing party has first notified the other in writing of such request. </span></li>
    </ul>
    <p class="txt-align-left"><span>28.4. For the avoidance of doubt, the obligations in this clause shall survive the termination of the Subscription. </span></p>
    <h2 class="txt-align-left"><span>29. NOTICES </span></h2>
    <p class="txt-align-left"><span>29.1. Any notice required or permitted under these terms and conditions shall be in writing (which may be by e-mail) and shall be deemed to have been properly given: </span></p>
    <ul class="list-style-none">
      <li class="txt-align-left"><span>29.1.1. 48 hours after being posted by first class mail (by airmail if sent internationally) with postage prepaid to the address for us set out in these terms and conditions and for you as set out in the Subscription request or other address notified for the purpose; or</span></li>
      <li class="txt-align-left"><span>29.1.2. at close of business (17:00 hours in the UK) on the day of effective transmission if sent by e-mail. </span></li>
    </ul>
    <h2 class="txt-align-left"><span>30. GENERAL </span></h2>
    <p class="txt-align-left"><span>30.1. No provision of these Terms and Conditions shall be enforceable under the Contracts (Rights of Third Parties) Act 1999 by a person who is not party to it, except for the avoidance of doubt by any one or more of our subsidiaries, if any, from time to time. </span></p>
    <p class="txt-align-left"><span>30.2. We may assign our rights or obligations under these terms and conditions at any time. Subject to the other provisions of these terms and conditions, you may not assign your rights or obligations under these terms and conditions without our prior written consent. </span></p>
    <p class="txt-align-left"><span>30.3. We shall not be liable for failure to meet our obligations under these terms and conditions if we are prevented from or delayed in doing so due to circumstances beyond our reasonable control including, without limitation, acts of God, governmental actions, war or national emergency, acts of terrorism, protests, riot, civil commotion, fire, explosion, flood, epidemic, lock-outs, strikes or other labour disputes, or restraints or delays affecting carriers or inability or delay in obtaining supplies of adequate or suitable materials. </span></p>
    <p class="txt-align-left"><span>30.4. We reserve the right to modify these terms and conditions at any time, effective upon posting of an updated version of this document on the Website. You are responsible for regularly reviewing this document on the Website. Continued use of the Service after any such changes shall constitute your consent to such changes.</span></p>
    <p class="txt-align-left"><span>30.5. You may link to our website, provided you do so in a way that is fair and legal and does not damage our reputation or take advantage of it, but you must not establish a link in such a way as to suggest any form of association, approval or endorsement on our part where none exists. You must not establish a link from any website that is not owned by you. Our website must not be framed on any other site, nor may you create a link to any part of our site other than the home page. We reserve the right to withdraw linking permission without notice. </span></p>
    <h2 class="txt-align-left"><span>31. GOVERNING LAW </span></h2>
    <p class="txt-align-left"><span>31.1. DIYS does not represent or warrant that the Service complies with the regulatory regime of any countries other than the United Kingdom and The United States of America. If you access the Service from locations outside the United Kingdom or the United States of America, you do so entirely at your own risk and you are responsible for compliance with local laws. </span></p>
    <p class="txt-align-left"><span>31.2. These terms and conditions shall be interpreted in accordance with and governed by English law and the parties submit to the exclusive jurisdiction of the English courts.</span></p>
    <div></div>
    <h2 class="txt-align-left"><span>SCHEDULE ONE </span></h2>
    <h2 class="txt-align-left"><span>1. MAINTENANCE </span></h2>
    <p class="txt-align-left"><span>1.1. We will provide corrections, fixes and periodic notices. If you have a warranty period defined within your agreement with us, we shall correct a defect free of charge, within the warranty period, if you are able to demonstrate that there is programming or other defect in the Service for which you are not responsible and which prevents you from using the Service. This is dependent upon you giving reasonable time and resources for testing and sign-off. </span></p>
    <p class="txt-align-left"><span>1.2. If any defect arises in the Service or in its use and/or operation then once such defect has been notified to us by you, or otherwise comes to our attention, we shall use all reasonable endeavours to correct each such defect within a reasonable time based upon the priority of that defect. Once each such defect has been corrected we shall implement any specific procedures which are necessary to update the Service. Whilst we shall use all reasonable endeavours to correct Service defects we cannot guarantee to fix a Service defect within a specified period of time or at all. </span></p>
    <p class="txt-align-left"><span>1.3. In the event of operational difficulty with the Service you may contact us for assistance in accordance with the Operational Support Procedure below. We shall assist you to recover system operations in the event of failure, operator error or system misuse in accordance with this Agreement. If you request any changes or enhancements to the Service which require development work by us or any changes as a result of your misuse of the Service, the costs of such enhancements or changes will be charged at £850 per day or part thereof. </span></p>
    <h2 class="txt-align-left"><span>2. UPDATES </span></h2>
    <p class="txt-align-left"><span>2.1. We may issue new releases of the Service which will, where appropriate, incorporate full corrections in lieu of defect corrections on the earlier release. We reserve the right to use new releases of the Service to rectify Service defects under the obligations of this Agreement, if such updates to rectify defects are permitted at no extra charge. </span></p>
    <h2 class="txt-align-left"><span>3. EXCLUDED SERVICES </span></h2>
    <p class="txt-align-left"><span>3.1. We shall not be liable for operational difficulties caused by or arising from the integration of the Service with hardware or services not supplied by us or any modifications not undertaken by or on behalf of us or our subcontractors. </span></p>
    <h2 class="txt-align-left"><span>4. SUPPORT </span></h2>
    <p class="txt-align-left"><span>4.1. DIYS shall endeavour to respond to all support requests within 24 business (weekdays excluding public and bank holidays in England and Wales) hours of receipt of the request. DIYS shall use all reasonable endeavours to provide a solution for any support requests, within a reasonable time, based upon the urgency of the request. While DIYS will use all reasonable endeavours to correct Service defects, DIYS cannot guarantee a fix to a Service defect within a specified period of time or at all.</span></p>
    <p class="txt-align-left"><span>4.2. All support is available from 9:00am to 5:00pm UK time from Monday to Friday excluding bank holidays and public holidays in England and Wales. The escalation procedure for the provision of support shall be as follows:</span></p>
    <ul>
      <li class="txt-align-left"><span>Level One – Email Support. You can contact us via the support&#64;diysurveys.com All emails will be responded to within 24 business hours;</span></li>
      <li class="txt-align-left"><span>Level Two – Chat Support. This is available to Enterprise users and should only be used once email support has been requested. </span></li>
      <li class="txt-align-left"><span>Level Three – Onsite Support. This is available to you on request and is charged on a daily rate to a minimum of a half a day (£850 per day) plus expenses;</span></li>
    </ul>
    <p class="txt-align-left"><span>4.3. An incident will be prioritised at our discretion taking into account your position and other support calls. Whilst we can respond to a call within fixed times we are unable to provide fixed times for resolution of any one incident. We shall use our reasonable endeavours to keep you up to date with the progress of any incident.</span></p>
    <p class="txt-align-left"><span>4.4. You will be accessing the Service via the Internet and mobile technology. It is your responsibility to ensure that your telecommunications links are, at all times, adequate to enable such access. We shall not be responsible for providing support for any issues associated with your telecommunications links. However, if requested, we shall use our reasonable endeavours to assist in the rectification of any such faults at a cost of £850 per day or part thereof.</span></p>
    <p class="txt-align-left"><span>4.5. In the event that you require to purchase support services over and above that provided as part of your Subscription (as outlined in paragraphs 4.1, 4.2, 4.3 above), the price that we quote will be based on the following: </span></p>
    <ul>
      <li class="txt-align-left"><span>The number of Users accessing the Service and the load placed upon the Service; </span></li>
      <li class="txt-align-left"><span>The level of functionality of the Service as documented and offered under the general provisions; </span></li>
      <li class="txt-align-left"><span>The volume of support calls per month (calculated over a three-month rolling period) remaining within 10% of the average number of support calls calculated over the first three months of this Agreement; </span></li>
    </ul>
    <p class="txt-align-left"><span>In the event of any variation to any of these factors we reserve the right to renegotiate the support costs.</span></p>
  </div>
</div>
<app-newfooter></app-newfooter>
