<div *ngIf="userFeatures && basicEditor" class="fullHeight">
  <mat-horizontal-stepper linear #stepper class="fullHeight">
    <mat-step *ngIf="displayQuestionEditor" [completed]="hasScript">
      <ng-template matStepLabel>Design Survey</ng-template>
      <ng-template #designSurveyTourContent>
        <strong> Design Survey </strong>
        <br />
        <br />
        Step 1: Create and test your survey
      </ng-template>
      <question-editor [(survey)]="survey"></question-editor>
    </mat-step>
    <mat-step>
      <ng-template matStepLabel>Select Respondents</ng-template>
      <ng-template #selectRespondentTourContent>
        <strong> Select Respondents </strong>
        <br />
        <br />
        Step 2: Choose respondents and delivery method (SMS, email or WhatsApp)
      </ng-template>
      <app-select-respondents-landing-page #step2 (secondStepEvent)="moveToNextStep()" [interview]="interview" [survey]="survey"></app-select-respondents-landing-page>
    </mat-step>
    <mat-step *ngIf="interview" label="Make Live">
      <ng-template matStepLabel #step3>Make Survey Live</ng-template>
      <ng-template #surveyLiveTourContent>
        <strong> Make Survey Live </strong>
        <br />
        <br />
        Step 3: Schedule and send your survey
      </ng-template>
    </mat-step>
  </mat-horizontal-stepper>
</div>
<div *ngIf="userFeatures && !basicEditor">
  <mat-tab-group mat-stretch-tabs="false" mat-align-tabs="start">
    <mat-tab *ngIf="displayQuestionEditor" label="Survey">
      <question-editor [(survey)]="survey"></question-editor>
    </mat-tab>
    <mat-tab label="Respondents">
      <div style="margin:5px">
        <app-distribution-list [survey]="survey" [interview]="interview"></app-distribution-list>
      </div>
      <div style="margin:5px 0 5px 0">
        <mat-divider></mat-divider>
      </div>
      <app-respondent-source [survey]="survey" [interview]="interview"></app-respondent-source>
    </mat-tab>
    <mat-tab *ngIf="showDataAnalysis()" label="Data">
      <app-basic-data [survey]="survey" [interview]="interview" [modelChecked]="modelChecked"></app-basic-data>
    </mat-tab>
    <mat-tab *ngIf="showSmsInsights()" label="Message Insights">
      <app-message-history [survey]="survey"></app-message-history>
    </mat-tab>
    <mat-tab *ngIf="showDataAnalysis()" label="Reporting">
      <app-reports [survey]="survey" [interview]="interview" [modelChecked]="modelChecked"></app-reports>
    </mat-tab>
    <mat-tab label="Exports">
      <app-automations [survey]="survey" [interview]="interview" [exportOnly]="true"></app-automations>
    </mat-tab>
    <mat-tab *ngIf="showDataAnalysis()" label="Quotas">
      Need to work on this
    </mat-tab>
  </mat-tab-group>
</div>
