import { Component, Input } from '@angular/core';
import { plainToClass } from 'class-transformer';
import { WindowModel } from '../../authentication.Service';
import { Schedule } from '../../scheduler/scheduler.component';
import { SurveyModel } from '../../survey.Service';

@Component({
  selector: 'app-survey-schedular',
  templateUrl: './survey-schedular.component.html',
  styleUrls: ['./survey-schedular.component.css']
})
export class SurveySchedularComponent {

  displayScheduler: boolean = false;
  windows: WindowModel[] = [];
  schedule: Schedule;
  scheduleType: string = "now";

  @Input()
  get survey(): SurveyModel {
    return this._survey;
  }
  set survey(value: SurveyModel) {
    this._survey = value;
    if (this._survey == undefined || this._survey == null || this.survey.JobMaster == null) {
      return;
    }

    let timezone = this.survey.JobMaster.Name;
    this.schedule = new Schedule(timezone, this.survey.JobMaster.Start, this.survey.JobMaster.End, this.survey.JobMaster.CronExpression);
    this.selectedScheduleType();
    if (this.scheduleType == "cron") {
      this.openScheduler();
    }
    else {
      this.selectNow();
    }

    this.windows = [];
    if (this.survey.Windows != undefined) {
      for (let i = 0; i < this.survey.Windows.length; i++) {
        this.windows.push(plainToClass(WindowModel, this.survey.Windows[i]));
      }
    }
  }
  private _survey: SurveyModel;

  selectNow() {
    this.displayScheduler = false;
    this.scheduleType = "now";
  }

  openScheduler() {
    this.displayScheduler = true;
    this.scheduleType = "cron";
  }

  onWindowChange(value: WindowModel[]) {
    this.windows = value;
  }

  scheduleChanged(value: any) {

  }

  selectedScheduleType() {
    if (this.survey.JobMaster) {
      if (this.survey.JobMaster.CronExpression === "") {
        this.scheduleType = "now";
      }
      else {
        this.scheduleType = "cron";
      }
    }
  }
}
