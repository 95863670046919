import { Input } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { PanelModel, PanelService } from '../../panel.Service';

@Component({
  selector: 'app-panel-summary',
  templateUrl: './panel-summary.component.html',
  styleUrls: ['./panel-summary.component.css']
})
export class PanelSummaryComponent implements OnInit {

  panel: PanelModel | null = null;
  constructor(private panelService: PanelService) { }

  ngOnInit(): void {
    this.panelService.getPanel(this._panelId)
      .subscribe(result => {
        this.panel = result;
       });
  }

  respondentStatement() {
    let response = this.panel?.PanellistCount + " respondent";
    if (this.panel?.PanellistCount == 1) {
      return response;
    }

    return response + "s";
  }

  @Input()
  get panelId() {
    return this._panelId;
  }
  set panelId(value: number) {
    this._panelId = value;
    this.ngOnInit();
  }
  private _panelId: number = -1;
}
