<div class="leftFloat">
  <h2>{{title}}</h2>
  <p>
    <button mat-raised-button class="mt-5" color="primary" (click)="newProcess()">{{buttonText}}</button>
  </p>
</div>
<div class="rightFloat">
  <button mat-icon-button (click)="close()">
    <mat-icon>close</mat-icon>
  </button>
</div>
<div class="outer-table-container mat-elevation-z8 clearBoth">
  <app-configured-processes (select)="selectProcess(true, $event)" [processes]="processes"></app-configured-processes>
</div>
