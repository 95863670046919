<div>
  <div class="content-container txt-align-center">
    <br />
    <span class="content-span fa-battery-full-width mat-confirm-dialog-span">{{data.message}}</span>
  </div>
  <br />
  <div class="mat-confirm-dialog-button-row txt-align-center">
    <button mat-raised-button color="primary" [mat-dialog-close]="true">Ok</button>
    <button mat-raised-button color="accent" [mat-dialog-close]="false">Dismiss</button>
  </div>
  <div class="mat-confirm-dialog-bottom-div"></div>
</div>
