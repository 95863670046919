<div>
  <form class="dlccampaignform" [formGroup]="formGroup">

    <mat-form-field appearance="outline" class="msg-hist-filtByCountry-field">
      <mat-label>Use case</mat-label>
      <mat-select formControlName="usecase">
        <mat-option value="Low Volumne Mixed">Low Volumne Mixed</mat-option>
        <mat-option value="Marketing">Marketing</mat-option>
      </mat-select>
    </mat-form-field>&nbsp;

    <mat-form-field appearance="outline" class="msg-hist-filtByNum-field">
      <mat-label>Campaign name</mat-label>
      <input matInput placeholder="Campaign name" formControlName="campaignname">
    </mat-form-field>&nbsp;

    <mat-form-field appearance="outline" class="msg-hist-filtByNum-field">
      <mat-label>Campaign Description</mat-label>
      <input matInput placeholder="Campaign Description" formControlName="campaigndescription">
    </mat-form-field>&nbsp;

    <mat-form-field appearance="outline" class="msg-hist-filtByNum-field">
      <mat-label>Sample Message</mat-label>
      <input matInput placeholder="Sample Message" formControlName="samplemessage">
    </mat-form-field>&nbsp;

    <br />

    <mat-form-field appearance="outline" class="msg-hist-filtByCountry-field">
      <mat-label>Subscriber Opt-in flow</mat-label>
      <mat-select formControlName="suboptinflow">
        <mat-option value="Opt-in keyword">Opt-in keyword</mat-option>
        <mat-option value="Opt-in sample message">Opt-in sample message</mat-option>
      </mat-select>
    </mat-form-field>&nbsp;

    <mat-form-field appearance="outline" class="msg-hist-filtByNum-field">
      <mat-label></mat-label>
      <input matInput placeholder="Message box" formControlName="messageboxin">
    </mat-form-field>&nbsp;

    <br />
    <mat-form-field appearance="outline" class="msg-hist-filtByCountry-field">
      <mat-label>Subscriber Opt-out</mat-label>
      <mat-select formControlName="suboptoutflow">
        <mat-option value="Opt-out keyword">Opt-out keyword</mat-option>
        <mat-option value="Opt-out sample message">Opt-out sample message</mat-option>
      </mat-select>
    </mat-form-field>&nbsp;

    <mat-form-field appearance="outline" class="msg-hist-filtByNum-field">
      <mat-label></mat-label>
      <input matInput placeholder="Message box" formControlName="messageboxout">
    </mat-form-field>&nbsp;

    <br />
    <mat-form-field appearance="outline" class="msg-hist-filtByCountry-field">
      <mat-label>Subscriber help</mat-label>
      <mat-select formControlName="suboptinflowhelp">
        <mat-option value="Help keyword">Help keyword</mat-option>
        <mat-option value="Help sample message">Help sample message</mat-option>
      </mat-select>
    </mat-form-field>&nbsp;

    <mat-form-field appearance="outline" class="msg-hist-filtByNum-field">
      <mat-label></mat-label>
      <input matInput placeholder="Message box" formControlName="messageboxhelp">
    </mat-form-field>&nbsp;

    <br />

    <button mat-flat-button color="primary" (click)="newSearch()">Send</button>
  </form>
</div>
<br />

<div *ngIf="displayError">
  <div class="alert alert-danger" role="alert">
    {{errorMsg}}
  </div>
</div>

<div *ngIf="displaySuccess">
  <div class="alert alert-success" role="alert">
    Email has been sent for your 10 DLC_Campaign Registration.
  </div>
</div>
