<div *ngIf="preview" matRipple>
  <app-advanced-properties [model]="model"></app-advanced-properties>
  <div class="question-var-container" (click)="click()">
    <div class="column-flex auto-flex fullWidth">
      <div class="ml-15 auto-flex" *ngIf="model.Banner !== ''">[{{ model.QuestionName }}]</div>
      <div class="auto-flex">
        <diy-player #canvas [show]="show" [model]="previewModel" [showJson]="false" [preview]="true"></diy-player>
      </div>
    </div>
    <div class="question-icon">
      <mat-icon>star_rate</mat-icon>
    </div>
  </div>
  <div class="question-separator">
  </div>
</div>
<div *ngIf="!preview" [formGroup]="ratingFormGroup">
  <div formArrayName="ratingFormArray">
    <app-add-question-button action="before" (doAction)="questionAction($event)"></app-add-question-button>
    <app-advanced-properties [model]="model"></app-advanced-properties>
    <div *ngFor="let opt of questionArr?.controls; let i = index" [formGroupName]="i">
      <div class="question-var-container" (click)="click()">
        <mat-form-field appearance="outline">
          <mat-label>Question Identity</mat-label>
          <input matInput formControlName="qNumber" [patterns]="customPatterns" mask="SA*">
        </mat-form-field>
        <div class="question-icon">
          <div class="question-copy-btn-div">
            <button mat-mini-fab color="accent" matTooltip="Copy Question" (click)="questionAction('copy')"><mat-icon>content_copy</mat-icon></button>
          </div>
          <div>
            <button mat-mini-fab color="accent" matTooltip="Delete Question" (click)="questionAction('delete')"><mat-icon>delete</mat-icon></button>&nbsp;
          </div>
          <div class="question-type-icon-div">
            <mat-icon>star_rate</mat-icon>
          </div>
        </div>
      </div>
      <div>
        <diy-editor [channel]="model.Interview.Channel" [questions]="model.Interview.reportableQuestions()" formControlName="banner"></diy-editor>
      </div>
      <div class="margin-top-bot-10">
        <div mat-line>
          <mat-form-field appearance="outline">
            <mat-label>Min Value</mat-label>
            <input matInput [readonly]="model.Control === 'star'" formControlName="minValue">
          </mat-form-field>
          &nbsp;
          <mat-form-field appearance="outline">
            <mat-label>Min Label</mat-label>
            <input matInput formControlName="minLabel">
          </mat-form-field>
        </div>
        <div mat-line>
          <mat-form-field appearance="outline">
            <mat-label>Max Value</mat-label>
            <input matInput formControlName="maxValue">
          </mat-form-field>
          &nbsp;
          <mat-form-field appearance="outline">
            <mat-label>Max Label</mat-label>
            <input matInput formControlName="maxLabel">
          </mat-form-field>
        </div>
        <div *ngIf="model.NotApplicable">
          <mat-form-field appearance="outline" class="wd-80-per">
            <mat-label>Not Applicable Label</mat-label>
            <input matInput formControlName="naLabel">
          </mat-form-field>
        </div>
      </div>
      <div *ngIf="model.Control === 'radiobutton' || model.Control === 'link'" class="margin-top-bot-10 display-flex wd-90-per">
        <form [formGroup]="optionFormGroup" [class]="(isHandset$ | async) ? 'fullWidth' : 'wd-90-per'">
          <div [class]="(isHandset$ | async) ? 'column-flex' : 'display-flex'">
            <div formArrayName="rowOptions" [class]="(isHandset$ | async) ? 'fullWidth' : 'fullWidth'" [hidden]="!showRatingOptions">
              <div *ngFor="let opt of rowOptionArr.controls; let i = index" [formGroupName]="i">
                <mat-form-field appearance="outline">
                  <mat-label> Value {{i+1}} </mat-label>
                  <input matInput formControlName="valueInput">
                </mat-form-field>&nbsp;&nbsp;
                <mat-form-field appearance="outline">
                  <mat-label> Label {{i+1}} </mat-label>
                  <input matInput formControlName="labelInput">
                </mat-form-field>&nbsp;&nbsp;
                <mat-form-field appearance="outline" [hidden]="!opt.valid">
                  <mat-label> Route to </mat-label>
                  <mat-select>
                    <mat-option>None</mat-option>
                    <mat-option *ngFor="let question of questions" [value]="question.id">
                      {{question.value}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
                <button mat-icon-button matTooltip="Remove option" matTooltipPosition="above"
                        [hidden]="!opt.valid" (click)="removeRowOption(i)">
                  <mat-icon>delete</mat-icon>
                </button>
                <button mat-icon-button matTooltip="Add new answer option" matTooltipPosition="above"
                        [hidden]="!opt.valid" (click)="addNewRowOption()">
                  <mat-icon>add</mat-icon>
                </button>
              </div>
            </div>
          </div>
          <div class="rating-else-route-div" [hidden]="!opt.valid">
            <mat-form-field appearance="outline">
              <mat-label> Else </mat-label>
              <input matInput disabled>
            </mat-form-field>
            &nbsp;&nbsp;&nbsp;
            <mat-form-field appearance="outline" [hidden]="optionFormGroup.invalid">
              <mat-label> Route to </mat-label>
              <mat-select>
                <mat-option>None</mat-option>
                <mat-option *ngFor="let question of questions" [value]="question.id">
                  {{question.value}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </form>
      </div>
      <app-add-question-button action="after" (doAction)="questionAction($event)"></app-add-question-button>
    </div>
  </div>
</div>
