<button mat-icon-button (click)="isOpen = !isOpen" cdkOverlayOrigin #trigger="cdkOverlayOrigin" aria-label="icon-button with a menu">
  <mat-icon>more_vert</mat-icon>
</button>
<ng-template
  cdkConnectedOverlay
  [cdkConnectedOverlayOrigin]="trigger"
  [cdkConnectedOverlayOpen]="isOpen"
  [cdkConnectedOverlayHasBackdrop]="true"
  cdkConnectedOverlayBackdropClass="action-backdrop-class"
  (backdropClick)="isOpen = false"
>
<div class="question-action-menu">
    <button mat-menu-item class="question-action-vert-button" (click)="click('before')"><mat-icon>add</mat-icon></button>
    <button mat-menu-item class="question-action-vert-button" (click)="click('copy')"><mat-icon>content_copy</mat-icon></button>
    <button mat-menu-item class="question-action-vert-button" (click)="click('delete')"><mat-icon>delete</mat-icon></button>
    <button mat-menu-item class="question-action-vert-button" (click)="click('after')"><mat-icon>add</mat-icon></button>
</div>
  </ng-template>
