<div class="leftFloat">
  <h2>Edit Your Report Details</h2>
</div>
<div class="rightFloat">
  <button mat-icon-button mat-dialog-close>
    <mat-icon>close</mat-icon>
  </button>
</div>
<div class="clearBoth"></div>
<div class="fullWidth display-flex align-center-center-flex fill-flex">
  <form class="edit-report-form align-center-center-flex column-flex" [formGroup]="formGroup">
    <div *ngIf="displayError">
      <div class="alert alert-danger" role="alert">
        {{errorMsg}}
      </div>
    </div>
    <div class="controls-container">
      <div class="edit-report-fields-container">
        <mat-form-field appearance="outline">
          <mat-label>Report Title</mat-label>
          <input formControlName="ReportTitle" required="true" matInput placeholder="Report Title *">
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>Description</mat-label>
          <textarea formControlName="ReportDescription" matInput placeholder="Description"></textarea>
        </mat-form-field>
      </div>
    </div>
    <button mat-raised-button class="edit-report-save-button" (click)="onSubmit()" type="submit" [disabled]="formGroup.invalid">Save</button>
  </form>
</div>
