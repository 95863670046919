import { Component } from '@angular/core';
import { Observable } from "rxjs";
import { tap } from 'rxjs/operators';
import { GatewayTranSummary, MessageService } from '../../message.Service';
import { TranslateService } from '@ngx-translate/core';
import { MatTableDataSource } from '@angular/material/table';
import { flipInYAnimation } from 'angular-animations';

@Component({
  selector: 'app-invite-analysis',
  templateUrl: './invite-analysis.component.html',
  styleUrls: ['./invite-analysis.component.css'],
  animations: [ flipInYAnimation() ]
})
export class InviteAnalysisComponent {
  tempSummary: Observable<any>;
  summary: GatewayTranSummary[] = null;
  displayedColumns = ['OwnerID', 'When', 'SurveyDescription', 'Count'];
  dataSource = new MatTableDataSource(this.summary);
  showSummary = true;
  animation = 'flipInY';
  animationState = false;
  animationWithState = false;

  constructor(public translate: TranslateService,
    private messageService: MessageService) {
    this.tempSummary = this.messageService.getGatewaySummary("total").pipe(tap<GatewayTranSummary[]>(
      result => {
        this.summary = result;
        this.dataSource = new MatTableDataSource(this.summary);
      }
    ));
  }

  userDetails(element: GatewayTranSummary): string {
    return element.UserProfile.UserName + "\n" + element.UserProfile.FirstName + " " + element.UserProfile.LastName + "\n(" + element.UserProfile.EmailAddress + ")";
  }

  edit(row: any) {

  }

  filterResults() {
    this.animate();
  }

  noSave() {
    this.animate();
  }

  animate() {
    this.animationState = false;
    setTimeout(() => {
      this.showSummary = !this.showSummary;
      this.animationState = true;
      this.animationWithState = !this.animationWithState;
    }, 1);
  }
}
