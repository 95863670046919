import { Component, Input, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Observable, tap } from 'rxjs';
import { AuthenticationService, SecurityKey } from '../../authentication.Service';
import { SmsHomeComponent } from '../../sms-home/sms-home.component';

@Component({
  selector: 'app-keys',
  templateUrl: './keys.component.html',
  styleUrls: ['./keys.component.css']
})
export class KeysComponent implements OnInit {

  tempKeys: Observable<any>;
  Keys: SecurityKey[] = [];
  dataSource = new MatTableDataSource(this.Keys);
  displayedColumns: string[] = ['Name', 'Created', 'Amended', 'Action'];
  actionType: string = "profile";

  constructor(private authService: AuthenticationService,
    public translate: TranslateService,
    private actRoute: ActivatedRoute,
    private router: Router) {
    this.actionType = this.actRoute.snapshot.params.type;
    if (this.actionType == undefined) {
      this.actionType = 'profile';
    }
  }

  ngOnInit(): void {
  }

  newKey() {
    this.router.navigate(["/securitykey", 0, this.keyType, this.userId, this.actionType]);
  }

  edit(key: SecurityKey) {
    this.router.navigate(["/securitykey", key.KeyId, this.keyType, this.userId, this.actionType]);
  }

  delete(key: SecurityKey) {
    key.IsDeleted = true;
    this.authService.setSecurityKey(key).subscribe();
    for (let i = 0; i < this.Keys.length; i++) {
      if (this.Keys[i].KeyId == key.KeyId) {
        this.Keys.splice(i, 1);
        this.dataSource = new MatTableDataSource(this.Keys);
        return;
      }
    }
  }

  transfer(key: SecurityKey) {
    this.router.navigate(["/transfer", key.KeyId, this.actionType]);
  }

  @Input()
  get keyType(): string {
    return this._keyType;
  }
  set keyType(value: string) {
    this._keyType = value;
    if (this._keyType && this._userId > 0) {
      this.findKeys();
    }
  }
  private _keyType: string;

  @Input()
  get userId(): number {
    return this._userId;
  }
  set userId(value: number) {
    this._userId = value;
    if (this._keyType && this._userId > 0) {
      this.findKeys();
    }
  }
  private _userId: number = -1;

  findKeys() {
    this.tempKeys = this.authService.getSecurityKeysForAccount(this._keyType, this._userId).pipe(tap<SecurityKey[]>(result => {
      this.Keys = result;
      this.dataSource = new MatTableDataSource(this.Keys);
    }));
  }
}
