import { AfterViewInit, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { JobMasterModel, SurveyModel, SurveyService } from '../../survey.Service';
import { CommsModel, CommsService, WhatsAppFullTemplate } from '../../comms.Service';
import { SmsEditorComponent } from '../../Development/sms-editor/sms-editor.component';
import { MatSelectChange } from '@angular/material/select';
import { deserialize } from 'class-transformer';

@Component({
  selector: 'app-message-builder',
  templateUrl: './message-builder.component.html',
  styleUrls: ['./message-builder.component.css']
})
export class MessageBuilderComponent implements OnInit {

  formGroup: FormGroup = new FormGroup({});
  survey: SurveyModel;
  subject: string = "";
  content: string = "";
  channel: number;
  comms: CommsModel[] = null;

  @ViewChild('smseditor') public smseditor: SmsEditorComponent | undefined;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<MessageBuilderComponent>,
    private surveyService: SurveyService,
    private commsService: CommsService,
    private fb: FormBuilder) {

    this.survey = data.survey;
    this.channel = data.channel;
    let tempChannel = 0;
    switch (this.channel) {
      case 1:
        tempChannel = 0;
        break;
      case 5:
        tempChannel = 1;
        break;
      default:
        tempChannel = 2;
        break;
    }

    this.formGroup = this.fb.group({
      subject: new FormControl("", [Validators.required]),
      content: new FormControl("", [Validators.required])
    });
    this.messageType == 'whatsapp' ? this.formGroup.controls.content.disable() : this.formGroup.controls.content.enable();

    this.commsService.getTemplatesToUse(tempChannel).subscribe(
      result => {
        let temp = [];
        if (this.messageType == "whatsapp") {
          for (let i = 0; i < result.length; i++) {
            try {
              let template = deserialize(WhatsAppFullTemplate, result[i].EmailContent);
              if (template.template.status == "APPROVED") {
                temp.push(result[i]);
              }
            }
            catch { }
          }

          this.comms = temp;
          if (this.comms.length == 1) {
            let template = deserialize(WhatsAppFullTemplate, this.comms[0].EmailContent);
            this.subject = template.template.name + ":" + template.template.language;
            this.content = template.message;
          }

          this.setValues();
          this.populateForm();
          return;
        }

        this.comms = result;
        if (this.comms.length == 1) {
          this.subject = this.comms[0].Subject;
          this.content = this.comms[0].EmailContent;
          this.setValues();
          this.populateForm();
        }
      }
    )

  }

  setValues() {
    if (this.survey.JobMaster && this.survey.JobMaster.Content != ""
        && (this.survey.JobMaster.Subject != "" && this.messageType == "email" || this.subject == "" && this.messageType != "email")) {
      this.subject = this.survey.JobMaster.Subject;
      this.content = this.survey.JobMaster.Content;
    }
  }

  ngOnInit(): void {
  }

  populateForm(): void {
    this.formGroup.controls.subject.setValue(this.subject);
    this.formGroup.controls.content.setValue(this.content);
  }

  selectionChanges(event: MatSelectChange) {
    for (let i = 0; i < this.comms.length; i++) {
      if (this.comms[i].EmailTemplateID == event.value) {
        this.formGroup.controls.subject.setValue(this.comms[i].Subject);
        if (this.messageType == "whatsapp") {
          let template = deserialize(WhatsAppFullTemplate, this.comms[i].EmailContent);
          this.formGroup.controls.content.setValue(template.message);
          return;
        }

        this.formGroup.controls.content.setValue(this.comms[i].EmailContent)
        return;
      }
    }
  }

  onSendEmailSubmit() {
    if (this.survey.JobMaster === undefined || this.survey.JobMaster === null) {
      this.survey.JobMaster = new JobMasterModel();
      this.survey.JobMaster.JobMasterId = 0;
      this.survey.JobMaster.SurveyID = this.survey.SurveyID;
      this.survey.JobMaster.Start = new Date();
      this.survey.JobMaster.End = new Date();
      this.survey.JobMaster.End.setDate(this.survey.JobMaster.End.getDate() + 3);
      this.survey.JobMaster.Name = "";
      this.survey.JobMaster.CronExpression = "";
      this.survey.JobMaster.CanMissfire = false;
    }

    this.survey.JobMaster.ChannelID = this.channel;
    this.survey.JobMaster.Subject = this.formGroup.controls.subject.value;
    this.survey.JobMaster.Content = this.formGroup.controls.content.value;
    this.surveyService.saveJobMaster(this.survey.JobMaster).subscribe(
      result => {
        this.survey.JobMaster = result;
        this.dialogRef.close({ survey: this.survey });
        return true;
      });

    return false;
  }

  get messageType(): string {
    switch (this.channel) {
      case 1:
        return "web";
      case 5:
        return "whatsapp";
      default:
        return "sms";
    }
  }
}
