import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import { Field } from '../../../process.Service';
import { QuestionModel } from '../../../question.service';

@Component({
  selector: 'app-field-selector',
  templateUrl: './field-selector.component.html',
  styleUrls: ['./field-selector.component.css']
})
export class FieldSelectorComponent implements OnInit, OnDestroy {
  panelBasicData: any = [
    { name: 'panellistid', title: 'Panellist ID', type: 'number' },
    { name: 'title', title: 'Title', type: 'mcsa' },
    { name: 'firstname', title: 'First Name', type: 'text' },
    { name: 'lastname', title: 'Last Name', type: 'text ' },
    { name: 'email', title: 'Email', type: 'email' },
    { name: 'mobilenumber', title: 'Mobile', type: 'text' },
    { name: 'dob', title: 'Date of Birth', type: 'date' },
    { name: 'gender', title: 'Gender', type: 'mcsa' },
    { name: 'maritalstatus', title: 'Marital Status', type: 'mcsa' },
    { name: 'when', title: 'When', type: 'date' }
  ];

  panelLocationData: any = [
    { name: 'fulladdress', title: 'Full Address', type: 'text' },
    { name: 'address1', title: 'Address Line 1', type: 'text' },
    { name: 'address2', title: 'Address Line 2', type: 'text' },
    { name: 'postalcode', title: 'Postal Code/Zip Code', type: 'text' },
    { name: 'geolocation', title: 'GeoLocation', type: 'text' },
    { name: 'countrycode', title: 'Country Code', type: 'text' },
    { name: 'countryname', title: 'Country Name', type: 'text' }
  ];

  surveyData: any = [
    { name: 'start', title: 'Start Time', type: 'date' },
    { name: 'finish', title: 'Finish Time', type: 'date' },
    { name: 'ip', title: 'IP Address', type: 'string' },
    { name: 'DataCollection.InterviewMode', title: 'Channel', type: 'string' },
    { name: 'DataCollection.ReceiptStatusString', title: 'Receipt Status', type: 'string'}
  ];

  messageData: any = [
    { name: 'source', title: 'Source', type: 'string' },
    { name: 'destination', title: 'Destination', type: 'string' },
    { name: 'message', title: 'Message', type: 'string' },
    { name: 'trandate', title: 'Transaction Date', type: 'string' },
    { name: 'status', title: 'Receipt Status', type: 'mcsa'}
  ];

  specialData: any = [
    { name: '(placeholder)', title: 'Placeholder', type: 'string' }
  ];

  dateFormat: any = [
    { name: ""}
  ];

  displayQuestions: QuestionModel[] = [];
  displayPanelProfile: any[] = [];
  displayEmbeddedData: QuestionModel[] = [];
  displaySystemFields: any[] = [];
  formGroup: FormGroup = new FormGroup({});
  sizePlus: number = 25;
  subscription: Subscription;
  hideUpdate: boolean = false;

  constructor() { }

  ngOnInit(): void {
    this.formGroup = new FormGroup({
      Name: new FormControl(this.field.Name)
    });
    this.formGroup.valueChanges.subscribe(
      result => {
        if (!this.hideUpdate) {
          this.hideUpdate = true;
          let name = this.formGroup.controls.Name.value;
          let temp = name == null || name == '' ? null : this.findFieldFromName(name);
          if (this._field == null) {
            this._field = new Field();
            this._field.Name = temp == null ? name : temp.Name;
            this._field.Title = temp?.title;
          }
          else {
            if (this._field.Name != name) {
              this._field.Name = temp == null ? name : temp.Name;
              if (this.field.Title == null || this.field.Title == undefined || this._field.Title == '') {
                this._field.Title = temp?.title;
              }
            }
          }

          if (temp != null) {
            this.formGroup.controls.Name.setErrors(null);
            this.formGroup.controls.Name.setValue(temp.name);
            this._field.Name = temp.name;
            this.selectField.emit(this._field);
          }
          else {
            this.formGroup.controls.Name.setErrors({ 'incorrect': true });
          }

          this.hideUpdate = false;
        }
      });
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }

  @Input()
  get showButton(): boolean {
    return this._showButton;
  }
  set showButton(value: boolean) {
    this._showButton = value;
  }
  private _showButton: boolean = false;

  @Input()
  get questions(): QuestionModel[] {
    return this._questions;
  }
  set questions(value: QuestionModel[]) {
    this._questions = value;
    this.displayQuestions = [];
    this.displayEmbeddedData = [];
    for (let i = 0; i < this._questions?.length ?? 0; i++) {
      if (this._questions[i].QuestionType == "advanced" && this._questions[i].Action == "embed") {
        for (let j = 0; j < this._questions[i].Questions.List.length; j++) {
          let found = false;
          for (let k = 0; k < this.displayEmbeddedData.length; k++) {
            if (this.displayEmbeddedData[k].QuestionName == this._questions[i].Questions.List[j].QuestionName) {
              found = true;
              break;
            }
          }

          if (!found) {
            this.displayEmbeddedData.push(this._questions[i].Questions.List[j]);
          }
        }
      }
      else {
        this.displayQuestions.push(this._questions[i]);
      }
    }
  }
  private _questions: QuestionModel[] = [];

  @Input()
  get size(): number {
    return this._size;
  }
  set size(value: number) {
    this._size = value;
    this.sizePlus = value + 10;
  }
  private _size: number = 15;

  @Input()
  get forPiping(): boolean {
    return this._forPiping;
  }
  set forPiping(value: boolean) {
    this._forPiping = value;
  }
  private _forPiping: boolean = false;

  @Input()
  get isOpen(): boolean {
    return this._isOpen;
  }
  set isOpen(value: boolean) {
    this._isOpen = value;
  }
  private _isOpen: boolean = false;

  @Input()
  get trigger(): any {
    return this._trigger;
  }
  set trigger(value: any) {
    this._trigger = value;
  }
  private _trigger: any;

  @Input()
  get templateProfile(): any {
    return this._templateProfile;
  }
  set templateProfile(value: any) {
    this._templateProfile = value;
    this.displayPanelProfile = value;
  }
  private _templateProfile: any;

  @Input()
  get field(): Field {
    return this._field;
  }
  set field(value: Field) {
    this._field = value;
  }
  private _field: Field = new Field();

  @Input()
  get purpose(): string {
    return this._purpose;
  }
  set purpose(value: string) {
    this._purpose = value;
  }
  private _purpose: string = "import";

  @Output()
  selectField: EventEmitter<Field> = new EventEmitter<Field>();

  findFieldFromName(name: string): any {
    let search = name;
    let therest = "";
    if (name.includes(".")) {
      for (let i = 0; i < this.surveyData.length; i++) {
        if (this.surveyData[i].name.toLowerCase() == name.toLowerCase()) {
          return this.surveyData[i];
        }
      }

      let temp = name.split('.');
      let s = "";

      for (let i = 0; i + 1 < temp.length; i++) {
        s += i == 0 ? temp[i] : "." + temp[i];
      }

      therest = name.substring(s.length + 1);
      search = s;
    }

    let tempField = this.findField(search);
    if (tempField != null) {
      switch (therest.toLowerCase()) {
        case "asked":
        case "answered":
        case "optiontext":
        case "factor":
          tempField.name += "." + therest.toLowerCase();
          break;
      }
      return tempField;
    }
  }

  findField(name: string): any {
    name = name.toLowerCase();
    for (let i = 0; i < this.panelBasicData.length; i++) {
      if (this.panelBasicData[i].name.toLowerCase() == name) {
        return this.panelBasicData[i];
      }
    }

    for (let i = 0; i < this.panelLocationData.length; i++) {
      if (this.panelLocationData[i].name.toLowerCase() == name) {
        return this.panelLocationData[i];
      }
    }

    for (let i = 0; i < this.surveyData.length; i++) {
      if (this.surveyData[i].name.toLowerCase() == name) {
        return this.surveyData[i];
      }
    }

    for (let i = 0; i < this.messageData.length; i++) {
      if (this.messageData[i].name.toLowerCase() == name) {
        return this.messageData[i];
      }
    }

    for (let i = 0; i < this.specialData.length; i++) {
      if (this.specialData[i].name.toLowerCase() == name) {
        return this.specialData[i];
      }
    }

    for (let i = 0; i < this.questions.length; i++) {
      if (this.questions[i].FullName.toLowerCase() == name) {
        return { name: this.questions[i].FullName, title: this.questions[i].Summary, type: '' };
      }
    }

    if (this.displayPanelProfile.length == 0) {
      return { name: name, title: '', type: '' };
    }

    for (let i = 0; i < this.displayPanelProfile.length; i++) {
      if (this.displayPanelProfile[i].Name.toLowerCase() == name) {
        return { name: this.displayPanelProfile[i].Name, title: this.displayPanelProfile[i].Name, type: '' };
      }
    }

    return null;
  }

  checkFieldType(fieldType: string): boolean {
    return this.formGroup && this.formGroup.controls.FieldType.value == fieldType;
  }

  getFieldFormats(question: QuestionModel) {
    switch (question.QuestionType) {
      case "text":
        return ["Response", "Asked", "Answered"];
      case "mcma":
      case "mcsa":
        return ["Response", "OptionText", "Asked", "Answered", "Factor"];
      case "information":
      case "terminate":
        return ["Asked"];
    }

    return ["Response", "Asked", "Answered"];
  }

  selected($event, question: QuestionModel, format: string) {
    // $event.stopPropagation();
    let a = question;
    this._field = new Field()
    this._field.Title = question.Summary;
    this._field.Name = question.FullName + "." + format;
    this.formGroup.controls.Name.setValue(this._field.Name);
  }

  selectSimple($event, simpleData: any) {
    $event.stopPropagation();
    this._field = new Field();
    this._field.Title = simpleData.title;
    this._field.Name = simpleData.name;
    this.formGroup.controls.Name.setValue(simpleData.name);
  }

  selectCustom($event, customData: any) {
    $event.stopPropagation();
    this._field = new Field();
    this._field.Title = customData.Name;
    this._field.Name = customData.Value;
    this.formGroup.controls.Name.setValue(customData.Name);
  }
}
