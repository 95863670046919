import { Component, OnInit, Input, ViewChild } from '@angular/core';
import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';
import { AnswerModel } from "../../models/AnswerModel";
import { QuestionModel } from '../../../question.service';
import { DataComponent } from '../data/data.component';

class Rank {
  name: string = "";
  value: number = 0;
  label: string = "";
  isOther: boolean = false;
  other: any;

  constructor(name?: string, value?: number, label?: string, isOther?: boolean, other?: any) {
    if (name != undefined) {
      this.name = name;
    }

    if (value != undefined) {
      this.value = value;
    }

    if (label != undefined) {
      this.label = label;
    }

    if (isOther != undefined) {
      this.isOther = isOther;
    }

    if (other != undefined) {
      this.other = other;
    }
  }
}

@Component({
  selector: 'diy-ranking',
  templateUrl: './ranking.component.html',
  styleUrls: ['./ranking.component.css']
})
export class RankingComponent implements OnInit {

  constructor() { }
  ranks: Rank[] = [];

  @ViewChild('data') data: DataComponent | undefined;

  ngOnInit(): void {
  }

  @Input()
  get question(): any {
    return this._question;
  }
  set question(value: any) {
    this._question = value;
    if (this._question === undefined) {
      return;
    }

    let i = 0;
    this.ranks = [];
    this._question.Categories.Items.forEach(() => {
      this.ranks.push(new Rank());
    });
    this._question.Item.Items.forEach((question: any) => {
      const rank = new Rank(question.Item.Items[0].QuestionFullName, question.Item.Items[0].Response.Value, this._question.Categories.Items[i].Label.Text,
        question.Categories.Items[i].IsOther, question.Categories.Items[i].OtherQuestion);
      if (rank.value == null || rank.value.toString() == "0") {
        rank.value = i + 1;
      }
      this.ranks.splice(rank.value - 1, 1, rank);
      i++;
    });
  }
  private _question: any

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.ranks, event.previousIndex, event.currentIndex);
  }

  get answers(): AnswerModel[] {
    const model: AnswerModel[] = [];
    let i = 1;
    this.ranks.forEach(r => {
      let rank = r as Rank;
      if (rank.isOther) {
        this.data?.answers.forEach(answer => {
          model.push(answer);
        })
      }

      model.push(new AnswerModel(rank.name, i.toString()));
      i++;
    });
    return model;
  }

  get valid(): boolean {
    return true;
  }
}
