import { animate, state, style, transition, trigger } from '@angular/animations';
import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { CintFeasibility, CintFullQuote, CintService } from '../../cint.Service';
import { EstimateModel, PanelModel, PanelService } from '../../panel.Service';
import { SelectRespondentsLandingPageComponent } from '../../select-respondents-landing-page/select-respondents-landing-page.component';
import { SurveyDetailsNavigationComponent } from '../../survey-details-navigation/survey-details-navigation.component';
import { SurveyModel, SurveyService } from '../../survey.Service';
import { MatDialog } from '@angular/material/dialog';
import { PanelSelectorComponent } from '../../panel-selector/panel-selector.component';
import { UserModel, UserProfile, UserService } from '../../user.Service';
import { PricingSummaryComponent } from '../pricing-summary/pricing-summary.component';
import { SurveyDistributionComponent } from '../../survey-distribution/survey-distribution.component';
import { FinancialService } from '../../finacial.Service';
import { Observable, Subscription, timer } from 'rxjs';
import { InterviewModel } from '../../question.service';

@Component({
  selector: 'app-panel-pricing',
  templateUrl: './panel-pricing.component.html',
  styleUrls: ['./panel-pricing.component.css'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', maxHeight: '0' })),
      state('expanded', style({ height: '*', })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ])
  ]
})
export class PanelPricingComponent implements OnInit, AfterViewInit {
  private inTimer = false;
  waiting?: Observable<any>;
  waitTime: Observable<any> = timer(0, 500);
  subscription: Subscription;

  notEnough: boolean = false;
  formGroup: FormGroup = new FormGroup({});
  numberMask = "0*";
  readonly = false;
  user: UserProfile = new UserProfile();
  fullQuote: CintFullQuote | null = null;
  quotaRequested = false;

  @ViewChild("pricingSummary") pricingSummary: PricingSummaryComponent | undefined;
  @ViewChild("surveyDistribution") surveyDistribution: SurveyDistributionComponent | undefined;

  get distributionSetup(): boolean {
    if (this.survey && this.survey.Channel == 1 && this.panel && !this.panel.IsCint && this.panel.Summary.Devices > 0) {
      return true;
    }

    if (this.survey && this.survey.JobMaster && this.survey.JobMaster.JobMasterId !== 0) {
      if (this.panel && !this.panel.IsCint && this.panel.Summary && this.panel.Summary.Emails === 0 && this.panel.Summary.Devices === 0) {
        return false;
      }

      return (this.panel && this.panel.IsCint) || this.survey.JobMaster.ChannelID != 0;
    }

    return false;
  }

  get edit(): boolean {
    if (this._respondents) {
      return this._respondents.edit;
    }

    if (this._surveyDetails) {
      return false;
    }

    return false;
  }
  set edit(value: boolean) {
    if (this._respondents != null) {
      this._respondents.edit = value;
    }
  }

  get panel(): PanelModel | null {
    if (this._respondents != null ) {
      this.checkEstimate(this._respondents.panel);
      return this._respondents.panel;
    }

    if (this._surveyDetails && this._surveyDetails.step2) {
      this.checkEstimate(this._surveyDetails.step2.panel);
      return this._surveyDetails.step2.panel;
    }

    return null
  }

  get model(): CintFeasibility | null {
    if (!(this.panel)) {
      return null;
    }

    if (!(this.panel.Quota) || this.panel.Quota == "") {
      return null;
    }

    return JSON.parse(this.panel.Quota);
  }

  constructor(private surveyService: SurveyService,
    private financeService: FinancialService,
    private panelService: PanelService,
    private cintService: CintService,
    private userService: UserService,
    private dialog: MatDialog,
    private fb: FormBuilder,
  ) {
    this.userService.getCurrentUser().subscribe(
      result => { this.user = result; }
    );
    this.calculateCosts();
    this.subscription = this.waitTime.subscribe(() => {
      if (this.inTimer) {
        return;
      }

      if (this.survey !== undefined && this.user !== undefined) {
        this.inTimer = true;
        this.checkBalance();
      }
    });
  }

  checkBalance() {
    /*if (this.survey != undefined && this.estimate != undefined) {
      this.financeService.checkBalanceForReference(this.survey.SurveyID, this.estimate.Total).subscribe(
        result => {
          this.notEnough = !result && !UserService.hasRole(this.user.Member.Roles, "Credit");
          this.inTimer = false;
        });
    }*/
  }

  ngOnInit(): void {
    this.formGroup = this.fb.group({
      number: new FormControl("", [Validators.required]),
      content: new FormControl("", [Validators.required])
    });
  }

  ngAfterViewInit(): void {
    if (this._respondents != null && this._surveyDetails != undefined) {
      this._respondents = this._surveyDetails.step2;
    }
  }

  get name(): string {
    if (this.panel) {
      let temp = this.panel.Name;
      if (this.panel.Name === "Cint") {
        temp = "";
      }
      if (this.panel.Name === this.panel.Description || this.panel.Description === "Cint") {
        return temp;
      }

      return temp + " " + this.panel.Description;
    }

    return "";
  }

  @Input()
  get summary(): string {
    if (this.respondents && this.respondents.interview) {
      return this.respondents.interview.getSummary();
    }

    return "";
  }

  @Input()
  get respondents(): SelectRespondentsLandingPageComponent | undefined {
    return this._respondents;
  }
  set respondents(value: SelectRespondentsLandingPageComponent | undefined) {
    this._respondents = value;
    if (this._respondents) {
      this.survey = this._respondents?.survey ?? null;
    }
  }
  private _respondents: SelectRespondentsLandingPageComponent | undefined = undefined;

  @Input()
  get surveyDetails(): SurveyDetailsNavigationComponent | undefined {
    return this._surveyDetails;
  }
  set surveyDetails(value: SurveyDetailsNavigationComponent | undefined) {
    this._surveyDetails = value;
  }
  private _surveyDetails: SurveyDetailsNavigationComponent | undefined = undefined;

  @Input()
  get survey(): SurveyModel {
    if (this._surveyDetails?.step2) {
      return this._surveyDetails.step2.survey;
    }

    return this._survey;
  }
  set survey(value: SurveyModel) {
    this._survey = value;
  }
  private _survey: SurveyModel = null;

  @Input()
  get interview(): InterviewModel {
    return this._interview;
  }
  set interview(value: InterviewModel) {
    this._interview = value;
  }
  private _interview: InterviewModel = null;

  @Input()
  get allowDelete(): boolean {
    return this._allowDelete;
  }
  set allowDelete(value: boolean) {
    this._allowDelete = value;
  }
  private _allowDelete: boolean = true;

  @Input()
  get estimate(): EstimateModel {
    if (this._estimate) {
      return this._estimate;
    }

    this._estimate = new EstimateModel();
    return this._estimate;
  }
  set estimate(value: EstimateModel) {
    this._estimate = value;
  }
  private _estimate: EstimateModel = new EstimateModel();

  @Output()
  editing: EventEmitter<boolean> = new EventEmitter<boolean>();

  registerOnEditing(fn: any): void {
    this.onEditing = fn;;
  }

  refresh() {
    this.surveyDistribution.refresh();
  }

  onEditing() {
    this.editing.emit(this.edit);
  }

  checkEstimate(value: PanelModel | null) {
    if (value != null) {
      this.readonly = true;
      if (value.Quota && value.Quota != "") {
        if (this.quotaRequested) {
          if (this.fullQuote) {
            this.estimate.Respondents = this.fullQuote.Feasibility.Limit;
            this.estimate.Rate = this.fullQuote.Quote.Cpi.Amount;
            this.calculateCosts();
          }
        }
        else {
          const quota = JSON.parse(value.Quota) as CintFeasibility;
          this.quotaRequested = true;
          this.cintService.checkFeasibility(quota).subscribe(
            result => {
              this.fullQuote = result as CintFullQuote;
              this.estimate.Respondents = this.fullQuote.Feasibility.Limit;
              this.estimate.Rate = this.fullQuote.Quote.Cpi.Amount;
              this.calculateCosts();
            });
        }
      }
      else {
        this.estimate.Respondents = value.PanellistCount;
        this.estimate.Rate = this.user ? this.user.CompleteCost : 0;
        this.calculateCosts();
      }
    }
    else {
      this.readonly = false;
    }
  }

  @Input()
  get allowEditDelete(): boolean {                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                           
    return this._allowEditDelete;
  }
  set allowEditDelete(value: boolean) {
    this._allowEditDelete = value;
  }
  private _allowEditDelete = true;

  cancelChoice() {
    if (this.survey != null) {
      this.survey.JobMaster = null;
      this.survey.PanelID = 0;
      if (this._respondents != null) {
        this._respondents.panel = null;
      }

      this.surveyService.updateBasicDetails(this.survey).subscribe();
      this.surveyService.setPanel(this.survey.SurveyID, 0);
    }

    this.edit = false;
    this.onEditing();
  }

  editCurrentOptions() {
    this.edit = !this.edit;
    this.onEditing();
  }

  checkNumber($event: any) {
    this.calculateCosts();
  }

  calculateCosts() {
    if (!(this._estimate)) {
      return;
    }

    this._estimate.Total = this._estimate.Rate * this._estimate.Respondents; // * this._estimate.CompletionRate / 100;
  }

  editPanel() {
    let quota = new CintFeasibility();
    try {
      if (this.panel != null) {
        quota = JSON.parse(this.panel.Quota) as CintFeasibility;
      }
    }
    catch { }
    const dialogRef = this.dialog.open(PanelSelectorComponent, { width: '80%', data: { quota: quota, questionCount: 5 } });
    dialogRef.afterClosed().subscribe(result => {
      if (result && result.quota) {
        if (this.panel != null) {
          this.panel.Quota = JSON.stringify(result.quota.Feasibility);
          this.panelService.updatePanel(this.panel).subscribe();
        }

        this.fullQuote = result.quota;
        this.checkEstimate(this.panel);
        if (this.pricingSummary != null) {
          this.pricingSummary.model = result.quota.Feasibility;
          this.pricingSummary.refresh();
        }
      }

      return false;
    });
    return false;
  }
}
