import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { AuthenticationService, RegistrationModel, SocialLogin } from "../authentication.Service";
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { version } from "../../environments/environment";
import { GoogleLoginProvider } from '../social-service/providers/google-login-provider';
import { FacebookLoginProvider } from '../social-service/providers/facebook-login-provider';
import { SocialAuthService } from '../social-service/socialauth.service';
import { MatSnackBar } from '@angular/material/snack-bar';


@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit, OnDestroy {
  formGroup: FormGroup = new FormGroup({});
  errorMsg: string;
  displayError: boolean;
  displaySuccess: boolean;
  hide = true;
  breakpoint: number = 0;
  currentVersion: string;
  currentYear = new Date().getFullYear();
  subscription?: Subscription;

//create array to store user data we need
  userData: any [] = [];
// create a field to hold error messages so we can bind it to our        template
  resultMessage: string = "";

  constructor(private authenticationService: AuthenticationService,
    private socialService: SocialAuthService,
    private breakpointObserver: BreakpointObserver,
    public dialog: MatDialog,
    private router: Router,
    private snackbar: MatSnackBar) {
    this.currentVersion = version;
    this.errorMsg = "";
    this.displayError = false;
    this.displaySuccess = false;
    this.authenticationService.logoutWithoutNavigate();
    this.socialService.signOut();
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }

  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
    .pipe(map(result => result.matches));

  ngOnInit(): void {
    this.subscription = this.socialService.authState.subscribe((user) => {
      let model = new SocialLogin();
      if (user != null) {
        model.EmailAddress = user.email;
        model.FirstName = user.firstName;
        model.LastName = user.lastName;
        model.PictureUrl = user.photoUrl;
        model.Provider = user.provider;
        model.UserID = user.id;
      }
      this.authenticationService.socialRegister(model)
        .subscribe(
          result => {
            this.displayError = false;
            this.displaySuccess = true;
          },
          error => {
            //this.displayError = true;
            this.displaySuccess = false;
            if (error.status === 404) {
              this.openSnackbar("The Platform is not currently available for registration, please come back and try again", "Cancel");
            }
            else if (error.status === 400) {
              // cannot register
              this.openSnackbar("Unable to register at this time please check the email address", "Cancel");
            }
            else if (error.status === 409) {
              // Duplicate details
              this.openSnackbar("These details may already be in use", "Cancel");
            } else {
              console.error(error.message);
              this.openSnackbar(error.message, "Cancel");
            }
          });
    });
    this.initForm();
  }

//logIn with facebook method. Takes the platform (Facebook) parameter.
  logInWithFacebook(): boolean {
    this.socialService.signIn(FacebookLoginProvider.PROVIDER_ID);
    return false;
  }


  onSubmit() {
    if (this.formGroup.invalid) {
      return;
    }

    this.formGroup.value.username = this.formGroup.value.email;
    let model: RegistrationModel = this.formGroup.value;
    this.authenticationService.register(model).subscribe(
      result => {
          this.displayError = false;
          this.displaySuccess = true;
        },
        error => {
          this.displayError = true;
          this.displaySuccess = false;
          if (error.status === 404) {
            this.errorMsg = "The Platform is not currently available for registration, please come back and try again";
          }
          else if (error.status === 400) {
            // cannot register
            this.errorMsg = "Unable to register at this time please check the email address";
          }
          else if (error.status === 409) {
            // Duplicate details
            this.errorMsg = "These details may already be in use";
          } else {
            console.error(error.message);
            this.errorMsg = error.message;
          }
        });
  }

  initForm() {
    this.formGroup = new FormGroup({
      firstname: new FormControl("", [Validators.required]),
      lastname: new FormControl("", [Validators.required]),
      username: new FormControl(""),
      email: new FormControl("", [Validators.required, Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$')]),
      password: new FormControl("", [Validators.required]),
      tandc: new FormControl(false)
    });

  }

  openTerms() {
    const url = this.router.createUrlTree(['/termsofservice'])
    window.open(url.toString(), '_blank')
  }

  openPrivacy() {
    const url = this.router.createUrlTree(['/privacystatement'])
    window.open(url.toString(), '_blank')
  }

  goToLink(url: string) {
    window.open(url, "_blank");
  }

  private openSnackbar(message: string, action: string) {
    if (action == "") {
      this.snackbar.open(message, action, { duration: 2000 });
    }
    else {
      this.snackbar.open(message, action, { duration: 7000 });
    }
  }
}
