import { Component, Input, OnInit } from '@angular/core';
import { DataTask, ProcessService, ProcessServiceModel } from '../../process.Service';

@Component({
  selector: 'app-task-summary',
  templateUrl: './task-summary.component.html',
  styleUrls: ['./task-summary.component.css']
})
export class TaskSummaryComponent implements OnInit {

  method: string = "export";
  constructor() { }

  ngOnInit(): void {
  }

  @Input()
  get process(): ProcessServiceModel {
    return this._process;
  }
  set process(value: ProcessServiceModel) {
    this._process = value;
    this.method = this._process.Task.Type == "IMPORT" ? "import" : "export";
  }
  private _process: ProcessServiceModel = new ProcessServiceModel();


  transport(): string {
    switch (this.process.Task.FileTransfer.Type) {
      case "SFTP":
        return "SFTP";
      case "FTPS":
        return "FTPS";
      case "SPECIFIC":
        if (this.process.ProcessTypeId < 2) {
          return "UPLOAD";
        }

        return "DOWNLOAD";
      case "EMAIL":
        return "EMAIL";
      default:
        return "Unknown";
    }
  }

  standardCustom(): string {
    let standard = this.process.Task.CustomFormat?.Lines[0]?.Fields?.length > 0 && this.process.Task.CustomFormat?.Lines[0]?.Fields[0]?.Name != "" ? "Custom " : "";
    switch (this.process.Task.Format) {
      case "CSV":
        return standard + "Comma separated values";
      case "TSV":
        return standard + "Tab separated values";
      case "EXCEL":
        return standard + "Microsoft Excel";
      case "XML":
        return standard + "XML";
      case "PIPE":
        return standard + "Pipe separated values";
      case "FIXED":
        return standard + "Fixed File Format";
    }

    return "";
  }
}
