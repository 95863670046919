import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { baseUrl, options } from "../environments/environment";
import { RuleList } from './question.service';
import { JsonCyclic } from './utils';
import { SurveySummaryModel } from './survey.Service';
import { UserProfile } from './user.Service';
import { ProfileVariable } from './panel.Service';
import { Schedule } from './scheduler/scheduler.component';

export class ProcessServiceModel {
  ProcessId: number = 0;
  OwnerId: number = 0;
  Created: Date = new Date();
  Amended: Date = new Date();
  Path: string = "";
  Name: string = "";
  IsDeleted: boolean = false;
  Progress: number = 0;
  StatusId: number = 0;
  ProcessTypeId: number = 0;
  Start: Date = new Date();
  Finish: Date = new Date();
  Adjustment: number;
  Timezone: string;
  Cron: string = "";
  Task: DataTask = new DataTask();
  ReferenceId: number = 0;
  Message: string = "";
  LastErrorTimestamp: Date = new Date();
  LastUpdated: Date = new Date();
  errors: ProcessErrorModel[] = [];
  messages: ProcessErrorModel[] = [];
  surveySummary: SurveySummaryModel = new SurveySummaryModel();
  userProfile: UserProfile = new UserProfile();


  constructor() {
    this.Task = new DataTask();
  }

  get StatusDescription(): string {
    switch (this.StatusId) {
      case 0:
        return "Waiting to Start";
      case 1:
        return "In progress";
      case 2:
        if (this.Task.LastSuccess) {
          return "Idle";
        }

        return "Paused with a problem";
      case 3:
        return "Successfully Completed";
      case 4:
        return "Stopped with Errors";
      case 5:
        return "Saved Definition";
      case 6:
        return "Saved";
      default:
        return "-- ? --";
    }
  }
}

export class ProcessErrorModel {
  ErrorId: number = 0;
  ProcessId: number = 0;
  Created: Date = new Date();
  Path: string = "";
  Col: number = 0;
  Line: number = 0;
  Message: string = "";
  TypeId: number = 0;
  TypeValue: number = 0;
}

export class CustomFormat {
  Header: boolean = true;
  Lines: Line[] = [];

  createVarialbeList(): ProfileVariable[] {
    let variables: ProfileVariable[] = [];
    for (let i = 0; i < this.Lines.length; i++) {
      for (let j = 0; j < this.Lines[i].Fields.length; j++) {
        let field = this.Lines[i].Fields[j];
        let variable = new ProfileVariable();
        variable.Name = field.Name;
        variable.Id = variables.length;
        variable.TypeId = 0;
        variables.push(variable);
      }
    }

    return variables;
  }
}

export class Line {
  Fields: Field[] = [];
}

export class Field {
  Name: string = "";
  Position: number = 0;
  Terminated: boolean = false;
  Optional: boolean = false;
  EndsWith: string = "";
  Size: number = 0;
  Type?: object;
  ConditionValue?: object;
  Key: boolean = false;
  Repeat: boolean = false;
  Start: number = 0;
  Length: number = 0;
  Justification: string = "";
  Alias: string = "";
  Custom: boolean = false;
  Text: string = "";
  Title: string = "";
  ReceiptRequest: boolean = false;
  Format: string = "";
}

export class DataTask {
  Name: string = "";
  Type: string = "";
  Entity: string = "";
  FileTransfer: FileTransferModel = new FileTransferModel();
  Format: string;
  Duplication: DuplicationModel = new DuplicationModel();
  LocalPath: string = ""; //
  ReferenceID: number = 0; //
  LastSuccess: boolean = false;
  ErrorName: string = "";
  LastRun: Date;
  Username: string = "";
  AllData: boolean = false;
  From: boolean = false;
  Compress: boolean = false;
  UseNumericValues: boolean = false;
  RemoveLineBreaks: boolean = false;
  SplitMultiValueFields: boolean = false;
  SystemData: boolean = false;
  Timings: boolean = false;
  Headers: boolean = false;
  Rules: RuleList | null = null;
  UseCustomFormat: boolean = false;
  CustomFormat: CustomFormat = new CustomFormat();
  Start: Date;
  Finish: Date;
  Automated: boolean = false;
  Country: string = "";
  Countries: string[] = [];
  Number: string = "";
  Direction: number = 0;
  Statuses: number[] = [];
  Content: string = ""
  IncludePanelData: boolean = false;
  ErrorHandling: string = "full";
  ReportErrors: boolean = false;
  ErrorHandlingFileTransfer: FileTransferModel = new FileTransferModel();
  Custom: boolean = false;
  CustomUrl: string = '';
  MaskingLevel: number = 0;
  CustomCustom: boolean = false;
  NumberLookup: number = 0;
  Quotas: boolean = false;
  QuotaRules?: RuleList[] = [];
  CountryPrefix: string = "";

  constructor() {
    this.Format = "CSV";
  }
}

export class FileTransferModel {
  Type?: string;
  LocalPath?: string;
  Server?: string;
  Username?: string;
  Password?: string;
  Passive?: boolean = false;
  ServerFolder?: string;
  LocalFolder?: string;
  File?: string;
  Pattern?: string;
  Port?: number;
  Ssl?: boolean = false;
  Archive?: string;
}

export class DuplicationModel {
  Days: number = 0;
}

export class QuotaCheckModel {
  PanelId: number = 0;
  CustomFormat: CustomFormat = new CustomFormat();
  Rules: RuleList = new RuleList();
  Index: number = 0;

  constructor(panelId: number, customFormat: CustomFormat, rules: RuleList, index: number) {
    this.PanelId = panelId;
    this.CustomFormat = customFormat;
    this.Rules = rules;
    this.Index = index;
  }
}

@Injectable()
export class ProcessService {

  public static ExcelDownload: string[] = ["application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", ".xlsx"];
  public static CsvDownload: string[] = ["text/csv", ".csv"];
  public static TsvDownload: string[] = ["text/plain", ".tsv"];
  public static XmlDownload: string[] = ["test/xml", ".xml"];
  public static TextDownload: string[] = ["text/plain", ".txt"];
  public static PipeDownload: string[] = ["text/plain", ".pipe"];
  public static ZipDownload: string[] = ["application/zip", ".zip"];

  public static FindDownloadFromTask(task: DataTask) {
    if (task.Compress) {
      return this.ZipDownload;
    }

    return this.FindDownload(task.Format);
  }

  public static FindDownload(downloadType: string): string[] {
    switch (downloadType) {
      case "EXCEL":
        return this.ExcelDownload;
      case "CSV":
        return this.CsvDownload;
      case "TSV":
        return this.TsvDownload;
      case "XML":
        return this.XmlDownload;
      case "PIPE":
        return this.PipeDownload;
      default:
        return this.TextDownload;
    }
  }

  public static newProcess(referenceId: number, name: string, isExport: boolean, automate: boolean) {
    let process = new ProcessServiceModel();

    // Basic details
    let schedule = new Schedule("", new Date(), new Date(), "");
    process.Cron = schedule.cron;
    process.Start = schedule.start;
    process.Finish = schedule.end;
    process.Timezone = schedule.timezone;
    process.IsDeleted = false;
    process.Name = name;

    process.ProcessTypeId = isExport ? 2 : 0;
    process.Task.Entity = isExport ? "Invites" : "SurveyPanel";

    // file Transfer Information
    process.Task.Type = isExport ? "EXPORT" : "IMPORT";
    process.Task.FileTransfer.Type = automate ? "SFTP" : "SPECIFIC";
    process.Task.FileTransfer.Passive = false;
    process.Task.FileTransfer.Ssl = false;
    process.Task.FileTransfer.Port = 22;

    process.ReferenceId = process.Task.ReferenceID = referenceId;
    process.Task.Rules = null;
    process.Task.SplitMultiValueFields = true;
    process.Task.RemoveLineBreaks = true;
    process.Task.Timings = false;
    process.Task.SystemData = false;
    process.Task.Format = "CSV";
    process.Task.UseNumericValues = false;
    process.Task.AllData = true;
    process.Task.Start = new Date();
    process.Task.Finish = new Date();
    process.Task.Automated = automate;
    process.Task.Country = "";
    process.Task.Number = "";
    process.Task.Direction = 0;
    process.Task.Statuses = [];
    process.Task.Content = "";
    process.Task.IncludePanelData = false;
    process.Task.ErrorHandling = "full";
    return process;
  }

  constructor(private http: HttpClient) {
  }

  getQuota(panelId: number, customFormat: CustomFormat, rules: RuleList, index: number): Observable<any> {
    let model = new QuotaCheckModel(panelId, customFormat, rules, index);
    return this.http.post<any>(baseUrl + 'service/GetQuota', model, options);
  }

  getImport(name: string): Observable<any> {
    return this.http.get<any>(baseUrl + 'service/GetImport?name=' + name, options);
  }

  getProcesses(page: number, size: number): Observable<ProcessServiceModel[]> {
    return this.http.get<ProcessServiceModel[]>(baseUrl + 'service/GetProcesses?page=' + page + '&size=' + size, options);
  }

  getProcessForSystem(): Observable<any> {
    return this.http.get(baseUrl + 'service/GetProcessesForSystem', options);
  }

  getActiveProcessForSystem(): Observable<any> {
    return this.http.get(baseUrl + 'service/GetActiveProcessesForSystem', options);
  }

  processCount(): Observable<any> {
    return this.http.get(baseUrl + 'service/GetProcessCount', options);
  }

  hasProcesses(): Observable<any> {
    return this.http.get(baseUrl + 'service/HasProcesses', options);
  }

  getProcessById(id: number): Observable<any> {
    return this.http.get(baseUrl + 'service/GetProcess?id=' + id, options);
  }

  getProcess(id: number): Observable<any> {
    return this.http.get(baseUrl + 'service/CheckProgress?id=' + id, options);
  }

  Abandon(id: number): Observable<any> {
    return this.http.patch(baseUrl + 'service/Abandon?id=' + id, options);
  }

  updateProcess(model: ProcessServiceModel): Observable<any> {
    let temp = JsonCyclic.toJson(model);
    return this.http.post(baseUrl + 'service/update', temp, options);
  }

  deleteProcess(model: ProcessServiceModel): Observable<any> {
    return this.http.delete(baseUrl + 'service/delete?id=' + model.ProcessId, options);
  }

  abandonProcess(model: ProcessServiceModel): Observable<any> {
    return this.http.delete(baseUrl + 'service/abandon?id=' + model.ProcessId, options);
  }

  deleteForUserProcess(model: ProcessServiceModel): Observable<any> {
    return this.http.delete(baseUrl + 'service/deleteForUser?id=' + model.ProcessId + '&userId=' + model.OwnerId, options);
  }

  getProcessErrors(id: number, count: number): Observable<any> {
    return this.http.get(baseUrl + 'service/GetProcessErrors?id=' + id + '&count=' + count);
  }

  getProcessMessages(id: number, count: number): Observable<any> {
    return this.http.get(baseUrl + 'service/GetProcessMessages?id=' + id + '&count=' + count);
  }

  getExportProcesses(referenceId: number): Observable<ProcessServiceModel[]> {
    return this.getProcessesByType(referenceId, 2);
  }

  getImportProcesses(referenceId: number): Observable<ProcessServiceModel[]> {
    return this.getProcessesByType(referenceId, 0);
  }

  getDefinitions(referenceId: number): Observable<ProcessServiceModel[]> {
    return this.http.get<ProcessServiceModel[]>(baseUrl + 'service/GetDefinitions?referenceId=' + referenceId, options);
  }

  getErrors(referenceId: number): Observable<ProcessServiceModel[]> {
    return this.http.get<ProcessServiceModel[]>(baseUrl + 'service/GetErrors?referenceId=' + referenceId, options);
  }

  getAbandoned(referenceId: number): Observable<ProcessServiceModel[]> {
    return this.http.get<ProcessServiceModel[]>(baseUrl + 'service/GetAbandoned?referenceId=' + referenceId, options);
  }

  getErrorsForUser(userId: string): Observable<ProcessServiceModel[]> {
    return this.http.get<ProcessServiceModel[]>(baseUrl + 'service/GetErrorsForUser?userId=' + userId, options);
  }

  getActiveProcessesOnly(referenceId: number): Observable<ProcessServiceModel[]> {
    return this.http.get<ProcessServiceModel[]>(baseUrl + 'service/GetActiveProcesses?referenceId=' + referenceId, options);
  }

  getActiveProcessesForUserOnly(userId: string): Observable<ProcessServiceModel[]> {
    return this.http.get<ProcessServiceModel[]>(baseUrl + 'service/GetActiveProcessesForUser?userId=' + userId, options);
  }

  getAwsEndPoints(): Observable<any> {
    return this.http.get<any>(baseUrl + 'service/awsendpoints', options);
  }

  getImportProcessesForReference(referenceId: number): Observable<ProcessServiceModel[]> {
    return this.http.get<ProcessServiceModel[]>(baseUrl + 'service/getImportProcessesForReference?referenceId=' + referenceId, options);
  }

  getCompletedProcessesOnly(referenceId: number): Observable<ProcessServiceModel[]> {
    return this.http.get<ProcessServiceModel[]>(baseUrl + 'service/GetCompletedProcesses?referenceId=' + referenceId, options);
  }

  getCompletedProcessesForUserOnly(userId: string): Observable<ProcessServiceModel[]> {
    return this.http.get<ProcessServiceModel[]>(baseUrl + 'service/GetCompletedProcessesForUser?userId=' + userId, options);
  }

  getActiveProcesses(referenceId: number): Observable<ProcessServiceModel[]> {
    return this.http.get<ProcessServiceModel[]>(baseUrl + 'service/GetActiveCompletedProcesses?referenceId=' + referenceId, options);
  }

  getProcessesByType(referenceId: number, typeId: number): Observable<ProcessServiceModel[]> {
    return this.http.get<ProcessServiceModel[]>(baseUrl + 'service/GetSavedProcesses?typeId=' + typeId + '&referenceId=' + referenceId, options);
  }

  saveProcess(model: ProcessServiceModel): Observable<ProcessServiceModel> {
    let temp = JsonCyclic.toJson(model);
    return this.http.post<ProcessServiceModel>(baseUrl + 'service/SaveProcess', temp, options);
  }

  runProcess(model: ProcessServiceModel): Observable<ProcessServiceModel> {
    let temp = JsonCyclic.toJson(model);
    return this.http.post<ProcessServiceModel>(baseUrl + 'service/ExecuteProcess', temp, options);
  }

  testFTP(model: FileTransferModel): Observable<string[]> {
    let temp = JsonCyclic.toJson(model);
    return this.http.post<string[]>(baseUrl + 'service/TestFTP', temp, options);
  }

  uploadPanel(name: string, filename: string, format: string, referenceId: number): Observable<any> {
    let model = new DataTask();
    model.Name = name;
    model.Type = "IMPORT";
    model.Format = format;
    model.LastSuccess = true;
    model.ReferenceID = referenceId;
    model.FileTransfer = new FileTransferModel();
    model.FileTransfer.Type = "SPECIFIC";
    model.FileTransfer.LocalPath = filename;
    model.Headers = true;
    return this.http.post(baseUrl + 'service/uploadPanel', model, options)
  }

  importData(process: ProcessServiceModel): Observable<any> {
    return this.http.post(baseUrl + 'service/runprocessfromprocess', process, options)
  }

  interviewTest(surveyId: number, batchId: number, name: string, numberOfTests: number, envPar: string[], envParValues: string[]): Observable<any> {
    let model = new DataTask();
    model.Name = name;
    model.Type = "TEST";
    model.Format = numberOfTests.toString();
    model.LastSuccess = true;
    model.ReferenceID = surveyId;
    model.ErrorName = batchId.toString();
    model.FileTransfer = new FileTransferModel();
    model.FileTransfer.Type = envPar.join(",");
    model.FileTransfer.LocalPath = envParValues.join(",");
    return this.http.post(baseUrl + 'service/interviewTest', model, options)
  }

  exportData(name: string, surveyId: number, model: DataTask): Observable<any> {
    model.Name = name;
    model.Type = "EXPORT";
    model.LastSuccess = true;
    model.ReferenceID = surveyId;
    model.FileTransfer = new FileTransferModel();
    model.FileTransfer.Type = "SPECIFIC";
    model.FileTransfer.LocalPath = '';
    return this.http.post(baseUrl + 'service/exportResults', JsonCyclic.toJson(model), options)
  }

  getFile(process: ProcessServiceModel): Observable<any> {
    return this.http.get(baseUrl + 'service/getFile?id=' + process.ProcessId,
      {
        responseType: "blob",
        headers: new HttpHeaders().append("Content-Type", "application/json")
      });
  }
}
