import { AfterViewChecked, AfterViewInit, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { CategoryModel, ParameterModel, QuestionModel } from '../../question.service';
import { UserFeatures } from '../../user.Service';

@Component({
  selector: 'app-advanced-answer-option',
  templateUrl: './advanced-answer-option.component.html',
  styleUrls: ['./advanced-answer-option.component.css']
})
export class AdvancedAnswerOptionComponent implements OnInit, AfterViewInit {

  form: FormGroup;
  fb: FormBuilder = new FormBuilder();
  icon = "indeterminate_check_box";
  optionText = "Answer Option";
  declareChange: boolean = false;
  optionTextLabel: string = "Answer Option";
  formPopulated: boolean = false;
  ref: any;

  constructor(private dialog: MatDialog) {
    this.form = new FormGroup({
      id: new FormControl(""),
      option: new FormControl(""),
      factor: new FormControl("")
    });
    this.form.valueChanges.subscribe(x => {
      this.onChange();
    });
  }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    Promise.resolve(null).then(() => {
      this.populateForm();
    });
  }

  allowTextEntry() {
    if (this._category == null) {
      return;
    }

    if (this._category.Other) {
      this._category.Other = null;
      this.onChange();
      return;
    }

    this._category.Other = new QuestionModel();
    this._category.Other.QuestionType = "textbox";
    this._category.Other.Banner = this.form.controls.option.value;
    this.onChange();
  }

  displayAnswerOption() {
    return this.userFeatures.QuestionEditorFeatures.EditAnswerOptions || this.isSms;
  }

  displayFactor() {
    return this.category && this.userFeatures.QuestionEditorFeatures.EditAnswerOptionFactors;
  }

  fixPosition() {
    if (this._category == null) {
      return;
    }
    this._category.Fixed = !this._category.Fixed;
    this.onChange();
  }

  onChange() {
    if (!this.declareChange) {
      return;
    }

    this.declareChange = false;
    this.change.emit(this);
    this.declareChange = true;
  }

  exclusive() {
    if (this._category == null) {
      return;
    }

    this._category.Exclusive = !this._category.Exclusive;
    this.onChange();
  }

  @Input()
  get userFeatures(): UserFeatures {
    return this._userFeatures;
  }
  set userFeatures(value: UserFeatures) {
    this._userFeatures = value;
  }
  private _userFeatures: UserFeatures = new UserFeatures();

  @Input()
  get category(): CategoryModel | null {
    return this._category;
  }
  set category(value: CategoryModel | null) {
    this._category = value;
  }
  private _category: CategoryModel | null = null;

  @Input()
  get parameter(): ParameterModel | null {
    return this._parameter;
  }
  set parameter(value: ParameterModel | null) {
    this._parameter = value;
  }
  private _parameter: ParameterModel | null = null;

  @Input()
  get label(): string {
    return this.optionText;
  }
  set label(value: string) {
    this.optionText = value;
  }

  @Input()
  get model(): QuestionModel {
    return this._model;
  }
  set model(value: QuestionModel) {
    this._model = value;
  }
  private _model: QuestionModel = new QuestionModel();

  @Input()
  get index(): number {
    return this._index;
  }
  set index(value: number) {
    this._index = value;
  }
  private _index = -1;


  @Input()
  get shouldDisplay(): boolean {
    if (this.category) {
      if (this.optionType) {
        if (this.optionType < 3) {
          return true;
        }
      }
    }

    return false;
  }

  @Input()
  get optionType(): number {
    return this._optionType;
  }
  set optionType(value: number) {
    this._optionType = value;
    switch (this._optionType) {
      case 1:
        this.icon = "radio_button_unchecked";
        break;
      case 2:
        this.icon = "check_box_outline_blank";
        break;
      default:
        this.icon = "indeterminate_check_box";
        break;
    }
  }
  private _optionType: number = -1;

  @Input()
  get isBoolean(): boolean {
    return this._isBoolean;
  }
  set isBoolean(value: boolean) {
    this._isBoolean = value;
  }
  private _isBoolean = false;

  @Input()
  get count(): number {
    return this._count;
  }
  set count(value: number) {
    this._count = value;
  }
  private _count: number = 0;

  @Input()
  get isSms(): boolean {
    return this._isSms;
  }
  set isSms(value: boolean) {
    this._isSms = value;
  }
  private _isSms = false;

  @Output() delete = new EventEmitter();
  @Output() change = new EventEmitter();
  @Output() up = new EventEmitter();
  @Output() down = new EventEmitter();

  deleteOption() {
    this.delete.emit(this.index);
  }

  richContentEditor(template) {
    this.ref = this.dialog.open(template);
    this.ref.afterClosed().subscribe(
      result => {
        this.form.controls.option.setValue(this.form.controls.option.value);
      });
  }

  close() {
    this.ref.close();
  }

  moveUp() {
    this.up.emit(this.index);
  }

  moveDown() {
    this.down.emit(this.index);
  }

  populateForm() {
    if (this.form === undefined || this.form === null) {
      return;
    }

    this.declareChange = false;
    if (this._category) {
      this.form.controls.id.setValue(this._category.Name);
      this.form.controls.option.setValue(this._category.Label);
      this.form.controls.factor.setValue(this._category.Factor);
    }

    if (this._parameter) {
      this.form.controls.id.setValue(this._parameter.Name);
      this.form.controls.option.setValue(this._parameter.Value);
    }

    if (this.isBoolean) {
      if (this._index === 0) {
        this.optionTextLabel = "Yes";
      }
      else {
        this.optionTextLabel = "No";
      }
    }
    else {
      this.optionTextLabel = this.optionText + " " + (this._index + 1);
    }

    this.formPopulated = true;
    this.declareChange = true;
  }
}
