<mat-accordion class="accordion-headers-align">
  <mat-expansion-panel *ngIf="!isSms">
    <mat-expansion-panel-header [collapsedHeight]="'38px'" [expandedHeight]="'38px'">
      <mat-panel-title>
        <span>Styling</span>
      </mat-panel-title>
      <mat-panel-description>
      </mat-panel-description>
    </mat-expansion-panel-header>
    <button mat-flat-button color="primary" (click)="openStyling()">Manage Styling</button>
    </mat-expansion-panel>
    <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
      <mat-expansion-panel-header [collapsedHeight]="'38px'" [expandedHeight]="'38px'">
        <mat-panel-title>
          <span>Advanced Properties</span>
        </mat-panel-title>
        <mat-panel-description>
        </mat-panel-description>
      </mat-expansion-panel-header>
      <div>
        <div>
          <strong>Error Handling</strong>
          <mat-radio-group [(ngModel)]="errorHandling" name="radioButtons">
            <div>
              <div class="flex-space-btwn">
                <mat-radio-button [value]="0" (change)="changeErrorHandling($event)">Default</mat-radio-button>
              </div>
              <div class="flex-space-btwn">
                Will continue to ask the respondent the question until they enter a valid option.
              </div>
              <div class="flex-space-btwn">
                <mat-radio-button [value]="1" (change)="changeErrorHandling($event)">None</mat-radio-button>
              </div>
              <div class="flex-space-btwn">
                No Error management is applied, unless you have specified custom error handling logic here.
              </div>
            </div>
          </mat-radio-group>
          <div>

          </div>
        </div>
        <div>
          <strong>Running Characteristics</strong><br />
          <mat-checkbox [(ngModel)]="isInvite" (change)="changeInviteFlag($event)">Runs the survey as an invite process</mat-checkbox>
        </div>
      </div>
    </mat-expansion-panel>
    <mat-expansion-panel>
      <mat-expansion-panel-header [collapsedHeight]="'38px'" [expandedHeight]="'38px'">
        <mat-panel-title>
          <span>Import Questionnaire</span>
        </mat-panel-title>
        <mat-panel-description>
        </mat-panel-description>
      </mat-expansion-panel-header>
      <app-file-upload #scriptUpload accept=".txt,.docx" text="Import Questionnaire" (uploaded)="onFileUploaded($event)"></app-file-upload>
    </mat-expansion-panel>
    <mat-expansion-panel>
      <mat-expansion-panel-header [collapsedHeight]="'38px'" [expandedHeight]="'38px'">
        <mat-panel-title>
          <span>Languages</span>
        </mat-panel-title>
        <mat-panel-description>
        </mat-panel-description>
      </mat-expansion-panel-header>
      <div>
        <button *ngIf="DefaultLanguage" mat-stroked-button matTooltip="Default Language" [matMenuTriggerFor]="defLangMenu">
          <mat-icon>language</mat-icon>{{DefaultLanguage.Language.Description}}
        </button>
        <mat-menu #defLangMenu="matMenu">
          <button *ngFor="let summary of summaries" mat-menu-item (click)="setLanguage(summary)">
            <span>{{summary.Language.Description}}</span>
          </button>
        </mat-menu>
      </div>
      <div class="mt-5">
        <button mat-stroked-button matTooltip="Manage Language Translations" (click)="clickLanguages()">
          Manage Language Translations
        </button>
      </div>
      <div class="mt-5">
        <mat-checkbox (change)="changeShowLanguage($event)" [checked]="allowLanguageChoice">Show Language Option to Recipient</mat-checkbox>
      </div>
    </mat-expansion-panel>
</mat-accordion>
<ng-template #styleDialog>
  <div class="dialog-title fullWidth">
    <div class="leftFloat">
      <h3>Survey Style</h3>
    </div>
    <div class="rightFloat">
      <button mat-icon-button matDialogClose="no" matTooltip="Close" matTooltipPosition="below">
        <mat-icon class="dialog-close-icon">close</mat-icon>
      </button>
    </div>
  </div>
  <div class="clearBoth mt-10">
    <div class="preview custom-scroll-bar survey-style-preview-div">
      <diy-player #player [show]="show" [model]="model" [preview]="false" (navigate)="navigateQuestions($event)" (changeLanguage)="languageChanged($event)" [playerWidth]="500" [ngClass]="selectedTheme"></diy-player>
    </div>
    <mat-tab-group mat-stretch-tabs="false" mat-align-tabs="start">
      <mat-tab label="Themes">
        <div class="custom-scroll-bar">
          <div (click)="selectedTheme('deepPurpleAmber')" class="theme-selector-container">
            <div class="theme-selector-content">
              <div class="theme-selector-color-container">
                <div style="background-color: #4a148c; width: 50%;"></div>
                <div style="background-color: #ff8f00; width: 50%;"></div>
              </div>
              <div>
                <span>Deep Purple - Amber</span>
              </div>
              <div>
                <mat-icon [style.visibility]="themes.deepPurpleAmber ? 'visible' : 'hidden'">done</mat-icon>
              </div>
            </div>
          </div>
          <div (click)="selectedTheme('indigoPink')" class="theme-selector-container">
            <div class="theme-selector-content">
              <div class="theme-selector-color-container">
                <div style="background-color: indigo; width: 50%;"></div>
                <div style="background-color: #ad1457; width: 50%;"></div>
              </div>
              <div>
                <span>Indigo - Pink</span>
              </div>
              <div>
                <mat-icon [style.visibility]="themes.indigoPink ? 'visible' : 'hidden'">done</mat-icon>
              </div>
            </div>
          </div>
          <div (click)="selectedTheme('pinkBlueGrey')" class="theme-selector-container">
            <div class="theme-selector-content">
              <div class="theme-selector-color-container">
                <div style="background-color: #757575; width: 50%;"></div>
                <div style="background-color: #37474f; width: 50%;"></div>
              </div>
              <div>
                <span>Pink - BlueGrey</span>
              </div>
              <div>
                <mat-icon [style.visibility]="themes.pinkBlueGrey ? 'visible' : 'hidden'">done</mat-icon>
              </div>
            </div>
          </div>
          <div (click)="selectedTheme('greenPurple')" class="theme-selector-container">
            <div class="theme-selector-content">
              <div class="theme-selector-color-container">
                <div style="background-color: green; width: 50%;"></div>
                <div style="background-color: purple; width: 50%;"></div>
              </div>
              <div>
                <span>Green - Purple</span>
              </div>
              <div>
                <mat-icon [style.visibility]="themes.greenPurple ? 'visible' : 'hidden'">done</mat-icon>
              </div>
            </div>
          </div>
          <div (click)="selectedTheme('default')" class="theme-selector-container">
            <div class="theme-selector-content">
              <div class="theme-selector-color-container">
                <div style="background-color: #ffffff; width: 50%;"></div>
                <div style="background-color: #8ca6d7; width: 50%;"></div>
              </div>
              <div>
                <span>Default</span>
              </div>
              <div>
                <mat-icon [style.visibility]="themes.default ? 'visible' : 'hidden'">done</mat-icon>
              </div>
            </div>
          </div>
        </div>
      </mat-tab>
      <mat-tab label="Custom Colors">
        <div [formGroup]="customForm" class="mt-10">
          <div class="fieldRowWithPadding">
            <div class="wd-95-per">
              Background
            </div>
            <div>
              <ngx-colors ngx-colors-trigger
                          formControlName="BackgroundColor"></ngx-colors>
            </div>
          </div>
          <div class="fieldRowWithPadding">
            <div class="wd-95-per">
              Header
            </div>
            <div>
              <ngx-colors ngx-colors-trigger
                          formControlName="HeaderColor"></ngx-colors>
            </div>
          </div>
          <div class="fieldRowWithPadding">
            <div class="wd-95-per">
              Controls
            </div>
            <div>
              <ngx-colors ngx-colors-trigger
                          formControlName="ControlColor"></ngx-colors>
            </div>
          </div>
          <div class="fieldRowWithPadding">
            <div class="wd-95-per">
              Navigation
            </div>
            <div>
              <ngx-colors ngx-colors-trigger
                          formControlName="NavigationColor"></ngx-colors>
            </div>
          </div>
        </div>
      </mat-tab>
      <mat-tab label="Header Logo">
        <form class="flex-flow-col mt-10 fullWidth" [formGroup]="logoFormGroup">
          <div class="txt-align-center">
            <div>
              <input type="file" class="noDisplay" #logoInput (change)="onLogoChange($event)" accept="image/png, image/jpeg">
              <button mat-raised-button color="primary" (click)="onClickLogoInputButton()">
                <mat-icon>image</mat-icon> Select logo
              </button>
              <p class="file-name" *ngIf="!selectedLogo; else logoName">No file selected</p>
              <ng-template #logoName>
                <p>{{ selectedLogo?.name }}</p>
              </ng-template>
            </div>
            <img [src]="media" *ngIf="media" class="survey-style-headerLogo-img">
          </div>
          <div class="txt-align-center">
            <mat-checkbox [checked]="isDefaultChecked" (change)="checkDefault($event)" class="survey-style-useDflt-chkbox">Use Default</mat-checkbox>
          </div>
        </form>
      </mat-tab>
      <mat-tab label="Powered By Logo">
        <div class="mt-10">
          <mat-radio-group [(ngModel)]="selectedFooterLogo" name="radioButtons">
            <div>
              <div class="flex-spaceBtwn-center">
                <div>
                  <mat-radio-button [value]="0" (change)="changeFooterLogo($event)">Light</mat-radio-button>
                </div>
                <div class="ml-10">
                  <img src="assets/img/logo@3x_white.png" class="themeThumbnailLight" />
                </div>
              </div>
              <div class="flex-spaceBtwn-center">
                <div>
                  <mat-radio-button [value]="1" (change)="changeFooterLogo($event)">Default</mat-radio-button>
                </div>
                <div class="ml-10">
                  <img src="assets/img/logo.svg" class="themeThumbnail" />
                </div>
              </div>
            </div>
          </mat-radio-group>
        </div>
      </mat-tab>
    </mat-tab-group>
  </div>
</ng-template>
