<ngx-charts-heat-map [animations]="false"
                          [view]="view"
                     [scheme]="colorScheme"
                     [legend]="legend"
                     [showXAxisLabel]="showXAxisLabel"
                     [showYAxisLabel]="showYAxisLabel"
                     [xAxis]="xAxis"
                     [yAxis]="yAxis"
                     [xAxisLabel]="xAxisLabel"
                     [yAxisLabel]="yAxisLabel"
                     [results]="multi"
                     (select)="onSelect($event)"
                     (activate)="onActivate($event)"
                     (deactivate)="onDeactivate($event)">
</ngx-charts-heat-map>

