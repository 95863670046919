import { DatePipe } from '@angular/common';
import is from '@angular/common/locales/extra/is';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import * as moment from 'moment';

@Component({
  selector: 'app-advanced-timings',
  templateUrl: './advanced-timings.component.html',
  styleUrls: ['./advanced-timings.component.css']
})
export class AdvancedTimingsComponent implements OnInit {

  formGroup: FormGroup;
  detect: boolean = true;
  date: FormControl = new FormControl();
  tz: number;

  @ViewChild('picker') public picker: any | undefined;

  constructor(private datePipe: DatePipe) {
    this.formGroup = new FormGroup({
      date: this.date
    })
  }

  ngOnInit(): void {
    this.formGroup.valueChanges.subscribe(() => {
      if (this.detect) {
        let date = new Date(this.formGroup.controls.date.value.getTime());
        this._datetime = moment(date).subtract(this.tz, 'm').toDate();
        this.valueChange.emit(this._datetime);
      }
    });

    this.populateForm();
  }

  @Input()
  get isUtc(): boolean {
    return this._isUtc;
  }
  set isUtc(value: boolean) {
    this._isUtc = value;
    if (this.formGroup) {
      this.populateForm();
    }
  }
  private _isUtc: boolean = true;

  @Input()
  label: string = "";

  @Input()
  placeholder: string = "";

  @Input()
  get timezone(): string {
    return this._timezone;
  }
  set timezone(value: string) {
    this._timezone = value;
    this.populateForm();
  }
  private _timezone: string = null;

  @Input()
  get value(): Date {
    return this._datetime;
  }
  set value(value: Date) {
    if (value instanceof Date) {
      this._datetime = value;
    }
    else {
      try {
        this._datetime = new Date(value);
      }
      catch { }
    }
    if (this.formGroup) {
      this.populateForm();
    }
  }
  private _datetime: Date = new Date();

  @Output() valueChange = new EventEmitter<Date>();

  private populateForm() {
    if (this.formGroup == undefined || this._timezone == null) {
      this.detect = true;
      return;
    }

    let datetz = (this._datetime.getTimezoneOffset() ?? 0);
    let tztz = (moment.tz.zone(this._timezone)?.utcOffset(0) ?? 0);
    this.tz = datetz;
    // console.log(this._datetime + "," + datetz + "," + tztz);
    this.detect = false;
    let temp = moment(this._datetime).add(this.tz, 'm').toDate();
    this.formGroup.controls.date.setValue(temp);
    this.detect = true;
  }

}
