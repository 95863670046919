import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { FinancialService } from '../../finacial.Service';
import { EstimateModel, PanelModel } from '../../panel.Service';
import { SurveyModel } from '../../survey.Service';
import { CcPaymentComponent } from '../cc-payment/cc-payment.component';

@Component({
  selector: 'app-cc-balance',
  templateUrl: './cc-balance.component.html',
  styleUrls: ['./cc-balance.component.css']
})
export class CcBalanceComponent implements OnInit {

  balance: number = 0;

  constructor(
    private financialService: FinancialService,
    private dialog: MatDialog,
  ) {
    this.checkBalance();
  }

  ngOnInit(): void {
  }

  proceed() {
    const dialogRef = this.dialog.open(CcPaymentComponent, { width: '80%', data: { estimate: this._estimate, panel: this._panel, questionCount: 5, survey: this._survey } });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.purchaseMade.emit();
      }
    });
  }

  checkBalance() {
    this.financialService.checkBalance().subscribe(result => {
      this.balance = result
    });
  }

  @Input()
  get panel(): PanelModel | null {
    return this._panel;
  }
  set panel(value: PanelModel | null) {
    this._panel = value;
  }
  private _panel: PanelModel | null = null;

  @Input()
  get estimate(): EstimateModel {
    if (this._estimate) {
      return this._estimate;
    }

    this._estimate = new EstimateModel();
    return this._estimate;
  }
  set estimate(value: EstimateModel) {
    this._estimate = value;
  }
  private _estimate: EstimateModel = new EstimateModel();

  @Input()
  get survey(): SurveyModel | null {
    return this._survey;
  }
  set survey(value: SurveyModel | null) {
    this._survey = value;
  }
  private _survey: SurveyModel | null = null;

  @Output() purchaseMade = new EventEmitter();

}
