<ngx-charts-bar-vertical-stacked [animations]="false"
                          [view]="view"
                                 [scheme]="colorSets"
                                 [results]="groupResults"
                                 [gradient]="gradient"
                                 [xAxis]="showXAxis"
                                 [yAxis]="showYAxis"
                                 [legend]="showLegend"
                                 [legendPosition]="legendPosition"
                                 [showXAxisLabel]="showXAxisLabel"
                                 [showYAxisLabel]="showYAxisLabel"
                                 [xAxisLabel]="xAxisLabel"
                                 [yAxisLabel]="yAxisLabel"
                                 [legendTitle]="legendTitle"
                                 (select)="onSelect($event)"
                                 (activate)="onActivate($event)"
                                 (deactivate)="onDeactivate($event)">
</ngx-charts-bar-vertical-stacked>
