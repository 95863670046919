<div>
  <div class="panel-slctr-template-heading-div">
    <span>Select one of our Pre-profiled Respondent Groups</span>
  </div>
  <div class="wrapFlex">
    <div *ngFor="let panel of panels; let i = index;" class="panel-slctr-template-cards-div">
      <a>
        <mat-card (click)="onSelectedAction(i)" class="panel-slctr-template-cards">
          <img mat-card-image [src]="Media(i) | safe: 'resourceUrl'" alt="Select & Buy Respondents" class="panel-slctr-template-cards-image">
          <mat-card-content>
            <div class="panel-slctr-template-card-name-div">
              <span>{{panel.Name}}</span>
            </div>
            <div class="panel-slctr-template-card-remark-div">
              <span>{{panel.Remark}}</span>
            </div>
            <div class="panel-slctr-template-card-desc-div">
              <span>{{panel.Description}}</span>
              <br />
              <span>{{panellistCount(i)}}</span>
            </div>
          </mat-card-content>
        </mat-card>
      </a>
    </div>
  </div>
</div>
