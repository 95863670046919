import { Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AuthenticationService, UserGroup, Group, Policy } from '../../authentication.Service';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MissionService } from '../../MissionService';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-policy-group',
  templateUrl: './policy-group.component.html',
  styleUrl: './policy-group.component.css'
})
export class PolicyGroupComponent implements OnDestroy {
  groupDetailsForm: FormGroup;
  groupId: number;
  subscription: Subscription;
  policies: Policy[] = [];
  defaultPolicies: Policy[] = [];
  previews: boolean[] = [];

  constructor(public translate: TranslateService,
    private router: Router,
    private missionService: MissionService,
    private authService: AuthenticationService,
    private actRoute: ActivatedRoute
  ) {
    this.subscription = missionService.missionAnnounced$.subscribe(
      mission => {
        if (mission === "Back") {
          this.onSubmit();
        }
      });
    if (!this.router.url.startsWith("/usergroup")) {
      this.groupId = this.actRoute.snapshot.params?.id ?? -1;
      if (this.groupId > 0) {
        this.authService.getPolicyGroup(this.groupId).subscribe(
          result => { this.group = result; }
        );
        this.authService.getPoliciesForGroup(this.groupId).subscribe(
          result => {
            this.policies = result;
            for (let i = 0; i < this.policies.length; i++) {
              this.previews.push(true);
            }
          }
        );
      }
    }

    this.initForm();
  }

  initForm() {
    this.groupDetailsForm = new FormGroup({
      Name: new FormControl("", [Validators.required]),
      Description: new FormControl("", [Validators.required])
    });
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }

  createPolicy() {
    this.previews.push(false);
    let policy = new Policy();
    policy.GroupId = this.groupId;
    this.policies.push(policy);
  }

  clickOnButton(button: number) {
    switch (button) {
      case 0:
        this.onClick?.emit({ action: 'edit', id: this.group.GroupId });
        break;
      case 1:
        this.onClick?.emit({ action: 'delete', id: this.group.GroupId });
        break;
      case 2:
        this.preview = true;
        this.previewChange.emit(true);
    }
  }

  onSubmit() {
    this.router.navigate(["/security"]);
  }

  @Input()
  get group(): Group {
    return this._group
  }
  set group(value: Group) {
    this._group = value;
    this.policyCheck();
  }
  private _group: Group = null;

  @Input()
  get userGroup(): UserGroup {
    return this._userGroup;
  }
  set userGroup(value: UserGroup) {
    this._userGroup = value;
    this.policyCheck();
  }
  private _userGroup: UserGroup = null;

  @Input()
  get preview(): boolean {
    return this._preview;
  }
  set preview(value: boolean) {
    this._preview = value;
  }
  private _preview: boolean = false;

  @Output()
  previewChange = new EventEmitter<boolean>();

  @Output()
  onClick: EventEmitter<any> = new EventEmitter<any>();

  policyCheck() {
    if (this.group == null || this.userGroup == null) {
      return;
    }

    this.authService.getPolicyDetailsForUserGroup(this.group.GroupId, this.group.Name).subscribe(
      result => {
        this.policies = result;
        this.previews = [];
        for (let i = 0; i < this.policies.length; i++) {
          this.previews.push(true)
        }
      }
    );

    this.authService.getDefaultPolicies(this.group.Name).subscribe(
      result => {
        this.defaultPolicies = result;
      }
    );
  }

  policyClick(data) {
    switch (data.action) {
      case 'delete':
        for (let i = 0; i < this.policies.length; i++) {
          if (this.policies[i].PolicyId == data.id) {
            if (data.id > 0) {
              let policy = this.policies[i];
              this.policies.splice(i, 1);
              policy.IsDeleted = true;
              this.authService.savePolicy(policy).subscribe(result => {
              });
            }

            this.previews.splice(i, 1);
          }
        }

        break;
    }
  }
}
