<div [formGroup]="formGroup">
  <div>
    <mat-form-field appearance="outline" class="fullWidth">
      <mat-label>Content</mat-label>
      <textarea matInput placeholder="Content" [readonly]="readonly" formControlName="Text"></textarea>
    </mat-form-field>
  </div>
  <div>
    <mat-form-field appearance="outline" class="fullWidth">
      <mat-label>Examples</mat-label>
      <textarea matInput placeholder="Examples" [readonly]="readonly" formControlName="Examples"></textarea>
    </mat-form-field>
  </div>
</div>
