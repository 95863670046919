<div cdkDropList  (cdkDropListDropped)="drop($event)">
  <div *ngFor="let quota of quotas; let i = index" class="quota-list" (click)="setSelectedQuota(i)" cdkDrag>
    <div class="questionnaire-sidebar-custom-placeholder" *cdkDragPlaceholder></div>
    <div *ngIf="selectedQuota == i" class="quota-edit" style="width:99%">
      <ng-container *ngTemplateOutlet="actions; context: {$implicit: {where: 'before', index: i}}"></ng-container>
      <app-quota-definition [display]="false" [rules]="quota" (changed)="quotaUpdated(i, $event)" [variables]="variables"></app-quota-definition>
      <ng-container *ngTemplateOutlet="actions; context: {$implicit: {where: 'after', index: i}}"></ng-container>
    </div>
    <div *ngIf="selectedQuota != i" style="width:99%">
      <div class="alert alert-primary survey-flow-ruleLayout cursor-grab" role="alert" style="margin:10px 20px;padding: 20px 20px">
        @if (quota.Rules.length == 0 || quota.Rules[0].Rules.length == 0) {
        Quota not properly defined.
        }
        @else {
        {{ buildSummary(i)}}
          <div *ngIf="quota.Quota?.Progress > 0">
            . Progress&nbsp;
          </div>
          <div *ngIf="quota.Quota?.Progress > 0" style="width: 40px;">
            <mat-progress-bar mode="determinate" [value]="calcQuotaPercent(quota)"></mat-progress-bar>
          </div>
          <div>
            &nbsp;{{quota.Quota?.Progress < 1 ? '' : quota.Quota.Progress}}
          </div>
        }
      </div>
    </div>
    <div class="width-30" style="margin: auto 0px">
      <div class="quota-list-hide" cdkDragHandle>
        <mat-icon>drag_indicator</mat-icon>
      </div>
    </div>
  </div>
</div>
<ng-template #actions let-d>
  <div class="simple-row" style="padding: 0px 20px">
    <div class="wd-49-per">
    </div>
    <div [style]="d.where == 'before' ? 'display:flex;flex-flow:row;margin-top:-20px' : 'display:flex;flex-flow:row;margin-bottom:-20px'">
      <button mat-mini-fab (click)="performAction($event, d.where)"><mat-icon>add</mat-icon></button>
      <button *ngIf="quotas.length > 1" mat-mini-fab color="accent" matTooltip="Delete Block" (click)="deleteAction()"><mat-icon>delete</mat-icon></button>&nbsp;
    </div>
    <div class="wd-49-per">
    </div>
  </div>
</ng-template>
