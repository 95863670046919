<app-enhanced-list #list [data]="panels" [total]="count" [border]="canEdit" [filter]="filters" [actions]="actions"
                   [pageSize]="panelPageSize" [addButton]="canEdit ? 'Add Respondent Template' : ''" [template]="panel" (doAction)="doAction($event)"></app-enhanced-list>
<ng-template #panel let-data="data">
  <div style="display:flex;flex-flow:row">
    <div style="display:flex;flex-flow:column;width:99%;font-size:1.1em">
      <div style="display:flex;flex-flow: row;font-size:1.1em;align-items:center">
        <div>
          {{data.Name}} - {{data.Description}}&nbsp;&nbsp;
        </div>
        <div>
          <span [class]="data.IsCint ? 'badge text-bg-info' : 'badge text-bg-primary'">{{data.IsCint ? 'Cint' : 'Personal'}}</span>
        </div>
      </div>
      <div style="font-size:1.0em;display:flex;flex-flow:row;width:100%;align-items:center;align-content:center;vertical-align:middle;color:#aaa">
        <div>
          <i class="bi bi-chat-left-dots" placement="top" ngbTooltip="Mobile Numbers" tooltipClass="tooltip-custom-class"></i>&nbsp;
        </div><div>
          {{data.Summary?.Devices}} Mobile Number(s)&nbsp;&nbsp;
        </div>
        <div>
          <mat-icon>email</mat-icon>&nbsp;
        </div><div>
          {{data.Summary?.Emails}} Email Address(es)&nbsp;&nbsp;
        </div>
        <div>
          <mat-icon>diversity_1</mat-icon>&nbsp;
        </div>
        <div>
          {{data.PanellistCount}} Respondent(s)&nbsp;&nbsp;</div> 
        <div>
          <mat-icon>calendar_month</mat-icon>&nbsp;
        </div><div>
          Created <app-timeago [created]="data.CreatedDate" [amended]="data.LastUpdatedDate"></app-timeago>
        </div>
      </div>
    </div>
    <div>
      <div style="width:1%">
        <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="icon-button with a menu" (click)="$event.stopPropagation()">
          <mat-icon>more_vert</mat-icon>
        </button>
        <mat-menu #menu="matMenu">
          <button mat-menu-item (click)="editPanel(data)">
            <mat-icon>edit</mat-icon>
            <span>Edit</span>
          </button>
          <button mat-menu-item (click)="renamePanel(data)">
            <mat-icon>settings</mat-icon>
            <span>Settings</span>
          </button>
          <button mat-menu-item (click)="deletePanel(data)">
            <mat-icon>delete</mat-icon>
            <span>Delete</span>
          </button>
          <button mat-menu-item (click)="copyPanel(data)">
            <mat-icon>file_copy</mat-icon>
            <span>Copy</span>
          </button>
        </mat-menu>
      </div>
    </div>
  </div>
</ng-template>
