import { Component, OnInit } from '@angular/core';
import { FormGroup, FormArray, Validators } from '@angular/forms';
import { BaseQuestion, DataModel, InterviewService, ParameterModel, QuestionService } from '../../question.service';

@Component({
  selector: 'qe-app-terminate',
  templateUrl: './qe-terminate.component.html',
  styleUrls: ['./qe-terminate.component.css']
})
export class QeTerminateComponent extends BaseQuestion implements OnInit {
  showRedirect: boolean = false;

  constructor(private qs: QuestionService, private is: InterviewService) {
    super(qs, is, undefined, undefined, new DataModel("terminate"));
  }

  public terminateFormGroup: FormGroup = new FormGroup({});

  ngOnInit() {
    this.terminateFormGroup = this.fb.group({
      terminateFormArray: this.fb.array([this.initQuestion()])
    });
    this.terminateFormGroup.valueChanges.subscribe(x => {
      let array = this.terminateFormGroup.controls.terminateFormArray as FormArray;
      let form = array.controls[0] as FormGroup;
      if (this.model.QuestionName !== form.controls.qNumber.value) {
        this.model.QuestionName = form.controls.qNumber.value;
        this.model.QuestionNameTouched = true;
      }
      this.model.Banner = form.controls.banner.value;
      this.model.Parameters = [];
      this.showRedirect = form.controls.action.value === "redirect";
      this.model.Parameters.push(this.createParameterModel("action", form.controls.action.value));
      this.model.Parameters.push(this.createParameterModel("redirect", form.controls.redirect.value));
    });
  }


  get questionArr(): FormArray | null {
    if (this.terminateFormGroup) {
      return this.terminateFormGroup.get('terminateFormArray') as FormArray;
    }

    return null;
  }

  createParameterModel(name: string, value: string): ParameterModel {
    let model = new ParameterModel();
    model.Name = name;
    model.Value = value;
    return model;
  }


  getParameter(name: string): string {
    for (let i = 0; i < this.model.Parameters.length; i++) {
      if (this.model.Parameters[i].Name === name) {
        return this.model.Parameters[i].Value;
      }
    }

    return "";
  }

  initQuestion() {
    this.showRedirect = this.getParameter("action") === "redirect";
    this.formGroup = this.fb.group({
      qNumber: [this.model.QuestionName, Validators.required],
      banner: [this.model.Banner],
      action: [this.getParameter("action"), Validators.required],
      redirect: [this.getParameter("redirect")]
    });
    return this.formGroup;
  }
}
