<div class="comm-list-main-container">
  <mat-sidenav-container>
    <mat-sidenav #leftSidenav class="reports-mat-sidenav-left" mode="side" opened [disableClose]="true">
      <div class="simple-row">
        &nbsp;
        <button mat-stroked-button [matMenuTriggerFor]="ioMenu">
          <mat-icon>expand_more</mat-icon>
          <span>Create</span>
        </button>&nbsp;
        <button mat-icon-button (click)="getDetails()">
          <mat-icon>refresh</mat-icon>
        </button>
        <mat-menu #ioMenu="matMenu">
          <button mat-menu-item (click)="newQueue()">
            <span>Message Manager Queue</span>
          </button>
        </mat-menu>
      </div>
      <div class="reports-leftSidebar-list custom-scroll-bar comm-list-sidebar-div">
        <div class="reports-question-sidebar-box" *ngFor="let detail of details; let i = index" cdkDrag>
          <div (click)="selectedIndex = i" [class]="selectedIndex == i ? 'comm-list-selected' : 'comm-list-notSelected'">
            <div class="reports-qnSidebar-text">
              <span>{{detail.name}}&nbsp;{{detail.comms?.length > 0 ? '(' + detail.comms?.length + ')' : ''}}</span>
            </div>
          </div>
        </div>
      </div>
    </mat-sidenav>
    <mat-sidenav-content class="comm-list-sidenav-content">
      <div *ngIf="selectedIndex == 0" class="comm-list-sidenav-div">
        <div class="margin-5">
          <h3>{{details[selectedIndex].description}}</h3>
        </div>
        <div class="margin-5">
          <h3>Welcome to the DIY Surveys' Messaging Routing Services</h3>
          <p>
            Below is a list of the active services on the connected Message Routing Server.
          </p>
        </div>
        <div class="custom-scroll-bar comm-list-editor-div">
          <app-service-list [services]="zoneSummary"></app-service-list>
        </div>
      </div>
      <div *ngIf="selectedIndex == 1" class="comm-list-sidenav-div">
        <div class="margin-5">
          <h3>{{details[selectedIndex].description}}</h3>
        </div>
        <div class="margin-5">
          <p>
            Connection templates can be reused by zones to save you time in general settings that may be used in many different connections.
          </p>
        </div>
        <div class="custom-scroll-bar comm-list-editor-div">
          <div *ngFor="let zone of zoneSummary; let i = index">
            <div>{{zone.Name}}</div>
          </div>
        </div>
      </div>
      <div *ngIf="selectedIndex == 2" class="comm-list-sidenav-div">
        <div class="margin-5">
          <h3>{{details[selectedIndex].description}}</h3>
        </div>
        <div class="margin-5">
          <h3>Sub Title</h3>
          <p>
            Description
          </p>
        </div>
        <div class="custom-scroll-bar comm-list-editor-div">
          <div *ngFor="let zone of zoneSummary; let i = index">
            <div>{{zone.Name}}</div>
          </div>
        </div>
      </div>
      <div *ngIf="selectedIndex == 3" class="comm-list-sidenav-div">
        <div class="margin-5">
          <h3>{{details[selectedIndex].description}}</h3>
        </div>
        <div class="margin-5">
          <h3>
            Balance clients messaging experience across our platform.
          </h3>
          <p>
            Manage the message manager queues for inviation and API performance. regardless of the message manager queues avilable the same queuing will be applied across them all.
          </p>
        </div>
        <div class="custom-scroll-bar comm-list-editor-div">
          <h3>Message Manager Services</h3>
          <app-service-list [services]="messageSummary"></app-service-list>
          <p>
            &nbsp;
          </p>
          <h3>Message Queues</h3>
          <div *ngFor="let queue of queues; let i = index">
            <div><app-message-queue [queue]="queue" [preview]="i < queuePreviews.length ? queuePreviews[i] : 0" (closed)="processClosed($event)"></app-message-queue></div>
          </div>
        </div>
      </div>
      <div *ngIf="selectedIndex == 4" class="comm-list-sidenav-div">
        <div class="margin-5">
          <h3>{{details[selectedIndex].description}}</h3>
        </div>
        <div class="margin-5">
          <h3>Sub Title</h3>
          <p>
            Description
          </p>
        </div>
        <div class="custom-scroll-bar comm-list-editor-div">
          <div *ngFor="let zone of zoneSummary; let i = index">
            <div>{{zone.Name}}</div>
          </div>
        </div>
      </div>
      <div *ngIf="selectedIndex == 5" class="comm-list-sidenav-div">
        <div class="margin-5">
          <h3>{{details[selectedIndex].description}}</h3>
        </div>
        <div class="margin-5">
          <h3>Sub Title</h3>
          <p>
            Description
          </p>
        </div>
        <div class="custom-scroll-bar comm-list-editor-div">
          <div *ngFor="let zone of zoneSummary; let i = index">
            <div>{{zone.Name}}</div>
          </div>
        </div>
      </div>
      <div *ngIf="selectedIndex == 6" class="comm-list-sidenav-div">
        <div class="margin-5">
          <h3>{{details[selectedIndex].description}}</h3>
        </div>
        <div class="margin-5">
          Gateway traffic analysis allows you to see the status of the network over a period of time.
        </div>
        <div class="custom-scroll-bar comm-list-editor-div">
          <app-traffic-analysis></app-traffic-analysis>
        </div>
      </div>

    </mat-sidenav-content>
  </mat-sidenav-container>
</div>
