<div [formGroup]="formGroup">
  <h2>Financial Transactions</h2>
  <div *ngIf="isAdminUser">
    <button mat-raised-button class="mt-5" color="primary" (click)="findUser($event)">Find User</button>&nbsp;
    <br />
  </div>
  <div *ngIf="userProfile && userProfile.UserID" class="fin-trans-username-div">
    Username: {{userProfile.UserName}}<br/> Name: {{userProfile.FirstName}} {{userProfile.LastName}}<br/> Email Address: {{userProfile.EmailAddress}}
  </div>
  <mat-form-field appearance="outline" class="fin-trans-daterange-field">
    <mat-label>Enter a range of dates</mat-label>
    <mat-date-range-input [rangePicker]="picker">
      <input matStartDate formControlName="start" placeholder="Start date">
      <input matEndDate formControlName="end" placeholder="End date">
    </mat-date-range-input>
    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
    <mat-date-range-picker #picker></mat-date-range-picker>
  </mat-form-field>
    &nbsp;&nbsp;
    <button *ngIf="isAdminUser" mat-raised-button color="primary" (click)="addTransaction()">Add</button>

    <div class="outer-table-container mat-elevation-z8">

      <div class="table-container custom-scroll-bar">
        <table mat-table #table [dataSource]="dataSource"
               multiTemplateDataRows
               matSort
               matSortActive="created"
               matSortDisableClear matSortDirection="desc">

          <!-- Name Column -->
          <ng-container matColumnDef="id">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Id </th>
            <td mat-cell *matCellDef="let tran"> {{tran.TranID}} </td>
          </ng-container>

          <!-- Created Column -->
          <ng-container matColumnDef="created">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Created </th>
            <td mat-cell *matCellDef="let tran"> {{tran.Created | date:'short': '' : translate.currentLang}} </td>
          </ng-container>

          <!-- Created Column -->
          <ng-container matColumnDef="description">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Description </th>
            <td mat-cell *matCellDef="let tran"> {{tran.Description}} </td>
          </ng-container>

          <!-- Debit Column -->
          <ng-container matColumnDef="debit">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Debit </th>
            <td mat-cell *matCellDef="let tran"> {{tran.Debit}} </td>
          </ng-container>

          <!-- Debit Column -->
          <ng-container matColumnDef="credit">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Credit </th>
            <td mat-cell *matCellDef="let tran"> {{tran.Credit}} </td>
          </ng-container>

          <!-- Action column -->
          <ng-container matColumnDef="action">
            <th mat-header-cell *matHeaderCellDef mat-sort-header></th>
            <td mat-cell *matCellDef="let tran">
              <div>
                <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="icon-button with a menu" (click)="$event.stopPropagation()">
                  <mat-icon>more_vert</mat-icon>
                </button>
                <mat-menu #menu="matMenu">
                  <button mat-menu-item>
                    <mat-icon>edit</mat-icon>
                    <span>Edit</span>
                  </button>
                  <button mat-menu-item>
                    <mat-icon>delete</mat-icon>
                    <span>Delete</span>
                  </button>
                  <button mat-menu-item>
                    <mat-icon>file_copy</mat-icon>
                    <span>Copy</span>
                  </button>
                </mat-menu>
              </div>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="columnsToDisplay; sticky: true"></tr>
          <tr mat-row *matRowDef="let tran; columns: columnsToDisplay;"
              class="users-element-row" (click)="edit(tran)">
          </tr>
        </table>
      </div>
      <mat-paginator [pageSize]="pageSize" [length]="resultsLength"></mat-paginator>
    </div>
  </div>
