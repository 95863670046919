<div fxLayout.xs="column" class="fullWidth column-flex table" ngClass.xs="table-mobile">
  <mat-card class="pricing-card">
    <div fxLayout.xs="row" class="row column-flex pb-5">
      <div class="cell message" style="column-span: all"><strong>Survey Cost Estimates</strong></div>
    </div>
    <div *ngIf="panel && panel.PanelType === 1" fxLayout.xs="row" class="row column-flex">
      <div class="cell message">
        &nbsp;&nbsp;
        <mat-form-field mat-line appearance="outline" class="pricing-summ-rate-field">
          <mat-label>Rate</mat-label>
          <input matInput name="completionRate" [(ngModel)]="completionRate" mask="0*" placeholder="Rate" (keyup)="checkNumber($event)">
        </mat-form-field>
        % Completion Rate
      </div>
      <div fxFlex class="cell value"></div>
    </div>
    <div fxLayout.xs="row" class="row column-flex">
      <div class="cell message">
        &nbsp;&nbsp;
        <mat-form-field mat-line appearance="outline" class="pricing-summ-respondents-field">
          <mat-label>No.</mat-label>
          <input matInput name="number" [readonly]="panel && (panel.PanelType === 0 || panel.PanelType === 2)" [(ngModel)]="number" mask="0*" placeholder="Number" (keyup)="checkNumber($event)">
        </mat-form-field>
        Respondents
      </div>
      <div class="cell value auto-flex"></div>
    </div>
    <div fxLayout.xs="row" class="row row-flex">
      <div class="cell message" style="width:66%">&nbsp;&nbsp;Price per Response</div>
      <div class="cell value auto-flex align-end-flex display-flex wd-25-per">£{{estimate?.Rate | number:'1.2'}}</div>
    </div>
    <div fxLayout.xs="row" class="row row-flex">
      <div class="cell message halfWidth">&nbsp;&nbsp;{{summary}}</div>
      <div class="cell value auto-flex halfWidth"></div>
    </div>
    <div *ngIf="panel && (panel.PanelType === 0 || panel.PanelType === 2)" fxLayout.xs="row" class="row row-flex">
      <div><strong>&nbsp;&nbsp;Targetting Options</strong></div>
    </div>
    <div *ngIf="panel && (panel.PanelType === 0 || panel.PanelType === 2)" fxLayout.xs="row" class="row row-flex">
      <div class="pricing-summ-audience-container custom-scroll-bar">
        <app-audience class="fullWidth ml-15" #audience [preview]="true" [model]="model"></app-audience>
      </div>
    </div>
    <div fxLayout.xs="row" class="row column-flex margin-top-bot-10">
      <mat-divider></mat-divider>
    </div>
    <div fxLayout.xs="row" class="row row-flex margin-top-bot-10">
      <div class="cell message" style="width:66%"><strong>Total</strong></div>
      <div class="cell value display-flex wd-25-per align-end-flex auto-flex"><strong>£{{estimate?.Total | number:'1.2'}}</strong></div>
    </div>
  </mat-card>
  &nbsp;
  <mat-card *ngIf="showBalance && !enoughBalance" class="column-flex align-start-flex">
    <app-cc-balance [panel]="panel" [survey]="survey" [estimate]="estimate" (purchaseMade)="purchaseMade()"></app-cc-balance>
  </mat-card>
</div>
