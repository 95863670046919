import { Component, ComponentFactoryResolver, ComponentRef, Input, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { CaseDataService } from '../../casedata.Service';
import { WordCloudChartComponent } from '../../charts/word-cloud-chart/word-cloud-chart.component';
import { QuestionReportData } from '../reports.component';


@Component({
  selector: 'app-chart-item',
  templateUrl: './chart-item.component.html',
  styleUrls: ['./chart-item.component.css']
})
export class ChartItemComponent implements OnInit {

  isDone = false;
  @ViewChild('chartContainer', { static: false, read: ViewContainerRef }) chart: ViewContainerRef | undefined;

  constructor(
    private resolver: ComponentFactoryResolver,
    private caseDataService: CaseDataService
  ) { }

  ngOnInit(): void {


  }

  get surveyId(): number {
    return this._surveyId;
  }
  set surveyId(value: number) {
    this._surveyId = value;
  }
  private _surveyId: number = 0;

  @Input()
  get question(): QuestionReportData | null {
    return this._question;
  }
  set question(value: QuestionReportData | null) {
    this._question = value;
  }
  private _question: QuestionReportData | null = null;

  isReady() {
    if (!this.chart || !this._question) {
      return false;
    }

    if (this.isDone) {
      return true;
    }

    if (!this._question.isReady) {
      return false;
    }

    this.chart.clear();
    const component = this._question.chartComponent(this.resolver);
    if (component === undefined) {
      return true;
    }

    const temp = this.chart.createComponent(component) as ComponentRef<any>;
    temp.instance.model = this._question.reportingQuestion;
    temp.instance.results = this._question.isMatrix || this._question.isMaxDiff || this._question.isBucket ? this._question.matrixResults : this._question.results;
    temp.instance.scheme = this._question.reportingQuestion.ColourScheme;
    let comp = temp.instance;
    if (comp instanceof WordCloudChartComponent) {
      let c = temp.instance as WordCloudChartComponent
      c.words = this._question.data;
    }
    else {
      temp.instance.data = this._question.table;
    }
    this.isDone = true;
    return true;
  }

  getQuestionLabel(): string {
    return this._question?.reportingQuestion.Title ? this._question.reportingQuestion.Title : this._question?.question.Summary ?? "";
  }


}
