import { Component, Input } from '@angular/core';
import { ZoneSummary } from '../../network.Service';

@Component({
  selector: 'app-service-list',
  templateUrl: './service-list.component.html',
  styleUrl: './service-list.component.css'
})
export class ServiceListComponent {
  displayedColumns = ['Name', 'IpAddress', 'Port', 'Type', 'Status'];

  edit(service: ZoneSummary) {

  }

  zoneType(type: number): string {
    switch (type) {
      case 0: return "Shotgun";
      case 1: return "SMS Gateway";
      case 2: return "Message Manager";
      case 6: return "WhatsApp Gateway";
      default: return "Unknown";
    }
  }

  zoneStatus(status: number): string {
    switch (status) {
      case 0: return "Starting";
      case 1: return "Running";
      case 2: return "Stopped";
      default: return "Unknown";
    }
  }

  @Input()
  get services(): ZoneSummary[] {
    return this._services;
  }
  set services(value: ZoneSummary[]) {
    this._services = value;
  }
  private _services: ZoneSummary[] = null;
}
