import { Component } from '@angular/core';
import { Observable } from "rxjs";
import { tap } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { MatTableDataSource } from '@angular/material/table';
import { SurveyService, SurveySummaryModel } from '../../survey.Service';
import { UserProfile, UserService } from '../../user.Service';

@Component({
  selector: 'app-active-surveys',
  templateUrl: './active-surveys.component.html',
  styleUrls: ['./active-surveys.component.css']
})
export class ActiveSurveysComponent {
  tempSummary: Observable<any>;
  summary: SurveySummaryModel[] = null;
  users: Map<number, UserProfile> = new Map<number, UserProfile>();
  displayedColumns = ['UserID', 'Launched', 'Description'];
  dataSource = new MatTableDataSource(this.summary);

  constructor(public translate: TranslateService,
    private surveyService: SurveyService,
    private userService: UserService) {
    this.tempSummary = this.surveyService.getSurveySummariesForSystem().pipe(tap<SurveySummaryModel[]>(
      result => {
        this.summary = result;
        this.dataSource = new MatTableDataSource(this.summary);
        for (let i = 0; i < this.summary.length; i++) {
          if (!this.users.has(this.summary[i].UserID)) {
            this.userService.getUser(this.summary[i].UserID.toString()).subscribe(
              result => {
                this.users.set(this.summary[i].UserID, result);
              });
          }
        }
      }
    ));
  }

  edit(row: any) {

  }
}
