<div class="fullWidth dialog-title">
  <div class="leftFloat">
    <h3>Language Translation Editor</h3>
  </div>
  <div class="rightFloat">
    <button mat-icon-button matDialogClose="no" matTooltip="Close" matTooltipPosition="below">
      <mat-icon class="dialog-close-icon">close</mat-icon>
    </button>
  </div>
</div>
<div class="margin-top-bot-5">&nbsp;</div>
<div class="outer-table-container mat-elevation-z8">
  <div class="lang-edtr-table-container custom-scroll-bar">
    <table mat-table #table [dataSource]="dataSource"
           multiTemplateDataRows>
      <ng-continer *ngFor="let column of columnsToDisplay; let colIndex = index" matColumnDef="{{column}}" [sticky]="colIndex == 0">
        <th mat-header-cell *matHeaderCellDef class="padding-10">
          <div class="basic-data-header-container" matTooltip="{{column == '..' ? '' : column}}" matTooltipPosition="above">
            <span class="basic-data-header-span">{{getLanguage(column).Description}}&nbsp;({{column}})</span>
          </div>
        </th>
        <td mat-cell *matCellDef="let element" class="padding-10 vert-align-top">
          <div *ngIf="colIndex == 0">
            <div *ngIf="element.LanguageElementType == 0">
              <div class="fieldRowWithPadding">
                <div>
                  <mat-icon>{{getQuestionModel(element).Icon}}</mat-icon>
                </div>
                <div>
                  &nbsp;<strong>{{element.QuestionFullName}}</strong>
                </div>
                <div>
                  &nbsp;<strong>({{getQuestionModel(element).QuestionTypeDescription}})</strong>
                </div>
              </div>
            </div>
            <div *ngIf="element.LanguageElementType == 3">
              <div class="fieldRowWithPadding">
                <div>
                  <mat-icon>switch_right</mat-icon>
                </div>
                <div>
                  &nbsp;<strong>Navigation - {{element.QuestionName}}</strong>
                </div>
                <div>
                  &nbsp;<strong>({{getQuestionModel(element).Banner}})</strong>
                </div>
              </div>
            </div>
            <div *ngFor="let e of collectElementsForQuestion(element.QuestionFullName)">
              <div [innerHTML]="getLabel(e, colIndex)"></div>
            </div>
          </div>
          <div *ngIf="colIndex > 0 && isEdit(element, colIndex)" [formGroup]="formGroup">
            <div class="column-flex-flow" formArrayName="array">
              <div *ngFor="let e of collectElementsForQuestion(element.QuestionFullName);let i = index" [formGroupName]="i" class="row-flex-flow">
                <div *ngIf="e.LanguageElementType == 0 && e.AdditionalElement.length == 0" class="fullWidth">
                  <diy-editor [channel]="interview.Channel" [questions]="interview.reportableQuestions()" formControlName="text"></diy-editor>
                </div>
                <div *ngIf="e.LanguageElementType == 3">
                  <mat-form-field appearance="outline">
                    <mat-label>Navigation</mat-label>
                    <input matInput placeholder="Navigation" formControlName="text">
                  </mat-form-field>
                </div>
                <div *ngIf="e.AdditionalElement.length > 0" class="fullWidth mt-2">
                  <mat-form-field appearance="outline" class="lang-edtr-advancedOptionRow fullWidth">
                    <mat-icon *ngIf="interview.Channel != 'sms' && categories[i - 1]" matPrefix>{{icon}}</mat-icon>
                    <mat-label>
                      {{optionText[i - 1]}}
                      <mat-icon *ngIf="categories[i - 1] && categories[i - 1].Other">short_text</mat-icon>
                      <mat-icon *ngIf="categories[i - 1] && categories[i - 1].Fixed">vertical_distribute</mat-icon>
                      <mat-icon *ngIf="categories[i - 1] && categories[i - 1].Exclusive">done</mat-icon>
                    </mat-label>
                    <div>
                      <textarea matInput formControlName="text" class="scroll"
                                cdkTextareaAutosize
                                #autosize="cdkTextareaAutosize"
                                cdkAutosizeMinRows="1"
                                cdkAutosizeMaxRows="10"></textarea>
                    </div>
                  </mat-form-field>
                </div>
                <div>
                  <button mat-icon-button [matMenuTriggerFor]="actionMenu" aria-label="icon-button with a menu" (click)="$event.stopPropagation()">
                    <mat-icon>more_vert</mat-icon>
                  </button>
                  <mat-menu #actionMenu="matMenu">
                    <button mat-menu-item (click)="translateLabel(e, colIndex, i)">
                      <mat-icon>view_headline</mat-icon>
                      <span>Translate</span>
                    </button>
                    <button mat-menu-item (click)="copyLabel(e, colIndex, i)">
                      <mat-icon>replay</mat-icon>
                      <span>Copy</span>
                    </button>
                  </mat-menu>
                </div>
              </div>
            </div>
          </div>
          <div *ngIf="colIndex > 0 && !isEdit(element, colIndex)" class="fullWidth" (click)="edit(element, colIndex)">
            <div class="lang-edtr-missLang-top-padding"></div>
            <div *ngIf="invalidElementsForQuestion(element, colIndex)" class="alert alert-warning pt-2 lang-edtr-missLang-msg-div" role="alert">
              The question is missing language translations
            </div>
            <div *ngFor="let e of collectElementsForQuestion(element.QuestionFullName)">
              <div [innerHTML]="getLabel(e, colIndex)"></div>
            </div>
          </div>
        </td>
      </ng-continer>
      <tr mat-header-row *matHeaderRowDef="columnsToDisplay; sticky: true"></tr>
      <tr mat-row *matRowDef="let element; columns: columnsToDisplay;"
          class="users-element-row">
      </tr>
    </table>
  </div>
</div>
<div class="mt-10">
  <button mat-raised-button color="primary" (click)="save()">
    <span>Save</span>
  </button>&nbsp;
</div>
