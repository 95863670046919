<h2>Send Message</h2>
<div class="flex-stretch-column margin-10">
  <form [formGroup]="formGroup" (ngSubmit)="sendMessage()">
    <div>
      <mat-form-field appearance="outline" class="halfWidth">
        <mat-label>HMAC Access Token</mat-label>
        <mat-select [formControl]="keysControl" required>
          <mat-option>--</mat-option>
          <mat-option *ngFor="let key of keys" [value]="key">
            {{key.Name}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="mb-10">
      <mat-button-toggle-group #group="matButtonToggleGroup" formControlName="Type">
        <mat-button-toggle value="sms">SMS</mat-button-toggle>
        <mat-button-toggle value="whatsappinvite">WhatsApp Invite</mat-button-toggle>
        <mat-button-toggle value="whatsapp">WhatsApp</mat-button-toggle>
      </mat-button-toggle-group>
    </div>
    <div *ngIf="isSms()">
      <mat-form-field mat-line appearance="outline">
        <mat-label>Source</mat-label>
        <input matInput placeholder="Source" formControlName="Source">
      </mat-form-field>
    </div>
    <div>
      <mat-form-field mat-line appearance="outline">
        <mat-label>Destination</mat-label>
        <input matInput placeholder="Destination" formControlName="Destination">
      </mat-form-field>
    </div>
    <div>
      <app-sms-editor #sms [text]="message" [channel]="isSms() ? 'sms' : 'whatsapp'" [smart]="false"></app-sms-editor>
    </div>
    <div>&nbsp;</div>
    <div>
      <p>Flags</p>
      <mat-button-toggle-group #group="matButtonToggleGroup" multiple formControlName="Flags">
        <mat-button-toggle value="reply">Reply</mat-button-toggle>
        <mat-button-toggle value="lookup">Lookup</mat-button-toggle>
        <mat-button-toggle value="test">Test</mat-button-toggle>
      </mat-button-toggle-group>
    </div>
    <div>
      <button mat-raised-button type="submit" class="mt-5" color="primary">Send</button>&nbsp;
    </div>
    <div>
      <br />
    </div>
    <div>
      <pre>{{response}}</pre>
    </div>
  </form>
</div>
