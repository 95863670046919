import { AfterViewInit, Component, ElementRef,Inject,  OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { ImageCropComponent } from '../image-cropper/image-crop.component';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { MatAutocompleteSelectedEvent, MatAutocomplete } from '@angular/material/autocomplete';
import { map, startWith } from 'rxjs/operators';
import { PanelModel, PanelService } from '../panel.Service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatChipInputEvent } from '@angular/material/chips';
import { AuthenticationService } from '../authentication.Service';
import { CintQuota } from '../cint.Service';

@Component({
  selector: 'app-new-panel',
  templateUrl: './new-panel.component.html',
  styleUrls: ['./new-panel.component.css']
})
export class NewPanelComponent implements OnInit, AfterViewInit {
  selectedFile: File | null = null;
  formGroup: FormGroup = new FormGroup({});
  title = "Create Your Respondent Template";
  buttonName = "Create Template";
  separatorKeysCodes: number[] = [ENTER, COMMA];
  categoryCtrl = new FormControl();
  filteredCategories?: Observable<string[]>;
  categories: string[] = ['Category 1'];
  allCategories: string[] = ['Category 1', 'Category 2', 'Category 3', 'Category 4'];
  panel: PanelModel = new PanelModel();
  isAdmin = false;
  isNewAdmin = false;
  panelTypes: any;
  displayError: boolean = false;
  errorMsg: string = "";
  isNew = false;
  selectable = true;
  removable = true;
  panelId: number = 0;

  @ViewChild('categoryInput') categoryInput: ElementRef<HTMLInputElement> | undefined;
  @ViewChild("imageCropper") imageCropper: ImageCropComponent | undefined;


  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<NewPanelComponent>,
    private fb: FormBuilder,
    private panelService: PanelService,
    private auth: AuthenticationService
  ) {
    this.isAdmin = this.auth.isAuthorized(['Admin']);
    if (data && data.id && data.id > 0) {
      this.title = "Edit Your Respondent Template Details";
      this.buttonName = "Update";
      this.panelId = data.id;
    }
    else {
      this.panel = new PanelModel();
      this.panel.PanelType = 1;
      this.isNewAdmin = this.isAdmin;
      this.isNew = true;
    }

    this.panelTypes = this.isAdmin ? [
        { id: 1, value: 'Normal' },
        { id: 0, value: 'Cint' },
        { id: 2, value: 'Branded' }
      ] : [
        { id: 1, value: 'Normal' },
        { id: 0, value: 'Cint' }
      ];
  }

  ngOnInit(): void {
    this.formGroup = this.fb.group({
      PanelTitle: [this.panel.Name, Validators.required],
      PanelSubtitle: [this.panel.Remark, Validators.required],
      PanelDescription: [this.panel.Description, Validators.required],
      PanelType: ['',],
      categoryCtrl: [''],
    });
    if (!this.isNew) {
      this.panelService.getPanel(this.panelId).subscribe(result => {
        this.panel = result as PanelModel;
        Promise.resolve(null).then(() => {
          this.populateForm();
        });
      });
    }

    this.filteredCategories = this.categoryCtrl.valueChanges.pipe(
      startWith(null),
      map((category: string | null) => category ? this._filter(category) : this.allCategories.slice()));
  }

  ngAfterViewInit(): void {
  }

  populateForm() {
    this.formGroup.controls.PanelTitle.setValue(this.panel.Name);
    this.formGroup.controls.PanelSubtitle.setValue(this.panel.Remark);
    this.formGroup.controls.PanelDescription.setValue(this.panel.Description);
    this.formGroup.controls.PanelType.setValue(this.panel.PanelType.toString());
    this.formGroup.controls.categoryCtrl.setValue(this.panel.SearchTag ? this.panel.SearchTag.split(',') : [])
  }

  add(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();
    if (value) {
      this.categories.push(value);
    }

    this.categoryCtrl.setValue(null);
  }

  remove(category: string): void {
    const index = this.categories.indexOf(category);
    if (index >= 0) {
      this.categories.splice(index, 1);
    }
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    this.categories.push(event.option.viewValue);
    if (this.categoryInput != undefined) {
      this.categoryInput.nativeElement.value = '';
    }

    this.categoryCtrl.setValue(null);
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.allCategories.filter(category => category.toLowerCase().indexOf(filterValue) === 0);
  }

  onSubmit() {
    let model: PanelModel = new PanelModel();
    model.PanelID = this.panel.PanelID;
    model.Name = this.formGroup.controls.PanelTitle.value;
    model.Remark = this.formGroup.controls.PanelSubtitle.value;
    model.Description = this.formGroup.controls.PanelDescription.value;
    if (this.isAdmin) {
      model.PanelType = Number(this.formGroup.controls.PanelType.value);
      model.SearchTag = this.categories.join(',');
    }
    else {
      model.PanelType = 1;
      model.SearchTag = "";
    }

    if (this.panel.PanelID == undefined) {
      this.panel.PanelID = 0;
    }

    if (this.panel.PanelID == 0) {
      switch (model.PanelType) {
        case 0:
        case 2:
          model.IsCint = true;
          model.Quota = JSON.stringify(new CintQuota());
          break;
        default:
          model.IsCint = false;
          model.Quota = "";
          break;
      }
    }
    else {
      model.Quota = this.panel.Quota;
      model.CreatedDate = this.panel.CreatedDate;
    }

    if (this.imageCropper) {
      this.imageCropper.uploadImage().then((id: number) => {
        model.MediaID = id;
        this.updatePanel(model);
      });
    }
    else {
      model.MediaID = 0;
      this.updatePanel(model);
    }
  }

  private updatePanel(model: PanelModel) {
    let temp;
    if (model.PanelID === undefined || model.PanelID === 0) {
      temp = this.panelService.addPanel(model);
    }
    else {
      temp = this.panelService.updatePanel(model);
    }

    temp.subscribe(
      result => {
        model = result as PanelModel;
        this.dialogRef.close({ data: model, name: model.Name });
      });
  }
}
