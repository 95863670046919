import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { RuleGroup, RuleModel } from '../../question.service';

@Component({
  selector: 'app-routing-dialog',
  templateUrl: 'routing-dialog.component.html',
  styleUrls: ['routing-dialog.component.css']
})
export class RoutingDialogComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<RoutingDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit(): void {
  }

  displayOptions(): boolean {
    return this.data.name === "Answer Choice ";
  }

  displayGoto(): boolean {
    return this.data.name === "Branch ";
  }

  onChange(selection: any) {
    this.data.rules.Rules[1].Rules[0].Answers = [];
    selection.forEach((a: any) => {
      this.data.rules.Rules[1].Rules[0].Answers.push(a.value);
    });
  }

  close() {
    this.dialogRef.close(this.data.rules);
  }

  onNoClick() {
    this.dialogRef.close(this.data.rules);
  }
}
