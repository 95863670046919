import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { QuestionModel, RuleGroup, RuleList, RuleModel } from '../../question.service';
import { AdvancedRoutingComponent } from '../advanced-routing/advanced-routing.component';
import { RoutingDialogComponent } from '../routing-dialog/routing-dialog.component';

@Component({
  selector: 'app-advanced-properties',
  templateUrl: './advanced-properties.component.html',
  styleUrls: ['./advanced-properties.component.css']
})
export class AdvancedPropertiesComponent implements OnInit {

  @ViewChild('displayLogicRouting') displayLogicRouting: AdvancedRoutingComponent | undefined;
  @ViewChild('skipLogicRouting') skipLogicRouting: AdvancedRoutingComponent | undefined;
  @ViewChild('carryForwardLogicRouting') carryForwardLogicRouting: AdvancedRoutingComponent | undefined;
  @ViewChild('answerChoiceDisplayogicRouting') answerChoiceDisplayogicRouting: AdvancedRoutingComponent | undefined;
  @ViewChild('errorLogicRouting') errorLogicRouting: AdvancedRoutingComponent | undefined;


  constructor(public dialog: MatDialog) { }

  ngOnInit(): void {
  }

  @Input()
  get model(): QuestionModel | null {
    return this._model;
  }
  set model(value: QuestionModel | null) {
    this._model = value;
  }
  private _model: QuestionModel | null = null;

  showDisplayLogic(): boolean {
    if (this.model) {
      if (this.model.DisplayLogic && this.checkLogic(this.model.DisplayLogic)) {
        if (this.displayLogicRouting) {
          this.displayLogicRouting.rules = this.model.DisplayLogic;
        }
        return true;
      }
    }

    return false;
  }

  showSkipLogic(): boolean {
    if (this.model) {
      if (this.model.SkipLogic && this.checkLogic(this.model.SkipLogic)) {
        if (this.skipLogicRouting) {
          this.skipLogicRouting.rules = this.model.SkipLogic;
        }
        return true;
      }
    }

    return false;
  }

  showCarryForwardLogic(): boolean {
    if (this.model) {
      if (this.model.CarryForward && this.checkLogic(this.model.CarryForward)) {
        if (this.carryForwardLogicRouting) {
          this.carryForwardLogicRouting.rules = this.model.CarryForward;
        }
        return true;
      }
    }

    return false;
  }

  showAnswerChoiceDisplayLogic(): boolean {
    if (this.model) {
      if (this.model.AnswerChoiceLogic && this.checkLogic(this.model.AnswerChoiceLogic)) {
        if (this.answerChoiceDisplayogicRouting) {
          this.answerChoiceDisplayogicRouting.rules = this.model.AnswerChoiceLogic;
        }
        return true;
      }
    }

    return false;
  }

  showErrorLogic(): boolean {
    if (this.model) {
      if (this.model.ErrorLogic && this.checkLogic(this.model.ErrorLogic)) {
        if (this.errorLogicRouting) {
          this.errorLogicRouting.rules = this.model.ErrorLogic;
        }
        return true;
      }
    }

    return false;
  }

  checkLogic(list: RuleList): boolean {
    if (list.Rules && list.Rules.length > 0
      && list.Rules[0].Rules
      && list.Rules[0].Rules.length > 0
      && list.Rules[0].Rules[0].Condition
      && list.Rules[0].Rules[0].Condition !== "") {
      return true;
    }

    return false;
  }

  displayLogic() {
    this.addLogic("Display ", this._model?.DisplayLogic ?? null);
  }

  deleteDisplayLogic() {
    if (this._model != null) {
      this._model.DisplayLogic = new RuleList();
    }
  }

  errorLogic() {
    if (this._model == null) {
      return;
    }

    let rules = this._model.ErrorLogic;
    if (rules === undefined || rules === null || rules.Rules.length < 1) {
      rules = new RuleList();
      rules.Question = this._model;
      rules.Rules = [];
      rules.Rules.push(new RuleGroup());
      rules.Rules[0].Rules = [];
      rules.Rules[0].Rules.push(new RuleModel());
      rules.Rules[0].Rules[0].RuleType = "error";
    }

    this._model.ErrorLogic = rules;
    this.addLogic("Error Handling ", this._model.ErrorLogic);
  }

  deleteErrorLogic() {
    if (this._model != null) {
      this._model.ErrorLogic = new RuleList();
    }
  }

  carryForwardLogic() {
    if (this._model == null) {
      return;
    }

    let rules = this._model.CarryForward;
    if (rules === undefined || rules === null || rules.Rules.length < 1) {
      rules = new RuleList();
      rules.Question = this._model;
      rules.Rules = [];
      rules.Rules.push(new RuleGroup());
      rules.Rules[0].Rules = [];
      rules.Rules[0].Rules.push(new RuleModel());
      rules.Rules[0].Rules[0].RuleType = "carry";
    }

    this._model.CarryForward = rules;
    this.addLogic("Carry Forward ", this._model.CarryForward);
  }

  deleteCarryForwardLogic() {
    if (this._model == null) {
      return;
    }

    this._model.CarryForward = new RuleList();
  }

  answerChoiceDisplayLogic() {
    if (this._model == null) {
      return;
    }

    let rules = this._model.AnswerChoiceLogic;
    if (rules === undefined || rules === null || rules.Rules.length < 2) {
      rules = new RuleList();
      rules.Question = this._model;
      rules.Rules = [];
      rules.Rules.push(new RuleGroup());
      rules.Rules.push(new RuleGroup());
      rules.Rules[0].Rules = [];
      rules.Rules[0].Rules.push(new RuleModel());
      rules.Rules[1].Rules = [];
      rules.Rules[1].Rules.push(new RuleModel());
      rules.Rules[1].Rules[0].Answers = [];
    }

    this._model.AnswerChoiceLogic = rules;
    this.addLogic("Answer Choice ", this._model.AnswerChoiceLogic);
  }

  deleteAnswerChoiceDisplayLogic() {
    if (this._model == null) {
      return;
    }

    this._model.AnswerChoiceLogic = new RuleList();
  }

  skipLogic() {
    if (this._model == null) {
      return;
    }

    let rules = this._model.SkipLogic;
    if (rules === undefined || rules === null || rules.Rules.length < 1) {
      rules = new RuleList();
      rules.Question = this._model;
      rules.Rules = [];
      rules.Rules.push(new RuleGroup());
      rules.Rules[0].Rules = [];
      rules.Rules[0].Rules.push(new RuleModel());
      rules.Rules[0].Rules[0].RuleType = "skip";
    }

    this._model.SkipLogic = rules;
    this.addLogic("Skip ", this._model.SkipLogic);
  }

  deleteSkipLogic() {
    if (this._model == null) {
      return;
    }

    this._model.SkipLogic = new RuleList();
  }

  addLogic(name: string, rules: RuleList | null) {
    if (rules == null) {
      rules = new RuleList();
    }

    rules.Question = this._model;
    let ruleType = rules.Rules[0].Rules[0].RuleType;
    let showButton = false;
    switch (ruleType.toLowerCase()) {
      case "carry":
      case "error":
      case "display":
        showButton = false;
        break;
      default:
        showButton = true;
        break;
    }

    if (name == "Display ") {
      showButton = false;
    }

    const dialogRef = this.dialog.open(RoutingDialogComponent,
      {
        data: { name: name, rules: rules, showAddRemove: showButton },
        maxHeight: '50%',
        width: '95vw',
        maxWidth: '95vw',
      });
    dialogRef.afterClosed().subscribe(result => {
      rules = result;
    });
  }
}
