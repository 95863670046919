import { Component, Input, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Observable, tap } from 'rxjs';
import { WebHookModel, WebHookService } from '../../webhook.Service';

@Component({
  selector: 'app-webhooks',
  templateUrl: './webhooks.component.html',
  styleUrls: ['./webhooks.component.css']
})
export class WebhooksComponent implements OnInit {

  tempWebHooks: Observable<any>;
  WebHooks: WebHookModel[] = [];
  dataSource = new MatTableDataSource(this.WebHooks);
  displayedColumns: string[] = ['Name', 'TypeList', 'Created', 'Amended', 'Action'];
  actionType: string = "webhook";

  constructor(private webHookService: WebHookService,
    public translate: TranslateService,
    private actRoute: ActivatedRoute,
    private router: Router) {
    this.actionType = this.actRoute.snapshot.params.type ?? "profile";
  }

  ngOnInit(): void {
  }

  newWebHook() {
    this.router.navigate(["/webhook", 0, this.actionType, this.userId]);
  }

  getHookTypeList(list: string) {
    if (list === "") {
      return "";
    }

    return "(" + list + ")";
  }

  @Input()
  get userId(): number {
    return this._userId;
  }
  set userId(value: number) {
    this._userId = value;
    if (this._userId > 0) {
      this.findHooks();
    }
  }
  private _userId: number = -1;


  edit(hook: WebHookModel) {
    this.router.navigate(["/webhook", hook.WebHookId, this.actionType, this.userId]);
  }

  findHooks() {
    this.tempWebHooks = this.webHookService.getWebHooksForAccount(this._userId).pipe(tap<WebHookModel[]>(result => {
      this.WebHooks = result;
      this.dataSource = new MatTableDataSource(this.WebHooks);
    }));
  }

  deleteHook(hook: WebHookModel) {
    this.webHookService.deleteWebHook(hook.WebHookId.toString());
    this.findHooks();
  }
}
