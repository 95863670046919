<div *ngIf="preview && group">
  <div class="column-flex-flow margin-5">
      <div class="row-flex-flow">
        <div><strong>{{group.Name}}</strong></div>&nbsp;
        <div>{{group.Description}}</div>
      </div>
      <div class="row-flex-flow">
        <div class="proc-def-status-div">
          <div><span class="sub-title">({{group.GroupId}})&nbsp;</span>Modified: {{group.Amended | date:'shortDate': '' : translate.currentLang}} {{group.Amended | date:'mediumTime': '' : translate.currentLang}}</div>
        </div>
        <div class="row-flex-flow" style="align-content:end;margin-top:-8px">
          <div class="proc-def-actionBtn-div">
            <button mat-mini-fab color="accent" matTooltip="Edit Group Policy" (click)="clickOnButton(0)"><mat-icon>edit</mat-icon></button>
          </div>
          <div *ngIf="userGroup == null" class="proc-def-actionBtn-div">
            <button mat-mini-fab color="accent" matTooltip="Delete Group Policy" (click)="clickOnButton(1)"><mat-icon>delete</mat-icon></button>
          </div>
      </div>
    </div>
  </div>
</div>
<div *ngIf="!preview">
  <div *ngIf="userGroup == null">
    <button mat-raised-button color="primary" (click)="createPolicy()">New Policy</button>&nbsp;&nbsp;<br/>&nbsp;
  </div>
  <div *ngIf="userGroup != null">
    <div class="row-flex-flow">
      <div><strong>{{group.Name}}</strong></div>&nbsp;
      <div>{{group.Description}}</div>
    </div>
    <div class="row-flex-flow">
      <div class="proc-def-status-div">
        <div><span class="sub-title">({{group.GroupId}})&nbsp;</span>Modified: {{group.Amended | date:'shortDate': '' : translate.currentLang}} {{group.Amended | date:'mediumTime': '' : translate.currentLang}}</div>
      </div>
      <div class="row-flex-flow" style="align-content:end;margin-top:-8px">
        <div class="proc-def-actionBtn-div">
          <button mat-mini-fab color="accent" matTooltip="Close Group Policy" (click)="clickOnButton(2)"><mat-icon>close</mat-icon></button>
        </div>
      </div>
    </div>
  </div>
  <mat-divider></mat-divider>
  <div *ngIf="group && policies?.length > 0">
    <div *ngFor="let policy of policies; let i = index">
      <app-policy-detail [preview]="previews[i]" [userGroup]="userGroup" [defaultPolicy]="defaultPolicies[i]" [policy]="policy" (onClick)="policyClick($event)"></app-policy-detail>
    </div>
  </div>
  <mat-divider></mat-divider>
</div>
