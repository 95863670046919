<div [formGroup]="blockFormGroup">
  <div formArrayName="blockFormArray">
    <div *ngFor="let opt of questionArr?.controls; let i = index" [formGroupName]="i">
      <app-add-question-button action="before" [isBlock]="true" (doAction)="questionAction($event)"></app-add-question-button>
      <app-advanced-properties [model]="model"></app-advanced-properties>
      <div class="fullWidth">
        <mat-form-field appearance="outline">
          <mat-label>Question Identity</mat-label>
          <input matInput formControlName="qNumber" [patterns]="customPatterns" mask="SA*">
        </mat-form-field>
      </div>
      <div id="blockContentInside" #blockContent></div>
      <div matRipple class="qe-block-addQnBtn-div" (click)="click()">
        <span class="txt-align-center">Add questions to this block by selecting from the list below</span>
        <mat-form-field appearance="outline">
          <mat-label>Add Question</mat-label>
          <mat-select>
            <mat-option>None</mat-option>
            <mat-option (click)="addQuestion('information')"><mat-icon>info</mat-icon>Survey Introduction</mat-option>
            <mat-option (click)="addQuestion('textbox')"><mat-icon>text_fields</mat-icon>Free Text</mat-option>
            <mat-option (click)="addQuestion('email')"><mat-icon>email</mat-icon>Email Address</mat-option>
            <mat-option (click)="addQuestion('mcma')"><mat-icon>check_box</mat-icon>Multiple Answer</mat-option>
            <mat-option (click)="addQuestion('mcsa')"><mat-icon>radio_button_checked</mat-icon>Single Answer</mat-option>
            <mat-option (click)="addQuestion('matrix')"><mat-icon>apps</mat-icon>Matrix</mat-option>
            <mat-option (click)="addQuestion('rating')"><mat-icon>star_rate</mat-icon>Rating</mat-option>
            <mat-option (click)="addQuestion('ranking')"><mat-icon>reorder</mat-icon>Rank Order</mat-option>
            <mat-option (click)="addQuestion('currency')"><mat-icon>money</mat-icon>Currency</mat-option>
            <mat-option (click)="addQuestion('integer')"><mat-icon>looks_one</mat-icon>Number</mat-option>
            <mat-option (click)="addQuestion('boolean')"><mat-icon>thumbs_up_down</mat-icon>Yes or No</mat-option>
            <mat-option (click)="addQuestion('date')"><mat-icon>date_range</mat-icon>Date &amp; Time</mat-option>
            <mat-option (click)="addQuestion('bucket')"><mat-icon>group_work</mat-icon>Grouping (Card sort)</mat-option>
            <mat-option (click)="addQuestion('imageMap')"><mat-icon>images</mat-icon>Image Map</mat-option>
            <mat-option (click)="addQuestion('media')"><mat-icon>camera</mat-icon>Media</mat-option>
            <mat-option (click)="addQuestion('geocode')"><mat-icon>location_on</mat-icon>Location</mat-option>
            <mat-option (click)="addQuestion('barcode')"><mat-icon>qr_code_scanner</mat-icon>Barcode</mat-option>
            <mat-option (click)="addQuestion('sendSMS')"><mat-icon>sms</mat-icon>Send SMS</mat-option>
            <mat-option (click)="addQuestion('sendEmail')"><mat-icon>send</mat-icon>Send Email</mat-option>
            <mat-option (click)="addQuestion('userlist')"><mat-icon>list</mat-icon>User List</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <app-add-question-button action="after" [isBlock]="true" (doAction)="questionAction($event)"></app-add-question-button>
    </div>
  </div>
</div>
