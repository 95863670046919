import { Component, OnInit, Inject, Input } from '@angular/core';
import { DialogService } from '../shared/dialog.service';
import { MatDialog } from '@angular/material/dialog';
import { InterviewService, QuestionModel, LibraryQuestionModel } from '../question.service';
import { plainToClass } from 'class-transformer';
import { QuestionEditorComponent } from '../question-editor/question-editor.component';
import { Subscription } from 'rxjs';
import { PositionStrategy, ScrollStrategy } from '@angular/cdk/overlay';

@Component({
  selector: 'app-question-library',
  templateUrl: './question-library.component.html',
  styleUrls: ['./question-library.component.css']
})
export class QuestionLibraryComponent implements OnInit {

  libraryQuestions: LibraryQuestionModel[] = [];
  scriptQuestions: QuestionModel[] = [];
  model?: QuestionModel
  clickEventSubscription?: Subscription;
  isOpen = false;
  scrollStrategy?: ScrollStrategy;
  positionStrategy?: PositionStrategy;

  constructor(private interviewService: InterviewService, private dialog: MatDialog, private dialogService: DialogService) {
  }

  ngOnInit(): void {
    this.interviewService.getLibrary().subscribe(result => {
      this.libraryQuestions = result;
      this.scriptQuestions = [];
      this.libraryQuestions.forEach(question => {
        const model = plainToClass(QuestionModel, JSON.parse(question.QuestionXML))
        this.scriptQuestions.push(model);
      });
    });
  }

  addQuestionFromScript(parentIndex: number, index: number) {
    if (this.editor == null) {
      return;
    }

    const question = this.editor.interview.findQuestionByIndex(parentIndex, index);
    this.interviewService.addToLibrary(question).subscribe(result => {
      this.libraryQuestions.push(result);
      this.scriptQuestions.push(question);
    });
  }

  addQuestion(index: number) {
    if (this.editor == null) {
      return;
    }

    const question = plainToClass(QuestionModel, JSON.parse(this.libraryQuestions[index].QuestionXML));
    question.checkAllModel();
    question.Interview = this.editor.interview;
    question.Index = this.editor.interview.Questions.length;
    question.ParentIndex = -1;
    this.editor.interview.Questions.push(question);
    this.editor.interview.reIndex(this.editor.interview.Questions[0].ParentIndex, this.editor.interview.Questions[0].DisplayParentIndex, this.editor.interview.Questions);
    if (this.editor.questions != undefined) {
      let data = this.editor.questions.addNewComponent({ action: 'after', index: question.Index, parentIndex: question.ParentIndex, questionType: question.QuestionType }, question);
      if (question.QuestionType == 'block') {
        for (let j = 0; j < question.Questions.List.length; j++) {
          let subQuestion = question.Questions.List[j];
          subQuestion.Interview = this.editor.interview;
          this.editor.questions.addNewComponent({
            questionType: subQuestion.QuestionType,
            populate: true,
            action: "after",
            index: subQuestion.Index,
            displayIndex: subQuestion.DisplayIndex,
            parentIndex: subQuestion.ParentIndex,
            displayParentIndex: subQuestion.DisplayParentIndex
          }, subQuestion);
        }
      }

      if (this.editor.sorter) {
        this.editor.sorter.refresh();
      }

      this.editor.questions.selectQuestion(data.index, data.parentIndex, data.displayIndex, data.displayParentIndex);
    }
  }

  deleteQuestion(index: number) {
    this.dialogService.openConfirmDialog('Are you sure to delete this ?')
      .afterClosed().subscribe(res => {
        if (res) {
          this.interviewService.deleteFromLibrary(this.libraryQuestions[index].QuestionLibraryID).subscribe();
          this.libraryQuestions.splice(index, 1);
          this.scriptQuestions.splice(index, 1);
        }
      });
  }

  @Input()
  get editor(): QuestionEditorComponent | null {
    return this._editor;
  }
  set editor(value: QuestionEditorComponent | null) {
    this._editor = value;
  }
  private _editor: QuestionEditorComponent | null = null;

}
