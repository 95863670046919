<div [formGroup]="formGroup">
  <app-sms-editor *ngIf="channel == 'sms' || channel == 'whatsapp'" (onChange)="smsChanged($event)" [disabled]="disabled" [smart]="smart" [questions]="questions" [text]="smsText" [channel]="channel" (onSmart)="clickSmart($event)"></app-sms-editor>
  <quill-editor #editor *ngIf="channel == 'web'" class="fullWidth" formControlName="value" [modules]="quillModules" [customModules]="customModules">
    <div quill-editor-toolbar>
      <span class="ql-formats">
        <select class="ql-size">
          <option value="small"></option>
          <option selected></option>
          <option value="large"></option>
          <option value="huge"></option>
        </select>
      </span>
      <span class="ql-formats">
        <button class="ql-bold"></button>
        <button class="ql-italic"></button>
        <button class="ql-underline"></button>
        <button class="ql-strike"></button>
      </span>
      <span class="ql-formats">
        <select class="ql-color"></select>
        <select class="ql-background"></select>
      </span>
      <span class="ql-formats">
        <button class="ql-list" value="ordered"></button>
        <button class="ql-list" value="bullet"></button>
        <select class="ql-align">
          <option selected></option>
          <option value="center"></option>
          <option value="right"></option>
          <option value="justify"></option>
        </select>
      </span>
      <span class="ql-formats">
        <button class="ql-link"></button>
        <button class="ql-image"></button>
        <button class="ql-code"></button>
      </span>
      <span class="ql-formats">
        <app-field-selector *ngIf="questions && questions.length > 0" [showButton]="true" [questions]="questions" (selectField)="selectField($event)"></app-field-selector>
      </span>
    </div>
</quill-editor>
</div>
