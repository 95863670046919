import { Component, OnInit, ViewChild } from '@angular/core';
import { MatStepper } from '@angular/material/stepper';
import { SurveyModel, SurveyService } from '../survey.Service';
import { InterviewModel, InterviewService, QuestionModel } from '../question.service';
import { ActivatedRoute } from '@angular/router';
import { plainToClass } from 'class-transformer';
import { UserFeatures, UserService } from '../user.Service';

@Component({
  selector: 'app-survey-editor',
  templateUrl: './survey-editor.component.html',
  styleUrls: ['./survey-editor.component.css']
})
export class SurveyEditorComponent implements OnInit {

  basicEditor: boolean = false;
  hasScript: boolean = false;
  displayQuestionEditor: boolean = true;
  surveyId: number;
  survey: SurveyModel;
  interview: InterviewModel;
  modelChecked: boolean = false;
  questionTexts: QuestionModel[] = [];
  userFeatures: UserFeatures;

  @ViewChild('stepper') public stepper: MatStepper | undefined;

  constructor(
    private userService: UserService,
    private actRoute: ActivatedRoute,
    private surveyService: SurveyService,
    private interviewService: InterviewService) {

    this.surveyId = this.actRoute.snapshot.params.id;
    this.userService.getUserFeatures().subscribe(result => {
      this.userFeatures = result;
      this.basicEditor = !this.userFeatures.GeneralFeatures.ProfessionalEditor ?? false;
    });
    this.surveyService.getSurveyDetail(this.surveyId).subscribe(result => {
      this.survey = result;
      this.interviewService.getInterview(this.survey.ScriptID).subscribe(result => {
        if (result === null) {
          this.survey.QuestionCount = 0;
          this.interview = new InterviewModel();
          this.modelChecked = true;
        }
        else {
          this.interview = plainToClass(InterviewModel, result);
          this.interview.checkModel();
          this.questionTexts = this.interview.textQuestions();
          this.modelChecked = true;
        }
      });
    });
  }

  ngOnInit(): void {
  }

  moveToNextStep() {
    this.stepper.next();
  }

  showSmsInsights(): boolean {
    return this.survey && (this.survey.Channel >= 1 && this.survey.Channel <= 5);
  }

  showDataAnalysis(): boolean {
    return this.survey && (this.survey.Channel == 0 || this.survey.Channel == 1 || this.survey.Channel == 2 || this.survey.Channel == 5);
  }



}
