import { Component, OnInit, Input, ViewChild, ComponentRef, ViewRef, ComponentFactoryResolver, ViewContainerRef, EventEmitter, Output, OnDestroy, AfterContentInit } from '@angular/core';
import { AfterViewInit } from '@angular/core';

import { NumberComponent } from '../number/number.component';
import { TextComponent } from '../text/text.component';
import { CategoricalComponent } from '../categorical/categorical.component';
import { DateComponent } from '../date/date.component';
import { FloatComponent } from '../float/float.component';
import { BooleanComponent } from '../boolean/boolean.component';
import { PageComponent } from '../page/page.component';
import { BlockComponent } from '../block/block.component';
import { LoopCategoricalComponent } from '../loop-categorical/loop-categorical.component';
import { LoopNumericComponent } from '../loop-numeric/loop-numeric.component';
import { GeocodeComponent } from '../geocode/geocode.component';
import { MediaUploadComponent } from '../media-upload/media-upload.component';
import { RankingComponent } from '../ranking/ranking.component';
import { AnswerModel } from "../../models/AnswerModel";
import { TerminateComponent } from '../terminate/terminate.component';
import { MaxDiffComponent } from '../maxdiff/maxdiff.component';
import { BucketComponent } from '../bucket/bucket.component';
import { fromEvent, Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'diy-data',
  templateUrl: './data.component.html',
  styleUrls: ['./data.component.css']
})
export class DataComponent implements OnInit, AfterViewInit, OnDestroy {
  banners: any;
  errors: any;
  label: any;
  display = false;

  private isDifferent = true;
  private initPassThru = false;

  components: ComponentRef<any>[] = [];
  @ViewChild('controls', { static: false, read: ViewContainerRef }) controls: ViewContainerRef | undefined;

  constructor(private resolver: ComponentFactoryResolver) {
    this._displayLabel = true;
  }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    this.configureControls();
  }

  ngOnDestroy() {
    if (this.components.length > 0) {
      if (this.components[0].instance.change) {
        this.components[0].instance.change.unsubscribe();
      }
    }
  }

  @Input()
  get playerWidth(): number {
    return this._playerWidth;
  }
  set playerWidth(value: number) {
    this._playerWidth = value;
    if (this.components.length > 0) {
      if (this.components[0].instance.playerWidth) {
        this.components[0].instance.playerWidth = value;
      }
    }
  }
  private _playerWidth: number = 0;

  @Input()
  get readonly(): boolean {
    return this._readonly;
  }
  set readonly(value: boolean) {
    this._readonly = value;
  }
  private _readonly: boolean = false;

  @Input()
  get color(): string {
    return this._color;
  }
  set color(value: string) {
    this._color = value;
  }
  private _color: string = "";

  @Input()
  get show(): boolean {
    return this._show;
  }
  set show(value) {
    this._show = value;
  }
  private _show = false;

  @Input()
  get displayLabel(): boolean {
    return this._displayLabel;
  }
  set displayLabel(value: boolean) {
    this._displayLabel = value;
    this.initPassThru = false;
  }
  private _displayLabel: boolean = true;

  @Input()
  get preview(): boolean {
    return this._preview;
  }
  set preview(value: boolean) {
    this._preview = value;
  }
  private _preview: boolean = false;

  @Input()
  get question(): any {
    return this._question;
  }
  set question(value: any) {
    if (this._question === undefined || this._question === null) {
      this.isDifferent = true;
    }
    else {
      if (value === undefined || value === null) {
        this.isDifferent = true;
      }
      else {
        if (DataComponent.getComponentType(this._question) != DataComponent.getComponentType(value)) {
          this.isDifferent = true;
        }
        else {
          this.isDifferent = false;
        }
      }
    }

    this._question = value;
    if (this._question === undefined || this._question === null) {
      return;
    }

    this.banners = this._question.Banners;
    this.errors = this._question.Errors;
    this.label = this._question.Label;
    this.configureControls();
    this.initPassThru = false;
  }
  private _question: any;

  get answers(): AnswerModel[] {
    const answers: AnswerModel[] = [];
    if (this.controls != undefined) {
      for (let i = 0; i < this.controls.length; i++) {
        const component = this.components[i].instance;
        if (component.answers !== undefined) {
          component.answers.forEach((answer: AnswerModel)  => { answers.push(answer); });
        }
      }
    }

    return answers;
  }
  set answers(value: any) {
    if (this.controls != undefined) {
      for (let i = 0; i < this.controls.length; i++) {
        const component = this.components[i].instance;
        if (component.answers !== undefined) {
          component.answers = "dummy value";
        }
      }
    }
  }

  get valid(): boolean {
    let v = true;
    if (this.controls != undefined) {
      for (let i = 0; i < this.controls.length; i++) {
        if (!this.components[i].instance.valid) {
          v = false;
        }
      }
    }

    return v;
  }

  @Output() change = new EventEmitter();

  onChange() {
    this.change.emit();
  }

  private configureControls() {
    Promise.resolve(null).then(() => {
    this.display = false;
    let mobileaware = false;
    if (this._question === undefined || this._question === null) {
      return;
    }

    if (this.controls === undefined) {
      return;
    }

    if (this.isDifferent || !this.preview || this.controls.length === 0) {
      this.controls.clear();
      this.components = [];
      let childComponent: any | null = null;
      let componentType = DataComponent.getComponentType(this._question);
      switch (componentType) {
        case 1:
          childComponent = this.resolver.resolveComponentFactory(RankingComponent);
          break;
        case 2:
          childComponent = this.resolver.resolveComponentFactory(LoopCategoricalComponent);
          mobileaware = true;
          break;
        case 3:
          childComponent = this.resolver.resolveComponentFactory(LoopNumericComponent);
          break;
        case 4:
          childComponent = this.resolver.resolveComponentFactory(BlockComponent);
          mobileaware = true;
          this._displayLabel = false;
          break;
        case 5:
          childComponent = this.resolver.resolveComponentFactory(PageComponent);
          break;
        case 6:
          childComponent = this.resolver.resolveComponentFactory(NumberComponent);
          break;
        case 7:
          childComponent = this.resolver.resolveComponentFactory(GeocodeComponent);
          break;
        case 8:
          childComponent = this.resolver.resolveComponentFactory(TextComponent);
          break;
        case 9:
          childComponent = this.resolver.resolveComponentFactory(CategoricalComponent);
          break;
        case 10:
          childComponent = this.resolver.resolveComponentFactory(DateComponent);
          break;
        case 11:
          childComponent = this.resolver.resolveComponentFactory(FloatComponent);
          break;
        case 12:
          childComponent = this.resolver.resolveComponentFactory(BooleanComponent);
          break;
        case 13:
          childComponent = this.resolver.resolveComponentFactory(MediaUploadComponent);
          break;
        case 14:
          if (this._preview !== undefined) {
            if (!this._preview) {
              childComponent = this.resolver.resolveComponentFactory(TerminateComponent);
            }
          }
          else {
            childComponent = this.resolver.resolveComponentFactory(TerminateComponent);
          }
          break;
        case 15:
          childComponent = this.resolver.resolveComponentFactory(MaxDiffComponent);
          break;
        case 16:
          childComponent = this.resolver.resolveComponentFactory(BucketComponent);
          mobileaware = true;
          break;
      }
      if (childComponent !== null) {
        const componentEnd = this.controls.createComponent(childComponent) as ComponentRef<any>;
        this.components.push(componentEnd);
      }

    }

    if (this.components.length > 0) {
      if (this.components[0].instance.change) {
        this.components[0].instance.change.subscribe(() => this.onChange());
      }

      this.components[0].instance.preview = this._preview;
      this.components[0].instance.question = this._question;
      if (mobileaware) {
        this.components[0].instance.playerWidth = this.playerWidth;
      }
    }
      this.display = true;
    });
  }

  public static getComponentType(question: any): number {
    switch (question.QuestionDataType) {
      case 0:
        switch (question.QuestionType) {
          case 0:
            // Informational
            if (question.Style.Control.Type === 0x100D) {
              // Send Email
              return -1
            }

            if (question.Style.Control.Type === 0x100E) {
              // Terminate Survey
              return 14;
            }
            return 0;
          case 1:
            // Loop Categorical
            if (question.Style.Control.Type === 0x1006) {
              // Ranking
              return 1;
            }

            if (question.Style.Control.Type === 0x100A) {
              // MaxDiff
              return 15;
            }

            if (question.Style.Control.Type === 0x100C) {
              // Bucket
              return 16;
            }

            // Loop Categorical
            return 2;
          case 2:
            // Loop Numeric
            return 3;
          case 3:
            // Compound
            return -1;
          case 4:
            // Block
            return 4;
          case 5:
            // Page
            return 5;
          case 6:
            // Derived
            return -1;
        }

        return -1;
      case 1:
        // Integer
        return 6;
      case 2:
        // String
        if (question.Style.Control.Type === 0x1003) {
          // GeoCode
          return 7;
        }
        else {
          // Text
          return 8;
        }
      case 3:
        // Categorical
        return 9;
      case 4:
        // Object
        return -1;
      case 5:
        // Date
        return 10;
      case 6:
        // Float
        return 11;
      case 7:
        // Boolean
        return 12;
      case 8:
        // Level
        return -1;
      case 0x1001:
        // Media
        return 13;
      default:
        // Unknown
        return -1;
    }
  }
}
