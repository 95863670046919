import { options, baseUrl } from "../environments/environment";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";

@Injectable()
export class ScriptService {

  constructor(private client: HttpClient) {}

  getScripts() {
    return this.client.get<ScriptModel[]>(baseUrl + 'Questionnaire/GetQuestionnaire', options);
  }

  getScript(id: string) {
    return this.client.get<ScriptModel>(baseUrl + 'Questionnaire/GetScript?id=' + id, options);
  }
}

export class ScriptModel {
  ScriptID: number = 0;
  UserID: number = 0;
  Name: string = "";
  Description: string = "";
  IsDeleted: boolean = false;
  CreatedDate: Date = new Date();
  LastUpdatedDate: Date = new Date();
  Istoolkit: boolean = false;
}

export class Question {
  Identity: string = "";
  Label: string[] = [];
  QuestionType: number = 0;
  SuitableForSms: boolean = false;
  Mandatory: boolean = false;
  PageBreak: boolean = false;
  InitialValue: string = "";
  DefaultValue: string = "";
  Options: Option[] = [];
}

export class Option {
  Identity: string = "";
  Label: string[] = [];
  Fix: boolean = false;
  Factory: string = "";
}
