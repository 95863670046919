<div class="fullWidth dialog-title">
  <div class="leftFloat">
    <h3>Find User</h3>
  </div>
  <div class="rightFloat">
    <button mat-icon-button matDialogClose="no" matTooltip="Close" matTooltipPosition="below">
      <mat-icon class="dialog-close-icon">close</mat-icon>
    </button>
  </div>
</div>
<div class="clearBoth mt-5"></div>
<div class="find-user-main-div align-center-center-flex fill-flex">
  <mat-card class="fxFlex-100" fxFlex.xs="100" fxFlex.sm="40">
    <form class="padding-0 flex-stretch-column" [formGroup]="formGroup">
      <div>
        <div class="fullWidth display-flex">
          <div class="leftFloat">
            <mat-form-field appearance="outline" class="fullWidth">
              <mat-label>find User</mat-label>
              <input matInput placeholder="Find User" formControlName="search">
            </mat-form-field>
          </div>&nbsp;&nbsp;
          <div class="pt-5 leftFloat">
            <button mat-raised-button color="primary" (click)="findUser($event)">Find</button>
          </div>
        </div>
      </div>
      <div class="or-separator">
      </div>
      <div *ngIf="tempUsers | async">
        <mat-selection-list #users [multiple]="false" class="custom-scroll-bar">
          <mat-list-option *ngFor="let user of list; let i = index" (click)="selectUser($event, i)">
            {{user}}
          </mat-list-option>
        </mat-selection-list>
      </div>
    </form>
  </mat-card>
</div>
