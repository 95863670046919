<ngx-charts-pie-chart [animations]="false"
                          [view]="view"
                      [scheme]="colorSets"
                      [results]="results"
                      [gradient]="gradient"
                      [legend]="showLegend"
                      [legendPosition]="legendPosition"
                      [labels]="showLabels"
                      [trimLabels] ="true"
                      [maxLabelLength] = 20
                      [doughnut]="isDoughnut"
                      (select)="onSelect($event)"
                      (activate)="onActivate($event)"
                      (deactivate)="onDeactivate($event)">
</ngx-charts-pie-chart>
