<div class="fullWidth dialog-title">
  <div class="leftFloat">
    <h3>Add a Question</h3>
  </div>
  <div class="rightFloat">
    <button mat-icon-button matDialogClose="no" matTooltip="Close" matTooltipPosition="below">
      <mat-icon class="dialog-close-icon">close</mat-icon>
    </button>
  </div>
</div>
<div class="clearBoth margin-5 padding-lr-10">
  Select a Ready to Use Question
</div>
<div [formGroup]="formGroup">
  <div class="mb-10 padding-lr-10">
    <mat-divider></mat-divider>
  </div>
  <div class="fieldRow mb-5 padding-8">
    <div>
      <mat-form-field appearance="outline">
        <mat-label>Question Categories</mat-label>
        <mat-select (selectionChange)="onCategoryChange($event)" formControlName="category">
          <mat-option *ngFor="let category of categories; let i = index;"
                      [value]="category.name">{{category.description}}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="ml-5">
      <mat-button-toggle-group formControlName="layout" (change)="layoutChange($event)">
        <mat-button-toggle value="detail"><mat-icon>grid_view</mat-icon></mat-button-toggle>
        <mat-button-toggle value="list"><mat-icon>list</mat-icon></mat-button-toggle>
      </mat-button-toggle-group>
    </div>
  </div>
  <div *ngIf="layout == 'list' && category == 'all'">
    <div class="fieldRow" *ngFor="let question of getQuestionForAllCategories(); let i = index">
      <div class="halfWidth">
        <button mat-flat-button [matTooltip]="question[0].description" (click)="addNewQuestion(question[0])">
          <mat-icon>{{question[0].icon}}</mat-icon>
          {{question[0].title}}
        </button>
      </div>
      <div class="halfWidth" *ngIf="question.length > 1">
        <button mat-flat-button [matTooltip]="question[1].description" (click)="addNewQuestion(question[1])">
          <mat-icon>{{question[1].icon}}</mat-icon>
          {{question[1].title}}
        </button>
      </div>
    </div>
  </div>
  <div *ngIf="layout == 'list' && category != 'all' && category != 'library'">
    <div *ngFor="let question of getQuestionForCategory(category); let i = index">
      <button mat-flat-button [matTooltip]="question.description" (click)="addNewQuestion(question)">
        <mat-icon>{{question.icon}}</mat-icon>
        {{ question.title }}
      </button>
    </div>
  </div>
  <div *ngIf="layout == 'list' && category == 'library'" class="custom-scroll-bar add-qn-library-list-container">
    <div *ngFor="let question of fromLibrary(); let i = index" class="add-qn-library-list-content">
      <a (click)="addNewQuestion(question)">
        <mat-card class="padding-5">
          <div class="randomise-embed-option-div">
            <mat-icon>{{question.icon}}</mat-icon>
            <div class="add-qn-list-questionTitle-div">{{question.title}}</div>
          </div>
          <div #listContent class="add-qn-list-container" [class.add-qn-show]="question.visible">
            <p class="margin-0">
              {{category == 'library' ? question.model.FormatBannerForSms : question.description}}
            </p>
          </div>
          <button *ngIf="((listContent.offsetHeight + 2) < listContent.scrollHeight) && !question.visible" mat-button (click)="question.visible = true; $event.stopPropagation()">
            Show More
          </button>
          <button *ngIf="(listContent.offsetHeight >= listContent.scrollHeight) && question.visible" mat-button (click)="question.visible = false; $event.stopPropagation()">
            Show Less
          </button>
        </mat-card>
      </a>
    </div>
  </div>
  <div *ngIf="layout == 'detail'" mat-dialog-content class="custom-scroll-bar">
    <div class="cardRows">
      <div class="add-qn-question-div" *ngFor="let question of category == 'library' ? fromLibrary() : getQuestionForCategory(category); let i = index">
        <a (click)="addNewQuestion(question)">
          <mat-card *ngIf="category == 'library'">
            <div class="add-qn-detail-questionTitle-div">
              <mat-card-title class="add-qn-detail-question-title">{{question.title}}</mat-card-title>
              <mat-icon>{{question.icon}}</mat-icon>
            </div>
            <mat-card-content #content class="add-qn-grid-container" [class.add-qn-show]="question.visible">
              <p>
                {{category == 'library' ? question.model.FormatBannerForSms : question.description}}
              </p>
            </mat-card-content>
            <button *ngIf="((content.offsetHeight + 2) < content.scrollHeight) && !question.visible" mat-button (click)="question.visible = true; $event.stopPropagation()">
              Show More
            </button>
            <button *ngIf="(content.offsetHeight >= content.scrollHeight) && question.visible" mat-button (click)="question.visible = false; $event.stopPropagation()">
              Show Less
            </button>
          </mat-card>
          <mat-card class="add-qn-question-card" *ngIf="category !== 'library'">
            <div class="add-qn-detail-questionTitle-div">
              <mat-card-title class="add-qn-detail-question-title">{{question.title}}</mat-card-title>
              <mat-icon>{{question.icon}}</mat-icon>
            </div>
            <mat-card-content class="txt-align-left">
              <p>
                {{category == 'library' ? question.model.FormatBannerForSms : question.description}}
              </p>
            </mat-card-content>
          </mat-card>
        </a>
      </div>
    </div>
  </div>
</div>
