<h3 *ngIf="isAdminView">Admin access to all organizations</h3>
<div *ngIf="tempOrgs | async; else loading" [class]="displayTitle ? 'mat-elevation-z1' : 'outer-table-container'">
  <div *ngIf="organizations.length == 0">You are not currently a member of any organizations</div>
  <div *ngIf="organizations.length > 0">
    <table mat-table #table [dataSource]="dataSource"
           multiTemplateDataRows
           matSort
           matSortActive="created"
           matSortDisableClear matSortDirection="desc">

      <!-- Id Column -->
      <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Org(Owner - Member) </th>
        <td mat-cell *matCellDef="let organization"> {{organization.OrganizationId}}({{organization.OwnerId}} - {{organization.MemberId}}) </td>
      </ng-container>

      <!-- Name Column -->
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
        <td mat-cell *matCellDef="let organization"> {{organization.Name}} </td>
      </ng-container>

      <!-- Created Column -->
      <ng-container matColumnDef="created">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Created </th>
        <td mat-cell *matCellDef="let organization"> {{organization.Created | date:'short': '' : translate.currentLang}} </td>
      </ng-container>

      <!-- Respondent count Column -->
      <ng-container matColumnDef="description">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Description </th>
        <td mat-cell *matCellDef="let organization"> {{organization.Description}} </td>
      </ng-container>

      <!-- Owner Element Column -->
      <ng-container matColumnDef="owner">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Owner </th>
        <td mat-cell *matCellDef="let organization"> {{owner(organization)}} </td>
      </ng-container>

      <!-- Role Element Column -->
      <ng-container matColumnDef="role">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Role </th>
        <td mat-cell *matCellDef="let organization"> {{role(organization)}} </td>
      </ng-container>

      <!-- Action column -->
      <ng-container matColumnDef="action">
        <th mat-header-cell *matHeaderCellDef mat-sort-header></th>
        <td mat-cell *matCellDef="let organization">
          <div>
            <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="icon-button with a menu" (click)="$event.stopPropagation()">
              <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu #menu="matMenu">
              <button *ngIf="isAdminView" mat-menu-item [routerLink]='["/edituser", organization.OwnerId, "user" ]'>
                <mat-icon>edit</mat-icon>
                <span>Edit</span>
              </button>
              <button *ngIf="isOwner(organization)" mat-menu-item (click)="deleteOrganization(organization.OrganizationId)">
                <mat-icon>delete</mat-icon>
                <span>Delete</span>
              </button>
              <button *ngIf="role(organization) != 'Snubbed' && !isOwner(organization)" mat-menu-item (click)="leave(organization)">
                <mat-icon>person_remove</mat-icon>
                <span>Leave</span>
              </button>
            </mat-menu>
          </div>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr mat-row *matRowDef="let organization; columns: displayedColumns;"
          class="txt-analysis-element-row"
          (click)="onClick(organization)">
      </tr>
    </table>
  </div>
</div>
<ng-template #loading>
  <div class="loading-container">
    <span class="loading-content">
      <div class="spinner-div"><mat-spinner [diameter]="25"></mat-spinner></div>
      <div class="spinner-text"> Please Wait...</div>
    </span>
  </div>
</ng-template>

