import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormArray, FormGroup, Validators } from '@angular/forms';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { BaseQuestion, DataModel, InterviewService, QuestionService } from '../../question.service';

@Component({
  selector: 'app-mcma',
  templateUrl: './mcma.component.html',
  styleUrls: ['./mcma.component.css']
})
export class McmaComponent extends BaseQuestion implements OnInit {
  constructor(private breakpointObserver: BreakpointObserver, private qs: QuestionService, private is: InterviewService) {
    super(qs, is, undefined, undefined, new DataModel("mcma"));
  }

  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
    .pipe(map(result => result.matches));


  public mcmaFormGroup: FormGroup = new FormGroup({});

  ngOnInit() {
    this.mcmaFormGroup = this.fb.group({
      mcmaFormArray: this.fb.array([this.initQuestion()])
    });
  }

  onSubmit(): void {
  }

  get questionArr(): FormArray | null {
    if (this.mcmaFormGroup) {
      return this.mcmaFormGroup.get('mcmaFormArray') as FormArray;
    }

    return null;
  }
}
