import { Component, OnInit } from '@angular/core';
import { BaseChart } from '../../question.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-table-chart',
  templateUrl: './table-chart.component.html',
  styleUrls: ['./table-chart.component.css']
})
export class TableChartComponent extends BaseChart implements OnInit {

  displayedColumns: string[] = ['LastAnswered', 'Response'];

  constructor(public translate: TranslateService) {
    super();
  }

  ngOnInit(): void {
  }

}
