import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { BasicData, CaseDataService, ChartData } from '../../casedata.Service';
import { WordCloudChartComponent } from '../../charts/word-cloud-chart/word-cloud-chart.component';
import { InterviewPreviewComponent } from '../../interview-preview/interview-preview.component';
import { QuestionModel } from '../../question.service';
import { DataAnalysisComponent } from '../data-analysis.component';
import { ReviewDataComponent } from '../review-data/review-data.component';

@Component({
  selector: 'app-text-analysis',
  templateUrl: './text-analysis.component.html',
  styleUrls: ['./text-analysis.component.css']
})
export class TextAnalysisComponent implements OnInit {

  questions: QuestionModel[] = [];
  selectedQuestion: QuestionModel = null;
  formGroup: FormGroup = new FormGroup({});
  data: BasicData[][] = [];
  size: number = 20;
  columnsToDisplay = ['Response', 'action'];

  cloudData: ChartData[] = [];
  cloudTable: ChartData[] = [];
  colorScheme = {
    domain: ['#5AA454', '#C7B42C', '#AAAAAA']
  };
  isReady: boolean = false;


  @ViewChild(MatPaginator) paginator: MatPaginator | undefined;
  @ViewChild("wordCloud") wordCloud: WordCloudChartComponent | undefined;

  constructor(private caseService: CaseDataService,
    public dialog: MatDialog) { }

  ngOnInit(): void {
    this.formGroup = new FormGroup({
      AnalysisType: new FormControl("Search", [Validators.required]),
      Search: new FormControl("")
    });
  }

  @Input()
  get analysis(): DataAnalysisComponent | undefined {
    return this._analysis
  }
  set analysis(value: DataAnalysisComponent | undefined) {
    this._analysis = value;
  }
  private _analysis: DataAnalysisComponent | undefined;

  get Questions() {
    if (this.questions.length > 0) {
      return this.questions;
    }

    if (this.analysis && this.analysis.modelChecked) {
      this.questions = this.analysis.interview.textQuestions();
      this.selectedQuestion = this.questions.length == 0 ? new QuestionModel() : this.questions[0];
    }

    return this.questions;
  }

  analysisType(name: string): boolean {
    return this.formGroup.controls.AnalysisType.value == name;
  }

  search() {
    if (this.analysis == undefined) {
      return;
    }

    let keywords = this.formGroup.controls.Search.value.split(',')
    this.caseService.getTextResults(this.analysis.id, this.selectedQuestion.QuestionName, keywords).subscribe(
      response => {
        this.data = response;
      });
  }

  setupWordCloud() {
    if (this.analysis == undefined) {
      return;
    }

    this.caseService.getWordCloud(this.analysis.id, this.selectedQuestion.QuestionName, false).subscribe(
      results => {
        this.cloudData = results;
        this.caseService.getTableData(this.analysis?.id ?? 0, this.selectedQuestion.QuestionName, false).subscribe(
          results => {
            this.cloudTable = results;
            if (this.wordCloud != undefined) {
              this.wordCloud.words = this.cloudData;
              this.wordCloud.scheme = this.colorScheme;
              this.isReady = true;
            }
          });
      }
    );
  }

  changeQuestion(index: number) {
    this.selectedQuestion = this.questions[index];
    if (this.analysisType("Keyword")) {
      this.setupWordCloud();
    }
    else {
      this.data = [];
    }
  }

  change(event: any) {
    if (event.value == "Keyword") {
      this.setupWordCloud();
    }
  }

  viewInterview(element: BasicData[]) {
    let model = this.analysis.interview;
    const dialogRef = this.dialog.open(ReviewDataComponent, {
      data: element,
      width: '80%'
    });
  }

  replayInterview(element: BasicData[]) {
    let model = this.analysis.interview;
    let id = element[0].PanellistReferenceId;
    const dialogRef = this.dialog.open(InterviewPreviewComponent, {
      data: { model: model, isReview: true, id: id },
      width: '80%'
    });
  }

  getColumnByName(element: BasicData[], name: string): BasicData {
    for (let i = 0; i < element.length; i++) {
      if (element[i].CommonName == name.toLowerCase()) {
        return element[i];
      }
    }

    return new BasicData();
  }
}
