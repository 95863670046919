<div *ngIf="summary">
  <div>
    Email Address(es): {{summary.Emails}}
  </div>
  <div>
    Mobile Number(s): {{summary.Devices}}
  </div>
  <div *ngIf="showMobileDistribution">
    <div *ngFor="let country of summary.Countries">
      {{ country.Country ? country.Country.Name : "" }}: {{country.Count}}
    </div>
  </div>
</div>
