import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { CronOptions } from './CronOptions';
import { DayMonthHeader } from './day-month-picker/day-month-picker.component';
import * as moment from 'moment-timezone';
import * as cronstrue from 'cronstrue';
import { TimezonePickerComponent } from './timezone-picker/timezone-picker.component';
import { DatePipe } from '@angular/common';
import { AuthenticationService } from '../authentication.Service';

declare var require: any;
const dayjs = require("dayjs");

export class Schedule {
  timezone: string = moment.tz.guess();
  start: Date = new Date();
  end: Date = new Date(new Date().getDate() + 3);
  cron: string;

  constructor(timezone: string, start: Date, end: Date, cron: string) {
    this.timezone = timezone;
    this.start = start;
    this.end = end;
    this.cron = cron;
  }

  get offset(): number {
    let a = moment.tz(this.timezone);
    return moment.tz(this.timezone).utcOffset();
  }
}

export const Days: any = {
  'SUN': "Sunday",
  'MON': "Monday",
  'TUE': "Tuesday",
  'WED': "Wednesday",
  'THU': "Thursday",
  'FRI': "Friday",
  'SAT': "Saturday"
};

export const MonthWeeks: any = {
  '#1': "First",
  '#2': "Second",
  '#3': "Third",
  '#4': "Fourth",
  '#5': "Fifth",
  'L': "Last"
};

export enum Months {
  January = 1,
  February,
  March,
  April,
  May,
  June,
  July,
  August,
  September,
  October,
  November,
  December
};

@Component({
  selector: 'app-scheduler',
  templateUrl: './scheduler.component.html',
  styleUrls: ['./scheduler.component.css']
})
export class SchedulerComponent implements OnInit {
  public cronExpression = '0 0 1 1 *';
  public isCronDisabled = false;
  public selectOptions = this.getSelectOptions();
  public activeTab: string = "";
  public state: any;

  private localCron = "0 0 1 1 *";
  private isDirty: boolean = false;
  registerChange: boolean = true;

  weyhey = true;
  dayMonthHeader = DayMonthHeader;
  timezone: string = "";

  daterangepickerOptions = {
    startDate: dayjs(),
    endDate: dayjs(),
    format: "DD.MM.YYYY HH:mm",
    minDate: dayjs()
      .add(-120, "months")
      .format("DD.MM.YYYY hh:mm a"),
    maxDate: dayjs()
      .add(120, "months")
      .format("DD.MM.YYYY hh:mm a"),
    inactiveBeforeStart: true,
    autoApply: true,
    addTouchSupport: true,
    showRanges: true,
    singleCalendar: false,
    displayFormat: "DD.MM.YYYY hh:mm a",
    position: "left",
    disabled: false,
    noDefaultRangeSelected: true,
    timePicker: {
      minuteInterval: 5,
      twentyFourHourFormat: false
    },
    disableBeforeStart: false,
    alwaysOpen: false,
    placeholder: 'Schedule duration',
  };


  public cronOptions: CronOptions = {
    formInputClass: 'form-control cron-editor-input',
    formSelectClass: 'form-control cron-editor-select',
    formRadioClass: 'cron-editor-radio',
    formCheckboxClass: 'cron-editor-checkbox',

    defaultTime: '00:00:00',

    hideOnceTab: false,
    hideMinutesTab: false,
    hideHourlyTab: false,
    hideDailyTab: false,
    hideWeeklyTab: false,
    hideMonthlyTab: false,
    hideYearlyTab: false,
    hideAdvancedTab: false,
    hideSpecificWeekDayTab: false,
    hideSpecificMonthWeekTab: false,

    use24HourTime: true,
    hideSeconds: false,

    cronFlavor: 'quartz'
  };

  formGroup: FormGroup = new FormGroup({});

  onceForm: FormGroup = new FormGroup({});
  onceDate = new FormControl();
  hourlyForm: FormGroup = new FormGroup({});
  dailyForm: FormGroup = new FormGroup({});
  weeklyForm: FormGroup = new FormGroup({});
  monthlyForm: FormGroup = new FormGroup({});
  endForm: FormGroup = new FormGroup({});

  public options: CronOptions = {
    formInputClass: 'form-control cron-editor-input',
    formSelectClass: 'form-control cron-editor-select',
    formRadioClass: 'cron-editor-radio',
    formCheckboxClass: 'cron-editor-checkbox',

    defaultTime: '00:00:00',

    hideOnceTab: false,
    hideMinutesTab: false,
    hideHourlyTab: false,
    hideDailyTab: false,
    hideWeeklyTab: false,
    hideMonthlyTab: false,
    hideYearlyTab: false,
    hideAdvancedTab: false,
    hideSpecificWeekDayTab: false,
    hideSpecificMonthWeekTab: false,

    use24HourTime: true,
    hideSeconds: false,

    cronFlavor: 'quartz'
  };

  constructor(public translate: TranslateService,
    public authenticationService: AuthenticationService,
    private fb: FormBuilder) {
    let offset = moment.tz(moment.tz.guess()).utcOffset();
    this._start = new Date(new Date().getTime() + offset * 60000);
    this._end = new Date(new Date().getTime() + offset * 60000);
    this._end.setDate(this._end.getDate() + 3);
  }

  get isDIYTestersUser(): boolean {
    return this.authenticationService.isAuthorized(['diy']);
  }

  public dayDisplay(day: string): string {
    return Days[day];
  }

  public monthDayDisplay(month: string): string {
    if (month === "L") {
      return "Last Day";
    } else if (month === "LW") {
      return "Last Weekday";
    } else if (month === "1W") {
      return "First Weekday";
    } else {
      return `${month}${this.getOrdinalSuffix(month)}`;
    }
  }

  public monthWeekDisplay(monthWeekNumber: string): string {
    return MonthWeeks[monthWeekNumber];
  }

  public monthDisplay(month: number): string {
    return Months[month];
  }

  timezoneChanged(timezonePicker: TimezonePickerComponent) {
    if (this.registerChange) {
      this.registerChange = false;
      this.timezone = timezonePicker.currentTimezone;
      this.computeForTab();
      this.scheduleChange.emit(this.schedule ?? new Schedule(this.timezone, this.start, this.end, this.cron));
      this.registerChange = true;
    }
  }

  timeChanged(time: any) {
    this.start = time;
    this.computeForTab();
  }

  ngOnInit(): void {
    this.state = this.getDefaultState();

    this.handleModelChange(this.cron);

    const [defaultHours, defaultMinutes, defaultSeconds] =
      this.options.defaultTime.split(":").map(Number);

    this.formGroup = new FormGroup({
      Timezone: new FormControl("", [Validators.required])
    });

    this.onceForm = this.fb.group({
      dummy: []
    });
    this.onceForm.valueChanges.subscribe((value) => {
      this.computeOnceCron(value)
      this.registerChange = false;
      this.scheduleChange.emit(this.schedule ?? new Schedule(this.timezone, this.start, this.end, this.cron));
      this.registerChange = true;
    });

    this.hourlyForm = this.fb.group({
      hours: [this.state.hourly.hours],
      minutes: [this.state.hourly.minutes],
      seconds: [this.state.hourly.seconds],
    });
    this.hourlyForm.valueChanges.subscribe((value) => {
      this.computeHourlyCron(value);
      this.registerChange = false;
      this.scheduleChange.emit(this.schedule ?? new Schedule(this.timezone, this.start, this.end, this.cron));
      this.registerChange = true;
    }
    );

    this.dailyForm = this.fb.group({
      subTab: this.state.daily.subTab,
      everyDays: this.fb.group({
        days: [this.state.daily.everyDays.days],
        hours: [this.state.daily.everyDays.hours],
        minutes: [this.state.daily.everyDays.minutes],
        seconds: [0],
        hourType: [this.getHourType(0)],
        at: [this.state.daily.at]
      }),
      everyWeekDay: this.fb.group({
        at: [this.state.daily.at]
      }),
      every: this.fb.group({
        hours: [this.state.daily.everyDays.hours],
        minutes: [this.state.daily.everyDays.minutes],
        seconds: [this.state.daily.everyDays.seconds],
        hourType: [this.getHourType(0)],
      }), 
      until: this.fb.group({
        hours: [this.state.daily.until.hours],
        minutes: [this.state.daily.until.minutes],
        seconds: [this.state.daily.until.seconds],
        hourType: [this.getHourType(0)],

      }),
    });
    this.dailyForm.valueChanges.subscribe((value) => {
      this.computeDailyCron(value);
      this.registerChange = false;
      this.scheduleChange.emit(this.schedule ?? new Schedule(this.timezone, this.start, this.end, this.cron));
      this.registerChange = true;
    });

    this.weeklyForm = this.fb.group({
      subTab: this.state.weekly.subTab,
      MON: [this.state.weekly.MON],
      TUE: [this.state.weekly.TUE],
      WED: [this.state.weekly.WED],
      THU: [this.state.weekly.THU],
      FRI: [this.state.weekly.FRI],
      SAT: [this.state.weekly.SAT],
      SUN: [this.state.weekly.SUN],
      every: this.fb.group({
        days: [1],
        hours: [this.state.weekly.every.hours],
        minutes: [this.state.weekly.every.minutes],
        seconds: [this.state.weekly.every.seconds],
        hourType: [this.getHourType(this.state.weekly.hours)],
      }),
      hourly: this.fb.group({
        hours: [this.getAmPmHour(1)],
        minutes: [0],
        seconds: [0],
        hourType: [this.getHourType(0)],
      }),
      until: this.fb.group({
        hours: [this.state.weekly.until.hours],
        minutes: [0],
        seconds: [0],
        hourType: [this.getHourType(0)],
      }),
      at: [this.state.weekly.at],
      hours: [this.getAmPmHour(defaultHours)],
      minutes: [defaultMinutes],
      seconds: [defaultSeconds],
      hourType: [this.getHourType(defaultHours)],
    });
    this.weeklyForm.valueChanges.subscribe((value) => {
      this.computeWeeklyCron(value);
      this.registerChange = false;
      this.scheduleChange.emit(this.schedule ?? new Schedule(this.timezone, this.start, this.end, this.cron));
      this.registerChange = true;
    });

    this.monthlyForm = this.fb.group({
      subTab: ["specificDay"],
      specificDay: this.fb.group({
        day: ["1"],
        months: [1],
        hours: [this.getAmPmHour(defaultHours)],
        minutes: [defaultMinutes],
        seconds: [defaultSeconds],
        hourType: [this.getHourType(defaultHours)],
      }),
      specificWeekDay: this.fb.group({
        monthWeek: ["#1"],
        day: ["MON"],
        months: [1],
        hours: [this.getAmPmHour(defaultHours)],
        minutes: [defaultMinutes],
        seconds: [defaultSeconds],
        hourType: [this.getHourType(defaultHours)],
      }),
    });
    this.monthlyForm.valueChanges.subscribe((value) => {
      this.computeMonthlyCron(value);
      this.registerChange = false;
      this.scheduleChange.emit(this.schedule ?? new Schedule(this.timezone, this.start, this.end, this.cron));
      this.registerChange = true;
    });
  }

  public onTabFocus(idx: number) {
    switch (idx) {
      case 0:
        this.onceForm.setValue(this.onceForm.value);
        break;
      case 1:
        this.hourlyForm.setValue(this.hourlyForm.value);
        break;
      case 2:
        this.dailyForm.setValue(this.dailyForm.value);
        break;
      case 3:
        this.weeklyForm.setValue(this.weeklyForm.value);
        break;
      case 4:
        this.monthlyForm.setValue(this.monthlyForm.value);
        break;
      default:
        throw new Error("Invalid tab selected");
    }
  }

  private computeForTab() {
    switch (this.tabIndex) {
      case 0:
        break;
      case 1:
        this.computeHourlyCron(this.hourlyForm.value);
        break;
      case 2:
        this.computeDailyCron(this.dailyForm.value);
        break;
      case 3:
        this.computeWeeklyCron(this.weeklyForm.value);
        break;
      case 4:
        this.computeMonthlyCron(this.monthlyForm.value);
        break;
    }
  }

  private computeOnceCron(state: any) {
    this.cron = "0 0 1 1 *";
  }

  private computeMinutesCron(state: any) {
    this.cron = `${this.isCronFlavorQuartz ? state.seconds : ""} 0/${state.minutes
      } * 1/1 * ${this.weekDayDefaultChar} ${this.yearDefaultChar}`.trim();
  }

  private computeHourlyCron(state: any) {
    if (state.hours === 0) {
      this.computeMinutesCron(state);
      return;
    }

    this.cron = `${this.isCronFlavorQuartz ? state.seconds : ""} ${state.minutes
      } 0/${state.hours} 1/1 * ${this.weekDayDefaultChar} ${this.yearDefaultChar
      }`.trim();
  }

  private getTimeElement(time: string, element: number): number {
    try {
      let x = time.split(" ");
      let elements = x[0].split(":");
      return Number(elements[element]) + (element == 0 && x[x.length - 1] == "PM" ? 12 : 0);
    }
    catch {
      return 0;
    }
  }

  private computeDailyCron(state: any) {
    // let at = moment(state.everyDays.at).add(this.schedule.offset, 'm').toDate();
    switch (state.subTab) {
      case "attime":
      case "everyDays":
        this.cron = `${this.isCronFlavorQuartz ? state.everyDays.seconds : ""
          } ${this.getTimeElement(state.everyDays.at, 1)} ${this.hourToCron(
            this.getTimeElement(state.everyDays.at, 0),
            state.everyDays.hourType
          )} 1/${state.everyDays.days} * ${this.weekDayDefaultChar} ${this.yearDefaultChar
          }`.trim();
        break;
      case "everyWeekDay":
        this.cron = `${this.isCronFlavorQuartz ? state.everyDays.seconds : ""
          } ${this.getTimeElement(state.everyWeekDay.at, 1)} ${this.hourToCron(
            this.getTimeElement(state.everyWeekDay.at, 0),
            state.everyDays.hourType
          )} ${this.monthDayDefaultChar} * MON-FRI ${this.yearDefaultChar
          }`.trim();
        break;
      case "hourly":
        let tempDate = moment(this.start).add(this.start.getTimezoneOffset(), 'm').toDate()
        this.cron = `${this.isCronFlavorQuartz ? state.every.seconds : ""} ${tempDate.getMinutes()}/${state.every.minutes
          } ${tempDate.getHours()}${state.until.hours == 0 ? "" : "-"
          }${state.until.hours == 0 ? "" : tempDate.getHours() + Number(state.until.hours)}${state.every.hours > 0 ? "/" + state.every.hours : ""} * * ${this.monthDayDefaultChar
          } ${this.yearDefaultChar}`.trim();
        break;
      default:
        throw new Error("Invalid cron daily subtab selection");
    }
  }

  private computeWeeklyCron(state: any) {
    const days = this.selectOptions.days
      .reduce((acc: string[], day: string) => (state[day] ? acc.concat([day]) : acc), [])
      .join(",");
    switch (state.subTab) {
      case "attime":
        this.cron = `${this.isCronFlavorQuartz ? state.seconds : ""} ${this.getTimeElement(state.at, 1)
          } ${this.hourToCron(this.getTimeElement(state.at, 0), state.hourType)} ${this.monthDayDefaultChar
          } * ${days} ${this.yearDefaultChar}`.trim();
        break;
      case "everytime":
        let tempDate = moment(this.start).add(this.start.getTimezoneOffset(), 'm').toDate()
        this.cron = `${this.isCronFlavorQuartz ? state.every.seconds : ""} ${tempDate.getMinutes()}/${state.every.minutes
          } ${tempDate.getHours()}${state.until.hours == 0 ? "" : "-"
          }${state.until.hours == 0 ? "" : tempDate.getHours() + Number(state.until.hours)}${state.every.hours > 0 ? "/" + state.every.hours : ""
          } ? * ${days} ${this.yearDefaultChar}`.trim();
        break;
    }
  }

  private computeMonthlyCron(state: any) {
    switch (state.subTab) {
      case "specificDay":
        this.cron = `${this.isCronFlavorQuartz ? state.specificDay.seconds : ""
          } ${state.specificDay.minutes} ${this.hourToCron(
            state.specificDay.hours,
            state.specificDay.hourType
          )} ${state.specificDay.day} 1/${state.specificDay.months} ${this.weekDayDefaultChar
          } ${this.yearDefaultChar}`.trim();
        break;
      case "specificWeekDay":
        this.cron = `${this.isCronFlavorQuartz ? state.specificWeekDay.seconds : ""
          } ${state.specificWeekDay.minutes} ${this.hourToCron(
            state.specificWeekDay.hours,
            state.specificWeekDay.hourType
          )} ${this.monthDayDefaultChar} 1/${state.specificWeekDay.months} ${state.specificWeekDay.day
          }${state.specificWeekDay.monthWeek} ${this.yearDefaultChar}`.trim();
        break;
      default:
        throw new Error("Invalid cron montly subtab selection");
    }
  }

  private getRange(start: number, end: number): number[] {
    const length = end - start + 1;
    return Array.apply(null, Array(length)).map((_, i) => i + start);
  }

  private getSelectOptions() {
    return {
      months: this.getRange(1, 12),
      monthWeeks: ["#1", "#2", "#3", "#4", "#5", "L"],
      days: ["MON", "TUE", "WED", "THU", "FRI", "SAT", "SUN"],
      minutes: this.getRange(0, 59),
      fullMinutes: this.getRange(0, 59),
      seconds: this.getRange(0, 59),
      hours: this.getRange(1, 23),
      monthDays: this.getRange(1, 31),
      monthDaysWithLasts: [
        "1W",
        ...[...this.getRange(1, 31).map(String)],
        "LW",
        "L",
      ],
      monthDaysWithOutLasts: [...[...this.getRange(1, 31).map(String)]],
      hourTypes: ["AM", "PM"],
    };
  }

  private hourToCron(hour: number, hourType: string) {
    if (this.options.use24HourTime) {
      return hour == 24 ? 0 : hour > 24 ? hour - 12 : hour;
    } else {
      return hourType === "AM"
        ? hour === 12
          ? 0
          : hour
        : hour === 12
          ? 12
          : hour + 12;
    }
  }

  @Input() get schedule(): Schedule | null {
    return new Schedule(this.timezone, this.start, this.end, this.cron);
  }
  set schedule(value: Schedule | null) {
    if (value && this.registerChange) {
      this.timezone = value.timezone;
      this.start = value.start;
      this.end = value.end;
      this.cron = value.cron;
    }
  }

  @Output() scheduleChange = new EventEmitter<Schedule>();

  @Input() get cron(): string {
    return this.localCron;
  }
  set cron(value: string) {
    if (value != this.localCron) {
      this.localCron = value;
      if (this.registerChange) {
        this.registerChange = false;
        this.scheduleChange.emit(this.schedule ?? new Schedule(this.timezone, this.start, this.end, this.cron));
        this.registerChange = true;
      }
    }
  }

  get start(): Date {
    return this._start;
  }
  set start(value: Date) {
    if (this._start != value) {
      if (typeof value === "object") {
        this._start = value;
      }
      else {
        this._start = new Date(value);
      }

      if (this.registerChange) {
        this.registerChange = false;
        this.scheduleChange.emit(this.schedule ?? new Schedule(this.timezone, this.start, this.end, this.cron));
        this.registerChange = true;
      }
    }
  }
  private _start: Date = new Date();

  get end(): Date {
    return this._end;
  }
  set end(value: Date) {
    if (this._end != value) {
      if (typeof value === "object") {
        this._end = value;
      }
      else {
        this._end = new Date(value);
      }

      if (this.registerChange) {
        this.registerChange = false;
        this.scheduleChange.emit(this.schedule ?? new Schedule(this.timezone, this.start, this.end, this.cron));
        this.registerChange = true;
      }
    }
  }
  private _end: Date = new Date();

  get tabIndex(): number {
    switch (this.activeTab) {
      case "once":
        return 0;
      case "hourly":
        return 1;
      case "daily":
        return 2;
      case "weekly":
        return 3;
      case "monthly":
        return 4;
      default:
        return 0;
    }
  }

  get cronDescription(): string {
    if (this.cron === "0 0 1 1 *") {
      return "";
    }

    return " - " + this.cron; // + cronstrue.toString(this.cron);
  }

  private getAmPmHour(hour: number) {
    return this.options.use24HourTime ? hour : ((hour + 11) % 12) + 1;
  }

  private getHourType(hour: number) {
    return this.options.use24HourTime ? undefined : hour >= 12 ? "PM" : "AM";
  }

  private getDefaultState() {
    const [defaultHours, defaultMinutes, defaultSeconds] =
      this.options.defaultTime.split(":").map(Number);

    return {
      once: {
      },
      minutes: {
        minutes: 1,
        seconds: 0,
      },
      hourly: {
        hours: 1,
        minutes: 0,
        seconds: 0,
      },
      daily: {
        subTab: "attime",
        everyDays: {
          days: 1,
          hours: this.getAmPmHour(defaultHours),
          minutes: defaultMinutes,
          seconds: defaultSeconds,
          hourType: this.getHourType(defaultHours),
        },
        everyWeekDay: {
          hours: this.getAmPmHour(defaultHours),
          minutes: defaultMinutes,
          seconds: defaultSeconds,
          hourType: this.getHourType(defaultHours),
        },
        every: {
          hours: this.getAmPmHour(defaultHours),
          minutes: defaultMinutes,
          seconds: defaultSeconds,
          hourType: this.getHourType(defaultHours),
        },
        until: {
          hours: this.getAmPmHour(defaultHours),
          minutes: defaultMinutes,
          seconds: defaultSeconds,
          hourType: this.getHourType(defaultHours),
        }
      },
      weekly: {
        MON: true,
        TUE: false,
        WED: false,
        THU: false,
        FRI: false,
        SAT: false,
        SUN: false,
        hours: this.getAmPmHour(defaultHours),
        minutes: defaultMinutes,
        seconds: defaultSeconds,
        hourType: this.getHourType(defaultHours),
        every: {
          hours: this.getAmPmHour(defaultHours),
          minutes: defaultMinutes,
          seconds: defaultSeconds,
          hourType: this.getHourType(defaultHours),
        },
        until: {
          hours: this.getAmPmHour(defaultHours),
          minutes: defaultMinutes,
          seconds: defaultSeconds,
          hourType: this.getHourType(defaultHours),
        }
      },
      monthly: {
        subTab: "specificDay",
        specificDay: {
          day: "1",
          months: 1,
          hours: this.getAmPmHour(defaultHours),
          minutes: defaultMinutes,
          seconds: defaultSeconds,
          hourType: this.getHourType(defaultHours),
        },
        specificWeekDay: {
          monthWeek: "#1",
          day: "MON",
          months: 1,
          hours: this.getAmPmHour(defaultHours),
          minutes: defaultMinutes,
          seconds: defaultSeconds,
          hourType: this.getHourType(defaultHours),
        },
      },
      yearly: {
        subTab: "specificMonthDay",
        specificMonthDay: {
          month: 1,
          day: "1",
          hours: this.getAmPmHour(defaultHours),
          minutes: defaultMinutes,
          seconds: defaultSeconds,
          hourType: this.getHourType(defaultHours),
        },
        specificMonthWeek: {
          monthWeek: "#1",
          day: "MON",
          month: 1,
          hours: this.getAmPmHour(defaultHours),
          minutes: defaultMinutes,
          seconds: defaultSeconds,
          hourType: this.getHourType(defaultHours),
        },
      },
      advanced: {
        expression: this.isCronFlavorQuartz
          ? "0 15 10 L-2 * ? *"
          : "15 10 2 * *",
      },
    };
  }

  get isCronFlavorQuartz() {
    return this.options.cronFlavor === "quartz";
  }

  get isCronFlavorStandard() {
    return this.options.cronFlavor === "standard";
  }

  get yearDefaultChar() {
    return this.options.cronFlavor === "quartz" ? "*" : "";
  }

  get weekDayDefaultChar() {
    return this.options.cronFlavor === "quartz" ? "?" : "*";
  }

  get monthDayDefaultChar() {
    return this.options.cronFlavor === "quartz" ? "?" : "*";
  }

  private handleModelChange(cron: string) {
    if (this.isDirty) {
      this.isDirty = false;
      return;
    } else {
      this.isDirty = false;
    }

    if (cron === "") {
      this.activeTab = "once";
      return;
    }

    if (!this.cronIsValid(cron)) {
      this.activeTab = "once";
      return;
    }

    const origCron: string = cron;
    if (cron.split(" ").length === 5 && this.isCronFlavorStandard) {
      cron = `0 ${cron} *`;
    }

    const [seconds, minutes, hours, dayOfMonth, month, dayOfWeek] =
      cron.split(" ");

    if (cron.match(/\d+ 0\/\d+ \* 1\/1 \* [\?\*] \*/)) {
      this.activeTab = "hourly";
      this.state.hourly.hours = 0;
      this.state.hourly.minutes = parseInt(minutes.substring(2), 10);
      this.state.hourly.seconds = parseInt(seconds, 10);
    } else if (cron.match(/\d+ \d+ 0\/\d+ 1\/1 \* [\?\*] \*/)) {
      this.activeTab = "hourly";
      this.state.hourly.hours = parseInt(hours.substring(2), 10);
      this.state.hourly.minutes = parseInt(minutes, 10);
      this.state.hourly.seconds = parseInt(seconds, 10);
    } else if (cron.match(/\d+ \d+ \d+ 1\/\d+ \* [\?\*] \*/)) {
      this.activeTab = "daily";
      this.state.daily.subTab = "everyDays";
      this.state.daily.everyDays.days = parseInt(dayOfMonth.substring(2), 10);
      const parsedHours = parseInt(hours, 10);
      this.state.daily.everyDays.hours = this.getAmPmHour(parsedHours);
      this.state.daily.everyDays.hourType = this.getHourType(parsedHours);
      this.state.daily.everyDays.minutes = parseInt(minutes, 10);
      this.state.daily.everyDays.seconds = parseInt(seconds, 10);
      let ampm = this.state.daily.everyDays.hours > 12 ? "PM" : "AM";
      this.state.daily.at = `${this.state.daily.everyDays.hours - (ampm == "PM" ? 12 : 0)}:${this.state.daily.everyDays.minutes} ${ampm}`;
    } else if (cron.match(/\d+ \d+ \d+ [\?\*] \* MON-FRI \*/)) {
      this.activeTab = "daily";
      this.state.daily.subTab = "everyWeekDay";
      const parsedHours = parseInt(hours, 10);
      this.state.daily.everyWeekDay.hours = this.getAmPmHour(parsedHours);
      this.state.daily.everyWeekDay.hourType = this.getHourType(parsedHours);
      this.state.daily.everyWeekDay.minutes = parseInt(minutes, 10);
      this.state.daily.everyWeekDay.seconds = parseInt(seconds, 10);
      let ampm = this.state.daily.everyDays.hours > 12 ? "PM" : "AM";
      this.state.daily.at = `${this.state.daily.everyDays.hours - (ampm == "PM" ? 12 : 0)}:${this.state.daily.everyDays.minutes} ${ampm}`;
    } else if (cron.match(/\d+ \d+\/\d+ \d+\-\d+ \* \* [\*\?] \*/) || cron.match(/\d+ \d+\/\d+ \d+\-\d+\/\d+ \* \* [\*\?] \*/)) {
      this.activeTab = "daily";
      this.state.daily.subTab = "hourly";
      let hourtab = hours.split('/');
      if (hourtab.length == 1) {
        this.state.daily.everyDays.hours = 0;
      }
      else {
        this.state.daily.everyDays.hours = parseInt(hourtab[1], 10);
      }

      let hourdiff = hourtab[0].split('-');
      this.state.daily.until.hours = parseInt(hourdiff[1], 10) - parseInt(hourdiff[0], 10);
      this.state.daily.until.minutes = 0;
      this.state.daily.until.seconds = 0;

      let minutetab = minutes.split('/');
      if (minutetab.length == 1) {
        this.state.daily.everyDays.minutes = 0;
      }
      else {
        this.state.daily.everyDays.minutes = parseInt(minutetab[1], 10);
      }

      this.state.daily.everyDays.seconds = parseInt(seconds, 10);
    } else if (
      cron.match(/\d+ \d+ \d+ [\?\*] \* (MON|TUE|WED|THU|FRI|SAT|SUN)(,(MON|TUE|WED|THU|FRI|SAT|SUN))* \*/) ||
      cron.match(/\d+ \d+\/\d+ \d+\-\d+ [\*\?] \* (MON|TUE|WED|THU|FRI|SAT|SUN)(,(MON|TUE|WED|THU|FRI|SAT|SUN))* \*/) ||
      cron.match(/\d+ \d+\/\d+ \d+\-\d+\/\d+ [\*\?] \* (MON|TUE|WED|THU|FRI|SAT|SUN)(,(MON|TUE|WED|THU|FRI|SAT|SUN))* \*/)
    ) {
      this.activeTab = "weekly";
      this.state.weekly.subTab = "everytime";
      this.selectOptions.days.forEach(
        (weekDay) => (this.state.weekly[weekDay] = false)
      );
      dayOfWeek
        .split(",")
        .forEach((weekDay) => (this.state.weekly[weekDay] = true));

      // Check to see if it Every... or at a specific time.
      let hourtab = hours.split('/');
      if (hourtab.length == 1) {
        this.state.weekly.every.hours = 0;
      }
      else {
        this.state.weekly.every.hours = parseInt(hourtab[1], 10);
      }

      let hourdiff = hourtab[0].split('-');
      if (hourdiff.length == 1) {
        this.state.weekly.subTab = "attime";
        const parsedHours = parseInt(hours, 10);
        this.state.weekly.hours = this.getAmPmHour(parsedHours);
        this.state.weekly.hourType = this.getHourType(parsedHours);
        this.state.weekly.minutes = parseInt(minutes, 10);
        this.state.weekly.seconds = parseInt(seconds, 10);
        let ampm = this.state.weekly.hours > 12 ? "PM" : "AM";
        this.state.weekly.at = `${this.state.weekly.hours - (ampm == "PM" ? 12 : 0)}:${this.state.weekly.minutes} ${ampm}`;
      }
      else {
        this.state.weekly.until.hours = parseInt(hourdiff[1], 10) - parseInt(hourdiff[0], 10);
        this.state.weekly.until.minutes = 0;
        this.state.weekly.until.seconds = 0;

        let minutetab = minutes.split('/');
        if (minutetab.length == 1) {
          this.state.weekly.every.minutes = 0;
        }
        else {
          this.state.weekly.every.minutes = parseInt(minutetab[1], 10);
        }
      }
    } else if (cron.match(/\d+ \d+ \d+ (\d+|L|LW|1W) 1\/\d+ [\?\*] \*/)) {
      this.activeTab = "monthly";
      this.state.monthly.subTab = "specificDay";
      this.state.monthly.specificDay.day = dayOfMonth;
      this.state.monthly.specificDay.months = parseInt(month.substring(2), 10);
      const parsedHours = parseInt(hours, 10);
      this.state.monthly.specificDay.hours = this.getAmPmHour(parsedHours);
      this.state.monthly.specificDay.hourType = this.getHourType(parsedHours);
      this.state.monthly.specificDay.minutes = parseInt(minutes, 10);
      this.state.monthly.specificDay.seconds = parseInt(seconds, 10);
    } else if (
      cron.match(
        /\d+ \d+ \d+ [\?\*] 1\/\d+ (MON|TUE|WED|THU|FRI|SAT|SUN)((#[1-5])|L) \*/
      )
    ) {
      const day = dayOfWeek.substr(0, 3);
      const monthWeek = dayOfWeek.substr(3);
      this.activeTab = "monthly";
      this.state.monthly.subTab = "specificWeekDay";
      this.state.monthly.specificWeekDay.monthWeek = monthWeek;
      this.state.monthly.specificWeekDay.day = day;
      this.state.monthly.specificWeekDay.months = parseInt(
        month.substring(2),
        10
      );
      const parsedHours = parseInt(hours, 10);
      this.state.monthly.specificWeekDay.hours = this.getAmPmHour(parsedHours);
      this.state.monthly.specificWeekDay.hourType =
        this.getHourType(parsedHours);
      this.state.monthly.specificWeekDay.minutes = parseInt(minutes, 10);
      this.state.monthly.specificWeekDay.seconds = parseInt(seconds, 10);
    } else if (cron.match(/\d+ \d+ \d+ (\d+|L|LW|1W) \d+ [\?\*] \*/)) {
      this.activeTab = "yearly";
      this.state.yearly.subTab = "specificMonthDay";
      this.state.yearly.specificMonthDay.month = parseInt(month, 10);
      this.state.yearly.specificMonthDay.day = dayOfMonth;
      const parsedHours = parseInt(hours, 10);
      this.state.yearly.specificMonthDay.hours = this.getAmPmHour(parsedHours);
      this.state.yearly.specificMonthDay.hourType =
        this.getHourType(parsedHours);
      this.state.yearly.specificMonthDay.minutes = parseInt(minutes, 10);
      this.state.yearly.specificMonthDay.seconds = parseInt(seconds, 10);
    } else if (
      cron.match(
        /\d+ \d+ \d+ [\?\*] \d+ (MON|TUE|WED|THU|FRI|SAT|SUN)((#[1-5])|L) \*/
      )
    ) {
      const day = dayOfWeek.substr(0, 3);
      const monthWeek = dayOfWeek.substr(3);
      this.activeTab = "yearly";
      this.state.yearly.subTab = "specificMonthWeek";
      this.state.yearly.specificMonthWeek.monthWeek = monthWeek;
      this.state.yearly.specificMonthWeek.day = day;
      this.state.yearly.specificMonthWeek.month = parseInt(month, 10);
      const parsedHours = parseInt(hours, 10);
      this.state.yearly.specificMonthWeek.hours = this.getAmPmHour(parsedHours);
      this.state.yearly.specificMonthWeek.hourType =
        this.getHourType(parsedHours);
      this.state.yearly.specificMonthWeek.minutes = parseInt(minutes, 10);
      this.state.yearly.specificMonthWeek.seconds = parseInt(seconds, 10);
    } else {
      this.activeTab = "advanced";
      this.state.advanced.expression = origCron;
    }
  }

  private cronIsValid(cron: string): boolean {
    if (cron) {
      const cronParts = cron.split(" ");
      return (
        (this.isCronFlavorQuartz &&
          (cronParts.length === 6 || cronParts.length === 7)) ||
        (this.isCronFlavorStandard && cronParts.length === 5)
      );
    }

    return false;
  }

  private getOrdinalSuffix(value: string) {
    if (value.length > 1) {
      const secondToLastDigit = value.charAt(value.length - 2);
      if (secondToLastDigit === "1") {
        return "th";
      }
    }

    const lastDigit = value.charAt(value.length - 1);
    switch (lastDigit) {
      case "1":
        return "st";
      case "2":
        return "nd";
      case "3":
        return "rd";
      default:
        return "th";
    }
  }
}


