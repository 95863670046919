<div class="fullWidth dialog-title">
  <div class="leftFloat">
    <h3>{{data.name}}Logic</h3>
  </div>
  <div class="rightFloat">
    <button mat-icon-button matDialogClose="no" matTooltip="Close" matTooltipPosition="below" (click)="close()">
      <mat-icon class="dialog-close-icon">close</mat-icon>
    </button>
  </div>
</div>
<div class="routing-dialog-content custom-scroll-bar">
  <app-advanced-routing [rules]="data.rules" [showAddRemove]="data.showAddRemove" [name]="data.name" class="fullWidth"></app-advanced-routing>
  <div *ngIf="displayGoto()">
    <app-question-combo [displayGoto]="true" [rules]="data.rules"></app-question-combo>
  </div>
  <div *ngIf="displayOptions()">
    Answers to Display
    <mat-selection-list #options (selectionChange)="onChange(options.selectedOptions.selected)">
      <mat-list-option *ngFor="let category of data.rules.Question.Categories.List; let i = index" [value]="category.Name" [selected]="data.rules.Rules[1].Rules[0].Answers.includes(category.Name)">
        {{category.Label}}
      </mat-list-option>
    </mat-selection-list>
  </div>
</div>
