import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { baseUrl, options } from "../environments/environment";
import { UserProfile } from "./user.Service";

export class SystemConfig {
  ConfigurationID: number = 0;
  Name: string = "";
  Id: string = "";
  ModifiedId: string = "";
  Value: string = "";
  ConfigType: string = "";
  GroupName: string = "";
  IsDeleted: boolean = false;
  LastUpdatedDate: Date = new Date();
  UserProfile: UserProfile = new UserProfile();
}

@Injectable()
export class SystemService {
  constructor(private http: HttpClient) {
  }

  get(): Observable<SystemConfig[]> {
    return this.http.get<SystemConfig[]>(baseUrl + 'System/Get', options);
  }

  set(settings: SystemConfig[]) {
    return this.http.post(baseUrl + 'System/Post', JSON.stringify(settings), options);
  }

  getById(id: string): Observable<any> {
    return this.http.patch(baseUrl + "System/GetById", JSON.stringify(id), options);
  }
}
