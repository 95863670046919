<div class="qn-properties-window custom-scroll-bar" [formGroup]="formGroup">
  <div>
    <mat-form-field *ngIf="!showChangeTo()" appearance="outline">
      <mat-label>Question Type</mat-label>
      <input type="text" readonly="readonly" matInput placeholder="Question Type" formControlName="questionType">
    </mat-form-field>
    <mat-form-field *ngIf="showChangeTo()" appearance="outline">
      <mat-label>Question Type</mat-label>
      <mat-select formControlName="questionTypeId">
        <mat-option *ngIf="properties.changeTo.includes('boolean')" value="boolean">Yes or No</mat-option>
        <mat-option *ngIf="properties.changeTo.includes('mcsa')" value="mcsa">Single Answer</mat-option>
        <mat-option *ngIf="properties.changeTo.includes('mcma')" value="mcma">Multiple Answer</mat-option>
        <mat-option *ngIf="properties.changeTo.includes('ranking')" value="ranking">Ranking</mat-option>
        <mat-option *ngIf="properties.changeTo.includes('userList')" value="userList">User List</mat-option>
        <mat-option *ngIf="properties.changeTo.includes('textbox')" value="textbox">Text box</mat-option>
        <mat-option *ngIf="properties.changeTo.includes('email')" value="email">Email</mat-option>
        <mat-option *ngIf="properties.changeTo.includes('information')" value="information">Survey Introduction</mat-option>
        <mat-option *ngIf="properties.changeTo.includes('barcode')" value="barcode">Bar Code</mat-option>
        <mat-option *ngIf="properties.changeTo.includes('currency')" value="currency">Currency</mat-option>
        <mat-option *ngIf="properties.changeTo.includes('integer')" value="integer">Number</mat-option>
        <mat-option *ngIf="properties.changeTo.includes('terminate')" value="terminate">Close Survey</mat-option>
        <mat-option *ngIf="properties.changeTo.includes('geocode')" value="geocode">Location</mat-option>
        <mat-option *ngIf="properties.changeTo.includes('date')" value="date">Date &amp; Time</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div *ngIf="properties.defaultAnswer">
    <mat-form-field *ngIf="!isSms && !hasChoices()" appearance="outline">
      <mat-label>Default Answer</mat-label>
      <input type="text" matInput placeholder="Default Answer" formControlName="defaultValue">
    </mat-form-field>
    <mat-form-field *ngIf="hasChoices() && hasMultipleAnswers()" appearance="outline">
      <mat-label>Default Answer</mat-label>
      <mat-select formControlName="defaultAnswerId" multiple>
        <mat-option *ngFor="let category of model.Categories.List" [value]="category.Name">{{category.Label}}</mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field *ngIf="hasChoices() && !hasMultipleAnswers()" appearance="outline">
      <mat-label>Default Answer</mat-label>
      <mat-select formControlName="defaultValue">
        <mat-option [value]="">[Nothing Selected]</mat-option>
        <mat-option *ngFor="let category of model.Categories.List" [value]="category.Name">{{category.Label}}</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div *ngIf="properties.initialAnswer && !isSms">
    <mat-form-field *ngIf="!isSms && !hasChoices()" appearance="outline">
      <mat-label>Initial Answer</mat-label>
      <input type="text" matInput placeholder="Initial Answer" formControlName="initialValue">
    </mat-form-field>
    <mat-form-field *ngIf="hasChoices() && hasMultipleAnswers()" appearance="outline">
      <mat-label>Initial Answer</mat-label>
      <mat-select formControlName="initialAnswerId" multiple>
        <mat-option *ngFor="let category of model.Categories.List" [value]="category.Name">{{category.Label}}</mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field *ngIf="hasChoices() && !hasMultipleAnswers()" appearance="outline">
      <mat-label>Initial Answer</mat-label>
      <mat-select formControlName="initialValue">
        <mat-option [value]=""></mat-option>
        <mat-option *ngFor="let category of model.Categories.List" [value]="category.Name">{{category.Label}}</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div *ngIf="properties.mandatory" class="mt-10">
    <mat-slide-toggle formControlName="mandatory"><span class="font-wt-400">Mandatory</span></mat-slide-toggle>
  </div>
  <div *ngIf="properties.imageOptions" class="mt-10">
    <mat-slide-toggle [hidden]="true" formControlName="imageOptions"><span class="font-wt-400">Image Options</span></mat-slide-toggle>
  </div>
  <div *ngIf="properties.sortBy && !isSms" class="mt-15">
    <mat-form-field appearance="outline">
      <mat-label>Sort By</mat-label>
      <mat-select formControlName="sortBy" required>
        <mat-option value="none">None</mat-option>
        <mat-option value="ascending">Ascending</mat-option>
        <mat-option value="descending">Descending</mat-option>
        <mat-option value="random">Random</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div *ngIf="properties.min != -1" class="mt-20">
    <mat-form-field appearance="outline">
      <mat-label>{{ getLowLabel() }}</mat-label>
      <input type="text" matInput [placeholder]="getLowLabel()" formControlName="minSize">
    </mat-form-field>
  </div>
  <div *ngIf="properties.max > -1">
    <mat-form-field appearance="outline">
      <mat-label>{{ getHighLabel() }}</mat-label>
      <input type="text" matInput [placeholder]="getHighLabel()" formControlName="maxSize">
    </mat-form-field>
  </div>
  <div *ngIf="properties.precision > -1">
    <mat-form-field appearance="outline">
      <mat-label>Precision</mat-label>
      <input type="text" matInput placeholder="Precision" formControlName="precision">
    </mat-form-field>
  </div>
  <div *ngIf="!isSms && properties.placeholder">
    <mat-form-field appearance="outline">
      <mat-label>Placeholder</mat-label>
      <input type="text" matInput placeholder="Placeholder" formControlName="placeHolder">
    </mat-form-field>
  </div>
  <div *ngIf="!isSms && properties.numericMask">
    <mat-form-field appearance="outline">
      <mat-label>Mask</mat-label>
      <input type="text" matInput placeholder="Mask" formControlName="numericMask">
    </mat-form-field>
  </div>
  <div *ngIf="!isSms && properties.textMask">
    <mat-form-field appearance="outline">
      <mat-label>Mask</mat-label>
      <input type="text" matInput placeholder="Mask" formControlName="textMask">
    </mat-form-field>
  </div>
  <div *ngIf="!isSms && !isWhatsApp && properties.controls.length > 0" class="mt-10">
    <mat-form-field appearance="outline">
      <mat-label>Control Type</mat-label>
      <mat-select formControlName="control" (selectionChange)="controlChanged($event)" required>
        <mat-option *ngIf="properties.controls.includes('none')" value="none">Textbox</mat-option>
        <mat-option *ngIf="properties.controls.includes('slider')" value="slider">Slider</mat-option>
        <mat-option *ngIf="properties.controls.includes('star')" value="star">Stars</mat-option>
        <mat-option *ngIf="properties.controls.includes('radiobutton')" value="radiobutton">Radio Buttons</mat-option>
        <mat-option *ngIf="properties.controls.includes('checkboxes')" value="checkboxes">Check Boxes</mat-option>
        <mat-option *ngIf="properties.controls.includes('link')" value="link">Links</mat-option>
        <mat-option *ngIf="properties.controls.includes('button')" value="button">Buttons</mat-option>
        <mat-option *ngIf="properties.controls.includes('dropdown')" value="dropdown">Drop Down</mat-option>
        <mat-option *ngIf="properties.controls.includes('datetime')" value="datetime">Date &amp; Time</mat-option>
        <mat-option *ngIf="properties.controls.includes('date')" value="date">Date</mat-option>
        <mat-option *ngIf="properties.controls.includes('time')" value="time">Time</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div *ngIf="!isSms && properties.orientation.length > 0" class="mt-10">
    <mat-form-field appearance="outline">
      <mat-label>Orientation</mat-label>
      <mat-select formControlName="orientation" required>
        <mat-option value="horizontal">Horizontal</mat-option>
        <mat-option value="vertical">Vertical</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div *ngIf="properties.showNA" class="mt-10">
    <mat-checkbox formControlName="showNA">Show Not Applicable</mat-checkbox>
  </div>
  <div class="controlList">
    <div *ngIf="properties.addToLibrary" class="controlList">
      <button mat-stroked-button (click)="addToLibrary()">
        <mat-icon>add_circle_outline</mat-icon>To Library
      </button>
    </div>
    <div *ngIf="properties.displayLogic || properties.skipLogic || properties.carryForwardLogic || properties.optionFilter || properties.errorLogic" class="controlList">
      <button mat-stroked-button [matMenuTriggerFor]="menu" aria-label="icon-button with a menu" class="logic-btn">
        <mat-icon>add_circle_outline</mat-icon>Logic
      </button>
      <mat-menu #menu="matMenu">
        <button *ngIf="properties.displayLogic" mat-menu-item (click)="displayLogic()">
          <mat-icon>account_tree</mat-icon>&nbsp;&nbsp;<span>Display Logic</span>
        </button>
        <button *ngIf="properties.skipLogic" mat-menu-item (click)="skipLogic()">
          <mat-icon>low_priority</mat-icon>&nbsp;&nbsp;<span>Skip Logic</span>
        </button>
        <button *ngIf="properties.carryForwardLogic" mat-menu-item (click)="carryForwardLogic()">
          <mat-icon>read_more</mat-icon>&nbsp;&nbsp;<span>Carry Forward Logic</span>
        </button>
        <button *ngIf="properties.optionFilter" mat-menu-item (click)="answerChoiceLogic()">
          <mat-icon>filter_list</mat-icon>&nbsp;&nbsp;<span>Answer Choice Display Logic</span>
        </button>
        <button *ngIf="properties.errorLogic" mat-menu-item (click)="errorLogic()">
          <mat-icon>error_outline</mat-icon>&nbsp;&nbsp;<span>Error Handling Logic</span>
        </button>
      </mat-menu>
    </div>
  </div>
</div>
