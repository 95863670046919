import { Component, OnInit, Inject, Input, AfterViewInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { InterviewService, QuestionModel, RuleGroup, RuleList, RuleModel } from '../question.service';
import { FormGroup, FormControl, Validators, FormArray } from '@angular/forms';
import { QuestionEditorComponent } from '../question-editor/question-editor.component';
import { RoutingDialogComponent } from '../questions/routing-dialog/routing-dialog.component';
import { plainToClass } from 'class-transformer';
import { MatSnackBar } from '@angular/material/snack-bar';


@Component({
  selector: 'app-question-properties',
  templateUrl: './question-properties.component.html',
  styleUrls: ['./question-properties.component.css']
})

export class QuestionPropertiesComponent implements OnInit, AfterViewInit {
  propertyDefinitions = [
    {
      questionType: 'unknown', description: 'unknown', initialAnswer: false, defaultAnswer: false, mandatory: false, imageOptions: false, sortBy: false,
      fixed: false, optionSortBy: false, factory: false, optionImageOptions: false, bulkEdit: false, textBuilder: false,
      addToLibrary: false, displayLogic: false, carryForwardLogic: false, skipLogic: false, errorLogic: false, optionFilter: false,
      selectFromUserList: false, min: -1, max: -1, precision: -1, textMask: false, numericMask: false, placeholder: false,
      lowHighLabel: false, controls: [], lowLabel: '', highLabel: '', changeTo: [], orientation: [], showNA: false
    },
    {
      questionType: 'geocode', description: 'Location', initialAnswer: false, defaultAnswer: false, mandatory: true, imageOptions: false, sortBy: false,
      fixed: false, optionSortBy: false, factory: false, optionImageOptions: false, bulkEdit: false, textBuilder: false,
      addToLibrary: true, displayLogic: true, carryForwardLogic: false, skipLogic: true, errorLogic: true, optionFilter: false, selectFromUserList: false, min: -1, max: -1, precision: -1, textMask: false, numericMask: false, placeholder: false,
      lowHighLabel: false, controls: [], lowLabel: '', highLabel: '', changeTo: ['textbox', 'email', 'currency', 'information', 'integer', 'barcode', 'date', 'terminate', 'geocode'], orientation: [], showNA: false
    },
    {
      questionType: 'barcode', description: 'Bar Code', initialAnswer: false, defaultAnswer: false, mandatory: true, imageOptions: false, sortBy: false,
      fixed: false, optionSortBy: false, factory: false, optionImageOptions: false, bulkEdit: false, textBuilder: false,
      addToLibrary: true, displayLogic: true, carryForwardLogic: false, skipLogic: true, errorLogic: true, optionFilter: false, selectFromUserList: false, min: -1, max: -1, precision: -1, textMask: false, numericMask: false, placeholder: false,
      lowHighLabel: false, controls: [], lowLabel: '', highLabel: '', changeTo: ['textbox', 'email', 'currency', 'information', 'integer', 'barcode', 'date', 'terminate', 'geocode'], orientation: [], showNA: false
    },
    {
      questionType: 'block', description: 'Block', initialAnswer: false, defaultAnswer: false, mandatory: false, imageOptions: false, sortBy: true,
      fixed: false, optionSortBy: false, factory: false, optionImageOptions: false, bulkEdit: false, textBuilder: false,
      addToLibrary: true, displayLogic: true, carryForwardLogic: false, skipLogic: false, errorLogic: false, optionFilter: false, selectFromUserList: false, min: -1, max: -1, precision: -1, textMask: false, numericMask: false, placeholder: false,
      lowHighLabel: false, controls: [], lowLabel: '', highLabel: '', changeTo: [], orientation: [], showNA: false
    },
    {
      questionType: 'boolean', description: 'Yes or No', initialAnswer: true, defaultAnswer: true, mandatory: true, imageOptions: false, sortBy: false,
      fixed: false, optionSortBy: false, factory: false, optionImageOptions: false, bulkEdit: false, textBuilder: false,
      addToLibrary: true, displayLogic: true, carryForwardLogic: false, skipLogic: true, errorLogic: true, optionFilter: false, selectFromUserList: false, min: -1, max: -1, precision: -1, textMask: false, numericMask: false, placeholder: false,
      lowHighLabel: false, controls: ["radiobutton", "checkboxes"], lowLabel: '', highLabel: '', changeTo: ['boolean', 'textbox', 'information', 'integer', 'mcma', 'mcsa', 'userlist'], orientation: [], showNA: false
    },
    {
      questionType: 'bucket', description: 'Card Sort', initialAnswer: false, defaultAnswer: false, mandatory: true, imageOptions: false, sortBy: true,
      fixed: false, optionSortBy: false, factory: false, optionImageOptions: false, bulkEdit: false, textBuilder: false,
      addToLibrary: true, displayLogic: true, carryForwardLogic: false, skipLogic: false, errorLogic: false, optionFilter: false, selectFromUserList: false, min: -1, max: -1, precision: -1, textMask: false, numericMask: false, placeholder: false,
      lowHighLabel: false, controls: [], lowLabel: '', highLabel: '', changeTo: [], orientation: [], showNA: false
    },
    {
      questionType: 'currency', description: 'Currency', initialAnswer: true, defaultAnswer: true, mandatory: true, imageOptions: false, sortBy: false,
      fixed: false, optionSortBy: false, factory: false, optionImageOptions: false, bulkEdit: false, textBuilder: false,
      addToLibrary: true, displayLogic: true, carryForwardLogic: false, skipLogic: true, errorLogic: true, optionFilter: false, selectFromUserList: false, min: -1, max: 20000000, precision: -1, textMask: false, numericMask: true, placeholder: true,
      lowHighLabel: false, controls: [], lowLabel: '', highLabel: '', changeTo: ['textbox', 'email', 'currency', 'information', 'integer', 'barcode', 'date', 'terminate', 'geocode'], orientation: [], showNA: false
    },
    {
      questionType: 'email', description: 'Email', initialAnswer: true, defaultAnswer: true, mandatory: true, imageOptions: true, sortBy: false,
      fixed: false, optionSortBy: false, factory: false, optionImageOptions: false, bulkEdit: false, textBuilder: false,
      addToLibrary: true, displayLogic: true, carryForwardLogic: false, skipLogic: true, errorLogic: true, optionFilter: false, selectFromUserList: false, min: -1, max: -1, precision: -1, textMask: false, numericMask: false, placeholder: true,
      lowHighLabel: false, controls: [], lowLabel: '', highLabel: '', changeTo: ['textbox', 'email', 'currency', 'information', 'integer', 'barcode', 'date', 'terminate', 'geocode'], orientation: [], showNA: false
    },
    {
      questionType: 'information', description: 'Information', initialAnswer: false, defaultAnswer: false, mandatory: true, imageOptions: false, sortBy: false,
      fixed: false, optionSortBy: false, factory: false, optionImageOptions: false, bulkEdit: false, textBuilder: false,
      addToLibrary: true, displayLogic: true, carryForwardLogic: false, skipLogic: false, errorLogic: false, optionFilter: false, selectFromUserList: false, min: -1, max: -1, precision: -1, textMask: false, numericMask: false, placeholder: false,
      lowHighLabel: false, controls: [], lowLabel: '', highLabel: '', changeTo: ['textbox', 'email', 'currency', 'information', 'integer', 'barcode', 'date', 'terminate', 'geocode'], orientation: [], showNA: false
    },
    {
      questionType: 'integer', description: 'Number', initialAnswer: true, defaultAnswer: true, mandatory: true, imageOptions: false, sortBy: false,
      fixed: false, optionSortBy: false, factory: false, optionImageOptions: false, bulkEdit: false, textBuilder: false,
      addToLibrary: true, displayLogic: true, carryForwardLogic: false, skipLogic: true, errorLogic: true, optionFilter: false, selectFromUserList: false, min: -20000000, max: 20000000, precision: -1, textMask: false, numericMask: false, placeholder: true,
      lowHighLabel: false, controls: [], lowLabel: 'Minimum Value', highLabel: 'Maximum Value', changeTo: ['textbox', 'email', 'currency', 'information', 'integer', 'barcode', 'date', 'terminate', 'geocode'], orientation: [], showNA: false
    },
    {
      questionType: 'matrix', description: 'Matrix', initialAnswer: false, defaultAnswer: false, mandatory: true, imageOptions: false, sortBy: true,
      fixed: false, optionSortBy: true, factory: false, optionImageOptions: false, bulkEdit: false, textBuilder: false,
      addToLibrary: true, displayLogic: true, carryForwardLogic: false, skipLogic: false, errorLogic: false, optionFilter: true, selectFromUserList: false, min: -1, max: -1, precision: -1, textMask: false, numericMask: false, placeholder: false,
      lowHighLabel: false, controls: [], lowLabel: '', highLabel: '', changeTo: [], orientation: [], showNA: false
    },
    {
      questionType: 'maxDiff', description: 'Max Diff', initialAnswer: false, defaultAnswer: false, mandatory: true, imageOptions: false, sortBy: false,
      fixed: false, optionSortBy: false, factory: false, optionImageOptions: false, bulkEdit: false, textBuilder: false,
      addToLibrary: true, displayLogic: true, carryForwardLogic: false, skipLogic: false, errorLogic: true, optionFilter: false, selectFromUserList: false, min: -1, max: -1, precision: -1, textMask: false, numericMask: false, placeholder: false,
      lowHighLabel: false, controls: [], lowLabel: '', highLabel: '', changeTo: [], orientation: [], showNA: false
    },
    {
      questionType: 'mcma', description: 'Multiple Choice', initialAnswer: true, defaultAnswer: true, mandatory: true, imageOptions: true, sortBy: true,
      fixed: false, optionSortBy: false, factory: false, optionImageOptions: true, bulkEdit: false, textBuilder: false,
      addToLibrary: true, displayLogic: true, carryForwardLogic: true, skipLogic: true, errorLogic: true, optionFilter: true, selectFromUserList: false, min: 0, max: 100, precision: -1, textMask: false, numericMask: false, placeholder: false,
      lowHighLabel: false, controls: ["checkboxes", "button", "dropdown"], lowLabel: 'Minimum Options', highLabel: 'Maximum Options', changeTo: ['mcsa', 'mcma', 'userlist', 'ranking'], orientation: [], showNA: false
    },
    {
      questionType: 'date', description: 'Date', initialAnswer: true, defaultAnswer: true, mandatory: true, imageOptions: true, sortBy: false,
      fixed: false, optionSortBy: false, factory: false, optionImageOptions: true, bulkEdit: false, textBuilder: false,
      addToLibrary: true, displayLogic: true, carryForwardLogic: false, skipLogic: true, errorLogic: true, optionFilter: false, selectFromUserList: false, min: -1, max: -1, precision: -1, textMask: false, numericMask: false, placeholder: false,
      lowHighLabel: false, controls: ["datetime", "date", "time"], lowLabel: '', highLabel: '', changeTo: ['textbox', 'email', 'currency', 'information', 'integer', 'barcode', 'date', 'terminate', 'geocode'], orientation: [], showNA: false
    },
    {
      questionType: 'mcsa', description: 'Single Answer', initialAnswer: true, defaultAnswer: true, mandatory: true, imageOptions: true, sortBy: true,
      fixed: false, optionSortBy: false, factory: false, optionImageOptions: true, bulkEdit: false, textBuilder: false,
      addToLibrary: true, displayLogic: true, carryForwardLogic: true, skipLogic: true, errorLogic: true, optionFilter: false, selectFromUserList: false, min: -1, max: -1, precision: -1, textMask: false, numericMask: false, placeholder: false,
      lowHighLabel: false, controls: ["radiobutton", "button", "dropdown"], lowLabel: 'Min Options', highLabel: 'Max Options', changeTo: ['mcma', 'mcsa', 'userlist', 'ranking'], orientation: [], showNA: false
    },
    {
      questionType: 'media', description: 'Media', initialAnswer: true, defaultAnswer: true, mandatory: true, imageOptions: true, sortBy: true,
      fixed: false, optionSortBy: false, factory: false, optionImageOptions: false, bulkEdit: false, textBuilder: false,
      addToLibrary: true, displayLogic: true, carryForwardLogic: false, skipLogic: false, errorLogic: false, optionFilter: false, selectFromUserList: false, min: -1, max: -1, precision: -1, textMask: false, numericMask: false, placeholder: false,
      lowHighLabel: false, controls: [], lowLabel: '', highLabel: '', changeTo: [], orientation: [], showNA: false
    },
    {
      questionType: 'ranking', description: 'Ranking', initialAnswer: true, defaultAnswer: true, mandatory: true, imageOptions: true, sortBy: true,
      fixed: false, optionSortBy: false, factory: false, optionImageOptions: false, bulkEdit: false, textBuilder: false,
      addToLibrary: true, displayLogic: true, carryForwardLogic: false, skipLogic: false, errorLogic: true, optionFilter: false, selectFromUserList: false, min: -1, max: -1, precision: -1, textMask: false, numericMask: false, placeholder: false,
      lowHighLabel: false, controls: [], lowLabel: '', highLabel: '', changeTo: ['mcsa', 'mcma', 'userlist', 'ranking'], orientation: [], showNA: false
    },
    {
      questionType: 'rating', description: 'Rating', initialAnswer: true, defaultAnswer: true, mandatory: true, imageOptions: true, sortBy: false,
      fixed: false, optionSortBy: false, factory: false, optionImageOptions: false, bulkEdit: false, textBuilder: false,
      addToLibrary: true, displayLogic: true, carryForwardLogic: false, skipLogic: false, errorLogic: true, optionFilter: false, selectFromUserList: false, min: -1, max: -1, precision: -1, textMask: false, numericMask: false, placeholder: false,
      lowHighLabel: false, controls: ["none", "star", "slider", "radiobutton", "link"], lowLabel: '', highLabel: '', changeTo: [], orientation: ["horizontal", "vertical"], showNA: true
    },
    {
      questionType: 'sendEmail', description: 'Send Email', initialAnswer: true, defaultAnswer: true, mandatory: true, imageOptions: true, sortBy: true,
      fixed: false, optionSortBy: false, factory: false, optionImageOptions: false, bulkEdit: false, textBuilder: false,
      addToLibrary: true, displayLogic: true, carryForwardLogic: false, skipLogic: false, errorLogic: false, optionFilter: false, selectFromUserList: false, min: -1, max: -1, precision: -1, textMask: false, numericMask: false, placeholder: false,
      lowHighLabel: false, controls: [], lowLabel: '', highLabel: '', changeTo: [], orientation: [], showNA: false
    },
    {
      questionType: 'sendSms', description: 'Send SMS', initialAnswer: true, defaultAnswer: true, mandatory: true, imageOptions: true, sortBy: true,
      fixed: false, optionSortBy: false, factory: false, optionImageOptions: false, bulkEdit: false, textBuilder: false,
      addToLibrary: true, displayLogic: true, carryForwardLogic: false, skipLogic: false, errorLogic: false, optionFilter: false, selectFromUserList: false, min: -1, max: -1, precision: -1, textMask: false, numericMask: false, placeholder: false,
      lowHighLabel: false, controls: [], lowLabel: '', highLabel: '', changeTo: [], orientation: [], showNA: false
    },
    {
      questionType: 'terminate', description: 'Terminate', initialAnswer: true, defaultAnswer: true, mandatory: true, imageOptions: true, sortBy: true,
      fixed: false, optionSortBy: false, factory: false, optionImageOptions: false, bulkEdit: false, textBuilder: false,
      addToLibrary: true, displayLogic: true, carryForwardLogic: false, skipLogic: false, errorLogic: false, optionFilter: false, selectFromUserList: false, min: -1, max: -1, precision: -1, textMask: false, numericMask: false, placeholder: false,
      lowHighLabel: false, controls: [], lowLabel: '', highLabel: '', changeTo: ['textbox', 'email', 'currency', 'information', 'integer', 'barcode', 'date', 'terminate', 'geocode'], orientation: [], showNA: false
    },
    {
      questionType: 'textbox', description: 'Text box', initialAnswer: true, defaultAnswer: true, mandatory: true, imageOptions: true, sortBy: false,
      fixed: false, optionSortBy: false, factory: false, optionImageOptions: false, bulkEdit: false, textBuilder: false,
      addToLibrary: true, displayLogic: true, carryForwardLogic: false, skipLogic: true, errorLogic: true, optionFilter: false, selectFromUserList: false, min: 0, max: 1024, precision: -1, textMask: true, numericMask: false, placeholder: true,
      lowHighLabel: false, controls: [], lowLabel: '', highLabel: '', changeTo: ['textbox', 'email', 'currency', 'information', 'integer', 'barcode', 'date', 'terminate', 'geocode'], orientation: [], showNA: false
    },
    {
      questionType: 'userlist', description: 'User List', initialAnswer: true, defaultAnswer: true, mandatory: true, imageOptions: true, sortBy: true,
      fixed: false, optionSortBy: false, factory: false, optionImageOptions: true, bulkEdit: false, textBuilder: false,
      addToLibrary: true, displayLogic: false, carryForwardLogic: false, skipLogic: false, errorLogic: false, optionFilter: false, selectFromUserList: false, min: -1, max: -1, precision: -1, textMask: false, numericMask: false, placeholder: false,
      lowHighLabel: false, controls: [], lowLabel: '', highLabel: '', changeTo: ['mcsa', 'mcma', 'userlist', 'ranking'], orientation: [], showNA: false
    },
    {
      questionType: 'advanced', description: 'Routing', initialAnswer: false, defaultAnswer: false, mandatory: false, imageOptions: false, sortBy: false,
      fixed: false, optionSortBy: false, factory: false, optionImageOptions: false, bulkEdit: false, textBuilder: false,
      addToLibrary: false, displayLogic: false, carryForwardLogic: false, skipLogic: false, errorLogic: false, optionFilter: false,
      selectFromUserList: false, min: -1, max: -1, precision: -1, textMask: false, numericMask: false, placeholder: false,
      lowHighLabel: false, controls: [], lowLabel: '', highLabel: '', changeTo: [], orientation: [], showNA: false
    },
  ];

  formGroup: FormGroup = new FormGroup({});
  quesSelected: string = "";
  seletedPropertiestext: string = "";
  propertyDefinition: QuestionProperties[] = this.propertyDefinitions;
  properties: QuestionProperties = new QuestionProperties();
  panelOpenState: boolean = false;

  private subscribeFormChange = false;

  constructor(
    public dialog: MatDialog,
    private interviewService: InterviewService,
    private snackbar: MatSnackBar
  ) {

  }

  ngOnInit() {
    this.formGroup = new FormGroup({
      questionType: new FormControl(),
      questionTypeId: new FormControl(),
      defaultValue: new FormControl(),
      defaultAnswerId: new FormControl(),
      initialValue: new FormControl(),
      initialAnswerId: new FormControl(),
      mandatory: new FormControl(),
      imageOptions: new FormControl(),
      sortBy: new FormControl(),
      minSize: new FormControl(),
      maxSize: new FormControl(),
      precision: new FormControl(),
      textMask: new FormControl(),
      numericMask: new FormControl(),
      placeHolder: new FormControl(),
      control: new FormControl(),
      orientation: new FormControl(),
      showNA: new FormControl()
    });
    this.quesSelected = "None";
    this.properties = this.propertyDefinition[0];
    this.formGroup.valueChanges.subscribe(x => {
      if (this._model === undefined) {
        return;
      }
      if (this.subscribeFormChange) {
        if (this._model.QuestionType !== this.formGroup.controls.questionTypeId.value) {
          if (this._model.QuestionType === "mcsa" && this.formGroup.controls.questionTypeId.value === "mcma") {
            this._model.DefaultValue = "";
            this._model.InitialValue = "";
          }

          this._model.QuestionType = this.formGroup.controls.questionTypeId.value;
          this.editor?.refreshComponent(
            {
              index: this._model.Index,
              parentIndex: this._model.ParentIndex,
              displayIndex: this._model.DisplayIndex,
              displayParentIndex: this._model.DisplayParentIndex,
              questionType: this._model.QuestionType,
              action: "replace"
            });
          this.setProperties();
        }

        if (this.hasChoices()) {
          try {
            if (this.hasMultipleAnswers()) {
              this._model.DefaultValue = this.formGroup.controls.defaultAnswerId.value.join(",");
            }
            else {
              if (this.formGroup.controls.defaultAnswerId.value as []) {
                this._model.DefaultValue = this.formGroup.controls.defaultAnswerId.value;
              }
              else {
                this._model.DefaultValue = this.formGroup.controls.defaultValue.value;
              }
            }
          }
          catch {
            this._model.DefaultValue = this.formGroup.controls.defaultAnswerId.value;
          }

          try {
            if (this.hasMultipleAnswers()) {
              this._model.InitialValue = this.formGroup.controls.initialAnswerId.value.join(",");
            }
            else {
              if (this.formGroup.controls.initialAnswerId.value as []) {
                this._model.InitialValue = this.formGroup.controls.initialAnswerId.value;
              }
              else {
                this._model.InitialValue = this.formGroup.controls.initialValue.value;
              }
            }
          }
          catch {
            this._model.InitialValue = this.formGroup.controls.initialAnswerId.value;
          }
        }
        else {
          this._model.DefaultValue = this.formGroup.controls.defaultValue.value;
          this._model.InitialValue = this.formGroup.controls.initialValue.value;
        }

        this._model.MustAnswer = this.formGroup.controls.mandatory.value;
        this._model.Categories.Order = this.formGroup.controls.sortBy.value;
        this._model.Min = Number(this.formGroup.controls.minSize.value);
        this._model.Max = Number(this.formGroup.controls.maxSize.value);
        this._model.Precision = Number(this.formGroup.controls.precision.value);
        this._model.Placeholder = this.formGroup.controls.placeHolder.value;
        this._model.Control = this.formGroup.controls.control.value;
        this._model.Orientation = this.formGroup.controls.orientation.value;
        this._model.NotApplicable = this.formGroup.controls.showNA.value;
        this._model.Mask = "";
        if (this.properties.numericMask) {
          this._model.Mask = this.formGroup.controls.numericMask.value;
        }
        if (this.properties.textMask) {
          this._model.Mask = this.formGroup.controls.textMask.value;
        }
      }
    });
  }

  ngAfterViewInit(): void {
    Promise.resolve(null).then(() => { this.CalculateModel(); });
  }

  controlChanged($event: any) {
    if ($event.value === "star") {
      this.formGroup.controls.minSize.setValue(1);
      this._model.Min = 1;
      const ref = this.editor?.questions?.getQuestionComponent(this._model);
      ref.model = this._model;
      let array = ref.ratingFormGroup.controls.ratingFormArray as FormArray;
      let form = array.controls[0] as FormGroup;
      form.controls.minValue.setValue(1);
    }
  }

  addToLibrary() {
    if (this.editor?.library) {
      this.editor.library.editor = this.editor;
      this.editor.library.addQuestionFromScript(this._model.ParentIndex, this._model.Index);
      this.openSnackbar("Question successfully added to the library", "");
    }
    else {
      const question = this.editor?.interview.findQuestionByIndex(this._model.ParentIndex, this._model.Index);
      if (question == null) {
        this.openSnackbar("Unable to add the question at this point in time", "Cancel");
        return;
      }

      this.interviewService.addToLibrary(question).subscribe(result => {
        this.openSnackbar("Question successfully added to the library", "");
      });
    }
  }

  errorLogic() {
    let rules = this._model.ErrorLogic;
    if (rules === undefined || rules === null || rules.Rules.length < 2) {
      rules = new RuleList();
      rules.Rules = [];
      rules.Rules.push(new RuleGroup());
      rules.Rules[0].Rules = [];
      rules.Rules[0].Rules.push(new RuleModel());
      rules.Rules[0].Rules[0].RuleType = "error";
    }

    this._model.ErrorLogic = this.addLogic("Error Handling ", rules);
  }

  displayLogic() {
    let rules = this._model.DisplayLogic;
    if (rules === undefined || rules === null || rules.Rules.length < 1) {
      rules = new RuleList();
      rules.Rules = [];
      rules.Rules.push(new RuleGroup());
      rules.Rules[0].Rules = [];
      rules.Rules[0].Rules.push(new RuleModel());
      rules.Rules[0].Rules[0].RuleType = "display";
    }

    this._model.DisplayLogic = this.addLogic("Display ", rules);
  }

  carryForwardLogic() {
    let rules = this._model.CarryForward;
    if (rules === undefined || rules === null || rules.Rules.length < 1) {
      rules = new RuleList();
      rules.Rules = [];
      rules.Rules.push(new RuleGroup());
      rules.Rules[0].Rules = [];
      rules.Rules[0].Rules.push(new RuleModel());
      rules.Rules[0].Rules[0].RuleType = "carry";
    }

    this._model.CarryForward = this.addLogic("Carry Forward ", rules);
  }

  answerChoiceLogic() {
    let rules = this._model.CarryForward;
    if (rules === undefined || rules === null || rules.Rules.length < 2) {
      rules = new RuleList();
      rules.Rules = [];
      rules.Rules.push(new RuleGroup());
      rules.Rules.push(new RuleGroup());
      rules.Rules[0].Rules = [];
      rules.Rules[0].Rules.push(new RuleModel());
      rules.Rules[1].Rules = [];
      rules.Rules[1].Rules.push(new RuleModel());
      rules.Rules[1].Rules[0].Answers = [];
    }

    this._model.AnswerChoiceLogic = this.addLogic("Answer Choice ", rules);
  }

  skipLogic() {
    let rules = this._model.SkipLogic;
    if (rules === undefined || rules === null || rules.Rules.length < 1) {
      rules = new RuleList();
      rules.Rules = [];
      rules.Rules.push(new RuleGroup());
      rules.Rules[0].Rules = [];
      rules.Rules[0].Rules.push(new RuleModel());
      rules.Rules[0].Rules[0].RuleType = "skip";
    }

    this._model.SkipLogic = this.addLogic("Skip ", rules);
  }

  addLogic(name: string, rules: RuleList | null): RuleList {
    if (rules == null) {
      rules = new RuleList();
    }

    rules.Question = this._model;
    const dialogRef = this.dialog.open(RoutingDialogComponent,
      {
        data: { name: name, rules: rules, showAddRemove: false },
        height: '50%',
        width: '95vw',
        maxWidth: '95vw'
      });

    dialogRef.afterClosed().subscribe(result => {
      let a = result;
    });

    return rules;
  }

  optionFilter() {
  }

  @Input()
  get editor(): QuestionEditorComponent | null {
    return this._editor;
  }
  set editor(value: QuestionEditorComponent | null) {
    this._editor = value;
  }
  private _editor: QuestionEditorComponent | null = null;

  @Input()
  get parentIndex(): number {
    return this._parentIndex;
  }
  set parentIndex(value: number) {
    this._parentIndex = value;
    this.CalculateModel();
  }
  private _parentIndex: number = -1;

  @Input()
  get index(): number {
    return this._index;
  }
  set index(value: number) {
    if (value !== -2) {
      this._index = value;
    }

    if (value !== undefined) {
      this.CalculateModel();
    }
  }
  private _index: number = -1;

  CalculateModel() {
    if (this.editor === undefined) {
      this.model = new QuestionModel();
    }

    if (!(this.editor) || !(this.editor.interview)) {
      return;
    }

    if (this._parentIndex === undefined || this._parentIndex === -1) {
      if (this.editor.interview.Questions.length > this._index) {
        this.model = this.editor.interview.Questions[this._index];
      }
      else {
        this.model = new QuestionModel();
      }
    }
    else {
      if (this.editor.interview.Questions.length > this._parentIndex) {
        let temp = this.editor.interview.Questions[this._parentIndex];
        if (temp.Questions.List.length > this._index) {
          this.model = temp.Questions.List[this._index];
        }
        else {
          this.model = new QuestionModel();
        }
      }
      else {
        this.model = new QuestionModel();
      }
    }
  }

  get model(): QuestionModel {
    return this._model;
  }
  set model(value: QuestionModel) {
    this._model = value;
    if (this._model === undefined) {
      this._model = new QuestionModel();
    }

    // Find the property definitions for the specific question type and map them to the form.
    Promise.resolve(null).then(() => {
      this.setProperties();
    });
  }
  private _model: QuestionModel = new QuestionModel();

  setProperties() {
    this.propertyDefinition.forEach(property => {
      if (property.questionType === this._model.QuestionType) {
        this.properties = property;
        this.mapToForm();
      }
    });
  }

  get isSms(): boolean {
    if (this.model) {
      return this.model.Interview.Channel == "sms";
    }

    return false;
  }

  get isWhatsApp(): boolean {
    return this.model?.Interview.Channel == "whatsapp" ?? false;
  }

  getLowLabel() {
    return this.properties.lowLabel == "" ? "Minimum Size" : this.properties.lowLabel;
  }

  getHighLabel() {
    return this.properties.highLabel == "" ? "Maximum Size" : this.properties.highLabel;
  }

  hasMultipleAnswers() {
    if (this._model && this._model.QuestionType === "mcma") {
      return true;
    }

    return undefined;
  }

  hasChoices(): boolean {
    return this._model &&
      (this._model.QuestionType === "mcma" ||
        this._model.QuestionType === "mcsa" ||
        this._model.QuestionType === "userList" ||
        this._model.QuestionType === "matrix" ||
        this._model.QuestionType === "ranking" ||
        this._model.QuestionType === "bucket" ||
      (this._model.QuestionType === "boolean"));
  }

  showChangeTo(): boolean {
    return this.properties.changeTo && this.properties.changeTo.length > 0
  }

  mapToForm() {
    if (this.formGroup === undefined) {
      return;
    }

    let minValue = this._model.Min;
    if (this.properties.min > -1 && this._model.Min === -1) {
      minValue = this.properties.min;
    }

    let maxValue = this._model.Max;
    if (this.properties.max > -1 && this._model.Max === -1) {
      maxValue = this.properties.max;
    }

    let precision = this._model.Precision;
    if (this.properties.precision > -1 && this._model.Precision === -1) {
      precision = this.properties.precision;
    }

    let textMask = "";
    let numericMask = "";
    if (this.properties.textMask) {
      textMask = this._model.Mask;
    }
    if (this.properties.numericMask) {
      numericMask = this._model.Mask;
    }

    this.subscribeFormChange = false;
    this.formGroup.patchValue({
      questionType: this.properties.description,
      questionTypeId: this.properties.questionType,
      defaultValue: this._model.DefaultValue,
      defaultAnswerId: this._model.DefaultValue.includes(',') ? this._model.DefaultValue.split(",") : "",
      initialValue: this._model.InitialValue,
      initialAnswerId: this._model.InitialValue.includes(',') ? this._model.InitialValue.split(",") : "",
      mandatory: this._model.MustAnswer,
      sortBy: this._model.Categories.Order,
      minSize: minValue,
      maxSize: maxValue,
      precision: precision,
      placeHolder: this._model.Placeholder,
      control: this._model.Control,
      numericMask: numericMask,
      textMask: textMask,
      orientation: this._model.Orientation == "" ? "horizontal" : this.model.Orientation,
      showNA: this.model.NotApplicable
    });
    this.subscribeFormChange = true;
  }

  private openSnackbar(message: string, action: string) {
    if (action == "") {
      this.snackbar.open(message, action, { duration: 2000 });
    }
    else {
      this.snackbar.open(message, action, { duration: 7000 });
    }
  }
}

export class QuestionProperties {
  // Basic Properties
  questionType: string = "";
  description: string = "";
  initialAnswer: boolean = false;
  defaultAnswer: boolean = false;
  mandatory: boolean = false;
  imageOptions: boolean = false;
  sortBy: boolean = false;

  // Option Properties
  fixed: boolean = false;
  optionSortBy: boolean = false;
  factory: boolean = false;
  optionImageOptions: boolean = false;
  bulkEdit: boolean = false;
  textBuilder: boolean = false;

  // Functions
  addToLibrary: boolean = false;
  displayLogic: boolean = false;
  carryForwardLogic: boolean = false;
  skipLogic: boolean = false;
  errorLogic: boolean = false;
  optionFilter: boolean = false;
  selectFromUserList: boolean = false;

  // Advanced Properties
  min: number = 0;
  max: number = 0;
  precision: number = 0;
  textMask: boolean = false;
  numericMask: boolean = false;
  placeholder: boolean = false;
  lowHighLabel: boolean = false;
  controls: string[] = [];
  orientation: string[] = [];
  showNA: boolean = false;

  lowLabel: string = "";
  highLabel: string = "";

  changeTo: string[] = [];
}
