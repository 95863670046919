import { Component, OnInit, Input, ViewChildren, QueryList } from '@angular/core';
import { AnswerModel } from "../../models/AnswerModel";
import { DataComponent } from '../data/data.component';

@Component({
  selector: 'app-page',
  templateUrl: './page.component.html',
  styleUrls: ['./page.component.css']
})
export class PageComponent implements OnInit {

  @ViewChildren('data') dataQuestion: QueryList<DataComponent> | undefined;

  questions: any;

  constructor() { }

  ngOnInit(): void {
  }

  @Input()
  get question(): any {
    return this._question;
  }
  set question(value: any) {
    this._question = value;
    if (this._question === undefined) {
      return;
    }

    this.questions = this._question.Item.Items;
  }
  private _question: any;

  get answers(): AnswerModel[] {
    const model: AnswerModel[] = [];
    this.dataQuestion?.forEach(question => {
      question.answers.forEach(answer => {
        model.push(answer);
      });
    });
    return model;
  }

  get valid(): boolean {
    if (this.dataQuestion == undefined) {
      return false;
    }

    for (let i = 0; i < this.dataQuestion.length; i++) {
      if (!this.dataQuestion.get(i)?.valid) {
        return false;
      }
    }

    return true;
  }
}
