import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { trigger, transition, animate, style, query, stagger, animateChild, group, state } from '@angular/animations';
import { CanvasComponent } from './elements/canvas/canvas.component';
import { AnswerModel } from './models/AnswerModel';
import { version } from '../../environments/environment';
import { MediaService } from '../media.Service';
import { StyleModel } from '../question.service';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Observable, Subscription, timer } from 'rxjs';
import { map } from 'rxjs/operators';
import { DataComponent } from './elements/data/data.component';
import { JsonCyclic } from '../utils';

@Component({
  selector: 'diy-player',
  templateUrl: './one-script-angular-player.component.html',
  styleUrls: ['./one-script-angular-player.component.css'],
  animations: [
    trigger('filterIn', [
      transition('* => true', [
        style({ opacity: 0, transform: 'translateX(-25px)'}),
        group([
          animate('0.5s ease', style({transform: 'translateX(0px)'})),
          animate('0.5s ease-in-out', style({ opacity: 1 }))
        ]),
      ])
    ]),
    trigger("filterOut", [
      transition('* => true', [
        group([
          animate('0.5s ease', style({transform: 'translateX(25px)'})),
          animate('0.5s ease-in-out', style({ opacity: 0 }))
        ])
      ])
    ])
  ]
})
export class OneScriptAngularPlayerComponent implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild('canvas') canvas: CanvasComponent | undefined;
  jsonView: string = "";
  type: any;
  answers: AnswerModel[] = [];
  nextQuestion: any;
  static firstTime = true;
  currentVersion: string;
  selectedTheme = "";
  poweredBySource: string = "";
  poweredByClass: string = "";
  surveyBackground: string = "";
  headerBackground: string = "";
  isTheme: boolean = false;
  isHeader: boolean = true;
  navigationColor: string = "";
  controlColor: string = "";

  private inTimer = false;
  waiting?: Observable<any>;
  waitTime: Observable<any> = timer(0, 60000);
  subscription?: Subscription;


  isIn = true;
  isOut = false;

  @ViewChild('background') background: ElementRef | undefined;
  @ViewChild('themeHeader') themeHeader: ElementRef | undefined;
  @ViewChild('customHeader') customHeader: ElementRef | undefined;

  constructor(private mediaService: MediaService,
    private elementRef: ElementRef,
    private breakpointObserver: BreakpointObserver) {
    this.currentVersion = version;
  }

  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
    .pipe(map(result => result.matches));

  ngOnInit(): void {
    if (this.model) {
      if (this.model.Style) {
        this.selectedTheme = this.model.Style.Name;
      }
      else {
        this.model.Style = new StyleModel();
      }
    }
  }

  ngAfterViewInit() {

  }

  ngOnDestroy(): void {
  }

  backgroundColor() {
    if (this.background !== undefined) {
      let backGround = this.background.nativeElement;
      var color = window.getComputedStyle(backGround).getPropertyValue('background-color');
      var vals = color.substring(color.indexOf('(') + 1, color.length - 1).split(', ');
      var r = parseInt(vals[0]);
      var g = parseInt(vals[1]);
      var b = parseInt(vals[2]);

      var luma = (r + g + b) / 3;
      return ((luma == 0) || (luma > 128)) ? "#000000" : "#ffffff";
    }
    return "#000000";
  }

  @Input()
  get playerWidth(): number {
    return this._playerWidth;
  }
  set playerWidth(value: number) {
    this._playerWidth = value;
  }
  private _playerWidth: number = -1;

  @Input()
  get model(): any {
    return this._model;
  }
  set model(value: any) {
    this._model = value;
    if (this._model === undefined || this._model === null) {
      this.jsonView = "";
      return;
    }

    this.jsonView = JsonCyclic.toJson(this._model);
    if (!this._preview && !OneScriptAngularPlayerComponent.firstTime) {
      this.isOut = true;
      this.isIn = false;
    }

    if (this.model.Style.FooterLogoIsDark) {
      this.poweredBySource = '../../assets/img/logo@3x_white.png';
      this.poweredByClass = 'poweredByLight';
    } else {
      this.poweredBySource = '../../assets/logo.svg';
      this.poweredByClass = 'poweredBy';
    }

    if (this.model.Style.BackgroundColor) {
      this.surveyBackground = this.model.Style.BackgroundColor;
    } else {
      this.surveyBackground = '';
    }

    if (this.model.Style.Name) {
      this.selectedTheme = this.model.Style.Name;
      this.isHeader = false;
      this.isTheme = true;
    } else {
      this.selectedTheme = '';
      this.isTheme = false;
    }

    if (this.model.Style.HeaderColor) {
      this.headerBackground = this.model.Style.HeaderColor;
      this.isHeader = true;
      this.isTheme = false;
      this.selectedTheme = '';
    } else {
      this.headerBackground = '';
      this.isHeader = false;
    }

    this.navigationColor = this.model.Style.NavigationColor;
    this.controlColor = this.model.Style.ControlsColor;
    this.nextQuestion = this._model;

    if (this.canvas) {
      this.canvas.model = this._model;
    }
  }
  private _model: any;

  @Input()
  get show(): boolean {
    return this._show;
  }
  set show(value) {
    this._show = value;
    this.isIn = this._show;
    this.isOut == !this._show;
  }
  private _show = false;

  @Input()
  get preview(): boolean {
    return this._preview;
  }
  set preview(value: boolean) {
    this._preview = value;
  }
  private _preview = false;

  @Input()
  get showJson(): boolean {
    return this._showJson;
  }
  set showJson(value: boolean) {
    this._showJson = value;
  }
  private _showJson = false;

  isTerminate(): string {
    if (this.nextQuestion && this.nextQuestion.Question) {
      if (DataComponent.getComponentType(this.nextQuestion.Question) === 14) {
        return this.getParameter("redirect");
      }
    }

    return "";
  }

  @Output()
  navigate: EventEmitter<this> = new EventEmitter<this>();

  @Output()
  changeLanguage: EventEmitter<string> = new EventEmitter<string>();

  languageChanged(canvas: any) {
    this.changeLanguage.emit(canvas.languageSelected);
  }

  registerOnNavigate(fn: any): void {
    this.onNavigate = fn;
  }

  onNavigate(element: any) {
    this.type = element.type;
    if (!this._preview) {
      this.answers = this.canvas?.answers ?? [];
    }
    this.navigate.emit(this);
  }

  showQuestion() {
    this.setQuestion(this._model);
    this.isIn = true;
    this.isOut = false;
    let temp = null;
    if (this.isTheme) {
      temp = this.themeHeader;
    }

    if (this.isHeader) {
      temp = this.customHeader;
    }

    temp?.nativeElement.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
        inline: "nearest"
      });
  }

  endQuestion() {
  }

  startEndQuestion() {

  }

  private getParameter(name: string): string {
    for (let i = 0; i < this.nextQuestion.Question.Properties.Items.length; i++) {
      if (this.nextQuestion.Question.Properties.Items[i].Name === name) {
        return this.nextQuestion.Question.Properties.Items[i].Value;
      }
    }

    return "";
  }


  private setQuestion(value: any) {
    Promise.resolve(null).then(() => {
      this.nextQuestion = value;
    });
  }

  private _gettingMedia: boolean = false;

  get media(): string | null {
    if (this._model && !this._gettingMedia) {
      this._gettingMedia = true;
      if (this._model.Style.Logo === 0) {
        this._media = '../../assets/logo.svg';
      }
      else if (this._model.Style.Logo > 0) {
        this.mediaService.getMediaById(this._model.Style.Logo).subscribe(
          result => { this._media = result; });
      }
    }

    return this._media;
  }
  set media(value: string) {
    this._gettingMedia = false;
  }
  private _media: string | null = null;
}

