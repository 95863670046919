<div>
  <div class="schdlr-timeZnPckr-div">
    <app-timezone-picker [guess]="true" [timezone]="timezone" (timezoneChange)="timezoneChanged($event)"></app-timezone-picker>
  </div>
  <div class="margin-10">
    <mat-tab-group [@.disabled]="true"
                   [selectedIndex]="tabIndex"
                   (selectedIndexChange)="onTabFocus($event)">
      <mat-tab>
        <ng-template mat-tab-label>Once</ng-template>
        <div class="fullHeight column-flex">
          <div class="padding-5" [formGroup]="onceForm">
            <app-advanced-timings label="" placeholder="Starts" (valueChange)="timeChanged($event)" [value]="start"></app-advanced-timings>
          </div>
        </div>
      </mat-tab>
      <mat-tab>
        <ng-template mat-tab-label>Hourly</ng-template>
        <div class="fullHeight column-flex">
          <div class="padding-5">
            <app-advanced-timings label="" placeholder="Starts" [(value)]="start"></app-advanced-timings>
            <span class="cron-form-label">Every </span>
            <app-time-picker [formGroup]="hourlyForm"
                             [use24HourTime]="options.use24HourTime"
                             [hideSeconds]="true">
            </app-time-picker>
          </div>
        </div>
      </mat-tab>
      <mat-tab>
        <ng-template mat-tab-label>Daily</ng-template>
        <div class="fullHeight column-flex">
          <div class="padding-5" [formGroup]="dailyForm">
            <app-advanced-timings label="" placeholder="Starts" (valueChange)="timeChanged($event)" [value]="start"></app-advanced-timings>
            <mat-radio-group formControlName="subTab">
              <mat-radio-button name="subTab"
                                value="everyDays"
                                checked="checked" class="display-flex">
                <div class="schdlr-dly-form-field-container" formGroupName="everyDays">
                  <div class="schdlr-dly-form-field-content">
                    &nbsp;Every&nbsp;
                    <mat-form-field appearance="outline"
                               class="schdlr-dly-day-selector">
                      <mat-label>Day(s)</mat-label>
                      <mat-select formControlName="days">
                        <mat-option *ngFor="let monthDay of selectOptions.monthDays"
                                    [value]="monthDay">
                          {{monthDay}}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                  <div class="schdlr-dly-form-field-content">
                    &nbsp;at&nbsp;
                    <mat-form-field appearance="outline" class="schdlr-dly-time-picker">
                      <input matInput [ngxTimepicker]="dailyPicker" formControlName="at">
                      <ngx-material-timepicker #dailyPicker></ngx-material-timepicker>
                    </mat-form-field>
                  </div>
                </div>
              </mat-radio-button>

              <mat-radio-button name="subTab"
                                value="everyWeekDay" class="display-flex">
                <div class="schdlr-dly-form-field-container" formGroupName="everyWeekDay">
                  <div class="pt-10">
                    &nbsp;Week Day (MON-FRI) at&nbsp;
                    <mat-form-field appearance="outline" class="schdlr-dly-time-picker">
                      <input matInput [ngxTimepicker]="dailyPicker2" formControlName="at">
                      <ngx-material-timepicker #dailyPicker2></ngx-material-timepicker>
                    </mat-form-field>
                  </div>
                </div>
              </mat-radio-button>
              <mat-radio-button name="subTab"
                                value="hourly" class="display-flex">
                <div class="schdlr-dly-form-field-container">
                  <div class="pt-10">
                    <span class="cron-form-label">Every </span>
                    <app-time-picker formGroupName="every"
                                     [use24HourTime]="options.use24HourTime"
                                     [hideSeconds]="true">
                    </app-time-picker>
                    <span class="cron-form-label">&nbsp;for&nbsp;</span>
                    <app-time-picker formGroupName="until"
                                     [use24HourTime]="options.use24HourTime"
                                     [hideMinutes]="true"
                                     [hideSeconds]="true">
                    </app-time-picker>
                  </div>
                </div>
              </mat-radio-button>

            </mat-radio-group>
          </div>
        </div>
      </mat-tab>
      <mat-tab>
        <ng-template mat-tab-label>Weekly</ng-template>
        <div class="fullHeight column-flex">
          <div class="padding-5" [formGroup]="weeklyForm">
            <app-advanced-timings label="" placeholder="Starts" (valueChange)="timeChanged($event)" [value]="start"></app-advanced-timings>
            <span class="cron-form-label">Every </span>
            <div [formGroup]="weeklyForm" class="margin-10">
              <mat-checkbox class="checkbox-margin" formControlName="MON">
                <span class="schdlr-wkly-every-span">Monday</span>
              </mat-checkbox>
              <mat-checkbox class="checkbox-margin" formControlName="TUE">
                <span class="schdlr-wkly-every-span">Tuesday</span>
              </mat-checkbox>
              <mat-checkbox class="checkbox-margin" formControlName="WED">
                <span class="schdlr-wkly-every-span">Wednesday</span>
              </mat-checkbox>
              <mat-checkbox class="checkbox-margin" formControlName="THU">
                <span class="schdlr-wkly-every-span">Thursday</span>
              </mat-checkbox>
              <mat-checkbox class="checkbox-margin" formControlName="FRI">
                <span class="schdlr-wkly-every-span">Friday</span>
              </mat-checkbox>
              <mat-checkbox class="checkbox-margin" formControlName="SAT">
                <span class="schdlr-wkly-every-span">Saturday</span>
              </mat-checkbox>
              <mat-checkbox class="checkbox-margin" formControlName="SUN">
                <span class="schdlr-wkly-every-span">Sunday</span>
              </mat-checkbox>
            </div>
            <mat-radio-group formControlName="subTab">
              <mat-radio-button name="subTab"
                                value="attime"
                                checked="checked" class="display-flex">
                <div class="schdlr-dly-form-field-container">
                  <div class="schdlr-dly-form-field-content">
                    <span class="cron-form-label">at time </span>
                    <mat-form-field appearance="outline" class="schdlr-dly-time-picker">
                      <input matInput [ngxTimepicker]="weeklyPicker" formControlName="at">
                      <ngx-material-timepicker #weeklyPicker></ngx-material-timepicker>
                    </mat-form-field>
                  </div>
                </div>
              </mat-radio-button>
              <mat-radio-button name="subTab"
                                value="everytime">
                <div class="schdlr-dly-form-field-container">
                  <div class="pt-10">
                    <span class="cron-form-label">Every </span>
                    <app-time-picker formGroupName="every"
                                     [use24HourTime]="options.use24HourTime"
                                     [hideSeconds]="true">
                    </app-time-picker>
                    <span class="cron-form-label">&nbsp;for&nbsp;</span>
                    <app-time-picker formGroupName="until"
                                     [use24HourTime]="options.use24HourTime"
                                     [hideMinutes]="true"
                                     [hideSeconds]="true">
                    </app-time-picker>
                  </div>
                </div>
              </mat-radio-button>
            </mat-radio-group>
          </div>
        </div>
      </mat-tab>
      <mat-tab>
        <ng-template mat-tab-label>Monthly</ng-template>
        <div class="fullHeight column-flex">
          <div class="padding-5" [formGroup]="monthlyForm">
            <app-advanced-timings label="" placeholder="Starts" (valueChange)="timeChanged($event)" [value]="start"></app-advanced-timings>
            <mat-radio-group formControlName="subTab">
              <mat-radio-button name="monthly-radio"
                                value="specificDay"
                                class="margin-10">
                <!-- Spesific day -->
                <div formGroupName="specificDay"
                     class="schdlr-mnthly-spDay-container">
                  <div>
                    &nbsp;On the&nbsp;

                    <ng-container *ngIf="options.cronFlavor === 'quartz'">
                      <mat-form-field appearance="outline"
                                      class="schdlr-mnthly-spDay-formField">
                        <mat-label>Day</mat-label>
                        <mat-select class="month-days" formControlName="day">
                          <mat-option *ngFor="let monthDaysWithLast of selectOptions.monthDaysWithLasts"
                                      [value]="monthDaysWithLast">
                            {{monthDayDisplay(monthDaysWithLast)}}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </ng-container>
                    <ng-container *ngIf="options.cronFlavor === 'standard'">
                      <mat-form-field appearance="outline"
                                      class="schdlr-mnthly-spDay-formField">
                        <mat-label>Day</mat-label>
                        <mat-select class="month-days" formControlName="day">
                          <mat-option *ngFor="let monthDaysWithOutLast of selectOptions.monthDaysWithOutLasts"
                                      [value]="monthDaysWithOutLast">
                            {{monthDayDisplay(monthDaysWithOutLast)}}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </ng-container>

                    &nbsp;of every&nbsp;

                    <mat-form-field appearance="outline"
                                    class="schdlr-mnthly-spDay-formField">
                      <mat-label>Month</mat-label>
                      <mat-select class="months-small" formControlName="months">
                        <mat-option *ngFor="let month of selectOptions.months"
                                    [value]="month">
                          {{month}}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                  <div>
                    &nbsp;at time&nbsp;

                    <mat-form-field appearance="outline" class="schdlr-dly-time-picker">
                      <input matInput [ngxTimepicker]="monthlyPicker">
                      <ngx-material-timepicker #monthlyPicker></ngx-material-timepicker>
                    </mat-form-field>
                  </div>
                </div>
              </mat-radio-button>
              <br />
              <mat-radio-button name="monthly-radio"
                                value="specificWeekDay"
                                class="margin-10">
                <!-- Spesific Week day -->
                <div formGroupName="specificWeekDay"
                     class="schdlr-mnthly-spDay-container">
                  <div>
                    &nbsp;On the&nbsp;

                    <mat-form-field appearance="outline"
                                    class="schdlr-mnthly-spWkday-formField">
                      <mat-label>Week</mat-label>
                      <mat-select class="day-order-in-month"
                                  formControlName="monthWeek">
                        <mat-option *ngFor="let monthWeek of selectOptions.monthWeeks"
                                    [value]="monthWeek">
                          {{monthWeekDisplay(monthWeek)}}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                    &nbsp;
                    <mat-form-field appearance="outline"
                                    class="schdlr-mnthly-spDay-formField">
                      <mat-label>Day</mat-label>
                      <mat-select class="week-days" formControlName="day">
                        <mat-option *ngFor="let day of selectOptions.days"
                                    [value]="day">
                          {{dayDisplay(day)}}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>

                    &nbsp;of every&nbsp;

                    <mat-form-field appearance="outline"
                                    class="schdlr-mnthly-spDay-formField">
                      <mat-label>Month</mat-label>
                      <mat-select class="months-small" formControlName="months">
                        <mat-option *ngFor="let month of selectOptions.months"
                                    [value]="month">
                          {{month}}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                  <div>
                    &nbsp;at time&nbsp;
                    <mat-form-field appearance="outline" class="schdlr-dly-time-picker">
                      <input matInput [ngxTimepicker]="monthlyTimePicker">
                      <ngx-material-timepicker #monthlyTimePicker></ngx-material-timepicker>
                    </mat-form-field>
                  </div>
                </div>
              </mat-radio-button>
            </mat-radio-group>
          </div>
        </div>
      </mat-tab>
    </mat-tab-group>
    <mat-divider></mat-divider>
    <div class="schdlr-advanced-timings display-flex align-start-end-flex">
      <app-advanced-timings label="" placeholder="Ends" [(value)]="end"></app-advanced-timings>
    </div>
    <div *ngIf="isDIYTestersUser">
      <h3>
        Expression: {{start | date:'short': '' : translate.currentLang }} - {{end | date:'short': '' : translate.currentLang }} {{ cronDescription }}
      </h3>
    </div>
  </div>
</div>
