import { Component, OnInit, Inject, ViewChild, ElementRef, EventEmitter, Output, Input, OnDestroy } from '@angular/core';
import { FormGroup, FormControl, Validators, FormArray, FormBuilder } from '@angular/forms';
import { CintService, CintCountry, CintQuestion, CintCategory, CintFeasibility, CintQuotaGroup, CintQuota, CintTargetGroup, CintFullQuote, CintDefaultSettings } from '../cint.Service';
import { Observable, Subscription, timer } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Category } from "../category-selector/category-selector.component";
import { RangeBox } from "../range-box/range-box.component";
import { AuthenticationService } from '../authentication.Service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CategoryListComponent } from './category-list/category-list.component';
import { AudienceComponent } from './audience/audience.component';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'panel-selector',
  templateUrl: './panel-selector.component.html',
  styleUrls: ['./panel-selector.component.css']
})
export class PanelSelectorComponent implements OnInit, OnDestroy {
  formGroup: FormGroup = new FormGroup({});
  countries: CintCountry[] = [];
  tempCountries?: Observable<CintCountry[]>;
  cintCategories: CintCategory[] = [];
  selectedCintCategories: CintCategory[] = [];
  tempCategories: Observable<CintCategory[]> = new Observable<any>();
  selectedCountry = "";

  private inTimer = false;
  private feasibilityLoaded = false;
  private savedFeasibility = "";
  waiting?: Observable<any>;
  waitTime: Observable<any> = timer(0, 1000);
  subscription: Subscription;

  categories: Category[] = [];
  useRanges: boolean = false;
  selectedCategories: Category[] = [];
  selectedQuestion: CintQuestion | null = null;
  quotaOn = false;
  total: number = 0;
  reportedQuestion: CintQuestion | null = null;
  reportedCategories: Category[] = [];
  reportedIds: number[] = [];
  defaultSettings: CintDefaultSettings = new CintDefaultSettings();
  questionCount: number = 0;
  isAdmin = false;
  isLoaded = false;
  fullQuote: CintFullQuote = new CintFullQuote();

  selections: Map<number, Category[]> = new Map<number, Category[]>();

  currentCategory: number = -1;
  currentQuestion: number = -1;

  @ViewChild('stepper') public stepper: any;
  @ViewChild('categoryList') public categoryList: CategoryListComponent | undefined;
  @ViewChild('audience') public audience: AudienceComponent | undefined;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    private snackbar: MatSnackBar,
    private dialogRef: MatDialogRef<PanelSelectorComponent>,
    private cintService: CintService,
    private authService: AuthenticationService,
    private formBuilder: FormBuilder) {
    this.isAdmin = this.authService.isAuthorized(['Admin']);
    this.inTimer = true;
    this.cintService.defaultSettings().subscribe(
      result => {
        this.defaultSettings = result;
        if (this.formGroup) {
          this.formGroup.controls.ir.setValue(this.defaultSettings.Ir);
          this.formGroup.controls.loi.setValue(this.defaultSettings.MinimumLoi);
        }
        this.tempCountries = this.cintService.getCountries().pipe(tap<CintCountry[]>(
          result => {
            this.countries = result;
            this.questionCount = data.questionCount;
            this.quota = data.quota;
            this.inTimer = false;
          }));
      });
    /*this.subscription = this.waitTime.subscribe(() => {
      if (this.inTimer || !this.isLoaded || !this.feasibilityLoaded) {
        return;
      }

      this.inTimer = true;
      this.checkFeasibility();
      this.inTimer = false;
    });*/
  }

  ngOnDestroy(): void {
    //this.subscription.unsubscribe();
  }

  ngOnInit(): void {
    this.initForm();
  }

  initForm() {
    this.formGroup = new FormGroup({
      country: new FormControl(),
      catList: new FormControl(),
      quotaSelected: new FormControl(),
      quotaList: this.formBuilder.array([this.createItem(0)]),
      rangeList: this.formBuilder.array([this.createRangeItem(new RangeBox(0, 0))]),
      needed: new FormControl(),
      ir: new FormControl(),
      loi: new FormControl(),
      cost: new FormControl({ value: '{NA}', disabled: true }),
      available: new FormControl('0')
    });
    this.rangeList().clear();
    this.quotaList().clear();
    if (this.quota) {
      this.formGroup.controls.country.setValue(this.quota.CountryId);
    }

    this.formGroup.controls.needed.setValue(100);
    if (this.defaultSettings) {
      this.formGroup.controls.ir.setValue(this.defaultSettings.Ir);
      this.formGroup.controls.loi.setValue(this.defaultSettings.MinimumLoi);
    }
    else {
      this.formGroup.controls.ir.setValue(80);
      this.formGroup.controls.loi.setValue(5);
    }

    this.formGroup.controls.needed.valueChanges.subscribe(result => {

    })
    this.subscription = this.formGroup.valueChanges.subscribe(result => {
      let a = result;
    })

    // this.loadFromFeasibility(this._quota);
  }

  createItem(percent: number): FormGroup {
    return this.formBuilder.group({
      percentage: new FormControl({ value: percent, disabled: !this.quotaOn })
    });
  }

  createRangeItem(r: RangeBox): FormGroup {
    return this.formBuilder.group({
      range: new FormControl(r)
    });
  }

  addRange($event: any) {
    if (this.selectedQuestion == null) {
      return;
    }

    this.categories.push(new Category(this.selectedQuestion.Id, this.selectedQuestion.Id, this.selectedQuestion.Name, false, 0, 99, 0));
    this.selectedCategories.push(new Category(this.selectedQuestion.Id, this.selectedQuestion.Id, this.selectedQuestion.Name, false, 0, 99, 0));
    this.rangeList().push(this.createRangeItem(new RangeBox(0, 99)));
    this.quotaList().push(this.createItem(0));
    this.distributeEvenly();
  }

  deleteAllRanges($event: any) {
    this.categories = [];
    this.selectedCategories = [];
    this.quotaList().clear();
    this.rangeList().clear();
    this.storeSelections();
  }

  resetStepper() {
    this.stepper.reset();
    this.categories = [];
    this.selectedCategories = [];
    this.quotaList().clear();
    this.rangeList().clear();
  }

  rangeList(): FormArray {
    return this.formGroup.get('rangeList') as FormArray;
  }

  onCountryChange($event: any) {
    this.selectedCountry = this.formGroup.controls['country'].value;
  }

  loaded($event: any) {
    this.cintCategories = this.categoryList?.categories ?? [];
    this.loadFromFeasibility(this.quota);
    this.isLoaded = true;
  }

  onQuotaChange(checked: any) {
    this.setQuota(checked.checked);
  }

  setQuota(checked: boolean) {
    this.quotaOn = checked;
    const items = this.quotaList();
    let i = 0;
    for (let i = 0; i < this.selectedCategories.length; i++) {
      let array = items.controls[i] as FormGroup;
      if (array != undefined) {
        if (this.quotaOn) {
          array.enable();
          array.controls.percentage.enable();
        } else {
          array.disable();
          array.controls.percentage.disable();
        }
      }
    }
  }

  quotaList(): FormArray {
    return this.formGroup.get('quotaList') as FormArray;
  }

  isSelected(i: number, j: number) {
    if (this.cintCategories[i].Questions[j].UseRanges) {
      return this.selections.has(this.cintCategories[i].Questions[j].Id);
    } else {
      if (this.selections.has(this.cintCategories[i].Questions[j].Id)) {
        let set = false;
        let sel = this.selections.get(this.cintCategories[i].Questions[j].Id);
        if (sel == undefined) {
          return false;
        }

        for (let i = 0; i < sel.length; i++) {
          if (sel[i].set) {
            return true;
          }
        }
      }

      return false;
    }
  }

  isSelectedCategory(i: number) {
    for (let j = 0; j < this.cintCategories[i].Questions.length; j++) {
      if (this.isSelected(i, j)) {
        return true;
      }
    }

    return false;
  }

  checkQuestion(id: number) {
    this.cintCategories.forEach(c => {
      c.Questions.forEach(q => {
        if (q.Id === id) {
          this.reportedQuestion = q;
        }
      });
    });
    return true;
  }

  checkCategories(): Category[] {
    if (this.reportedQuestion == null) {
      return [];
    }

    this.reportedCategories = this.selections.get(this.reportedQuestion.Id) ?? [];
    return this.reportedCategories;
  }

  distributeEvenlyAction() {
    this.distributeEvenly();
    this.storeSelections();
  }

  distributeEvenly() {
    let i = this.selectedCategories.length;
    const percent = Math.round(100 / i);
    const items = this.quotaList();
    let j = 0;
    let t = 0;
    this.selectedCategories.forEach(c => {
      let x = percent;
      if (j === i - 1) {
        x = 100 - t;
      }
      let fg = items.controls[j] as FormGroup;
      fg.controls.percentage.setValue(x);
      t += percent;
      j++;
    });
    this.total = 100;
  }

  onCloseRange($event: any, index: number) {
    this.categories.splice(index, 1);
    this.rangeList().removeAt(index);
    this.selectedCategories.splice(index, 1);
    this.quotaList().removeAt(index);
    this.distributeEvenly();
    this.storeSelections();
  }

  onChangeRange($event: any, index: number) {
    if ($event === null) {
      let a = 0;
      a = 1;
    }
    if ($event) {
      if ($event instanceof RangeBox) {
        console.info("Range change" + $event.minRange + "," + $event.maxRange);
        this.selectedCategories[index].range = $event;
        this.storeSelections();
      }
    } else {
      let b = 0;
      b = 1;
    }
  }

  onCategoryChange($event: any) {
    const temp: Category[] = this.formGroup.controls['catList'].value;
    const items = this.quotaList();
    const oldValues = this.selectedCategories;
    let j = 0;
    oldValues.forEach(c => {
      c.amount = this.percentValue(j);
      j++;
    });

    items.clear();
    this.selectedCategories = [];
    temp.forEach(c => {
      if (c.set) {
        this.selectedCategories.push(c);
        let amount = 0;
        oldValues.forEach(o => {
          if (o.name === c.name) amount = o.amount;
        });
        items.push(this.createItem(amount));
      }
    });
    if (!this.quotaOn) {
      this.distributeEvenly();
    }

    this.storeSelections();
  }

  findQuestion(id: number): CintQuestion | null {
    for (let i = 0; i < this.cintCategories.length; i++) {
      for (let j = 0; j < this.cintCategories[i].Questions.length; j++) {
        if (this.cintCategories[i].Questions[j].Id === id) {
          return this.cintCategories[i].Questions[j];
        }
      }
    }

    return null;
  }

  findQuestionByVariableId(id: number): CintQuestion | null {
    for (let i = 0; i < this.cintCategories.length; i++) {
      for (let j = 0; j < this.cintCategories[i].Questions.length; j++) {
        for (let k = 0; k < this.cintCategories[i].Questions[j].Variables.length; k++) {
          if (this.cintCategories[i].Questions[j].Variables[k].Id === id) {
            return this.cintCategories[i].Questions[j];
          }
        }
      }
    }

    return null;
  }

  selectQuestion(list: CategoryListComponent) {
    if (this.categoryList == null) {
      return;
    }

    this.selectQuestionIndex(this.categoryList.category, this.categoryList.question);
  }

  selectQuestionIndex(i: number, j: number) {
    if(this.currentQuestion > -1 && this.currentCategory > -1 ) {
      if (j !== this.currentQuestion || i !== this.currentCategory ) {
        this.storeSelections();
      }
    }
    const temp: Category[] = [];
    this.categories = [];
    this.selectedQuestion = this.cintCategories[i].Questions[j];
    this.selectedCategories = [];
    this.rangeList().clear();
    this.quotaList().clear();
    let amount = 0;
    let disitrbuteEvenly = true;
    if (this.selectedQuestion.UseRanges) {
      if (this.selections.has(this.selectedQuestion.Id)) {
        let sel = this.selections.get(this.selectedQuestion.Id);
        if (sel != undefined) {
          sel.forEach(c => {
            if (this.selectedQuestion != null) {
              const cat = new Category(this.selectedQuestion.Id, this.selectedQuestion.Id, this.selectedQuestion.Name, false, c.range.minRange, c.range.maxRange, c.amount);
              temp.push(cat);
              this.selectedCategories.push(cat);
            }

            this.rangeList().push(this.createRangeItem(new RangeBox(c.range.minRange, c.range.maxRange)));
            if (this.quotaList().length === 0) {
              amount = c.amount;
            }
            else {
              if (amount !== c.amount) {
                disitrbuteEvenly = false;
              }
            }

            this.quotaList().push(this.createItem(c.amount));
          });
        }
      } else {
        this.selectedQuestion.Ranges.forEach(range => {
          if (this.selectedQuestion != null) {
            const cat = new Category(this.selectedQuestion.Id, this.selectedQuestion.Id, this.selectedQuestion.Name, false, range.From, range.To, 0);
            temp.push(cat);
          }
        });
      }
      this.categories = temp;
      this.useRanges = true;
    } else {
      this.selectedQuestion.Variables.forEach(variable => {
        let selectedCat: Category | null = null;
        if (this.selectedQuestion != null) {
          if (this.selections.has(this.selectedQuestion.Id)) {
            let cs = this.selections.get(this.selectedQuestion.Id);
            let l = cs?.length ?? 0;
            if (cs != null) {
              for (let i = 0; i < l; i++) {
                if (cs[i].id == variable.Id) {
                  selectedCat = cs[i];
                  break;
                }
              }
            }
          }
        }
        if (selectedCat != null) {
          if (this.selectedQuestion != null) {
            const cat = new Category(this.selectedQuestion.Id, variable.Id, variable.Name, selectedCat.set, 0, 0, selectedCat.amount);
            if (selectedCat.set) {
              this.selectedCategories.push(cat);
              this.rangeList().push(this.createRangeItem(new RangeBox(selectedCat.range.minRange, selectedCat.range.maxRange)));
              if (this.quotaList().length === 0) {
                amount = selectedCat.amount;
              }
              else {
                if (amount !== selectedCat.amount) {
                  disitrbuteEvenly = false;
                }
              }

              this.quotaList().push(this.createItem(selectedCat.amount));
            }
            temp.push(cat);
          }
        } else {
          temp.push(new Category(this.selectedQuestion?.Id ?? 0, variable.Id, variable.Name, false, 0, 0, 0));
        }
      });
      this.categories = temp;
      this.useRanges = false;
      this.formGroup.controls['catList'].setValue(temp);
    }

    this.formGroup.controls.quotaSelected.setValue(!disitrbuteEvenly);
    this.setQuota(!disitrbuteEvenly);
    this.currentCategory = i;
    this.currentQuestion = j;
    this.checkTotal();
  }

  percentValue(index: number): number {
    let temp = this.quotaList();
    if (temp.controls.length > index) {
      let fc = temp.controls[index] as FormGroup;
      let i = fc.controls.percentage.value;
      return Number(i);
    }

    return 0;
  }

  rangeValue(index: number): RangeBox {
    let fc = this.rangeList().controls[index] as FormGroup;
    return fc.controls.range.value;
  }

  storeSelections() {
    for (let i = 0; i < this.selectedCategories.length; i++) {
      this.selectedCategories[i].amount = this.percentValue(i);
    }

    let storeCat: Category[] = [];
    if (this.selectedQuestion != null) {
      for (let i = 0; i < this.selectedCategories.length; i++) {
        let c = this.selectedCategories[i];
        storeCat.push(new Category(this.selectedQuestion?.Id ?? 0, c.id, c.name, c.set, c.range.minRange, c.range.maxRange, c.amount));
      }

      this.selections.set(this.selectedQuestion.Id, storeCat);
    }
    this.reportedIds = [];
    for (let k of this.selections.keys()) {
      this.reportedIds.push(k);
    }

    let model = this.loadFeasibilityModel();
    if (model != null) {
      this.checkFeasibility(model);
    }
  }

  distributionChange() {
    this.checkTotal();
    this.storeSelections();
  }

  checkTotal() {
    let j = 0;
    for (let i = 0; i < this.selectedCategories.length; i++) {
      j += this.percentValue(i);
    }

    this.total = j;
  }

  loadFromFeasibility(model: CintFeasibility | null) {
    try {
      if (model == null) {
        return;
      }

      if (model.CountryId) {
        if (this.formGroup) {
          this.formGroup.controls.country.setValue(model.CountryId.toString());
        }

        this.selectedCountry = model.CountryId.toString();
      }
      else {
        return;
      }

      if (model.Limit) {
        this.formGroup.controls.needed.setValue(model.Limit);
      }

      this.selections.clear();
      model.QuotaGroups.forEach(group => {
        let categories: Category[] = [];
        let question: CintQuestion | null = new CintQuestion(0, "", "", "");
        let i = 0;
        let limitTotal = 0;
        group.Quotas.forEach(quota => {
          i++;
          let limit = model.Limit / group.Quotas.length;
          if (i === group.Quotas.length && i > 1) {
            limit = model.Limit - limitTotal;
          }

          limitTotal += limit;
          let category;
          if (quota.TargetGroup.MinAge !== null && quota.TargetGroup.MinAge !== undefined) {
            // Add Age range to selections
            question = this.findQuestion(1);
            if (question != null) {
              category = new Category(1, 0, question.Name, true, quota.TargetGroup.MinAge, quota.TargetGroup.MaxAge, quota.Limit);
              categories.push(category);
            }
          }
          else if (quota.TargetGroup.Gender !== null && quota.TargetGroup.Gender !== undefined) {
            // Add Gender Definition to selections
            question = this.findQuestion(2);
            if (question != null) {
              category = new Category(2, quota.TargetGroup.Gender, quota.Name, true, 0, 0, quota.Limit);
              categories.push(category);
            }
          }
          else {
            let variableIds = quota.TargetGroup.VariableIds;
            if (variableIds === null || variableIds === undefined || variableIds.length === 0) {
              variableIds = quota.TargetGroup.RegionIds;
            }
            // Add variable settings to selections
            question = this.findQuestionByVariableId(variableIds[0]);
            if (question != null) {
              category = new Category(question.Id, variableIds[0], quota.Name, true, 0, 0, quota.Limit);
              categories.push(category);
            }
          }
        });

        this.selectedCategories = categories;
        this.selectedQuestion = question;
        let storeCat: Category[] = [];
        if (question != null) {
          this.selectedCategories.forEach(c => storeCat.push(new Category(question?.Id ?? 0, c.id, c.name, c.set, c.range.minRange, c.range.maxRange, c.amount)));
          this.selections.set(question.Id, storeCat);
        }
        this.reportedIds = [];
        for (let k of this.selections.keys()) {
          this.reportedIds.push(k);
        }
      });

      let isSelected = false;
      for (let i = 0; i < this.cintCategories.length && !isSelected; i++) {
        for (let j = 0; j < this.cintCategories[i].Questions.length && !isSelected; j++) {
          if (this.selections.has(this.cintCategories[i].Questions[j].Id)) {
            this.selectQuestionIndex(i, j);
            isSelected = true;
          }
        }
      }

      this.feasibilityLoaded = true;
      let temp = this.loadFeasibilityModel();
      if (temp == null) {
        return;
      }

      this.checkFeasibility(temp);
    }
    catch { }
  }

  loadFeasibilityModel(): CintFeasibility {
    const model = new CintFeasibility();
    const limit = Number(this.formGroup.controls.needed.value);
    model.IncidenceRate = Number(this.formGroup.controls.ir.value);
    if (this.questionCount === undefined) {
      this.questionCount = 5;
    }

    if (this.isAdmin) {
      model.LengthOfInterview = Number(this.formGroup.controls.loi.value);
    }
    else {
      if (this.defaultSettings) {
        model.LengthOfInterview = this.questionCount * (this.defaultSettings.QuestionLength / 60);
      }
      else {
        model.LengthOfInterview = this.questionCount * 30 / 60;
      }
    }

    if (this.defaultSettings) {
      if (model.LengthOfInterview < this.defaultSettings.MinimumLoi) {
        model.LengthOfInterview = this.defaultSettings.MinimumLoi;
      }
    }
    else {
      if (model.LengthOfInterview < 5) {
        model.LengthOfInterview = 5;
      }
    }

    let sels = Array.from(this.selections);
    for (let i = 0; i < sels.length; i++) {
      let categories = sels[i][1];
      const quotaGroup = new CintQuotaGroup();
      quotaGroup.Limit = 100;
      if (categories.length > 0) {
        const question = this.findQuestion(categories[0].questionId);
        if (question != null) {
          quotaGroup.Name = question.Name;
          let targetGroup;
          for (let j = 0; j < categories.length; j++) {
            let category = categories[j];
            let quota = new CintQuota();
            switch (question.Id) {
              case 1:
                targetGroup = new CintTargetGroup();
                quota.Name = question.Name + j;
                quota.Limit = category.amount;
                targetGroup.MinAge = category.range.minRange;
                targetGroup.MaxAge = category.range.maxRange;
                quota.TargetGroup = targetGroup;
                quotaGroup.Quotas.push(quota);
                break;
              case 2:
                targetGroup = new CintTargetGroup();
                quota.Name = category.name;
                quota.Limit = category.amount;
                targetGroup.Gender = category.id;
                quota.TargetGroup = targetGroup;
                quotaGroup.Quotas.push(quota);
                break;
              default:
                targetGroup = new CintTargetGroup();
                quota.Name = category.name;
                quota.Limit = category.amount;
                targetGroup.VariableIds = [];
                targetGroup.RegionIds = [];
                if (question.CategoryName === "Region") {
                  targetGroup.RegionIds.push(category.id);
                }
                else {
                  targetGroup.VariableIds.push(category.id);
                }

                quota.TargetGroup = targetGroup;
                quotaGroup.Quotas.push(quota);
                break;
            }
          }

          model.QuotaGroups.push(quotaGroup);
        }
      }
    }

    model.CountryId = Number(this.formGroup.controls.country.value);
    model.Limit = limit;
    model.FieldPeriod = 1;
    let temp = JSON.stringify(model);
    if (temp === this.savedFeasibility) {
      return null;
    }

    this.savedFeasibility = temp;
    this.fullQuote.Feasibility = model;
    this._quota = model;
    return model;
  }

  checkFeasibility(model: CintFeasibility) {
    this.cintService.checkFeasibility(model).subscribe(
      result => {
        this.fullQuote = result as CintFullQuote;

        this.formGroup.controls.available.setValue(this.fullQuote.Feasibility.FeasibilityCount);
        this.formGroup.controls.cost.setValue((this.fullQuote.Quote.Cpi.Amount * model.Limit).toFixed(2) + " " + this.fullQuote.Quote.Cpi.Currency);
      },
      error => {
        this.formGroup.controls.available.setValue(0);
        this.formGroup.controls.cost.setValue("{NA}");
      });
  }

  calculateLimit(target: number, percent: number) {
    return target / percent * 100;
  }

  get quota(): CintFeasibility | null {
    return this._quota;
  }
  set quota(value: CintFeasibility | null) {
    if (typeof value === "string") {
      try {
        this._quota = JSON.parse(value);
      }
      catch {
        this._quota = null;
      }
    }
    else {
      this._quota = value;
    }

    if (this._quota !== null && this._quota !== undefined) {
      if (this._quota.CountryId) {
        if (this.formGroup) {
          this.formGroup.controls.country.setValue(this._quota.CountryId.toString());
        }

        this.selectedCountry = this._quota.CountryId.toString();
      }

      return;
    }

    this._quota = new CintFeasibility();
    this._quota.Limit = 100;
  }
  private _quota: CintFeasibility | null = null;

  onConfirmedAction() {
    let model = this.loadFeasibilityModel();
    if (model == null) {
      this.dialogRef.close({ quota: this.fullQuote });
    }

    this.snackbar.open("Saving Panel");
    this.cintService.checkFeasibility(model).subscribe(
      result => {
        this.fullQuote = result as CintFullQuote;

        this.formGroup.controls.available.setValue(this.fullQuote.Feasibility.FeasibilityCount);
        this.formGroup.controls.cost.setValue((this.fullQuote.Quote.Cpi.Amount * model.Limit).toFixed(2) + " " + this.fullQuote.Quote.Cpi.Currency);
        this.snackbar.dismiss();
        this.dialogRef.close({ quota: this.fullQuote });
      },
      error => {
        this.formGroup.controls.available.setValue(0);
        this.formGroup.controls.cost.setValue("{NA}");
        this.snackbar.dismiss();
        this.dialogRef.close({ quota: this.fullQuote });
      });
  }
}
