<mat-form-field appearance="outline" class="timeZonePkr-form-field">
  <mat-label>Timezone</mat-label>
  <mat-select [(value)]="currentTimezone" (selectionChange)="selectionChanged()">
    <ng-template let-c ngFor [ngForOf]="allTimezones">
      <mat-optgroup *ngIf="c.zones.length > 1" [label]="c.iso | iso2CountryPipe">
        <mat-option *ngFor="let t of c.zones" [value]="t">
          {{c.iso | iso2CountryPipe}} - {{formatTimezoneString(t)}}
          <span *ngIf="showOffset">{{offsetOfTimezone(t)}}</span>
        </mat-option>
      </mat-optgroup>
      <mat-option *ngIf="c.zones.length === 1" [value]="c.zones[0]">
        {{c.iso | iso2CountryPipe}}
        <span *ngIf="showOffset">{{offsetOfTimezone(c.zones[0])}}</span>
      </mat-option>
    </ng-template>
  </mat-select>
</mat-form-field>
