<div class="dialog-title">
  <div *ngIf="message" class="leftFloat">
    <h3>{{message.Subject}}</h3>
  </div>
  <div *ngIf="message == null" class="leftFloat">
    <h3>New Message</h3>
  </div>
  <div class="rightFloat">
    <button mat-icon-button mat-dialog-close matTooltip="Close" matTooltipPosition="below">
      <mat-icon class="dialog-close-icon">close</mat-icon>
    </button>
  </div>
</div>
<div class="clearBoth"></div>
<div class="compose-msg-main-div">
  <div *ngIf="displayError">
    <div class="alert alert-danger" role="alert">
      {{errorMsg}}
    </div>
  </div>
  <div class="compose-msg-container">
    <div *ngIf="message" class="compose-msg-content-div">
      <div class="compose-msg-from-div">From: <strong>{{message.From.UserName}} ({{message.From.FirstName}} {{message.From.LastName}})</strong></div>
      <div class="compose-msg-divider"><mat-divider></mat-divider></div>
      <div #content class="compose-msg-content custom-scroll-bar" [style]="(reply === false && message != null) ? 'max-height: 50vh;':' max-height: 20vh; margin-bottom: 5px;'"
           [innerHTML]="message.Content | safe: 'html'"></div>
    </div>
    <div *ngIf="reply === false && message != null">
      <br />
      <button mat-raised-button color="primary" (click)="setupReply()" class="compose-msg-reply-btn">Reply</button>
    </div>
    <div *ngIf="message == undefined || reply">
      <form [formGroup]="formGroup">
        <div class="fullWidth">
          <app-user-search label="To" [users]="users"></app-user-search>
          <mat-form-field appearance="outline" class="fullWidth">
            <mat-label>Subject</mat-label>
            <input formControlName="subject" matInput placeholder="Message Subject">
          </mat-form-field>
        </div>
        <div class="fullWidth">
          <diy-editor channel="web" formControlName="contents"></diy-editor>
        </div>
        <div>
          <button mat-raised-button color="primary" (click)="onSubmit()" type="submit" [disabled]="formGroup.invalid" class="compose-msg-reply-btn">Send</button>
        </div>
      </form>
    </div>
  </div>
</div>
