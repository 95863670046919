import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { UserService, UserProfile } from "../user.Service";
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'app-find-user',
  templateUrl: './find-user.component.html',
  styleUrls: ['./find-user.component.css']
})
export class FindUserComponent implements OnInit {
  formGroup: FormGroup;
  tempUsers?: Observable<UserProfile[]>;
  users: UserProfile[] = [];
  list: string[] = [];

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<FindUserComponent>,
    private userService: UserService) {
    this.formGroup = new FormGroup({
      search: new FormControl('', Validators.required)
    });
  }


  ngOnInit(): void {
  }

  findUser($event: any) {
    this.tempUsers = this.userService.findUser(this.formGroup.value.search).pipe(tap<UserProfile[]>(result => {
      this.users = result;
      this.list = [];
      this.users.forEach(user => this.list.push("[" +
        user.UserID + ": " + user.UserName +
        "] " +
        user.FirstName +
        " " +
        user.LastName +
        " (" +
        user.EmailAddress +
        ")"));
    }));
  }

  selectUser($event: any, id: number) {
    this.dialogRef.close(this.users[id]);
  }
}
