<div class="fullWidth">
  Web hooks allow external services to be notified when certain events happen.
  When the specified events happen, we’ll send a POST request to each of the URLs you provide. Learn more in our <a href="https://apidocs.diysurveys.com/callbacks" target="_blank">Web Hooks Guide</a>.
</div>
<div>
  <div class="users-header-content">
    <div>
      <button mat-raised-button color="primary" (click)="newWebHook()">Create New Web Hook</button>&nbsp;&nbsp;
    </div>
  </div>
  <div *ngIf="tempWebHooks | async; else loading">
    <table *ngIf="WebHooks.length > 0" mat-table [dataSource]="dataSource">
      <ng-container matColumnDef="Name">
        <th mat-header-cell *matHeaderCellDef> Name </th>
        <td mat-cell *matCellDef="let element"> <strong>{{element.Tag}}</strong> - {{element.PayloadUrl}} </td>
      </ng-container>
      <ng-container matColumnDef="TypeList">
        <th mat-header-cell *matHeaderCellDef> </th>
        <td mat-cell *matCellDef="let element"> {{getHookTypeList(element.WebHookTypeList)}} </td>
      </ng-container>
      <ng-container matColumnDef="Created">
        <th mat-header-cell *matHeaderCellDef> Created </th>
        <td mat-cell *matCellDef="let element"> {{element.Created  | date:'short' : '' : translate.currentLang}} </td>
      </ng-container>

      <ng-container matColumnDef="Amended">
        <th mat-header-cell *matHeaderCellDef> Modified </th>
        <td mat-cell *matCellDef="let element"> {{element.Amended  | date:'short' : '' : translate.currentLang}} </td>
      </ng-container>

      <!-- Action column -->
      <ng-container matColumnDef="Action">
        <th mat-header-cell *matHeaderCellDef mat-sort-header></th>
        <td mat-cell *matCellDef="let element">
          <div>
            <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="icon-button with a menu" (click)="$event.stopPropagation()">
              <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu #menu="matMenu">
              <button mat-menu-item (click)="edit(element)">
                <mat-icon>edit</mat-icon>
                <span>Edit</span>
              </button>
              <mat-divider></mat-divider>
              <button mat-menu-item (click)="deleteHook(element)">
                <mat-icon>delete</mat-icon>
                <span>Delete</span>
              </button>
            </mat-menu>
          </div>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr mat-row class="users-element-row" *matRowDef="let row; columns: displayedColumns;" (click)="edit(row)"></tr>
    </table>
  </div>
</div>

<ng-template #loading>
  <div class="loading-container">
    <span class="loading-content">
      <div class="spinner-div"><mat-spinner [diameter]="25"></mat-spinner></div>
      <div class="spinner-text"> Loading Web Hooks...</div>
    </span>
  </div>
  <br /><br />
</ng-template>



