<div class="column-flex align-center-flex auto-flex">
  <div class="txt-align-center">
    <div class="step-3-scheduleType-content">
      <mat-label>Survey will go live: </mat-label>
      <mat-button-toggle-group aria-label="Schedule Type" [value]="scheduleType">
        <mat-button-toggle value="now" (click)="selectNow()">Now</mat-button-toggle>
        <mat-button-toggle value="cron" (click)="openScheduler()">Later</mat-button-toggle>
      </mat-button-toggle-group>
    </div>
  </div>
  <div *ngIf="!displayScheduler" class="margin-10">
    <div *ngIf="survey && survey.JobMaster" class="comm-edtr-diyEdtr-container">
      <app-advanced-timings label="Ends" [(value)]="survey.JobMaster.End"></app-advanced-timings>
    </div>
  </div>
  <div *ngIf="displayScheduler" class="margin-10">
    <div class="surv-schedular-scheduler-div">
      <app-scheduler [schedule]="schedule" (scheduleChange)="scheduleChanged($event)"></app-scheduler>
    </div>
  </div>
  <mat-accordion class="surv-schedular-accordion">
    <mat-expansion-panel hideToggle>
      <mat-expansion-panel-header>
        <mat-panel-title>
          More Options
        </mat-panel-title>
        <mat-panel-description>
        </mat-panel-description>
      </mat-expansion-panel-header>
      <div>
        <h3>Invite Windows</h3>
        <p>
          Invite Windows allow you to control (within the schedule) when invites can go out. This is particularly useful when you
          have SMS going out that take time to send and you only want them to go out within specific windows of time so that
          it does not annoy the recipients. Use multiple windows to refine the times for different days and/or multiple times on the same day.<br /><br />
          Note: For invites using mobile numbers, all times are adjusted based on the destination country of the message being sent.
        </p>
        <app-window #messageWindows (onChange)="onWindowChange($event)" [model]="windows"></app-window>
      </div>
    </mat-expansion-panel>
  </mat-accordion>
 </div>
