import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatButtonToggleChange } from '@angular/material/button-toggle';
import { CommsModel, CommsService } from "../comms.Service";
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'app-communications',
  templateUrl: './communications.component.html',
  styleUrls: ['./communications.component.css']
})
export class CommunicationsComponent implements OnInit {
  term: string = "";
  toggle: string = "card";
  tempComms?: Observable<CommsModel[]>;
  comms: CommsModel[] = [];
  filter: string = "";

  constructor(public dialog: MatDialog, private commsService: CommsService) {
  }

  toggleView(change: MatButtonToggleChange) {
    this.toggle = change.value;
  }

  ngOnInit(): void {
      this.tempComms = this.commsService.getComms().pipe(tap<CommsModel[]>(result => {
        this.comms = result;
      }));
  }
}


