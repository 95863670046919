<ngx-charts-gauge [animations]="false"
                          [view]="view"
                  [scheme]="colorScheme"
                  [results]="results"
                  [legend]="legend"
                  [legendPosition]="legendPosition"
                  (select)="onSelect($event)"
                  (activate)="onActivate($event)"
                  (deactivate)="onDeactivate($event)">
</ngx-charts-gauge>

