import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { AuthenticationService } from '../authentication.Service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css']
})
export class ForgotPasswordComponent implements OnInit {
  formGroup: FormGroup;

  errorMsg: string;
  displayError: boolean;
  displaySuccess: boolean;

  constructor(private authService: AuthenticationService) {
    this.errorMsg = "";
    this.displayError = false;
    this.displaySuccess = false;
    this.formGroup = new FormGroup({
      username: new FormControl("", [Validators.required])
    });
  }

  ngOnInit(): void {
  }

  onSubmit() {
    if (this.formGroup.valid) {
      this.authService.requestResetPassword(this.formGroup.value.username)
        .subscribe(result => {
            this.displayError = false;
            this.displaySuccess = true;
          },
          error => {
            console.error(error);
            this.errorMsg = "There was a problem producing your reset email";
            this.displayError = true;
          });
    }
  }

}
