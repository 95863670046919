<div *ngIf="preview" matRipple>
  <app-advanced-properties [model]="model"></app-advanced-properties>
  <div class="question-var-container" (click)="click()">
    <div class="column-flex auto-flex fullWidth">
      <div class="ml-15 auto-flex" *ngIf="model.Banner !== ''">[{{ model.QuestionName }}]</div>
      <div class="auto-flex">
        <diy-player #canvas [show]="show" [model]="previewModel" [showJson]="false" [preview]="true"></diy-player>
      </div>
    </div>
    <div class="question-icon">
      <mat-icon>group_work</mat-icon>
    </div>
  </div>
  <div class="question-separator">
  </div>
</div>
<div *ngIf="!preview" [formGroup]="bucketFormGroup">
  <div formArrayName="bucketFormArray">
    <app-add-question-button action="before" (doAction)="questionAction($event)"></app-add-question-button>
    <app-advanced-properties [model]="model"></app-advanced-properties>
    <div *ngFor="let opt of questionArr?.controls; let i = index" [formGroupName]="i">
      <div class="question-var-container" (click)="click()">
        <mat-form-field appearance="outline">
          <mat-label>Question Identity</mat-label>
          <input matInput formControlName="qNumber" [patterns]="customPatterns" mask="SA*">
        </mat-form-field>
        <div class="question-icon">
          <div class="question-copy-btn-div">
            <button mat-mini-fab color="accent" matTooltip="Copy Question" (click)="questionAction('copy')"><mat-icon>content_copy</mat-icon></button>
          </div>
          <div>
            <button mat-mini-fab color="accent" matTooltip="Delete Question" (click)="questionAction('delete')"><mat-icon>delete</mat-icon></button>&nbsp;
          </div>
          <div class="question-type-icon-div">
            <mat-icon>group_work</mat-icon>
          </div>
        </div>
      </div>
      <div>
        <diy-editor [channel]="model.Interview.Channel" [questions]="model.Interview.reportableQuestions()" formControlName="banner"></diy-editor>
      </div>
      <div class="column-flex">
        <div class="margin-top-bot-5 fullWidth column-flex">
          <app-answer-options [model]="model" [useParameters]="true" label="Column" title="Columns" #buckets></app-answer-options>
        </div>
        <div class="margin-top-bot-5 fullWidth column-flex">
          <app-answer-options [model]="model" label="Option" title="Options" #options></app-answer-options>
        </div>
      </div>
      <app-add-question-button action="after" (doAction)="questionAction($event)"></app-add-question-button>
    </div>
  </div>
