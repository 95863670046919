<div class="user-details-dialog-container">
  <div class="leftFloat">
    <h3>Import Languages Translations</h3>
  </div>
  <div class="rightFloat">
    <button mat-icon-button (click)="close()">
      <mat-icon>close</mat-icon>
    </button>
  </div>
</div>
<div class="leftFloat">
  <div class="mt-5 mb-5">
    <app-file-upload #scriptUpload accept=".xlsx" text="Import Language Translations" (uploaded)="onFileUploaded($event)"></app-file-upload>
  </div>
</div>
<div class="outer-table-container mat-elevation-z8 clearBoth">
</div>
