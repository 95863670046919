<div *ngIf="process">
  {{process.Name}}
  <span class="upload">
    {{process.Progress}}%
    <mat-progress-bar mode="determinate" [value]="process.Progress"></mat-progress-bar>
  </span>
  {{process.Message}}
  <div *ngIf="showErrors && errors?.length > 0">
    <div *ngFor="let error in errors">
      ({{error.Col}} {{error.Message}}
    </div>
  </div>
</div>
