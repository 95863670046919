import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { FileTransferModel, ProcessService } from '../../process.Service';

@Component({
  selector: 'app-ftp-setup',
  templateUrl: './ftp-setup.component.html',
  styleUrls: ['./ftp-setup.component.css']
})
export class FtpSetupComponent implements OnInit {

  testing: boolean = false;
  errors: string[] = [];
  successes: string[] = [];
  formGroup: FormGroup = new FormGroup({});

  constructor(private service: ProcessService) { }

  ngOnInit(): void {
    this.formGroup = new FormGroup({
      Host: new FormControl(this.fileTransfer.Server),
      Port: new FormControl(this.fileTransfer.Port),
      Username: new FormControl(this.fileTransfer.Username),
      Password: new FormControl(this.fileTransfer.Password),
      Folder: new FormControl(this.fileTransfer.ServerFolder),
      File: new FormControl(this.fileTransfer.Pattern),
      Passive: new FormControl(this.fileTransfer.Passive)
    });

    this.formGroup.valueChanges.subscribe(result => {
      this.fileTransfer.Server = this.formGroup.controls.Host.value;
      this.fileTransfer.Port = this.formGroup.controls.Port.value;
      this.fileTransfer.Username = this.formGroup.controls.Username.value;
      this.fileTransfer.Password = this.formGroup.controls.Password.value;
      this.fileTransfer.ServerFolder = this.formGroup.controls.Folder.value;
      this.fileTransfer.Pattern = this.formGroup.controls.File.value;
      this.fileTransfer.Passive = this.formGroup.controls.Passive.value;
    })
  }

  @Input()
  get fileTransfer(): FileTransferModel {
    return this._fileTransfer;
  }
  set fileTransfer(value: FileTransferModel) {
    this._fileTransfer = value;
  }
  private _fileTransfer: FileTransferModel = new FileTransferModel();

  refresh() {
    if (this.formGroup) {
      this.formGroup.controls.Port.setValue(this._fileTransfer.Port);
    }
  }

  testService() {
    this.testing = true;
    this.successes = [];
    this.errors = [];
    this.service.testFTP(this._fileTransfer).subscribe(
      result => {
        this.successes = result;
        this.testing = false;
        let a = result;
      },
      error => {
        this.testing = false;
        this.errors = error.error;
      }
    )
  }
}
