<div *ngIf="preview == 0 && comm" class="display-inline">
  <div class="row-flex-flow margin-5">
    <div class="comm-edtr-name-container">
      <div><strong>{{comm.Name}}</strong></div>
      <div class="fieldRow">
        <div>
          Modified: {{comm.LastUpdatedDate | date:'shortDate': '' : translate.currentLang}} {{comm.LastUpdatedDate | date:'mediumTime': '' : translate.currentLang}}
        </div>
        <div *ngIf="isWhatsApp() && isLocked()">
          <button mat-mini-fab color="warning" matTooltip="Locked for 24 hours since last modified"><mat-icon>lock_clock</mat-icon></button>
        </div>
      </div>
    </div>
    <div class="column-flex-flow">
      <div class="row-flex-flow">
        <div class="comm-edtr-type-container">
          <strong>{{getType(comm.EmailType)?.name}}</strong><br/>
          <span *ngIf="isWhatsApp()">{{template.template.status == '' ? 'NEW' : template.template.status}}</span>
        </div>
      </div>
      <div class="row-flex-flow">
        <div class="comm-edtr-space-container">
          &nbsp;
        </div>
        <div class="row-flex-flow comm-edtr-space-container">
          <div class="comm-edtr-btn-container">
            <button mat-mini-fab color="accent" [matTooltip]="isLocked() ? 'View Communication' : 'Edit Communication'" (click)="editComm(1)"><mat-icon>{{ isLocked() ? 'preview' : 'edit'}}'</mat-icon></button>
          </div>
          <div *ngIf="isWhatsApp()" class="comm-edtr-btn-container">
            <button mat-mini-fab color="accent" matTooltip="Copy Communication" (click)="editComm(2)"><mat-icon>content_copy</mat-icon></button>
          </div>
          <div class="comm-edtr-btn-container">
            <button mat-mini-fab color="accent" matTooltip="Delete Communication" (click)="editComm(3)"><mat-icon>delete</mat-icon></button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <mat-divider></mat-divider>
</div>
<div *ngIf="preview == 1 && comm && !isWhatsApp()" class="margin-5">
  <ng-container *ngTemplateOutlet="editorButtons"></ng-container>
  <div [formGroup]="formGroup">
    <div>
      <div>
        <h3>{{getType(comm.EmailType)?.name}}</h3>
      </div>
      <div>
        <mat-form-field appearance="outline" class="fullWidth">
          <mat-label>Template Name</mat-label>
          <input matInput placeholder="Template Name" formControlName="Name">
        </mat-form-field>
      </div>
      <div>
        <mat-form-field appearance="outline" class="fullWidth">
          <mat-label>Description</mat-label>
          <input matInput placeholder="Description" formControlName="Description">
        </mat-form-field>
      </div>
      <div *ngIf="channel() == 'web'">
        <mat-form-field appearance="outline" class="fullWidth">
          <mat-label>Subject</mat-label>
          <input matInput placeholder="Subject" formControlName="Subject">
        </mat-form-field>
      </div>
    </div>
    <div class="comm-edtr-diyEdtr-container">
      <diy-editor [channel]="channel()" formControlName="EmailContent"></diy-editor>
    </div>
  </div>
  <ng-container *ngTemplateOutlet="editorButtons"></ng-container>
</div>
<div *ngIf="preview == 1 && comm && isWhatsApp()" class="margin-5">
  <ng-container *ngTemplateOutlet="editorButtons"></ng-container>
  <div [formGroup]="formWAGroup">
    <div>
      <div>
        <h3>{{getType(comm.EmailType)?.name}} ({{template.template.status == '' ? 'NEW' : template.template.status}})</h3>
      </div>
      <div>
        <mat-form-field appearance="outline" class="fullWidth">
          <mat-label>Template Name</mat-label>
          <input matInput placeholder="Template Name" [readonly]="!canEdit()" formControlName="Name" [patterns]="customPatterns" mask="SA*">
        </mat-form-field>
      </div>
      <div>
        <mat-form-field appearance="outline" class="fullWidth">
          <mat-label>Description</mat-label>
          <input matInput placeholder="Description" formControlName="Description">
        </mat-form-field>
      </div>
      <div class="row-flex-flow">
        <div>
          <mat-form-field appearance="outline" class="fullWidth">
            <mat-select formControlName="Language">
              <mat-option *ngFor="let language of languages" [value]="language.code">{{language.name}}</mat-option>
            </mat-select>
            <mat-label>Language</mat-label>
          </mat-form-field>
        </div>
        <div *ngIf="!canEdit()" class="ml-10">
          <mat-form-field appearance="outline" class="fullWidth">
            <mat-label>Status</mat-label>
            <input matInput placeholder="Status" [readonly]="true" formControlName="Status">
          </mat-form-field>
        </div>
      </div>
    </div>
    <div class="comm-edtr-diyEdtr-container">
      <diy-editor [channel]="channel()" formControlName="EmailContent"></diy-editor>
    </div>
  </div>
  <ng-container *ngTemplateOutlet="editorButtons"></ng-container>
</div>
<ng-template #editorButtons>
  <div class="simple-row">
    <div class="comm-edtr-divider-div">
      <mat-divider>
      </mat-divider>
    </div>
    <div *ngIf="isWhatsApp() && canEdit()">
      <button mat-mini-fab [matTooltip]="(template.template.status == '' ? 'Submit' : 'Resubmit') + ' for Approval'" (click)="submitComm()"><mat-icon>approval</mat-icon></button>
    </div>
    @if(isWhatsApp()) {
      <button mat-mini-fab matTooltip="Test" #menuTrigger="matMenuTrigger" [matMenuTriggerFor]="testMenu"><mat-icon>bug_report</mat-icon></button>
    }
      <mat-menu #testMenu="matMenu">
        <form (click)="$event.stopPropagation()">
          <div class="simple-row" [formGroup]="formSendGroup">
            <mat-form-field appearance="outline" style="padding:5px">
              <mat-label>Send To</mat-label>
              <input matInput type="text" placeholder="Send To" formControlName="Destination">
            </mat-form-field>
            <button mat-icon-button color="primary" (click)="testComm()">
              <mat-icon>send</mat-icon>
            </button>
          </div>
        </form>
      </mat-menu>
    <div *ngIf="!isLocked()">
      <button mat-mini-fab matTooltip="Save" (click)="saveComm()"><mat-icon>save</mat-icon></button>
    </div>
    <div>
      <button mat-mini-fab matTooltip="Close" (click)="cancel()"><mat-icon>close</mat-icon></button>
    </div>
    <div class="comm-edtr-divider-div">
      <mat-divider>
      </mat-divider>
    </div>
  </div>
</ng-template>
