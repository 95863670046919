import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormArray, FormGroup, Validators } from '@angular/forms';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { BaseQuestion, DataModel, InterviewService, ParameterModel, QuestionService } from '../../question.service';

@Component({
  selector: 'app-send-sms',
  templateUrl: 'send-sms.component.html',
  styleUrls: ['send-sms.component.css']
})
export class SendSmsComponent extends BaseQuestion implements OnInit {
  constructor(private breakpointObserver: BreakpointObserver, private qs: QuestionService, private is: InterviewService) {
    super(qs, is, undefined, undefined, new DataModel("sendSms"));
  }

  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
    .pipe(map(result => result.matches));


  public sendSMSFormGroup: FormGroup = new FormGroup({});

  ngOnInit() {
    this.sendSMSFormGroup = this.fb.group({
      sendSMSFormArray: this.fb.array([this.initQuestion()])
    });
    this.sendSMSFormGroup.valueChanges.subscribe(x => {
      let array = this.sendSMSFormGroup.controls.sendSMSFormArray as FormArray;
      let form = array.controls[0] as FormGroup;
      if (this.model.QuestionName !== form.controls.qNumber.value) {
        this.model.QuestionName = form.controls.qNumber.value;
        this.model.QuestionNameTouched = true;
      }
      this.model.Banner = form.controls.banner.value;
      this.model.Parameters = [];
      this.model.Parameters.push(this.createParameterModel("source", ""));
      this.model.Parameters.push(this.createParameterModel("destination", form.controls.destination.value));
    });
  }

  createParameterModel(name: string, value: string): ParameterModel {
    let model = new ParameterModel();
    model.Name = name;
    model.Value = value;
    return model;
  }

  get questionArr(): FormArray | null {
    if (this.sendSMSFormGroup) {
      return this.sendSMSFormGroup.get('sendSMSFormArray') as FormArray;
    }

    return null;
  }

  getParameter(name: string): string {
    for (let i = 0; i < this.model.Parameters.length; i++) {
      if (this.model.Parameters[i].Name === name) {
        return this.model.Parameters[i].Value;
      }
    }

    return "";
  }

  initQuestion() {
    return this.fb.group({
      qNumber: [this.model.QuestionName, Validators.required],
      source: [this.getParameter("source"), Validators.required],
      destination: [this.getParameter("destination"), Validators.required],
      banner: [this.model.Banner]
    });
  }
}
