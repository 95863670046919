import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { baseUrl, options } from "../environments/environment";
import { Observable, tap } from 'rxjs';
import { MediaService } from './media.Service';
import { ReportModel } from './question.service';
import { EstimateModel } from './panel.Service';
import { FinancialService, Payment, PaymentType, ReferenceType } from './finacial.Service';
import { JsonCyclic } from './utils';
import { MessageWindow, WindowModel } from './authentication.Service';

@Injectable()
export class SurveyService {
  constructor(private http: HttpClient) {
  }

  updateBasicDetails(model: SurveyModel): Observable<any> {
    return this.http.put(baseUrl + 'Survey/UpdateBasicDetails', model, options);
  }

  updateReportModel(id: number, model: ReportModel, updateTitle: boolean) {
    return this.http.put(baseUrl + 'Survey/UpdateReportModel?id=' + id + '&updateTitle=' + updateTitle, model, options);
  }

  createBlankSurvey(model: SurveyModel) {
    return this.http.post(baseUrl + 'Survey/CreateBlankSurvey', model, options);
  }

  getSurveyCategories(): Observable<any> {
    return this.http.get(baseUrl + 'Survey/GetSurveyCategories', options);
  }

  getSurveySummaryByCategory(category: string): Observable<any> {
    return this.http.get(baseUrl + 'Survey/GetSurveySummariesByCategory?id=' + category, options);
  }

  getSurveySummaries(): Observable<any> {
    return this.http.get(baseUrl + 'Survey/GetSurveySummaries', options);
  }

  getSurveyTotals(): Observable<number[]> {
    return this.http.get<number[]>(baseUrl + 'Survey/GetSurveyTotals', options);
  }

  getSurveySummariesForUser(userid: string): Observable<any> {
    return this.http.get(baseUrl + 'Survey/GetSurveySummariesForUser?userid=' + userid, options);
  }

  getSurveyCount(): Observable<any> {
    return this.http.get(baseUrl + 'Survey/GetSurveyCount', options);
  }

  getPagedSurveySummaries(status: number, searchKey: string, page: number, pageSize: number): Observable<SurveySummaryModel[]> {
    return this.http.post<SurveySummaryModel[]>(baseUrl + 'Survey/GetSurveySummariesPaged?status=' + status + '&page=' + page + '&pageSize=' + pageSize, JSON.stringify({ "Name": searchKey }), options);
  }

  getSurveySummariesForSystem(): Observable<any> {
    return this.http.get<SurveySummaryModel[]>(baseUrl + 'Survey/GetSurveySummariesForSystem', options);
  }

  getSurveySummary(id: number): Observable<any> {
    return this.http.get(baseUrl + 'Survey/GetSurveySummary?id=' + id, options);
  }

  getSurveyDetail(id: number): Observable<SurveyModel> {
    return this.http.get<SurveyModel>(baseUrl + 'Survey/GetSurveyDetail?id=' + id, options);
  }

  getSurveyDetailAdmin(id: number): Observable<SurveyModel> {
    return this.http.get<SurveyModel>(baseUrl + 'Survey/GetSurveyDetailAdmin?id=' + id, options);
  }

  checkSurvey(name: string, username: string): Observable<any> {
    return this.http.get<any>(baseUrl + 'Survey/CheckSurvey?name=' + name + "&username=" + username, options);
  }

  transferSurvey(id: number, name: string, username: string, orgname: string): Observable<any> {
    return this.http.get<any>(baseUrl + 'Survey/TransferSurvey?id=' + id + '&name=' + name + '&username=' + username + "&orgname=" + orgname, options);
  }

  deleteSurvey(id: number): Observable<any> {
    return this.http.delete(baseUrl + 'Survey/DeleteSurvey?SurveyIDs=' + id, options);
  }

  copySurvey(id: number): Observable<any> {
    return this.http.get(baseUrl + 'Survey/CopySurvey?surveyID=' + id, options);
  }

  copyTemplate(id: number): Observable<any> {
    return this.http.get(baseUrl + 'Survey/CopyTemplate?surveyID=' + id, options);
  }

  setPanel(surveyId: number, panelId: number) {
    this.http.patch(baseUrl + 'Survey/SetPanel?surveyId=' + surveyId + '&panelId=' + panelId, options).subscribe();
  }

  saveJobMaster(model: JobMasterModel): Observable<any> {
    return this.http.put<JobMasterModel>(baseUrl + 'Survey/UpdateJobMaster', model, options);
  }

  saveJobMasterForDistribution(model: JobMasterModel): Observable<DistributionModel> {
    return this.http.put<DistributionModel>(baseUrl + 'Survey/UpdateJobMasterForDistribution', model, options);
  }

  removeJobMaster(model: SurveyModel): Observable<any> {
    return this.http.patch(baseUrl + 'Survey/RemoveJobMaster', model, options);
  }

  getDeliveryStatsSinceBeginning(id: number): Observable<DeliveryItem[]> {
    return this.http.get<DeliveryItem[]>(baseUrl + 'Survey/GetDeliveryStatsFromTheBeginning?surveyId=' + id)
  }

  exportSurveys(userid: number): Observable<any> {
    return this.http.get<any>(baseUrl + 'Survey/ExportSurveys?userid=' + userid, options);
  }

  importSurveys(filename: string, selections: ImportProjectSelections, userid: number): Observable<any> {
    return this.http.post(baseUrl + 'Survey/ImportSurveys?filename=' + filename + '&userid=' + userid, selections, options);
  }

  makePayment(model: SurveyModel, estimate: EstimateModel) {
    let payment = new Payment();
    payment.EndingIn = 0;
    payment.Expires = new Date();
    payment.Description = "Payment for survey ";
    payment.TypeID = PaymentType.Credit;
    payment.BillingEmail = "";
    payment.AlternateBillingEmail = "";
    payment.CompanyName = "";
    payment.PurchaseOrderNumber = "";
    payment.Address = "";
    payment.Town = "";
    payment.County = "";
    payment.PostCode = "";
    payment.Country = "";
    payment.VatRegistrationNumber = "";
    payment.Amount = estimate.Total;
    payment.InvoiceLines = FinancialService.buildInvoiceLines(estimate);
    payment.Reference = model.SurveyID;
    payment.ReferenceTypeID = ReferenceType.Survey;
    return this.http.post(baseUrl + 'finance/ChargeBalanceForReference', payment, options);
  }

  makeLive(model: SurveyModel) {
    return this.http.put<SurveyModel>(baseUrl + 'Survey/MakeLive', model, options);
  }

  abandonSurvey(id: number) {
    return this.http.get<SurveyModel>(baseUrl + 'Survey/AbandonSurvey?id=' + id, options);
  }

  relaunchSurvey(id: number) {
    return this.http.get<SurveyModel>(baseUrl + 'Survey/RelaunchSurvey?id=' + id, options);
  }

  getAnonymousLink(id: number) {
    return this.http.get<string>(baseUrl + 'Survey/GetAnonymousLink?id=' + id, options);
  }

  getSurveyTesetLink(id: number): Observable<any> {
    return this.http.get<DistributionModel>(baseUrl + 'Survey/GetSurveyTestUrls?id=' + id, options);
  }
}

export class DistributionModel {
  Sid: string = "";
  TinyUrl: string = "";
  JobMasterId: number = 0;
}

export class PeriodStatusTotal {
  Period: string = "";
  Total: StatusTotal = new StatusTotal();
}

export class StatusTotal {
  Status: number = 0;
  Total: number = 0;
}

export class SurveyModel {
  SurveyID: number = 0;
  UserID: number = 0;
  Name: string = "";
  Description: string = "";
  Status: number = 1;
  Type: number = 0;
  MediaID: number = 0;
  ScriptID: number = 0;
  IsDeleted: boolean = false;
  IsOffline: boolean = false;
  RewardPoints: number = 0;
  IsCapi: boolean = false;
  CreatedDate: Date = new Date();
  LastUpdatedDate: Date = new Date();
  EstimatedTime: number = 0;
  Occurences: number = 0;
  DeadLine: Date = new Date();
  Link: string = "";
  ThirdParty: boolean = false;
  IsProfileSurvey: boolean = false;
  SurveyReference: string = "";
  IsLive: boolean = false;
  PanelID: number = 0;
  JobMaster: JobMasterModel | null = null;
  PanelSize: number = 0;
  Completed: number = 0;
  QuestionCount: number = 0;
  SearchTags: string = "";
  CintId: number = 0;
  Reference: PanellistReference = new PanellistReference();
  Sid: string = "";
  ReportModel: string = "";
  Channel: number = -1;
  Totals: StatusTotal[] = [];
  WindowId: number = 0;
  Windows: WindowModel[] | null = [];
}

export class SurveyReferenceModel {
  SurveyID: number = 0;
  Channel: number = 0;
  Script: string = "";
}

export class SurveySummaryModel {

  SurveyID: number = 0;
  UserID: number = 0;
  Name: string = "";
  Description: string = "";
  SurveyReference: string = "";
  SearchTags: string = "";
  Status: number = 0;
  Type: number = 0;
  Channel: number = 0;
  Created: Date = new Date();
  LastUpdated: Date = new Date();
  MediaID: number = 0;
  QuestionCount: number = 0;
  PanellistCount: number = 0;
  CompletionCount: number = 0;
  ReportModel: string = "";
  Script: string = "";
  Started: Date = new Date();
  Ended: Date = new Date();
  Launched: Date = new Date();
  RepeatSchedule: boolean = false;
  SurveyReferences: SurveyReferenceModel[] = [];
  StatusTotals: StatusTotal[] = [];
  MediaLoading?: Observable<string>

  get Media(): string {
    if (this._media == "" && this.MediaID > 0 && !this._gettingMedia) {
      this._gettingMedia = true;
      this.getMediaForSurvey()
    }

    return this._media;
  }
  set Media(value: string) {
    this._media = value;
  }
  private _media: string = "";

  getMediaForSurvey(): Observable<string> | null {
    if (this.MediaLoading != undefined) {
      return this.MediaLoading;
    }

    if (this.mediaService == null) {
      return null;
    }

    if (this.MediaID == 0) {
      this._media = "";
      this.MediaLoading = new Observable<string>(result => { result.next("x"); });
      return this.MediaLoading;
    }

    this.MediaLoading = this.mediaService.getMediaById(this.MediaID).pipe(tap<string>(
      result => {
        this._media = result
      },
      error => {
        this._media = ""
      }
    ));
    return this.MediaLoading;
  }


  public static copy(result: SurveySummaryModel, mediaService?: MediaService) {
    const model = new SurveySummaryModel(mediaService);
    model.SurveyID = result.SurveyID;
    model.UserID = result.UserID;
    model.Name = result.Name;
    model.Description = result.Description;
    model.SurveyReference = result.SurveyReference;
    model.SearchTags = result.SearchTags;
    model.Status = result.Status;
    model.Type = result.Type;
    model.Created = result.Created;
    model.LastUpdated = result.LastUpdated;
    model.MediaID = result.MediaID;
    model.SurveyReferences = result.SurveyReferences;
    model.Script = result.Script;
    return model;
  }


  private _gettingMedia = false;
  private mediaService?: MediaService;

  constructor(mediaService?: MediaService) {
    this.mediaService = mediaService;
  }
}

export class JobMasterModel {
  JobMasterId: number = 0;
  SurveyID: number = 0;
  Name: string = "";
  CronExpression: string = "";
  Adjustment: number = 0;
  Start: Date = new Date();
  End: Date = new Date();
  Launched: Date = new Date();
  IsActive: boolean = false;
  IsDeleted: boolean = false;
  CanMissfire: boolean = false;
  ChannelID: number = 0;
  Subject: string = "";
  Content: string = "";
  JobTypeID: number = 0;
}

export class PanellistReference {
  PanellistReferenceID: number = 0;
  Created: Date = new Date();
  Expires: Date = new Date();
  SurveyID: number = 0;
  Serial: number = 0;
  InviteID: number = 0;
  Guid: string = "";
  BatchID: number = 0;
  StatisID: number = 0;
  PanellistID: number = 0;
}

export class DeliveryItem {
  SurveyId: number = 0;
  Period: Date = new Date();
  Label: string = "";
  StatusValue: number = 0;
  Total: number = 0;
}

export class ImportProjectSelections {
  AllSurveys: boolean;
  Criteria: ProjectSelectionCriteria;
  Surveys: ImportProjectSelection[] = [];
}

export class ImportProjectSelection {
  SurveyId: number;
  Criteria: ProjectSelectionCriteria;
}

export class ProjectSelectionCriteria {
  public Respondents: boolean;
  public DataIO: boolean;
  public JobMaster: boolean
}
