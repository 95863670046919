import { Component, OnInit, Inject, ViewChild, ElementRef } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { CintService, CintCountry, CintQuestion } from '../cint.Service';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Category, CategorySelectorComponent } from "../category-selector/category-selector.component";
import { MatSidenav } from '@angular/material/sidenav';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-cint-mapper',
  templateUrl: './cint-mapper.component.html',
  styleUrls: ['./cint-mapper.component.css']
})
export class CintMapperComponent implements OnInit {
  formGroup: FormGroup;
  index = -1;
  country: string = "";
  countries: CintCountry[] = [];
  tempCountries?: Observable<CintCountry[]>;
  tempSelected?: Observable<Category[]>;
  select: Category[] = [];
  questions: CintQuestion[] = [];
  tempQuestions: Observable<CintQuestion[]> = new Observable<any>();
  categories: Category[] = [];

  @ViewChild('catList') catList: ElementRef | undefined;
  @ViewChild('rightSidenav') public rightSidenav: MatSidenav | undefined;

  constructor(private cintService: CintService, private breakpointObserver: BreakpointObserver) {
    this.tempCountries = this.cintService.getCountries().pipe(tap<CintCountry[]>(
      result => {
        this.countries = result;
      },
      error => {
        let a = error;
      }));
    this.tempQuestions = new Observable<any>().pipe(tap());
    this.formGroup = new FormGroup({
      country: new FormControl(),
      search: new FormControl('', Validators.required),
      min: new FormControl('', Validators.required),
      max: new FormControl('', Validators.required)
    });
  }

  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
    .pipe(map(result => result.matches));

  ngOnInit(): void {
  }

  onCountryChange($event: any) {
    const selectedCountry = this.formGroup.controls['country'].value;
    if (selectedCountry === "") {
      return;
    }

    this.country = selectedCountry;
    this.tempQuestions = this.cintService.getRegionsAndFinanceQuestions(selectedCountry)
      .pipe(tap<CintQuestion[]>(result => {
        this.index = -1;
        this.questions = result;
        this.select = [];
      }));
  }

  selectQuestion($event: any, i: number) {
    if (this.index > -1) {
      this.updateMappings();
    }

    this.categories = [];
    this.index = i;
    this.select = [];
    this.questions[i].Variables.forEach(variable => {
      const cat = new Category(this.questions[i].Id, variable.Id, variable.Name, variable.Set, 0, 0, 0);
      this.categories.push(cat);
      if (cat.set) {
        this.select.push(cat);
      }
    });
    (<any>this.catList).quota = this.questions[i].Name;
    (<any>this.catList).categoryList = this.categories;
  }

  updateMappings() {
        this.questions[this.index].Variables.forEach(v => {
          v.Set = false;
          this.select.forEach(item => {
          if (item.id === v.Id) {
            v.Set = item.set;
          }
        });
    });
    this.cintService.updateQuestionVariables(this.country,
      this.questions[this.index],
      this.questions[this.index].Variables).subscribe();
  }

  onCategoryChange(category: Category) {
    if (!this.select) {
      if (category.set) {
        this.select = [category];
      }
      return;
    }

    if (category.set) {
      let added = false;
      this.select.forEach((item, index) => {
        if (item.id === category.id) {
          added = true;
          return;
        }

        if (item.id > category.id && !added) {
          this.select.splice(index, 0, category);
          added = true;
          return;
        }
      });

      if (!added) {
        this.select.push(category);
      }

      this.updateMappings();
      return;
    }

    this.select.forEach((item, index) => {
      if (item.id === category.id) {
        this.select.splice(index, 1);
      }
    });
    this.updateMappings();
  }

  isSelected(i: number) {
    let set = false;
    this.questions[i].Variables.forEach(v => {
      if (v.Set) {
        set = true;
      }
    });

    return set;
  }

  openSelectorSideNav() {
    this.rightSidenav?.open();
  }

  closeSelectorSidenav() {
    this.rightSidenav?.close();
  }
}
