<div [formGroup]="formGroup">
  <div>
    <input type="file" formControlName="file" class="noDisplay" #fileInput (change)="fileChangeEvent($event)" accept="image/x-png, image/gif, image/jpeg">
    <button mat-stroked-button (click)="onClickFileInputButton()">
      <mat-icon class="img-cropper-imgIcon">image</mat-icon>Select survey image
    </button>
    <div>
      <div class="img-cropper-div" *ngIf="imageChangedEvent">
        <image-cropper #imageCropper [imageChangedEvent]="imageChangedEvent"
                       [maintainAspectRatio]="false"
                       [aspectRatio]="16 / 9"
                       format="png"
                       (imageCropped)="imageCropped($event)"
                       (imageLoaded)="imageLoaded()"
                       (cropperReady)="cropperReady()"
                       (loadImageFailed)="loadImageFailed()"></image-cropper>
      </div>
      <div>
        <img *ngIf="mediaID > 0 && croppedImage" [src]="croppedImage" class="img-cropper-croppedImg" />
      </div>
    </div>
  </div>
</div>
