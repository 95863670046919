<mat-card class="dashboard-card">
  <div class="fieldRow">
    <div>
      <button mat-mini-fab color="primary" aria-label="Refresh">
        <mat-icon>refresh</mat-icon>
      </button>
    </div>
    <div>
      <h3>Active Surveys</h3>
    </div>
  </div>
  <table *ngIf="tempSummary | async; else loading" mat-table [dataSource]="dataSource">
    <ng-container matColumnDef="UserID">
      <th mat-header-cell *matHeaderCellDef> UID </th>
      <td mat-cell *matCellDef="let element"> <div matTooltip="{{users.has(element.UserID) ? users.get(element.UserID)?.UserName : ''}}"> {{element.UserID}} </div></td>
    </ng-container>

    <ng-container matColumnDef="Launched">
      <th mat-header-cell *matHeaderCellDef> Created </th>
      <td mat-cell *matCellDef="let element"> {{element.Launched  | date:'short' : '' : translate.currentLang}} </td>
    </ng-container>

    <ng-container matColumnDef="Description">
      <th mat-header-cell *matHeaderCellDef> </th>
      <td mat-cell *matCellDef="let element"> {{element.Description}} </td>
    </ng-container>

    <ng-container matColumnDef="Count">
      <th mat-header-cell *matHeaderCellDef> Count </th>
      <td mat-cell *matCellDef="let element"> {{element.Count}} </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <tr mat-row class="users-element-row" *matRowDef="let row; columns: displayedColumns;" (click)="edit(row)"></tr>
  </table>
  <ng-template #loading>
    <div class="loading-container">
      <span class="loading-content">
        <div class="spinner-div"><mat-spinner [diameter]="25"></mat-spinner></div>
        <div class="spinner-text"> Loading active surveys...</div>
      </span>
    </div>
    <br /><br />
  </ng-template>
</mat-card>
