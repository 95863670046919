import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router, ActivatedRoute, ActivatedRouteSnapshot } from '@angular/router';
import { MissionService } from "../MissionService";
import { Subscription }   from 'rxjs';
import { CommsModel, CommsService } from "../comms.Service";

interface EmailType {
  id: number;
  text: string;
}

@Component({
  selector: 'app-edit-communication',
  templateUrl: './edit-communication.component.html',
  styleUrls: ['./edit-communication.component.css']
})
export class EditCommunicationComponent implements OnInit, OnDestroy {
  comms: CommsModel = new CommsModel();
  emailTemplateId: string = "";
  formGroup: FormGroup;

  subscription: Subscription;
  types: EmailType[] = [
    { id: 0, text: 'Undefined' },
    { id: 1, text: 'Invitation' },
    { id: 2, text: 'Reminder' },
    { id: 3, text: 'Reset Password' },
    { id: 4, text: 'Registration Confirmation' },
    { id: 5, text: 'Forgot Password' },
    { id: 6, text: 'Email Change Warning' },
    { id: 7, text: 'Username Change Warning' },
    { id: 8, text: 'Profile Change Warning' },
    { id: 9, text: 'Organization Invitation' },
    { id: 10, text: 'Non-Member Organization Invitation' },
    { id: 11, text: 'Reporting Invitation' },
    { id: 12, text: 'Non-Member Reporting Invitation' },
    { id: 13, text: 'WhatsApp Invitation' },
    { id: 17, text: 'WhatsApp 2-Way Invitation' },
    { id: 14, text: 'Email 2FA' },
    { id: 15, text: 'Network Request' },
    { id: 16, text: 'Network Request Update' },
    { id: 99, text: 'Other' }
  ];

  constructor(
    missionService: MissionService,
    private router: Router,
    private commsService: CommsService,
    private actRoute: ActivatedRoute) {
    this.subscription = missionService.missionAnnounced$.subscribe(
      mission => {
        if (mission === "Back") {
          this.onSubmit();
        }
      });
    this.formGroup = new FormGroup({
      Name: new FormControl("", [Validators.required]),
      Description: new FormControl("", [Validators.required]),
      Subject: new FormControl("", [Validators.required]),
      EmailContent: new FormControl("", [Validators.required]),
      EmailType: new FormControl()
    });
  }

  ngOnInit(): void {
    this.emailTemplateId = this.actRoute.snapshot.params.id;
    if (this.emailTemplateId === "0") {
      this.comms = new CommsModel();
    }
    else {
      this.commsService.getComm(this.emailTemplateId).subscribe(result => {
          this.comms = result;
          this.formGroup.patchValue(this.comms);
        });
    }
  }

  onSubmit() {
    if (this.formGroup.invalid) {
      this.router.navigate(['/communications']);
    }

    this.comms.Name = this.formGroup.value.Name;
    this.comms.Subject = this.formGroup.value.Subject;
    this.comms.Description = this.formGroup.value.Description;
    this.comms.EmailContent = this.formGroup.value.EmailContent;
    this.comms.EmailType = this.formGroup.value.EmailType;
    this.commsService.setComm(this.comms).subscribe(result => {
      this.router.navigate(['/communications']);
    });
  }

  ngOnDestroy() {
    // prevent memory leak when component destroyed
    this.subscription.unsubscribe();
  }
}
