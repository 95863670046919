<app-newtoolbar toolbarType="login"></app-newtoolbar>
<div #body class="body" [class]="(isHandset$ | async) ? 'noDisplay' : ''">
  <div class="header">
    <div *ngIf="formReady" class="headerContent" [@fadeInOnEnter] [formGroup]="formGroup" style="display:flex;align-content:center;flex-wrap:wrap !important;flex-direction:column;gap:5px">
      <div style="width:auto">
        <div style="width:100%;text-align:center">
          <h1><span style="font-size:2rem">Welcome to DIY Surveys</span></h1>
        </div>
      </div>
      <div style="width:auto">
        <div style="width:100%;text-align:center">
          <p>
            Complete the easy sign-up form and start using DIY Surveys today!
          </p>
        </div>
      </div>
      <div style="width:auto">
        <div style="margin:2px auto;width:50%;text-align:center">
          <div style="display:flex;flex-flow:row;width:100%;text-align:center">
            <div style="width:90%">
              <mat-form-field appearance="outline" style="width:100%">
                <mat-label>Email Address</mat-label>
                <input matInput placeholder="Email Address" formControlName="email" (keyup.enter)="setFocus('name',emailValid)">
              </mat-form-field>
            </div>
            <div>
              <button mat-mini-fab color="primary" [disabled]="!emailValid" (click)="setFocus('name', emailValid)"><mat-icon>east</mat-icon></button>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="showName" [@fadeInOnEnter] style="width:auto;">
        <div style="margin:2px auto;width:50%;text-align:center">
          <div style="display:flex;flex-flow:row;width:100%;text-align:center">
            <div style="width:90%">
              <mat-form-field appearance="outline" style="width:100%">
                <mat-label>Name</mat-label>
                <input #name matInput placeholder="Name" formControlName="name" (keyup.enter)="setFocus('password', nameValid)">
              </mat-form-field>
            </div>
            <div>
              <button mat-mini-fab color="primary" [disabled]="!nameValid" (click)="showPassword = true"><mat-icon>east</mat-icon></button>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="showPassword" [@fadeInOnEnter] srtyle="width:auto;">
        <div style="margin:2px auto;width:50%;text-align:center">
          <div style="display:flex;flex-flow:row;width:100%;text-align:center">
            <div style="width:90%">
              <mat-form-field appearance="outline" style="width:100%">
                <mat-label>Password</mat-label>
                <input #password matInput [type]="hide ? 'password' : 'text'" placeholder="Password" formControlName="password" (keyup.enter)="setFocus('password2', passwordValid)">
                <mat-icon class="reveal" matSuffix (click)="hide = !hide">{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
              </mat-form-field>
            </div>
            <div>
              <button mat-mini-fab color="primary" [disabled]="!passwordValid" (click)="showPassword2 = true" color="primary"><mat-icon>east</mat-icon></button>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="showPassword2" [@fadeInOnEnter] style="width:auto;">
        <div style="margin:2px auto;width:50%;text-align:center">
          <div style="display:flex;flex-flow:row;width:100%;text-align:center">
            <div style="width:90%">
              <mat-form-field appearance="outline" style="width:100%">
                <mat-label>Confirm Password</mat-label>
                <input #password2 matInput [type]="hide2 ? 'password' : 'text'" placeholder="Confirm Password" formControlName="password2">
                <mat-icon class="reveal" matSuffix (click)="hide2 = !hide2">{{hide2 ? 'visibility_off' : 'visibility'}}</mat-icon>
              </mat-form-field>
            </div>
          </div>
        </div>
      </div>
      <div style="width:auto">
        <div *ngIf="hasErrors" [@fadeInOnEnter] [@fadeOutOnLeave] class="alert alert-primary" role="alert">
          <div *ngFor="let error of errorMessages">
            <div style="font-size:0.75rem" *ngIf="error != ''">{{error}}</div>
          </div>
          <div *ngIf="unavailable && !tellmemore" style="font-size:0.75rem">
            <mat-checkbox (click)="updateAccount()">Tick the box if you would like us to notify you when you can sign up for an account. </mat-checkbox>&nbsp;&nbsp;
          </div>
        </div>
      </div>
      <div *ngIf="!hasErrors && passwordValid2  && showPassword2" [@fadeInOnEnter]>
        <div style="margin: 0 auto;width:20%">
          <button mat-raised-button [disabled]="submitted" color="accent" (click)="submitForm()">SIGN UP</button>
        </div>
      </div>
      <div style="width:auto">
        <div *ngIf="tellmemore" [@fadeInOnEnter] [@fadeOutOnLeave] class="alert alert-success" role="alert">
          <span style="font-size:0.75rem">Thank you! We will let you know as soon as we are ready for you.</span>
        </div>
      </div>
      <div style="width:auto">
        <div *ngIf="registered" [@fadeInOnEnter] [@fadeOutOnLeave] class="alert alert-success" role="alert">
          <span style="font-size:0.75rem">Please check your email for a verification link</span>
        </div>
      </div>
      <div style="width:auto">
        <div style="margin:15px auto;width:70%;">
          <div class="register-or-separator">
            <span>Or Sign up with</span>
          </div>
          <div style="width: 100%; margin-top:-20px; height: 50px; display: flex; flex-flow: row; align-items: center;">
            <div style="width: 50%; margin: 0 -40px 0 18%;">
              <asl-google-signin-button type="icon" size="medium"></asl-google-signin-button>
            </div>
            <div class="social-login-separator">
            </div>
            <a mat-stroked-button href="#" (click)="logInWithFacebook()" style="width: 50%; border: none; height: 100%; padding-top: 7px;">
              <img src="assets/img/icon-facebook.png" />
            </a>
          </div>
        </div>
      </div>
      <div style="width:auto">
        <div style="margin-top: 30px;font-size:0.75rem;width:100%;text-align:center">
          By Creating an account you have read and agree to the terms and privacy policy.
        </div>
      </div>
    </div>
  </div>
  <div class="footer">
    <app-newfooter></app-newfooter>
  </div>
</div>
<div #body class="body" [class]="(isHandset$ | async) ? '' : 'noDisplay'">
  <div class="section-mobile-header">
    <div *ngIf="formReady" class="headerContent" [@fadeInOnEnter] [formGroup]="formGroup" style="display:flex;align-content:center;flex-wrap:wrap !important;flex-direction:column;gap:5px">
      <div style="width:auto">
        <div style="width:100%;text-align:center">
          <h1><span style="font-size:2rem">Welcome to DIY Surveys</span></h1>
        </div>
      </div>
      <div style="width:auto">
        <div style="width:100%;text-align:center">
          <p>
            Complete the easy sign-up form and start using DIY Surveys today!
          </p>
        </div>
      </div>
      <div style="width:auto">
        <div style="margin:2px auto;width:95%;text-align:center">
          <div style="display:flex;flex-flow:row;width:100%;text-align:center">
            <div style="width:90%">
              <mat-form-field appearance="outline" style="width:100%">
                <mat-label>Email Address</mat-label>
                <input matInput placeholder="Email Address" formControlName="email" (keyup.enter)="setFocus('name',emailValid)">
              </mat-form-field>
            </div>
            <div>
              <button mat-mini-fab color="primary" [disabled]="!emailValid" (click)="setFocus('name', emailValid)"><mat-icon>east</mat-icon></button>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="showName" [@fadeInOnEnter] style="width:auto;">
        <div style="margin:2px auto;width:95%;text-align:center">
          <div style="display:flex;flex-flow:row;width:100%;text-align:center">
            <div style="width:90%">
              <mat-form-field appearance="outline" style="width:100%">
                <mat-label>Name</mat-label>
                <input #name matInput placeholder="Name" formControlName="name" (keyup.enter)="setFocus('password', nameValid)">
              </mat-form-field>
            </div>
            <div>
              <button mat-mini-fab color="primary" [disabled]="!nameValid" (click)="showPassword = true"><mat-icon>east</mat-icon></button>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="showPassword" [@fadeInOnEnter] srtyle="width:auto;">
        <div style="margin:2px auto;width:95%;text-align:center">
          <div style="display:flex;flex-flow:row;width:100%;text-align:center">
            <div style="width:90%">
              <mat-form-field appearance="outline" style="width:100%">
                <mat-label>Password</mat-label>
                <input #password matInput [type]="hide ? 'password' : 'text'" placeholder="Password" formControlName="password" (keyup.enter)="setFocus('password2', passwordValid)">
                <mat-icon class="reveal" matSuffix (click)="hide = !hide">{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
              </mat-form-field>
            </div>
            <div>
              <button mat-mini-fab color="primary" [disabled]="!passwordValid" (click)="showPassword2 = true" color="primary"><mat-icon>east</mat-icon></button>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="showPassword2" [@fadeInOnEnter] style="width:auto;">
        <div style="margin:2px auto;width:95%;text-align:center">
          <div style="display:flex;flex-flow:row;width:100%;text-align:center">
            <div style="width:90%">
              <mat-form-field appearance="outline" style="width:100%">
                <mat-label>Confirm Password</mat-label>
                <input #password2 matInput [type]="hide2 ? 'password' : 'text'" placeholder="Confirm Password" formControlName="password2">
                <mat-icon class="reveal" matSuffix (click)="hide2 = !hide2">{{hide2 ? 'visibility_off' : 'visibility'}}</mat-icon>
              </mat-form-field>
            </div>
          </div>
        </div>
      </div>
      <div style="width:auto">
        <div *ngIf="hasErrors" [@fadeInOnEnter] [@fadeOutOnLeave] class="alert alert-primary" role="alert">
          <div *ngFor="let error of errorMessages">
            <div style="font-size:0.75rem" *ngIf="error != ''">{{error}}</div>
          </div>
          <div *ngIf="unavailable && !tellmemore" style="font-size:0.75rem">
            <mat-checkbox (click)="updateAccount()">Tick the box if you would like us to notify you when you can sign up for an account. </mat-checkbox>&nbsp;&nbsp;
          </div>
        </div>
      </div>
      <div style="width:auto">
        <div *ngIf="tellmemore" [@fadeInOnEnter] [@fadeOutOnLeave] class="alert alert-success" role="alert">
          <span style="font-size:0.75rem">Thank you! We will let you know as soon as we are ready for you.</span>
        </div>
      </div>
      <div *ngIf="!hasErrors && passwordValid2  && showPassword2" [@fadeInOnEnter]>
        <div style="margin: 0 auto;width:20%">
          <button mat-raised-button [disabled]="submitted" color="accent" (click)="submitForm()">SIGN UP</button>
        </div>
      </div>
      <div style="width:auto">
        <div *ngIf="registered" [@fadeInOnEnter] [@fadeOutOnLeave] class="alert alert-success" role="alert">
          <span style="font-size:0.75rem">Please check your email for a verification link.</span>
        </div>
      </div>
      <div style="width:auto">
        <div style="margin:15px auto;width:95%;">
          <div class="register-or-separator">
            <span>Or Sign up with</span>
          </div>
          <div style="width: 100%; margin-top:-20px; height: 50px; display: flex; flex-flow: row; align-items: center;">
            <div style="width: 50%; margin: 0 -40px 0 18%;">
              <asl-google-signin-button type="icon" size="medium"></asl-google-signin-button>
            </div>
            <div class="social-login-separator">
            </div>
            <a mat-stroked-button href="#" (click)="logInWithFacebook()" style="width: 50%; border: none; height: 100%; padding-top: 7px;">
              <img src="assets/img/icon-facebook.png" />
            </a>
          </div>
        </div>
      </div>
      <div style="width:auto">
        <div style="margin-top: 30px;font-size:0.75rem;width:100%;text-align:center">
          By Creating an account you have read and agree to the terms and privacy policy.
        </div>
      </div>
    </div>
  </div>
  <div class="footer-mobile">
    <app-newfooter></app-newfooter>
  </div>
</div>
