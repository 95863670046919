import { Component, OnInit } from '@angular/core';
import { FormGroup, FormArray, Validators } from '@angular/forms';
import { BaseQuestion, QuestionService, DataModel, InterviewService } from '../../question.service';

@Component({
  selector: 'app-information',
  templateUrl: './information.component.html',
  styleUrls: ['./information.component.css']
})
export class InformationComponent extends BaseQuestion implements OnInit {
  constructor(private qs: QuestionService, private is: InterviewService) {
    super(qs, is, undefined, undefined, new DataModel("information"));
  }

  public infoFormGroup: FormGroup = new FormGroup({});

  ngOnInit() {
    this.infoFormGroup = this.fb.group({
      infoFormArray: this.fb.array([this.initQuestion()])
    });
  }

  get questionArr(): FormArray | null {
    if (this.infoFormGroup != null) {
      return this.infoFormGroup.get('infoFormArray') as FormArray;
    }

    return null;
  }

  onSubmit(): void {
  }
}
