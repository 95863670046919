<div>
  <form class="buynumberform" [formGroup]="formGroup">
    <p>
      Organization
      <br />
      <mat-form-field appearance="outline" class="msg-hist-filtByCountry-field">
        <mat-label>Organization Name</mat-label>
        <mat-select formControlName="Organization">
          <mat-option value="">All Organization</mat-option>
          <mat-option *ngFor="let org of orgList" [value]="org">{{org.name}}</mat-option>
        </mat-select>
      </mat-form-field>
      <br />
      Country
      <br />
      <mat-form-field appearance="outline" class="msg-hist-filtByCountry-field">
        <mat-label>Filter by Country</mat-label>
        <mat-select formControlName="Country">
          <mat-option value="">All Countries</mat-option>
          <mat-option *ngFor="let country of countries" [value]="country">{{country.Country}}</mat-option>
        </mat-select>
      </mat-form-field>
      <br />
      Channel
      <br />
      <mat-form-field appearance="outline" class="msg-hist-filtByCountry-field">
        <mat-label>Channel</mat-label>
        <mat-select formControlName="feature">
          <mat-option value="SMS">SMS</mat-option>
          <mat-option value="Whatsapp">Whatsapp</mat-option>
        </mat-select>
      </mat-form-field>
      <br />
      Number Type
      <br />
      <mat-form-field appearance="outline" class="msg-hist-filtByCountry-field">
        <mat-label>Number Type</mat-label>
        <mat-select formControlName="numbertype">
          <mat-option value="Toll-Free">Toll-Free</mat-option>
          <mat-option value="10 DLC">10 DLC</mat-option>
          <mat-option value="Shorts Code">Short Code</mat-option>
        </mat-select>
      </mat-form-field>
      <br />
      Number Contains
      <br />
      <mat-form-field appearance="outline" class="msg-hist-filtByCountry-field">
        <mat-label>Number</mat-label>
        <mat-select formControlName="number">
          <mat-option value="Contains">Contains</mat-option>
          <mat-option value="Starts With">Starts With</mat-option>
          <mat-option value="Ends With">Ends With</mat-option>
        </mat-select>
      </mat-form-field>
      <br />
      Number
      <br />
      <mat-form-field appearance="outline" class="msg-hist-filtByNum-field" style="width:288px">
        <mat-label></mat-label>
        <input matInput placeholder="e.g. 1105" formControlName="numberval">
      </mat-form-field>
    </p>

      <button mat-flat-button color="primary" (click)="newSearch()">Send</button>
  </form>
</div>
<div *ngIf="displayError">
  <div class="alert alert-danger" role="alert">
    {{errorMsg}}
  </div>
</div>

<div *ngIf="displaySuccess">
  <div class="alert alert-success" role="alert">
    Email has been sent for your Buy Number Query.
  </div>
</div>

<div>
  &nbsp;
</div>
