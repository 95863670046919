import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-country-combo',
  templateUrl: './country-combo.component.html',
  styleUrls: ['./country-combo.component.css']
})
export class CountryComboComponent implements OnInit {

  locations = [
    {
      id: '1', value: 'Afghanistan', countryCode: 'AF'
    }, {
      id: '2', value: 'Albania', countryCode: 'AL'
    }, {
      id: '3', value: 'Algeria', countryCode: 'DZ'
    }, {
      id: '4', value: 'American Samoa', countryCode: 'AS'
    }, {
      id: '5', value: 'Andorra', countryCode: 'AD'
    }, {
      id: '6', value: 'Angola', countryCode: 'AO'
    }, {
      id: '7', value: 'Anguilla', countryCode: 'AI'
    }, {
      id: '8', value: 'Antarctica', countryCode: 'AQ'
    }, {
      id: '9', value: 'Antigua and Barbuda', countryCode: 'AG'
    }, {
      id: '10', value: 'Argentina', countryCode: 'AR'
    }, {
      id: '11', value: 'Armenia', countryCode: 'AM'
    }, {
      id: '12', value: 'Aruba', countryCode: 'AW'
    }, {
      id: '13', value: 'Australia', countryCode: 'AU'
    }, {
      id: '14', value: 'Austria', countryCode: 'AT'
    }, {
      id: '15', value: 'Azerbaijan', countryCode: 'AZ'
    }, {
      id: '16', value: 'Bahamas', countryCode: 'BS'
    }, {
      id: '17', value: 'Bahrain', countryCode: 'BH'
    }, {
      id: '18', value: 'Bangladesh', countryCode: 'BD'
    }, {
      id: '19', value: 'Barbados', countryCode: 'BB'
    }, {
      id: '20', value: 'Belarus', countryCode: 'BY'
    }, {
      id: '21', value: 'Belgium', countryCode: 'BE'
    }, {
      id: '22', value: 'Belize', countryCode: 'BZ'
    }, {
      id: '23', value: 'Benin', countryCode: 'BJ'
    }, {
      id: '24', value: 'Bermuda', countryCode: 'BM'
    }, {
      id: '25', value: 'Bhutan', countryCode: 'BT'
    }, {
      id: '26', value: 'Bolivia', countryCode: 'BO'
    }, {
      id: '27', value: 'Bonaire', countryCode: ''
    }, {
      id: '28', value: 'Bosnia and Herzegovina', countryCode: 'BA'
    }, {
      id: '29', value: 'Botswana', countryCode: 'BW'
    }, {
      id: '30', value: 'Bouvet Island', countryCode: ''
    }, {
      id: '31', value: 'Brazil', countryCode: 'BR'
    }, {
      id: '32', value: 'British Indian Ocean Territory', countryCode: 'IO'
    }, {
      id: '33', value: 'Brunei Darussalam', countryCode: ''
    }, {
      id: '34', value: 'Bulgaria', countryCode: 'BG'
    }, {
      id: '35', value: 'Burkina Faso', countryCode: 'BF'
    }, {
      id: '36', value: 'Burundi', countryCode: 'BI'
    }, {
      id: '37', value: 'Cabo Verde', countryCode: ''
    }, {
      id: '38', value: 'Cambodia', countryCode: 'KH'
    }, {
      id: '39', value: 'Cameroon', countryCode: 'CM'
    }, {
      id: '40', value: 'Canada', countryCode: 'CA'
    }, {
      id: '41', value: 'Cayman Islands', countryCode: 'KY'
    }, {
      id: '42', value: 'Central African Republic', countryCode: 'CF'
    }, {
      id: '43', value: 'Chad', countryCode: 'TD'
    }, {
      id: '44', value: 'Chile', countryCode: 'CL'
    }, {
      id: '45', value: 'China', countryCode: 'CN'
    }, {
      id: '46', value: 'Christmas Island', countryCode: 'CX'
    }, {
      id: '47', value: 'Cocos (Keeling) Islands', countryCode: ''
    }, {
      id: '48', value: 'Colombia', countryCode: 'CO'
    }, {
      id: '49', value: 'Comoros', countryCode: 'KM'
    }, {
      id: '50', value: 'Congo (the Democratic Republic)', countryCode: ''
    }, {
      id: '51', value: 'Congo', countryCode: ''
    }, {
      id: '52', value: 'Cook Islands', countryCode: 'CK'
    }, {
      id: '53', value: 'Costa Rica', countryCode: 'CR'
    }, {
      id: '54', value: 'Croatia', countryCode: 'HR'
    }, {
      id: '55', value: 'Cuba', countryCode: 'CU'
    }, {
      id: '56', value: 'Curacao', countryCode: 'CW'
    }, {
      id: '57', value: 'Cyprus', countryCode: 'CY'
    }, {
      id: '58', value: 'Czechia', countryCode: ''
    }, {
      id: '59', value: 'Cote d',countryCode:''
}, {
      id: '60', value: 'Denmark', countryCode: 'DK'
    }, {
      id: '61', value: 'Djibouti', countryCode: 'DJ'
    }, {
      id: '62', value: 'Dominica', countryCode: 'DM'
    }, {
      id: '63', value: 'Dominican Republic', countryCode: 'DO'
    }, {
      id: '64', value: 'Ecuador', countryCode: 'EC'
    }, {
      id: '65', value: 'Egypt', countryCode: 'EG'
    }, {
      id: '66', value: 'El Salvador', countryCode: 'SV'
    }, {
      id: '67', value: 'Equatorial Guinea', countryCode: 'GQ'
    }, {
      id: '68', value: 'Eritrea', countryCode: 'ER'
    }, {
      id: '69', value: 'Estonia', countryCode: 'EE'
    }, {
      id: '70', value: 'Eswatini', countryCode: ''
    }, {
      id: '71', value: 'Ethiopia', countryCode: 'ET'
    }, {
      id: '72', value: 'Falkland Islands [Malvinas]', countryCode: ''
    }, {
      id: '73', value: 'Faroe Islands', countryCode: 'FO'
    }, {
      id: '74', value: 'Fiji', countryCode: 'FJ'
    }, {
      id: '75', value: 'Finland', countryCode: 'FI'
    }, {
      id: '76', value: 'France', countryCode: 'FR'
    }, {
      id: '77', value: 'French Guiana', countryCode: ''
    }, {
      id: '78', value: 'French Polynesia', countryCode: 'PF'
    }, {
      id: '79', value: 'French Southern Territories', countryCode: ''
    }, {
      id: '80', value: 'Gabon', countryCode: 'GA'
    }, {
      id: '81', value: 'Gambia', countryCode: 'GM'
    }, {
      id: '82', value: 'Georgia', countryCode: 'GE'
    }, {
      id: '83', value: 'Germany', countryCode: 'DE'
    }, {
      id: '84', value: 'Ghana', countryCode: 'GH'
    }, {
      id: '85', value: 'Gibraltar', countryCode: 'GI'
    }, {
      id: '86', value: 'Greece', countryCode: 'GR'
    }, {
      id: '87', value: 'Greenland', countryCode: 'GL'
    }, {
      id: '88', value: 'Grenada', countryCode: 'GD'
    }, {
      id: '89', value: 'Guadeloupe', countryCode: ''
    }, {
      id: '90', value: 'Guam', countryCode: 'GU'
    }, {
      id: '91', value: 'Guatemala', countryCode: 'GT'
    }, {
      id: '92', value: 'Guernsey', countryCode: 'GG'
    }, {
      id: '93', value: 'Guinea', countryCode: 'GN'
    }, {
      id: '94', value: 'Guinea-Bissau', countryCode: 'GW'
    }, {
      id: '95', value: 'Guyana', countryCode: 'GY'
    }, {
      id: '96', value: 'Haiti', countryCode: 'HT'
    }, {
      id: '97', value: 'Heard Island and McDonald Islands', countryCode: ''
    }, {
      id: '98', value: 'Holy See', countryCode: ''
    }, {
      id: '99', value: 'Honduras', countryCode: 'HN'
    }, {
      id: '100', value: 'Hong Kong', countryCode: 'HK'
    }, {
      id: '101', value: 'Hungary', countryCode: 'HU'
    }, {
      id: '102', value: 'Iceland', countryCode: 'IS'
    }, {
      id: '103', value: 'India', countryCode: 'IN'
    }, {
      id: '104', value: 'Indonesia', countryCode: 'ID'
    }, {
      id: '105', value: 'Iran', countryCode: 'IR'
    }, {
      id: '106', value: 'Iraq', countryCode: 'IQ'
    }, {
      id: '107', value: 'Ireland', countryCode: 'IE'
    }, {
      id: '108', value: 'Isle of Man', countryCode: 'IM'
    }, {
      id: '109', value: 'Israel', countryCode: 'IL'
    }, {
      id: '110', value: 'Italy', countryCode: 'IT'
    }, {
      id: '111', value: 'Jamaica', countryCode: 'JM'
    }, {
      id: '112', value: 'Japan', countryCode: 'JP'
    }, {
      id: '113', value: 'Jersey', countryCode: 'JE'
    }, {
      id: '114', value: 'Jordan', countryCode: 'JO'
    }, {
      id: '115', value: 'Kazakhstan', countryCode: 'KZ'
    }, {
      id: '116', value: 'Kenya', countryCode: 'KE'
    }, {
      id: '117', value: 'Kiribati', countryCode: 'KI'
    }, {
      id: '118', value: 'Korea (the Democratic People',countryCode:''
}, {
      id: '119', value: 'Korea (the Republic of)', countryCode: ''
    }, {
      id: '120', value: 'Kuwait', countryCode: 'KW'
    }, {
      id: '121', value: 'Kyrgyzstan', countryCode: 'KG'
    }, {
      id: '122', value: 'Lao People',countryCode:''
}, {
      id: '123', value: 'Latvia', countryCode: 'LV'
    }, {
      id: '124', value: 'Lebanon', countryCode: 'LB'
    }, {
      id: '125', value: 'Lesotho', countryCode: 'LS'
    }, {
      id: '126', value: 'Liberia', countryCode: 'LR'
    }, {
      id: '127', value: 'Libya', countryCode: 'LY'
    }, {
      id: '128', value: 'Liechtenstein', countryCode: 'LI'
    }, {
      id: '129', value: 'Lithuania', countryCode: 'LT'
    }, {
      id: '130', value: 'Luxembourg', countryCode: 'LU'
    }, {
      id: '131', value: 'Macao', countryCode: ''
    }, {
      id: '132', value: 'Madagascar', countryCode: 'MG'
    }, {
      id: '133', value: 'Malawi', countryCode: 'MW'
    }, {
      id: '134', value: 'Malaysia', countryCode: 'MY'
    }, {
      id: '135', value: 'Maldives', countryCode: 'MV'
    }, {
      id: '136', value: 'Mali', countryCode: 'ML'
    }, {
      id: '137', value: 'Malta', countryCode: 'MT'
    }, {
      id: '138', value: 'Marshall Islands', countryCode: 'MH'
    }, {
      id: '139', value: 'Martinique', countryCode: ''
    }, {
      id: '140', value: 'Mauritania', countryCode: 'MR'
    }, {
      id: '141', value: 'Mauritius', countryCode: 'MU'
    }, {
      id: '142', value: 'Mayotte', countryCode: 'YT'
    }, {
      id: '143', value: 'Mexico', countryCode: 'MX'
    }, {
      id: '144', value: 'Micronesia (Federated States of)', countryCode: ''
    }, {
      id: '145', value: 'Moldova (the Republic of)', countryCode: ''
    }, {
      id: '146', value: 'Monaco', countryCode: 'MC'
    }, {
      id: '147', value: 'Mongolia', countryCode: 'MN'
    }, {
      id: '148', value: 'Montenegro', countryCode: 'ME'
    }, {
      id: '149', value: 'Montserrat', countryCode: 'MS'
    }, {
      id: '150', value: 'Morocco', countryCode: 'MA'
    }, {
      id: '151', value: 'Mozambique', countryCode: 'MZ'
    }, {
      id: '152', value: 'Myanmar', countryCode: 'MM'
    }, {
      id: '153', value: 'Namibia', countryCode: 'NA'
    }, {
      id: '154', value: 'Nauru', countryCode: 'NR'
    }, {
      id: '155', value: 'Nepal', countryCode: 'NP'
    }, {
      id: '156', value: 'Netherlands', countryCode: 'NL'
    }, {
      id: '157', value: 'New Caledonia', countryCode: 'NC'
    }, {
      id: '158', value: 'New Zealand', countryCode: 'NZ'
    }, {
      id: '159', value: 'Nicaragua', countryCode: 'NI'
    }, {
      id: '160', value: 'Niger', countryCode: 'NE'
    }, {
      id: '161', value: 'Nigeria', countryCode: 'NG'
    }, {
      id: '162', value: 'Niue', countryCode: 'NU'
    }, {
      id: '163', value: 'Norfolk Island', countryCode: ''
    }, {
      id: '164', value: 'Northern Mariana Islands', countryCode: 'MP'
    }, {
      id: '165', value: 'Norway', countryCode: 'NO'
    }, {
      id: '166', value: 'Oman', countryCode: 'OM'
    }, {
      id: '167', value: 'Pakistan', countryCode: 'PK'
    }, {
      id: '168', value: 'Palau', countryCode: 'PW'
    }, {
      id: '169', value: 'Palestine', countryCode: 'PS'
    }, {
      id: '170', value: 'Panama', countryCode: 'PA'
    }, {
      id: '171', value: 'Papua New Guinea', countryCode: 'PG'
    }, {
      id: '172', value: 'Paraguay', countryCode: 'PY'
    }, {
      id: '173', value: 'Peru', countryCode: 'PE'
    }, {
      id: '174', value: 'Philippines', countryCode: 'PH'
    }, {
      id: '175', value: 'Pitcairn', countryCode: 'PN'
    }, {
      id: '176', value: 'Poland', countryCode: 'PL'
    }, {
      id: '177', value: 'Portugal', countryCode: 'PT'
    }, {
      id: '178', value: 'Puerto Rico', countryCode: 'PR'
    }, {
      id: '179', value: 'Qatar', countryCode: 'QA'
    }, {
      id: '180', value: 'Republic of North Macedonia', countryCode: ''
    }, {
      id: '181', value: 'Romania', countryCode: 'RO'
    }, {
      id: '182', value: 'Russian Federation', countryCode: ''
    }, {
      id: '183', value: 'Rwanda', countryCode: 'RW'
    }, {
      id: '184', value: 'Reunion', countryCode: 'RE'
    }, {
      id: '185', value: 'Saint Barthelemy', countryCode: 'BL'
    }, {
      id: '186', value: 'Saint Helena', countryCode: 'SH'
    }, {
      id: '187', value: 'Saint Kitts and Nevis', countryCode: 'KN'
    }, {
      id: '188', value: 'Saint Lucia', countryCode: 'LC'
    }, {
      id: '189', value: 'Saint Martin (French part)', countryCode: ''
    }, {
      id: '190', value: 'Saint Pierre and Miquelon', countryCode: 'PM'
    }, {
      id: '191', value: 'Saint Vincent and the Grenadines', countryCode: 'VC'
    }, {
      id: '192', value: 'Samoa', countryCode: 'WS'
    }, {
      id: '193', value: 'San Marino', countryCode: 'SM'
    }, {
      id: '194', value: 'Sao Tome and Principe', countryCode: 'ST'
    }, {
      id: '195', value: 'Saudi Arabia', countryCode: 'SA'
    }, {
      id: '196', value: 'Senegal', countryCode: 'SN'
    }, {
      id: '197', value: 'Serbia', countryCode: 'RS'
    }, {
      id: '198', value: 'Seychelles', countryCode: 'SC'
    }, {
      id: '199', value: 'Sierra Leone', countryCode: 'SL'
    }, {
      id: '200', value: 'Singapore', countryCode: 'SG'
    }, {
      id: '201', value: 'Sint Maarten (Dutch part)', countryCode: ''
    }, {
      id: '202', value: 'Slovakia', countryCode: 'SK'
    }, {
      id: '203', value: 'Slovenia', countryCode: 'SI'
    }, {
      id: '204', value: 'Solomon Islands', countryCode: 'SB'
    }, {
      id: '205', value: 'Somalia', countryCode: 'SO'
    }, {
      id: '206', value: 'South Africa', countryCode: 'ZA'
    }, {
      id: '207', value: 'South Georgia and the South Sandwich Islands', countryCode: ''
    }, {
      id: '208', value: 'South Sudan', countryCode: 'SS'
    }, {
      id: '209', value: 'Spain', countryCode: 'ES'
    }, {
      id: '210', value: 'Sri Lanka', countryCode: 'LK'
    }, {
      id: '211', value: 'Sudan', countryCode: 'SD'
    }, {
      id: '212', value: 'Suri', countryCode: ''
    }, {
      id: '213', value: 'Svalbard and Jan Mayen', countryCode: 'SJ'
    }, {
      id: '214', value: 'Sweden', countryCode: 'SE'
    }, {
      id: '215', value: 'Switzerland', countryCode: 'CH'
    }, {
      id: '216', value: 'Syrian Arab Republic', countryCode: ''
    }, {
      id: '217', value: 'Taiwan', countryCode: 'TW'
    }, {
      id: '218', value: 'Tajikistan', countryCode: 'TJ'
    }, {
      id: '219', value: 'Tanzania', countryCode: 'TZ'
    }, {
      id: '220', value: 'Thailand', countryCode: 'TH'
    }, {
      id: '221', value: 'Timor-Leste', countryCode: ''
    }, {
      id: '222', value: 'Togo', countryCode: 'TG'
    }, {
      id: '223', value: 'Tokelau', countryCode: 'TK'
    }, {
      id: '224', value: 'Tonga', countryCode: 'TO'
    }, {
      id: '225', value: 'Trinidad and Tobago', countryCode: 'TT'
    }, {
      id: '226', value: 'Tunisia', countryCode: 'TN'
    }, {
      id: '227', value: 'Turkey', countryCode: 'TR'
    }, {
      id: '228', value: 'Turkmenistan', countryCode: 'TM'
    }, {
      id: '229', value: 'Turks and Caicos Islands', countryCode: 'TC'
    }, {
      id: '230', value: 'Tuvalu', countryCode: 'TV'
    }, {
      id: '231', value: 'Uganda', countryCode: 'UG'
    }, {
      id: '232', value: 'Ukraine', countryCode: 'UA'
    }, {
      id: '233', value: 'United Arab Emirates', countryCode: 'AE'
    }, {
      id: '234', value: 'United Kingdom of Great Britain and Northern Ireland', countryCode: 'GB'
    }, {
      id: '235', value: 'United States Minor Outlying Islands', countryCode: 'US'
    }, {
      id: '236', value: 'United States of America', countryCode: 'US'
    }, {
      id: '237', value: 'Uruguay', countryCode: 'UY'
    }, {
      id: '238', value: 'Uzbekistan', countryCode: 'UZ'
    }, {
      id: '239', value: 'Vanuatu', countryCode: 'VU'
    }, {
      id: '240', value: 'Venezuela (Bolivarian Republic of)', countryCode: 'VE'
    }, {
      id: '241', value: 'Viet Nam', countryCode: 'VN'
    }, {
      id: '242', value: 'Virgin Islands (British)', countryCode: ''
    }, {
      id: '243', value: 'Virgin Islands (U.S.)', countryCode: ''
    }, {
      id: '244', value: 'Wallis and Futuna', countryCode: 'WF'
    }, {
      id: '245', value: 'Western Sahara', countryCode: 'EH'
    }, {
      id: '246', value: 'Yemen', countryCode: 'YE'
    }, {
      id: '247', value: 'Zambia', countryCode: 'ZM'
    }, {
      id: '248', value: 'Zimbabwe', countryCode: 'ZW',
    }, { id: '249', value: 'Aland Islands', countryCode: '' }
  ];

  country = { id: '', value:'', countryCode:'' }

  countrySelector = new FormControl('');

  constructor() { }

  ngOnInit(): void {
  }

  @Input()
  get required(): boolean {
    return this._required;
  }
  set required(value: boolean) {
    this._required = value;
    if (value == undefined) {
      return;
    }

    this.countrySelector.clearValidators();
    if (this._required) {
      this.countrySelector.addValidators(Validators.required);
    }
  }
  private _required = false;

  @Input()
  get selected(): any {
    return this.country;
  }
  set selected(value: any) {
    for (let i = 0; i < this.locations.length; i++) {
      if (this.locations[i].id === value) {
        this.country = this.locations[i];
        this.selectedChange.emit(this.country)
        return;
      }
    }
  }

  @Input()
  get label(): string {
    return this._label;
  }
  set label(value: string) {
    this._label = value;
  }
  private _label = "Location";

  @Output() selectedChange = new EventEmitter<any>();

  selectionChanged() {
    this.selected = this.countrySelector.value;
  }
}

