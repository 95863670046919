<div *ngIf="tempKeywords | async; else loading">
  <mat-accordion>
    <mat-expansion-panel hideToggle [expanded]="true">
      <mat-expansion-panel-header>
        <mat-panel-title>
          Keyword Manager
        </mat-panel-title>
        <mat-panel-description>

        </mat-panel-description>
      </mat-expansion-panel-header>
      <div>
        <button mat-raised-button color="primary" (click)="openKeywordDialog()">Add Keyword</button>
      </div>
      <table *ngIf="keywords.length > 0" mat-table [dataSource]="keywords" class="mat-elevation-z8">
        <ng-container matColumnDef="edit">
          <th mat-header-cell *matHeaderCellDef> </th>
          <td mat-cell *matCellDef="let element">
            <button mat-icon-button aria-label="Example icon button with a open in new tab icon" [routerLink]="['/edit-respondent', panelId, element.PanellistID, 'panelist']">
              <mat-icon>open_in_new</mat-icon>
            </button>
          </td>
        </ng-container>
        <ng-container matColumnDef="Code">
          <th mat-header-cell *matHeaderCellDef> Name </th>
          <td mat-cell *matCellDef="let element"> {{element.Code}} </td>
        </ng-container>

        <ng-container matColumnDef="StartDate">
          <th mat-header-cell *matHeaderCellDef> Start </th>
          <td mat-cell *matCellDef="let element"> {{element.StartDate}} </td>
        </ng-container>
        <ng-container matColumnDef="FinishDate">
          <th mat-header-cell *matHeaderCellDef> Finish </th>
          <td mat-cell *matCellDef="let element"> {{element.FinishDate}} </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
    </mat-expansion-panel>
    <mat-expansion-panel hideToggle>
      <mat-expansion-panel-header>
        <mat-panel-title>
          Message Manager
        </mat-panel-title>
        <mat-panel-description>
        </mat-panel-description>
      </mat-expansion-panel-header>
      <div>

      </div>
    </mat-expansion-panel>
    <mat-expansion-panel hideToggle>
      <mat-expansion-panel-header>
        <mat-panel-title>
          Anomaly Fixer
        </mat-panel-title>
        <mat-panel-description>
        </mat-panel-description>
      </mat-expansion-panel-header>
      <div>
        <div class="leftFloat">
          <mat-icon aria-hidden="false" aria-label="Example home icon">security</mat-icon>
        </div>
        <div class="leftFloat">
          <p>Two step verification gives you additional security by requiring a Verification code whenever you sign in on a new device. Learn more</p>
        </div>
      </div>
    </mat-expansion-panel>
  </mat-accordion>
</div>

<ng-template #loading>
  <div class="loading-container">
    <span class="loading-content">
      <div class="spinner-div"><mat-spinner [diameter]="25"></mat-spinner></div>
      <div class="spinner-text"> Please Wait...</div>
    </span>
  </div>
</ng-template>

