import { Component, ViewChild, OnInit, Input, Output, EventEmitter, HostBinding } from '@angular/core';
import { AnswerModel } from "../../models/AnswerModel";
import { DataComponent } from '../data/data.component';
import { NavbarComponent } from '../navbar/navbar.component';

@Component({
  selector: 'diy-canvas',
  templateUrl: './canvas.component.html',
  styleUrls: ['./canvas.component.css']
})
export class CanvasComponent implements OnInit {
 @HostBinding('@.disabled')
  public animationsDisabled = false;

  @ViewChild('data') data: DataComponent | undefined;
  @ViewChild('nav') nav: NavbarComponent | undefined;

  displayLabel = false;
  question: any;
  navigation: any;
  type: any;
  answers: AnswerModel[] = [];
  navigationColor: string = "";
  navigationTextColor: string = "";
  controlsColor: string = "";

  constructor() { }

  ngOnInit(): void {
  }

  showNavigation() {
    return this.checkAllNavigation(["prev", "next", "stop"]);
  }

  checkAllNavigation(values: string[]): boolean {
    if (this.navigation) {
      for (let i = 0; i < this.navigation.Items.length; i++) {
        if (this.navigation.Items[i].Style.Hidden === false && values.includes(this.getNavigationType(this.navigation.Items[i].Type))) {
          return true;
        }
      }
    }

    return false;
  }

  getNavigationType(id: number): string {
    switch (id) {
      case 0:
        return "next";
      case 1:
        return "prev";
      case 5:
        return "stop";
      default:
        return "";
    }
  }

  updateLanguage(language: any) {
    this.languageSelected = language.Region;
    this.changeLanguage.emit(this);
  }

  get displayLanguages(): boolean {
    return this._displayLanguages;
  }
  private _displayLanguages: boolean;

  languageSelected: any = null;
  languages: any[] = [];

  @Input()
  get model(): any {
    return this._model;
  }
  set model(value: any) {
    this.displayLabel = true;
    if (this.data) {
      this.data.displayLabel = true;
    }

    this._model = value;
    if (!(this._model)) {
      return;
    }

    this.languages = [];
    if (this.model.AllowLanguageChoice && this._model.Languages.length > 1) {
      this.languages = this._model.Languages;
      this.languageSelected = this._model.Language;
      this._displayLanguages = true;

      if (this.languageSelected == null) {
        this.languageSelected = this.languages[0];
      }
    }

    this.question = this._model.Question;
    this.navigation = this._model.Navigations;
    this.navigationColor = this._model.Style.NavigationColor;
    if (this.navigationColor == 'red' || this.navigationColor == 'blue' || this.navigationColor == 'green') {
      this.navigationTextColor = '#ffffff';
    } else {
      this.navigationTextColor = '#000000';
    }
    this.controlsColor = this._model.Style.ControlsColor;
  }
  private _model: any;

  @Input()
  get playerWidth(): number {
    return this._playerWidth;
  }
  set playerWidth(value: number) {
    this._playerWidth = value;
  }
  private _playerWidth: number = 0;

  @Input()
  get show(): boolean {
    return this._show;
  }
  set show(value) {
    this._show = value;
  }
  private _show = false;

  @Input()
  get preview(): boolean {
    return this._preview;
  }
  set preview(value: boolean) {
    this._preview = value;
  }
  private _preview = false;

  @Output()
  navigate: EventEmitter<this> = new EventEmitter<this>();

  @Output()
  changeLanguage: EventEmitter<this> = new EventEmitter<this>();

  registerOnNavigate(fn: any): void {
    this.onNavigate = fn;;
  }

  onNavigate(element: any) {
    this.type = element.type;
    if (!this._preview) {
      this.answers = this.data?.answers ?? [];
    }

    if (this.type == 0) {
      if (this.valid) {
        this.navigate.emit(this);
      }

      return;
    }

    this.navigate.emit(this);
  }

  get valid(): boolean {
    return this.data?.valid ?? false;
  }
}
