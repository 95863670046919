<div *ngIf="showButton">
  <button mat-icon-button matTooltip="Pipe Information" aria-label="Pipe" [matMenuTriggerFor]="menu" style="width:{{sizePlus}}px;height:{{sizePlus}}px;" class="fieldSlctr-pipe-icon-button">
    <mat-icon style="font-size:{{size}}px">colorize</mat-icon>
  </button>
</div>
<div *ngIf="!showButton" [formGroup]="formGroup">
  <mat-form-field appearance="outline">
    <mat-label> Field </mat-label>
    <input matInput placeholder="Field" formControlName="Name">
    <button matSuffix mat-icon-button aria-label="Clear" [matMenuTriggerFor]="menu">
      <mat-icon>arrow_drop_down</mat-icon>
    </button>
  </mat-form-field>
</div>
<mat-menu #menu="matMenu">
  <ng-template matMenuContent>
    <button *ngIf="displayQuestions.length > 0" mat-menu-item [matMenuTriggerFor]="questionMenu">Questions</button>
    <button *ngIf="displayQuestions.length > 0 && displayEmbeddedData.length > 0" mat-menu-item [matMenuTriggerFor]="embeddedMenu">Embedded Data</button>
    <button mat-menu-item [matMenuTriggerFor]="panelMenu">Panel Data</button>
    <button *ngIf="displayQuestions.length == 0" mat-menu-item [matMenuTriggerFor]="messageMenu">Message Data</button>
    <button *ngIf="displayQuestions.length > 0" mat-menu-item [matMenuTriggerFor]="surveyMenu">Survey Data</button>
    <button *ngIf="purpose == 'import'" mat-menu-item [matMenuTriggerFor]="specialMenu">Special</button>
  </ng-template>
</mat-menu>

<mat-menu #questionMenu="matMenu">
  <ng-template matMenuContent>
    <button mat-menu-item *ngFor="let question of displayQuestions;let i = index" [matMenuTriggerFor]="questionFormatMenu" [matMenuTriggerData]="{question: question}">
      <span>{{question.Summary}}</span>
    </button>
  </ng-template>
</mat-menu>

<mat-menu #embeddedMenu="matMenu">
  <ng-template matMenuContent>
    <button mat-menu-item *ngFor="let question of displayEmbeddedData;let i = index" [matMenuTriggerFor]="questionFormatMenu" [matMenuTriggerData]="{question: question}">
      <span>{{question.QuestionName}}</span>
    </button>
  </ng-template>
</mat-menu>

<mat-menu #questionFormatMenu="matMenu">
  <ng-template matMenuContent let-question="question">
    <button mat-menu-item *ngFor="let format of getFieldFormats(question)" (click)="selected($event, question, format)">{{format}}</button>
  </ng-template>
</mat-menu>

<mat-menu #specialMenu="matMenu">
  <ng-template matMenuContent>
    <button mat-menu-item *ngFor="let item of specialData;let i = index" (click)="selectSimple($event, item)">{{item.title}}</button>
  </ng-template>
</mat-menu>

<mat-menu #panelMenu="matMenu">
  <ng-template matMenuContent>
    <button mat-menu-item [matMenuTriggerFor]="panelBasicMenu">Basic</button>
    <button mat-menu-item [matMenuTriggerFor]="panelLocationMenu">Location</button>
    <button mat-menu-item *ngIf="displayPanelProfile.length > 0" [matMenuTriggerFor]="panelVariableMenu">Custom</button>
  </ng-template>
</mat-menu>

<mat-menu #panelBasicMenu="matMenu">
  <ng-template matMenuContent>
    <button mat-menu-item *ngFor="let item of panelBasicData;let i = index" (click)="selectSimple($event, item)">{{item.title}}</button>
  </ng-template>
</mat-menu>

<mat-menu #panelLocationMenu="matMenu">
  <ng-template matMenuContent>
    <button mat-menu-item *ngFor="let item of panelLocationData;let i = index">{{item.title}}</button>
  </ng-template>
</mat-menu>

<mat-menu #panelVariableMenu="matMenu">
  <ng-template matMenuContent>
    <button mat-menu-item *ngFor="let item of displayPanelProfile;let i = index" (click)="selectCustom($event, item)">
      <span>{{item.Name}}</span>
    </button>
  </ng-template>
</mat-menu>

<mat-menu #messageMenu="matMenu">
  <ng-template matMenuContent>
    <button mat-menu-item *ngFor="let item of messageData;let i = index" (click)="selectSimple($event, item)">{{item.title}}</button>
  </ng-template>
</mat-menu>

<mat-menu #surveyMenu="matMenu">
  <ng-template matMenuContent>
    <button mat-menu-item *ngFor="let item of surveyData;let i = index" (click)="selectSimple($event, item)">{{item.title}}</button>
  </ng-template>
</mat-menu>

<mat-menu #dateMenu="matMenu">
  <ng-template matMenuContent>
    <button mat-menu-item></button>
  </ng-template>
</mat-menu>

