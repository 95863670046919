<div class="leftFloat">
  <h2>{{transactionType}}</h2>
</div>
<div class="rightFloat">
  <button mat-icon-button mat-dialog-close>
    <mat-icon>close</mat-icon>
  </button>
</div>
<div class="clearBoth"></div>
<div mat-dialog-content [formGroup]="formGroup">
  <div>
    <mat-form-field appearance="outline">
      <mat-label>Transaction Date</mat-label>
      <input matInput [matDatepicker]="picker" formControlName="tranDate">
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-datepicker #picker></mat-datepicker>
    </mat-form-field>
  </div>
  <div>
    <mat-form-field appearance="outline">
      <mat-label>Type</mat-label>
      <mat-select formControlName="tranType">
        <mat-option value="Balance">New Balance</mat-option>
        <mat-option value="Adjustment">Adjustment</mat-option>
        <mat-option value="Panel">Cint Panel</mat-option>
        <mat-option value="Completes">Survey Completes</mat-option>
        <mat-option value="Sms">SMS Messages</mat-option>
        <mat-option value="Hlr">HLR Lookups</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div>
    <mat-form-field appearance="outline">
      <mat-label>Unit</mat-label>
      <mat-select formControlName="unit">
        <mat-option value="Value">Value</mat-option>
        <mat-option value="Completes">Completes</mat-option>
        <mat-option value="Panellists">Panellists</mat-option>
        <mat-option value="Sms">SMS</mat-option>
        <mat-option value="Hlr">HLR</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div>
    <mat-form-field appearance="outline" class="halfWidth">
      <mat-label>Title</mat-label>
      <input type="text" matInput placeholder="Title" formControlName="title">
    </mat-form-field>
  </div>
  <div>
    <mat-form-field appearance="outline" class="fullWidth">
      <mat-label>Description</mat-label>
      <textarea matInput placeholder="Description" formControlName="description"></textarea>
    </mat-form-field>
  </div>
  <div>
    <mat-form-field appearance="outline">
      <mat-label>Your Reference</mat-label>
      <input type="text" matInput placeholder="Your Reference" formControlName="yourRef">
    </mat-form-field>
  </div>
  <div>
    <mat-form-field appearance="outline">
      <mat-label>Reference</mat-label>
      <input type="text" matInput placeholder="Reference" formControlName="reference">
    </mat-form-field>
  </div>
  <div>
    <mat-form-field appearance="outline">
      <mat-label>Net</mat-label>
      <input type="text" matInput placeholder="Net Value" formControlName="net">
    </mat-form-field>
    &nbsp;&nbsp;
    <mat-form-field appearance="outline">
      <mat-label>VAT</mat-label>
      <input type="text" matInput placeholder="VAT Value" formControlName="vatRate">
    </mat-form-field>
    &nbsp;&nbsp;
    <mat-form-field appearance="outline">
      <mat-label>Value</mat-label>
      <input type="text" matInput placeholder="Value" formControlName="value">
    </mat-form-field>
  </div>
  <div>
    <mat-checkbox formControlName="confirmed">Confirmed</mat-checkbox>
  </div>
</div>
<div>
  <button mat-raised-button color="primary" (click)="save()">Save</button>
</div>

