import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { InterviewModel, ReportModel } from '../../question.service';
import { plainToClass } from 'class-transformer';
import { SurveyService } from '../../survey.Service';

@Component({
  selector: 'app-edit-report-name',
  templateUrl: './edit-report-name.component.html',
  styleUrls: ['./edit-report-name.component.css']
})
export class EditReportNameComponent implements OnInit {

  formGroup: FormGroup;
  model: ReportModel = new ReportModel();
  displayError: boolean = false;
  errorMsg: string = "";

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<EditReportNameComponent>,
    private surveyService: SurveyService
  ) {
    this.formGroup = new FormGroup({
      ReportTitle: new FormControl("", Validators.required),
      ReportDescription: new FormControl("", Validators.required)
    });

    this.surveyService.getSurveySummary(Number(data.id)).subscribe(result => {
      if (result.ReportModel === "") {
        const interview = plainToClass(InterviewModel, JSON.parse(result.Script));
        interview.checkModel();
        this.model = new ReportModel(result, interview);
      }
      else {
        this.model = plainToClass(ReportModel, JSON.parse(result.ReportModel));
      }

      if (this.model.Title == null) {
        this.model.Title = result.Name;
      }

      if (this.model.Description == null) {
        this.model.Description = result.Description;
      }

      this.formGroup.controls.ReportTitle.setValue(this.model.Title);
      this.formGroup.controls.ReportDescription.setValue(this.model.Description);
    });

  }

  ngOnInit(): void {
  }

  onSubmit() {
    this.model.Title = this.formGroup.controls.ReportTitle.value;
    this.model.Description = this.formGroup.controls.ReportDescription.value;
    this.surveyService.updateReportModel(Number(this.data.id), this.model, true).subscribe(
      result => {
        this.dialogRef.close({ name : this.model.Title, description: this.model.Description });
      });
  }

}
