import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { options, baseUrl } from "../environments/environment";
import { Observable } from "rxjs";
import { ShortCode } from "./user.Service";

export class Keyword {
  KeywordID: number = 0;
  Created: Date = new Date();
  Amended: Date = new Date();
  Code: string = "";
  AdministratorID: number = 0;
  StartDate: Date = new Date();
  FinishDate: Date = new Date();
  IsDeleted: boolean = false;
  KeywordType: number;
  ActionListID: number = 0;
  Campaign: string = "";
  MetadataId: number = 0;
  ShortCodeReferences: KeywordShortCode[] = [];
  EmailAddress: string = "";
  EmailSubject: string = "";
  EmailBody: string = "";
  Message: string = "";
  constructor() {
    this.KeywordType = 3;
  }
}

export class KeywordShortCode {
  KeywordShortCodeID: number = 0;
  KeywordID: number = 0;
  ShortCodeID: number = 0;
  ShortCode: ShortCode = new ShortCode();
}

export class KeywordModel {
  Keyword: Keyword = new Keyword();
  Code: string = "";
  Numbers: string = "";
  Message: string = "";
  To: string = "";
  Subject: string = "";
  Body: string = "";
  Created: Date = new Date();
  ActionListID: number = 0;
  KeywordType: number = 0;
  Campaign: string = "";
  MetadataId: number = 0;
}

@Injectable()
export class KeywordService {
  constructor(
    private http: HttpClient) {
  }

  public getKeywords(): Observable<any> {
    return this.http.get(baseUrl + 'Keyword/GetKeywords', options);
  }

  public getKeyword(id: string): Observable<any> {
    return this.http.get(baseUrl + 'Keyword/GetById?id=' + id, options);
  }

  public checkKeyword(code: string, networks: string): Observable<Keyword> {
    return this.http.get<Keyword>(baseUrl + 'Keyword/Check?code=' + code + '&networks=' + networks, options);
  }

  public setKeyword(id: string, model: KeywordModel) {
    return this.http.patch<KeywordModel>(baseUrl + 'Keyword/UpdateKeyword?id=' + id, JSON.stringify(model), options);
  }

  public deleteKeyword(id: string) {
    this.http.delete(baseUrl + "Keyword/Delete?id=" + id, options).subscribe();
  }
}
