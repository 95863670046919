import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { finalize, map, Observable, Subject, Subscription, take, takeUntil, timer } from 'rxjs';
import { ProcessErrorModel, ProcessService, ProcessServiceModel } from '../../process.Service';

@Component({
  selector: 'app-file-process',
  templateUrl: './file-process.component.html',
  styleUrls: ['./file-process.component.css']
})
export class FileProcessComponent implements OnInit, OnDestroy {
  private inTimer = false;
  private timer: any | null = null;
  showProcess = false;
  subscription: any | null = null;
  waiting?: Observable<any>;
  waitTime: Observable<any> = timer(0, 1000);
  errors: ProcessErrorModel[] = [];
  processCompleted: boolean = false;

  constructor(
    private processService: ProcessService
  ) {

  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  @Input()
  get showErrors(): boolean {
    return this._showErrors;
  }
  set showErrors(value: boolean) {
    this._showErrors = value;
  }
  _showErrors: boolean = false;

  @Input()
  get process(): ProcessServiceModel {
    return this._process;
  }
  set process(value: ProcessServiceModel) {
    this._process = value;
    if (this._process) {
      this.showProcess = true;
      this.setupTimer();
    }
  }
  private _process: ProcessServiceModel = new ProcessServiceModel();

  private setupTimer() {
    let counter = 5;
    this.timer = setInterval(() => {
      if (this.inTimer) {
        return;
      }

      this.inTimer = true;
      this.updateProgress();
    }, 1000);
  }

  private updateProgress() {

    this.processService.getProcess(this._process.ProcessId).subscribe(
      result => {
        if (result != null) {
          this._process = result;
          switch (this._process.StatusId) {
            case 3:
              this.onCompleted();
              break;
            case 4:
              this.processService.getProcessErrors(this._process.ProcessId, 100).subscribe(result => {
                this.errors = result;
              });
              this.onCompleted();
              break;
          }
        }

        this.inTimer = false;
      }
    )
  }

  @Output()
  completed: EventEmitter<this> = new EventEmitter<this>();

  registerOnCompleted(fn: any): void {
    this.onCompleted = fn;;
  }

  onCompleted() {
    this.processCompleted = true;
    clearInterval(this.timer);
    this.completed.emit(this);
  }
}
