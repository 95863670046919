import { Component, OnInit, Input, AfterViewInit } from '@angular/core';
import { AnswerModel } from "../../models/AnswerModel";
declare var L: any;

@Component({
  selector: 'app-geocode',
  templateUrl: './geocode.component.html',
  styleUrls: ['./geocode.component.css']
})
export class GeocodeComponent implements OnInit, AfterViewInit {

  buttonText: string = "";
  position: string = "";
  hidden: boolean = false;
  coords: number[] = [];
  private map: any;

  static nextId = 0;
  id = `mapquest-geo-${GeocodeComponent.nextId++}`

  constructor() {
    if (L.mapquest.key == "") {
      L.mapquest.key = 'Fmjtd|luub2l0a2d,85=o5-96y0l4';
    }
  }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    this.buttonText = "Get Location";
    this.getPosition().then(() => {
      if (this.position != undefined) {
        this.map = L.mapquest.map(this.id, {
          center: [this.coords[0], this.coords[1]],
          layers: L.mapquest.tileLayer('map'),
          zoom: 15
        });
        L.marker([this.coords[0], this.coords[1]], {
          icon: L.mapquest.icons.marker(),
          draggable: false
        }).bindPopup('You are here!').addTo(this.map);
        this.map.addControl(L.mapquest.control());
        this.hidden = false;
        this.buttonText = "Update Location";
        this.position = "Lat: " + this.coords[0] + ", Long: " + this.coords[1];
      }
      else {
        this.buttonText = "Get Location";
      }
    });
  }

  @Input()
  get preview(): boolean {
    return this._preview;
  }
  set preview(value: boolean) {
    this._preview = value;
    if (this._preview != undefined && this._preview) {
      this.id = 'mapquest-geo-0';
    }
  }
  private _preview = false;

  @Input()
  get question(): any {
    return this._question;
  }
  set question(value: any) {
    this._question = value;
    if (this._question === undefined) {
      return;
    }

    if (this._question.Response.Value === null || this._question.Response.Value === "") {
      return;
    }

    this.coords = this._question.Response.Value.split(",");
    if (this.coords.length !== 2) {
      return;
    }

    Promise.resolve(null).then(() => {
      this.buttonText = "Update Location";
      this.position = "Lat: " + this.coords[0] + ", Long: " + this.coords[1];
    });
  }
  private _question: any;

  getLocation() {
    this.buttonText = "Update Location";
    this.hidden = false;
    this.getPosition().then(pos => {
      if (this.map != undefined) {
        this.map.remove();
      }

      this.map = L.mapquest.map(this.id, {
        center: [pos.lat, pos.lng],
        layers: L.mapquest.tileLayer('map'),
        zoom: 15
      });
      this.position = "Lat: " + pos.lat + ", Long: " + pos.lng;
      L.marker([pos.lat, pos.lng], {
        icon: L.mapquest.icons.marker(),
        draggable: false
      }).bindPopup('You are here!').addTo(this.map);
      this.map.addControl(L.mapquest.control());
    });
  }

  getPosition(): Promise<any>
  {
    return new Promise((resolve, reject) => {
      try {
        navigator.geolocation.getCurrentPosition(resp => {
          resolve({lng: resp.coords.longitude, lat: resp.coords.latitude});
          this.coords = [];
          this.coords.push(resp.coords.latitude);
          this.coords.push(resp.coords.longitude);
        },
        err => {
          reject(err);
        });
      }
      catch (s) {}
    });
  }

  get answers(): AnswerModel[] {
    const model: AnswerModel[] = [];
    model.push(new AnswerModel(this._question.QuestionFullName, this.coords.toString()));
    return model;
  }

  get valid(): boolean {
    return true;
  }
}
