<ngx-charts-line-chart [animations]="false"
                          [view]="view"
                       [scheme]="colorScheme"
                       [legend]="legend"
                       [showXAxisLabel]="showXAxisLabel"
                       [showYAxisLabel]="showYAxisLabel"
                       [xAxis]="showXAxis"
                       [yAxis]="showYAxis"
                       [xAxisLabel]="xAxisLabel"
                       [yAxisLabel]="yAxisLabel"
                       [results]="results"
                       (select)="onSelect($event)"
                       (activate)="onActivate($event)"
                       (deactivate)="onDeactivate($event)">
</ngx-charts-line-chart>
