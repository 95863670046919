import { Component, Inject, Input, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { QuestionModel } from '../../question.service';

@Component({
  selector: 'app-randomise',
  templateUrl: './randomise.component.html',
  styleUrls: ['./randomise.component.css']
})
export class RandomiseComponent implements OnInit {

  constructor(private fb: FormBuilder) {
  }

  public dataFormGroup: FormGroup = new FormGroup({});

  ngOnInit(): void {
    this.dataFormGroup = this.fb.group({
      name:[this._question.QuestionName],
      number: [this._question.InitialValue],
      embeddedOption: this.fb.array([this.initEmbeddedDataRow(new QuestionModel(), 0)])
    });
    this.dataFormGroup.controls.name.valueChanges.subscribe(x => {
      this._question.QuestionName = x;
      this._question.QuestionNameTouched = true;
    });
    this.dataFormGroup.controls.number.valueChanges.subscribe(x => {
      this._question.InitialValue = x;
    });
    this.loadData();
  }

  isValid(index: number): boolean {
    return true;
  }

  questionsForDropdown(): QuestionModel[] {
    return this._question.Interview.questionList(false, [], "randomise");
  }

  addEmbeddedData(index: number) {
    const question = new QuestionModel();
    if (index + 1 === this._question.Questions.List.length) {
      this._question.Questions.List.push(question);
      this.dataArr.controls.push(this.initEmbeddedDataRow(question, index + 1));
    }
    else {
      this._question.Questions.List.splice(index + 1, 0, question);
      this.dataArr.controls.splice(index + 1, 0, this.initEmbeddedDataRow(question, index + 1));
    }
  }

  deleteEWmbeddedData(index: number) {
    if (index < this._question.Questions.List.length) {
      this._question.Questions.List.splice(index, 1);
      this.dataArr.controls.splice(index, 1);
    }
  }

  initEmbeddedDataRow(question: QuestionModel, index: number) {
    const formGroup =  this.fb.group({
      name: [question.QuestionId],
      questionData: [question],
      index: [index]
    });

    formGroup.valueChanges.subscribe(x => {
      const index = x.index as number;
      const question = this._question.Interview.findQuestion(x.name as string);
      if (question != null && question.QuestionName !== "") {
        if (index >= this._question.Questions.List.length) {
          this._question.Questions.List.push(new QuestionModel());
        }
        const q = this._question.Questions.List[index];
        q.QuestionId = question.QuestionId;
        q.QuestionName = question.QuestionName;
        q.QuestionType = question.QuestionType;
        this._question.Questions.List[index] = q;
      }
    });

    return formGroup;
  }

  @Input()
  get question(): QuestionModel {
    return this._question;
  }
  set question(value: QuestionModel) {
    this._question = value;
  }
  private _question: QuestionModel;

  get dataArr(): FormArray {
    return this.dataFormGroup.get('embeddedOption') as FormArray;
  }

  lineItem(index: number): FormGroup {
    return this.dataArr.controls[index] as FormGroup;
  }

  loadData() {
    if ( this.dataFormGroup === undefined) {
      return;
    }

    this.dataArr.clear();
    this.dataArr.controls.push(this.initEmbeddedDataRow(new QuestionModel(), 0));
    if (this._question === undefined) {
      return;
    }

    if (this._question.Questions.List.length === 0) {
      return;
    }

    this.dataArr.clear();
    let i = 0;
    this._question.Questions.List.forEach(question => {
      this.dataArr.push(this.initEmbeddedDataRow(question, i++));
    });
  }


}
