<mat-toolbar class="mat-toolbar-container mat-elevation-z2">
  <a [routerLink]="'/'"><img src="assets/img/logo.svg" class="toolbar-logo" /></a>
  <span class="toolbar-spacer"></span>
  <div [class]="(isHandset$ | async) ? 'noDisplay' : ''">
    <a href="mailto:hello@diysurveys.com" class="toolbar-hellodiysurveyscom">hello&#64;diysurveys.com</a>&nbsp;&nbsp;
    <button mat-raised-button color="accent" [routerLink]="'/signup'">SIGN UP</button>
  </div>
</mat-toolbar>
<div class="toolbar-handset-container">
  <div class="toolbar-handset">
    <a [routerLink]="'/'"><img src="assets/img/logo.svg" class="toolbar-logo" /></a>
  </div>
  <div class="login-signup-div-handset" [class]="(isHandset$ | async) ? '' : 'noDisplay'">
    <button mat-raised-button color="accent" [routerLink]="'/signup'" class="loginPage-signup-btn-handset">SIGN UP</button>
  </div>
</div>
<div class="diy-landing-content-bgColor">
  <mat-grid-list [cols]="(isHandset$ | async) ? '1' : '4'" rowHeight="24vh">
    <mat-grid-tile [colspan]="(isHandset$ | async) ? '1' : '1'" [rowspan]="(isHandset$ | async) ? '3' : '4'" class="diy-landing-gridList-bgColor">
      <form fxLayoutAlign="stretch" fxLayout="column" class="login-form" [formGroup]="formGroup" (ngSubmit)="loginProcess()">
        <div class="column-flex">
          <div class="txt-align-left" [style]="(isHandset$ | async) ? 'margin-bottom: 10px;' : 'margin-bottom: 15px;'">
            <span class="font-wt-500" W
                  [style]="(isHandset$ | async) ? 'margin-bottom: 10px; font-size: 20px;': 'font-size: 20px;'">Log in to your account</span>
          </div>
          <div>
            <mat-form-field appearance="outline" class="fullWidth">
              <mat-label>Email</mat-label>
              <input matInput placeholder="Email" formControlName="email">
            </mat-form-field>
          </div>
          <div class="padding-5">
          </div>
          <div>
            <mat-form-field appearance="outline" class="password-field fullWidth">
              <mat-label>Password</mat-label>
              <input matInput [type]="hide ? 'password' : 'text'" placeholder="Password" formControlName="password">
              <mat-icon class="reveal" matSuffix (click)="hide = !hide">{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
              <mat-error *ngIf="formGroup.controls.password.invalid">{{passwordError()}}</mat-error>
            </mat-form-field>
          </div>
          <mat-hint class="txt-align-right font-size-12 mt-10"><a [routerLink]="'/forgotpassword'" class="login-forgotPwd-hint">Forgot Password?</a></mat-hint>
          <button mat-raised-button type="submit" color="primary">LOG IN</button>
          <div *ngIf="displayWait">
            <mat-progress-bar mode="indeterminate"></mat-progress-bar>
          </div>
          <div *ngIf="!displayWait" class="height-4">

          </div>
          <div class="or-separator">
            <span>or Log in with</span>
          </div>
          <div class="login-social-container">
            <div class="login-google-container">
              <asl-google-signin-button type="icon" size="medium"></asl-google-signin-button>
            </div>
            <div class="social-login-separator">
            </div>
            <a mat-stroked-button href="#" (click)="logInWithFacebook()" class="login-fb-container">
              <img src="assets/img/icon-facebook.png" />
            </a>
          </div>
        </div>
        <div class="login-needAcct-div">Need an account?  <a [routerLink]="'/signup'" class="login-signup-link">Sign Up</a></div>
      </form>
    </mat-grid-tile>
    <mat-grid-tile [colspan]="(isHandset$ | async) ? '1' : '3'" [rowspan]="(isHandset$ | async) ? '3' : '2'" class="login-tile2-container" [class]="(isHandset$ | async) ? '' : 'diy-landing-grid-tile'">
      <div class="column-flex"
           [style]="(isHandset$ | async) ? 'padding: 30px;' : 'padding: 60px 30px 30px 30px;'">
        <div class="login-tile2-headerSpan-div">
          <span class="login-tile2-header-span"
                [style]="(isHandset$ | async) ? 'font-size: 24px;' : 'font-size: 28px;'">Today is a great day to use DIY Surveys...</span>
        </div>
        <div class="login-tile2-list-div">
          <div>
            <ol>
              <li class="noDisplay"><span>Sign up for your license free account</span></li>
              <li><span>Log into your free account</span></li>
              <li><span>Create a survey in minutes – 23 pre-programmed question types. Not a survey expert - Use our survey templates</span></li>
              <li><span>Upload your respondents, or use ours - 306+ million people, 130_ countries, 50+ demographics</span></li>
              <li><span>Preview the costs, launch your survey, see your results in real-time</span></li>
            </ol>
          </div>
        </div>
      </div>
    </mat-grid-tile>
    <mat-grid-tile [colspan]="(isHandset$ | async) ? '1' : '3'" [rowspan]="(isHandset$ | async) ? '2' : '2'" class="wrapFlex" [style]="(isHandset$ | async) ? 'margin-bottom: 45px;' : ' '">
      <div class="full-width-height txt-align-center" [style]="(isHandset$ | async) ? 'padding: 0 0 80px 0;' : 'padding: 0 0 15px 0;'">
        <img src="assets/img/sign-in-image@2x.png" class="full-width-height" />
      </div>
    </mat-grid-tile>
  </mat-grid-list>
  <div class="fixed-footer" [class]="(isHandset$ | async) ? 'noDisplay' : ''">
    <div class="footer-container">
      <div class="footer-left-content">
        <div class="footer-version-div">
          <span>&#169;{{ currentYear }} DIY Surveys Ltd </span>&nbsp;<span>{{currentVersion}}</span>
        </div>
        <div class="footer-terms-div">
          <span><a (click)="openTerms()" class="cursor-pt" [innerHTML]="(isHandset$ | async) ? 'Terms' : 'Terms of Service'"></a></span>
        </div>
        <div class="footer-privacy-div">
          <span><a (click)="openPrivacy()" class="cursor-pt" [innerHTML]="(isHandset$ | async) ? 'Privacy' : 'Privacy Statement'"></a></span>
        </div>
      </div>
    </div>
  </div>
  <div class="handset-footer" [class]="(isHandset$ | async) ? '' : 'noDisplay'">
    <div class="handset-footer-email-container">
      <div>
        <a href="mailto:hello@diysurveys.com" class="handset-footer-email"><span>hello&#64;diysurveys.com</span></a>
      </div>&nbsp;&nbsp;&nbsp;&nbsp;
    </div>
    <div class="handset-footer-bottom-container">
      <div>
        <span>&#169;{{ currentYear }} <br />DIY Surveys Ltd </span>
      </div>
      <div>
        <span><a (click)="openTerms()" class="cursor-pt">Terms of <br />Service</a></span>
      </div>
      <div>
        <span><a (click)="openPrivacy()" class="cursor-pt">Privacy <br />Statement</a></span>
      </div>
    </div>
  </div>
</div>
