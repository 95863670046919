<div>
  <div class="impersonating-container">
    <mat-icon *ngIf="isImpersonating() !== ''" matTooltip=" Impersonating ">supervisor_account</mat-icon>
  </div>
  <div class="user-menu-button-div">
    <button *ngIf="selectedOrg" [matTooltip]="selectedOrg.name" mat-button [matMenuTriggerFor]="menu" class="user-profile-name">
      <app-avatar [size]="30" name="{{selectedOrg.name}}" [media]="image"></app-avatar>
      <mat-icon *ngIf="isOrganization() !== -1" class="orgAvatarIcon">supervised_user_circle</mat-icon>
    </button>
  </div>
  <mat-menu #menu="matMenu">
    <ng-template matMenuContent>
      <div class="custom-scroll-bar user-menu-content">
        <button mat-menu-item *ngFor="let org of orgList; let i = index" (click)="makeSelection(i, $event)" [class]="org.selected ? 'orgSelected' : ''">
          <img *ngIf="org.media != null" [src]="org.media | safe: 'resourceUrl'" alt="Profile Image" class="user-menu-avatarIcon">
          <mat-icon *ngIf="org.media == null">{{org.icon}}</mat-icon>
          {{org.name}}
        </button>
      </div>
      <mat-divider></mat-divider>
      <button mat-menu-item [routerLink]="['/profile']"><mat-icon>account_box</mat-icon>My Account</button>
      <button mat-menu-item *ngIf="isPageTour" (click)="takeATour()"><mat-icon>tour</mat-icon>Start Page Tour</button>
      <button mat-menu-item [routerLink]="['/login']"><mat-icon>exit_to_app</mat-icon>Logout</button>
      <mat-divider *ngIf="isImpersonating()"></mat-divider>
      <button *ngIf="isImpersonating()" mat-menu-item (click)="stopImpersonating()"><mat-icon>people_alt</mat-icon>Finish Impersonating</button>
      <mat-divider></mat-divider>
      <button mat-menu-item (click)="goToLink('https://diysurveys.zendesk.com/')"><mat-icon>help</mat-icon>DIY Surveys Support</button>
      <button mat-menu-item (click)="goToLink('https://apidocs.diysurveys.com/')"><mat-icon>api</mat-icon>DIY Surveys API Documentation</button>
      <mat-divider></mat-divider>
      <button mat-menu-item>Last Logged In: {{lastLoggedIn | date:'dd/MM/yy, hh:mm a':'BST'}}</button>
    </ng-template>
  </mat-menu>
</div>
<ng-template #loading>
  <div class="loading-container">
    <mat-spinner [diameter]="25"></mat-spinner>
  </div>
</ng-template>
