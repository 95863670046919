import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MediaService } from '../media.Service';
import { PanelModel, PanelService } from '../panel.Service';

@Component({
  selector: 'app-panel-selector-template',
  templateUrl: './panel-selector-template.component.html',
  styleUrls: ['./panel-selector-template.component.css']
})
export class PanelSelectorTemplateComponent implements OnInit {

  panels: PanelModel[] = [];
  media: Map<number, string> = new Map<number, string>();

  constructor(
    private panelService: PanelService,
    private mediaService: MediaService,
    )
  {
    this.panelService.getPanelTemplates().subscribe(result => this.panels = result as PanelModel[]);
  }

  ngOnInit(): void {
  }

  Media(index: number): string {
    if (this.media.has(index)) {
      return this.media.get(index) ?? "";
    }

    if (this.panels[index].MediaID > 0) {
      this.media.set(index, "");
      this.mediaService.getMediaById(this.panels[index].MediaID).subscribe(
        result => { this.media.set(index, result); });
    }

    return this.media.get(index) ?? "";
  }

  panellistCount(index: number) {
    let text = this.panels[index].PanellistCount.toString() + " ";
    if (this.panels[index].PanellistCount === 1) {
      return text + "Panellist";
    }

    return text + "Panellists";
  }

  @Input()
  get panelId(): number {
    return this._panelId;
  }
  set panelId(value: number) {
    this._panelId = value;
  }
  private _panelId : number = 0;


  @Output()
  selected: EventEmitter<PanelModel> = new EventEmitter<PanelModel>();

  registerOnSelected(fn: any): void {
    this.onSelectedAction = fn;;
  }

  onSelectedAction(index: number) {
    this.panelService.copyTemplatePanel(this.panels[index].PanelID).subscribe(
      result => {
        this._panelId = result as number
        this.panels[index].PanelID = this._panelId;
        this.selected.emit(this.panels[index]);
      });
  }
}
