<div style="margin-left:15px;margin-right:15px;">
  <div>
    <input type="file" #file placeholder="Choose file" (change)="uploadFile(file.files)" class="noDisplay" [accept]="accept">
    <button mat-raised-button color="primary" (click)="file.click()">{{ text }}</button>
  </div>
  <div>
    <span class="upload" *ngIf="progress > 0">
      {{progress}}
      <mat-progress-bar mode="determinate" [value]="progress"></mat-progress-bar>
    </span>
    <span class="upload" *ngIf="message">
      {{message}}
    </span>
  </div>
</div>
