import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormArray, FormGroup, Validators } from '@angular/forms';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { BaseQuestion, DataModel, InterviewService, ParameterModel, QuestionModel, QuestionService } from '../../question.service';

@Component({
  selector: 'app-send-email',
  templateUrl: './send-email.component.html',
  styleUrls: ['./send-email.component.css']
})
export class SendEmailComponent extends BaseQuestion implements OnInit {
  constructor(private breakpointObserver: BreakpointObserver, private qs: QuestionService, private is: InterviewService) {
    super(qs, is, undefined, undefined, new DataModel("sendEmail"));
  }

  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
    .pipe(map(result => result.matches));


  public sendEmailFormGroup: FormGroup = new FormGroup({});

  ngOnInit() {
    this.sendEmailFormGroup = this.fb.group({
      sendEmailFormArray: this.fb.array([this.initQuestion()])
    });
    this.sendEmailFormGroup.valueChanges.subscribe(x => {
      let array = this.sendEmailFormGroup.controls.sendEmailFormArray as FormArray;
      let form = array.controls[0] as FormGroup;
      if (this.model.QuestionName !== form.controls.qNumber.value) {
        this.model.QuestionName = form.controls.qNumber.value;
        this.model.QuestionNameTouched = true;
      }
      this.model.Banner = form.controls.banner.value;
      this.model.Parameters = [];
      this.model.Parameters.push(this.createParameterModel("from", form.controls.from.value));
      this.model.Parameters.push(this.createParameterModel("to", form.controls.to.value));
      this.model.Parameters.push(this.createParameterModel("cc", form.controls.cc.value));
      this.model.Parameters.push(this.createParameterModel("bcc", form.controls.bcc.value));
      this.model.Parameters.push(this.createParameterModel("subject", form.controls.subject.value));
    });
  }

  createParameterModel(name: string, value: string): ParameterModel {
    let model = new ParameterModel();
    model.Name = name;
    model.Value = value;
    return model;
  }

  get questionArr(): FormArray | null {
    if (this.sendEmailFormGroup) {
      return this.sendEmailFormGroup.get('sendEmailFormArray') as FormArray;
    }

    return null;
  }

  getParameter(name: string): string {
    for (let i = 0; i < this.model.Parameters.length; i++) {
      if (this.model.Parameters[i].Name === name) {
        return this.model.Parameters[i].Value;
      }
    }

    return "";
  }

  initQuestion() {
    return this.fb.group({
      qNumber: [this.model.QuestionName, Validators.required],
      from: [this.getParameter("from"), Validators.required],
      to: [this.getParameter("to"), Validators.required],
      cc: [this.getParameter("cc")],
      bcc: [this.getParameter("bcc")],
      subject: [this.getParameter("subject"), Validators.required],
      banner: [this.model.Banner]
    });
  }
}
