import { Component, OnInit, Input, AfterViewInit, ChangeDetectorRef, AfterContentInit, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { AnswerModel } from "../../models/AnswerModel";
import { BreakpointObserver } from '@angular/cdk/layout';
import { MatSlider } from '@angular/material/slider';

@Component({
  selector: 'app-float',
  templateUrl: './float.component.html',
  styleUrls: ['./float.component.scss']
})
export class FloatComponent implements OnInit, AfterViewInit, AfterContentInit {
  display: boolean = false;
  isSingleLine = false;
  isSlider = false;
  isStar = false;
  isRadio = false;
  isLink = false;
  hasNA = false;
  naCode: any;
  placeholder = "";
  mask = "";
  value: number = 0;
  minimum: number = 0;
  displayMin: number = 0;
  max: number = 0;
  step: number = 0;
  cells: number[] = [];
  labels: TempLabel[] = [];
  columns: number = 0;

  minString = "";
  maxString = "";

  orientation: number = 2;

  formGroup: FormGroup;

  @ViewChild('floating') inputField: any;
  @ViewChild('slider') slider: any;

  isHandset$: boolean = false;

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private breakpointObserver: BreakpointObserver,
  ) { }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
  }

  ngAfterContentInit(): void {
    this.populateForm();
  }

  formatLabel(value: number): string {
    let temp = this as any;
    if (value <= temp.min) {
      return "";
    }

    return value.toString();
  }

  onRate($event: any) {
    this.value = $event.newValue;
  }

  @Input()
  get question(): any {
    return this._question;
  }
  set question(value: any) {
    this._question = value;
    if (this._question === undefined) {
      return;
    }

    if (this._question.Style.Placeholder !== "" && this._question.Style.Placeholder !== null) {
      this.placeholder = this._question.Style.Placeholder;
    }

    if (this._question.Style.Mask !== "" && this._question.Style.Mask !== null) {
      this.mask = this._question.Style.Mask;
    }
    else {
      this.mask = "0*.0*";
    }

    if (this._question.Response.Value !== null) {
      this.value = this._question.Response.Value;
    }

    Promise.resolve(null).then(() => {
      this.populateForm();
    });
  }
  private _question: any;

  populateForm() {
    if (this.formGroup === undefined || this.formGroup.controls.float === undefined) {
      this.formGroup = new FormGroup({
        float: new FormControl("", [Validators.required]),
        slider: new FormControl(-1, [Validators.required]),
        radio: new FormControl("", [Validators.required]),
        link: new FormControl("", [Validators.required]),
        NA: new FormControl(false)
      });
    }

    this.isRadio = false;
    this.isSlider = false;
    this.isStar = false;
    this.isSingleLine = false;
    this.isLink = false;
    this.hasNA = false;
    this.maxString = this._question.Style.Slider?.High.Text;
    this.minString = this._question.Style.Slider?.Low.Text;
    this.value = this._question.Response.Value;
    if (this.value == null) {
      this.value = this._question.Response.Initial;
    }

    for (let i = 0; i < this._question.Codes.Items.length; i++) {
      if (this._question.Codes.Items[i].Name == 'NA') {
        this.hasNA = true;
        this.naCode = this._question.Codes.Items[i];
      }
    }

    switch (this._question.Style.Control.Type) {
      case 0x0006:
        this.isRadio = true;
        this.minimum = this._question.Validation.MinValue;
        this.displayMin = this.minimum;
        this.max = this._question.Validation.MaxValue;
        this.columns = this.max - this.minimum + 1;
        this.cells = [];
        for (let i = this.minimum; i <= this.max; i++) {
          this.cells.push(i);
        }
        if (this._question.Validation.Step <= 0) {
          this.step = 1;
        }
        else {
          this.step = this._question.Validation.Step
        }
        this.orientation = this._question.Style.Orientation;
        break;
      case 0x1001:
        this.isSlider = true;
        this.minimum = this._question.Validation.MinValue;
        this.displayMin = this.minimum - 1;
        if (this.value == null) {
          this.value = this.minimum - 1;
        }

        this.max = this._question.Validation.MaxValue;
        this.columns = this.max - this.minimum + 2;
        this.cells = [];
        for (let i = this.minimum - 1; i <= this.max; i++) {
          this.cells.push(i);
        }

        if (this._question.Validation.Step <= 0) {
          this.step = 1;
        }
        else {
          this.step = this._question.Validation.Step
        }

        this.orientation = this._question.Style.Orientation;
        break;
      case 0x1007:
        this.isStar = true;
        this.orientation = this._question.Style.Orientation;
        this.minimum = this._question.Validation.MinValue;
        this.max = this._question.Validation.MaxValue;
        break;
      case 0x100F:
        this.isLink = true;
        this.isRadio = false;
        this.orientation = this._question.Style.Orientation;
        this.minimum = this._question.Validation.MinValue;
        this.max = this._question.Validation.MaxValue;
        this.labels = [];
        for (let i = this.minimum; i <= this.max; i++) {
          switch (i) {
            case this.minimum:
              this.labels.push(new TempLabel(i, i + " - " + this.minString));
              break;
            case this.max:
              this.labels.push(new TempLabel(i, i + " - " + this.maxString));
              break;
            default:
              this.labels.push(new TempLabel(i, i.toString()));
              break;
          }
        }

        break;
      default:
        this.isSingleLine = true;
        this.maxString = this.minString = "";
        this.formGroup.controls.float.clearValidators();
        if (this._question.MustAnswer) {
          this.formGroup.controls.float.addValidators(Validators.required);
        }
    }

    if (this.formGroup && this.formGroup.controls.slider) {
      this.formGroup.patchValue({
        slider: this.value,
        float: this.value,
        radio: this.value,
        link: this.value
      });
    }

    Promise.resolve(null).then(() => {
      this.display = true;
    });
  }

  get answers(): AnswerModel[] {
    const model: AnswerModel[] = [];
    if (this.isSlider) {
      if (this.formGroup.controls.slider.value !== undefined && this.formGroup.controls.slider.value !== this.displayMin) {
        model.push(new AnswerModel(this._question.QuestionFullName, this.formGroup.controls.slider.value.toString()));
      }
    }
    else if (this.isRadio) {
      if (this.formGroup.controls.radio.value !== undefined && this.formGroup.controls.radio.value !== null) {
        model.push(new AnswerModel(this._question.QuestionFullName, this.formGroup.controls.radio.value.toString()));
      }
    }
    else if (this.isStar) {
      let value = "";
      if (this.value !== undefined && this.value !== null) {
        value = this.value.toString();
      }
      model.push(new AnswerModel(this._question.QuestionFullName, value));
    }
    else if (this.isLink) {
      if (this.formGroup.controls.link.value !== undefined && this.formGroup.controls.link.value !== null) {
        model.push(new AnswerModel(this._question.QuestionFullName, this.formGroup.controls.link.value));
      }
    }
    else {
      if (this.formGroup.controls.float.value !== null && this.formGroup.controls.float.value !== undefined) {
        model.push(new AnswerModel(this._question.QuestionFullName, this.inputField.nativeElement.value));
      }
    }

    if (this.hasNA && this.formGroup.controls.NA.value == true) {
      model.push(new AnswerModel(this._question.QuestionFullName + ".NA", "NA"))
    }

    return model;
  }

  get valid(): boolean {
    return true;
  }
}

class TempLabel {
  value: number;
  label: string;
  constructor(value: number, label: string) {
    this.value = value;
    this.label = label;
  }
}
