<div class="txt-align-center mb-10">
  <h1>Welcome to</h1>
  <img src="assets/img/logo.svg" class="feature-tour-logo" />
</div>
<div class="feature-tour-content-div">
  <div class="start-tour-content-div">
    <div class="start-tour-content">
      <div class="tour-icon-div">
        <mat-icon class="tour-icon">tour</mat-icon>
      </div>
      <div class="start-tour-content-span">
        <span>Would you like to take a tour before you start?</span>
      </div>
    </div>
    <div class="wd-30-per txt-align-center">
      <button mat-raised-button class="tour-start-btn" (click)="startHomeTour()">Start</button>
    </div>
  </div>
  <div class="whatsnew-content-div">
    <div class="whatsnew-content">
      <div class="tour-icon-div">
        <mat-icon class="tour-icon">auto_awesome</mat-icon>
      </div>
      <div class="start-tour-content-span">
        <span>Or find out what''s new</span>
      </div>
    </div>
    <div class="wd-30-per txt-align-center">
      <button mat-raised-button class="whatsnew-start-btn">Check</button>
    </div>
  </div>
  <div mat-dialog-actions class="feature-tour-closeBtn-div">
    <button mat-raised-button class="dialog-close-btn" (click)="onDialogClose()">Close</button>
  </div>
</div>
