import { Component, OnInit, Input, AfterViewInit, OnDestroy, ElementRef, ViewChild, ViewChildren, QueryList } from '@angular/core';
import { FormBuilder, FormArray, FormGroup, Validators } from '@angular/forms';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { QuestionModel, CategoryModel, QuestionService, ParameterModel, LabelModel } from '../../question.service';
import { AdvancedAnswerOptionComponent } from '../advanced-answer-option/advanced-answer-option.component';
import { moveItemInArray } from '@angular/cdk/drag-drop';
import { UserFeatures } from '../../user.Service';

@Component({
  selector: 'app-answer-options',
  templateUrl: './answer-options.component.html',
  styleUrls: ['./answer-options.component.css']
})
export class AnswerOptionsComponent implements OnInit {
  optionText = "Answer Option";
  options: Option[] = [];
  isBulk: boolean = false;
  bulkText: string = "Bulk Edit";
  form: FormGroup;

  constructor(private breakpointObserver: BreakpointObserver, private qs: QuestionService, private elem: ElementRef, private fb: FormBuilder) {
    this.form = this.fb.group({
      options: ['']
    });
    this.form.valueChanges.subscribe(x => {
      this.onChange();
    });
  }

  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
    .pipe(map(result => result.matches));

  ngOnInit(): void {
  }

  onSort() {
    if (this.isBulk) {
      let temp = this.form.controls.options.value.split("\n");
      temp.sort(function (a: any, b: any) {
        return a.toLowerCase().localeCompare(b.toLowerCase());
      });
      let option = "";
      for (let i = 0; i < temp.length; i++) {
        if (temp[i].trim() != "") {
          option += temp[i] + "\n";
        }
      }

      this.form.controls.options.setValue(option);
    }
    else {
      if (this.useParameters) {
        let temp: Option[] = [];
        for (let i = 0; i < this.options.length; i++) {
          let option = this.options[i].value as ParameterModel;
          let added = false;
          for (let j = 0; j < temp.length; j++) {
            let toption = temp[j].value as ParameterModel;
            if (option.Value.localeCompare(toption.Value) < 0) {
              if (!added) {
                temp.splice(j, 0, this.options[i]);
                added = true;
              }
            }
          }

          if (!added) {
            temp.push(this.options[i]);
          }
        }

        this.options = temp;
      }
      else {
        let temp: Option[] = [];
        for (let i = 0; i < this.options.length; i++) {
          let option = this.options[i].value as CategoryModel;
          let added = false;
          for (let j = 0; j < temp.length; j++) {
            let toption = temp[j].value as CategoryModel;
            if (this.isSms) {
              if (option.Name.localeCompare(toption.Name) < 0) {
                if (!added) {
                  temp.splice(j, 0, this.options[i]);
                  added = true;
                }
              }
            }
            else {
              if (option.Label.localeCompare(toption.Label) < 0) {
                if (!added) {
                  temp.splice(j, 0, this.options[i]);
                  added = true;
                }
              }
            }
          }

          if (!added) {
            temp.push(this.options[i]);
          }
        }

        this.options = temp;
      }

      this.updateModel();
      if (this.options.length == 0) {
        this.addNewOption();
      }
    }
  }

  onChange() {
    let temp = this.form.controls.options.value.split("\n");
    this.options = [];
    for (let i = 0; i < temp.length; i++) {
      if (temp[i].trim() != "") {
        if (this.useParameters) {
          let opts = [];
          opts = temp[i].split(':');
          opts[0] = opts[0].replace("\x00", "");
          const parameter = new ParameterModel();
          parameter.Value = opts[0]?.trim()
          parameter.Name = opts.length > 1 ? opts[1]?.trim() : "";
          this.options.push(new Option(parameter.Name, parameter));
        }
        else {
          let option = temp[i].split(':');
          let category;
          let opts = [];
          if (option.length === 1) {
            opts = temp[i].split(',');
            category = this.createCategory(i, opts[0].trim());
          }
          else {
            opts = option[1].split(',');
            category = new CategoryModel(i, option[0].trim(), opts[0].trim());
          }

          for (let j = 1; j < opts.length; j++) {
            switch (opts[j].trim().toLowerCase()) {
              case "fixed":
                category.Fixed = true;
                break;
              case "ex":
                category.Exclusive = true;
                break;
              case "other":
                category.Other = new QuestionModel();
                category.Other.QuestionType = "textbox";
                category.Other.Banner = opts[0].trim();
                break;
              default:
                category.Factor = opts[j];
                break;
            }
          }

          this.options.push(new Option(category.Name, category));
        }
      }
    }

    if (this.options.length == 0) {
      this.addNewOption();
    }

    this.updateModel();
  }

  onBulkEdit() {
    this.isBulk = !this.isBulk;
    this.bulkText = !this.isBulk ? "Bulk Edit" : this.label + " Edit";
    if (this.isBulk) {
      let temp = "";
      for (let i = 0; i < this.options.length; i++) {
        if (this.useParameters) {
          temp += (this.isSms || this.userFeatures.QuestionEditorFeatures.EditAnswerOptions ? this.options[i].value.Name + " : " : "")
            + this.options[i].value.Value
            + "\n";
        }
        else {
          temp += (this.isSms || this.userFeatures.QuestionEditorFeatures.EditAnswerOptions ? this.options[i].name + " : " : "")
            + this.options[i].value.Label
            + (this.userFeatures.QuestionEditorFeatures.EditAnswerOptionFactors ? "," + this.options[i].value.Factor : "")
            + (this.options[i].value.Fixed ? ",fixed" : "")
            + (this.options[i].value.Exclusive ? ",ex" : "")
            + (this.options[i].value.Other != null ? ",other" : "")
            + "\n";
        }
      }

      this.form.controls.options.setValue(temp);
    }
    else {
      this.onChange();
    }
  }

  valueChanged(option: AdvancedAnswerOptionComponent) {
    if (option.form == undefined) {
      return;
    }

    let id = option.form.controls.id.value;
    const value = option.form.controls.option.value;
    const factor = option.form.controls.factor.value;
    let label = new LabelModel();
    label.Label = value;
    label.Language = this._model.Interview.DefaultLanguage ?? "en-GB";
    if (this.useParameters) {
      if (id != undefined) {
        this.options[option.index].name = id;
      }

      this.options[option.index].value.Name = id;
      this.options[option.index].value.Value = value;
      if (this.options[option.index].value.LanguageValue == null) {
        this.options[option.index].value.LanguageValue = []
      }

      if (this.options[option.index].value.LanguageValue.legnth == 0) {
        this.options[option.index].value.LanguageValue.push(label);
      }
      else {
        this.options[option.index].value.LanguageValue[0] = label;
      }
    }
    else {
      if (id != undefined) {
        this.options[option.index].name = id;
      }

      this.options[option.index].value = option.category;
      this.options[option.index].value.Label = value;
      if (this.options[option.index].value.LanguageLabel == null) {
        this.options[option.index].value.languageLabel = [];
      }

      if (this.options[option.index].value.LanguageLabel.length = 0) {
        this.options[option.index].value.LanguageLabel.push(label);
      }
      else {
        this.options[option.index].value.LanguageLabel[0] = label;
      }

      this.options[option.index].value.Factor = factor;
    }

    this.updateModel();
  }

  updateModel() {
    if (this.useParameters) {
      this._model.Parameters = [];
      for (let i = 0; i < this.options.length; i++) {
        let parameter = new ParameterModel();
        parameter.Name = this.isSms || this.userFeatures.QuestionEditorFeatures.EditAnswerOptions ? this.options[i].value.Name : String.fromCharCode(97 + i);
        parameter.Value = this.options[i].value.Value;
        this._model.Parameters.push(parameter);
      }
    }
    else {
      this._model.Categories.List = [];
      for (let i = 0; i < this.options.length; i++) {
        this.options[i].value.Name = this.isSms || this.userFeatures.QuestionEditorFeatures.EditAnswerOptions ? this.options[i].name : String.fromCharCode(97 + i); 
        this._model.Categories.List.push(this.options[i].value);
      }
    }
  }

  createParameter(index: number, value?: string): ParameterModel {
    const name = String.fromCharCode(97 + index);
    const parameter = new ParameterModel();
    parameter.Name = name;
    parameter.Value = value ? value : "";
    return parameter;
  }

  createCategory(index: number, value?: string): CategoryModel {
    const name = String.fromCharCode(97 + index);
    const category = new CategoryModel(index, name, value ? value : "");
    return category;
  }

  initOptionRow(index: number): Option {
    if (this.useParameters) {
      let parameter = this.createParameter(index);
      return new Option(parameter.Name, parameter);
    }

    let category = this.createCategory(index);
    return new Option(category.Name, category);
  }

  addNewOption() {
    this.options.push(this.initOptionRow(this.options.length));
  }

  removeOption(index: number) {
    if (this.options.length > index) {
      this.options.splice(index, 1);
      this.updateModel();
    }
  }

  moveUp(index: number) {
    moveItemInArray(this.options, index, index - 1);
    this.updateModel();
  }

  moveDown(index: number) {
    moveItemInArray(this.options, index, index + 1);
    this.updateModel();
  }

  @Input()
  get userFeatures(): UserFeatures {
    return this._userFeatures;
  }
  set userFeatures(value: UserFeatures) {
    this._userFeatures = value;
  }
  private _userFeatures: UserFeatures = new UserFeatures();

  @Input()
  get useParameters(): boolean {
    return this._useParameters;
  }
  set useParameters(value: boolean) {
    this._useParameters = value;
  }
  private _useParameters = false;

  @Input()
  get title(): string {
    return this._title;
  }
  set title(value: string) {
    this._title = value;
  }
  private _title: string = "Options";

  @Input()
  get label(): string {
    return this.optionText;
  }
  set label(value: string) {
    this.optionText = value;
  }

  @Input()
  get isBoolean(): boolean {
    return this._isBoolean;
  }
  set isBoolean(value: boolean) {
    this._isBoolean = value;
  }
  private _isBoolean = false;

  @Input()
  get allowDeleteAdd(): boolean {
    return this._allowDeleteAdd;
  }
  set allowDeleteAdd(value: boolean) {
    this._allowDeleteAdd = value;
  }
  private _allowDeleteAdd = true;

  get isSms(): boolean {
    if (this.model) {
      return this.model.Interview.Channel == "sms" || this.model.Interview.Channel == "whatsapp";
    }

    return false;
  }
  set isSms(value: boolean) {
    this._isSms = value;
  }
  private _isSms = false;

  @Input()
  get model(): QuestionModel {
    return this._model;
  }
  set model(value: QuestionModel) {
    this._model = value;
    if (this._model !== undefined && this._model !== null) {
      Promise.resolve(null).then(() => {
        this.populateForm();
      });
    }
  }
  private _model: QuestionModel = new QuestionModel();

  optionValid(index: number): boolean {
    return index === this.options.length - 1;
  }

  optionTextLabel(index: number): string {
    if (this.isBoolean) {
      if (index === 0) {
        return "Yes";
      }
      else {
        return "No";
      }
    }

    return this.optionText + " " + (index + 1);
  }

  get multipleChoices(): number {
    if (this.useParameters) {
      return 3;
    }

    if (this._model) {
      if (this._model.QuestionType === "mcma" || (this._model.QuestionType === "matrix" && this._model.Control === "checkbox")) {
        return 2;
      }

      if (this._model.QuestionType === "mcsa" || (this._model.QuestionType === "matrix" && this._model.Control === "radiobutton")) {
        return 1;
      }

      if (this._model.QuestionType === "ranking") {
        return 3;
      }
    }

    return -1;
  }

  populateForm() {
    this.options = [];
    if (this.useParameters) {
      this._model.Parameters.forEach(parameter => {
        this.options.push(new Option(parameter.Name, parameter));
      });
      if (this.options.length === 0) {
        const parameter = this.createParameter(0);
        this.options.push(new Option(parameter.Name, parameter));
      }
    }
    else {
      this._model.Categories.List.forEach(category => {
        this.options.push(new Option(category.Name, category));
      });
      if (this.options.length === 0) {
        const category = this.createCategory(0);
        this.options.push(new Option(category.Name, category));
      }
    }
  }
}

class Option {
  name: string;
  value: any;

  constructor(name: string, value: any) {
    this.name = name;
    this.value = value;
  }
}
