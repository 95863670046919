<div class="user-details-dialog-container">
  <div class="leftFloat">
    <h3>{{title}}</h3>
  </div>
  <div class="rightFloat">
    <button mat-icon-button mat-dialog-close>
      <mat-icon>close</mat-icon>
    </button>
  </div>
</div>
<div class="clearBoth">
</div>
<div class="fullWidth align-center-center-flex fill-flex">
  <form class="chng-un-form column-flex align-center-start-flex" [formGroup]="formGroup">
    <div class="chng-un-controls-container">
      <div class="chng-un-select-name-div">
        <mat-form-field appearance="outline">
          <mat-label>Name</mat-label>
          <input formControlName="name" matInput placeholder="Name">
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>Description</mat-label>
          <textarea formControlName="description" matInput placeholder="Description"></textarea>
        </mat-form-field>
      </div>
    </div>
    <button mat-raised-button color="primary" (click)="onSubmit()" [disabled]="formGroup.invalid || !success">{{ buttonTitle }}</button>
  </form>
</div>
