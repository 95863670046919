import { Component, ViewChild } from '@angular/core';
import { FormControl, FormGroup, FormBuilder, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MessageService, MobileCountryInfo } from '../../message.Service';
import { TranslateService } from '@ngx-translate/core';
import { NetworkService } from '../../network.Service';
import { Organization, AuthenticationService, OrganizationModel } from "../../authentication.Service";
import { ActivatedRoute, Router } from "@angular/router";
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { MediaService } from '../../media.Service';
import { MessageModel } from "../../message.Service";
import { SmsEditorComponent } from '../../Development/sms-editor/sms-editor.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';

class OrgList {
  selected: boolean;
  icon: string;
  name: string;
  email: string;
  value: number;
  memberId: number;
  mediaId: number;
  mediaService: MediaService;

  constructor(selected: boolean, icon: string, name: string, email: string, value: number, memberId: number, mediaId: number,
    mediaService: MediaService) {
    this.selected = selected;
    this.icon = icon;
    this.name = name;
    this.email = email;
    this.value = value;
    this.memberId = memberId;
    this.mediaId = mediaId;
    this.mediaService = mediaService;
  }

  get media() {
    if (this._media != null) {
      return this._media == "" ? null : this._media;
    }

    if (this.mediaId == 0) {
      this._media = "";
      return null;
    }

    this.mediaService.getMediaById(this.mediaId).subscribe(
      result => {
        this._media = result;
      },
      error => {
        this._media = "";
      });
  }
  private _media = null;
}

@Component({
  selector: 'app-tfn',
  templateUrl: './tfn.component.html',
  styleUrl: './tfn.component.css'
})
export class TfnComponent {
  formGroup: FormGroup;
  category = new FormControl('');
  categoryList: string[] = ['1-Way SMS Survey Invitations', '1-Way SMS Survey Reminders', '2-Way SMS Surveys'];
  selectedcategory;
  errorMsg: string;
  displayError: boolean;
  displaySuccess: boolean;
  dataReady: boolean = false;
  countrycode: number = 0;
  countries: MobileCountryInfo[] = [];
  isAdminView = false;
  isMemberView = true;
  tempOrgs?: Observable<Organization[]>;
  orgPageSize: number = 20;
  userId: number = 0;
  organizations: Organization[] = [];
  orgList: OrgList[] = [];
  selectedOrg?: OrgList;
  ownOrganizations: boolean = false;
  message: string = "";

  @ViewChild('sms') public sms: SmsEditorComponent | undefined;

  constructor(private fb: FormBuilder,
    public translate: TranslateService,
    private authService: AuthenticationService,
    private messageService: MessageService,
    private networkSerivce: NetworkService,
    private mediaService: MediaService
  ) {
    this.errorMsg = "";
    this.displayError = false;
    this.displaySuccess = false;
    this.messageService.getCountries().subscribe(result => {
      this.countries = result;
      this.orgList = [];
      this.checkOrg();
    });

    this.formGroup = new FormGroup({
      businessname: new FormControl(),
      Type: new FormControl("sms"),
      Organization: new FormControl(),
      streetaddress: new FormControl(),
      city: new FormControl(),
      Country: new FormControl(),
      state: new FormControl(),
      postalcode: new FormControl(),
      companywebsite: new FormControl(),
      contactname: new FormControl(),
      contactemail: new FormControl(),
      contactnumber: new FormControl(),
      volofmessage: new FormControl(),
      tollfreenumber: new FormControl(),
      category: new FormControl(),
      description: new FormControl(),
      optindescription: new FormControl(),
      image: new FormControl(),
      url: new FormControl(),
      messagecontent: new FormControl()

    });
  }

  isSms(): boolean {
    return this.formGroup.controls.Type.value == "sms";
  }

  checkOrg() {
    let userId = this.authService.getUserId();

    this.authService.getOrganizationSummariesForMember().subscribe(result => {
      /*let m = this.orgList[0];
      m.selected = false;*/
      this.orgList = [];
      this.organizations = result;
      //this.orgList.push(m);
      let i = this.authService.currentOrganization();
      let found = false;
      for (let j = 0; j < this.organizations.length; j++) {
        let org = this.organizations[j];
        let o = new OrgList(false, org.OrganizationId == i ? 'supervised_user_circle' : 'supervisor_account', org.Name, "", org.OrganizationId, org.MemberId, org.MediaId, this.mediaService);
        if (org.RoleId == 5) {
          this.ownOrganizations = true;
        }

        if (o.value == i) {
          found = true;
          this.selectedOrg = o;
        }
        else {
          this.orgList.push(o);
        }
      }
    });
  }

  newSearch() {
    this.networkSerivce.tfnregrequest(this.formGroup.value.Organization?.name, this.formGroup.value.businessname, this.formGroup.value.streetaddress,
      this.formGroup.value.city, this.formGroup.value.Country?.Country, this.formGroup.value.state,
      this.formGroup.value.postalcode, this.formGroup.value.companywebsite, this.formGroup.value.contactname,
      this.formGroup.value.contactemail, this.formGroup.value.contactnumber, this.formGroup.value.volofmessage,
      this.formGroup.value.tollfreenumber, this.selectedcategory[0] + "," + this.selectedcategory[1] + "," + this.selectedcategory[2] , this.formGroup.value.description,
      this.formGroup.value.optindescription, this.formGroup.value.image, this.formGroup.value.url,
      this.sms?.text ?? "")
      .subscribe(result => {
        this.displayError = false;
        this.displaySuccess = true;
      },
        error => {
          console.error(error);
          this.errorMsg = "There was a problem Sending your TFN Registration Request. Please Try again later.";
          this.displayError = true;
        });
  }
}
