import { Component, OnInit, Input, ViewChildren, QueryList } from '@angular/core';
import { AnswerModel } from "../../models/AnswerModel";
import { DataComponent } from '../data/data.component';

@Component({
  selector: 'app-block',
  templateUrl: './block.component.html',
  styleUrls: ['./block.component.css']
})
export class BlockComponent implements OnInit {

  @ViewChildren('data') dataQuestion: QueryList<DataComponent> | undefined;

  questions: any;

  constructor() { }

  ngOnInit(): void {
  }

  @Input()
  get playerWidth(): number {
    return this._playerWidth;
  }
  set playerWidth(value: number) {
    this._playerWidth = value;
  }
  private _playerWidth: number = 0;

  @Input()
  get preview(): boolean {
    return this._preview;
  }
  set preview(value: boolean) {
    this._preview = value;
  }
  private _preview: boolean = false;

  @Input()
  get question(): any {
    return this._question;
  }
  set question(value: any) {
    this._question = value;
    if (this._question === undefined) {
      return;
    }

    this.questions = this._question.Item.Items;
  }
  private _question: any;

  get answers(): AnswerModel[] {
    const model: AnswerModel[] = [];
    this.dataQuestion?.forEach(question => {
      question.answers.forEach(answer => {
        model.push(answer);
      });
    });
    return model;
  }

  get valid(): boolean {
    const temp = this.dataQuestion?.toArray() ?? [];
    for (let i = 0; i < temp.length; i++) {
      if (!temp[i].valid) {
        return false;
      }
    }

    return true;
  }
}
