<div *ngIf="canDisplay()">
  <div *ngIf="displayGoto" class="padding-20 display-inline-flex"><span >Goto&nbsp;</span></div>
  <mat-form-field mat-line appearance="outline">
    <mat-label> Question </mat-label>
    <mat-select  (selectionChange)="valueChanged($event)" [value]="questionId">
      <mat-option>None</mat-option>
      <mat-option *ngFor="let question of questionsForRuleType()" [value]="question.QuestionId">
        {{question.FullName}}
      </mat-option>
    </mat-select>
  </mat-form-field>
</div>
