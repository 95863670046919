<div class="netwrk-dtl-container">
  <div class="netwrk-dtl-content">
    <form [formGroup]="formGroup">
      <div>
        <h2>Request a New Network Connection</h2>
      </div>
      <div class="alert alert-warning" role="alert">
        Remember, the use of SMS should follow our strict <a href="https://docs.diysurveys.com/sms-messaging-service-confirmation" target="_blank">SMS Usage Guidelines</a>
      </div>
      <div class="row-flex-flow">
        <div class="mng-multiRqst-sidebar-list custom-scroll-bar wd-30-per">
          <mat-selection-list #countries class="mng-multiRqst-sidebar-box" (selectionChange)="networksChanged($event)">
            <mat-list-option *ngFor="let net of networks" [value]="net">
              {{net.Name}}
            </mat-list-option>
          </mat-selection-list>
        </div>
        <div class="wd-70-per padding-5">
          <div>
            <mat-form-field appearance="outline" class="fullWidth">
              <mat-label>Name</mat-label>
              <input formControlName="name" matInput placeholder="Name">
            </mat-form-field>
          </div>
          <div>
            <mat-radio-group formControlName="type" (change)="twoWayChange()">
              <mat-radio-button [value]="1">1-Way SMS (Outbound, MT)</mat-radio-button>
              <mat-radio-button [value]="2">2-Way SMS (Out/Inbound, MT/MO)</mat-radio-button>
            </mat-radio-group>
          </div>
          <div *ngIf="unavailable.length > 0">
            <h3>Networks requiring a new number:</h3> {{ getNetworkNames(0) }}
          </div>
          <div *ngIf="singleNumberNetworks.length > 0">
            <h3>Networks with a number</h3> {{ getNetworkNames(1)}}
          </div>
          <div *ngIf="multiNumberNetworks.length > 0">
            <h3>Networks with multiple numbers requiring one to selected</h3>
            <div *ngFor="let network of multiNumberNetworks">
              <mat-form-field appearance="outline">
                <mat-label>{{ network.Name }}</mat-label>
                <mat-select formControlName="number" (selectionChange)="numberChange()">
                  <mat-option *ngFor="let num of network.SelectedNumbers; let i = index" [value]="num.NumberId">{{num.Number}}</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
