import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.css']
})
export class SettingsComponent implements OnInit {
  formGroup: FormGroup;
  private baseUrl: string;
  private client: HttpClient;
  private options = { headers: new HttpHeaders().set('Content-Type', 'application/json') };

  errorMsg: string;
  displayError: boolean;
  displaySuccess: boolean;

  constructor(client: HttpClient,
    @Inject('BASE_URL') baseUrl: string) {
    this.client = client;
    this.baseUrl = baseUrl;
    this.errorMsg = "";
    this.displayError = false;
    this.displaySuccess = false;
    this.formGroup = new FormGroup({
      username: new FormControl("", [Validators.required, Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$')])
    });
  }

  ngOnInit(): void {
  }

  onSubmit() {
    if (this.formGroup.valid) {
      this.client.post(this.baseUrl + 'api/Account/ForgotPassword', JSON.stringify(this.formGroup.value.username), this.options)
        .subscribe(result => {
          this.displayError = false;
          this.displaySuccess = true;
        },
          error => {
            console.error(error);
            this.errorMsg = "There was a problem producing your reset email";
            this.displayError = true;
          });
    }
  }

}
