import { Component, Input, OnInit } from '@angular/core';
import { PanelSummaryModel } from '../../panel.Service';

@Component({
  selector: 'app-panel-profile',
  templateUrl: './panel-profile.component.html',
  styleUrls: ['./panel-profile.component.css']
})
export class PanelProfileComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  @Input()
  get summary(): PanelSummaryModel {
    return this._summary;
  }
  set summary(value: PanelSummaryModel) {
    this._summary = value;
  }
  private _summary: PanelSummaryModel = new PanelSummaryModel();

  @Input()
  get showMobileDistribution(): boolean {
    return this._showMobileDistribution;
  }
  set showMobileDistribution(value: boolean) {
    this._showMobileDistribution = value;
  }
  private _showMobileDistribution = false;
}
