<div>
  <div class="users-header-content">
    <div>
      <button mat-raised-button color="primary" (click)="new()">Create New Keyword</button>&nbsp;&nbsp;
    </div>
  </div>
  <div *ngIf="tempKeywords | async; else loading">
    <table *ngIf="Keywords.length > 0" mat-table [dataSource]="dataSource" class="mat-elevation-z8">
      <ng-container matColumnDef="Name">
        <th mat-header-cell *matHeaderCellDef> Keyword </th>
        <td mat-cell *matCellDef="let element"> {{element.Code}} </td>
      </ng-container>
      <ng-container matColumnDef="Created">
        <th mat-header-cell *matHeaderCellDef> Created </th>
        <td mat-cell *matCellDef="let element"> {{element.Created  | date:'short' : '' : translate.currentLang}} </td>
      </ng-container>

      <ng-container matColumnDef="Amended">
        <th mat-header-cell *matHeaderCellDef> Modified </th>
        <td mat-cell *matCellDef="let element"> {{element.Amended  | date:'short' : '' : translate.currentLang}} </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr mat-row class="users-element-row" *matRowDef="let row; columns: displayedColumns;" (click)="edit(row)"></tr>
    </table>
  </div>
</div>

<ng-template #loading>
  <div class="loading-container">
    <span class="loading-content">
      <div class="spinner-div"><mat-spinner [diameter]="25"></mat-spinner></div>
      <div class="spinner-text"> Loading Keywords...</div>
    </span>
  </div>
  <br /><br />
</ng-template>



