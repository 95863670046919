<div class="user-details-dialog-container">
  <div class="leftFloat">
    <h3>Respondent Details</h3>
  </div>
  <div class="rightFloat">
    <button mat-icon-button matDialogClose="no" matTooltip="Close" matTooltipPosition="below">
      <mat-icon class="user-details-dialog-close-icon">close</mat-icon>
    </button>
  </div>
</div>
<div style="margin-top:5px">
  <app-edit-panelist #panellist [panelId]="panelId" [panellistId]="panellistId"></app-edit-panelist>
</div>
