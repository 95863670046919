import { Component, OnInit, OnDestroy, Inject, ViewChild, ElementRef, EventEmitter, Output } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SurveyModel, SurveyService } from '../survey.Service';
import { Observable } from 'rxjs';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { MatAutocompleteSelectedEvent, MatAutocomplete } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { map, startWith } from 'rxjs/operators';
import { ImageCropComponent } from '../image-cropper/image-crop.component';
import { AuthenticationService } from '../authentication.Service';

@Component({
  selector: 'app-name-survey-from-scratch',
  templateUrl: './name-survey-from-scratch.component.html',
  styleUrls: ['./name-survey-from-scratch.component.css']
})
export class NameSurveyFromScratchComponent implements OnInit {
  selectedFile: File | null = null;
  formGroup: FormGroup = new FormGroup({});
  title = "Create Your Survey";
  buttonName = "Create Survey";
  selectable = true;
  removable = true;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  categoryCtrl = new FormControl();
  filteredCategories?: Observable<string[]>;
  categories: string[] = ['Brand'];
  allCategories: string[] = [];
  survey: SurveyModel = new SurveyModel();
  script: string = "";
  loaded: boolean = false;

  displayError: boolean = false;

  @ViewChild('categoryInput') categoryInput: ElementRef<HTMLInputElement> | undefined;
  @ViewChild("imageCropper") imageCropper: ImageCropComponent | undefined;


  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<NameSurveyFromScratchComponent>,
    private fb: FormBuilder,
    private surveyService: SurveyService,
    private auth: AuthenticationService
  ) {
    if (data && data.id && data.id > 0) {
      this.title = "Edit Your Survey Details";
      this.buttonName = "Update";
      this.surveyService.getSurveyDetail(data.id).subscribe(result => {
        this.survey = result as SurveyModel;
        this.formGroup.controls.SurveyTitle.setValue(this.survey.Name);
        this.formGroup.controls.SurveySubtitle.setValue(this.survey.SurveyReference);
        this.formGroup.controls.SurveyDescription.setValue(this.survey.Description);
        this.formGroup.controls.Channel.setValue(this.survey.Channel);
        this.categories = this.survey.SearchTags.split(',');
        this.loaded = true;
      });
    }
    else {
      this.survey = new SurveyModel();
      this.survey.SurveyID = 0;
      this.loaded = true;
    }

    this.surveyService.getSurveyCategories().subscribe(
      result => {
        this.allCategories = result;
      });

  }

  ngOnInit(): void {
    this.formGroup = this.fb.group({
      SurveyTitle: [this.survey.Name, Validators.required],
      SurveySubtitle: [this.survey.SurveyReference, Validators.required],
      SurveyDescription: [this.survey.Description, Validators.required],
      categoryCtrl: [''],
      Channel: [0]
    });

    if (this.isDiyTester()) {
      this.formGroup.controls.Channel.addValidators(Validators.required);
    }

    this.filteredCategories = this.categoryCtrl.valueChanges.pipe(
      startWith(null),
      map((category: string | null) => category ? this._filter(category) : this.allCategories.slice()));
  }

  getChannel(channel: number) {
    return this.formGroup != undefined && Number(this.formGroup.controls.Channel.value) == channel;
  }

  add(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();
    if (value) {
      this.categories.push(value);
    }

    this.categoryCtrl.setValue(null);
  }

  isAdmin() {
    return this.auth.isAuthorized(['Admin']);
  }

  isDiyTester() {
    return this.auth.isAuthorized(['diy'])
  }

  onFileUploaded($event: any) {
    this.script = $event.name;
  }

  remove(category: string): void {
    const index = this.categories.indexOf(category);

    if (index >= 0) {
      this.categories.splice(index, 1);
    }
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    this.categories.push(event.option.viewValue);
    if (this.categoryInput != undefined) {
      this.categoryInput.nativeElement.value = '';
    }

    this.categoryCtrl.setValue(null);
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.allCategories.filter(category => category.toLowerCase().indexOf(filterValue) === 0);
  }

  onSubmit() {
    let model: SurveyModel = new SurveyModel();
    model.SurveyID = this.survey.SurveyID;
    model.Name = this.formGroup.controls.SurveyTitle.value;
    model.SurveyReference = this.formGroup.controls.SurveySubtitle.value;
    model.Description = this.formGroup.controls.SurveyDescription.value;
    model.SearchTags = this.categories.join(',');
    model.ReportModel = "";
    model.Occurences = 4320;
    if (this.script) {
      model.ReportModel = this.script;
    }

    if (this.imageCropper && this.imageCropper.imageCropper != undefined) {
      this.imageCropper.uploadImage().then((id: number) => {
        model.MediaID = id;
        let temp;
        if (model.SurveyID === undefined || model.SurveyID === 0) {
          if (this.formGroup.controls.Channel.value == "") {
            model.SurveyID = 0;
          }
          else {
            model.SurveyID = this.formGroup.controls.Channel.value;
          }

          temp = this.surveyService.createBlankSurvey(model);
        }
        else {
          temp = this.surveyService.updateBasicDetails(model);
        }

        temp.subscribe(
          result => {
            model = result as SurveyModel;
            this.dialogRef.close({ survey: model });
          });
      });
    }
    else {
      model.MediaID = this.imageCropper ? this.imageCropper.mediaID : 0;
      let temp;
      if (model.SurveyID === undefined || model.SurveyID === 0) {
        model.SurveyID = this.formGroup.controls.Channel.value;
        temp = this.surveyService.createBlankSurvey(model);
      }
      else {
        temp = this.surveyService.updateBasicDetails(model);
      }

      temp.subscribe(
        result => {
          model = result as SurveyModel;
          this.dialogRef.close({ survey: model });
        });
    }
  }
}
