<div #routing *ngIf="editor != undefined && editor.interview != undefined">
  <div *ngFor="let question of editor.interview.Questions; let i = index" id="route{{i}}">
    <div *ngIf="question.QuestionType !== 'advanced'" class="cursor-grab" [class]="isSelected(question.Index) ? 'alert alert-dark survey-flow-ruleLayout' : 'alert alert-secondary survey-flow-ruleLayout'" role="alert"
         (click)="onClick(question.Index, question.ParentIndex)">
      <div>
        <mat-icon class="survey-flow-qn-icon">{{question.Icon}}</mat-icon>
      </div>
      <div>
        <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="icon-button with a menu">
          <mat-icon class="dialog-close-icon">more_vert</mat-icon>
        </button>
        <mat-menu #menu="matMenu">
          <button mat-menu-item [matMenuTriggerFor]="flowtypes" [matMenuTriggerData]="{name: 'before'}">Add Before</button>
          <button mat-menu-item [matMenuTriggerFor]="flowtypes" [matMenuTriggerData]="{name: 'after'}">Add After</button>
        </mat-menu>
        <mat-menu #flowtypes="matMenu">
          <ng-template matMenuContent let-name="name">
            <button mat-menu-item (click)="onAdd(name, i, 'route')">
              <mat-icon>account_tree</mat-icon>
              <span>Branch</span>
            </button>
            <button mat-menu-item (click)="onAdd(name, i, 'embed')">
              <mat-icon>note_add</mat-icon>
              <span>Embed Data</span>
            </button>
            <button mat-menu-item (click)="onAdd(name, i, 'randomise')">
              <mat-icon>shuffle</mat-icon>
              <span>Randomiser</span>
            </button>
            <button mat-menu-item [hidden]="true" (click)="onAdd(name, i, 'web')">
              <mat-icon>cloud</mat-icon>
              <span>Web Service</span>
            </button>
            <mat-divider></mat-divider>
            <button mat-menu-item (click)="onAdd(name, i, 'quota')">
              <mat-icon>track_changes</mat-icon>
              <span>Quota</span>
            </button>
          </ng-template>
        </mat-menu>
      </div>
      <div>
        {{question.Summary}}
        <app-advanced-properties [model]="question"></app-advanced-properties>
      </div>
    </div>
    <div *ngIf="question.QuestionType === 'advanced' && question.Action != 'navigation' && !isSelected(question.Index)" class="alert alert-primary survey-flow-ruleLayout cursor-grab" role="alert"
         (click)="onClick(question.Index, question.ParentIndex)">
      <div>
        <mat-icon>{{question.Icon}}</mat-icon>
      </div>
      <div *ngIf="question.Action === 'route'" class="survey-flow-ruleLayout">
        <div>
          {{question.Summary}}
        </div>
      </div>
      <div *ngIf="question.Action !== 'route'">
        {{question.Summary}}
      </div>
    </div>
    <div *ngIf="question.QuestionType === 'advanced' && question.Action != 'navigation' && isSelected(question.Index)" class="survey-flow-editRouting">
      <div class="column-flex-flow fullWidth">
        <div class="survey-flow-delete-icon-div">
          <button mat-mini-fab color="accent" matTooltip="Delete" (click)="onDelete(i)"><mat-icon>delete</mat-icon></button>
        </div>
        <div class="column-flex-flow">
          <div *ngIf="question.Action == 'route'">
            <app-advanced-routing [display]="false" [rules]="question.Rules[0]" name="Branch "></app-advanced-routing>
            <mat-divider class="mt-10"> </mat-divider>
            <app-question-combo [displayGoto]="true" [rules]="question.Rules[0]"></app-question-combo>
          </div>
          <div *ngIf="question.Action == 'embed'">
            <app-embed-data [question]="question" [userFeatures]="editor?.userFeatures"></app-embed-data>
          </div>
          <div *ngIf="question.Action == 'randomise'">
            <app-randomise [question]="question"></app-randomise>
          </div>
          <div *ngIf="question.Action == 'quota'">
            <app-quota-definition [display]="false" [rules]="question.Rules[0]"></app-quota-definition>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
