import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthenticationService } from "../authentication.Service";
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'app-joinorg',
  templateUrl: './joinorg.component.html',
  styleUrls: ['./joinorg.component.css']
})
export class JoinorgComponent implements OnInit {
  displayError: number = 0;
  wait?: Observable<any>;
  errorMsg: string = "";
  constructor(private route: ActivatedRoute, private authService: AuthenticationService) {
  }

  ngOnInit(): void {
    this.authService.testInviteOrgToken(this.route.snapshot.queryParamMap.get("token") ?? "").subscribe(result => {
      this.displayError = 0;
    },
      error => {
        if (error.status === 400) {
          // cannot register
          this.displayError = 1;
          this.errorMsg = "Unable to join this organization at this time please check the email address or contact Client Services";
        }
        else if (error.status === 404) {
          this.displayError = 97;
          this.errorMsg = "You have already joined this organization";
        }
        else if (error.status === 409) {
          // Duplicate details
          this.displayError = 98;
          this.errorMsg = "You will need to sign up with us first before you can use this link";
        } else {
          this.displayError = 99;
          console.error(error.message);
          this.errorMsg = "Unable to join this organization at this time please check the email address or contact Client Services";
        }
      });
  }
}
