import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { CaseDataService, PanellistReferenceEntry } from '../../casedata.Service';
import { SurveyModel, SurveyService } from '../../survey.Service';
import { UserProfile, UserService } from '../../user.Service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AuthenticationService } from '../../authentication.Service';
import { Router } from '@angular/router';
import { GlobalStopList, MessageService } from '../../message.Service';

@Component({
  selector: 'app-check-number',
  templateUrl: './check-number.component.html',
  styleUrls: ['./check-number.component.css']
})
export class CheckNumberComponent {
  formGroup: FormGroup;
  references: PanellistReferenceEntry[] = null;
  stopList: GlobalStopList[] = null;
  surveys: SurveyModel[] = [];
  users: UserProfile[] = [];
  gettingDetails = false;

  constructor(private caseService: CaseDataService,
    private messageService: MessageService,
    private authenticationService: AuthenticationService,
    private surveyService: SurveyService,
    private userService: UserService,
    private router: Router,
    private snackbar: MatSnackBar
  ) {
    this.formGroup = new FormGroup({
      number: new FormControl('')
    })
  }

  onSubmit() {
    this.references = null;
    this.stopList = null;
    this.surveys = [];
    this.gettingDetails = false;
    this.caseService.findActiveNumber(this.formGroup.controls.number.value).subscribe(
      result => { this.references = result },
      error => { this.references = [] });
    this.messageService.checkGlobalStopList(this.formGroup.controls.number.value).subscribe(
      result => { this.stopList = result },
      error => { this.stopList = [] });
  }

  getSurveyDetails() {
    this.gettingDetails = true;
    this.surveys = [];
    this.users = [];
    for (let i = 0; i < this.references.length; i++) {
      this.surveyService.getSurveyDetailAdmin(this.references[i].SurveyID).subscribe(
        result => {
          let temp = result;
          this.userService.getUser(result.UserID.toString()).subscribe(
            result => {
              this.users.push(result);
              this.surveys.push(temp);
              this.gettingDetails = i + 1 < this.references.length;
            }
          )
        }
      )
    }
  }

  getStatus(index: number) {
    switch (this.references[index].StatusID) {
      case 0:
        return "Created";
      case 1:
        return "Started";
      case 2:
        return "Completed";
      case 3:
        return "Quota Exceeded";
      case 4:
        return "Terminated";
      case 5:
        return "Timed Out";
      case 6:
        return "Shutdown";
      case 7:
        return "Screened Out";
      case 8:
        return "Failed";
      case 9:
        return "Anomalies";
      default:
        return "Unknown";
    }
  }

  unstop(index: number) {
    this.messageService.unStopNumber(this.stopList[index].Source, this.stopList[index].Destination).subscribe(
      result => {
        this.openSnackbar("Successfully Unstopped", "");
        this.stopList.splice(index, 1);
      },
      error => { this.openSnackbar("There was a problem unstopping the number", "Cancel") }
    );
  }

  keyword(index: number) {
    if (this.stopList[index].KeywordID == 0) {
      return "STOP";
    }

    return this.stopList[index].KeywordID;
  }

  abandon(index: number) {
    this.references[index].Processed = true;
    this.references[index].StatusID = 8
    this.caseService.abandonReference(this.references[index]).subscribe(
      result => {
        this.openSnackbar("Successfully Abandoned", "");
        this.references.splice(index, 1);
        this.users.splice(index, 1);
        this.surveys.splice(index, 1);
      },
      error => { this.openSnackbar("There was a problem abandoning the active number", "Cancel") }
    );
  }

  private openSnackbar(message: string, action: string) {
    if (action == "") {
      this.snackbar.open(message, action, { duration: 2000 });
    }
    else {
      this.snackbar.open(message, action, { duration: 7000 });
    }
  }

  login(index: number) {
    let survey = this.surveys[index]
    this.authenticationService.isOrganization(survey.UserID).subscribe(
      result => {
        if (result) {
          this.authenticationService.impersonateAsOwner(survey.UserID)
            .subscribe(result => {
              const token = (result as any).Token;
              this.authenticationService.setAccessToken(token);
              this.router.navigate(["/delivery-summary", survey.SurveyID, "summary"])
            },
              error => {
                console.error(error);
              });
          return;
        }

        this.userService.getUser(survey.UserID.toString()).subscribe(
          result => {
            let user = result;
            this.authenticationService.impersonateAs(user.UserName).subscribe(
              result => {
                const token = (result as any).Token;
                this.authenticationService.setAccessToken(token);
                this.router.navigate(["/delivery-summary", survey.SurveyID, "summary"])
              },
              error => {
                console.error(error);
              });
          });
      });

    return false;
  }

}
