import { Component, Input } from '@angular/core';
import { SurveyModel } from '../../survey.Service';
import { InterviewModel } from '../../question.service';
import { PanelModel, PanelService } from '../../panel.Service';

@Component({
  selector: 'app-distribution-list',
  templateUrl: './distribution-list.component.html',
  styleUrl: './distribution-list.component.css'
})
export class DistributionListComponent {

  panels: PanelModel[] = [];

  constructor(private panelService: PanelService) { }

  getPanels() {
    this.panelService.getPanelsForSurvey(this._survey.SurveyID).subscribe(result => this.panels = result);
  }

  @Input()
  get survey(): SurveyModel {
    return this._survey;
  }
  set survey(value: SurveyModel) {
    this._survey = value;
    if (this._survey != null) {
      this.getPanels();
    }
  }
  private _survey: SurveyModel;

  @Input()
  get interview(): InterviewModel {
    return this._interview;
  }
  set interview(value: InterviewModel) {
    this._interview = value;
  }
  private _interview: InterviewModel;
}
