<app-newtoolbar></app-newtoolbar>
<div #body class="body">
  <div class="header">
    <div class="headerContent">
      <router-outlet></router-outlet>
    </div>
  </div>
  <div class="footer">
    <app-newfooter></app-newfooter>
  </div>
</div>
