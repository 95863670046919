import { Component, OnInit, Input, AfterViewInit } from '@angular/core';
import { AnswerModel } from "../../models/AnswerModel";

@Component({
  selector: 'app-boolean',
  templateUrl: './boolean.component.html',
  styleUrls: ['./boolean.component.css']
})
export class BooleanComponent implements OnInit, AfterViewInit {

  radioButtons: boolean = false;
  checkBoxes: boolean = false;
  checked: boolean = false;
  checkedString: string = "";

  constructor() { }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    Promise.resolve(null).then(() => {
      this.populateForm();
    });
  }


  @Input()
  get question(): any {
    return this._question;
  }
  set question(value: any) {
    this._question = value;
    if (this._question === undefined) {
      return;
    }

    this.checkBoxes = this._question.Style.Control.Type === 7;
    this.radioButtons = this._question.Style.Control.Type === 6;
    this.populateForm();
  }
  private _question: any;

  get yes(): string {
    return this.findLabel("true", "Yes");
  }

  get no(): string {
    return this.findLabel("false", "No");
  }

  private findLabel(name: string, value: string): string {
    let i = this.findCategory(name);
    if (i === -1) {
      return value;
    }

    return this.question.Categories.Items[i].Label.Text;
  }

  private findCategory(name: string): number {
    for (let i = 0; i < this.question.Categories.Items.length; i++) {
      if (this.question.Categories.Items[i].Name === name) {
        return i;
      }
    }

    return -1;
  }

  get answers(): AnswerModel[] {
    let answer = "";
    if (this.checkedString === undefined || this.checkedString === null) {
    }
    else {
      if (this.radioButtons) {
        this.checked = this.checkedString === "true";
      }
    }

    if (this.checked != undefined && this.checked != null) {
      answer = this.checked.toString();
    }

    const model: AnswerModel[] = [];
    model.push(new AnswerModel(this._question.QuestionFullName, answer));
    return model;
  }

  get valid(): boolean {
    return true;
  }

  populateForm() {
    if (this._question.Response.Value === null || this._question.Response.Value === undefined && this._question.Response.Value === "") {
      return;
    }

    this.checked = this._question.Response.Value === "True";
    this.checkedString = this.checked.toString();
  }
}
