import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { version, instance } from "../../../environments/environment";
import { Observable, Subject, map } from 'rxjs';

@Component({
  selector: 'app-newfooter',
  templateUrl: './newfooter.component.html',
  styleUrl: './newfooter.component.css'
})
export class NewfooterComponent implements OnDestroy {
  destroy = new Subject();

  destroy$ = this.destroy.asObservable();
  top: number = 0;

  currentVersion: string;
  currentYear = new Date().getFullYear();
  currentInstance: string;

  constructor(private breakpointObserver: BreakpointObserver, private router: Router) {
    this.currentVersion = version;
    this.currentInstance = instance;
  }

  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
    .pipe(map(result => result.matches));

  ngOnDestroy(): void {
    this.destroy.next(this.destroy$);
  }

  openTerms() {
    const url = this.router.createUrlTree(['/termsofservice'])
    window.open(url.toString(), '_blank')
  }

  openPrivacy() {
    const url = this.router.createUrlTree(['/privacystatement'])
    window.open(url.toString(), '_blank')
  }

  goToLink(url: string) {
    window.open(url, "_blank");
  }
}
