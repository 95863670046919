<div *ngIf="display">
  <div [formGroup]="formGroup" class="fullWidth">
    <div *ngIf="hasNA" style="width:100%">
      <mat-checkbox formControlName="NA">{{naCode.Label.Text}}</mat-checkbox>
    </div>
    <mat-form-field *ngIf="isSingleLine" appearance="outline">
      <mat-label>{{placeholder}}</mat-label>
      <input matInput #floating [placeholder]="placeholder" [mask]="mask" formControlName="float">
    </mat-form-field>
    <div *ngIf="isSlider && orientation != 2" class="float-slider-hr-div">
      <div class="display-flex fullWidth">
        <mat-slider class="fullWidth"
                    [min]="displayMin"
                    [max]="max"
                    [displayWith]="formatLabel"
                    [step]="step"
                    discrete
                    showTickMarks>
          <input #slider matSliderThumb formControlName="slider">
        </mat-slider>
      </div>
      <div class="display-flex fullWidth">
        <div *ngFor="let cell of cells;let i = index" [style]="(i === cells.length - 1) ? 'width: 0%; padding-right: 14px;' : 'width: 100%; padding-left: 4px;'">
          <div *ngIf="cells.length <= 12">
            <span *ngIf="i > 0" class="text-small">{{cell}}</span>
          </div>
          <div *ngIf="(cells.length > 12) && (cells[1] == 0)">
            <span *ngIf="i == 1" class="text-small">{{cell}}</span>
            <span *ngIf="i == (((cells.length - 2) / 2) + 1 )" class="text-small">{{cell}}</span>
            <span *ngIf="(i == (((cells.length - 2) / 2 )/2) + 1)" class="text-small">{{cell}}</span>
            <span *ngIf="i == ((((cells.length - 2) + ((cells.length - 2) / 2))/2) + 1)" class="text-small">{{cell}}</span>
            <span *ngIf="i == (cells.length - 1)" class="text-small">{{cell}}</span>
          </div>
          <div *ngIf="(cells.length) > 12 && (cells[1] !== 0)">
            <span *ngIf="i == 1" class="text-small">{{cell}}</span>
            <span *ngIf="i == ((cells.length - 1) / 2 )" class="text-small">{{cell}}</span>
            <span *ngIf="(i == ((cells.length - 1) / 2 )/2)" class="text-small">{{cell}}</span>
            <span *ngIf="i == (((cells.length - 1) + ((cells.length - 1) / 2))/2)" class="text-small">{{cell}}</span>
            <span *ngIf="(i + 1) == cells.length" class="text-small">{{cell}}</span>
          </div>
        </div>
      </div>
      <div class="float-slider-hr-maxMinString-div">
        <div *ngFor="let cell of cells;let i = index">
          <span *ngIf="i === 1" class="text-small">{{minString}}</span>
          <span *ngIf="i === (cells.length - 1)" class="text-small">{{maxString}}</span>
          <span *ngIf="i !== 1 && i !== (cells.length -1)" class="text-small"></span>
        </div>
      </div>
    </div>
    <div *ngIf="isSlider && orientation == 2" class="float-slider-vert-div">
      <div class="float-vert-slider-content">
        <mat-slider class="fullWidth"
                    [min]="displayMin"
                    [max]="max"
                    [displayWith]="formatLabel"
                    [step]="step"
                    discrete
                    showTickMarks>
          <input #slider matSliderThumb formControlName="slider">
        </mat-slider>
      </div>
      <div class="float-vert-slider-content">
        <div *ngFor="let cell of cells;let i = index" [style]="(i === cells.length - 1) ? 'width: 0%; padding-right: 26px; transform: rotate(90deg);' : 'width: 100%; padding-left: 4px; transform: rotate(90deg);'">
          <div *ngIf="cells.length <= 6" [style]="(i === cells.length - 1) ? 'top: 1px; position: relative;': 'top: 6px; position: relative;'">
            <span *ngIf="i > 0" class="text-small">{{cell}}</span>
          </div>
          <div *ngIf="(cells.length > 6) && (cells.length <= 12)" [style]="(i === cells.length - 1) ? 'top: 1px; position: relative;': 'top: 0px; position: relative;'">
            <span *ngIf="i > 0" class="text-small">{{cell}}</span>
          </div>
          <div *ngIf="(cells.length > 12) && (cells[1] == 0)">
            <span *ngIf="i == 1" class="text-small">{{cell}}</span>
            <span *ngIf="i == (((cells.length - 2) / 2) + 1 )" class="text-small">{{cell}}</span>
            <span *ngIf="(i == (((cells.length - 2) / 2 )/2) + 1)" class="text-small">{{cell}}</span>
            <span *ngIf="i == ((((cells.length - 2) + ((cells.length - 2) / 2))/2) + 1)" class="text-small">{{cell}}</span>
            <span *ngIf="i == (cells.length - 1)" class="text-small">{{cell}}</span>
          </div>
          <div *ngIf="(cells.length) > 12 && (cells[1] !== 0)">
            <span *ngIf="i == 1" class="text-small">{{cell}}</span>
            <span *ngIf="i == ((cells.length - 1) / 2 )" class="text-small">{{cell}}</span>
            <span *ngIf="(i == ((cells.length - 1) / 2 )/2)" class="text-small">{{cell}}</span>
            <span *ngIf="i == (((cells.length - 1) + ((cells.length - 1) / 2))/2)" class="text-small">{{cell}}</span>
            <span *ngIf="(i + 1) == cells.length" class="text-small">{{cell}}</span>
          </div>
        </div>
      </div>
      <div class="float-slider-vert-maxMinString-div">
        <div *ngFor="let cell of cells;let i = index">
          <div>
            <div class="float-rotate-90" [style]="(cells.length > 10) ? 'position: relative; top: 35px; right: 22px;' : 'position: relative; right: 5px; top: 30px;'">
              <div>
                <span *ngIf="i === 1" class="text-small">{{minString}}</span>
              </div>
            </div>
            <div class="float-rotate-90" [style]="(cells.length > 10) ? 'position: relative; top: 25px; left: 30px;' : 'position: relative; left: 30px; top: 20px;'">
              <div>
                <span *ngIf="i === (cells.length - 1)" class="text-small">{{maxString}}</span>
              </div>
            </div>
          </div>
          <div><span *ngIf="i !== 1 && i !== (cells.length -1)" class="text-small"></span></div>
        </div>
      </div>
    </div>
    <div *ngIf="isStar && orientation != 2" class="fullWidth">
      <div class="display-flex align-center-start-flex fill-flex">
        <span *ngIf="minString.length > 0" class="float-star-min-label">{{minString}}</span>
        <div class="pt-10">
          <bar-rating name="rating" [theme]="'default'" [(rate)]="value" [max]="max"></bar-rating>
        </div>
        <span *ngIf="maxString.length > 0" class="float-star-max-label">{{maxString}}</span>
      </div>
    </div>
    <div *ngIf="isStar && orientation == 2" class="pl-10">
      <div class="column-flex">
        <span *ngIf="minString.length > 0">{{minString}}</span>
        <bar-rating class="float-star-rating-vertical" [(rate)]="value" [max]="max"></bar-rating>
        <span *ngIf="maxString.length > 0">{{maxString}}</span>
      </div>
    </div>
    <div *ngIf="isRadio && orientation == 2" class="pl-10">
      <span *ngIf="minString.length > 0" class="wd-4-per mr-5">{{minString}}</span>
      <mat-radio-group formControlName="radio" class="column-flex">
        <mat-radio-button *ngFor="let cell of cells;let i = index" #radiobutton [value]="i.toString()">
        </mat-radio-button>
      </mat-radio-group>
      <span *ngIf="maxString.length > 0" class="wd-4-per">{{maxString}}</span>
    </div>
    <div *ngIf="isRadio && orientation != 2" class="fullWidth mt-30">
      <div class="display-flex align-center-start-flex fill-flex">
        <span *ngIf="minString.length > 0" class="float-radio-min-label">{{minString}}</span>
        <mat-radio-group class="horiztonal-radio-group" formControlName="radio">
          <mat-radio-button *ngFor="let cell of cells;let i = index" #radiobutton class="horizontal-radio-button" [value]="i.toString()">
          </mat-radio-button>
        </mat-radio-group>
        <span *ngIf="maxString.length > 0" class="float-radio-max-label">{{maxString}}</span>
      </div>
    </div>
    <div *ngIf="isLink && orientation != 2">
      <mat-button-toggle-group #group="matButtonToggleGroup" formControlName="link">
        <mat-button-toggle *ngFor="let label of labels; let i = index" [value]="label.value.toString()">{{label.label}}</mat-button-toggle>
      </mat-button-toggle-group>
    </div>
    <div *ngIf="isLink && orientation == 2" class="pl-10">
      <mat-button-toggle-group #group="matButtonToggleGroup" [vertical]="true" formControlName="link">
        <mat-button-toggle *ngFor="let label of labels; let i = index" [value]="label.value.toString()">{{label.label}}</mat-button-toggle>
      </mat-button-toggle-group>
    </div>
  </div>
</div>
