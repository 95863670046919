import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MessageService } from '../../message.Service';
import { Field } from '../../process.Service';
import { QuestionModel } from '../../question.service';

@Component({
  selector: 'app-sms-editor',
  templateUrl: './sms-editor.component.html',
  styleUrls: ['./sms-editor.component.css']
})
export class SmsEditorComponent implements OnDestroy {
  formGroup: FormGroup;
  response: string = "";
  timeout: any | null = null;
  length = 0;
  segments = 0;
  segmentText = "0 Segments";
  isGsm: boolean = false;
  isUnicode: boolean = false;
  isLatin: boolean = false;

  @ViewChild('textarea') public textarea: ElementRef | undefined;

  constructor(private messageService: MessageService) {
    this.formGroup = new FormGroup({
      Message: new FormControl(),
    });
  }
  ngOnDestroy(): void {
    this.timeout = null;
  }

  initForm() {
    this.formGroup.controls.Message.setValue(this._text);
  }

  messageChange($event: any) {
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      this.checkMessage(this.formGroup.controls.Message.value, true);
    }, 10);
  }

  selectField(field: Field) {
    let position = this.textarea?.nativeElement.selectionStart;
    let temp = "";
    if (position > 0) {
      temp = this._text.substring(0, position);
    }

    let fieldString = "{" + field.Name + "}";
    temp += fieldString;
    if (position + 1 < this._text.length) {
      temp += this._text.substr(position, this._text.length - position);
    }

    this._text = temp;
    this.formGroup.controls.Message.setValue(this._text);
    this.textarea?.nativeElement.setPosition(position + fieldString.length, 0);
  }

  @Output()
  onChange: EventEmitter<string> = new EventEmitter<string>();

  @Output()
  onSmart: EventEmitter<string> = new EventEmitter<string>();

  clickSmart(value: string) {
    if (this.onSmart) {
      this.onSmart.emit(value);
    }
  }

  checkMessage(message: string, shouldNotifyChange: boolean) {
    if (this._text == message) {
      return;
    }

    this._text = message;
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      this.messageService.check(message).subscribe(
        result => {
          this.isGsm = false;
          this.isUnicode = false;
          this.isLatin = false;
          this.segments = result.Segments;
          switch (result.Type) {
            case "GSM":
              this.isGsm = true;
              break;
            case "UNICODE":
              this.isUnicode = true;
              break;
            default:
              if (this.segments > 0) {
                this.isLatin = true;
              }

              break;
          }

          if (this.segments == 0) {
            this.segmentText = "0 Segments";
          }
          else if (this.segments == 1) {
            this.segmentText = "1 Segment";
          }
          else {
            this.segmentText = this.segments + " Segments";
          }

          this.length = result.Length
        });
    }, 10);
    if (shouldNotifyChange) {
      this.onChange?.emit(this._text);
    }
  }

  @Input()
  get questions(): QuestionModel[] {
    return this._questions;
  }
  set questions(value: QuestionModel[]) {
    this._questions = value;
  }
  private _questions: QuestionModel[] = []

  @Input()
  get disabled(): boolean {
    return this._disabled;
  }
  set disabled(value: boolean) {
    this._disabled = value;
    this._disabled ? this.formGroup.controls.Message.disable() : this.formGroup.controls.Message.enable();
  }
  private _disabled: boolean = false;

  @Input()
  get text(): string {
    if (this.formGroup) {
      return this.textarea?.nativeElement.value.replace('/n', '<br/>');
    }

    return this._text;
  }
  set text(value: string) {
    if (value == undefined || value == null) {
      value = "";
    }

    this._text = value.replace(/<\/?[^>]+(>|$)/g, "").replace("&nbsp;", " ").replace("&amp;", "&");
    this.initForm();
    let temp = this._text;
    this._text = "";
    this.checkMessage(temp, false);
  }
  private _text: string = "";

  @Input()
  get label(): string {
    return this._label;
  }
  set label(value: string) {
    this._label = value;
  }
  private _label: string = "Message";

  @Input()
  get smart(): boolean {
    return this._smart;
  }
  set smart(value: boolean) {
    this._smart = value;
  }
  private _smart: boolean = false;

  @Input()
  get channel(): string {
    return this._channel;
  }
  set channel(value: string) {
    this._channel = value;
  }
  private _channel = null;
}
