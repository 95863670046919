import { Component } from '@angular/core';
import { Observable } from "rxjs";
import { tap } from 'rxjs/operators';
import { TranLogSummary, MessageService } from '../../message.Service';
import { TranslateService } from '@ngx-translate/core';
import { MatTableDataSource } from '@angular/material/table';
import { flipInYAnimation } from 'angular-animations';

@Component({
  selector: 'app-gateway-activity',
  templateUrl: './gateway-activity.component.html',
  styleUrl: './gateway-activity.component.css',
  animations: [flipInYAnimation()]
})
export class GatewayActivityComponent {
  tempSummary: Observable<any>;
  summary: TranLogSummary[] = null;
  displayedColumns = ['GatewayName', 'When', 'StatusString', 'Count'];
  dataSource = new MatTableDataSource(this.summary);
  showSummary = true;
  animation = 'flipInY';
  animationState = false;
  animationWithState = false;

  constructor(public translate: TranslateService,
    private messageService: MessageService) {
    this.tempSummary = this.messageService.getTranLogSummary().pipe(tap<TranLogSummary[]>(
      result => {
        this.summary = result;
        this.dataSource = new MatTableDataSource(this.summary);
      }
    ));
  }

  edit(row: any) {

  }

  filterResults() {
    this.animate();
  }

  noSave() {
    this.animate();
  }

  animate() {
    this.animationState = false;
    setTimeout(() => {
      this.showSummary = !this.showSummary;
      this.animationState = true;
      this.animationWithState = !this.animationWithState;
    }, 1);
  }
}
