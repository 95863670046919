import { Component, Input, EventEmitter, Output } from '@angular/core';
import { RuleGroup, RuleList, RuleModel } from '../../question.service';
import { ProfileVariable } from '../../panel.Service';
import { fadeInOnEnterAnimation, fadeOutOnLeaveAnimation } from 'angular-animations';
import { plainToClass } from 'class-transformer';
import { CdkDragDrop } from '@angular/cdk/drag-drop';

@Component({
  selector: 'app-quota-definitions',
  templateUrl: './quota-definitions.component.html',
  styleUrl: './quota-definitions.component.scss',
  animations: [
    fadeInOnEnterAnimation({ duration: 100 }),
    fadeOutOnLeaveAnimation({ duration: 100 })
  ]
})
export class QuotaDefinitionsComponent {

  fromHere: boolean = false;

  @Input()
  get quotas(): RuleList[] {
    return this._quotas;
  }
  set quotas(value: any) {
    this._quotas = value;
    if (value?.length == 1 && this._selectedQuota == -1) {
      this._selectedQuota = 0;
    }
  }
  private _quotas: RuleList[] = [];

  @Output()
  quotasChanged = new EventEmitter<RuleList[]>();

  @Input()
  get variables(): ProfileVariable[] {
    return this._variables;
  }
  set variables(value: ProfileVariable[]) {
    this._variables = value;
  }
  private _variables: ProfileVariable[] = [];

  drop(event: CdkDragDrop<string[]>) {
    if (event.currentIndex == event.previousIndex) {
      return;
    }

    let quota = this.quotas[event.previousIndex];
    this.quotas.splice(event.previousIndex, 1)
    if (event.previousIndex > this.selectedQuota) {
      this.quotas.splice(event.currentIndex, 0, quota);
      this._selectedQuota = event.currentIndex;
      return;
    }

    if (event.previousIndex < this.selectedQuota) {
      this.quotas.splice(event.currentIndex, 0, quota);
      this._selectedQuota = event.currentIndex;
      return;
    }
  }

  performAction(event, where: string): boolean {
    event.stopPropagation();
    switch (where) {
      case "before":
        this._quotas.splice(this.selectedQuota, 0, this.blankQuota());
        break;
      case "after":
        if (this._selectedQuota + 1 == this._quotas.length) {
          this._quotas.push(this.blankQuota());
          this._selectedQuota = this._quotas.length - 1;
          return false;
        }

        this._quotas.splice(this._selectedQuota + 1, 0, this.blankQuota());
        this._selectedQuota++;
        break;
    }

    return false;
  }

  setSelectedQuota(index: number): boolean {
    this._selectedQuota = index;
    return false;
  }

  blankQuota(): RuleList {
    let quota = new RuleList();
    quota.Rules.push(new RuleGroup());
    quota.Rules[0].Rules = [];
    quota.Rules[0].Rules.push(new RuleModel());
    quota.Rules[0].Rules[0].RuleType = "profile";
    return quota;
  }

  deleteAction() {
    this._quotas.splice(this.selectedQuota, 1);
    if (this.selectedQuota > this._quotas.length) {
      this._selectedQuota--;
    }
  }

  calcQuotaPercent(rules: RuleList) {
    if (rules.Quota == null) {
      return 0;
    }

    return rules.Quota.Progress / rules.Quota.Target * 100;
  }

  @Input()
  get selectedQuota(): number {
    return this._selectedQuota;
  }
  set selectedQuota(value: number) {
    this._selectedQuota = value;
  }
  private _selectedQuota: number = -1;

  @Input()
  get quotaTotal(): number {
    return this._quotaTotal;
  }
  set quotaTotal(value: number) {
    this._quotaTotal = value;
  }
  private _quotaTotal: number = -1;

  buildSummary(index: number): string {
    try {
      return this._quotas[index]?.buildSummary("When ");
    }
    catch {
      return "There is a problem with this quota";
    }
  }

  quotaUpdated(index: number, rules: RuleList) {
    this._quotas[index] = rules;
    this.fromHere = true;
    //this.quotasChanged?.emit(this._quotas);
    this.fromHere = false;
  }
}
