import { Component, EventEmitter, Inject, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { TranslateService } from '@ngx-translate/core';
import { ProcessErrorModel, ProcessService, ProcessServiceModel } from '../../process.Service';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { MatTableDataSource } from '@angular/material/table';
import { JsonCyclic } from '../../utils';

@Component({
  selector: 'app-configured-processes',
  templateUrl: './configured-processes.component.html',
  styleUrls: ['./configured-processes.component.css'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ]

})
export class ConfiguredProcessesComponent implements OnInit, OnDestroy {
  messages: ProcessErrorModel[] = [];
  errors: ProcessErrorModel[] = [];
  searchKey: string = "";
  resultsLength = 0;
  expandedProcess: ProcessServiceModel | null;
  dataSource = new MatTableDataSource<ProcessServiceModel>();
  processPageSize = 10;
  isRateLimitReached: boolean = false;
  page: number = 1;
  title: string = "Saved Export Configurations";
  showClose: boolean = false;
  statusChecks: StatusCheck[] = [];
  get columnsToDisplay(): string[] {
    return ['name', 'amended', 'method', 'status', 'action'];
  }

  @ViewChild('table', { static: true }) table: any | undefined;
  @ViewChild(MatSort) sort: MatSort | undefined;

  constructor(public translate: TranslateService,
    private processService: ProcessService) {
    this.expandedProcess = null;
  }

  ngOnDestroy(): void {
  }

  ngOnInit(): void {
  }

  type(process: ProcessServiceModel) {
    switch (process.Task.FileTransfer?.Type) {
      case "SFTP":
        return "SFTP";
      case "FTPS":
        return "FTPS";
      case "SPECIFIC":
        if (process.ProcessTypeId == 0) {
          return "UPLOAD";
        }
        else {
          return "DOWNLOAD";
        }
      case "EMAIL":
        return "EMAIL";
      default:
        return "Undefined";
    }
  }

  getStatus(process: ProcessServiceModel) {
    switch (process.StatusId) {
      case 0:
        this.checkStatus(process);
        return "Waiting to Start";
      case 1:
        this.checkStatus(process);
        return "In progress";
      case 2:
        this.checkStatus(process);
        if (process.Task.LastSuccess) {
          return "Paused";
        }

        return "Paused with a problem";
      case 3:
        return "Successfully Completed";
      case 4:
        return "Stopped with Errors";
      case 5:
        return "Saved";
      case 6:
        return "Saved";
      default:
        return "-- ? --";
    }
  }

  checkStatus(process: ProcessServiceModel) {
    let i = 0;
    if (process.ProcessId == 0) {
      return;
    }

    for (i = 0; i < this.processes.length; i++) {
      if (this.processes[i].ProcessId == process.ProcessId) {
        break;
      }
    }

    if (i == this.processes.length) {
      return;
    }

    if (this.statusChecks[i].shouldCheck()) {
      this.processService.getProcess(process.ProcessId).subscribe(result => {
        this.processes[i] = result;
        this.statusChecks[i].reset(10);
      })
    }
  }

  edit(process: ProcessServiceModel) {
    this.select.emit(process);
  }

  export(process: ProcessServiceModel) {
    let blob = new Blob([JsonCyclic.toJson(process)], { type: "text/plain" });
    let url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    const fileName = "Process";
    a.href = url;
    a.download = fileName + ".txt";
    a.click();
    window.URL.revokeObjectURL(url);
  }

  copy(process: ProcessServiceModel) {
    process.ProcessId = 0;
    this.select.emit(process)
  }

  viewErrors(event: any, process: ProcessServiceModel) {
    event.stopPropagation();
    if (process == this.expandedProcess) {
      this.expandedProcess = null;
      return;
    }

    this.expandedProcess = process;
    this.messages = [];
    this.errors = [];
    this.processService.getProcessMessages(process.ProcessId, 20).subscribe(
      result => {
        this.messages = result;
      });
    this.processService.getProcessErrors(process.ProcessId, 10).subscribe(
      result => {
        this.errors = result;
      });
  }

  delete(process: ProcessServiceModel) {
    this.processService.deleteProcess(process).subscribe(result => {
      for (let i = 0; i < this._processes.length; i++) {
        if (this._processes[i].ProcessId == process.ProcessId) {
          this._processes.splice(i, 1);
          this.dataSource = new MatTableDataSource<ProcessServiceModel>(this.processes);
          this.processesChange.emit(this._processes);
          return;
        }
      }
    });
  }

  @Output()
  select: EventEmitter<ProcessServiceModel> = new EventEmitter<ProcessServiceModel>();

  @Output()
  processesChange: EventEmitter<ProcessServiceModel[]> = new EventEmitter<ProcessServiceModel[]>();


  @Input()
  get processes(): ProcessServiceModel[] {
    return this._processes;
  }
  set processes(value: ProcessServiceModel[]) {
    this._processes = value;
    if (this._processes == undefined) {
      this._processes = [];
    }

    this.errors = [];
    this.messages = [];
    this.dataSource = new MatTableDataSource<ProcessServiceModel>(this.processes);
    for (let i = 0; i < this.processes.length; i++) {
      this.statusChecks.push(new StatusCheck(10));
    }

    this.resultsLength = this._processes.length;
  }
  private _processes: ProcessServiceModel[] =[];
}

export class StatusCheck {
  Timeout: Date;

  constructor(seconds: number) {
    this.reset(seconds);
  }

  shouldCheck(): boolean {
    let check = this.Timeout < new Date();
    this.reset(10);
    return check;
  }

  reset(seconds: number) {
    this.Timeout = new Date(new Date().getTime() + seconds * 1000);
  }
}
