import { Component, Input, TemplateRef, ViewChild } from '@angular/core';
import { DataAnalysisComponent } from '../data-analysis.component';
import { CustomFormat, Field, FileTransferModel, Line, ProcessErrorModel, ProcessService, ProcessServiceModel } from '../../process.Service';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { plainToClass } from 'class-transformer';
import { Schedule } from '../../scheduler/scheduler.component';
import { ProcessDefinitionEvent } from '../process-definition/process-definition.component';
import { SurveyModel } from '../../survey.Service';
import { InterviewModel } from '../../question.service';

@Component({
  selector: 'app-automations',
  templateUrl: './automations.component.html',
  styleUrls: ['./automations.component.css']
})
export class AutomationsComponent {
  selectedIndex: number = 0;
  details: any = [
    { name: 'Active', description: 'Active ', icon: 'play_arrow', processes: [] = [] },
    { name: 'Definitions', description: 'Definitions', icon: 'playlist_add', proceses: [] = [] },
    { name: 'Completed', description: 'Completed', icon: 'done_all', processes: [] = [] },
    { name: 'Failures', description: 'Failures', icon: 'running_with_errors', processes: [] = [] },
    { name: 'Abandoned', description: 'Abandoned', icon: 'delete', processes: [] = [] }
  ];
  dialogRef: MatDialogRef<any, any>;

  @ViewChild('importDataDialog') dataDefinitionDialog: TemplateRef<any>;

  constructor(private processService: ProcessService,
    public dialog: MatDialog) {
  }

  addProcess(process: ProcessServiceModel) {
    this.details[1].processes.splice(0, 0, process);
    this.selectedIndex = 1;
  }

  newProcess(isExport: boolean, automate: boolean) {
    let process = ProcessService.newProcess(this.survey.SurveyID, this.survey.Name, isExport, automate);
    this.addProcess(process);
  }

  copyProcess(oldProcess: ProcessServiceModel) {
    let process = new ProcessServiceModel();

    // Basic details
    process.ProcessId = 0;
    process.Cron = oldProcess.Cron;
    process.Start = process.Finish = new Date();
    process.Timezone = oldProcess.Timezone;
    process.IsDeleted = false;
    process.Name = oldProcess.Name;

    process.ProcessTypeId = oldProcess.ProcessTypeId;
    process.Task.Entity = oldProcess.Task.Entity;

    // file Transfer Information
    process.Task.Type = oldProcess.Task.Type;
    process.Task.FileTransfer.Type = oldProcess.Task.FileTransfer.Type;
    process.Task.FileTransfer.Passive = oldProcess.Task.FileTransfer.Passive;
    process.Task.FileTransfer.Ssl = oldProcess.Task.FileTransfer.Ssl;
    process.Task.FileTransfer.Port = oldProcess.Task.FileTransfer.Port;
    process.Task.FileTransfer.LocalPath = oldProcess.Task.FileTransfer.LocalFolder;
    process.Task.FileTransfer.Server = oldProcess.Task.FileTransfer.Server;
    process.Task.FileTransfer.Username = oldProcess.Task.FileTransfer.Username;
    process.Task.FileTransfer.Password = oldProcess.Task.FileTransfer.Password;
    process.Task.FileTransfer.ServerFolder = oldProcess.Task.FileTransfer.ServerFolder;
    process.Task.FileTransfer.LocalFolder = oldProcess.Task.FileTransfer.LocalFolder;
    process.Task.FileTransfer.File = oldProcess.Task.FileTransfer.File;
    process.Task.FileTransfer.Pattern = oldProcess.Task.FileTransfer.Pattern;
    process.Task.FileTransfer.Ssl = oldProcess.Task.FileTransfer.Ssl;

    process.ReferenceId = oldProcess.ReferenceId;
    process.Task.ReferenceID = oldProcess.Task.ReferenceID;
    process.Task.Rules = oldProcess.Task.Rules;
    process.Task.SplitMultiValueFields = oldProcess.Task.SplitMultiValueFields;
    process.Task.RemoveLineBreaks = oldProcess.Task.RemoveLineBreaks;
    process.Task.Timings = oldProcess.Task.Timings;
    process.Task.SystemData = oldProcess.Task.SystemData;
    process.Task.Format = oldProcess.Task.Format;
    process.Task.UseNumericValues = oldProcess.Task.UseNumericValues;
    process.Task.AllData = oldProcess.Task.AllData;
    process.Task.Start = process.Task.Finish = new Date();
    process.Task.From = false;
    process.Task.Automated = oldProcess.Task.Automated;
    process.Task.Headers = oldProcess.Task.Headers;
    process.Task.IncludePanelData = oldProcess.Task.IncludePanelData;

    process.Task.ErrorHandling = oldProcess.Task.ErrorHandling;
    if (process.Task.ErrorHandlingFileTransfer == null) {
      process.Task.ErrorHandlingFileTransfer = new FileTransferModel();
    }

    if (oldProcess.Task.ErrorHandlingFileTransfer == null) {
      oldProcess.Task.ErrorHandlingFileTransfer = new FileTransferModel();
    }

    process.Task.ErrorHandlingFileTransfer.Type = oldProcess.Task.ErrorHandlingFileTransfer.Type;
    process.Task.ErrorHandlingFileTransfer.Passive = oldProcess.Task.ErrorHandlingFileTransfer.Passive;
    process.Task.ErrorHandlingFileTransfer.Ssl = oldProcess.Task.ErrorHandlingFileTransfer.Ssl;
    process.Task.ErrorHandlingFileTransfer.Port = oldProcess.Task.ErrorHandlingFileTransfer.Port;
    process.Task.ErrorHandlingFileTransfer.LocalPath = oldProcess.Task.ErrorHandlingFileTransfer.LocalFolder;
    process.Task.ErrorHandlingFileTransfer.Server = oldProcess.Task.ErrorHandlingFileTransfer.Server;
    process.Task.ErrorHandlingFileTransfer.Username = oldProcess.Task.ErrorHandlingFileTransfer.Username;
    process.Task.ErrorHandlingFileTransfer.Password = oldProcess.Task.ErrorHandlingFileTransfer.Password;
    process.Task.ErrorHandlingFileTransfer.ServerFolder = oldProcess.Task.ErrorHandlingFileTransfer.ServerFolder;
    process.Task.ErrorHandlingFileTransfer.LocalFolder = oldProcess.Task.ErrorHandlingFileTransfer.LocalFolder;
    process.Task.ErrorHandlingFileTransfer.File = oldProcess.Task.ErrorHandlingFileTransfer.File;
    process.Task.ErrorHandlingFileTransfer.Pattern = oldProcess.Task.ErrorHandlingFileTransfer.Pattern;
    process.Task.ErrorHandlingFileTransfer.Ssl = oldProcess.Task.ErrorHandlingFileTransfer.Ssl;

    process.Task.ErrorHandlingFileTransfer.Type = oldProcess.Task.ErrorHandlingFileTransfer.Type;
    process.Task.ReportErrors = oldProcess.Task.ReportErrors;
    process.Task.CustomCustom = oldProcess.Task.CustomCustom;

    process.Task.CustomFormat = new CustomFormat();
    process.Task.CustomFormat.Lines = [];
    process.Task.CustomFormat.Header = oldProcess.Task.CustomFormat.Header;
    process.Task.CustomFormat.Lines.push(new Line());
    for (let i = 0; i < oldProcess.Task.CustomFormat.Lines[0].Fields.length; i++) {
      let oldField = oldProcess.Task.CustomFormat.Lines[0].Fields[i];
      let field = new Field();
      field.Name = oldField.Name;
      field.Position = oldField.Position;
      field.Terminated = oldField.Terminated;
      field.Optional = oldField.Optional;
      field.EndsWith = oldField.EndsWith;
      field.Size = oldField.Size;
      field.Type = oldField.Type;
      field.ConditionValue = oldField.ConditionValue;
      field.Key = oldField.Key;
      field.Repeat = oldField.Repeat;
      field.Start = oldField.Start;
      field.Length = oldField.Length;
      field.Justification = oldField.Justification;
      field.Alias = oldField.Alias;
      field.Custom = oldField.Custom;
      field.Text = oldField.Text;
      field.Title = oldField.Title;
      field.ReceiptRequest = oldField.ReceiptRequest;
      field.Format = oldField.Format;
      process.Task.CustomFormat.Lines[0].Fields.push(field);
    }

    process.Task.Country = oldProcess.Task.Country;
    process.Task.Number = oldProcess.Task.Number;
    process.Task.Direction = oldProcess.Task.Direction;
    process.Task.Statuses = oldProcess.Task.Statuses;
    process.Task.Content = oldProcess.Task.Content;
    this.addProcess(process);
  }

  showImportDataDefinition() {
    this.dialogRef = this.dialog.open(this.dataDefinitionDialog);
  }

  importDataDefinition($event) {
    this.dialogRef.close();
    this.processService.getImport($event.name).subscribe(
      result => {
        let process = plainToClass(ProcessServiceModel, result);
        process.ProcessId = 0;
        process.ReferenceId = process.Task.ReferenceID = this.survey?.SurveyID ?? 0;
        process.Start = process.Finish = process.Task.Start = process.Task.Finish = new Date();
        process.Task.From = false;
        this.addProcess(process);
      });
  }

  getProcesses() {
    this.processService.getActiveProcessesOnly(this.survey?.SurveyID ?? 0).subscribe(result => this.details[0].processes = result);
    this.processService.getCompletedProcessesOnly(this.survey?.SurveyID ?? 0).subscribe(
      result => this.details[2].processes = this.classifyProcesses(result));
    this.processService.getDefinitions(this.survey?.SurveyID ?? 0).subscribe(
      result => this.details[1].processes = this.classifyProcesses(result));
    this.processService.getErrors(this.survey?.SurveyID ?? 0).subscribe(
      result => this.details[3].processes = this.classifyProcesses(result));
    this.processService.getAbandoned(this.survey?.SurveyID ?? 0).subscribe(
      result => this.details[4].processes = this.classifyProcesses(result));
  }

  classifyProcesses(processes: ProcessServiceModel[]): ProcessServiceModel[] {
    for (let i = 0; i < processes.length; i++) {
      processes[i] = this.classifyProcess(processes[i]);
    }

    return processes;
  }

  classifyProcess(process: ProcessServiceModel): ProcessServiceModel {
    return plainToClass(ProcessServiceModel, process);
  }

  processClosed(event: ProcessDefinitionEvent) {
    switch (event.action) {
      case "closed":
        for (let i = 0; i < this.details[event.index].processes.length; i++) {
          if (this.details[event.index].processes[i].ProcessId == event.process.ProcessId) {
            if (event.process.ProcessId == 0) {
              this.details[event.index].processes.splice(i, 1);
              return;
            }
          }
        }

        break;

      case "copy":
        this.copyProcess(event.process);
        break;
      case "saved":
        for (let i = 0; i < this.details[event.index].processes.length; i++) {
          if (this.details[event.index].processes[i].ProcessId == 0 || this.details[event.index].processes[i].ProcessId == event.process.ProcessId) {
            this.details[event.index].processes[i] = event.process;
            return;
          }
        }

        break;
      case "delete":
        this.deleteProcess(event.index, event.process);
        if (event.index == 0 || this.selectedIndex == 3) {
          this.deleteProcess(event.index == 0 ? 3 : 0, event.process);
        }

        break;
      case "launched":
        for (let i = 0; i < this.details[event.index].processes.length; i++) {
          if (this.details[event.index].processes[i].ProcessId == event.process.ProcessId) {
            if (this.selectedIndex != 0) {
              this.details[0].processes.splice(0, 0, process);
              this.details[event.index].processes.splice(i, 1);
            }

            return;
          }
        }

        break;
      case "abandon":
        this.abandonProcess(event.index, event.process);
        this.details[4].processes.splice(0, 0, event.process);
        this.selectedIndex = 4;
        break;
    }
  }

  abandonProcess(index: number, process: ProcessServiceModel) {
    for (let i = 0; i < this.details[index].processes.length; i++) {
      if (this.details[index].processes[i].ProcessId == process.ProcessId) {
        this.processService.abandonProcess(process).subscribe(result => {
          this.details[index].processes.splice(i, 1);
        });
        return;
      }
    }
  }

  deleteProcess(index: number, process: ProcessServiceModel) {
    for (let i = 0; i < this.details[index].processes.length; i++) {
      if (this.details[index].processes[i].ProcessId == process.ProcessId) {
        this.processService.deleteProcess(process).subscribe(result => {
          this.details[index].processes.splice(i, 1);
        });
        return;
      }
    }
  }

 @Input()
  get exportOnly(): boolean {
    return this._exportOnly;
  }
  set exportOnly(value: boolean) {
    this._exportOnly = value;
  }
  private _exportOnly: boolean = false;

  @Input()
  get survey(): SurveyModel {
    return this._survey;
  }
  set survey(value: SurveyModel) {
    this._survey = value;
    if (this._survey?.SurveyID ?? 0 > 0) {
      this.getProcesses();
    }
  }
  private _survey: SurveyModel;

  @Input()
  get interview(): InterviewModel {
    return this._interview;
  }
  set interview(value: InterviewModel) {
    this._interview = value;
  }
  private _interview: InterviewModel;
}
