<div *ngIf="report" class="full-width-height">
  <mat-sidenav-container>
    <mat-sidenav #leftSidenav class="reports-mat-sidenav-left" mode="side" opened [disableClose]="true">
      <div>
        <div class="reports-leftControls-container">
          <div class="fullWidth">
            <button mat-icon-button (click)="export()">
              <mat-icon>download_file</mat-icon>
            </button>
            <button mat-icon-button matTooltip=" Tools " [matMenuTriggerFor]="menu" matTooltipPosition="after"><mat-icon>settings</mat-icon></button>
            <mat-menu #menu="matMenu" xPosition="after">
              <button mat-menu-item [matMenuTriggerFor]="deleteMenu">
                <mat-icon>delete</mat-icon>
                <span>Delete Data</span>
              </button>
              <button mat-menu-item (click)="setTestData()"><mat-icon [style.visibility]="testData ? 'visible' : 'hidden'">done</mat-icon>Show Test Data</button>
              <button mat-menu-item (click)="resetReport()"><mat-icon>clear_all</mat-icon>Reset Report</button>"
            </mat-menu>
            <mat-menu #deleteMenu="matMenu">
              <button mat-menu-item (click)="deleteTestData()">Test Data...</button>
              <button mat-menu-item (click)="deleteLiveData()">Live Data...</button>
            </mat-menu>
          </div>
        </div>
        <div cdkDropList class="reports-leftSidebar-list custom-scroll-bar" (cdkDropListDropped)="drop($event)">
          <div class="reports-slctAll-container">
            <mat-checkbox [checked]="isChecked(-1)"
                          (change)="$event ? toggleAll($event) : null">Select All</mat-checkbox>
          </div>
          <div class="reports-question-sidebar-box" *ngFor="let question of report.Questions; let i = index" cdkDrag>
            <div [class]="currentQuestion == i ? 'reports-selected' : 'reports-notSelected'">
              <div class="row-flex-flow">
                <div>
                  <mat-checkbox (click)="$event.stopPropagation()"
                                (change)="$event ? toggle(i, $event) : null"
                                [checked]="isChecked(i)"></mat-checkbox>
                </div>
                <div>
                  <mat-icon class="reports-question-icon">{{questionIcon(question)}}</mat-icon>
                </div>
              </div>
              <div class="reports-qnSidebar-custPlaceholder" *cdkDragPlaceholder></div>
              <div class="reports-qnSidebar-text" (click)="selectQuestion(i)">
                <span>{{questionSummary(question)}}</span>
              </div>
              <div class="reports-drag-question">
                <mat-icon>drag_indicator</mat-icon>
              </div>
            </div>
          </div>
        </div>
      </div>
    </mat-sidenav>

    <mat-sidenav #rightSidenav class="reports-mat-sidenav-right" position="end" mode="side" opened [disableClose]="true">
      <div>
        <div class="reports-qnProp-heading-container">
          <div class="reports-qnProp-heading-content fullWidth">
            <span>Question Properties</span>
          </div>
        </div>
        <div [formGroup]="formGroup" class="reports-qnProp-heading-content">
          <mat-form-field appearance="outline">
            <mat-label>Question Label</mat-label>
            <textarea matInput formControlName="questionLabel" class="reports-rightNav-scroll"
                      placeholder="Question Label"
                      cdkTextareaAutosize
                      #autosize="cdkTextareaAutosize"
                      cdkAutosizeMinRows="1"
                      cdkAutosizeMaxRows="10"></textarea>
          </mat-form-field>
        </div>
        <div *ngIf="chartTypes.length > 0" class="reports-chartSlctr-div">
          <mat-button-toggle-group name="chartSelector">
            <mat-button-toggle *ngFor="let chartType of chartTypes; let i = index" (click)="chartChanged(i)" [checked]="selectedChartType === chartType.value" value="chartType.value">
              <mat-icon matTooltip="{{chartType.description}}" matTooltipPosition="above">{{chartType.icon}}</mat-icon>
            </mat-button-toggle>
          </mat-button-toggle-group>
        </div>
        <div *ngIf="chartTypes.length > 0" class="reports-clrScheme-div">
          <span>Color Scheme: </span>
          <mat-form-field appearance="outline">
            <mat-select [(ngModel)]="selectedColorScheme"
                        (ngModelChange)="setColorScheme($event)">
              <mat-option *ngFor="let scheme of colorSets" [value]="scheme.name">{{ scheme.name }}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <!--Testing changes for Grouping changes to reporting-->
        <mat-divider></mat-divider>
        <div *ngIf="isDiyTester() && chartTypes.length > 0" class="reports-clrScheme-div">
          <span>Group Results:</span>
          <section class="example-section">
            <mat-checkbox class="example-margin">Time</mat-checkbox>
            <mat-checkbox [checked]="myModel" class="example-margin">Profile</mat-checkbox>
          </section>
          <br />
          <mat-form-field appearance="outline">
            <mat-label>Group By</mat-label>
            <mat-select class="form-control" id="selectedvalue" (change)="onChangeDD($event)" required>
              <mat-option value="Title">Title</mat-option>
              <mat-option value="Age">Age</mat-option>
              <mat-option value="Gender">Gender</mat-option>
              <mat-option value="Country">Country</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
    </mat-sidenav>

    <mat-sidenav-content class="reports-sidenav-content">
      <div class="reports-content-div custom-scroll-bar">
        <div class="fullWidth">
          <div class="column-flex-flow" id="contentToConvert">
            <div *ngIf="isReady()">
              <h3>{{report.Questions[this.currentQuestion].Title}}</h3>
            </div>
            <div class="fullWidth">
              <div #chartContainer class="fullWidth">
              </div>
            </div>
          </div>
        </div>
      </div>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>
