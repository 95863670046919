<div *ngIf="!edit">
  <div class="row-flex panel-pricing-header-div">
    <div class="mr-10">
      <img *ngIf="panel && panel.Summary;" mat-card-sm-image style="width:80px" src="assets/img/respondents_2.png">
      <img *ngIf="panel && panel.Summary == undefined;" mat-card-sm-image style="width:80px" src="assets/img/buy_2.png">
    </div>
    <div *ngIf="survey?.PanelID === -1;then surveyOnly else panels"></div>
    <div *ngIf="!allowEdit && notEnough">
      <button mat-mini-fab matTooltip="Save" color="warn" (click)="editCurrentOptions()"><mat-icon>{{ edit ? 'save' : 'payment' }}</mat-icon></button>&nbsp;
    </div>
    <div *ngIf="allowEdit && allowDelete && notEnough" class="row-flex boxSizing-borderBox">
      <button mat-mini-fab color="warn" (click)="editCurrentOptions()"><mat-icon>{{ edit ? 'save' : 'payment' }}</mat-icon></button>&nbsp;
      <button *ngIf="allowDelete" mat-mini-fab (click)="cancelChoice()"><mat-icon>delete</mat-icon></button>
    </div>
    <div *ngIf="allowEdit && allowDelete && !notEnough" class="row-flex boxSizing-borderBox">
      <button mat-mini-fab (click)="editCurrentOptions()"><mat-icon>{{ edit ? 'save' : 'edit' }}</mat-icon></button>&nbsp;
      <button *ngIf="allowDelete" mat-mini-fab (click)="cancelChoice()"><mat-icon>delete</mat-icon></button>
    </div>
  </div>
</div>
<div *ngIf="edit">
  <ng-container *ngTemplateOutlet="editorButtons"></ng-container>
  <div style="display:flex;flex-flow:row">
    <div style="width:75%;margin:5px">
      <mat-stepper orientation="vertical" [linear]="true" #stepper>
        <mat-step *ngIf="panel" [stepControl]="firstFormGroup">
          <div style="display:flex;flex-flow:row;justify-content:space-between;padding:5px 0">
            <div [formGroup]="firstFormGroup">
              <ng-template matStepLabel>Respondents</ng-template>
              {{panel.Name}}
              {{respondentStatement()}}
            </div>
            <div *ngIf="panel?.PanellistCount > 0">
              <button mat-raised-button color="primary" (click)="viewPanel()">View Respondents</button>
            </div>
          </div>
          <div *ngIf="process">
            <app-process-definition [survey]="survey" [interview]="interview" [index]="0" [process]="process"></app-process-definition>
          </div>
        </mat-step>
        <mat-step>
          <div>
            <ng-template matStepLabel>Distribution</ng-template>
            <div *ngIf="edit && survey && survey.PanelID == -1;then distribution"></div>
            <div *ngIf="edit && survey && panel?.PanelType == 1;then distribution"></div>
            <div *ngIf="edit && panel && panel?.PanelType == 2" class="row-flex align-start-flex">
              <mat-divider></mat-divider>
              <div class="panel-pricing-congratsMsg-div">
                <div>
                  <span>Congratulations you have chosen to use one of our Pre-profiled Respondent Groups. If you change your mind you can delete this choice above and select another option from below.</span>
                </div>
              </div>
              <div *ngIf="edit;then pricing" class="display-flex"></div>
            </div>
            <div *ngIf="edit && panel && panel.PanelType == 0" class="row-flex align-start-flex">
              <mat-divider role="separator" class="mat-divider ng-tns-c331-127 mat-divider-horizontal mr-20" aria-orientation="horizontal"></mat-divider>
              <div class="panel-pricing-editPanel-container">
                <div>
                  Refine your selection.
                </div>
                <div>
                  <button mat-raised-button color="primary" (click)="editPanel()">Edit Panel</button>
                </div>
              </div>
            </div>
          </div>
        </mat-step>
        <mat-step>
          <div style="margin:5px 0">
            <ng-template matStepLabel>Schedule</ng-template>
            <h3>Select when you want your respondents invites/messages to be distributed</h3>
            <app-task-scheduler></app-task-scheduler>
          </div>
        </mat-step>
      </mat-stepper>
    </div>
    <div style="width:25%;margin-top:-15px">
      <div *ngIf="edit;then pricing"></div>
    </div>
  </div>
</div>
<ng-template #pricing>
  <app-pricing-summary #pricingSummary class="wd-25-per padding-10" [showBalance]="true" [estimate]="estimate" [panel]="panel" [survey]="survey"></app-pricing-summary>
</ng-template>

<ng-template #surveyOnly>
  <img mat-card-image mat-card-sm-image src="assets/img/your_way_2.png">&nbsp;&nbsp;
  <div class="column-flex fullWidth">
    <div>Send surveys your way</div>
    <div>Make sure you have enough credit in your account to cover the estimated number of completes.</div>
  </div>
</ng-template>

<ng-template #panels>
  <div *ngIf="panel && panel.Summary;then yourPanel else cintPanel"></div>
</ng-template>

<ng-template #yourPanel>
  @if (panel.PanellistCount != undefined && panel.PanellistCount > 0) {
  <div class="column-flex align-start-flex wd-90-per">
    <div>{{name}} - {{panel?.PanellistCount}} respondent(s) with {{panel?.Summary?.Emails}} email address(es) and {{panel?.Summary?.Devices}} Mobile Devices</div>
    <div>Total: £{{estimate?.Total | number:'1.2'}}</div>
  </div>
  } @else {
  <div class="column-flex align-start-flex wd-90-per">
    <div>Custom Data Import Service </div>
  </div>
  }
</ng-template>

<ng-template #cintPanel>
  <div *ngIf="panel && panel.PanelType === 2;then yourCintPanel else brandPanel"></div>
</ng-template>

<ng-template #brandPanel>
  <div *ngIf="panel && estimate" class="column-flex align-start-flex wd-90-per">
    <div>{{name}} - {{estimate?.Respondents}} respondent(s) that will automatically be contacted for their feedback</div>
    <div>Total: £{{estimate?.Total | number:'1.2'}}</div>
  </div>
</ng-template>

<ng-template #yourCintPanel>
  <div *ngIf="panel" class="column-flex align-start-flex wd-90-per">
    <div>{{name}} - {{estimate?.Respondents}} respondent(s) that will automatically be contacted for their feedback</div>
    <div>Total: £{{estimate?.Total | number:'1.2'}}</div>
  </div>
</ng-template>


<ng-template #distribution>
  <div class="column-flex alItems-flex-start">
    <div class="row-flex alItems-flex-start fullWidth">
      <div class="fullWidth">
        <div *ngIf="!displayQRTiny && survey && !(survey.JobMaster && survey.JobMaster.ChannelID > 0 && survey.Channel != 1)">
          <div *ngIf="panel && panel.Summary.Emails > 0 && panel.Summary.Devices > 0 && survey.Channel != 1 && survey.Channel != 5" class="surv-distr-header-span" [hidden]="!show">
            <span>How do you want to distribute your Survey?</span>
          </div>
          <div *ngIf="panel && panel.Summary.Emails > 0 && panel.Summary.Devices > 0 && survey.Channel == 1" [hidden]="!show">
            <h3>Your survey will be sent via SMS</h3>
            <p>Please make sure your first question introduces your survey and indicates how respondents should reply.</p>
          </div>
          <div class="surv-distr-sendType-container">
            <div *ngIf="panel && !panel.IsCint && survey.Channel != 1 && survey.Channel != 4" class="surv-distr-card-div">
              <a (click)="onSendClick(5)">
                <mat-card class="surv-distr-distribution-cards surv-distr-card">
                  <img mat-card-image src="assets/img/whatsapp.svg" alt="Send Survey by WhatsApp Invite" class="surv-distr-send-whatsapp-img">
                  <mat-card-content>
                    <div class="surv-distr-send-type-span">
                      <span>Send Survey by WhatsApp Invite</span>
                    </div>
                  </mat-card-content>
                </mat-card>
              </a>
            </div>
            <div *ngIf="panel && !panel.IsCint && panel.Summary.Emails > 0 && survey && survey.Channel != 3 && survey.Channel != 5 && survey.Channel != 1 && survey.Channel != 4" class="surv-distr-card-div">
              <a (click)="onSendClick(1)">
                <mat-card class="surv-distr-distribution-cards surv-distr-card">
                  <img mat-card-image src="assets/img/email_2.png" alt="Send Survey by email" class="surv-distr-send-type-img">
                  <mat-card-content>
                    <div class="surv-distr-send-type-span">
                      <span>Send Survey by email</span>
                    </div>
                  </mat-card-content>
                </mat-card>
              </a>
            </div>
            <div *ngIf="panel && !panel.IsCint && panel.Summary.Devices > 0 && survey && survey.Channel != 3 && survey.Channel != 5 && survey.Channel != 1" class="surv-distr-card-div">
              <a (click)="onSendClick(2)">
                <mat-card class="surv-distr-distribution-cards surv-distr-card">
                  <img mat-card-image src="assets/img/invite_2.png" alt="Send Survey link by SMS Invite" class="surv-distr-send-type-img">
                  <mat-card-content>
                    <div class="surv-distr-send-type-span">
                      <span>Send Survey link by SMS invite</span>
                    </div>
                  </mat-card-content>
                </mat-card>
              </a>
            </div>
          </div>
        </div>
        <div *ngIf="survey && survey.JobMaster && survey.JobMaster.ChannelID > 0 && survey.JobMaster.ChannelID !== 3 && survey.Channel != 1">
          <mat-card class="surv-distr-send-email-card column-flex">
            <div class="surv-distr-jobmaster-header auto-flex">
              <strong *ngIf="survey.JobMaster.ChannelID === 1">Your Email Invite</strong>
              <strong *ngIf="survey.JobMaster.ChannelID === 2">Your SMS Invite</strong>
            </div>
            <div *ngIf="survey.JobMaster.ChannelID === 1">
              <h3>{{survey.JobMaster.Subject}}</h3>
            </div>
            <div class="auto-flex">
              <div *ngIf="survey.JobMaster.ChannelID == 1 || survey.JobMaster.ChannelID == 2 || survey.JobMaster.ChannelID == 5">
                <mat-form-field *ngIf="survey.JobMaster.ChannelID != 1" appearance="outline" class="fullWidth">
                  <mat-label>Content</mat-label>
                  <textarea matInput [readonly]="true" class="surv-distr-textarea"
                            cdkTextareaAutosize
                            #autosize="cdkTextareaAutosize"
                            cdkAutosizeMinRows="6"
                            cdkAutosizeMaxRows="20">{{survey.JobMaster.Content}}</textarea>
                </mat-form-field>
                <div *ngIf="survey.JobMaster.ChannelID == 1" [innerHTML]="survey.JobMaster.Content" class="mat-form-field-appearance-outline surv-distr-inviteMessage"></div>
              </div>
            </div>
            <div class="auto-flex">
              <button mat-stroked-button (click)="onSendClick(survey.JobMaster.ChannelID)">Edit</button>&nbsp;&nbsp;
              <button mat-stroked-button (click)="cancelChoice()">Cancel</button>
            </div>
          </mat-card>
        </div>
        <div *ngIf="displayQRTiny" class="column-flex auto-flex margin-10">
          <div class="surv-distr-send-qr-card column-flex">
            <div class="surv-distr-send-qr-div auto-flex"><strong>Send by QR Code</strong></div>
            <div class="auto-flex">
              Copy this QR Code, add it to your chosen communication channel(s) e.g. Website, TV advert, Poster, Menu, Invoice) and invite respondents to scan it with their mobile device’s camera to take your survey.
            </div>
            <div class="txt-align-center auto-flex">
              <asw-qr-code [qrData]="surveyUrl"
                           [width]="180"
                           [height]="180"
                           logo="../../../assets/img/QrLogo.png"
                           [logoStyle]="{hideBackgroundCircle: true, logoSize: 0.3, logoMargin: 0}">    </asw-qr-code>
            </div>
            <div class="surv-distr-send-qr-div flow-flex">
              <strong>Survey URLs</strong>
            </div>
            <div class="flow-flex">
              Use this url and we will track its usage for you!
            </div>
            <div class="flow-flex">
              <mat-form-field appearance="outline" class="fullWidth">
                <input matInput readonly [value]="survey?.Sid">
                <button mat-icon-button matSuffix (click)="copied(survey?.Sid ?? '')">
                  <mat-icon class="surv-disr-icon">file_copy</mat-icon>
                </button>
              </mat-form-field>
            </div>
            <div class="flow-flex">
              or use our Tiny URL service for an easier version to share.
            </div>
            <div class="flow-flex">
              <mat-form-field appearance="outline">
                <input matInput readonly [value]="tinyUrl">
                <button mat-icon-button matSuffix (click)="copied(tinyUrl)">
                  <mat-icon matRipple class="surv-disr-icon">file_copy</mat-icon>
                </button>
              </mat-form-field>
            </div>
          </div>
        </div>
        <div *ngIf="displayKeyword" class="column-flex auto-flex margin-10">
          <div class="surv-distr-send-qr-card column-flex">
            <div class="surv-distr-send-qr-div auto-flex"><strong>Trigger your Survey with a Keyword</strong></div>
            <div class="auto-flex">
              Select a keyword from from your list or gnenerate one
            </div>
            <div class="flow-flex">
              <mat-form-field appearance="outline">
                <input matInput readonly [value]="tinyUrl">
                <button mat-icon-button matSuffix (click)="copied(tinyUrl)">
                  <mat-icon class="surv-disr-icon">file_copy</mat-icon>
                </button>
              </mat-form-field>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #editorButtons>
  <div class="simple-row">
    <div class="comm-edtr-divider-div">
      <mat-divider>
      </mat-divider>
    </div>
    <div>
      <button mat-mini-fab matTooltip="Save" (click)="update(1)"><mat-icon>save</mat-icon></button>
    </div>
    <div>
      <button mat-mini-fab color="accent" matTooltip="Delete" (click)="update(2)"><mat-icon>delete</mat-icon></button>
    </div>
    <div>
      <button mat-mini-fab matTooltip="Close" (click)="update(3)"><mat-icon>close</mat-icon></button>
    </div>
    <div class="comm-edtr-divider-div">
      <mat-divider>
      </mat-divider>
    </div>
  </div>
</ng-template>
