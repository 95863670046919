import { Component, OnInit, Input, OnDestroy, ViewChild, ElementRef, Optional, Self, Output, EventEmitter } from '@angular/core';
import { FormGroup, Validators, FormBuilder, Validator, NG_VALIDATORS, ControlValueAccessor, NgControl, AbstractControl, FormControl } from '@angular/forms';
import { MatFormFieldControl } from '@angular/material/form-field';
import { Subject, Observable } from 'rxjs';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { FocusMonitor } from '@angular/cdk/a11y';

export class RangeBox {
  minRange: number;
  maxRange: number;
  constructor(
    min: number,
    max: number
  ) {
    this.minRange = min;
    this.maxRange = max;
  }
}

@Component({
  selector: 'rangebox',
  templateUrl: './range-box-container.component.html'
})
export class RangeBoxComponent implements ControlValueAccessor, MatFormFieldControl<RangeBox> {
  constructor(
    formBuilder: FormBuilder,
    private _focusMonitor: FocusMonitor,
    private _elementRef: ElementRef<HTMLElement>,
    @Optional() @Self() public ngControl: NgControl
  ) {
    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }
  }
  static nextId = 0;
    stateChanges = new Subject<void>();
    placeholder: string = "";
    focused: boolean = false;
    empty: boolean = true;
    shouldLabelFloat: boolean = false;
    required: boolean = false;
    disabled: boolean = false;
    describedBy = '';
    errorState: boolean = false;
    controlType?: string;
    autofilled?: boolean;
    id = `rangebox-${RangeBoxComponent.nextId++}`;

    setDescribedByIds(ids: string[]): void {
      this.describedBy = ids.join(' ');
    }
    onContainerClick(event: MouseEvent): void {
    }

  onChange = (_: any) => { };

    registerOnTouched(fn: any): void {
      return;
    }
    setDisabledState?(isDisabled: boolean): void {
      return;
    }

  form: FormGroup = new FormGroup({
    range: new FormControl(new RangeBox(0,99))
  });
  onClose = (_: any) => { };
  onChangeFn = (_: any) => { };

  @Input()
  get value(): RangeBox | null {
    return this.rangeBox()?.value;
  }
  set value(range: RangeBox | null) {
    this.rangeBox()?.setValue(range);
  }

  rangeBox() {
    return this.form.get('range');
  }

  writeValue(range: any | null): void {
    this.value = range;
  }

  @Output()
  close: EventEmitter<FormGroup> = new EventEmitter<FormGroup>();

  @Output()
  change: EventEmitter<RangeBox | null> = new EventEmitter<RangeBox | null>();

  registerOnClose(fn: any): void {
    this.onClose = fn;;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  onClick($event: any) {
    this.close.emit(this.form);
  }

  onChangeValue($event: any, value: RangeBox | null) {
    this.change.emit(value);
  }
}

@Component({
  selector: 'app-ranger',
  templateUrl: './range-box.component.html',
  styleUrls: ['./range-box.component.css'],
  providers: [
    { provide: MatFormFieldControl, useExisting: RangerComponent }
  ],
  host: {
    '[class.range-floating]': 'shouldLabelFloat',
    '[id]': 'id',
    '[attr.aria-describedby]': 'describedBy'
  }
})
export class RangerComponent implements ControlValueAccessor, MatFormFieldControl<RangeBox>, OnDestroy {
  static nextId = 0;
  @ViewChild('minRange') minRangeInput: HTMLInputElement | undefined;
  @ViewChild('maxRange') maxRangeInput: HTMLInputElement | undefined;

  ranges: FormGroup;
  stateChanges = new Subject<void>();
  focused = false;
  errorState = false;
  controlType = 'app-ranger';
  id = `app-ranger-${RangerComponent.nextId++}`;
  describedBy = '';
  onChange = (_: any) => { };
  onTouched = () => { };
  timeout: any | null = null;

  get empty() {
    const {
      value: { minRange, maxRange }
    } = this.ranges;

    return !minRange && !maxRange;
  }

  get shouldLabelFloat() {
    return this.focused || !this.empty;
  }

  @Input()
  get placeholder(): string {
    return this._placeholder;
  }
  set placeholder(value: string) {
    this._placeholder = value;
    this.stateChanges.next();
  }
  private _placeholder: string = "";

  @Input()
  get required(): boolean {
    return this._required;
  }
  set required(value: boolean) {
    this._required = coerceBooleanProperty(value);
    this.stateChanges.next();
  }
  private _required = false;

  @Input()
  get disabled(): boolean {
    return this._disabled;
  }
  set disabled(value: boolean) {
    this._disabled = coerceBooleanProperty(value);
    this._disabled ? this.ranges.disable() : this.ranges.enable();
    this.stateChanges.next();
  }
  private _disabled = false;

  @Input()
  get value(): RangeBox | null {
    if (this.ranges.valid) {
      return new RangeBox(parseInt(this.ranges.value.minRange), parseInt(this.ranges.value.maxRange));
    }
    return null;
  }
  set value(range: RangeBox | null) {
    const r = range || new RangeBox(0, 0);
    this.ranges.setValue({ minRange: r.minRange, maxRange: r.maxRange });
    this.stateChanges.next();
  }

  constructor(
    formBuilder: FormBuilder,
    private _focusMonitor: FocusMonitor,
    private _elementRef: ElementRef<HTMLElement>,
    @Optional() @Self() public ngControl: NgControl
  ) {
    this.ranges = formBuilder.group({
      minRange: [
        null, [Validators.required, Validators.minLength(1), Validators.maxLength(2)]
      ],
      maxRange: [
        null, [Validators.required, Validators.minLength(1), Validators.maxLength(2)]
      ]
    });

    _focusMonitor.monitor(_elementRef, true).subscribe(origin => {
      if (this.focused && !origin) {
        this.onTouched();
      }
      this.focused = !!origin;
      this.stateChanges.next();
    });

    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }
  }

  autoFocusNext(control: AbstractControl, nextElement?: HTMLInputElement): void {
    if (!control.errors && nextElement) {
      this._focusMonitor.focusVia(nextElement, 'program');
    }
  }

  autoFocusPrev(control: AbstractControl, prevElement: HTMLInputElement): void {
    if (control.value.length < 1) {
      this._focusMonitor.focusVia(prevElement, 'program');
    }
  }

  ngOnDestroy() {
    this.stateChanges.complete();
    this._focusMonitor.stopMonitoring(this._elementRef);
  }

  setDescribedByIds(ids: string[]) {
    this.describedBy = ids.join(' ');
  }

  onContainerClick(event: MouseEvent): void {
  }

  writeValue(range: RangeBox | null): void {
    this.value = range;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  _handleInput(control: AbstractControl, nextElement?: HTMLInputElement): void {
    /*if (control.value.length == 2) {
      this.autoFocusNext(control, nextElement);
    }*/
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      this.onChange(this.value);
    }, 500);
  }

  static ngAcceptInputType_disabled: boolean | string | null | undefined;
  static ngAcceptInputType_required: boolean | string | null | undefined;
}
