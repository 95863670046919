<div class="start-frm-temp-template-container">
  <div class="dashboard-template-content">
    <app-gateway-activity></app-gateway-activity>
  </div>
  <div class="dashboard-template-content">
    <app-invite-analysis></app-invite-analysis>
  </div>
  <div class="dashboard-template-content">
    <app-active-dataio></app-active-dataio>
  </div>
  <div class="dashboard-template-content">
    <app-active-users></app-active-users>
  </div>
  <div class="dashboard-template-content">
    <app-active-surveys></app-active-surveys>
  </div>
</div>
