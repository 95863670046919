import { Component, OnInit, Input } from '@angular/core';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { AnswerModel } from '../../models/AnswerModel';

export class BucketGroup {
  name: string = "";
  ranks: BucketRank[] = [];
  constructor(name?: string) {
    if (name != undefined) {
      this.name = name;
    }
  }
}

export class BucketRank {
  name: string = "";
  value: number = 0;
  label: string = "";

  constructor(name?: string, value?: number, label?: string) {
    if (name != undefined) {
      this.name = name;
    }

    if (value != undefined) {
      this.value = value;
    }

    if (label != undefined) {
      this.label = label;
    }
  }
}

@Component({
  selector: 'app-bucket',
  templateUrl: './bucket.component.html',
  styleUrls: ['./bucket.component.css']
})
export class BucketComponent implements OnInit {

  isHandset$: boolean = false;

  constructor() { }

  static nextId = 0;
  groups: BucketGroup[] = [];
  groupNames: string[] = [];
  bucketNames: string[] = [];
  ngOnInit(): void {
  }

  @Input()
  get playerWidth(): number {
    return this._playerWidth;
  }
  set playerWidth(value: number) {
    this._playerWidth = value;
    this.isHandset$ = this.playerWidth != undefined && this.playerWidth < 400 && this.playerWidth != -1;
  }
  private _playerWidth: number = -1;

  @Input()
  get question(): any {
    return this._question;
  }
  set question(value: any) {
    this.groups = [];
    this.bucketNames = [];
    this._question = value;
    if (this._question === undefined) {
      return;
    }

    // Figure out the number of groups
    this.groups.push(new BucketGroup(""));
    if (this._question.Item.Items.length > 0) {
      for (let j = 0; j < this._question.Item.Items[0].Item.Items.length; j++) {
        this.groups.push(new BucketGroup(this._question.Item.Items[0].Item.Items[j].Label.Text));
        this.bucketNames.push(this._question.Item.Items[0].Item.Items[j].QuestionName);
      }
    }

    // Add the categories to the correct group
    let j = 0;
    this._question.Item.Items.forEach((question: any) => {
      let groupPtr = 0;
      let i = 0;
      let rank = new BucketRank(question.QuestionFullName, -1, this._question.Categories.Items[j].Label.Text);
      question.Item.Items.forEach((question: any) => {
        if (question.Response.Value === null) {
          if (rank.value < 0) {
            rank.value = 0;
          }
        }
        else {
          if (rank.value < 1) {
            rank.value = question.Response.Value;
            groupPtr = i + 1;
          }
        }
        i++;
      });
      let added = false;
      if (this.groups[groupPtr].ranks.length < this._question.Categories.Items.length) {
        for (let k = 0; k < this.groups[groupPtr].ranks.length; k++) {
          if (rank.value < this.groups[groupPtr].ranks[k].value) {
            added = true;
            this.groups[groupPtr].ranks.splice(k, 0, rank);
            break;
          }
        }
        if (!added) {
          this.groups[groupPtr].ranks.push(rank);
        }
      }
      j++;
    });
    this.groupNames = [];
    for (let i = 0; i < this.groups.length; i++) {
      this.groupNames.push(`bucket-group${this.id()}`);
    }
  }
  private _question: any

  id() {
    // Math.random should be unique because of its seeding algorithm.
    // Convert it to base 36 (numbers + letters), and grab the first 9 characters
    // after the decimal.
    return '_' + Math.random().toString(36).substr(2, 9);
  }

  drop(event: CdkDragDrop<BucketRank[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    }
    else {
      transferArrayItem(event.previousContainer.data,
                        event.container.data,
                        event.previousIndex,
                        event.currentIndex);
    }
  }

  get answers(): AnswerModel[] {
    const model: AnswerModel[] = [];
    for (let j = 1; j < this.groups.length; j++) {
      let i = 1;
      this.groups[j].ranks.forEach((rank: any) => {
        model.push(new AnswerModel(rank.name + "." + this.bucketNames[j - 1], i.toString()));
        i++;
      });
    };
    return model;
  }

  get valid(): boolean {
    return true;
  }
}
