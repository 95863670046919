import { Component } from '@angular/core';
import { NetworkService, ZoneSummary, MessageQueue, SpeedDefinition } from '../../network.Service';
import { plainToClass } from 'class-transformer';
import { MessageQueueEvent } from '../message-queue/message-queue.component';

@Component({
  selector: 'app-routing-summary',
  templateUrl: './routing-summary.component.html',
  styleUrl: './routing-summary.component.css'
})
export class RoutingSummaryComponent {
  selectedIndex: number = 0;

  details: any = [
    { name: 'Status', description: 'System Status ', comms: [] = [] },
    { name: 'Connection Templates', description: 'Connection Templates ', comms: [] = [] },
    { name: 'Error Code Mappings', description: 'Error Code Mappings ', comms: [] = [] },
    { name: 'Message Managers', description: 'Message Managers ', comms: [] = [] },
    { name: 'Zones', description: 'Zones ', comms: [] = [] },
    { name: 'Routing', description: 'Routing ', comms: [] = [] },
    { name: 'Gateway Analysis', description: 'Gateway Traffic Analysis', coms: [] = [] }
  ];

  zoneSummary: ZoneSummary[] = [];
  messageSummary: ZoneSummary[] = [];
  queues: MessageQueue[] = [];
  queuePreviews: number[] = [];

  constructor(private networkService: NetworkService) {
    this.getDetails();
  }

  zoneType(type: number): string {
    switch (type) {
      case 0: return "Shotgun";
      case 1: return "SMS Gateway";
      case 2: return "Message Manager";
      case 6: return "WhatsApp Gateway";
      default: return "Unknown";
    }
  }

  zoneStatus(status: number): string {
    switch (status) {
      case 0: return "Starting";
      case 1: return "Running";
      case 2: return "Stopped";
      default: return "Unknown";
    }
  }

  newQueue() {
    let queue = new MessageQueue();
    this.queues.push(queue);
    this.queuePreviews.push(1);
  }

  removefromQueue(queue: MessageQueue) {
    for (let i = 0; i < this.queues.length; i++) {
      if (this.queues[i].QueueId == queue.QueueId) {
        this.queues.splice(i, 1);
        break;
      }
    }
  }

  deleteQueue(queue: MessageQueue) {
    if (queue.QueueId == 0) {
      this.removefromQueue(queue);
      return;
    }
    this.networkService.DeleteMessageQueueDefinitionForUser(queue.QueueId).subscribe(
      result => {
        this.removefromQueue(queue);
      }
    )
  }

  processClosed(event: MessageQueueEvent) {
    switch (event.action) {
      case "closed":
        if (event.queue.QueueId == 0) {
          this.removefromQueue(event.queue);
        }

        break;
      case "copy":
        break;
      case "saved":
        for (let i = 0; i < this.queues.length; i++) {
          if (this.queues[i].QueueId == event.queue.QueueId) {
            this.queues[i] = event.queue;
            return;
          }
        }

        break;
      case "delete":
        this.deleteQueue(event.queue);
        break;
    }
  }

  getDetails() {
    this.getSummary();
    this.getQueues();
  }

  getSummary() {
    this.networkService.getSummary().subscribe(
      result => {
        this.zoneSummary = result;
        this.messageSummary = [];
        for (let i = 0; i < this.zoneSummary.length; i++) {
          if (this.zoneSummary[i].Type == 2) {
            this.messageSummary.push(this.zoneSummary[i]);
          }
        }
      }
    );
  }

  getQueues() {
    this.networkService.getMessageQueueDefinitions().subscribe(
      result => {
        let q = [];
        this.queuePreviews = [];
        for (let i = 0; i < result.length; i++) {
          let queue = plainToClass(MessageQueue, result[i]) as MessageQueue;
          queue.MessageCharacteristics.DefaultSpeed = plainToClass(SpeedDefinition, queue.MessageCharacteristics.DefaultSpeed);
          for (let j = 0; j < queue.MessageCharacteristics.AllowedSpeeds.length; j++) {
            queue.MessageCharacteristics.AllowedSpeeds[j] = plainToClass(SpeedDefinition, queue.MessageCharacteristics.AllowedSpeeds[j]);
          }

          q.push(queue);
          this.queuePreviews.push(0);
        }

        this.queues = q;
      }
    );
  }
}
