<div class="user-details-dialog-container">
  <div class="leftFloat">
    <h3>Respondent Viewer</h3>
  </div>
  <div class="rightFloat">
    <button mat-icon-button matDialogClose="no" matTooltip="Close" matTooltipPosition="below">
      <mat-icon class="user-details-dialog-close-icon">close</mat-icon>
    </button>
  </div>
</div>
<div>
  <app-edit-panel #panel [panelId]="panelId"></app-edit-panel>
</div>
