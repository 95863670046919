import { Component } from '@angular/core';
import { Observable } from "rxjs";
import { tap } from 'rxjs/operators';
import { UserService, UserSummary } from '../../user.Service';
import { TranslateService } from '@ngx-translate/core';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-active-users',
  templateUrl: './active-users.component.html',
  styleUrls: ['./active-users.component.css']
})
export class ActiveUsersComponent {
  tempSummary: Observable<any>;
  summary: UserSummary[] = null;
  displayedColumns = ['UserID', 'BusinessType', 'LastLoggedIn', 'UserName'];
  dataSource = new MatTableDataSource(this.summary);

  constructor(public translate: TranslateService,
    private userService: UserService) {
    this.tempSummary = userService.getTopUsers(5).pipe(tap<UserSummary[]>(result => {
      this.summary = result;
      this.dataSource = new MatTableDataSource(this.summary);
    }));
  }

  edit(row: any) {

  }

  businessType(user: UserSummary): string {
    if (UserService.hasRole(user.Roles, "Cash")) {
      return "monetization_on";
    }

    return "credit_card";
  }

  isTemplate(user: UserSummary): boolean {
    return UserService.hasRole(user.Roles, "template") || UserService.hasRole(user.Roles, "Admin");
  }

  templateType(user: UserSummary): string {
    return UserService.hasRole(user.Roles, "Admin") ? "A" : "T";
  }

}
