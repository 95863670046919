import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormArray, Validators } from '@angular/forms';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { BaseQuestion, DataModel, InterviewService, QuestionService } from '../../question.service';

@Component({
  selector: 'qe-app-ranking',
  templateUrl: './qe-ranking.component.html',
  styleUrls: ['./qe-ranking.component.css']
})
export class QeRankingComponent extends BaseQuestion implements OnInit {
  constructor(private breakpointObserver: BreakpointObserver, private qs: QuestionService, private is: InterviewService) {
    super(qs, is, undefined, undefined, new DataModel("ranking"));
  }

  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
    .pipe(map(result => result.matches));

  public rankingFormGroup: FormGroup = new FormGroup({});
  public optionFormGroup: FormGroup = new FormGroup({});

  ngOnInit() {
    this.rankingFormGroup = this.fb.group({
      rankingFormArray: this.fb.array([this.initQuestion()])
    });

    this.optionFormGroup = this.fb.group({
      questionOption: this.fb.array([this.initOptionRow()])
    });
  }

  get optionArr() {
    return this.optionFormGroup.get('questionOption') as FormArray;
  }

  initOptionRow() {
    return this.fb.group({
      qOption: ['', Validators.required]
    });
  }

  onSubmit(): void {
  }

  addNewOption() {
    this.optionArr.push(this.initOptionRow());
  }

  removeOption(index: number) {
    console.log(this.optionArr.length);
    if (this.optionArr.length > 1) {
      this.optionArr.removeAt(index);
    }
  }

  get questionArr(): FormArray | null {
    if (this.rankingFormGroup) {
      return this.rankingFormGroup.get('rankingFormArray') as FormArray;
    }

    return null;
  }
}
