import { Component } from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { AuthenticationService } from '../../authentication.Service';
import { MissionService } from '../../MissionService';
import { NetworkModel, NetworkService, NumberModel } from '../../network.Service';

@Component({
  selector: 'app-network-detail',
  templateUrl: './network-detail.component.html',
  styleUrls: ['./network-detail.component.css']
})
export class NetworkDetailComponent {
  subscription: Subscription;
  formGroup: FormGroup = new FormGroup({});
  id: number = 0;
  network: NetworkModel;

  constructor(public translate: TranslateService,
    private authenticationService: AuthenticationService,
    private missionService: MissionService,
    private snackbar: MatSnackBar,
    private networkService: NetworkService,
    private actRoute: ActivatedRoute,
    private router: Router) {
    this.formGroup = new FormGroup({
      name: new FormControl(""),
      description: new FormControl(""),
      element: new FormArray([this.newRow()])
    })
    this.subscription = missionService.missionAnnounced$.subscribe(
      mission => {
        if (mission === "Back") {
          this.cancel();
        }
      });
    this.id = this.actRoute.snapshot.params.id;
    if (this.id > 0) {
      this.networkService.getNetwork(this.id).subscribe(result => {
        this.network = result;
        this.formGroup.controls.name.setValue(this.network.Name);
        this.formGroup.controls.description.setValue(this.network.Description);
        this.optionArr.clear();
        for (let i = 0; i < this.network.Numbers.length; i++) {
          this.optionArr.push(this.newRowWithData(this.network.Numbers[i]));
        }

        if (this.network.Numbers.length == 0) {
          this.optionArr.push(this.newRow());
        }
      });
    }
    else {
      this.network = new NetworkModel();
    }
  }

  get optionArr(): FormArray {
    return this.formGroup.get('element') as FormArray;
  }

  newRow() {
    return this.newRowWithData(new NumberModel());
  }

  newRowWithData(data: NumberModel) {
    let capability = [];
    if (data.Brandable) {
      capability.push(1);
    }

    if (data.GsmStandard) {
      capability.push(2);
    }

    if (data.GsmExtended) {
      capability.push(3);
    }

    if (data.Unicode) {
      capability.push(4);
    }

    if (data.Brandable) {
      capability.push(5);
    }

    let whitelisting = [];
    if (data.WhitelistingBranding) {
      whitelisting.push(1);
    }

    if (data.WhitelistingInvite) {
      whitelisting.push(2);
    }

    if (data.WhitelistingReminder) {
      whitelisting.push(3);
    }

    if (data.WhitelistingSurveyLink) {
      whitelisting.push(4);
    }

    if (data.WhitelistingOptOut) {
      whitelisting.push(5);
    }

    if (data.WhitelistingTinyUrl) {
      whitelisting.push(6);
    }

    let form = new FormGroup({
      number: new FormControl(data.Number),
      direction: new FormControl(data.TwoWay ? 2 : 1),
      capability: new FormControl(capability),
      whitelisting: new FormControl(whitelisting)
    });

    /*let sub = form.valueChanges.subscribe(x => {
      this.generateChange();
    });

    this.subscription.push(sub);*/
    return form;
  }

  onSubmit() {
    this.network.Name = this.formGroup.controls.name.value;
    this.network.Description = this.formGroup.controls.description.value;
    this.network.Numbers = [];
    for (let i = 0; i < this.optionArr.length; i++) {
      let form = this.optionArr.controls[i] as FormGroup;
      if (form.controls.number.value != "") {
        let number = new NumberModel();
        number.NetworkId = this.network.NetworkId;
        number.Number = form.controls.number.value;
        number.TwoWay = form.controls.direction.value == 2;
        number.Concatenation = form.controls.capability.value.includes(1);
        number.GsmStandard = form.controls.capability.value.includes(2);
        number.GsmExtended = form.controls.capability.value.includes(3);
        number.Unicode = form.controls.capability.value.includes(4);
        number.Brandable = form.controls.capability.value.includes(5);
        number.WhitelistingBranding = form.controls.whitelisting.value.includes(1);
        number.WhitelistingInvite = form.controls.whitelisting.value.includes(2);
        number.WhitelistingReminder = form.controls.whitelisting.value.includes(3);
        number.WhitelistingSurveyLink = form.controls.whitelisting.value.includes(4);
        number.WhitelistingOptOut = form.controls.whitelisting.value.includes(5);
        number.WhitelistingTinyUrl = form.controls.whitelisting.value.includes(6);
        this.network.Numbers.push(number);
      }
    }

    this.networkService.saveNetwork(this.network).subscribe(
      result => {
        this.openSnackbar("Successfully saved", "");
        this.router.navigate(["/networkmanager"]);
      },
      error => {
        this.openSnackbar("There was a problem saving the network", "Cancel");
      });
  }

  cancel() {
    this.router.navigate(["/networkmanager"]);
  }

  addNewRow(index: number) {
    this.optionArr.push(this.newRow());
  }

  deleteRow(index: number) {
    if (this.optionArr.controls.length == 1) {
      return;
    }

    this.optionArr.controls.splice(index, 1);
  }

  private openSnackbar(message: string, action: string) {
    if (action == "") {
      this.snackbar.open(message, action, { duration: 2000 });
    }
    else {
      this.snackbar.open(message, action, { duration: 7000 });
    }
  }
}
