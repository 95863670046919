import { Component, OnInit } from '@angular/core';
import { DeliveryItem, SurveyModel, SurveyService, SurveySummaryModel } from '../survey.Service';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { MissionService } from '../MissionService';
import { MatTableDataSource } from '@angular/material/table';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-delivery-summary',
  templateUrl: './delivery-summary.component.html',
  styleUrls: ['./delivery-summary.component.css']
})
export class DeliverySummaryComponent implements OnInit {

  subscription: Subscription;
  surveySummary: SurveySummaryModel = new SurveySummaryModel();
  summary: DeliverySummaryModel[] = [];
  dataSource = new MatTableDataSource<DeliverySummaryModel>(this.summary);
  completionratepercentage: number = 0
  id: number;
  outstanding: number = 0;
  name: string = "";
  results: ReportDataMatrix[] = [];
  showchart = false;
  view: [number, number] = [500, 30];
  deliveryView: [number, number] = [500, 300];
  inviteMessage = "Emails";

  // options
  showXAxis = false;
  showYAxis = false;
  gradient = false;
  showLegend = false;
  showXAxisLabel = false;
  xAxisLabel = '';
  showYAxisLabel = false;
  yAxisLabel = 'Count';
  animations: boolean = true;
  displayedColumns: string[] = ['distributionchannel', 'audiencesize', 'sent', 'responses', 'outstanding', 'completionrate'];
  dummyResults: ReportDataMatrix[] = [];

  colorScheme: any = {
    domain: ['#C00000', '#FF0000', '#FFC000', '#92D050']
  };

  constructor(private surveyService: SurveyService, private actRoute: ActivatedRoute, private router: Router,
    private missionService: MissionService,
    public translate: TranslateService) {
    this.id = Number(this.actRoute.snapshot.params.id);
    this.router = router;
    this.surveyService.getSurveySummary(this.id)
      .subscribe(result => {
        this.surveySummary = result;
        switch (this.surveySummary.Channel) {
          case 1:
            this.inviteMessage = "SMS Surveys";
            break;
          case 3:
            this.inviteMessage = "WhatsApp Invitations";
            break;
          case 4:
            this.inviteMessage = "SMS Invitations";
            break;
          case 5:
            this.inviteMessage = "WhatsApp Surveys";
            break;
          default:
            this.inviteMessage = "Emails"
            break;
        }

        this.name = this.surveySummary.Name;
        this.outstanding = this.surveySummary.PanellistCount - this.surveySummary.CompletionCount;
        if (this.outstanding < 0) {
          this.outstanding = 0;
        }

        this.completionratepercentage = this.surveySummary.CompletionCount / this.surveySummary.PanellistCount * 100;

        const element: DeliverySummaryModel[] = [
          { DistributionChannel: "Email Invite", AudienceSize: this.surveySummary.PanellistCount, Sent: this.surveySummary.PanellistCount, Responses: this.surveySummary.CompletionCount, Outstanding: this.outstanding, completionrate: this.completionratepercentage },
        ];
        let temp = new ReportDataMatrix("Invites");
        temp.series.push(new ChartData("Sent", this.surveySummary.CompletionCount));
        temp.series.push(new ChartData("Failed", 0));
        temp.series.push(new ChartData("Outstanding", this.outstanding));
        temp.series.push(new ChartData("Completed", this.surveySummary.CompletionCount));
        this.results.push(temp);
        this.surveyService.getDeliveryStatsSinceBeginning(this.surveySummary.SurveyID).subscribe(result => {
          let prevItem: DeliveryItem | null = null;
          let series = this.getDummyValues();
          for (let i = 0; i < result.length; i++) {
            let item = result[i];
            if (prevItem == null) {
              prevItem = item;
              series = this.getDummyValues();
            }

            if (prevItem.Period != item.Period) {
              let chart = new ReportDataMatrix(prevItem.Label);
              chart.series = series;
              this.dummyResults.push(chart);
              series = this.getDummyValues();
            }
            else {
              switch (item.StatusValue) {
                case 0:
                  series[0].value = item.Total;
                  break;
                case 1:
                  series[2].value = item.Total;
                  break;
                default:
                  series[3].value = item.Total;
                  break;
              }
            }

            prevItem = item;
          }

          if (prevItem != null) {
            let chart = new ReportDataMatrix(prevItem.Label);
            chart.series = series;
            this.dummyResults.push(chart);
          }

          this.dataSource = new MatTableDataSource(element);
          this.showchart = true;
        });
      });
  }

  isNotInvite(): boolean {
    return this.inviteMessage == "Emails" || this.inviteMessage == "SMS Surveys" || this.inviteMessage == "WhatsApp Surveys";
  }

  ngOnInit(): void {
  }

  onSubmit() {
    this.router.navigate(["/surveydashboard"]);
  }

  onSelect(data: any): void {
    console.log('Item clicked', JSON.parse(JSON.stringify(data)));
  }

  onActivate(data: any): void {
    console.log('Activate', JSON.parse(JSON.stringify(data)));
  }

  onDeactivate(data: any): void {
    console.log('Deactivate', JSON.parse(JSON.stringify(data)));
  }

  getDummyValues() {
    let items = [];
    items.push(new ChartData("Sent", 0));
    items.push(new ChartData("Failed", 0));
    items.push(new ChartData("Started", 0));
    items.push(new ChartData("Completed", 0));
    return items;
  }

  channelDescription(): string {
    if (this.surveySummary == undefined) {
      return "";
    }

    switch (this.surveySummary.Channel) {
      case 0:
        return "Email";
      case 1:
        return "SMS";
      case 2:
      case 3:
        return "WhatsApp";
      case 4:
        return "SMS";
      case 5:
        return "WhatsApp";
      default:
        return "Email";
    }
  }

  private getName(status: number) {
    switch (status) {
      case 0:
        return "Sent";
      case 1:
        return "Started";
      case 2:
        return "Completed";
      case 3:
        return "Quota Exceeeded";
      case 4:
        return "Terminated";
      case 5:
        return "Timed Out";
      case 6:
        return "Shutdown";
      case 7:
        return "Screened Out";
      default:
        return "Unknown";
    }
  }
}

export class ReportDataMatrix {
  name: string;
  series: ChartData[] = [];

  constructor(name: string) {
    this.name = name;
  }
}

export class ChartData {
  name: string;
  value: number;
  constructor(name: string, value: number) {
    this.name = name;
    this.value = value;
  }
}

export class DeliverySummaryModel {


  DistributionChannel: string = "";
  AudienceSize: number = 0;
  Sent: number = 0;
  Responses: number = 0;
  Outstanding: number = 0;
  completionrate: number = 0;
}
