import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { deserialize, plainToClass } from 'class-transformer';
import { CommsModel, CommsService, WhatsAppComponent, WhatsAppFullTemplate, WhatsAppTemplate } from '../../comms.Service';
import { JsonCyclic } from '../../utils';

@Component({
  selector: 'app-comm-editor',
  templateUrl: './comm-editor.component.html',
  styleUrls: ['./comm-editor.component.css']
})
export class CommEditorComponent {

  formGroup: FormGroup = new FormGroup({});
  formWAGroup: FormGroup = new FormGroup({});
  formSendGroup: FormGroup = new FormGroup({});
  preview: number = 0;
  isSaving: boolean = false;
  destination: string = "";
  customPatterns = { 'S': { pattern: new RegExp('\[a-z\]') }, 'A': { pattern: new RegExp('\[_0-9a-zA-Z\]') } };
  types: any[] = [
    { id: 1, name: 'Email Invitation', description: 'Email Invitation for a survey', admin: false },
    { id: 2, name: 'Email Reminder', description: 'Email Reminder for a survey', admin: false },
    { id: 3, name: 'Reset Password', description: 'Reset Password message for the system', admin: true },
    { id: 4, name: 'Registration Confirmation', description: 'Registration confirmation email for the system', admin: true },
    { id: 5, name: 'Forgot Password', description: 'Forgot password email for the system', admin: true },
    { id: 6, name: 'Email Change Warning', description: 'Change of email address warning for the system', admin: true },
    { id: 7, name: 'Username Change Warning', description: 'Change of username warning for the sytem', admin: true },
    { id: 8, name: 'Profile Change Warning', description: 'Change of profile warning for the system', admin: true },
    { id: 9, name: 'Organization Invitation', description: 'Organization Email Invitation for the sytem', admin: true },
    { id: 10, name: 'Non-Member Organization Invitation', description: 'Non-Member of the system Organization invitation for the system', admin: true },
    { id: 11, name: 'Report Invitation', description: 'Report invitation for the system', admin: true },
    { id: 12, name: 'Non-Member Report Invitation', description: 'Non-Member Report Invitation for the system', admin: true },
    { id: 14, name: 'Email 2FA', description: 'Two-Factor Authentication Email for the system', admin: true },
    { id: 15, name: 'Network Request', description: 'Network request email for the system', admin: true },
    { id: 16, name: 'Network Request Update', description: 'Network request update email for the system', admin: true },
    { id: 13, name: 'WhatsApp Invitation', description: 'WhatsApp Invitation for a web survey', admin: false },
    { id: 17, name: 'WhatsApp Two-Way Invitation', description: 'WhatsApp Invitation for a two-way WhatsApp survey', admin: false },
    { id: 18, name: 'SMS Invitation', description: 'SMS Invitation for a survey', admin: false },
    { id: 19, name: 'SMS Reminder', description: 'SMS Reminder for a survey', admin: false },
    { id: 20, name: 'SMS 2FA', description: 'Two-Factor Authentication SMS for the system', admin: true },
    { id: 21, name: 'WhatsApp Application', description: 'WhatsApp application email for the system', admin: true },
    { id: 22, name: 'WhatsApp Application Update', description: 'WhatsApp application update email for the system', admin: true },
    { id: 23, name: 'DataIO Notification', description: 'Data Import/Export Notification email for users and admin', admin: true },
    { id: 24, name: 'Request Speed Management', description: 'Request Speed Management for API', admin: true },
    { id: 30, name: 'Tell Me Later', description: 'Tell me later email to the CST about potential signups', admin: true },
  ];

  languages: any[] = [
    { 'name': 'Afrikaans', 'code': 'af' },
    { 'name': 'Albanian', 'code': 'sq' },
    { 'name': 'Arabic', 'code': 'ar' },
    { 'name': 'Azerbaijani', 'code': 'az' },
    { 'name': 'Bengali', 'code': 'bn' },
    { 'name': 'Bulgarian', 'code': 'bg' },
    { 'name': 'Catalan', 'code': 'ca' },
    { 'name': 'Chinese (CHN)', 'code': 'zh_CN' },
    { 'name': 'Chinese (HKG)', 'code': 'zh_HK' },
    { 'name': 'Chinese (TAI)', 'code': 'zh_TW' },
    { 'name': 'Croatian', 'code': 'hr' },
    { 'name': 'Czech', 'code': 'cs' },
    { 'name': 'Danish', 'code': 'da' },
    { 'name': 'Dutch', 'code': 'nl' },
    { 'name': 'English', 'code': 'en' },
    { 'name': 'English (UK)', 'code': 'en_GB' },
    { 'name': 'English (US)', 'code': 'en_US' },
    { 'name': 'Estonian', 'code': 'et' },
    { 'name': 'Filipino', 'code': 'fil' },
    { 'name': 'Finnish', 'code': 'fi' },
    { 'name': 'French', 'code': 'fr' },
    { 'name': 'Georgian', 'code': 'ka' },
    { 'name': 'German', 'code': 'de' },
    { 'name': 'Greek', 'code': 'el' },
    { 'name': 'Gujarati', 'code': 'gu' },
    { 'name': 'Hausa', 'code': 'ha' },
    { 'name': 'Hebrew', 'code': 'he' },
    { 'name': 'Hindi', 'code': 'hi' },
    { 'name': 'Hungarian', 'code': 'hu' },
    { 'name': 'Indonesian', 'code': 'id' },
    { 'name': 'Irish', 'code': 'ga' },
    { 'name': 'Italian', 'code': 'it' },
    { 'name': 'Japanese', 'code': 'ja' },
    { 'name': 'Kannada', 'code': 'kn' },
    { 'name': 'Kazakh', 'code': 'kk' },
    { 'name': 'Kinyarwanda', 'code': 'rw_RW' },
    { 'name': 'Korean', 'code': 'ko' },
    { 'name': 'Kyrgyz (Kyrgyzstan)', 'code': 'ky_KG' },
    { 'name': 'Lao', 'code': 'lo' },
    { 'name': 'Latvian', 'code': 'lv' },
    { 'name': 'Lithuanian', 'code': 'lt' },
    { 'name': 'Macedonian', 'code': 'mk' },
    { 'name': 'Malay', 'code': 'ms' },
    { 'name': 'Malayalam', 'code': 'ml' },
    { 'name': 'Marathi', 'code': 'mr' },
    { 'name': 'Norwegian', 'code': 'nb' },
    { 'name': 'Persian', 'code': 'fa' },
    { 'name': 'Polish', 'code': 'pl' },
    { 'name': 'Portuguese (BR)', 'code': 'pt_BR' },
    { 'name': 'Portuguese (POR)', 'code': 'pt_PT' },
    { 'name': 'Punjabi', 'code': 'pa' },
    { 'name': 'Romanian', 'code': 'ro' },
    { 'name': 'Russian', 'code': 'ru' },
    { 'name': 'Serbian', 'code': 'sr' },
    { 'name': 'Slovak', 'code': 'sk' },
    { 'name': 'Slovenian', 'code': 'sl' },
    { 'name': 'Spanish', 'code': 'es' },
    { 'name': 'Spanish (ARG)', 'code': 'es_AR' },
    { 'name': 'Spanish (SPA)', 'code': 'es_ES' },
    { 'name': 'Spanish (MEX)', 'code': 'es_MX' },
    { 'name': 'Swahili', 'code': 'sw' },
    { 'name': 'Swedish', 'code': 'sv' },
    { 'name': 'Tamil', 'code': 'ta' },
    { 'name': 'Telugu', 'code': 'te' },
    { 'name': 'Thai', 'code': 'th' },
    { 'name': 'Turkish', 'code': 'tr' },
    { 'name': 'Ukrainian', 'code': 'uk' },
    { 'name': 'Urdu', 'code': 'ur' },
    { 'name': 'Uzbek', 'code': 'uz' },
    { 'name': 'Vietnamese', 'code': 'vi' },
    { 'name': 'Zulu', 'code': 'zu' }
  ];

  @ViewChild('menuTrigger') trigger;

  constructor(public translate: TranslateService,
    private commService: CommsService,
    private snackbar: MatSnackBar
  ) {

  }

  getIcon(): string {
    if (this._comm == null) {
      return "email";
    }

    switch (this._comm.EmailType) {
      case 1:
      case 2:
      case 3:
      case 4:
      case 5:
      case 6:
      case 7:
      case 8:
      case 9:
      case 10:
      case 11:
      case 12:
      case 14:
      case 15:
      case 16:
      case 21:
      case 22:
      case 23:
      case 30:
        return "email";
      case 13:
      case 17:
        return "contact_support";
      case 18:
      case 19:
      case 20:
        return "sms";
      default:
        return "quick_phrases";
    }
  }

  isWhatsApp() {
    return this.getIcon() == "contact_support";
  }

  canEdit() {
    switch (this.template.template.status.toUpperCase()) {
      case "APPROVED":
      case "REJECTED":
      case "PAUSED":
      case "":
        return true;
      default:
        return false;
    }
  }

  channel(): string {
    if (this.isWhatsApp()) {
      return "whatsapp";
    }

    if (this.getIcon() != "sms") {
      return "web";
    }

    return "sms";
  }

  setupForms() {
    this.formGroup = new FormGroup({
      Name: new FormControl(this.comm.Name, [Validators.required]),
      Description: new FormControl(this.comm.Description, [Validators.required]),
      Subject: new FormControl(this.comm.Subject),
      EmailContent: new FormControl(this.comm.EmailContent, [Validators.required]),
      EmailType: new FormControl()
    });
    if (this.channel() == "web") {
      this.formGroup.controls.Subject.addValidators([Validators.required]);
    }

    this.formSendGroup = new FormGroup({
      Destination: new FormControl('', [Validators.required])
    });

    if (!this.isWhatsApp()) {
      if (this._comm?.EmailTemplateID == 0) {
        this.preview = 1;
      }

      return;
    }

    this.formWAGroup = new FormGroup({
      Name: new FormControl(this.comm.Name, [Validators.required]),
      Description: new FormControl(this.comm.Description, [Validators.required]),
      Language: new FormControl({ value: this.template.template.language, disabled: !this.canEdit() }),
      Status: new FormControl(this.template.template.status),
      Category: new FormControl(this.template.template.category),
      EmailContent: new FormControl(this.template.message),
      EmailType: new FormControl()
    });
    this.formWAGroup.controls.Name.valueChanges.subscribe(event => {
      this.formWAGroup.controls.Name.setValue(event.toLowerCase(), { emitEvent: false });
    });
    if (this._comm?.EmailTemplateID == 0) {
      this.preview = 1;
    }
  }

  isLocked() {
    if (this.channel() != "whatsapp") {
      return false;
    }

    let date = new Date().getTime() - new Date(this.comm.LastUpdatedDate).getTime();
    return date/1000/60/60 <= 24
  }

  getType(id: number): any {
    for (let i = 0; i < this.types.length; i++) {
      if (this.types[i].id == id) {
        return this.types[i];
      }
    }

    return null;
  }

  editComm(what: number) {
    switch (what) {
      case 1:
        break;
      case 2:
        this.closed?.emit(new CommsEvent("copy", this.comm));
        return;
      case 3:
        this.closed?.emit(new CommsEvent("delete", this.comm));
        return;
    }

    this.preview = what;
  }

  /*partUpdated(part: WhatsAppComponent) {
    if (this.template.components.length == 0) {
      this.template.components.push(part);
      return;
    }

    this.template.components[0] = part;
  }*/

  updateComm() {
    this.comm.Name = this.formGroup.controls.Name.value;
    this.comm.Description = this.formGroup.controls.Description.value;
    this.comm.Subject = this.formGroup.controls.Subject.value;
    this.comm.EmailContent = this.formGroup.controls.EmailContent.value;

    if (!this.isWhatsApp()) {
      return;
    }

    this.comm.Name = this.formWAGroup.controls.Name.value;
    this.comm.Description = this.formWAGroup.controls.Description.value;
    this.template.template.name = this.formWAGroup.controls.Name.value;
    this.template.template.language = this.formWAGroup.controls.Language.value;
    this.template.template.category = this.formWAGroup.controls.Category.value;
    this.template.message = this.formWAGroup.controls.EmailContent.value;
    if (this.template.template.category == "") {
      this.template.template.category = "MARKETING";
    }

    this.comm.EmailContent = JsonCyclic.toJson(this.template);
  }

  submitComm() {
    if (this.isSaving) {
      return;
    }

    if (this.isWhatsApp()) {
      if (this.formWAGroup.invalid) {
        return;
      }
    }
    else {
      if (this.formGroup.invalid) {
        return;
      }
    }

    this.isSaving = true;
    this.updateComm();
    this.commService.submitComm(this.comm).subscribe(
      result => {
        this.comm = result;
        this.openSnackbar("Successfully Submitted", "");
        this.isSaving = false;
        this.preview = 0;
        this.closed?.emit(new CommsEvent("saved", this.comm));
      },
      error => {
        this.openSnackbar("There was a problem submitted the settings", "Cancel");
        this.isSaving = false;
        this.preview = 0;
        this.closed?.emit(new CommsEvent("saved", this.comm));
      });
  }

  testComm() {
    if (this.isSaving) {
      return;
    }

    if (this.template.template.status != "APPROVED") {
      this.openSnackbar("This template is not approved", "Cancel");
      return;
    }

    this.commService.testComm(this.comm, this.formSendGroup.controls.Destination.value).subscribe(
      result => {
        this.openSnackbar("Successfully Sent Test", "");
        this.trigger.closeMenu();
      },
      error => {
        this.openSnackbar("There was a problem sending the test", "Cancel");
        this.trigger.closeMenu();
      }
    )
  }



  saveComm() {
    if (this.isSaving) {
      return;
    }

    if (this.isWhatsApp()) {
      if (this.formWAGroup.invalid) {
        return;
      }
    }
    else {
      if (this.formGroup.invalid) {
        return;
      }
    }

    this.isSaving = true;
    this.updateComm();
    this.commService.setComm(this.comm).subscribe(
      result => {
        this.comm = result;
        this.openSnackbar("Successfully Saved", "");
        this.isSaving = false;
        this.preview = 0;
        this.closed?.emit(new CommsEvent("saved", this.comm));
      },
      error => {
        this.openSnackbar("There was a problem saving the communication settings", "Cancel");
        this.isSaving = false;
        this.preview = 0;
        this.closed?.emit(new CommsEvent("saved", this.comm));
      });
  }

  cancel() {
    this.closed?.emit(new CommsEvent("closed", this.comm));
    this.preview = 0;
  }

  @Output()
  closed: EventEmitter<CommsEvent> = new EventEmitter<CommsEvent>();

  @Input()
  get comm(): CommsModel {
    return this._comm;
  }
  set comm(value: CommsModel) {
    this._comm = value;
    this.setupForms();
  }
  private _comm: CommsModel = null;

  get template(): WhatsAppFullTemplate | null {
    if (!this.isWhatsApp()) {
      return null;
    }

    if (this._template != null) {
      return this._template;
    }

    try {
      this._template = deserialize(WhatsAppFullTemplate, this.comm.EmailContent);
    }
    catch (e) {
      this._template = new WhatsAppFullTemplate();
    }

    return this._template;
  }
  private _template: WhatsAppFullTemplate | null = null;



  private openSnackbar(message: string, action: string) {
    if (action == "") {
      this.snackbar.open(message, action, { duration: 2000 });
    }
    else {
      this.snackbar.open(message, action, { duration: 7000 });
    }
  }
}

export class CommsEvent {
  public action: string;
  public comm: CommsModel;

  constructor(action: string, comm: CommsModel) {
    this.action = action;
    this.comm = comm;
  }
}

