import { FocusMonitor } from '@angular/cdk/a11y';
import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import {
  Component,
  ElementRef,
  Inject,
  Input,
  OnDestroy,
  Optional,
  Self,
  ViewChild,
} from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  FormBuilder,
  FormGroup,
  NgControl,
  Validators,
} from '@angular/forms';
import { MAT_FORM_FIELD, MatFormField, MatFormFieldControl } from '@angular/material/form-field';
import { Subject } from 'rxjs';


/** Data structure for holding credit card details. */
export class CreditCard {
  constructor(public part1: string, public part2: string, public part3: string, public part4: string, public month?: number, public year?: number, public cvc?: string) { }

  get cardNumber(): string {
    return this.part1 + this.part2 + this.part3 + this.part4;
  }

  get expiryDate(): Date {
    return new Date(this.year == undefined ? 1970 : this.year, this.month == undefined ? 1 : this.month, 1);
  }
}

/** Custom `MatFormFieldControl` for credit card input. */
@Component({
  selector: 'cc-input',
  templateUrl: 'cc-card.component.html',
  styleUrls: ['cc-card.component.css'],
  providers: [{ provide: MatFormFieldControl, useExisting: CcCardComponent }],
  host: {
    '[class.cc-floating]': 'shouldLabelFloat',
    '[id]': 'id',
  },
})
export class CcCardComponent implements ControlValueAccessor, MatFormFieldControl<CreditCard>, OnDestroy {
  static nextId = 0;
  @ViewChild('part1') part1Input: HTMLInputElement | undefined;
  @ViewChild('part2') part2Input: HTMLInputElement | undefined;
  @ViewChild('part3') part3Input: HTMLInputElement | undefined;
  @ViewChild('part4') part4Input: HTMLInputElement | undefined;
  @ViewChild('month') monthInput: HTMLInputElement | undefined;
  @ViewChild('year') yearInput: HTMLInputElement | undefined;
  @ViewChild('cvc') cvcInput: HTMLInputElement | undefined;

  parts: FormGroup;
  stateChanges = new Subject<void>();
  focused = false;
  touched = false;
  controlType = 'cc-input';
  id = `cc-input-${CcCardComponent.nextId++}`;
  onChange = (_: any) => { };
  onTouched = () => { };

  get empty() {
    const {
      value: { part1, part2, part3, part4, month, year, cvc },
    } = this.parts;

    return !part1 && !part2 && !part3 && !part4 && (month === null || month === 0) && (year === null || year === 0) && !cvc;
  }

  get shouldLabelFloat() {
    return this.focused || !this.empty;
  }

  @Input('aria-describedby') userAriaDescribedBy: string  = "";

  @Input()
  get placeholder(): string {
    return this._placeholder;
  }
  set placeholder(value: string) {
    this._placeholder = value;
    this.stateChanges.next();
  }
  private _placeholder: string = "";

  @Input()
  get required(): boolean {
    return this._required;
  }
  set required(value: BooleanInput) {
    this._required = coerceBooleanProperty(value);
    this.stateChanges.next();
  }
  private _required = false;

  @Input()
  get disabled(): boolean {
    return this._disabled;
  }
  set disabled(value: BooleanInput) {
    this._disabled = coerceBooleanProperty(value);
    this._disabled ? this.parts.disable() : this.parts.enable();
    this.stateChanges.next();
  }
  private _disabled = false;

  @Input()
  get value(): CreditCard | null {
    if (this.parts.valid) {
      const {
        value: { part1, part2, part3, part4, month, year, cvc },
      } = this.parts;
      return new CreditCard(part1, part2, part3, part4, month, year, cvc);
    }
    return null;
  }
  set value(card: CreditCard | null) {
    const { part1, part2, part3, part4, month, year, cvc } = card || new CreditCard('', '', '', '', undefined, undefined, '');
    this.parts.setValue({ part1, part2, part3, part4, month, year, cvc });
    this.stateChanges.next();
  }

  get errorState(): boolean {
    return this.parts.invalid && this.touched;
  }

  constructor(
    formBuilder: FormBuilder,
    private _focusMonitor: FocusMonitor,
    private _elementRef: ElementRef<HTMLElement>,
    @Optional() @Inject(MAT_FORM_FIELD) public _formField: MatFormField,
    @Optional() @Self() public ngControl: NgControl,
  ) {
    this.parts = formBuilder.group({
      part1: [null, [Validators.required, Validators.minLength(4), Validators.maxLength(4)]],
      part2: [null, [Validators.required, Validators.minLength(4), Validators.maxLength(4)]],
      part3: [null, [Validators.required, Validators.minLength(4), Validators.maxLength(4)]],
      part4: [null, [Validators.required, Validators.minLength(4), Validators.maxLength(4)]],
      month: [null, [Validators.required, Validators.minLength(2), Validators.maxLength(2)]],
      year: [null, [Validators.required, Validators.minLength(2), Validators.maxLength(2)]],
      cvc: [null, [Validators.required, Validators.minLength(3), Validators.maxLength(3)]]
    });

    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }
  }
    autofilled?: boolean | undefined;

  ngOnDestroy() {
    this.stateChanges.complete();
    this._focusMonitor.stopMonitoring(this._elementRef);
  }

  onFocusIn(event: FocusEvent) {
    if (!this.focused) {
      this.focused = true;
      this.stateChanges.next();
    }
  }

  onFocusOut(event: FocusEvent) {
    if (!this._elementRef.nativeElement.contains(event.relatedTarget as Element)) {
      this.touched = true;
      this.focused = false;
      this.onTouched();
      this.stateChanges.next();
    }
  }

  autoFocusNext(control: AbstractControl, nextElement?: HTMLInputElement): void {
    if (!control.errors && nextElement) {
      this._focusMonitor.focusVia(nextElement, 'program');
    }
  }

  autoFocusPrev(control: AbstractControl, prevElement?: HTMLInputElement): void {
    if (control.value.length < 1 && prevElement) {
      this._focusMonitor.focusVia(prevElement, 'program');
    }
  }

  setDescribedByIds(ids: string[]) {
    const controlElement = this._elementRef.nativeElement.querySelector(
      '.cc-input-container',
    )!;
    controlElement.setAttribute('aria-describedby', ids.join(' '));
  }

  onContainerClick() {
    if (this.parts.controls.cvc.valid && this.cvcInput != undefined) {
      this._focusMonitor.focusVia(this.cvcInput, 'program');
    } else if (this.parts.controls.year.valid && this.cvcInput != undefined) {
      this._focusMonitor.focusVia(this.cvcInput, 'program');
    } else if (this.parts.controls.month.valid && this.yearInput != undefined) {
      this._focusMonitor.focusVia(this.yearInput, 'program');
    } else if (this.parts.controls.part4.valid && this.monthInput != undefined) {
      this._focusMonitor.focusVia(this.monthInput, 'program');
    } else if (this.parts.controls.part3.valid && this.part4Input != undefined) {
      this._focusMonitor.focusVia(this.part4Input, 'program');
    } else if (this.parts.controls.part2.valid && this.part3Input != undefined) {
      this._focusMonitor.focusVia(this.part3Input, 'program');
    } else if (this.parts.controls.part1.valid && this.part2Input != undefined) {
      this._focusMonitor.focusVia(this.part2Input, 'program');
    } else if (this.part1Input != undefined) {
      this._focusMonitor.focusVia(this.part1Input, 'program');
    }
  }

  writeValue(card: CreditCard | null): void {
    this.value = card;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  _handleInput(control: AbstractControl, nextElement?: HTMLInputElement): void {
    this.autoFocusNext(control, nextElement);
    this.onChange(this.value);
  }
}
