<div class="rightFloat">
  <button mat-icon-button mat-dialog-close>
    <mat-icon>close</mat-icon>
  </button>
</div>
<div class="clearBoth"></div>
<div class="selectedQn-main-content">
  <mat-sidenav-container class="sidenav-container">
    <mat-sidenav-content>
      <div class="custom-scroll-bar selectedQn-sidenav-content">
        <div class="selectedQn-sidenav-div">
          <div class="fullWidth">
            <div #container class="row selectedQn-qnData-container" id="reportToConvert">
              <div *ngFor="let data of questionData; let i = index">
                <div class="selectedQn-chart-div">
                  <app-chart-item [question]="data"></app-chart-item>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </mat-sidenav-content>
  </mat-sidenav-container>
  <div>
    <button mat-icon-button (click)="exportToPDF()" matTooltip=" Download PDF " matTooltipPosition="above"><span><i class="fa fa-file-pdf-o selectedQn-downloadPDF-icon"></i></span></button>
    <br />
    <span>*Click above PDF icon to Export Results.</span>
  </div>
</div>

