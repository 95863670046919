import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { AnswerModel } from "../../models/AnswerModel";

@Component({
  selector: 'app-number',
  templateUrl: './number.component.html',
  styleUrls: ['./number.component.css']
})
export class NumberComponent implements OnInit {

  placeholder = "";
  mask = "";

  formGroup: FormGroup;

  constructor() {
    this.formGroup = new FormGroup({
      integer: new FormControl("", [Validators.required]),
    });
  }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    if (this._question.Response.Value === null) {
      return;
    }

    const value = this._question.Response.Value;
    this.formGroup.controls.integer.setValue(value);
    this.formGroup.controls.integer.clearValidators();
    if (this._question.MustAnswer) {
      this.formGroup.controls.integer.addValidators(Validators.required);
    }
  }

  @Input()
  get question(): any {
    return this._question;
  }
  set question(value: any) {
    this._question = value;
    if (this._question === undefined) {
      return;
    }

    if (this._question.Style.Placeholder !== "" && this._question.Style.Placeholder !== null) {
      this.placeholder = this._question.Style.Placeholder;
    }

    if (this._question.Style.Mask !== "" && this._question.Style.Mask !== null) {
      this.mask = this._question.Style.Mask;
    }
    else {
      this.mask = "0*";
    }
  }
  private _question: any;

  get answers(): AnswerModel[] {
    const values: AnswerModel[] = [];
    values.push(new AnswerModel(this._question.QuestionFullName, this.formGroup.controls.integer.value));
    return values;
  }

  get valid(): boolean {
    return true;
  }
}
