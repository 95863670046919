import { Component, OnInit } from '@angular/core';
import { BaseChart } from '../../question.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-matrix-table',
  templateUrl: './matrix-table.component.html',
  styleUrls: ['./matrix-table.component.css']
})
export class MatrixTableComponent extends BaseChart implements OnInit {

  displayedColumns: string[] = ['VariableName', 'Response'];

  constructor(public translate: TranslateService) {
    super();
  }

  ngOnInit(): void {
  }

}
