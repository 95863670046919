<div class="simple-row">
  <div class="wd-49-per">
    <mat-divider [style]="isBlock ? 'border-style:double' : ''">
    </mat-divider>
  </div>
  <div style="display:flex;flex-flow:row">
    <button mat-mini-fab [matTooltip]="toolTip" (click)="performAction()"><mat-icon>add</mat-icon></button>
    <button *ngIf="isBlock" mat-mini-fab color="accent" matTooltip="Delete Block" (click)="deleteAction()"><mat-icon>delete</mat-icon></button>&nbsp;
  </div>
  <div class="wd-49-per">
    <mat-divider [style]="isBlock ? 'border-style:double' : ''">
    </mat-divider>
  </div>
</div>
