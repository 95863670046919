import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-survey-details-layout',
  templateUrl: './survey-details-layout.component.html',
  styleUrls: ['./survey-details-layout.component.css']
})
export class SurveyDetailsLayoutComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
