import { Component } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, FormArray } from '@angular/forms';
import { MediaService } from '../../media.Service';
import { ImportUserSelection, ImportUserSelections, SelectionCriteria, UserProfile, UserService } from '../../user.Service';

@Component({
  selector: 'app-import-users',
  templateUrl: './import-users.component.html',
  styleUrls: ['./import-users.component.css']
})
export class ImportUsersComponent {
  formGroup: FormGroup;
  fb: FormBuilder = new FormBuilder();
  uploadFile: boolean = true;
  json: any;
  users: UserProfile[] = []
  filename: string;
  error: boolean = false;
  success: boolean = false;
  showImportButton: boolean = true;

  constructor(private mediaService: MediaService,
    private userService: UserService
  ) {

  }

  onFileUploaded($event: any) {
    this.filename = $event.name;
    this.mediaService.getJson($event.name).subscribe(
      result => {
        this.json = result;
        this.users = this.json.Users;
        this.formGroup = new FormGroup({
          AllUsers: new FormControl(false),
          Users: new FormArray([])
        });
        let userlist = this.formGroup.controls.Users as FormArray;
        for (let i = 0; i < this.users.length; i++) {
          let selections = [];
          if (this.getSecurityKeys(this.users[i].UserName).length > 0) {
            selections.push("keys");
          }
          if (this.getWebHooks(this.users[i].UserName).length > 0) {
            selections.push("webhooks");
          }
          if (this.getTemplates(this.users[i].UserName).length > 0) {
            selections.push("templates");
          }
          if (this.getOrganizations(this.users[i].UserName).length > 0) {
            selections.push("orgs");
          }
          if (this.getTinyUrls(this.users[i].UserName).length > 0) {
            selections.push("urls");
          }
          let form = new FormGroup({
            Selected: new FormControl(),
            Options: new FormControl(selections)
          });
          userlist.push(form);
        }

        this.uploadFile = false;
      }
    )
  }

  allSelected(): boolean {
    let userlist = this.formGroup.controls.Users as FormArray;
    let someselected = false;
    let somenotselected = false;
    for (let i = 0; i < this.users.length; i++) {
      if (this.isOrganization(this.users[i].UserName)) {
        continue;
      }

      let form = userlist.controls[i] as FormGroup;
      if (!form.controls.Selected.value) {
        somenotselected = true;
        continue;
      }

      someselected = true;
    }

    return someselected && !somenotselected;
  }

  patialSelection(): boolean {
    let userlist = this.formGroup.controls.Users as FormArray;
    let someselected = false;
    let somenotselected = false;
    for (let i = 0; i < this.users.length; i++) {
      if (this.isOrganization(this.users[i].UserName)) {
        continue;
      }

      let form = userlist.controls[i] as FormGroup;
      if (!form.controls.Selected.value) {
        somenotselected = true;
        continue;
      }

      someselected = true;
    }

    return someselected && somenotselected;
  }

  update(selected: boolean) {
    let userlist = this.formGroup.controls.Users as FormArray;
    for (let i = 0; i < this.users.length; i++) {
      if (this.isOrganization(this.users[i].UserName)) {
        continue;
      }

      let form = userlist.controls[i] as FormGroup;
      form.controls.Selected.setValue(selected);
    }
  }

  getSecurityKeys(username: string): any[] {
    return this.getArray(username, "key", this.json.SecurityKeys);
  }

  getTemplates(username: string): any[] {
    return this.getArray(username, "template", this.json.EmailTemplates);
  }

  getWebHooks(username: string): any[] {
    return this.getArray(username, "webhook", this.json.WebHooks);
  }

  getOrganizations(username: string): any[] {
    return this.getArray(username, "org", this.json.Organizations);
  }

  getTinyUrls(username: string): any[] {
    return this.getArray(username, "url", this.json.TinyUrls);
  }

  isOrganization(username: string): boolean {
    for (let i = 0; i < this.json.Organizations.length; i++) {
      if (this.json.Organizations[i].Organization.Name == username) {
        return true;
      }
    }

    return false;
  }

  getArray(username: string, type: string, originalList: any[]): any[] {
    let list: any[] = [];
    for (let i = 0; i < originalList.length; i++) {
      if (originalList[i].Username == username) {
        switch (type) {
          case "key":
            list.push(originalList[i].SecurityKey);
            break;
          case "webhook":
            list.push(originalList[i].WebHook);
            break;
          case "template":
            list.push(originalList[i].EmailTemplate);
            break;
          case "org":
            list.push(originalList[i].Organization);
            break;
          case "url":
            list.push(originalList[i].TinyUrl);
        }
      }
    }

    return list;
  }

  import() {
    this.showImportButton = false;
    let criteria = new ImportUserSelections();
    criteria.AllUsers = this.formGroup.controls.AllUsers.value == true;
    criteria.Criteria = new SelectionCriteria();
    let userlist = this.formGroup.controls.Users as FormArray;
    for (let i = 0; i < this.users.length; i++) {
      if (this.isOrganization(this.users[i].UserName)) {
        continue;
      }

      let form = userlist.controls[i] as FormGroup;
      if (!form.controls.Selected.value) {
        continue;
      }

      let userCriteria = new ImportUserSelection();
      userCriteria.Criteria = new SelectionCriteria();
      userCriteria.Username = this.users[i].UserName;
      let selections = form.controls.Options.value as [];
      for (let j = 0; j < selections.length; j++) {
        switch(selections[j]) {
          case "keys":
            userCriteria.Criteria.SecurityKeys = true;
            break;
          case "webhooks":
            userCriteria.Criteria.Webhooks = true;
            break;
          case "templates":
            userCriteria.Criteria.EmailTemplates = true;
            break;
          case "orgs":
            userCriteria.Criteria.Organizations = true;
            break;
          case "urls":
            userCriteria.Criteria.TinyUrls = true;
            break;
        }
      }

      criteria.Users.push(userCriteria);
    }

    this.userService.importUsers(this.filename, criteria).subscribe({
      next: result => {
        this.success = true;
      },
      error: err => {
        this.error = true;
      }
    });
  }
}
