<div class="user-details-dialog-container">
  <div class="leftFloat">
    <h3>Test on Other Devices</h3>
  </div>
  <div class="rightFloat">
    <button mat-icon-button mat-dialog-close>
      <mat-icon>close</mat-icon>
    </button>
  </div>
</div>
<div class="clearBoth"></div>
<div mat-dialog-content div class="column-flex align-center-start-flex runBy-qrCode-dialog-content">
  <div>
    Use the QR Code below to test the survey on other devices
  </div>
  <div *ngIf="surveyUrl != ''" class="fill-flex txt-align-center">
    <asw-qr-code [qrData]="surveyUrl"
                 [width]="180"
                 [height]="180"
                 logo="../../../assets/img/QrLogo.png"
                 [logoStyle]="{hideBackgroundCircle: true, logoSize: 0.3, logoMargin: 0}">    </asw-qr-code>
  </div>
  <div class="txt-align-center">
    <strong>Survey URLs</strong>
  </div>
  <div class="flow-flex">
    Or use this url for testing and add your own parameters to the end
  </div>
  <div class="flow-flex">
    <mat-form-field appearance="outline" class="fullWidth">
      <input matInput readonly [value]="surveyUrl">
      <mat-icon matSuffix matRipple [cdkCopyToClipboard]="surveyUrl" (cdkCopyToClipboardCopied)="copied($event)">file_copy</mat-icon>
    </mat-form-field>
  </div>
  <div class="flow-flex">
    or use our Tiny URL service and add your own parameters to the end.
  </div>
  <div class="flow-flex">
    <mat-form-field appearance="outline">
      <input matInput readonly [value]="tinyUrl">
      <mat-icon matSuffix matRipple [cdkCopyToClipboard]="tinyUrl" (cdkCopyToClipboardCopied)="copied($event)">file_copy</mat-icon>
    </mat-form-field>
  </div>

</div>
