<div *ngIf="surveySummary && showchart" class="dlvry-summ-container">
  <div class="wd-40-per">
    <mat-card>
      <mat-card-title>
        <div class="optionRow">
          <div class="margin-10">
            Launched: {{surveySummary.Launched |  date: 'short': '' : translate.currentLang}}
          </div>
          <div>
            <button [matMenuTriggerFor]="periodmenu" mat-stroked-button>
              <span>From: <strong>Beginning</strong></span>
              <mat-icon>arrow_drop_down</mat-icon>
            </button>
            <mat-menu #periodmenu="matMenu">
              <button mat-menu-item>
                <span>Last: <strong>Day</strong></span>
              </button>
              <button mat-menu-item>
                <span>Last: <strong>Week</strong></span>
              </button>
              <button mat-menu-item>
                <span>Last: <strong>Month</strong></span>
              </button>
            </mat-menu>
          </div>
        </div>
      </mat-card-title>
      <mat-card-subtitle class="margin-10">
        Invitation Summary ({{channelDescription()}})
      </mat-card-subtitle>
      <div class="dlvry-summ-hrChrt-container">
      <ngx-charts-bar-horizontal-stacked [animations]="false"
                                         [view]="view"
                                         [scheme]="colorScheme"
                                         [results]="results"
                                         [gradient]="gradient"
                                         [xAxis]="showXAxis"
                                         [yAxis]="showYAxis"
                                         [legend]="showLegend"
                                         [showXAxisLabel]="showXAxisLabel"
                                         [showYAxisLabel]="showYAxisLabel"
                                         [xAxisLabel]="xAxisLabel"
                                         [yAxisLabel]="yAxisLabel"
                                         (select)="onSelect($event)">
      </ngx-charts-bar-horizontal-stacked>
        </div>
    </mat-card>
  </div>
  <div class="dlvry-summ-statCards-container">
    <mat-card class="dlvry-summ-stat-card">
      <mat-card-title>
        {{surveySummary.PanellistCount}}
      </mat-card-title>
      {{inviteMessage}} Sent
    </mat-card>
    <mat-card class="dlvry-summ-stat-card">
      <mat-card-title>
        {{results[0].series[1].value}}
      </mat-card-title>
      {{inviteMessage}} Failed
    </mat-card>
    <mat-card *ngIf="isNotInvite()" class="dlvry-summ-stat-card">
      <mat-card-title>
        {{results[0].series[2].value}}
      </mat-card-title>
      Surveys Started
    </mat-card>
    <mat-card *ngIf="isNotInvite()" class="dlvry-summ-stat-card">
      <mat-card-title>
        {{results[0].series[3].value}}
      </mat-card-title>
      Surveys Completed
    </mat-card>
  </div>
  <div *ngIf="showchart">
    <ngx-charts-bar-vertical-stacked [animations]="false"
                                     [view]="deliveryView"
                                     [scheme]="colorScheme"
                                     [results]="dummyResults"
                                     [gradient]="gradient"
                                     [xAxis]="true"
                                     [yAxis]="showYAxis"
                                     [legend]="true"
                                     [showXAxisLabel]="showXAxisLabel"
                                     [showYAxisLabel]="showYAxisLabel"
                                     [xAxisLabel]="xAxisLabel"
                                     [yAxisLabel]="yAxisLabel"
                                     (select)="onSelect($event)">
    </ngx-charts-bar-vertical-stacked>
  </div>
</div>
