<div class="netwrk-dtl-container">
  <div class="netwrk-dtl-content">
    <form [formGroup]="formGroup">
      <div>
        <h2>Request a New Network Connection</h2>
      </div>
      <div class="alert alert-warning" role="alert">
        Remember, the use of SMS should follow our strict <a href="https://docs.diysurveys.com/sms-messaging-service-confirmation" target="_blank">SMS Usage Guidelines</a>
      </div>
      <div>
        <mat-form-field appearance="outline" class="fullWidth">
          <mat-label>Name</mat-label>
          <input formControlName="name" matInput placeholder="Name">
        </mat-form-field>
      </div>
      <div>
        <mat-form-field appearance="outline">
          <mat-label>Select Country</mat-label>
          <mat-select formControlName="country" (selectionChange)="countryChange()">
            <mat-option *ngFor="let net of networks;let i = index" [value]="net.NetworkId">{{net.Name}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div *ngIf="network.NetworkId > 0" [innerHTML]="network.Description">
      </div>
      <div>
        <mat-radio-group formControlName="type" (change)="twoWayChange()">
          <mat-radio-button [value]="1">1-Way SMS (Outbound, MT)</mat-radio-button>
          <mat-radio-button [value]="2">2-Way SMS (Out/Inbound, MT/MO)</mat-radio-button>
        </mat-radio-group>
      </div>
      <div *ngIf="network.NetworkId > 0 && numbers.length == 0">
        Currently there are no available numbers for this country and messaging capability. Please confirm the request and our Client Services Team will contact you to discuss your requirement.
      </div>
      <div *ngIf="numbers.length == 1">
        <h3>{{numbers[0].Number}}</h3>
      </div>
      <div *ngIf="numbers.length > 1">
        <mat-form-field appearance="outline">
          <mat-label>Available Number</mat-label>
          <mat-select formControlName="number" (selectionChange)="numberChange()">
            <mat-option *ngFor="let num of numbers; let i = index" [value]="num.NumberId">{{num.Number}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div *ngIf="!formGroup.invalid && number.NumberId != 0">
      <div *ngIf="!number.WhitelistingRequired && !number.RegistrationRequired">
        <h3>{{number.TwoWay == true ? '2-Way SMS (Out/Inbound, MT/MO)' : '1-Way SMS (Outbound, MT)'}}</h3>
        {{number.Description}}
        <div>
          <div class="column-flex-flow fullWidth">
            <div class="row-flex-flow fullWidth">
              <div class="halfWidth">Concatenation:</div>
              <div>{{number.Concatenation ? "Supported" : "Not Supported"}}</div>
            </div>
            <div class="row-flex-flow">
              <div class="halfWidth">GSM Standard Encoding:</div>
              <div>{{number.GsmStandard ? "Supported" : "Not Supported"}}</div>
            </div>
            <div class="row-flex-flow">
              <div class="halfWidth">GSM Extended Encoding:</div>
              <div>{{number.GsmExtended ? "Supported" : "Not Supported"}}</div>
            </div>
            <div class="row-flex-flow">
              <div class="halfWidth">Unicode Encoding:</div>
              <div>{{number.Unicode ? "Supported" : "Not Supported"}}</div>
            </div>
            <div class="row-flex-flow">
              <div class="halfWidth">Brandable:</div>
              <div>{{number.Brandable ? "Supported" : "Not Supported"}}</div>
            </div>
            <div>&nbsp;</div>
            <div class="row-flex-flow">
              <div class="halfWidth">Whitelisting:</div>
              <div>{{number.WhitelistingRequired ? "Required" : "Not Required"}}</div>
            </div>
            <div class="row-flex-flow">
              <div class="halfWidth">Registration:</div>
              <div>{{number.RegistrationRequired ? "Required" : "Not Required"}}</div>
            </div>
          </div>
        </div>
        <div>
        </div>
      </div>
      <div>
        <mat-stepper  *ngIf="number.WhitelistingRequired || number.RegistrationRequired" orientation="vertical">
          <mat-step>
            <ng-template matStepLabel>Message Capabilities</ng-template>
            <div>
              <h3>{{number.TwoWay == true ? '2-Way SMS (Out/Inbound, MT/MO)' : '1-Way SMS (Outbound, MT)'}}</h3>
              {{number.Description}}
            </div>
            <div class="column-flex-flow fullWidth">
              <div class="row-flex-flow fullWidth">
                <div class="halfWidth">Concatenation:</div>
                <div>{{number.Concatenation ? "Supported" : "Not Supported"}}</div>
              </div>
              <div class="row-flex-flow">
                <div class=" halfWidth">GSM Standard Encoding:</div>
                <div>{{number.GsmStandard ? "Supported" : "Not Supported"}}</div>
              </div>
              <div class="row-flex-flow">
                <div class=" halfWidth">GSM Extended Encoding:</div>
                <div>{{number.GsmExtended ? "Supported" : "Not Supported"}}</div>
              </div>
              <div class="row-flex-flow">
                <div class=" halfWidth">Unicode Encoding:</div>
                <div>{{number.Unicode ? "Supported" : "Not Supported"}}</div>
              </div>
              <div class="row-flex-flow">
                <div class=" halfWidth">Brandable:</div>
                <div>{{number.Brandable ? "Supported" : "Not Supported"}}</div>
              </div>
              <div>&nbsp;</div>
              <div class="row-flex-flow">
                <div class=" halfWidth">Whitelisting:</div>
                <div>{{number.WhitelistingRequired ? "Required" : "Not Required"}}</div>
              </div>
              <div class="row-flex-flow">
                <div class=" halfWidth">Registration:</div>
                <div>{{number.RegistrationRequired ? "Required" : "Not Required"}}</div>
              </div>
            </div>
          </mat-step>
          <mat-step>
            <ng-template matStepLabel>Additional Information Required</ng-template>
            <div *ngIf="number.WhitelistingRequired">
              <div>
                &nbsp;
              </div>
              <div *ngIf="number.WhitelistingBranding">
                <app-message-editor name="Branding" [text]="request.Branding" [multiLine]="false" (onChange)="store($event, 'branding')"></app-message-editor>
              </div>
              <div *ngIf="number.WhitelistingBranding">
                &nbsp;
              </div>
              <div *ngIf="number.WhitelistingInvite">
                <app-message-editor name="Invite Message" [text]="request.InviteMessage" [multiLine]="true" (onChange)="store($event, 'invite')"></app-message-editor>
              </div>
              <div *ngIf="number.WhitelistingInvite">
                &nbsp;
              </div>
              <div *ngIf="number.WhitelistingReminder">
                <app-message-editor name="Reminder Message" [text]="request.ReminderMessage" [multiLine]="true" (onChange)="store($event, 'reminder')"></app-message-editor>
              </div>
              <div *ngIf="number.WhitelistingReminder">
                &nbsp;
              </div>
              <div *ngIf="number.WhitelistingSurveyLink">
                <app-message-editor name="Survey Link Format" [text]="request.SurveyLinkFormat" [multiLine]="true" (onChange)="store($event, 'survey')"></app-message-editor>
              </div>
              <div *ngIf="number.WhitelistingSurveyLink">
                &nbsp;
              </div>
              <div *ngIf="number.WhitelistingOptOut">
                <app-message-editor name="Opt-Out Link" [text]="request.OptOutLink" [multiLine]="true" (onChange)="store($event, 'optout')"></app-message-editor>
              </div>
              <div *ngIf="number.WhitelistingOptOut">
                &nbsp;
              </div>
              <div *ngIf="number.WhitelistingTinyUrl">
                <app-message-editor name="TinyURL Example" [text]="request.TinyUrlExample" [multiLine]="false" (onChange)="store($event, 'tiny')"></app-message-editor>
              </div>
            </div>
            <div>
              &nbsp;
            </div>
            <div *ngIf="number.RegistrationRequired">
              <div class="alert alert-warning" role="alert">
                Further registration information may be required to support this network connection. We will email you this when you confirm the request.
              </div>
            </div>
          </mat-step>
        </mat-stepper>
        </div>
      </div>
      <div class="margin-top-bot-40">
        <div *ngIf="isAdminUser">
          <div>
            <h3>Administrator Facilities:</h3>
          </div>
          <div>
            Notes:
          </div>
          <diy-editor channel="web" formControlName="notes"></diy-editor>
          <div>
            <mat-form-field appearance="outline">
              <mat-label>Status</mat-label>
              <mat-select formControlName="status">
                <mat-option value="0">Requested</mat-option>
                <mat-option value="1">Started</mat-option>
                <mat-option value="2">Completed</mat-option>
                <mat-option value="3">Problem</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
        <div *ngIf="!isAdminUser && request.Notes != ''">
          <div>
            Notes:
          </div>
          <div>
            {{request.Notes}}
          </div>
          <div>
            Status: {{status()}}
          </div>
        </div>
        <div>
          <button mat-raised-button color="primary" (click)="onSubmit()" type="submit" [disabled]="formGroup.invalid">{{request.RequestId == 0 ? 'Confirm' : 'Update'}}</button>&nbsp;&nbsp;
          <button mat-raised-button color="accent" (click)="cancel()">Cancel</button>
        </div>
      </div>
    </form>
  </div>
</div>
