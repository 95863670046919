<div *ngIf="ready" [formGroup]="form">
  <div formArrayName="items">
    <div *ngFor="let item of itemArr.controls; let i = index" class="cat-selector-catBtn-div" [formGroupName]="i">
      <category-button formControlName="cat" (click)="onClick($event, itemFormGroup(i).controls.name.value)">{{itemFormGroup(i).controls.name.value}}</category-button>
    </div>
  </div>
  <br />
  <div *ngIf="ready && itemArr.controls.length > 0" class="cat-selector-btn-container">
    <div class="cat-selector-select-div">
      <button mat-flat-button color="primary" (click)="selectAll($event)">Select All</button>
    </div>
    <div class="cat-selector-select-div">
      <button mat-flat-button color="primary" (click)="deselectAll($event)">Deselect All</button>
    </div>
  </div>
</div>
