import { Component, EventEmitter, Input, Output, TemplateRef, ViewChild } from '@angular/core';
import { MatButton } from '@angular/material/button';
import { MatCheckbox, MatCheckboxChange } from '@angular/material/checkbox';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-enhanced-list',
  templateUrl: './enhanced-list.component.html',
  styleUrl: './enhanced-list.component.css'
})
export class EnhancedListComponent {

  dataSource = new MatTableDataSource<any>(this.data);
  selections: boolean[] = [];
  searchKey: string = "";

  @ViewChild('paginator') paginator: MatPaginator | undefined;

  @Input()
  get columnsToDisplay(): string[] {
    return this._columnsToDisplay;
  }
  private _columnsToDisplay = ['select', 'body']

  @Input()
  get data(): any {
    return this._data;
  }
  set data(value: any) {
    this._data = value;
    this.selections = [];
    for (let i = 0; i < this._data.length; i++) {
      this.selections.push(false);
    }
  }
  private _data: any;

  @Input()
  get total(): number {
    return this._total;
  }
  set total(value: number) {
    this._total = value;
  }
  private _total: number = 0;

  @Input()
  get pageSize(): number {
    return this._pageSize;
  }
  private _pageSize: number = 20;
  set pageSize(value: number) {
    this._pageSize = value;
  }

  @Input()
  get template(): TemplateRef<any> {
    return this._template;
  }
  set template(value: TemplateRef<any>) {
    this._template = value;
  }
  private _template: TemplateRef<any>;

  @Input()
  get addButton(): string {
    return this._addButton;
  }
  set addButton(value: string) {
    this._addButton = value;
  }
  private _addButton: string = '';

  @Input()
  get filter(): string[] {
    return this._filter;
  }
  set filter(value: string[]) {
    this._filter = value;
  }
  private _filter: string[] = [];

  @Input()
  get actions(): string[] {
    return this._actions;
  }
  set actions(value: string[]) {
    this._actions = value;
  }
  private _actions: string[];

  @Input()
  get border(): boolean {
    return this._border;
  }
  set border(value: boolean) {
    this._border = value;
  }
  private _border = true;

  refresh() {
    this.dataSource = new MatTableDataSource<any>(this.data); 
  }

  @Output()
  doAction = new EventEmitter<ActionEvent>();

  @Output()
  loaded = new EventEmitter();

  onFilterMenu(filterValue: string) {
    this.doAction.emit(new ActionEvent("filter", [filterValue]));
  }

  add() {
    this.doAction.emit(new ActionEvent("add", []));
  }

  edit(element: any) {
    this.doAction.emit(new ActionEvent("edit", [element]));
  }

  doActionForMultiple(action: string) {
    this.doAction.emit(new ActionEvent(action, this.getElements()));
  }

  getElements() {
    let elements = [];
    for (let i = 0; i < this.selections.length; i++) {
      if (this.selections[i]) {
        elements.push(this.data[i]);
      }
    }

    return elements;
  }

  onSearchClear(event: Event) {
    this.searchKey = "";
    this.dataSource.filter = this.searchKey;
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    if (filterValue === "") {
      return;
    }

    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  checkRow(index: number) {
    this.selections[index] = !this.selections[index];
  }

  checkTotal($event: MatCheckboxChange) {
    this.setSelections($event.checked);
  }

  setSelections(value: boolean) {
    for (let i = 0; i < this.selections.length; i++) {
      this.selections[i] = value;
    }
  }

  getTotal(): number {
    let t = 0;
    for (let i = 0; i < this.selections.length; i++) {
      if (this.selections[i]) {
        t++;
      }
    }

    return t;
  }

  pageEvents(event: PageEvent) {
    window.scrollTo(0, 0);
  }

}

export class ActionEvent {
  action: string;
  data: any[];
  constructor(_action: string, _data: any[]) {
    this.action = _action;
    this.data = _data;
  }
}
