import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-privacy-statement-layout',
  templateUrl: './privacy-statement-layout.component.html',
  styleUrls: ['./privacy-statement-layout.component.css']
})
export class PrivacyStatementLayoutComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
