<div class="rightFloat">
  <button mat-icon-button mat-dialog-close>
    <mat-icon>close</mat-icon>
  </button>
</div>
<div class="clearBoth"></div>
<div class="main-div display-flex align-center-center-flex fill-flex">
  <form class="column-flex align-center-center-flex" [formGroup]="formGroup">
    <h2>Edit Your Organization Details</h2>
    <div *ngIf="displayError">
      <div class="alert alert-danger" role="alert">
        {{errorMsg}}
      </div>
    </div>
    <div class="controls-container">
      <div class="wa-main-content">
        <mat-form-field appearance="outline">
          <mat-label>Organization Title</mat-label>
          <input formControlName="OrgName" matInput placeholder="Organization Name *">
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>Description</mat-label>
          <textarea formControlName="OrgDescription" matInput placeholder="Description"></textarea>
        </mat-form-field>
      </div>
    </div>
    <button mat-raised-button color="primary" (click)="onSubmit()" type="submit" [disabled]="formGroup.invalid">Save</button>
  </form>
</div>
