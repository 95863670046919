import { Component, Inject, Input, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PanelModel, PanelService } from '../../panel.Service';
import { SurveyModel, SurveyService } from '../../survey.Service';
import { PanelsComponent } from '../panels.component';

@Component({
  selector: 'app-panel-dialog',
  templateUrl: './panel-dialog.component.html',
  styleUrls: ['./panel-dialog.component.css']
})
export class PanelDialogComponent implements OnInit {
  panel: PanelModel = new PanelModel();
  survey: SurveyModel;

  @ViewChild('panelSelector') panelSelector: PanelsComponent | undefined;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<PanelDialogComponent>,
    private surveyService: SurveyService,
    private panelService: PanelService
  ) {
    this.survey = data.survey;
  }

  ngOnInit(): void {
  }

  panelSelected(panel: PanelModel) {
    this.panel = panel;
    this.survey.PanelID = panel.PanelID;
    this.surveyService.setPanel(this.survey.SurveyID, this.survey.PanelID);
    this.dialogRef.close({ data: { panel: panel } });
  }
}
