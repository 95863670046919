import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DistributionModel, SurveyService } from '../../survey.Service';

@Component({
  selector: 'app-run-by-qrcode',
  templateUrl: './run-by-qrcode.component.html',
  styleUrls: ['./run-by-qrcode.component.css']
})
export class RunByQrcodeComponent implements OnInit {

  surveyUrl: string = "";
  tinyUrl: string = "";
  model: DistributionModel = new DistributionModel();

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    private surveyService: SurveyService,
    private snackbar: MatSnackBar) {
    this.surveyService.getSurveyTesetLink(data.SurveyID).subscribe(result => {
      this.model = result;
      this.surveyUrl = this.model.Sid;
      this.tinyUrl = this.model.TinyUrl;
    });
  }

  ngOnInit(): void {
  }

  copied($event: any) {
    this.openSnackbar("Copied to clipboard!", "");
  }

  private openSnackbar(message: string, action: string) {
    if (action == "") {
      this.snackbar.open(message, action, { duration: 2000 });
    }
    else {
      this.snackbar.open(message, action, { duration: 7000 });
    }
  }
}
