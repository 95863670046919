<app-toolbar></app-toolbar>
<div class="forgot-pwd-main-div">
  <mat-card class="forgot-pwd-card">
    <div>
      <h2 class="txt-align-center">Join an Organization</h2>
    </div>
    <div *ngIf="displayError === 1 || displayError === 99">
      <div class="alert alert-danger" role="alert">
        {{errorMsg}}
      </div>
    </div>
    <div *ngIf="displayError === 97">
      <div class="alert alert-info" role="alert">
        {{errorMsg}}
      </div>
    </div>
    <div *ngIf="displayError === 98">
      <div class="alert alert-info" role="alert">
        {{errorMsg}} Please <a [routerLink]="'/signup'">Click Here</a> to signup.
      </div>
    </div>
    <div *ngIf="displayError === 0">
      <div class="alert alert-info" role="alert">
        You have successfully joined the Organization. Please <a [routerLink]="'/surveydashboard'">Click Here</a> to login.
      </div>
    </div>
  </mat-card>
</div>
