import { Component, EventEmitter, OnInit, Input, Output } from '@angular/core';
import { baseUrl, options } from "../../environments/environment";
import { HttpEventType, HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.css']
})
export class FileUploadComponent implements OnInit {
  originalName: string = "";

  public progress: number = 0;
  public message: string = "";

  constructor(private http: HttpClient) { }

  ngOnInit() {
  }

  public uploadFile = (files: any) => {
    if (files.length === 0) {
      return;
    }

    let fileToUpload = <File>files[0];
    const formData = new FormData();
    formData.append('file', fileToUpload, fileToUpload.name);

    this.http.post(baseUrl + 'media/upload', formData, {reportProgress: true, observe: 'events'})
      .subscribe(event => {
        if (event.type === HttpEventType.UploadProgress)
          this.progress = Math.round(100 * event.loaded / (event.total ?? 100));
        else if (event.type === HttpEventType.Response) {
          this.message = 'Upload success.';
          let body = (event.body) as any;
          this._name = body.Name;
          this.originalName = body.Original;
          this.onUploaded();
        }
      });
  }

  @Input()
  get accept(): string {
    return this._accept;
  }
  set accept(value: string) {
    this._accept = value;
  }
  private _accept = "";

  @Input()
  get name(): any {
    return this._name;
  }
  set name(value: any) {
    this._name = value;
  }
  private _name: any;

  @Input()
  get text(): string {
    return this._text;
  }
  set text(value: string) {
    this._text = value;
  }
  private _text = "Upload File";

  @Output()
  uploaded: EventEmitter<this> = new EventEmitter<this>();

  registerOnUploaded(fn: any): void {
    this.onUploaded = fn;;
  }

  onUploaded() {
    this.uploaded.emit(this);
  }
}
