<div *ngIf="navigation">
  <span *ngIf="getNavigation('prev') as n">
    <button type="button" appNoDblClickMat *ngIf="n.Style.Hidden === false && !isNavColor" mat-flat-button color="primary" (debounceClick)="onNavigate(n.Type)">
      {{n.Label.Text}}
    </button>
    <button type="button" appNoDblClickMat *ngIf="n.Style.Hidden === false && isNavColor" mat-flat-button [style.color]="navTxtColor" [style.background-color]="color" (debounceClick)="onNavigate(n.Type)">
      {{n.Label.Text}}
    </button>
  </span>
  <span *ngIf="getNavigation('next') as n">
    &nbsp;
    <button type="button" appNoDblClickMat *ngIf="n.Style.Hidden === false && !isNavColor" mat-flat-button color="primary" (debounceClick)="onNavigate(n.Type)">
      {{n.Label.Text}}
    </button>
    <button type="button" appNoDblClickMat *ngIf="n.Style.Hidden === false && isNavColor" mat-flat-button [style.color]="navTxtColor" [style.background-color]="color" (debounceClick)="onNavigate(n.Type)">
      {{n.Label.Text}}
    </button>
  </span>
  <span *ngIf="getNavigation('stop') as n">
    &nbsp;
    <button type="button" appNoDblClickMat *ngIf="n.Style.Hidden === false && !isNavColor" mat-flat-button color="primary" (debounceClick)="onNavigate(n.Type)">
      {{n.Label.Text}}
    </button>
    <button type="button" appNoDblClickMat *ngIf="n.Style.Hidden === false && isNavColor" mat-flat-button [style.color]="navTxtColor" [style.background-color]="color" (debounceClick)="onNavigate(n.Type)">
      {{n.Label.Text}}
    </button>
  </span>
</div>
