import { Component, OnInit } from '@angular/core';
import { FormGroup, FormArray } from '@angular/forms';
import { BaseQuestion, DataModel, InterviewService, QuestionService } from '../../question.service';

@Component({
  selector: 'qe-app-date',
  templateUrl: './qe-date.component.html',
  styleUrls: ['./qe-date.component.css']
})
export class QeDateComponent extends BaseQuestion implements OnInit {
  constructor(private qs: QuestionService, private is: InterviewService) {
    super(qs, is, undefined, undefined, new DataModel("date"));
  }

  public dateFormGroup: FormGroup = new FormGroup({});

  ngOnInit() {
    this.dateFormGroup = this.fb.group({
      dateFormArray: this.fb.array([this.initQuestion()])
    });
  }

  get questionArr(): FormArray | null {
    if (this.dateFormGroup) {
      return this.dateFormGroup.get('dateFormArray') as FormArray;
    }

    return null;
  }

  onSubmit(): void {
  }
}
