<div class="fullWidth dialog-title">
  <div class="leftFloat">
    <h3>Import Users</h3>
  </div>
  <div class="rightFloat">
    <button mat-icon-button matDialogClose="no" matTooltip="Close" matTooltipPosition="below">
      <mat-icon class="dialog-close-icon">close</mat-icon>
    </button>
  </div>
</div>
@if (uploadFile) {
<div style="margin-top:5px">
  <app-file-upload #fileUpload accept=".*" text="Import Users" (uploaded)="onFileUploaded($event)"></app-file-upload>
</div>
}
@else {
<div class="clearBoth margin-5 padding-lr-10">
  Select which users and what elements you want to import
  <div class="alert alert-warning" role="alert">
    Organizations are not listed. If there are any security, tinyurls or webhooks associated with them they will be imported based on the selections made for the user that owns them.
  </div>
</div>
<div [formGroup]="formGroup">
  <mat-checkbox [checked]="allSelected()" [indeterminate]="patialSelection()" (change)="update($event.checked)">All Users</mat-checkbox>
  <ul formArrayName="Users">
    @for (user of users; track user; let i = $index) {
    <li *ngIf="!isOrganization(user.UserName)" formGroupName="{{i}}">
      <mat-checkbox formControlName="Selected"><strong>{{user.UserName}}</strong> {{user.FirstName}} {{user.LastName}}</mat-checkbox>&nbsp;&nbsp;
      <mat-button-toggle-group formControlName="Options" multiple>
        <mat-button-toggle value="keys">{{getSecurityKeys(user.UserName).length}} Security Key(s)</mat-button-toggle>
        <mat-button-toggle value="webhooks">{{getWebHooks(user.UserName).length}} Web Hook(s)</mat-button-toggle>
        <mat-button-toggle value="templates">{{getTemplates(user.UserName).length}} Communication Template(s)</mat-button-toggle>
        <mat-button-toggle value="orgs">{{getOrganizations(user.UserName).length}} Organization(s)</mat-button-toggle>
        <mat-button-toggle value="urls">{{getTinyUrls(user.UserName).length}} Tiny Url(s)</mat-button-toggle>
      </mat-button-toggle-group>
    </li>
    }
  </ul>
  @if (showImportButton) {
  <button *ngIf="showImportButton" mat-raised-button color="primary" (click)="import()">Import</button>
  }
  @else {
  <div *ngIf="!error && !success">
    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
  </div>
  }
  <div *ngIf="error" class="alert alert-danger" role="alert">
    There was a problem importing the users. Please contact your support team.
  </div>
  <div *ngIf="success" class="alert alert-success" role="alert">
    The users selected were successfully imports. Please check the accounts to be sure you got what you wanted.
  </div>
</div>
}
