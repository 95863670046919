import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { JwtHelperService } from '@auth0/angular-jwt';
import { ActivatedRoute, Router } from "@angular/router";
import { Observable } from 'rxjs';
import { UserProfile } from "./user.Service";
import { baseUrl, options, version, environment } from "../environments/environment";
import { plainToClass } from 'class-transformer';
import { SpeedDefinition } from './network.Service';

export class SecurityKey {
  KeyId: number = 0;
  Name: string = "";
  KeyString: string = "";
  IsDeleted: boolean = false;
  Suspended: boolean = false;
  Created: Date = new Date();
  Amended: Date = new Date();
  Expires: Date = new Date();
  Owner: UserProfile = new UserProfile();
  AppId: string = "";
  MetadataId: number = 0;
  MessageWindowId: number = 0;
  MetadataList: Metadata[] = [];
  Windows: WindowModel[] = [];
  OwnerId: number = 0;
  DefaultTinyUrlId: number = 0;
  SpeedCharacteristics: string = "";
  Speed: SpeedDefinition = new SpeedDefinition();

  public GetMessageWindows(): MessageWindow[] {
    const windows = [];
    for (let i = 0; i < this.Windows.length; i++) {
      this.Windows[i] = plainToClass(WindowModel, this.Windows[i])
      windows.push(this.Windows[i].GetMessageWindow());
    }

    return windows;
  }

  public SetMessageWindows(windows: MessageWindow[]) {
    this.Windows = [];
    for (let i = 0; i < windows.length; i++) {
      if (windows[i].From.getTime() !== windows[i].To.getTime()) {
        this.Windows.push(new WindowModel(windows[i]));
      }
    }
  }
}

export class WindowModel {
  WindowsId: number;
  Sequence: number;
  Days: number;
  FromTime: Date = new Date();
  ToTime: Date = new Date();
  Created: Date = new Date();

  public constructor(window?: MessageWindow) {
    this.WindowsId = 0;
    this.Sequence = 0;
    this.Days = 0;
    if (window === undefined) {
      return;
    }

    for (let i = 0; i < window.Days.length; i++) {
      switch (window.Days[i]) {
        case "sun":
          this.Days += 1;
          break;
        case "mon":
          this.Days += 2;
          break;
        case "tue":
          this.Days += 4;
          break;
        case "wed":
          this.Days += 8;
          break;
        case "thu":
          this.Days += 16;
          break;
        case "fri":
          this.Days += 32;
          break;
        case "sat":
          this.Days += 64;
          break;
      }
    }

    this.FromTime = window.From;
    this.ToTime = window.To;
  }

  public GetMessageWindow(): MessageWindow {
    const window = new MessageWindow();
    window.From = this.FromTime;
    window.To = this.ToTime;
    if ((this.Days & 1) === 1) {
      window.Days.push("sun");
    }
    if ((this.Days & 2) === 2) {
      window.Days.push("mon");
    }
    if ((this.Days & 4) === 4) {
      window.Days.push("tue");
    }
    if ((this.Days & 8) === 8) {
      window.Days.push("wed");
    }
    if ((this.Days & 16) === 16) {
      window.Days.push("thu");
    }
    if ((this.Days & 32) === 32) {
      window.Days.push("fri");
    }
    if ((this.Days & 64) === 64) {
      window.Days.push("sat");
    }

    return window;
  }
}


export class MessageWindow {
  From: Date;
  To: Date;
  Days: string[];

  constructor() {
    this.From = new Date();
    this.From.setHours(0, 0, 0, 0);
    this.To = new Date();
    this.To.setHours(0, 0, 0, 0);
    this.Days = [];
  }
}

export class SocialLogin {
  UserID: string = "";
  FirstName: string = "";
  LastName: string = "";
  EmailAddress: string = "";
  PictureUrl: string = "";
  Provider: string = "";
}

export class Metadata {
  MetaId: number = 0;
  Sequence: number = 0;
  Name: string = "";
  Value: string = "";
  DataType: number = 0;
  Created: Date = new Date();
}

export class Organization {
  OrganizationId: number = 0;
  Name: string = "";
  Description: string = "";
  OwnerId: number = 0;
  Created: Date = new Date();
  Amended: Date = new Date();
  Owner: UserProfile = new UserProfile();
  Roles: OrgRole[] = [];
  RoleId: number = 0;
  Members: UserProfile[] = [];
  Invitations: SystemInvitation[] = [];
  MemberId: number = 0;
  MediaId: number = 0;
}

export class OrgRole {
  OrganizationId: number = 0;
  UserId: number = 0;
  RoleId: number = 0;
}

export class RegistrationModel {
  firstname: string = "";
  lastname: string = "";
  username: string = "";
  email: string = "";
  password: string = "";
  tandc: boolean = false;
}

export class ResetPasswordModel {
  NewPassword: string;
  ConfirmPassword: string;
  constructor(password: string) {
    this.NewPassword = password;
    this.ConfirmPassword = password;
  }
}

export class ChangePasswordModel {
  OldPassword: string;
  NewPassword: string;
  constructor(oldPassword: string, newPassword: string) {
    this.OldPassword = oldPassword;
    this.NewPassword = newPassword;
  }
}

export class UserInvite {
  UserID: number;
  UserName: string;
  Role: number;
  constructor(public userId: number, public username: string, role: number) {
    this.UserID = userId;
    this.UserName = username;
    this.Role = role;
  }
}

export class Transaction {
  TranId: number = 0;
  TranDate: Date = new Date();
  Created: Date = new Date();
  Amended: Date = new Date();
  OwnerId: number = 0;
  AdministratorId: number = 0;
  TypeId: number = 0;
  Description: string = "";
  Unit: number = 0;
  YourRef: string = "";
  Title: string = "";
  Confirmed: boolean = false;
  VatRate: number = 0;
  Net: number = 0;
  Value: number = 0;
  UnitText: string = "";
  Reference: number = 0;

  constructor() {}
}

export class RedirectToken {
  url: string = "";
  id: string = "";
  token: string = "";
  constructor(public router: Router) {
  }

  public Redirect(defaultUrl: string) {
    if (this.url && this.id && this.token) {
      this.router.navigate([defaultUrl, this.url, this.id, this.token]);
      return;
    }

    if (this.url && this.id) {
      this.router.navigate([defaultUrl, this.url, this.id]);
      return;
    }

    if (this.url && this.token) {
      this.router.navigate([defaultUrl, this.url, this.token]);
      return;
    }

    this.router.navigate([defaultUrl, this.url]);
  }
}

export class OrganizationModel {
  Name: string;
  Description: string;
  MediaId: number;
  InviteList: UserInvite[];
  constructor(public name: string, public description: string, public invites: UserInvite[], public mediaId: number) {
    this.Name = name;
    this.Description = description;
    this.InviteList = invites;
    this.MediaId = mediaId;
  }
}

export class UserOrgModel {
  Username: string = "";
  OrganizationId: number = 0;
  constructor(public username: string, public organizationId: number) {
    this.Username = username;
    this.OrganizationId = organizationId;
  }
}

export class SystemInvitation {
  InvitationId: number = 0;
  UserId: number = 0;
  ReferenceId: number = 0;
  InvitationTypeId: number = 0;
  EmailAddress: string = "";
  InviteVerificationToken: string = "";
  StatusId: number = 0;
  Created: Date = new Date();
  Amended: Date = new Date();
  OwnerId: number = 0;
  User: UserProfile = new UserProfile();
}

export class CreditCard {
  Name: string = "";
  Number: string = "";
  Expires: Date = new Date();
  Cvc: string = "";
}

export class Group {
  GroupId: number = 0;
  Name: string = "";
  Description: string = "";
  IsDeleted: boolean = false;
  Created: Date = new Date();
  Amended: Date = new Date();
  TypeId: number = 1;
}

export class Policy {
  PolicyId: number = 0;
  GroupId: number = 0;
  Name: string = "";
  Description: string = "";
  TypeId: number = 0;
  Settings: string = "";
  IsDeleted: boolean = false;
  Created: Date = new Date();
  Amended: Date = new Date();
}

export class PolicySettings {
  Switch: boolean = false;
  From: number = 0;
  To: number = 0;
  Number: number = 0;
  Create: boolean = false;
  View: boolean = false;
  Edit: boolean = false;
  Delete: boolean = false;
}

export class GroupPolicy {
  GroupPolicyId: number = 0;
  GroupId: number = 0;
  PolicyId: number = 0;
  Settings: string = "";
  IsDeleted: boolean = false;
  Created: Date = new Date();
  Amended: Date = new Date();
}

export class UserGroup {
  UserGroupId: number = 0;
  Name: string = "";
  Description: string = "";
  IsDeleted: boolean = false;
  Created: Date = new Date();
  Amended: Date = new Date();
}

export class UserGroupPolicy {
  UserGroupPolicyId: number = 0;
  UserGroupId: number = 0;
  GroupId: number = 0;
}

export class GroupUser {
  GroupUserId: number = 0;
  GroupId: number = 0;
  UserId: number = 0;
}

@Injectable()
export class AuthenticationService {
  getGroupPolicy(id: number) {
      throw new Error('Method not implemented.');
  }
  constructor(
    private http: HttpClient,
    private actRoute: ActivatedRoute,
    public jwtHelper: JwtHelperService,
    public router: Router) {
  }

  /*checkPolicy(name: string): Observable<any> {

  }*/

  testPolicy(name: string): Observable<any> {
    return this.http.get(baseUrl + 'Account/TestPolicy?name=' + name, options);
  }

  getUserGroups(): Observable<any> {
    return this.http.get(baseUrl + 'Account/GetUserGroups', options);
  }

  getUserProfilesForGroup(id: number): Observable<any> {
    return this.http.get(baseUrl + 'Account/GetUsersForGroup?id=' + id, options);
  }

  getUserGroup(id: number): Observable<any> {
    return this.http.get(baseUrl + 'Account/GetUserGroup?id=' + id, options);
  }

  saveUserGroup(group: UserGroup): Observable<any> {
    return this.http.post(baseUrl + 'Account/SaveUserGroup', group, options);
  }

  saveGroupUsers(groupId: number, members: GroupUser[]): Observable<any> {
    return this.http.post(baseUrl + 'Account/SaveGroupUsers?groupId=' + groupId, members, options);
  }

  getPoliciesForUserGroup(id: number): Observable<any> {
    return this.http.get(baseUrl + 'Account/GetPoliciesForUserGroup?id=' + id, options);
  }

  getDefaultPolicies(name: string): Observable<any> {
    return this.http.get(baseUrl + 'Account/GetDefaultPolicies?name=' + name, options);
  }

  getPolicyDetailsForUserGroup(id: number, name: string): Observable<any> {
    return this.http.get(baseUrl + 'Account/GetPolicyDetailsForUserGroup?id=' + id + '&name=' + name, options);
  }

  getPolicyGroups(): Observable<any> {
    return this.http.get(baseUrl + 'Account/GetPolicyGroups', options);
  }

  getPolicyGroup(id: number): Observable<any> {
    return this.http.get(baseUrl + 'Account/GetPolicyGroup?id=' + id, options);
  }

  getPoliciesForGroup(id: number): Observable<any> {
    return this.http.get(baseUrl + 'Account/GetPoliciesForGroup?id=' + id, options);
  }

  savePolicy(policy: Policy): Observable<any> {
    return this.http.post(baseUrl + 'Account/SavePolicy', policy, options);
  }

  savePolicyGroup(group: Group): Observable<any> {
    return this.http.post(baseUrl + 'Account/SavePolicyGroup', group, options);
  }

  isOrganization(id: number): Observable<any> {
    return this.http.get(baseUrl + 'Account/IsOrganization?id=' + id, options);
  }

  loginNoCheck(data: any): Observable<any> {
    return this.http.post(baseUrl + 'Account/login', JSON.stringify(data), options);
  }

  socialLogin(model: SocialLogin): Observable<any> {
    return this.http.post(baseUrl + 'Account/SocialLogin', model, options);
  }

  socialRegister(model: SocialLogin): Observable<any> {
    return this.http.post(baseUrl + 'Account/SocialRegister', model, options);
  }

  login(data: any) {
    this.loginNoCheck(data)
      .subscribe(result => {
        let token = (<any>result).Token;
        this.setAccessToken(token);
        this.router.navigate(['../surveys']);
      },
        error => {
          console.error(error);
        });
  }

  loginAs(username: string): Observable<any> {
    return this.http.post(baseUrl + "Account/loginAs", JSON.stringify(username), options);
  }

  impersonateAs(username: string): Observable<any> {
    return this.http.post(baseUrl + "Account/Impersonate", JSON.stringify(username), options);
  }

  impersonateAsUserOrg(username: string, organizationId: number): Observable<any> {
    let model = new UserOrgModel(username, organizationId);
    return this.http.post(baseUrl + "Account/ImpersonateForOrganization", JSON.stringify(model), options);
  }

  impersonateAsOwner(ownerId: number): Observable<any> {
    return this.http.post(baseUrl + "Account/ImpersonateForOwner", JSON.stringify(ownerId), options);
  }

  zendeskCheck(): Observable<any> {
    return this.http.get(baseUrl + "Account/ZendeskCheck", options);
  }

  impersonateAsUserIdOrg(userId: number, organizationId: number): Observable<any> {
    let model = [];
    model.push(userId);
    model.push(organizationId);
    return this.http.post(baseUrl + "Account/ImpersonateForOrganizationWithUserId", JSON.stringify(model), options);
  }

  isAuthorized(allowedRoles: string[]): boolean {
    // check if the list of allowed roles is empty, if empty, authorize the user to access the page
    if (allowedRoles == null || allowedRoles.length === 0) {
      return true;
    }

    // get token from local storage or state management
    const token = this.getAccessToken();

    // decode token to read the payload details
    const decodeToken = this.jwtHelper.decodeToken(token);

    // check if it was decoded successfully, if not the token is not valid, deny access
    if (!decodeToken) {
      console.log('Invalid token');
      return false;
    }

    let roles = decodeToken['role'];
    if (allowedRoles && roles) {
      for (let i = 0; i < allowedRoles.length; i++) {
        if (roles.indexOf(allowedRoles[i]) > -1) {
          return true;
        }
      }
    }

    return false;
  }

  lastLoggedIn(): Date {
    const token = this.getAccessToken();
    const decodeToken = this.jwtHelper.decodeToken(token);
    let date = decodeToken['birthdate'];
    return new Date(date);
  }

  impersonate(): boolean {
    const token = this.getDecodedToken();
    if (token) {
      if (token['Behalf']) {
        return true;
      }
    }

    return false;
  }

  stopImpersonating() {
    const token = this.getDecodedToken();
    if (token) {
      if (token['Behalf']) {
        let userId = this.getUserId();
        this.http.get(baseUrl + "Account/StopImpersonation", options)
          .subscribe(result => {
            let token = (<any>result).Token;
            this.setAccessToken(token);
            this.router.navigate(["/edituser", userId, "user"]);
          },
            error => {
              console.error(error);
            });
      }
    }
  }

  logout() {
    this.logoutWithoutNavigate();
    this.router.navigate(['login']);
  }

  logoutWithoutNavigate() {
    sessionStorage.removeItem("jwt");
    this.http.get(baseUrl + 'Account/Logout', options);
  }

  getDecodedToken() {
    const token = this.getAccessToken();
    if (token) {
      return this.jwtHelper.decodeToken(token);
    }

    return token;
  }

  getAccessToken(): string {
    return sessionStorage.getItem("jwt") ?? "";
  }

  setAccessToken(token: string) {
    sessionStorage.setItem("jwt", token);
  }

  makePayment(creditCard: CreditCard): Observable<any> {
    return this.http.post(baseUrl + 'CreditCard/MakePayment',
      JSON.stringify(creditCard), options);
  }

  refreshAccessToken(): Observable<any> {
    return this.http.post(baseUrl + 'Account/refreshtoken',
      JSON.stringify(this.getAccessToken()),
      options);
  }

  refreshToken() {
    return this.http.post(baseUrl + 'Account/refreshtoken',
      JSON.stringify(this.getAccessToken()),
      options).subscribe(result => {
        this.setAccessToken((<any>result).Token);
      },
        error => {
          console.error(error);
        });
  }

  testRegisterToken(token: string): Observable<any> {
    return this.testToken(token, "register");
  }

  testPasswordToken(token: string): Observable<any> {
    return this.testToken(token, "forgot");
  }

  requestResetPassword(username: string): Observable<any> {
    return this.http.post(baseUrl + 'Account/ForgotPassword', JSON.stringify(username), options);
  }

  testInviteOrgToken(token: string): Observable<any> {
    return this.testToken(token, "organizationinvite");
  }

  testUserId(userId: number): Observable<any> {
    return this.http.get(baseUrl + 'Account/TestUserId?userId=' + userId, options);
  }

  testToken(token: string, tokenType: string): Observable<any> {
    return this.http.get(baseUrl + 'Account/TestToken?token=' + encodeURIComponent(token) + "&tokenType=" + tokenType, options);
  }

  sendEmail2FA(): Observable<any> {
    return this.http.get(baseUrl + 'Account/SendEmail2FA', options);
  }

  sendSms2FA(): Observable<any> {
    return this.http.get(baseUrl + 'Account/SendSms2FA', options);
  }

  check2FA(value: string): Observable<any> {
    return this.http.get(baseUrl + 'Account/Check2FA?code=' + value, options);
  }

  confirm2FA(deviceType: string): Observable<any> {
    return this.http.patch(baseUrl + 'Account/Confirm2FA', { deviceType: deviceType }, options);
  }

  saveSetting(name: string, value: string) {
    localStorage.setItem(name, value);
  }

  changePassword(oldPassword: string, password: string) {
    return this.http.patch(baseUrl + 'Account/ChangePassword', new ChangePasswordModel(oldPassword, password), options);
  }

  loadSetting(name: string): string {
    return localStorage.getItem(name) ?? "";
  }

  register(model: RegistrationModel) {
    return this.http.post(baseUrl + 'Account/register', JSON.stringify(model), options);
  }

  tellMeLater(model: RegistrationModel) {
    return this.http.post(baseUrl + 'Account/tellmelater', JSON.stringify(model), options);
  }

  registerTemplate(username: string) {
    return this.http.get(baseUrl + 'Account/registerTemplate?username=' + username, options);
  }

  getOrganizationsForMember(): Observable<any> {
    return this.http.get(baseUrl + 'Account/GetOrganizationsForMember', options);
  }

  getOrganizationSummariesForMember(): Observable<any> {
    return this.http.get(baseUrl + 'Account/GetOrganizationSummariesForMember', options);
  }

  getOrganizationsForOwner(): Observable<any> {
    return this.http.get(baseUrl + 'Account/GetOrganizationsForOwner', options);
  }
  
  getMembersForOrganization(id: number): Observable<any> {
    return this.http.get(baseUrl + 'Account/GetMembersForOrganization?id=' + id, options);
  }

  getInvitesForOrganization(id: number): Observable<any> {
    return this.http.get(baseUrl + 'Account/GetInvitesForOrganization?id=' + id, options);
  }

  searchUsersForOrgOwner(searchTag: string): Observable<UserProfile[]> {
    return this.http.get<UserProfile[]>(baseUrl + 'Account/SearchUsersForOwnedOrganizations?searchTag=' + searchTag, options);
  }

  deleteUser(userId: number): Observable<any> {
    return this.http.delete(baseUrl + 'Account/DeleteUser?userId=' + userId, options);
  }

  setRoleForOrganization(id: number, userId: number, role: number): Observable<any> {
    return this.http.patch(baseUrl + 'Account/SetMemberRoleForOrganization?organizationId=' + id + '&userId=' + userId + '&role=' + role, options);
  }

  checkVersion(): boolean {
    if (environment.production) {
      const token = this.getDecodedToken();
      if (token) {
        if (token['http://schemas.microsoft.com/ws/2008/06/identity/claims/version']) {
          return token['http://schemas.microsoft.com/ws/2008/06/identity/claims/version'] === version;
        }
      }
    }

    return true;
  }

  getMediaId(): number {
    const token = this.getDecodedToken();
    if (token) {
      if (token['MediaId']) {
        return Number(token['MediaId']);
      }
    }

    return 0;
  }

  getEmail(): string | null {
    const token = this.getDecodedToken();
    if (token) {
      if (token['Email']) {
        return token['Email'];
      }
    }

    return null;
  }

  getMemberName(): string | null {
    const token = this.getDecodedToken();
    if (token) {
      if (token['unique_name']) {
        return token['unique_name'];
      }
    }

    return null;
  }

  getGivenName(): string | null {
    const token = this.getDecodedToken();
    if (token) {
      if (token['given_name']) {
        return token['given_name'];
      }
    }

    return null;
  }

  currentOrganization(): number {
    const token = this.getDecodedToken();
    if (token) {
      if (token['Organization']) {
        return parseInt(token['Organization']);
      }
    }

    return -1;
  }

  getUserId(): number {
    const token = this.getDecodedToken();
    if (token) {
      if (token['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/sid']) {
        return Number(token['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/sid']);
      }
    }

    return 0;
  }

  getRedirectToken(router: Router): RedirectToken {
    const redirectToken = new RedirectToken(router);
    redirectToken.url = this.actRoute.snapshot.url.join(' ');
    if (this.actRoute.snapshot.params.id) {
      redirectToken.id = this.actRoute.snapshot.params.id;
    }
    if (this.actRoute.snapshot.params.token) {
      redirectToken.token = this.actRoute.snapshot.params.token;
    }

    return redirectToken;
  }

  requestSpeedManagement(id: number): Observable<any> {
    return this.http.get(baseUrl + 'Account/RequestSpeedManagement?id=' + id, options);
  }

  getSecurityKeys(value: string): Observable<any> {
    return this.http.get(baseUrl + 'Account/GetSecurityKeys?keyType=' + value, options);
  }

  getSecurityKeysForAccount(value: string, userId: number): Observable<any> {
    return this.http.get(baseUrl + 'Account/GetSecurityKeysForAccount?keyType=' + value + "&userId=" + userId, options);
  }

  getSecurityKey(id: number): Observable<any> {
    return this.http.get(baseUrl + 'Account/GetSecurityKey?id=' + id, options);
  }

  getSecurityKeyForAccount(id: number, userId: number): Observable<any> {
    return this.http.get(baseUrl + 'Account/GetSecurityKeyForAccount?id=' + id + '&userId=' + userId, options);
  }

  checkSecurityKey(id: string, username: string): Observable<any> {
    return this.http.get(baseUrl + 'Account/CheckSecurityKey?id=' + id + "&username=" + username, options);
  }

  transferSecurityKey(id: number, name: string, username: string, orgname: string): Observable<any> {
    return this.http.get(baseUrl + 'Account/TransferSecurityKey?id=' + id + "&name=" + name + "&username=" + username + "&orgname=" + orgname, options);
  }

  transferOrganization(id: number, username: string): Observable<any> {
    return this.http.get(baseUrl + 'Account/TransferOrganization?id=' + id + "&username=" + username, options);
  }

  newSecurityKey(): Observable<any> {
    return this.http.get(baseUrl + 'Account/NewSecurityKey', options);
  }

  newSecurityKeyForAccount(userId: number): Observable<any> {
    return this.http.get(baseUrl + 'Account/NewSecurityKeyForAccount?userId=' + userId, options);
  }

  setSecurityKey(key: SecurityKey) {
    return this.http.post(baseUrl + 'Account/SetSecurityKey', key, options);
  }

  deleteSecurityKey(id: string) {
    return this.http.delete(baseUrl + "Account/DeleteSecurityKey?id=" + id, options).subscribe();
  }

  setOrganization(location: string, organizationId: number) {
    this.http.post(baseUrl + 'Account/SetOrganization', JSON.stringify(organizationId), options).subscribe(result => {
      let token = (<any>result).Token;
      this.setAccessToken(token);
      let l = "";
      if (location != null) {
        l = location;
      }

      switch (l) {
        case "/surveydashboard":
        case "/respondents":
        case "/smshome":
        case "/network":
          this.router.navigate(['/'])
            .then(() => {
              this.router.navigate([l])
            });
          break;
        default:
          if (this.isAuthorized(['Admin'])) {
            this.router.navigate(['../users']);
          }
          else if (this.isAuthorized(['developer'])) {
            this.router.navigate(['../smshome']);
          }
          else {
            this.router.navigate(["/surveydashboard"]);
          }
          break;
      }
    },
      error => {
        console.error(error);
      });
  }

  deleteOrganization(organizationId: number) {
    this.http.delete(baseUrl + 'Account/DeleteOrganization?id=' + organizationId, options).subscribe();
  }

  saveOrganizationForUser(userId: number, id: string, name: string, description: string, invites: UserInvite[], mediaId: number): Observable<any> {
    const model = new OrganizationModel(name, description, invites, mediaId);
    return this.http.post(baseUrl + 'Account/SaveOrganizationForUser?userId=' + userId + '&id=' + id, JSON.stringify(model), options);
  }

  saveOrganization(id: string, name: string, description: string, invites: UserInvite[], mediaId: number): Observable<any> {
    const model = new OrganizationModel(name, description, invites, mediaId);
    return this.http.post(baseUrl + 'Account/SaveOrganization?id=' + id, JSON.stringify(model), options);
  }

  invite(id: number, invites: UserInvite[]): Observable<any> {
    return this.http.post(baseUrl + 'Account/SaveInvites?id=' + id, JSON.stringify(invites), options);
  }

  addPeople(id: number, invites: UserInvite[]): Observable<any> {
    return this.http.post(baseUrl + 'Account/AddPeople?id=' + id, JSON.stringify(invites), options);
  }

  inviteAgain(id: number, invitationId: number): Observable<any> {
    return this.http.patch(baseUrl + "Account/InviteAgain?id=" + id + "&invitationId=" + invitationId, options);
  }

  deleteInvitation(invitationId: number) {
    this.http.delete(baseUrl + "Account/DeleteInvitation?invitationId=" + invitationId, options).subscribe();
  }

  deleteMemberForOrganization(organizationId: number, userId: number) {
    this.http.delete(baseUrl + "Account/DeleteMemberFromOrganization?organizationId=" + organizationId + "&userId=" + userId, options).subscribe();
  }

  getOrganizations(): Observable<any> {
    return this.http.get(baseUrl + 'Account/GetOrganizations', options);
  }

  getOrganization(id: number): Observable<any> {
    return this.http.get(baseUrl + 'Account/GetOrganization?id=' + id, options);
  }

  getAdminOrg(id: string): Observable<any> {
    return this.http.get(baseUrl + 'Account/GetAdminOrg?id=' + id, options);
  }

  getOrganizationsForUser() {
    return this.http.get(baseUrl + 'Account/GetOrganizationsForUser', options);
  }

  getOrganizationsForSpecificUser(id: string): Observable<any> {
    return this.http.get(baseUrl + 'Account/getOrganizationsForSpecificUser?id=' + id, options);
  }

  getOrganizationsForSpecificMember(id: string): Observable<any> {
    return this.http.get(baseUrl + 'Account/getOrganizationsForSpecificMember?id=' + id, options);
  }

  findOrganizations(name: string): Observable<any> {
    return this.http.get(baseUrl + 'Account/FindOrganizations?name=' + name, options);
  }

  resetPassword(token: string, password: string): Observable<any> {
    return this.http.patch(baseUrl + 'Account/ResetPassword?token=' + encodeURIComponent(token),
      JSON.stringify(new ResetPasswordModel(password)), options);
  }

  findUser(name: string): Observable<any> {
    return this.http.get(baseUrl + 'Account/Find?name=' + name, options);
  }

  checkToken(token: string): Observable<any> {
    return this.http.get(baseUrl + 'Account/CheckPasswordToken?token=' + encodeURIComponent(token), options);
  }


}
