<div [formGroup]="formGroup" class="date-time-input-field fullWidth">
  <mat-form-field *ngIf="showDate" appearance="outline">
    <mat-label>Choose a date</mat-label>
    <input matInput [matDatepicker]="picker" formControlName="date" (click)="picker.open()">
    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
    <mat-datepicker #picker></mat-datepicker>
  </mat-form-field>
  <span *ngIf="showTime && showTime">&nbsp;</span>
  <mat-form-field *ngIf="showTime" appearance="outline">
    <input matInput [ngxTimepicker]="picker" formControlName="time">
    <ngx-material-timepicker #picker></ngx-material-timepicker>
    <mat-icon matSuffix>query_builder</mat-icon>
  </mat-form-field>
</div>
